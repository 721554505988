import React, { FC } from 'react'
import { RouteComponentProps, Route, Switch, Redirect } from 'react-router-dom'

import {
  isTrashRoute,
  isDraftsRoute,
  isLibrariesRoute,
  isProjectRoute,
  RouteParams,
  routes,
  isCollectionRoute,
  isArchiveRoute,
} from '@sketch/modules-common'
import { useLayoutPortal } from '@sketch/utils'
import { useForTablet, BannersDisplay, LoadingState } from '@sketch/components'
import { useEducationWorkspaceApproved } from '../../modals/EducationWorkspaceApprovedModal'

import { getSectionTitle, getProjectTitle, getCollectionTitle } from './utils'

import WorkspaceSharesList from '../../../shares/components/WorkspaceSharesList'
import TrashList from '../../../shares/components/TrashList'
import { ArchiveList } from '../../../shares/components/ArchiveList'
import ShareNavbar from '../../../shares/components/ShareNavbar'
import ProjectsView from '../../../projects/views/ProjectsView'
import ProjectsAndCollectionsView, {
  ProjectsAndCollectionsViewRouteProps,
} from '../../../projects/views/ProjectsAndCollectionsView'
import CollectionsView from '../../../collections/views/CollectionView'
import {
  BillingStatusBanners,
  WelcomeModalTrigger,
  PersonalWorkspaceBanners,
} from '../../components'
import { userIsEditorOrAdmin } from '../../utils'
import { SearchBar } from '../../../shares/components/SearchBar'
import { useApolloClient } from '@apollo/react-hooks'

import {
  useGetProjectsQuery,
  useGetWorkspaceSharesCountQuery,
  useGetDocumentListSettingsQuery,
  useGetWorkspaceBillingQuery,
} from '@sketch/gql-types'
import { WorkspaceSidebarLayoutExtraProps } from '../../components/WorkspaceSidebarLayout/WorkspaceSidebarLayout'

import {
  NavbarTitle,
  SearchBarWrapper,
  BannerWrapper,
  FirstStepsBannerWrapper,
} from './WorkspaceDocumentsView.styles'
import { FirstStepsBanner } from '../../components/FirstStepsBanner'
import { WorkspaceSharesListHeader } from './WorkspaceSharesListHeader'
import { useForceIncludeDocsInProjects } from '../../hooks'

/**
 * TYPES
 */
export type WorkspaceDocumentsViewRoutesProps = RouteComponentProps<
  | RouteParams<'WORKSPACE_PROJECT'>
  | RouteParams<'WORKSPACE_SHARES'>
  | RouteParams<'WORKSPACE_TRASH'>
  | RouteParams<'WORKSPACE_ARCHIVE'>
>

export type WorkspaceDocumentsViewProps = WorkspaceDocumentsViewRoutesProps &
  WorkspaceSidebarLayoutExtraProps

type ProjectsViewProps = RouteComponentProps<
  Partial<RouteParams<'WORKSPACE_PROJECT'> | RouteParams<'WORKSPACE_DRAFTS'>>
>

type CollectionsViewProps = RouteComponentProps<
  Partial<RouteParams<'WORKSPACE_COLLECTION'>>
>

type WorkspaceArchiveRouteParams = RouteComponentProps<
  Partial<RouteParams<'WORKSPACE_ARCHIVE'>>
>

type WorkspaceTrashRouteParams = RouteComponentProps<
  Partial<RouteParams<'WORKSPACE_TRASH'>>
>

type WorkspaceSharesRouteParams = RouteComponentProps<
  Partial<RouteParams<'WORKSPACE_SHARES'>>
>

type WorkspaceLibrariesRouteParams = RouteComponentProps<
  Partial<RouteParams<'WORKSPACE_LIBRARIES'>>
>

type WorkspaceSharedWithMeRouteParams = RouteComponentProps<
  Partial<RouteParams<'WORKSPACE_SHARED_WITH_ME'>>
>

/**
 * COMPONENT
 */
const WorkspaceDocumentsView: FC<WorkspaceDocumentsViewProps> = ({
  match: { params, path },
  workspace,
  useOverrideLayoutProps,
}) => {
  const { workspaceId } = params
  const isTabletAndBigger = useForTablet()
  const isSmallerThanTablet = !isTabletAndBigger

  const NavbarPortal = useLayoutPortal('navbar-portal')
  const WarningsPortal = useLayoutPortal('warnings-portal')

  const isWorkspaceLibrariesRoute = isLibrariesRoute(path)

  // Shows docs in projects in `All documents` when the workspace is inactive
  useForceIncludeDocsInProjects(workspace.status)

  // TODO: use useGetProjects instead
  const { data, loading } = useGetProjectsQuery({
    variables: {
      workspaceId,
    },
  })

  const { data: settings } = useGetDocumentListSettingsQuery()
  const { showDocumentsInProjects, showArchivedDocuments } = settings || {}

  const workspaceSharesCount = useGetWorkspaceSharesCountQuery({
    variables: { identifier: workspaceId },
    fetchPolicy: 'cache-and-network',
  })
  const sharesCount =
    workspaceSharesCount.data?.workspace.shares.meta.totalCount ?? 0

  const billingQuery = useGetWorkspaceBillingQuery({
    variables: { identifier: workspaceId },
    skip: !workspace.customer?.identifier,
  })

  useEducationWorkspaceApproved(workspace)

  const { cache } = useApolloClient()

  let projectFromCache = null
  let collectionFromCache = null

  if (isProjectRoute(path)) {
    projectFromCache = getProjectTitle(params, cache)
  }

  if (isCollectionRoute(path)) {
    collectionFromCache = getCollectionTitle(params, cache)
  }

  const sectionTitle =
    collectionFromCache || projectFromCache || getSectionTitle(path)

  useOverrideLayoutProps({
    title: sectionTitle || 'Documents',
    hideFooter: true,
    hasNavbar: false,
  })

  const subscription = billingQuery.data?.workspace?.customer?.billing

  if (loading && isDraftsRoute(path)) {
    return <LoadingState />
  }

  const hasDraftsProject = !!data?.workspace.draftsProject.entries[0]
  const isEducationWorkspace = workspace.type === 'STUDENT'
  const isEditorOrAdmin = userIsEditorOrAdmin(workspace)

  const { status, subscriptionEnd } = subscription ?? {}

  if (isTrashRoute(path) && !isEditorOrAdmin) {
    // The user is accessing /workspace/${workspaceId}/trash but he doesn't have a "Trash"
    return <Redirect to={routes.ALL_DOCUMENTS.create({})} />
  }

  const hasArchiveFeature = workspace.features.archiveEnabled

  if (isArchiveRoute(path) && !hasArchiveFeature) {
    // The user is accessing /workspace/${workspaceId}/archive but he doesn't have a "Archive"
    return <Redirect to={routes.ALL_DOCUMENTS.create({})} />
  }

  if (isDraftsRoute(path) && !hasDraftsProject) {
    // The user is accessing /workspace/${workspaceId}/drafts but there isn't any
    // draft project in this workspace.
    return <Redirect to={routes.ALL_DOCUMENTS.create({})} />
  }

  const navbar = (
    <ShareNavbar
      userRole={workspace.userRole}
      title={<NavbarTitle>{sectionTitle}</NavbarTitle>}
      showViewOptions={Boolean(sharesCount)}
    />
  )

  return (
    <>
      <NavbarPortal>{navbar}</NavbarPortal>

      <WarningsPortal>
        <FirstStepsBannerWrapper>
          <FirstStepsBanner />
        </FirstStepsBannerWrapper>

        {/* Mounting the banner container to render them all */}
        {!isWorkspaceLibrariesRoute && (
          <BannerWrapper $hideMargins="top">
            <BannersDisplay />
          </BannerWrapper>
        )}
      </WarningsPortal>

      {/*
        Prevent PersonalWorkspaceBanners from showing along with the
        empty Workspace banners.
        */}
      {sharesCount ? (
        <PersonalWorkspaceBanners
          workspaceId={workspace.identifier}
          workspaceType={workspace.type}
          isOwner={workspace.userIsOwner}
        />
      ) : null}

      {status && (
        <BillingStatusBanners
          workspace={workspace}
          sharesCount={sharesCount}
          billingStatus={status}
          cancelPeriodEnd={subscriptionEnd ?? undefined}
          isEducationWorkspace={isEducationWorkspace}
        />
      )}

      {subscription && (
        <WelcomeModalTrigger
          subscriptionDetails={subscription}
          workspace={workspace}
        />
      )}

      {isSmallerThanTablet && (
        <SearchBarWrapper>
          <SearchBar userRole={workspace.userRole} fullWidth />
        </SearchBarWrapper>
      )}

      <Switch>
        <Route
          exact
          path={[
            routes.WORKSPACE_PROJECT.template(),
            routes.WORKSPACE_DRAFTS.template(),
            routes.WORKSPACE_COLLECTION.template(),
          ]}
          render={(routeProps: ProjectsAndCollectionsViewRouteProps) => (
            <>
              <ProjectsAndCollectionsView
                {...routeProps}
                workspace={workspace}
              />
            </>
          )}
        />
        <Route
          exact
          path={[routes.WORKSPACE_TRASH_COLLECTION.template()]}
          render={({ match: { params } }: CollectionsViewProps) => (
            <CollectionsView
              workspace={workspace}
              projectId={params.projectId!}
              collectionId={params.collectionId!}
            />
          )}
        />
        {isEditorOrAdmin && (
          <Route
            exact
            path={routes.WORKSPACE_TRASH.template()}
            render={({ match: { params } }: WorkspaceTrashRouteParams) => (
              <TrashList
                workspaceId={params.workspaceId!}
                userRole={workspace.userRole}
              />
            )}
          />
        )}
        <Route
          exact
          path={routes.WORKSPACE_TRASH_PROJECT.template()}
          render={({ match: { params } }: ProjectsViewProps) => (
            <ProjectsView
              workspace={workspace}
              projectId={'projectId' in params ? params.projectId : undefined}
            />
          )}
        />
        <Route
          exact
          path={routes.WORKSPACE_SHARES.template()}
          render={({ match }: WorkspaceSharesRouteParams) => (
            <>
              <WorkspaceSharesListHeader
                filter={showDocumentsInProjects ? null : 'NO_PROJECT'}
              />
              <WorkspaceSharesList
                workspaceId={match.params.workspaceId!}
                workspace={workspace}
                filter={showDocumentsInProjects ? null : 'NO_PROJECT'}
                showArchived={showArchivedDocuments}
              />
            </>
          )}
        />
        <Route
          exact
          path={routes.WORKSPACE_SHARED_WITH_ME.template()}
          render={({ match }: WorkspaceSharedWithMeRouteParams) => (
            <>
              <WorkspaceSharesListHeader filter={'SHARED_WITH_ME'} />
              <WorkspaceSharesList
                workspaceId={match.params.workspaceId!}
                workspace={workspace}
                filter="SHARED_WITH_ME"
                showArchived={showArchivedDocuments}
              />
            </>
          )}
        />
        <Route
          exact
          path={routes.WORKSPACE_TEMPLATES.template()}
          render={({ match }: WorkspaceSharedWithMeRouteParams) => (
            <>
              <WorkspaceSharesListHeader filter={'TEMPLATE'} />
              <WorkspaceSharesList
                workspaceId={match.params.workspaceId!}
                workspace={workspace}
                filter="TEMPLATE"
                showArchived={showArchivedDocuments}
              />
            </>
          )}
        />
        <Route
          exact
          path={routes.WORKSPACE_LIBRARIES.template()}
          render={({ match }: WorkspaceLibrariesRouteParams) => (
            <>
              <WorkspaceSharesListHeader filter={'LIBRARY'} />
              <WorkspaceSharesList
                workspaceId={match.params.workspaceId!}
                workspace={workspace}
                filter="LIBRARY"
                showArchived={showArchivedDocuments}
              />
            </>
          )}
        />
        {workspace.features.archiveEnabled && (
          <Route
            exact
            path={routes.WORKSPACE_ARCHIVE.template()}
            render={({ match: { params } }: WorkspaceArchiveRouteParams) => (
              <ArchiveList
                workspaceId={params.workspaceId!}
                workspace={workspace}
                userRole={workspace.userRole}
              />
            )}
          />
        )}
      </Switch>
    </>
  )
}

export default WorkspaceDocumentsView
