import styled, { css } from 'styled-components'

import {
  FlatButton,
  LinkButton as LinkButtonUnstyled,
  Text,
} from '@sketch/components'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 595px;
  width: 100%;
  text-align: center;
`

export const TextInbox = styled(Text.Div).attrs({
  textStyle: 'copy.primary.standard.E',
})`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 8px;
`

export const StyledLinkButton = styled(FlatButton)(
  ({ theme }) => css`
    display: inline-block;
    padding: 0;
    text-decoration: underline;
    line-height: ${theme.lineHeights.default};
    cursor: pointer;
    font-weight: ${theme.fontWeights.semibold};
  `
)

export const DoThisLaterButton = styled(LinkButtonUnstyled)`
  margin: 44px auto;
`
