import React, { useState, useContext } from 'react'

// Contexts
import { ToastContext } from '@sketch/toasts'

import {
  Box,
  Flex,
  Tooltip,
  Caption,
  Button,
  Link,
  Separator,
  CopyToClipboard,
  Section,
  useModalContext,
} from '@sketch/components'

// Modals
import { ConfirmDisableScimModal } from '../../modals/ConfirmDisableScimModal'
import { ConfirmGenerateNewScimTokenModal } from '../../modals/ConfirmGenerateNewScimTokenModal'

// Others
import { useEnableScimMutation } from '@sketch/gql-types'
import config from '@sketch/env-config'

interface DisabledFieldProps {
  'data-testid'?: string
}

const DisabledField: React.FC<DisabledFieldProps> = ({
  'data-testid': dataTestId,
  children,
}) => (
  <Box
    borderRadius={6}
    borderWidth="1px"
    borderStyle="dashed"
    borderColor="border.A"
    backgroundColor="background.secondary.B"
    py={2}
    px={4}
    color="foreground.secondary.D"
    style={{
      wordBreak: 'break-word',
    }}
    data-testid={dataTestId}
  >
    {children}
  </Box>
)

interface WorkspaceSettingsScimPanelProps {
  isSsoEnabled: boolean
  isScimEnabled: boolean
  workspaceId: string
}

export const WorkspaceSettingsScimPanel: React.FC<
  WorkspaceSettingsScimPanelProps
> = ({
  isSsoEnabled,
  isScimEnabled,
  workspaceId,
}: WorkspaceSettingsScimPanelProps) => {
  const [scimToken, setScimToken] = useState<string | null>()
  const { showToast } = useContext(ToastContext)
  const [enableScim, { loading: loadingEnableScim }] = useEnableScimMutation({
    variables: {
      identifier: workspaceId,
    },
    onError: 'show-toast',
    onCompleted: data => {
      setScimToken(data.enableScim.scimToken)
      showToast('SCIM provisioning has been enabled', 'positive')
    },
  })
  const { showModal } = useModalContext()

  const baseUrl = `${config.scim_provisioning_base_url}${workspaceId}`

  const getActionButton = () => {
    if (isScimEnabled) {
      return (
        <Button
          variant="negative-secondary"
          onClick={() =>
            showModal(ConfirmDisableScimModal, {
              workspaceId,
              onSuccess: () => {
                setScimToken(null)
              },
            })
          }
          size="40"
          disabled={loadingEnableScim}
          data-testid="disable-scim-button"
        >
          Disable SCIM&hellip;
        </Button>
      )
    }

    if (isSsoEnabled) {
      return (
        <Button
          variant="primary"
          onClick={() => enableScim()}
          size="40"
          disabled={loadingEnableScim}
          loading={loadingEnableScim}
          data-testid="enable-scim-button"
        >
          Enable SCIM
        </Button>
      )
    }

    return (
      <Tooltip content="You have to enable SSO before enabling SCIM">
        <Button
          variant="primary"
          size="40"
          disabled
          data-testid="enable-scim-button"
        >
          Enable SCIM
        </Button>
      </Tooltip>
    )
  }

  return (
    <>
      <Separator />
      <Box mt={8} mb={8}>
        <Section
          title="Set Up SCIM Provisioning"
          text={
            <div>
              System for Cross-domain Identity Management &#40;SCIM&#41;
              provisioning allows you to automatically add or remove Workspace
              Members from your IdP.
            </div>
          }
          action={getActionButton()}
        >
          {isScimEnabled && (
            <>
              <Caption>SCIM Base URL</Caption>
              <Flex mt={1} mb={6}>
                <DisabledField data-testid="scim-url-text-area">
                  {baseUrl}
                </DisabledField>
                <Box ml={4}>
                  <CopyToClipboard
                    size="40"
                    value={baseUrl}
                    showIcon={false}
                    data-testid="copy-scim-url-button"
                  />
                </Box>
              </Flex>
              <Caption>SCIM Token</Caption>
              <Flex mt={1}>
                {scimToken ? (
                  <>
                    <Flex flexDirection="column">
                      <DisabledField data-testid="scim-token-text-area">
                        {scimToken}
                      </DisabledField>
                      <Box mt={1} mb={2}>
                        <Caption>
                          <strong>
                            This authentication token will only be displayed
                            once.
                          </strong>{' '}
                          If you open SCIM provisioning settings again later,
                          the token will be hidden to protect your
                          Workspace&#8217;s security. You can easily generate a
                          new token, which will cause the current token to
                          expire.
                        </Caption>
                      </Box>
                    </Flex>
                    <Box ml={4}>
                      <CopyToClipboard
                        size="40"
                        value={scimToken}
                        showIcon={false}
                        data-testid="copy-scim-token-button"
                      />
                    </Box>
                  </>
                ) : (
                  <>
                    <Flex flexDirection="column">
                      <DisabledField data-testid="scim-token-text-area">
                        •••••••••
                      </DisabledField>
                      <Box mt={1} mb={2}>
                        <Caption>
                          This authentication token is hidden to protect your
                          Workspace&#8217;s security. You can easily generate a
                          new token, which will cause the current token to
                          expire.
                        </Caption>
                      </Box>
                      <Link
                        onClick={e => {
                          e.preventDefault()
                          showModal(ConfirmGenerateNewScimTokenModal, {
                            workspaceId,
                            onSuccess: token => {
                              setScimToken(token)
                            },
                          })
                        }}
                        isUnderlined
                        external
                        data-testid="generate-new-scim-token-link"
                      >
                        Generate New Token&hellip;
                      </Link>
                    </Flex>
                    <Box width={100} ml={4} />
                  </>
                )}
              </Flex>
            </>
          )}
        </Section>
      </Box>
    </>
  )
}
