import { getItem } from '@sketch/utils'
import { isProduction } from '@sketch/env-config'

/*
  This util provides the URL Scheme that Sketch app understands,
  allowing us to interact with the Mac app

  These are the possibilities for the different environments:
  production -> sketch://sketch.cloud/s/...
  test -> sketch://test.sketch.cloud/s/...
  staging -> sketch://staging.sketch.cloud/s/...
  dev -> sketch://dev.sketch.cloud/s/...
*/
export const setSketchUrlScheme = (url: string) => {
  url = url.replace(/(^https?:|^)\/\//, '')
  // I'm not entirely sure older mac apps can handle sketch.com in production so
  // playing safe and sticking with sketch.cloud
  url = url.replace(/(^www\.|^)sketch\.com/, 'sketch.cloud')

  const sketchAppVariant = getItem('debug.sketchAppVariant')

  if (isProduction() && isEditShareURL(url)) {
    return `sketch://${url}`
  } else {
    return `${getVariantProtocol(sketchAppVariant)}://${url}`
  }
}

const acceptedSketchVariants = [
  'final',
  'beta',
  'private',
  'internal',
  'experimental',
  'xcode',
  'testing',
  'feature-preview',
]

export const getVariantProtocol = (sketchVariant: string = '') =>
  acceptedSketchVariants.includes(sketchVariant)
    ? `sketch-${sketchVariant}`
    : 'sketch'

/**
 * This helper will check if the url belongs to the edit share deeplink. It's mostly used
 * for 'openInSketch' action
 *
 * The reason is that we only support the prefix fo `<env>.sketch.cloud in that links,
 * in any other case, prefixing them will result in a broken link even if we are logged in
 * in the same environment in web and mac apps.
 */
const isEditShareURL = (url: string) => url.search('sketch.cloud') !== -1
