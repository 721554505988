import styled from 'styled-components'
import { Tooltip as TooltipBase } from '../Tooltip'

export const Container = styled.div`
  overflow: hidden;
  max-width: 100%;
`

export const HiddenSegments = styled.div`
  opacity: 0;
  pointer-events: none;
  position: absolute;
  display: flex;
`

export const VisibleSegments = styled.div`
  white-space: nowrap;
  display: flex;
`

export const Segment = styled.span`
  white-space: nowrap;
`

export const Tooltip = styled(TooltipBase)`
  overflow: hidden;
  max-width: 100%;
  display: flex;
  flex-direction: column;
`
