import React from 'react'

import { dataIdFromObject } from '@sketch/graphql-cache'
import {
  AnnotationDotFragment,
  BaseAnnotationFragment,
  GetAnnotationsQuery,
} from '@sketch/gql-types'
import { LoadPageSeparator, handleFetchMore } from '@sketch/components'

import Annotation from '../Annotation'
import AnnotationInboxOptions from '../AnnotationInboxOptions'

import CommentSkeleton from '../CommentSkeleton'
import CommentTabMessageNoAnnotations from '../CommentTabMessageNoAnnotations'

import { FetchMoreFunc } from '@sketch/components/src/InfiniteList/InfiniteList'
import { NavLinkProps } from 'react-router-dom'
import { isAnnotationNewOrHasNewComments } from '../../utils'
import Link from '../Link'
import { ResolvedSeparator, CommentNewSeparator } from './AnnotationList.styles'
import { AnnotationStatusFilterWithoutUnread } from '../../types'

const ENTRIES_PATH = ['annotations', 'entries']
const LOADING_PAGE_SIZE = 6

const createRenderResolvedSeparator = () => {
  let isResolveSeparatorRendered = false

  return (annotation: BaseAnnotationFragment) => {
    const isAnnotationResolved = !!annotation.resolution?.resolvedAt

    if (isAnnotationResolved && !isResolveSeparatorRendered) {
      isResolveSeparatorRendered = true
      return true
    }

    return false
  }
}

const createUniqueLatestLabel = () => {
  let isLatestLabelRendered = false
  let hasAnnotationUnread = false

  return (annotation: BaseAnnotationFragment) => {
    const isUnreadNewAnnotation = isAnnotationNewOrHasNewComments(annotation)
    if (isUnreadNewAnnotation) {
      hasAnnotationUnread = true
    }

    if (
      !isLatestLabelRendered &&
      !isUnreadNewAnnotation &&
      hasAnnotationUnread
    ) {
      isLatestLabelRendered = true
      return true
    }

    return false
  }
}

interface PreventiveLinkProps extends OmitSafe<NavLinkProps, 'to'> {
  to?: NavLinkProps['to']
  $isResolved?: boolean
}

const PreventiveLink: React.FC<PreventiveLinkProps> = ({
  children,
  ...props
}) => {
  if (!props.to) {
    return <>{children}</>
  }

  return (
    <Link {...props} to={props.to}>
      {children}
    </Link>
  )
}

// Same interface used in "containers/AnnotationsListContainer"
interface ListProps {
  activeAnnotationIdentifier?: string
  resolveAnnotationLink?: (
    annotation: BaseAnnotationFragment
  ) => NavLinkProps['to'] | undefined
}

interface AnnotationListProps extends ListProps {
  entries: BaseAnnotationFragment[]
  meta: GetAnnotationsQuery['annotations']['meta']
  fetchMore: FetchMoreFunc
  annotationStatus: AnnotationStatusFilterWithoutUnread
  shouldRenderAnnotation?: (
    annotation: AnnotationDotFragment,
    isActiveAnnotation: boolean
  ) => boolean
}

const AnnotationList: React.FC<AnnotationListProps> = ({
  entries,
  meta,
  fetchMore,
  resolveAnnotationLink,
  activeAnnotationIdentifier,
  annotationStatus,
  shouldRenderAnnotation = () => true,
}) => {
  const isAllAnnotations = annotationStatus === 'ALL'
  const isActiveOnly = annotationStatus === 'ACTIVE_ONLY'

  const canRenderResolvedSeparator = createRenderResolvedSeparator()
  const canRenderLatestLabel = createUniqueLatestLabel()

  const renderedEntries: React.ReactNode[] = []
  entries.forEach(annotation => {
    const activeAnnotation =
      annotation.identifier === activeAnnotationIdentifier

    if (!shouldRenderAnnotation(annotation, activeAnnotation)) {
      return null
    }

    const showNewSeparator = isActiveOnly && canRenderLatestLabel(annotation)

    renderedEntries.push(
      <React.Fragment key={`unresolved-${annotation.identifier}`}>
        {showNewSeparator && (
          <CommentNewSeparator data-highlight="true">NEW</CommentNewSeparator>
        )}

        {isAllAnnotations && canRenderResolvedSeparator(annotation) && (
          <ResolvedSeparator>Resolved</ResolvedSeparator>
        )}

        {/* Render the annotation */}
        <PreventiveLink
          to={resolveAnnotationLink?.(annotation)}
          isActive={match =>
            !!match && annotation.identifier === activeAnnotationIdentifier
          }
        >
          <Annotation
            body={annotation.firstComment.body}
            user={annotation.firstComment.user}
            commentNumber={annotation.comments.meta.totalCount}
            createdAt={annotation.firstComment.createdAt}
            hasNewComments={annotation.hasNewComments!}
            resolvedAt={annotation.resolution?.resolvedAt ?? undefined}
            isNew={annotation.isNew!}
            optionsMenu={className => (
              <AnnotationInboxOptions
                className={className}
                annotationIdentifier={annotation.identifier}
                notificationStatus={annotation.subscriptionStatus}
                isResolved={!!annotation?.resolution}
                userCanDeleteAnnotation={annotation.firstComment.userCanDelete}
              />
            )}
          />
        </PreventiveLink>
      </React.Fragment>
    )
  })

  if (renderedEntries.length === 0) {
    return (
      <CommentTabMessageNoAnnotations
        annotationStatus={annotationStatus}
        showAddCommentButton
      />
    )
  }

  return (
    <>
      {renderedEntries}

      {meta.after && (
        <LoadPageSeparator
          key={meta.after}
          loadNewPage={handleFetchMore(fetchMore, ENTRIES_PATH, {
            dataIdFromObject,
            after: meta.after,
          })}
        />
      )}

      <CommentSkeleton
        count={Math.min(LOADING_PAGE_SIZE, meta.totalCount - entries.length)}
      />
    </>
  )
}

export default AnnotationList
