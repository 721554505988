import styled from 'styled-components'
import { Table as UnstyledTable } from '@sketch/components'
import { TableCell as UnstyledTableCell } from '@sketch/components/src/Table/Table.styles'

export const Table = styled(UnstyledTable)`
  width: 100%;
` as typeof UnstyledTable

export const TableCell = styled(UnstyledTableCell)`
  &:last-child {
    width: 100%;
    padding-left: 2rem;
  }
`
