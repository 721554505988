import React, { ReactNode, useEffect, useMemo } from 'react'
import {
  inspectorHoveredElementContext,
  inspectorContext,
  useInspectorOverlayEvents,
  InspectorContext,
  useHasAnyLayerWithExportableAssets,
} from '../../hooks/inspector'
import { useGetActiveSidebarTab } from '../../../components/ShareSidebarTabContext'
import { useSketchSceneTree } from '../../hooks/inspector/useSketchSceneTree'

type FrameGroupInspectorProviderProps = {
  children: ReactNode
}

export function FrameGroupInspectorProvider({
  children,
}: FrameGroupInspectorProviderProps) {
  const activeSidebarTab = useGetActiveSidebarTab()
  const isInspectorActive = activeSidebarTab === 'Inspect'

  const { sketchRootElement: sketchSceneRootElement, hasFailed } =
    useSketchSceneTree(isInspectorActive)

  const hasAnyLayerWithExportableAssets = useHasAnyLayerWithExportableAssets(
    sketchSceneRootElement
  )

  const {
    selectedElement,
    hoveredElement,
    setSelectedElement,
    setHoveredElement,
    clickHandler,
    mouseMoveHandler,
    mouseDownHandle,
  } = useInspectorOverlayEvents(
    sketchSceneRootElement,
    // Handle web renderer events only when we need to
    isInspectorActive
  )

  /**
   * Reset the set element when the
   * inspector is disabled
   */
  useEffect(() => {
    if (isInspectorActive) {
      return () => {
        setSelectedElement(null)
      }
    }
  }, [setSelectedElement, isInspectorActive])

  let inspectorStatus: InspectorContext['inspectorStatus'] = 'pending'

  if (sketchSceneRootElement) {
    inspectorStatus = 'ready' as const
  } else if (hasFailed) {
    inspectorStatus = 'errored' as const
  }

  const inspectorContextValue = useMemo((): InspectorContext => {
    return {
      selectedElement: selectedElement,
      isInspectorActive: isInspectorActive,
      sketchSceneRootElement: sketchSceneRootElement,
      setSelectedElement: setSelectedElement,
      setHoveredElement,
      inspectorStatus,
      hasAnyLayerWithExportableAssets,
      clickHandler,
      mouseMoveHandler,
      mouseDownHandle,
    }
  }, [
    clickHandler,
    hasAnyLayerWithExportableAssets,
    sketchSceneRootElement,
    isInspectorActive,
    mouseMoveHandler,
    selectedElement,
    setHoveredElement,
    setSelectedElement,
    inspectorStatus,
    mouseDownHandle,
  ])

  return (
    <inspectorContext.Provider value={inspectorContextValue}>
      <inspectorHoveredElementContext.Provider value={hoveredElement}>
        {children}
      </inspectorHoveredElementContext.Provider>
    </inspectorContext.Provider>
  )
}
