import React from 'react'

import {
  PRLayerSizingBehaviour,
  PRLayerSizingBehaviourEnum,
} from '@sketch-hq/sketch-web-renderer'

import { ReactComponent as FitIcon } from '@sketch/icons/arrows-left-right-inward-17'
import { ReactComponent as FillIcon } from '@sketch/icons/arrows-left-right-outward-17'
import { ReactComponent as FixIcon } from '@sketch/icons/fix-width-17'
import { ReactComponent as RelativeIcon } from '@sketch/icons/spring-horizontal-17'

import { Icon } from './MeasureSizingBehaviour.styles'

interface MeasureSizingBehaviourProps {
  behaviour?: PRLayerSizingBehaviourEnum
  rotate?: boolean
}

export const MeasureSizingBehaviour = (props: MeasureSizingBehaviourProps) => {
  if (!props.behaviour) {
    return (
      <>
        <Icon as={FillIcon} $rotate={props.rotate} />
        Fill
      </>
    )
  }

  switch (props.behaviour.value) {
    case PRLayerSizingBehaviour.Fill:
      return (
        <>
          <Icon as={FillIcon} $rotate={props.rotate} />
          Fill
        </>
      )
    case PRLayerSizingBehaviour.Relative:
      return (
        <>
          <Icon as={RelativeIcon} $rotate={props.rotate} />
          Relative
        </>
      )
    case PRLayerSizingBehaviour.Fit:
      return (
        <>
          <Icon as={FitIcon} $rotate={props.rotate} />
          Fit
        </>
      )
    case PRLayerSizingBehaviour.Fixed:
      return (
        <>
          <Icon as={FixIcon} $rotate={props.rotate} />
          Fixed
        </>
      )
  }

  return null
}
