import React from 'react'

import { useResponsiveDropdown } from '@sketch/components'
import { useGetProjects } from '../../../projects/operations/useGetProjects'
import ProjectDropdown from '../../../projects/components/ProjectDropdown'
import {
  DropdownButton,
  DropdownButtonEllipsis,
} from '../../../shares/components/DocumentItem/DocumentItem.styles'
import { NestedProjectHeaderActionsProps } from './types'

/**
 * Dropdown for nested project actions that appears in the project view, with nested project as thumbnails
 */
const NestedProjectDropdown: React.FC<NestedProjectHeaderActionsProps> = ({
  workspace,
  nestedProject,
}) => {
  const { projectsById } = useGetProjects({
    workspaceId: workspace.identifier,
  })

  const project = projectsById[nestedProject.identifier]?.project

  const [content, buttonProps] = useResponsiveDropdown({
    dropdown: ProjectDropdown,
    dropdownProps: {
      workspace,
      project,
    },
    placement: 'bottom-end',
    usePortal: true,
  })

  return (
    <div onClick={event => event.stopPropagation()}>
      <DropdownButton data-testid="nestedProject-dropdown" {...buttonProps}>
        <DropdownButtonEllipsis aria-label="Project Settings" />
        <span className="sr-only">Project Settings</span>
      </DropdownButton>
      {content}
    </div>
  )
}

export default NestedProjectDropdown
