import React from 'react'

import {
  MenuIconButton,
  NewFeatureHighlightPopover,
  useResponsiveDropdown,
} from '@sketch/components'
import { usePrototypeState } from '@sketch-hq/sketch-web-renderer'

import { PrototypeDropdown } from '../../../shares/components/PrototypeCardDropdown'
import { useVersioning } from '../../../versioning'

import * as S from './SharePrototype.styles'

export const SharePrototype = () => {
  const { currentArtboardId } = usePrototypeState()
  const { getPathname, share } = useVersioning()

  const pathname: string = getPathname({
    routeKey: 'PROTOTYPE_PLAYER',
    routeParams: {
      shareID: share.identifier,
      prototypeArtboardUUID: currentArtboardId,
      currentArtboardUUID: currentArtboardId,
    },
  })

  const [content, buttonProps] = useResponsiveDropdown({
    dropdown: PrototypeDropdown,
    dropdownProps: { pathname },
    placement: 'bottom-end',
    usePortal: false,
  })

  const button = (
    <MenuIconButton
      data-testid="share-prototype-button"
      description="Share prototype"
      {...buttonProps}
    >
      <S.Icon role="img" />
      <span className="sr-only">Share prototype</span>
    </MenuIconButton>
  )

  return (
    <>
      <NewFeatureHighlightPopover
        closeButtonTestId="sharing-prototype-close-callout"
        highlightPlacement="header"
        titleText="More Ways to View Prototypes"
        bodyText="If you want a cleaner, more client-friendly prototype experience, you can hide Hotspots and hints, as well as the toolbar and navigation."
        buttonLinkProps={{
          external: true,
          href: 'https://www.sketch.com/docs/prototyping/sharing-prototypes/#how-to-share-prototypes',
          target: '_blank',
          children: 'Learn more',
        }}
        localStorageKey="seenSharingPrototype"
        show={!buttonProps['aria-expanded']}
        showPrimaryCloseButton
      >
        {button}
      </NewFeatureHighlightPopover>
      {content}
    </>
  )
}
