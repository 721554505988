import { useEffect, useMemo, useRef, useState } from 'react'
import { useCombobox } from 'downshift'
import { useFormikContext } from 'formik'
import { WatchQueryFetchPolicy } from 'apollo-client'

import { useDebounceValue } from '@sketch/utils'
import { useToast } from '@sketch/toasts'

import {
  useGetInvitableUsersAndGroupsQuery,
  useGetInvitableUsersQuery,
} from '@sketch/gql-types'

import { AutoCompleteItem } from './types'
import { useUserProfile } from '../../libs'

export type FieldAutoCompleteMembersFilter =
  | 'Guests'
  | 'Members'
  | 'Members and Groups'
  | 'Members and Guests'
  | 'Members, Groups and Guests'

type FilterWithGroups = Array<FieldAutoCompleteMembersFilter>

const EMPTY_ARRAY: AutoCompleteItem[] = []

// Email regex from HTML spec
// https://html.spec.whatwg.org/multipage/input.html#valid-e-mail-address
const EMAIL_REGEX =
  /^[a-zA-Z0-9.!#$%&'*+\\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/
const FILTER_WITH_GROUPS: FilterWithGroups = [
  'Members and Groups',
  'Members, Groups and Guests',
]

const FILTER_WITHOUT_GUESTS: FilterWithGroups = [
  'Members',
  'Members and Groups',
]

export interface FieldAutoCompleteFormValues {
  shouldValidate: 'email' | 'user' | 'group' | false
  email: string | null
  workspaceMembershipIdentifier: string | null
  groupIdentifier: string | null
}

const getItemName = (item: AutoCompleteItem | null) => {
  if (item === null) {
    return undefined
  }

  if (item.__typename === 'PermissionGroup') {
    return item.name
  }

  return item.user?.name || ''
}

const isGuest = (item: AutoCompleteItem) =>
  item.__typename === 'WorkspaceMembership' && item.role === 'GUEST'

const filteredExcluded = (
  items: AutoCompleteItem[],
  excludedItems?: AutoCompleteItem[],
  userIdentifier?: string
) => {
  if (!excludedItems) {
    return items
  }

  return items
    .filter(member => {
      const isExcludedMember = !excludedItems.find(
        ({ identifier }) => member.identifier === identifier
      )

      const isOwnUser =
        member.__typename === 'WorkspaceMembership' &&
        member.user?.identifier === userIdentifier

      return isExcludedMember || isOwnUser
    })
    .splice(0, 5)
}

interface EmailValidator {
  filter: FieldAutoCompleteMembersFilter
  removeSelf: boolean
  value: string
  userEmail: string
  invitableUsers: AutoCompleteItem[]
  selectedItem: AutoCompleteItem | null
}

const createEmailValidator = (props: EmailValidator) => {
  const { filter, removeSelf, userEmail, value, invitableUsers, selectedItem } =
    props

  if (filter === 'Guests') {
    if (removeSelf && value === userEmail) {
      return 'Cannot invite yourself'
    }

    if (!EMAIL_REGEX.test(value)) {
      return 'Invalid email'
    }

    const isMember = invitableUsers.find(item =>
      item?.__typename === 'WorkspaceMembership'
        ? item.user?.email === value
        : false
    )

    if (isMember) {
      return 'Is a member of this workspace and cannot be invited as a Guest'
    }
  } else {
    if (
      EMAIL_REGEX.test(value) &&
      invitableUsers.length === 0 &&
      !selectedItem
    ) {
      return 'Is not an inviteable member of this workspace'
    }
  }
}

interface GetInvitableUsersOrGroupQuery {
  workspaceIdentifier: string

  shareIdentifier?: string
  projectIdentifier?: string

  search: string
  filter: FieldAutoCompleteMembersFilter
  skip?: boolean
  fetchPolicy?: WatchQueryFetchPolicy
}

const useGetInvitableUsersOrGroup = (props: GetInvitableUsersOrGroupQuery) => {
  const {
    filter,
    shareIdentifier,
    workspaceIdentifier,
    projectIdentifier,
    search,
    skip,
    fetchPolicy = 'cache-and-network',
  } = props

  const variables = {
    shareIdentifier,
    projectIdentifier,
    workspaceIdentifier,
    search,
  }

  const { showToast } = useToast()

  const excludeGroups = !FILTER_WITH_GROUPS.includes(filter)

  const invitableUsersQuery = useGetInvitableUsersQuery({
    variables,
    skip: skip || !excludeGroups,
    fetchPolicy,
    onError() {
      showToast('Failed to autocomplete name.', 'negative')
    },
  })

  const invitableUsersAndGroupsQuery = useGetInvitableUsersAndGroupsQuery({
    variables,
    skip: skip || excludeGroups,
    fetchPolicy,
    onError() {
      showToast('Failed to autocomplete name.', 'negative')
    },
  })

  const loading =
    invitableUsersQuery.loading || invitableUsersAndGroupsQuery.loading

  const data =
    invitableUsersQuery.data?.invitableUsers.entries ||
    invitableUsersAndGroupsQuery.data?.invitableUsersAndGroups ||
    EMPTY_ARRAY

  return useMemo(() => {
    if (filter === 'Guests') {
      const guests = data.filter(item => isGuest(item))
      return { loading, invitableUsers: guests }
    }

    if (FILTER_WITHOUT_GUESTS.includes(filter)) {
      const withoutGuests = data.filter(item => !isGuest(item))
      return { loading, invitableUsers: withoutGuests }
    }

    if (FILTER_WITH_GROUPS.includes(filter)) {
      const membersAndGroups = data.filter(item => !isGuest(item))
      return { loading, invitableUsers: membersAndGroups }
    }

    const membersAndGuests = data
    return { loading, invitableUsers: membersAndGuests }
  }, [filter, data, loading])
}

interface FieldAutocompleteProps {
  workspaceIdentifier: string
  shareIdentifier?: string
  projectIdentifier?: string
  filter?: FieldAutoCompleteMembersFilter
  excludeItems?: AutoCompleteItem[]
  removeSelf?: boolean
}

/**
 * Hook to orchestrate all the logic to create a users autocomplete
 */
const useFieldAutocomplete = ({
  shareIdentifier,
  workspaceIdentifier,
  projectIdentifier,
  filter = 'Members',
  removeSelf = false,
  excludeItems,
}: FieldAutocompleteProps) => {
  const [items, setItems] = useState<AutoCompleteItem[]>([])
  const formik = useFormikContext<FieldAutoCompleteFormValues>()
  const { data: userData } = useUserProfile()

  const {
    validateForm,
    setFieldValue,
    values: { shouldValidate },
  } = formik

  const combobox = useCombobox({
    items,
    itemToString: item => getItemName(item) || '',
  })

  const { inputValue, selectedItem, reset } = combobox
  const selectedItemName = getItemName(selectedItem)

  const debouncedValue = useDebounceValue(inputValue, 300)
  const isValueAMention = debouncedValue.charAt(0) === '@'
  const isValueSelected = selectedItemName === inputValue
  const isValueSelectedDebounced = selectedItemName === debouncedValue
  const isValueTooShort = debouncedValue.length === 0
  const isValueEmpty = inputValue.trim() === ''
  const isValueSynced = inputValue === debouncedValue

  const skip = isValueTooShort || isValueSelectedDebounced || isValueEmpty

  const search = isValueAMention ? debouncedValue.substring(1) : debouncedValue

  const { invitableUsers: unFilteredInvitableUsers, loading } =
    useGetInvitableUsersOrGroup({
      filter,
      search,
      shareIdentifier,
      workspaceIdentifier,
      projectIdentifier,
      skip,
    })

  const invitableUsers = useMemo(
    () =>
      filteredExcluded(
        unFilteredInvitableUsers,
        excludeItems,
        removeSelf ? userData?.me.email || undefined : undefined
      ),
    [unFilteredInvitableUsers, excludeItems, removeSelf, userData?.me.email]
  )

  useEffect(() => {
    // If we're skipping the query, then clear the items or when it's loading
    if (skip || loading || !invitableUsers) {
      setItems([])
      return
    }

    setItems(invitableUsers)
  }, [invitableUsers, skip, loading, filter])

  // Reset combobox if Formik form is reset
  useEffect(() => {
    if (!shouldValidate) {
      reset()
    }
  }, [shouldValidate, reset])

  const validate = useRef((email: string) =>
    createEmailValidator({
      filter,
      invitableUsers: unFilteredInvitableUsers,
      removeSelf,
      selectedItem,
      userEmail: userData?.me.email || '',
      value: email,
    })
  )

  useEffect(() => {
    validate.current = (email: string) =>
      createEmailValidator({
        filter,
        invitableUsers: unFilteredInvitableUsers,
        removeSelf,
        selectedItem,
        userEmail: userData?.me.email || '',
        value: email,
      })
  }, [
    unFilteredInvitableUsers,
    filter,
    selectedItem,
    userData?.me.email,
    removeSelf,
    loading,
  ])

  useEffect(() => {
    const { registerField, unregisterField } = formik

    registerField('email', { validate: validate.current })

    return () => {
      unregisterField('email')
    }
  }, [formik, filter, unFilteredInvitableUsers, selectedItem, userData])

  // Validate values on change to enable/disable
  // submit button accordingly
  // Need to use this due to bug in Formik:
  // https://github.com/formium/formik/issues/2083#issuecomment-734628995
  useEffect(() => {
    validateForm(formik.values)
  }, [validateForm, formik.values, invitableUsers])

  useEffect(() => {
    // When the field value does not match the combobox selection
    // then we indicate to formik we should be treating it as a plain email value

    if (selectedItem?.__typename === 'WorkspaceMembership') {
      setFieldValue('shouldValidate', 'user')

      // Set the userIdentifier if we are using value from the autocomplete. Otherwise, we will
      // send the plain email
      setFieldValue(
        'workspaceMembershipIdentifier',
        isValueSelectedDebounced && selectedItem.identifier
      )

      setFieldValue('email', selectedItem.user?.email)
    } else if (selectedItem?.__typename === 'PermissionGroup') {
      setFieldValue('shouldValidate', 'group')
      setFieldValue(
        'groupIdentifier',
        isValueSelectedDebounced && selectedItem.identifier
      )
    } else {
      setFieldValue('shouldValidate', 'email')
      // Set the formik value used to make the mutation to either combobox selection
      // or the input plain value, depending on whether a combobox item has been selected
      setFieldValue('email', inputValue)
    }
  }, [isValueSelectedDebounced, inputValue, selectedItem, setFieldValue])

  return {
    items,
    formik,
    combobox,
    valueSelected: selectedItemName,
    isValueSelected,
    membersLoading: loading || !isValueSynced,
  }
}

export { useFieldAutocomplete }
