import React, { useContext } from 'react'
import styled from 'styled-components'

import { ToastContext } from '../Context'
import { ToastContainer } from '../Toast/ToastContainer'
import { ToastChild, ToastsDisplay } from './Toasts.display'

const MAX_VISIBLE_TOASTS = 2

export interface ToastsProps {
  className?: string
  maxVisibleToasts?: number
}

export const ToastsContainer: React.FC<ToastsProps> = props => {
  const { className, maxVisibleToasts = MAX_VISIBLE_TOASTS }: typeof props =
    props
  const { dismissToast, toasts } = useContext(ToastContext)

  // Prevent showing more then the "maxVisibleToasts" at the time
  const initialToasts = toasts.slice(-maxVisibleToasts)

  return (
    <ToastsDisplay className={className}>
      {initialToasts.map(
        toast =>
          (
            <ToastContainer
              key={toast.id}
              id={toast.id}
              variant={toast.variant}
              autoDismiss={toast.autoDismiss}
              onDismiss={() => dismissToast(toast.id)}
            >
              {toast.message}
            </ToastContainer>
          ) as ToastChild
      )}
    </ToastsDisplay>
  )
}

export const Toasts = styled(ToastsContainer)`
  position: fixed;
  top: 60px;
  right: 20px;
  width: 100%;

  /* Make the toasts on top of everything */
  z-index: ${({ theme }) => theme.zIndex[8]};
  pointer-events: none;
`
