import React from 'react'

import { ReactComponent as NestedProjectIcon } from '@sketch/icons/folder-closed-16'

// This component shares the same util as ProjectItem
import { getButtonProps } from '../../../shares/components/ProjectItem/utils'

import { HighlightedText, TableComponents, Skeleton } from '@sketch/components'

import { useNestedProjectDropShares } from '../../hooks'

import {
  DroppableTableWrapper,
  DroppableTableWrapperDragTooltip,
} from './NestedProjectItem.styles'

// This component shares styles with DocumentItem
import {
  ImageWrapper,
  Name,
  StatusIcon,
  TableCell,
  TableWrapper,
  NameWrapper,
} from '../../../shares/components/DocumentItem/DocumentItem.styles'

import { NestedProjectItemProps } from './types'
import { PreviewSubtitle } from './PreviewSubtitle'
import { useSearch } from '../../../shares/hooks/useSearch'

export const NestedProjectItemListView = (props: NestedProjectItemProps) => {
  const {
    className,
    nestedProject,
    onClick,
    projectIdentifier,
    workspaceIdentifier,
    renderDropdown,
  } = props

  const { isDraggedOver = false, ...dropEvents } = useNestedProjectDropShares({
    projectIdentifier,
    workspaceIdentifier,
  })

  const a11yProps = onClick ? getButtonProps(onClick) : {}
  const dropdownContent = renderDropdown?.()
  const { search } = useSearch()

  return (
    <DroppableTableWrapper
      $isDraggedOver={isDraggedOver}
      className={className}
      title={nestedProject.name}
      {...dropEvents}
      {...a11yProps}
    >
      <TableCell>
        {isDraggedOver && (
          <DroppableTableWrapperDragTooltip>
            Add to project
          </DroppableTableWrapperDragTooltip>
        )}
        <NameWrapper>
          <ImageWrapper title={nestedProject.name}>
            <StatusIcon
              as={NestedProjectIcon}
              aria-label={`Project ${nestedProject.name}`}
            />
          </ImageWrapper>
          <Name>
            <HighlightedText search={search}>
              {nestedProject.name}
            </HighlightedText>
          </Name>
        </NameWrapper>
      </TableCell>
      <TableCell>
        <PreviewSubtitle project={nestedProject} />
      </TableCell>
      <TableComponents.TableCellSticky>
        {dropdownContent}
      </TableComponents.TableCellSticky>
    </DroppableTableWrapper>
  )
}

export interface NestedProjectItemListViewSkeletonProps {
  inViewListener?: React.ReactNode
}
export const NestedProjectItemListViewSkeleton = (
  props: NestedProjectItemListViewSkeletonProps
) => {
  const { inViewListener } = props
  return (
    <TableWrapper>
      <TableCell>
        <NameWrapper>
          <ImageWrapper>
            <StatusIcon as={NestedProjectIcon} aria-label="Project" />
          </ImageWrapper>
          <Skeleton height="12px" width="30%" />
        </NameWrapper>
      </TableCell>
      <TableCell>
        <Skeleton height="12px" width="30%" />
        {inViewListener}
      </TableCell>
      <TableCell></TableCell>
    </TableWrapper>
  )
}
