import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Formik } from 'formik'
import { Form, Button, ModalInjectedProps } from '@sketch/components'
import {
  ModalBody,
  MinHeightModal,
  ModalFooterZIndex,
} from '../CreateDesignSystemModal/CreateDesignSystemModal.styles'
import { FormEditCreate, validationSchema } from '../FormEditCreate'
import { routes } from '@sketch/modules-common'
import {
  useUpdateDesignSystemMutation,
  GetDesignSystemDocument,
  GetDesignSystemQueryVariables,
} from '@sketch/gql-types'
import { FormProps, DesignSystem } from '../../types'

interface EditDesignSystemModalProps extends ModalInjectedProps {
  workspaceId: string
  designSystemToEdit: DesignSystem
}

export const EditDesignSystemModal = (props: EditDesignSystemModalProps) => {
  const { hideModal, workspaceId, designSystemToEdit } = props
  const [logo, setLogo] = useState<File | null>(null)
  const history = useHistory()

  const libraries =
    designSystemToEdit.previewShares ?? designSystemToEdit.shares.entries

  const INITIAL_VALUES: FormProps = {
    name: designSystemToEdit.name,
    description: designSystemToEdit.description || '',
    libraries: libraries.map(library => library.identifier) || [],
  }

  const [updateMutation, { loading: submitting }] =
    useUpdateDesignSystemMutation({
      onCompleted: ({
        updateDesignSystem,
      }: {
        updateDesignSystem: { identifier: string }
      }) => {
        if (designSystemToEdit.previewShares) {
          history.push(
            routes.DESIGN_SYSTEM.create({
              workspaceId,
              designSystemId: updateDesignSystem.identifier,
            })
          )
        } else {
          hideModal()
        }
      },
      onError: 'show-toast',
      refetchQueries: [
        {
          query: GetDesignSystemDocument,
          variables: {
            identifier: designSystemToEdit.identifier,
            limit: 1,
          } as GetDesignSystemQueryVariables,
        },
      ],
    })

  const handleSubmit = (values: FormProps) => {
    updateMutation({
      variables: {
        identifier: designSystemToEdit.identifier,
        input: {
          name: values.name,
          description: values.description,
          shares: values.libraries,
          preview: logo ?? undefined,
        },
      },
    })
  }

  return (
    <MinHeightModal
      title={`Edit "${designSystemToEdit.name}"`}
      onCancel={hideModal}
    >
      <Formik
        validationSchema={validationSchema}
        initialValues={INITIAL_VALUES}
        onSubmit={handleSubmit}
      >
        {formikbag => {
          const cantSubmit = !!(
            formikbag.values.libraries.length === 0 ||
            formikbag.values.name.trim() === ''
          )

          return (
            <Form>
              <ModalBody>
                <FormEditCreate
                  workspaceId={workspaceId}
                  hideModal={hideModal}
                  onImageChange={setLogo}
                  formikbag={formikbag}
                  submitting={submitting}
                  logo={designSystemToEdit.preview ?? null}
                />
              </ModalBody>
              <ModalFooterZIndex>
                <Button disabled={submitting} onClick={hideModal}>
                  Cancel
                </Button>
                <Button
                  data-testid="create-ds-button"
                  type="submit"
                  variant="primary"
                  submitting={submitting}
                  loading={submitting}
                  disabled={cantSubmit}
                  onClick={formikbag.submitForm}
                >
                  Save
                </Button>
              </ModalFooterZIndex>
            </Form>
          )
        }}
      </Formik>
    </MinHeightModal>
  )
}
