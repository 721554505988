import { useEffect, useRef } from 'react'

import {
  GetInitialUserDocument,
  useGetInitialUserQuery,
} from '@sketch/gql-types'
import isEqual from 'lodash.isequal'
import { useUserAuthorizations } from './useUserAuthorizations'

type QueryParameters = Parameters<typeof useGetInitialUserQuery>[0]

export const useUserProfile = (
  cacheOnly = true,
  hookOptions?: QueryParameters
) => {
  const { activeAuthorization } = useUserAuthorizations()

  const previousActiveAuthorization = useRef(activeAuthorization)

  const apolloBag = useGetInitialUserQuery({
    ...hookOptions,
    fetchPolicy: cacheOnly ? 'cache-only' : 'cache-and-network',
  })

  const { client } = apolloBag

  // Compare the previous and current activeAuthorization
  // If the user changes from Regular to SSO workspace,
  // they should be different
  const hasActiveAuthorizationChanged = !isEqual(
    previousActiveAuthorization.current,
    activeAuthorization
  )

  // If the "activeAuthorization" changed we refetch the user profile
  // this allows us to keep the name of the user up to date
  // when moving from Regular to SSO workspaces
  useEffect(() => {
    if (hasActiveAuthorizationChanged) {
      client.query({
        query: GetInitialUserDocument,
        fetchPolicy: 'network-only',
      })
    }
  }, [hasActiveAuthorizationChanged, client])

  // Update previousActiveAuthorization reference with the activeAuthorization
  useEffect(() => {
    if (isEqual(previousActiveAuthorization.current, activeAuthorization)) {
      return
    }

    previousActiveAuthorization.current = activeAuthorization
  }, [activeAuthorization])

  return apolloBag
}

export const useProfileInfo = () => {
  const { data } = useUserProfile()

  return {
    hasPersonalIdentity: data?.me?.hasPersonalIdentity ?? false,
    userEmail: data?.me.email ?? undefined,
    userAvatar: data?.me.avatar?.small ?? undefined,
  }
}
