import { castError } from '@sketch/utils'
import React from 'react'
import { Formik, FormikHelpers } from 'formik'
import * as yup from 'yup'
import { MutationTuple } from 'react-apollo'

import AvatarUploadContainer from './AvatarUploadContainer'
import {
  Form,
  Input,
  DecoratedInput,
  useModalContext,
} from '@sketch/components'
import { ChangeEmailModal } from '@sketch/user'
import { ChangePasswordModal } from '../ChangePasswordModal'
import {
  FieldRow,
  NameField,
  NameFieldCaption,
  EditEmailButton,
  SaveNameButton,
  EditPasswordButton,
  EmailField,
  PasswordField,
  NewEmailCaption,
  ResendLink,
  PendingEmailPill,
} from './SettingsForm.styles'

import { useToast } from '@sketch/toasts'

import {
  useEmailResendChangeConfirmationMutation,
  UserUpdateMutation,
  UserUpdateMutationVariables,
} from '@sketch/gql-types'

const settingsSchema = yup.object().shape({
  name: yup.string().trim().required('Enter your preferred name'),
})

type Values = {
  name: string
}

export interface SettingsFormProps {
  userName: string
  userEmail: string
  userNewEmail: string | null
  userHasAvatar: boolean
  userAvatarSrc: string
  userHasPersonalIdentity: boolean
  userHasPersonalAuthorization: boolean
  isSignedInWithSso: boolean
  onUserUpdate: MutationTuple<
    UserUpdateMutation,
    UserUpdateMutationVariables
  >[0]
}

const SettingsForm: React.FC<SettingsFormProps> = props => {
  const {
    userName,
    userEmail,
    userNewEmail,
    userHasAvatar,
    userAvatarSrc,
    userHasPersonalAuthorization,
    isSignedInWithSso,
    onUserUpdate,
  } = props

  const { showModal } = useModalContext()
  const { showToast } = useToast()
  const [resendConfirmation, { loading: isResendingConfirmation }] =
    useEmailResendChangeConfirmationMutation({
      redirectErrors: false,
      onError: 'unsafe-throw-exception',
    })

  const handleOnSubmit = async (
    values: Values,
    actions: FormikHelpers<Values>
  ) => {
    try {
      await onUserUpdate({ variables: { input: values } })
    } catch (e) {
      // Error handling will be done by the parent component
    } finally {
      actions.setSubmitting(false)
    }
  }

  const handleResendConfirmation = async () => {
    if (isResendingConfirmation || !userNewEmail) {
      return
    }

    try {
      const { data } = await resendConfirmation()

      const result = data && data.emailResendChangeConfirmation

      if (result && result.errors && result.errors.length > 0) {
        throw result.errors[0]
      }

      showToast(
        `Please confirm your new email address using the link we sent to '${userNewEmail}'`,
        'positive'
      )
    } catch (e) {
      const error = castError(e)
      showToast(error.message, 'negative')
    }
  }

  return (
    <Formik
      validationSchema={settingsSchema}
      initialValues={{
        name: userName,
      }}
      onSubmit={handleOnSubmit}
      // Keep user name updated when checking account settings
      // with an SSO workspace selected.
      enableReinitialize
    >
      {formik => {
        const { handleChange, handleBlur, values, errors, isSubmitting } =
          formik

        return (
          <Form style={{ display: 'block' }}>
            <FieldRow>
              <AvatarUploadContainer
                userName={userName}
                userHasAvatar={userHasAvatar}
                userAvatarSrc={userAvatarSrc}
              />

              <NameField name="name" label="Name" errorText={errors.name}>
                <Input
                  name="name"
                  type="text"
                  placeholder="Your preferred name"
                  value={values.name}
                  disabled={!userHasPersonalAuthorization}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </NameField>

              <SaveNameButton
                type="submit"
                variant="secondary"
                loading={isSubmitting}
                disabled={isSubmitting || values.name === userName}
              >
                Save
              </SaveNameButton>
            </FieldRow>

            {isSignedInWithSso && (
              <NameFieldCaption>
                In SSO Workspaces, your name is set by the identity provider
              </NameFieldCaption>
            )}

            {userHasPersonalAuthorization && (
              <>
                <FieldRow>
                  <EmailField name="email" label="Email">
                    <DecoratedInput
                      value={userNewEmail ?? userEmail}
                      label="Email"
                      suffixComponent={
                        userNewEmail && (
                          <PendingEmailPill>Pending</PendingEmailPill>
                        )
                      }
                    />
                  </EmailField>

                  <EditEmailButton
                    onClick={() =>
                      showModal(ChangeEmailModal, {
                        initialEmail: userNewEmail ?? userEmail,
                      })
                    }
                  >
                    Edit&hellip;
                  </EditEmailButton>
                </FieldRow>
                {userNewEmail && (
                  <NewEmailCaption>
                    Your current email address is <b>{userEmail}</b> —
                    <ResendLink onClick={handleResendConfirmation}>
                      Resend Confirmation
                    </ResendLink>
                  </NewEmailCaption>
                )}
                <FieldRow>
                  <PasswordField name="password" label="Password">
                    <Input
                      name="password"
                      type="text"
                      value="••••••••"
                      disabled
                    />
                  </PasswordField>

                  <EditPasswordButton
                    onClick={() =>
                      showModal(
                        ChangePasswordModal,
                        {
                          initialEmail: userEmail,
                        },
                        { closeOnRouteChange: false }
                      )
                    }
                  >
                    Edit&hellip;
                  </EditPasswordButton>
                </FieldRow>
              </>
            )}
          </Form>
        )
      }}
    </Formik>
  )
}

export default SettingsForm
