import React from 'react'

import { NestedProjectGridPreview } from '../NestedProjectGridPreview'

import { HighlightedText, Skeleton } from '@sketch/components'

import NestedProjectShareDrop from '../NestedProjectShareDrop'

import {
  StyledName,
  StyledSubtitleTextWrapper,
  Container,
  GridWrapper,
  GridWrapperLeft,
  GridWrapperRight,
  GridNestedProjectItemOption,
} from './NestedProjectItem.styles'

// Shared
// This component shares the same util as ProjectItem
import { getButtonProps } from '../../../shares/components/ProjectItem/utils'
import { PreviewSubtitle } from './PreviewSubtitle'

import { NestedProjectItemProps } from './types'
import { useSearch } from '../../../shares/hooks/useSearch'

export const NestedProjectItemGridView = (props: NestedProjectItemProps) => {
  const {
    className,
    renderDropdown,
    onClick,
    nestedProject,
    workspaceIdentifier,
  } = props

  const a11yProps = onClick ? getButtonProps(onClick) : {}
  const dropdownContent = renderDropdown?.()

  const { search } = useSearch()
  const name = nestedProject.name

  return (
    <Container>
      <NestedProjectShareDrop
        projectIdentifier={nestedProject.identifier}
        workspaceIdentifier={workspaceIdentifier}
      >
        <GridWrapper
          className={className}
          title={nestedProject.name}
          {...a11yProps}
        >
          <NestedProjectGridPreview nestedProject={nestedProject} />

          <GridWrapperRight>
            <StyledName>
              <HighlightedText search={search}>{name}</HighlightedText>
            </StyledName>
            <StyledSubtitleTextWrapper>
              <PreviewSubtitle project={nestedProject} />
            </StyledSubtitleTextWrapper>
          </GridWrapperRight>
          {dropdownContent && (
            <GridNestedProjectItemOption aria-label="Document Options">
              {dropdownContent}
            </GridNestedProjectItemOption>
          )}
        </GridWrapper>
      </NestedProjectShareDrop>
    </Container>
  )
}

export interface NestedProjectItemGridViewSkeletonProps {
  inViewListener?: React.ReactNode
}
export const NestedProjectItemGridViewSkeleton = (
  props: NestedProjectItemGridViewSkeletonProps
) => {
  const { inViewListener } = props
  return (
    <Container>
      <GridWrapper>
        <GridWrapperLeft />
        <GridWrapperRight>
          <StyledName>
            <Skeleton width="75%" height="14px" />
          </StyledName>
          <StyledSubtitleTextWrapper>
            <Skeleton width="30%" height="12px" />
            {inViewListener}
          </StyledSubtitleTextWrapper>
        </GridWrapperRight>
      </GridWrapper>
    </Container>
  )
}
