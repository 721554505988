import { useApolloClient } from 'react-apollo'
import produce from 'immer'
import { v1 as uuid } from 'uuid'
import { DataProxy } from 'apollo-cache'

import {
  AnnotationCommentCountFragment,
  AnnotationCommentCountFragmentDoc,
  AnnotationSubjectCountersFragment,
  AnnotationSubjectSubscriptionStatusFragment,
  useCreateAnnotationCommentMutation,
} from '@sketch/gql-types'

import { dataIdFromObject } from '@sketch/graphql-cache'
import { errorPreventiveFragmentRead } from '@sketch/modules-common'

import {
  getAnnotationArtboardInfo,
  getAnnotationPageInfo,
  getAnnotationSubject,
  updateCommentsQuery,
} from '../utils/cache'

import {
  createCommentFragment,
  createInitialUserQuery,
  hasOptimisticResponseVariables,
} from '../utils'

type CreateCommentOptimistic =
  AnnotationSubjectCountersFragment['currentSubject'] &
    AnnotationSubjectSubscriptionStatusFragment['currentSubject']

const getOptimisticCurrentSubject = (
  apollo: DataProxy,
  annotationIdentifier: string
): CreateCommentOptimistic => {
  const annotation = getAnnotationSubject(apollo, annotationIdentifier)
  if (!annotation) {
    /**
     * This fragment should always exist since it's queried on the
     * "getAnnotationComments" and the only way to run this mutation is on the
     * "AnnotationCommentsList" by adding a comment. So this should never exist
     */
    throw new Error('Missing the AnnotationSubject fragment')
  }

  const { currentSubject } = annotation

  if (currentSubject.__typename === 'Artboard') {
    const artboardInfo = getAnnotationArtboardInfo(
      apollo,
      currentSubject.documentVersionShortId,
      currentSubject.permanentArtboardShortId
    )

    const pageInfo = getAnnotationPageInfo(
      apollo,
      currentSubject.page!.identifier
    )

    return {
      ...currentSubject,
      __typename: 'Artboard',
      unreadCount: (artboardInfo?.unreadCount || 0) + 1,
      annotationCount: (artboardInfo?.annotationCount || 0) + 1,
      subscriptionStatus: artboardInfo?.subscriptionStatus || 'ON',
      page: {
        ...currentSubject.page!,
        pageUUID: currentSubject.page!.uuid,
        hasUnreadComments: pageInfo?.hasUnreadComments || false,
      },
    }
  } else if (currentSubject.__typename === 'Page') {
    const pageInfo = getAnnotationPageInfo(apollo, currentSubject.identifier)

    return {
      ...currentSubject,
      __typename: 'Page',
      hasUnreadComments: pageInfo?.hasUnreadComments || false,
    }
  }

  return currentSubject
}

const useCreateAnnotationComment = (annotationIdentifier: string) => {
  const apollo = useApolloClient()
  const [createComment] = useCreateAnnotationCommentMutation({
    onError: 'show-toast',
  })

  return (...args: Parameters<typeof createComment>) => {
    const commentIdentifier = uuid()

    return createComment({
      ...args[0],
      update: (cache, { data }) => {
        /**
         *  1st step update the annotation query
         */
        const comment = data?.createAnnotationReply.comment

        if (!comment) {
          // If there's no comment response no need to update anything then
          return
        }

        updateCommentsQuery(cache, { annotationIdentifier }, data => {
          if (!data?.annotation) {
            // Preventive measure
            return
          }

          const { comments } = data.annotation

          /**
           * Prevent having 2 annotations with the
           * same identifier added
           */
          const commentAlreadyExists = comments.entries.find(
            ({ identifier }) => comment.identifier === identifier
          )

          if (commentAlreadyExists) {
            return
          }

          // If we reach the end of the list and there's no comment updating
          // means this is the optimistic comment, let's add it then
          comments.entries.unshift(comment)
          comments.meta.totalCount = comments.meta.totalCount + 1
        })

        /**
         * 2nd step update the annotation reply count if loaded
         */
        const annotationCacheId = dataIdFromObject({
          __typename: 'Annotation',
          identifier: annotationIdentifier,
        })!

        // Read the annotation pagination
        const annotationPagination =
          errorPreventiveFragmentRead<AnnotationCommentCountFragment>(apollo, {
            fragment: AnnotationCommentCountFragmentDoc,
            id: annotationCacheId,
          })

        if (!annotationPagination) {
          // If the fragment doesn't exist we don't update :)
          return
        }

        const updatePagination = produce(
          annotationPagination,
          ({ comments }) => {
            comments.meta.totalCount = comments.meta.totalCount + 1
          }
        )

        cache.writeFragment({
          fragment: AnnotationCommentCountFragmentDoc,
          id: annotationCacheId,
          data: updatePagination,
        })
      },
      optimisticResponse: variables => {
        if (!hasOptimisticResponseVariables(variables)) {
          throw new Error(
            'Missing variables in optimistic update (`createAnnotationComment` mutation)'
          )
        }

        const { body, identifier } = variables

        const user = createInitialUserQuery(apollo)

        const comment = createCommentFragment(body, user.me, commentIdentifier)
        const currentSubject = getOptimisticCurrentSubject(apollo, identifier)

        return {
          __typename: 'RootMutationType',
          createAnnotationReply: {
            __typename: 'CreateAnnotationReplyResponse',
            comment: {
              ...comment,
              annotation: {
                __typename: 'Annotation',
                subscriptionStatus: 'ON',
                latestCommentCreatedAt: comment.createdAt,
                identifier,

                currentSubject,

                /* We don't really need to update the share in a optimized way */
                share: {
                  __typename: 'Share',
                  identifier: 'some-fake-identifier',
                  subscriptionStatus: 'PARTIAL',
                },
              },
            },
          },
        }
      },
    })
  }
}

export default useCreateAnnotationComment
