import { useCallback, useMemo } from 'react'

import { STEP_ID_SORTED_BY_PRIORITY } from './constants'
import {
  FirstStepsItem,
  FirstStepsStepId,
  StepVisibilityVariables,
} from './types'
import { isStepVisible, stepPropertiesHooksByStepId } from './steps'
import { useWorkspaceSettings } from '../../operations'
import { useCurrentWorkspaceInPrivateWorkspaceRoute } from '../../hooks'
import { useUserProfile } from '@sketch/modules-common'
import { useCanSubscribeViaStripe } from '../../utils'

/**
 * Get all the first steps we want to show to the user, by order of priority.
 */
export function useFirstSteps() {
  const isStepVisibleFn = useStepVisibilityFilter()
  return useMemo((): FirstStepsItem[] => {
    const visibleStepIdList = STEP_ID_SORTED_BY_PRIORITY.filter(isStepVisibleFn)
    return visibleStepIdList.map(stepId => {
      const useGetPropsHook = stepPropertiesHooksByStepId[stepId]

      return {
        stepId,
        useGetPropsHook,
      }
    })
  }, [isStepVisibleFn])
}

/**
 * Returns a function we can use to filter all the step ids to only keep the ones that should be visible.
 */
export function useStepVisibilityFilter() {
  const { data: userProfile } = useUserProfile()
  const workspace = useCurrentWorkspaceInPrivateWorkspaceRoute()

  const { data: workspaceSettings, loading: isLoadingWorkspaceSettings } =
    useWorkspaceSettings(workspace)

  const { canSubscribeStripe } = useCanSubscribeViaStripe(
    workspaceSettings?.workspace?.identifier!,
    workspaceSettings?.workspace?.customer?.identifier
  )

  return useCallback(
    (stepId: FirstStepsStepId) => {
      const availableSeats =
        workspaceSettings.workspace?.customer?.billing?.seats.availableSeats

      const visibilityVariables: StepVisibilityVariables = {
        isVerified: Boolean(userProfile?.me?.isVerified),
        isMfaEnabled: userProfile?.me?.mfaState === 'ENABLED',
        isAdmin: workspace?.userRole === 'ADMIN',
        isEditor: Boolean(workspace?.userCanEdit),
        hasSubscription: Boolean(workspace.customer),
        isEducationWorkspace: workspace.type === 'STUDENT',
        isPersonalWorkspace: workspace.type === 'PERSONAL',
        isSsoWorkspace:
          isLoadingWorkspaceSettings || Boolean(workspaceSettings.sso),
        availableSeats: availableSeats ?? 0,
        canSubscribeStripe,
      }

      return isStepVisible(stepId, visibilityVariables)
    },
    [
      isLoadingWorkspaceSettings,
      userProfile,
      workspace,
      workspaceSettings,
      canSubscribeStripe,
    ]
  )
}
