import React from 'react'
import { roundWithLocale } from '@sketch/utils'

import { AttributeList, FullCopyAttribute, MiniHeader } from '../../components'

import { LayoutSettings as LayoutSettingsType } from '../../../../../../inspector'
import { pluralize } from '@sketch/components'

const renderColumns = ({
  totalWidth,
  horizontalOffset,
  numberOfColumns,
  guttersOutside,
  gutterWidth,
  columnWidth,
  drawVertical,
}: Partial<LayoutSettingsType>) => {
  const hasColumnInfo =
    totalWidth ||
    horizontalOffset ||
    numberOfColumns ||
    guttersOutside === false ||
    guttersOutside === true
  if (!hasColumnInfo) return null

  return (
    <>
      {drawVertical && (
        <>
          <MiniHeader>Columns</MiniHeader>

          {totalWidth !== undefined ? (
            <FullCopyAttribute
              label="Total Width"
              copyValue={`${totalWidth}px`}
              value={`${roundWithLocale(totalWidth, 2)}px`}
            />
          ) : null}

          {horizontalOffset !== undefined ? (
            <FullCopyAttribute
              label="Offset"
              copyValue={`${horizontalOffset}px`}
              value={`${roundWithLocale(horizontalOffset, 2)}px`}
            />
          ) : null}

          {!isNaN(numberOfColumns as number) ? (
            <FullCopyAttribute
              label="Number"
              value={`${String(numberOfColumns)} ${pluralize('Column', 'Columns', numberOfColumns || 0)}`}
            />
          ) : null}

          {guttersOutside === true || guttersOutside === false ? (
            <FullCopyAttribute
              label="Gutter Outside"
              value={guttersOutside ? 'Yes' : 'No'}
            />
          ) : null}
        </>
      )}

      {drawVertical && (
        <>
          {gutterWidth !== undefined ? (
            <FullCopyAttribute
              label="Gutter Width"
              copyValue={`${gutterWidth}px`}
              value={`${roundWithLocale(gutterWidth, 2)}px`}
            />
          ) : null}
          {columnWidth !== undefined ? (
            <FullCopyAttribute
              label="Column Width"
              copyValue={`${columnWidth}px`}
              value={`${roundWithLocale(columnWidth, 2)}px`}
            />
          ) : null}
        </>
      )}
    </>
  )
}

export const renderRows = ({
  gutterHeight,
  rowHeightMultiplication,
  drawHorizontal,
}: Partial<LayoutSettingsType>) => {
  if (!drawHorizontal) {
    return null
  }
  const hasRows = gutterHeight || rowHeightMultiplication
  const rowHeight =
    rowHeightMultiplication && gutterHeight
      ? rowHeightMultiplication * gutterHeight
      : undefined
  if (!hasRows) return null

  return (
    <>
      <MiniHeader>Rows</MiniHeader>
      <AttributeList>
        {gutterHeight !== undefined ? (
          <FullCopyAttribute
            label="Gutter Height"
            copyValue={`${gutterHeight}px`}
            value={`${roundWithLocale(gutterHeight, 2)}px`}
          />
        ) : null}
        {rowHeight !== undefined ? (
          <FullCopyAttribute
            label="Row Height"
            copyValue={`${rowHeightMultiplication}`}
            value={`${rowHeightMultiplication} x Gutter Height`}
          />
        ) : null}
      </AttributeList>
    </>
  )
}

export const LayoutSettings = ({
  layout,
}: {
  layout?: Partial<LayoutSettingsType>
}) => {
  if (!layout || layout.isEnabled === false) return null
  const columns = renderColumns(layout)
  const rows = layout.drawHorizontal ? renderRows(layout) : null
  if (!columns && !rows) return null

  return (
    <>
      {columns}
      {rows}
    </>
  )
}
