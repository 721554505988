import { useCallback } from 'react'

import { getWorkspaceUserRolePermissions } from '../../utils'

import {
  useGetWorkspaceSsoSettingsQuery,
  useGetWorkspaceSettingsQuery,
  useGetCustomerQuery,
  GetWorkspaceSettingsQuery,
  GetCustomerQuery,
  GetWorkspaceSsoSettingsQuery,
  WorkspaceMinimalFragment,
} from '@sketch/gql-types'

export interface WorkspaceSettings {
  workspace?: GetWorkspaceSettingsQuery['workspace']
  customer?: GetCustomerQuery['customer']
  sso?: GetWorkspaceSsoSettingsQuery['workspace']['customer']
}

export const useWorkspaceSettings = (workspace: WorkspaceMinimalFragment) => {
  const identifier = workspace.identifier

  const { isAdmin, isFinance, isPartner, canManageBillingSetting } =
    getWorkspaceUserRolePermissions(workspace)

  // Do not include shares for finance role
  const workspaceSettingsQuery = useGetWorkspaceSettingsQuery({
    variables: { identifier, includeShares: !isFinance && !isPartner },
    // Always request this data to keep permissions up-to-date and
    // avoid UI flickering.
    // By having network-only, we make sure useGetCustomerQuery is not called
    // since isAdmin is always undefined when the component loads.
    // This is useful to prevent requesting customer data when the user role is
    // outdated avoiding unauthorized error.
    fetchPolicy: 'network-only',
  })

  const { customer } = workspaceSettingsQuery.data?.workspace || {}

  const shouldLoadWorkspaceCustomer =
    canManageBillingSetting && customer?.identifier

  const shouldLoadWorkspaceSSO = isAdmin && customer?.ssoShortName

  const workspaceCustomer = useGetCustomerQuery({
    variables: { customerId: customer?.identifier!, isNewPricing: true },
    fetchPolicy: 'cache-and-network',
    skip: !shouldLoadWorkspaceCustomer,
  })

  const workspaceSsoConfig = useGetWorkspaceSsoSettingsQuery({
    variables: { identifier },
    fetchPolicy: 'cache-and-network',
    skip: !shouldLoadWorkspaceSSO,
  })

  const refetch = useCallback(async () => {
    shouldLoadWorkspaceCustomer && (await workspaceCustomer.refetch())
    await workspaceSettingsQuery.refetch()
  }, [shouldLoadWorkspaceCustomer, workspaceCustomer, workspaceSettingsQuery])

  const loading =
    workspaceSettingsQuery.loading ||
    workspaceCustomer.loading ||
    workspaceSsoConfig.loading

  const data: WorkspaceSettings = {
    workspace: workspaceSettingsQuery.data?.workspace,
    customer: workspaceCustomer.data?.customer,
    sso: workspaceSsoConfig?.data?.workspace.customer,
  }

  // We need to know updating state - i.e. a state where any of the queries is refetching data
  // while we do have data that we can already show.
  // In this case, we don't need to render big snipper for the whole page.
  const updating =
    loading &&
    !!data.workspace &&
    (shouldLoadWorkspaceCustomer ? !!data.customer : true) &&
    (shouldLoadWorkspaceSSO ? !!data.sso : true)

  const error =
    workspaceSettingsQuery.error ||
    workspaceCustomer.error ||
    workspaceSsoConfig.error

  return {
    data,
    loading,
    updating,
    error,
    refetch,
  }
}
