import React, { useState } from 'react'
import { ConfirmationDialog, ModalInjectedProps } from '@sketch/components'
import { ShareVersionDescription } from '../../components/ShareVersionDescription'
import { useUpdateDescription } from '../../hooks/useUpdateDescription'

export interface VersionDescriptionModalProps extends ModalInjectedProps {
  versionIdentifier: string
  shareIdentifier: string
  isStarred: boolean
  description: string
}

const baseUpdateButtonText = 'Save Description'
const titleText = 'Edit Description'
const deleteButtonText = 'Delete Description'

export const VersionDescriptionModal: React.FC<
  VersionDescriptionModalProps
> = props => {
  const {
    isModalOpen,
    hideModal,
    versionIdentifier,
    shareIdentifier,
    description,
    isStarred,
  } = props

  const [newDescription, setNewDescription] = useState(description)

  const { updateVersionDescription, versionDescriptionLoading } =
    useUpdateDescription({
      versionIdentifier,
      shareIdentifier,
      onComplete: () => {
        hideModal()
      },
    })

  const onConfirm = () => {
    updateVersionDescription(newDescription)
  }

  const descriptionNotTouched = description === newDescription
  const showDeleteButton = !descriptionNotTouched && newDescription === ''
  const updateButtonText = showDeleteButton
    ? deleteButtonText
    : baseUpdateButtonText
  const confirmButtonVariant = showDeleteButton ? 'negative' : 'primary'

  return (
    <ConfirmationDialog
      data-testid="update-version-description-modal"
      title={titleText}
      hideModal={hideModal}
      isModalOpen={isModalOpen}
      cancelButton={{ disabled: versionDescriptionLoading, text: 'Cancel' }}
      confirmButton={{
        variant: confirmButtonVariant,
        text: updateButtonText,
        loading: versionDescriptionLoading,
        disabled: versionDescriptionLoading || descriptionNotTouched,
      }}
      onConfirm={onConfirm}
    >
      <ShareVersionDescription
        description={description}
        handleOnChange={setNewDescription}
        isStarred={isStarred}
      />
    </ConfirmationDialog>
  )
}
