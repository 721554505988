import React from 'react'

import {
  Dropdown,
  useResponsiveDropdown,
  MenuIconButton,
} from '@sketch/components'

import {
  useHideAnnotationDots,
  useIsAnnotationContextAvailable,
} from '../../../../annotations/hooks'

import { ReactComponent as DotsIcon } from '@sketch/icons/dots-3-horizontal-24'
import { AnnotationDropdownActions } from '../../../../annotations/components'
import { useFlag } from '@sketch/modules-common'
import {
  ArtboardDetailInfoFragment,
  FrameDetailInfoFragment,
} from '@sketch/gql-types'

interface DetailDropdownProps {
  shareIdentifier: string
  frameGroup: ArtboardDetailInfoFragment | FrameDetailInfoFragment
  subscriptionStatus: string
}

const DetailDropdown = (props: DetailDropdownProps) => {
  const isFramesWebOn = useFlag('frames-web')
  const [hideAnnotations] = useHideAnnotationDots() || []

  return (
    <>
      <Dropdown.Header>{isFramesWebOn ? 'Frame' : 'Artboard'}</Dropdown.Header>

      {hideAnnotations !== undefined && (
        <AnnotationDropdownActions
          shareIdentifier={props.shareIdentifier}
          subscriptionStatus={props.subscriptionStatus}
          frameGroup={props.frameGroup}
        />
      )}
    </>
  )
}

export const FrameGroupDetailSettingsDropdown = (
  props: DetailDropdownProps
) => {
  const isFramesWebOn = useFlag('frames-web')
  const isAnnotationContextAvailable = useIsAnnotationContextAvailable()

  const [dropdown, buttonProps] = useResponsiveDropdown({
    dropdown: DetailDropdown,
    dropdownProps: props,
    placement: 'bottom-end',
  })

  if (!isAnnotationContextAvailable) {
    return null
  }

  return (
    <>
      <MenuIconButton data-testid="artboard-settings-toggle" {...buttonProps}>
        <DotsIcon role="img" />
        <span className="sr-only">
          {isFramesWebOn ? 'Frame' : 'Artboard'} Settings
        </span>
      </MenuIconButton>
      {dropdown}
    </>
  )
}
