import { useToast } from '@sketch/toasts'
import { useUpdateWorkspaceCustomerSsoIdentityProviderConfigMutation } from '@sketch/gql-types'

export const useCustomerUpdateIdentityProviderConfig = () => {
  const { showToast } = useToast()
  const [updateIdentityProviderConfig] =
    useUpdateWorkspaceCustomerSsoIdentityProviderConfigMutation({
      onCompleted: () => {
        showToast('SSO configuration successfully saved', 'positive')
      },
      onError: 'show-toast',
    })

  return {
    uploadIdentityProviderConfig: async (
      customerId: string,
      file?: File
    ): Promise<void> => {
      await updateIdentityProviderConfig({
        variables: {
          customerId,
          metadata: file,
        },
      })
    },
  }
}
