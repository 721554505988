import { useCallback, useEffect } from 'react'
import { useKey } from 'react-use'
import { usePrototypeContext } from '../../hooks'
import { usePrototypeState } from '@sketch-hq/sketch-web-renderer'

export function useExitFullscreenButton() {
  const { isFullscreen, exitFullscreen, toggleOffExitFullscreenButton } =
    usePrototypeContext()

  const { currentArtboardId } = usePrototypeState()

  const onExitFullscreenButtonClick = useCallback(() => {
    toggleOffExitFullscreenButton()
    exitFullscreen()
  }, [exitFullscreen, toggleOffExitFullscreenButton])

  // Hide the exit fullscreen button on any navigation within the prototype
  useEffect(() => {
    toggleOffExitFullscreenButton()
  }, [currentArtboardId, toggleOffExitFullscreenButton])

  // Pressing escape to exit native fullscreen is default browser behaviour, but
  // we add this key handler as an escape hatch - in case a user has entered the
  // pseudo fullscreen mode with a hidden header and has no visible UI to escape it
  useKey('Escape', () => isFullscreen && exitFullscreen(), {}, [isFullscreen])

  return {
    onExitFullscreenButtonClick,
  } as const
}
