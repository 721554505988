import { useEventDispatch } from '@sketch/utils'

import {
  invalidateCollectionShares,
  invalidateProjectCollections,
  invalidateProjectShares,
  removeCollectionFromProjectCollectionsForSelect,
} from './utils'

// GraphQL
import {
  useMoveAllCollectionSharesMutation,
  MoveAllCollectionSharesMutation,
  MoveAllCollectionSharesMutationVariables,
  MutationTuple,
} from '@sketch/gql-types'

interface UseMoveAllCollectionSharesProps {
  workspaceIdentifier: string
  onCompleted?: (data: MoveAllCollectionSharesMutation) => void
}

export const useMoveAllCollectionShares = ({
  workspaceIdentifier,
  onCompleted,
}: UseMoveAllCollectionSharesProps): MutationTuple<
  MoveAllCollectionSharesMutation,
  MoveAllCollectionSharesMutationVariables
> => {
  const dispatchSharesRefresh = useEventDispatch('workspaceShareRefresh')
  const [mutation, opts] = useMoveAllCollectionSharesMutation({
    onCompleted,
    onError: 'show-toast',
  })

  const mutationWithCacheUpdate: typeof mutation = (options = {}) => {
    const { originIdentifier, removeCollection = false } =
      options.variables ?? {}

    return mutation({
      ...options,
      update: (cache, { data }) => {
        if (!data || !originIdentifier) {
          return
        }

        const { targetCollection, projectIdentifier } =
          data.moveAllCollectionShares

        // Invalidate `collection.shares` for the origin collection
        invalidateCollectionShares({
          cache,
          collectionIdentifier: originIdentifier,
        })

        if (targetCollection) {
          // If the shares are being moved to a new collection, then invalidate
          // `collection.shares` for the target collection
          invalidateCollectionShares({
            cache,
            collectionIdentifier: targetCollection.identifier,
          })
        } else {
          // If the shares are being moved back to the project root, then
          // invalidate `project.shares` for the project collection
          invalidateProjectShares({ cache, projectIdentifier })
        }

        // Invalidate `project.collections` since the previews and share counts
        // are now out of date
        invalidateProjectCollections({
          cache,
          projectIdentifier,
        })

        if (removeCollection) {
          // If the collection is being removed then make sure it is removed
          // from any dropdowns
          removeCollectionFromProjectCollectionsForSelect({
            cache,
            projectIdentifier,
            collectionIdentifier: originIdentifier,
          })
        }

        // Force a refresh of the project view if the user is currently viewing
        // it. Since the user can delete a collection from the project view, we
        // must force a refresh so that the list of shares is refetched after the
        // cache invalidations
        dispatchSharesRefresh({
          workspaceIdentifier,
          projectIdentifier,
        })
      },
    })
  }

  return [mutationWithCacheUpdate, opts]
}
