import { useCallback } from 'react'
import {
  useTransferStorageItemMutation,
  TransferStorageItemMutationVariables,
  TransferStorageItemMutation,
  ProjectFragment,
  WorkspaceMinimalFragment,
  StorageItemFragment,
} from '@sketch/gql-types'
import { castError, useEventDispatch } from '@sketch/utils'
import { MutationFunctionOptions } from 'react-apollo'
import { useToast } from '@sketch/toasts'
import { showMovedSuccessToast } from './MovedToasts'
import { updateCacheAfterStorageItemTransferred } from './updateCacheAfterStorageItemTransferred'

export interface StorageItemTransferredEvent {
  location: StorageItemTransferLocation
  item: Pick<StorageItemFragment, '__typename' | 'identifier' | 'name'>
}

declare module '@sketch/utils' {
  export interface EventsMap {
    storageItemTransferred: StorageItemTransferredEvent
  }
}

export interface StorageItemLocation<
  ExtraFields extends keyof (WorkspaceMinimalFragment | ProjectFragment),
> {
  project?: Pick<ProjectFragment, 'identifier' | '__typename' | ExtraFields>
  workspace: Pick<
    WorkspaceMinimalFragment,
    'identifier' | '__typename' | ExtraFields
  >
}

export interface StorageItemTransferLocation<
  ExtraFields extends keyof (WorkspaceMinimalFragment | ProjectFragment) =
    | 'identifier'
    | '__typename',
> {
  source: StorageItemLocation<ExtraFields>
  destination: StorageItemLocation<ExtraFields>
}

export interface UseTransferStorageItemProps {
  onError: (error: string) => void
}

export interface TransferStorageItemArgs
  extends OmitSafe<
    MutationFunctionOptions<
      TransferStorageItemMutation,
      TransferStorageItemMutationVariables
    >,
    'variables'
  > {
  onCompleted?: (
    data: TransferStorageItemMutation,
    location: StorageItemTransferLocation<'name'>
  ) => void
  location: StorageItemTransferLocation<'name'>
  item: Pick<StorageItemFragment, '__typename' | 'identifier' | 'name'>
}

export function useTransferStorageItem(props: UseTransferStorageItemProps) {
  const { onError } = props

  const { showToast } = useToast()

  const [mutateFn, result] = useTransferStorageItemMutation({
    onError: () => {
      // do nothing, as we handle errors in the `mutate` function
    },
  })

  const dispatchItemTransferred = useEventDispatch('storageItemTransferred')

  const mutate = useCallback(
    async (args: TransferStorageItemArgs) => {
      try {
        const { onCompleted, update, location, item, ...rest } = args
        const result = await mutateFn({
          ...rest,
          variables: {
            identifier: item.identifier,
            type: item.__typename === 'Share' ? 'SHARE' : 'PROJECT',
            parentProjectId: location.destination.project?.identifier,
            workspaceId: location.destination.workspace.identifier,
          },
          update: (cache, mutationResult) => {
            if (
              mutationResult.data &&
              (mutationResult.errors?.length || 0) === 0
            ) {
              updateCacheAfterStorageItemTransferred({
                cache,
                location,
                item,
              })
            }
            update?.(cache, mutationResult)
          },
        })

        const errorMessage =
          result.errors?.map(error => error.message).join(', ') ||
          result.error?.message

        if (errorMessage || !result.data) {
          onError(errorMessage || 'An error occurred')
          return
        }

        showMovedSuccessToast({ location, showToast, item })

        onCompleted && onCompleted(result.data, location)
        dispatchItemTransferred({ location, item })
      } catch (error) {
        const message = castError(error).message
        onError(message || 'An error occurred')
      }
    },
    [dispatchItemTransferred, mutateFn, onError, showToast]
  )

  return [mutate, result] as const
}
