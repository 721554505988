import React, { useEffect, useRef } from 'react'
import { Separator } from './CommentNewSeparator.styles'

interface CommentNewSeparatorProps {
  className?: string
  shouldScrollOnMount?: boolean
}

const CommentNewSeparator: React.FC<CommentNewSeparatorProps> = ({
  className,
  shouldScrollOnMount,
}) => {
  const separatorRef = useRef<HTMLSpanElement>(null)

  useEffect(() => {
    const separatorElement = separatorRef.current

    if (separatorElement && shouldScrollOnMount) {
      // We check if the "NEW" badge is visible
      const observer = new IntersectionObserver(
        (entries, observer) => {
          if (entries[0].intersectionRatio <= 0) {
            // If not visible, we can scrollIntoView

            separatorElement.scrollIntoView()
          }

          observer.disconnect()
        },
        {
          threshold: 0,
        }
      )

      observer.observe(separatorElement)
    }
  }, [shouldScrollOnMount])

  return (
    <Separator ref={separatorRef} className={className}>
      NEW
    </Separator>
  )
}

export default CommentNewSeparator
