import { SketchElement } from '../types'
import { isTopLevelFrameElement } from './elements'

export function getWebRenderCursor(
  isPlacingDraftAnnotation: boolean | undefined,
  hoveredElement: SketchElement | null,
  isAnnotationMovingOnAnOldVersion: boolean
) {
  if (isAnnotationMovingOnAnOldVersion) {
    return 'not-allowed'
  }

  if (isPlacingDraftAnnotation) {
    return 'copy'
  }

  if (hoveredElement && !isTopLevelFrameElement(hoveredElement)) {
    return 'auto'
  }

  return undefined
}
