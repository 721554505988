import React from 'react'
import { Redirect, useParams } from 'react-router'
import { LocationDescriptorObject } from 'history'
import { RouteParams } from '@sketch/modules-common'
import { useVersioning } from '../../versioning'

/**
 * Redirects from the PROTOTYPE_LEGACY_ROUTE_2 route to the prototype player.
 */
export function PrototypeLegacyRoute2Redirect() {
  const { artboardUUID, shareID } =
    useParams<RouteParams<'PROTOTYPE_LEGACY_ROUTE_2'>>()
  const { getPathname } = useVersioning()

  const to: LocationDescriptorObject = {
    pathname: getPathname({
      routeKey: 'PROTOTYPE_PLAYER',
      routeParams: {
        shareID,
        prototypeArtboardUUID: artboardUUID,
        currentArtboardUUID: artboardUUID,
      },
    }),
  }

  return <Redirect to={to} push={false} />
}
