import styled from 'styled-components'
import { Button } from '@sketch/components'
import { ReactComponent as ArrowTop } from '@sketch/icons/arrow-right-circle-24'

export const ScrollToTopButtonWrapper = styled.div<{ isVisible: boolean }>`
  position: fixed;
  right: 24px;
  bottom: 24px;
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  transition: opacity 0.2s ease-in;
  pointer-events: ${({ isVisible }) => (isVisible ? 'auto' : 'none')};
  z-index: ${({ theme }) => theme.zIndex[3]};
`

export const ScrollToTopButton = styled(Button)`
  width: 40px;
  height: 40px;
  transform: rotate(270deg);
`

export const ArrowIcon = styled(ArrowTop)`
  color: ${({ theme }) => theme.colors.sketch.A};
`
