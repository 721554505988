import React from 'react'

import { Dropdown, Link, useModalContext } from '@sketch/components'
import { LaunchSketchAlert, routes } from '@sketch/modules-common'

import CopyLink from '../../../../../shares/components/ShareActionsDropdown/CopyLink'
import styled from 'styled-components'

const StyledLink = styled(Link)`
  font-weight: normal;
`

export interface LargeDocumentItemDropdownProps {
  identifier: string
  showOpenInSketchItem: boolean
}

export const LargeDocumentItemDropdown: React.FC<
  LargeDocumentItemDropdownProps
> = props => {
  const { identifier, showOpenInSketchItem } = props
  const { showModal } = useModalContext()
  return (
    <>
      <Dropdown.Item>
        <StyledLink
          variant="tertiary"
          target="_blank"
          to={routes.SHARE_VIEW.create({ shareID: identifier })}
        >
          View Document ↗
        </StyledLink>
      </Dropdown.Item>
      <CopyLink shareIdentifier={identifier} />
      {showOpenInSketchItem && (
        <Dropdown.Item
          onClick={() => {
            showModal(LaunchSketchAlert, {
              action: 'openInSketch',
              shareId: identifier,
            })
          }}
        >
          Open in Sketch
        </Dropdown.Item>
      )}
    </>
  )
}

export default LargeDocumentItemDropdown
