import React, { useContext } from 'react'
import {
  ArtboardDetailInfoFragment,
  GetArtboardsForDocumentDocument,
  GetArtboardsForDocumentQuery,
  GetFramesForDocumentDocument,
  GetFramesForDocumentQuery,
  useQuery,
} from '@sketch/gql-types'
import { useParams } from 'react-router-dom'
import { OperationVariables } from 'apollo-client'
import get from 'lodash.get'
import { useSearchComponentsContext } from '../../ComponentsWebView/context/SearchComponentsContext'
import { ResponsiveValues } from '@sketch/global-styles'
import { ShareVersionContext } from '../../../versioning'
import {
  RetryQueryProps,
  useQueryRetry,
  RouteParams,
  useFlag,
} from '@sketch/modules-common'
import { FrameGroupsList } from '../FrameGroupsList'

type RetryIfBuilder = (
  path: string[]
) => RetryQueryProps<any, OperationVariables>['retryIf']

const buildRetryIf: RetryIfBuilder =
  path =>
  ({ data, error }) => {
    if (error) return true

    const frames = get(data, path, []) as ArtboardDetailInfoFragment[]
    const files = frames.map(a => a.files).flat()

    return files.some(file => !file?.url)
  }

interface DocumentPageProps {
  search: string
  columns: ResponsiveValues<number>
}

/**
 * Handles a list of frames scoped to a specific share document
 * grouped by page.
 */
export const DocumentFrames = ({ search, columns }: DocumentPageProps) => {
  const isFramesWebOn = useFlag('frames-web')

  const entriesPath = [
    'share',
    'version',
    'document',
    isFramesWebOn ? 'frames' : 'artboards',
    'entries',
  ]
  const afterPath = [
    'share',
    'version',
    'document',
    isFramesWebOn ? 'frames' : 'artboards',
    'meta',
    'after',
  ]

  const { versionShortId } = useContext(ShareVersionContext)!
  const { shareID } = useParams<RouteParams<'SHARE_VIEW'>>()
  const { search: searchComponent } = useSearchComponentsContext()

  const framesQuery = useQuery<
    GetFramesForDocumentQuery | GetArtboardsForDocumentQuery
  >(
    isFramesWebOn
      ? GetFramesForDocumentDocument
      : GetArtboardsForDocumentDocument,
    {
      variables: {
        identifier: shareID,
        versionShortId,
        search: searchComponent,
      },
    }
  )

  const response = useQueryRetry(framesQuery, {
    retryIf: buildRetryIf(entriesPath),
  })

  const responseProps = {
    fetchMore: response.fetchMore,
    afterPath,
    entriesPath,
    data: response.data,
    loading: response.loading,
    error: response.error,
    columns,
    filterValue: search,
  }

  return <FrameGroupsList {...responseProps} />
}
