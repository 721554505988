import React, { useState } from 'react'
import { ApolloError } from 'apollo-client'

import {
  Button,
  ModalInjectedProps,
  Tabs,
  Tab,
  TabList,
  TabPanel,
  pluralize,
  TableComponents,
  FlatButton,
  Text,
  Skeleton,
  GenericError,
  LoadPageSeparator,
  handleFetchMore,
} from '@sketch/components'
import { dataIdFromObject } from '@sketch/graphql-cache'
import {
  getRenderStatus,
  preRenderfarmStatus,
  removeFromPaginated,
} from '@sketch/modules-common'

import {
  WorkspacePermissionGroupFragment,
  useGetWorkspacePermissionGroupSharesQuery,
  useGetWorkspacePermissionGroupProjectsQuery,
  useRemovePermissionGroupToShareProjectMutation,
} from '@sketch/gql-types'

import { thumbnailForPreviewFile } from '../../../shares/utils'
import { renderImage } from '../../../shares/components/DocumentItem'

import {
  PermissionGroup,
  Divider,
  Table,
  Filter,
  MinimumTableCell,
  ThumbnailSkeleton,
  TableWrapper,
  ImageWrapper,
  ProjectIcon,
  EmptyDescriptionWrapper,
  Modal,
  LoadingPlaceholder,
} from './PermissionGroupMembershipsModal.styles'
import { privacyIcon } from '../../../projects/components/ProjectListItem/ProjectListItem.utils'
import { useToast } from '@sketch/toasts'

const PAGE_SIZE = 20
const TABLE_HEADER = [
  { label: 'Thumbnail', customCell: MinimumTableCell },
  { label: 'Item' },
  { label: 'Remove Permission' },
]

const EMPTY_STATE_PROJECT_COPY = {
  title: 'No projects added yet',
  description:
    'To add a project, open its Share options and search for a group.',
}

const EMPTY_STATE_DOCUMENT_COPY = {
  title: 'No documents added yet',
  description:
    'To add a document, open its Share options and search for a group.',
}

const EMPTY_STATE_SEARCH_PROJECT_COPY = {
  title: 'No projects matched your filter',
}

const EMPTY_STATE_SEARCH_DOCUMENT_COPY = {
  title: 'No documents matched your filter',
}

const renderPlaceholderNode = (children?: React.ReactElement) => (
  <TableComponents.TableRow>
    <MinimumTableCell>
      <ThumbnailSkeleton />
    </MinimumTableCell>

    <TableComponents.TableCell>
      <Skeleton width="200px" height="20px" />
      {children}
    </TableComponents.TableCell>

    <MinimumTableCell />
  </TableComponents.TableRow>
)

interface RemoveButtonProps {
  relationIdentifier: string
  formatRemovalToastMessage: () => string
}

const RemoveButton = (props: RemoveButtonProps) => {
  const { relationIdentifier, formatRemovalToastMessage } = props
  const { showToast } = useToast()

  const [removePermission, { loading }] =
    useRemovePermissionGroupToShareProjectMutation({
      onError: 'show-toast',
      update: (cache, { data }) => {
        if (data?.removePermissionGroupRelation?.project) {
          removeFromPaginated(
            cache,
            {
              __typename: 'PermissionGroupRelationProject',
              identifier: relationIdentifier,
            },
            () => true
          )
        } else {
          removeFromPaginated(
            cache,
            {
              __typename: 'PermissionGroupRelationShare',
              identifier: relationIdentifier,
            },
            () => true
          )
        }
      },
      onCompleted: () => {
        showToast(formatRemovalToastMessage(), 'positive')
      },
    })

  if (loading) {
    return <LoadingPlaceholder />
  }

  return (
    <FlatButton
      variant="negative"
      onClick={() =>
        removePermission({ variables: { input: { relationIdentifier } } })
      }
    >
      Remove
    </FlatButton>
  )
}

interface ItemsTableProps<T extends {}> {
  search?: string
  setSearch: (search: string) => void
  loading: boolean
  error?: ApolloError
  items: T[]
  renderItem: (item: T | 'load-page' | 'placeholder') => React.ReactElement
  totalCount: number
  emptyState:
    | typeof EMPTY_STATE_DOCUMENT_COPY
    | typeof EMPTY_STATE_PROJECT_COPY
    | typeof EMPTY_STATE_SEARCH_DOCUMENT_COPY
    | typeof EMPTY_STATE_SEARCH_PROJECT_COPY
}

const ItemsTable = <T extends {}>(props: ItemsTableProps<T>) => {
  const {
    search,
    setSearch,
    loading,
    error,
    items,
    renderItem,
    totalCount,
    emptyState,
  } = props

  let content
  if (loading) {
    content = (
      <Table
        header={TABLE_HEADER}
        items={Array.from({ length: 2 })}
        hideHeader
        renderItem={() => renderPlaceholderNode()}
      />
    )
  } else if (error) {
    content = <GenericError />
  } else if (items.length === 0) {
    content = (
      <GenericError
        icon={null}
        title={emptyState.title}
        description={
          'description' in emptyState ? (
            <EmptyDescriptionWrapper>
              {emptyState.description}
            </EmptyDescriptionWrapper>
          ) : null
        }
      />
    )
  } else {
    const itemsWithPlaceholders = [
      ...items,
      ...Array.from({
        length: Math.min(totalCount - items.length, PAGE_SIZE),
      }).map((_, index) =>
        index === 0 ? ('load-page' as const) : ('placeholder' as const)
      ),
    ]

    content = (
      <TableWrapper>
        <Table
          header={TABLE_HEADER}
          items={itemsWithPlaceholders}
          hideHeader
          renderItem={renderItem}
        />
      </TableWrapper>
    )
  }

  return (
    <>
      <Filter value={search} onChange={setSearch} />
      {content}
    </>
  )
}

interface ProjectTableProps {
  permissionGroupIdentifier: string
  permissionGroupName: string
  search?: string
  setSearch: (search: string) => void
}

const ProjectsTable = (props: ProjectTableProps) => {
  const { permissionGroupIdentifier, permissionGroupName, search, setSearch } =
    props

  const { data, loading, error, fetchMore } =
    useGetWorkspacePermissionGroupProjectsQuery({
      variables: { permissionGroupIdentifier, search },
      fetchPolicy: 'cache-and-network',
    })

  const projects = data?.permissionGroup.projects.entries || []
  const totalCount = data?.permissionGroup.projects.meta.totalCount || 0
  const after = data?.permissionGroup.projects.meta.after

  return (
    <ItemsTable
      search={search}
      setSearch={setSearch}
      items={projects}
      loading={loading}
      error={error}
      totalCount={totalCount}
      emptyState={
        search ? EMPTY_STATE_SEARCH_PROJECT_COPY : EMPTY_STATE_PROJECT_COPY
      }
      renderItem={item => {
        if (item === 'load-page') {
          const loadSeparator = after ? (
            <LoadPageSeparator
              key={after}
              loadNewPage={handleFetchMore(
                fetchMore,
                ['permissionGroup', 'projects', 'entries'],
                {
                  after,
                  dataIdFromObject,
                }
              )}
            />
          ) : undefined

          return renderPlaceholderNode(loadSeparator)
        } else if (item === 'placeholder') {
          return renderPlaceholderNode()
        }

        const { identifier, project } = item
        const { component: icon, label } = privacyIcon(project)

        return (
          <TableComponents.TableRow
            key={identifier}
            data-testId="permission-group-project-item"
          >
            <MinimumTableCell>
              <ImageWrapper>
                <ProjectIcon as={icon} aria-label={label} />
              </ImageWrapper>
            </MinimumTableCell>

            <TableComponents.TableCell>
              <Text textStyle="copy.primary.standard.E">{project.name}</Text>
            </TableComponents.TableCell>

            <MinimumTableCell>
              <RemoveButton
                relationIdentifier={identifier}
                formatRemovalToastMessage={() =>
                  `”${permissionGroupName}” group removed from “${item.project.name}”`
                }
              />
            </MinimumTableCell>
          </TableComponents.TableRow>
        )
      }}
    />
  )
}

const DocumentsTable = (props: ProjectTableProps) => {
  const { permissionGroupIdentifier, permissionGroupName, search, setSearch } =
    props

  const { data, loading, error, fetchMore } =
    useGetWorkspacePermissionGroupSharesQuery({
      variables: { permissionGroupIdentifier, search },
      fetchPolicy: 'cache-and-network',
    })

  const shares = data?.permissionGroup.shares.entries || []
  const totalCount = data?.permissionGroup.shares.meta.totalCount || 0
  const after = data?.permissionGroup.shares.meta.after

  return (
    <ItemsTable
      search={search}
      setSearch={setSearch}
      items={shares}
      loading={loading}
      error={error}
      totalCount={totalCount}
      emptyState={
        search ? EMPTY_STATE_SEARCH_DOCUMENT_COPY : EMPTY_STATE_DOCUMENT_COPY
      }
      renderItem={item => {
        if (item === 'load-page') {
          const loadSeparator = after ? (
            <LoadPageSeparator
              key={after}
              loadNewPage={handleFetchMore(
                fetchMore,
                ['permissionGroup', 'shares', 'entries'],
                {
                  after,
                  dataIdFromObject,
                }
              )}
            />
          ) : undefined

          return renderPlaceholderNode(loadSeparator)
        } else if (item === 'placeholder') {
          return renderPlaceholderNode()
        }

        const { identifier, share } = item
        const renderStatus = share.version?.document?.renderStatus
        const thumbnailSource = thumbnailForPreviewFile(
          share.version?.document?.previewFile
        )

        const normalizedRenderStatus = getRenderStatus(
          renderStatus ||
            preRenderfarmStatus(!!thumbnailSource, share.updatedAt!)
        )

        const { image, tooltipMessage } = renderImage(
          {
            name: share.name,
            renderStatus: normalizedRenderStatus,
            thumbnailSource,
          },
          'list'
        )

        return (
          <TableComponents.TableRow
            key={identifier}
            data-testId="permission-group-share-item"
          >
            <MinimumTableCell>
              <ImageWrapper title={tooltipMessage}>{image}</ImageWrapper>
            </MinimumTableCell>

            <TableComponents.TableCell>
              <Text textStyle="copy.primary.standard.E">{share.name}</Text>
            </TableComponents.TableCell>

            <MinimumTableCell>
              <RemoveButton
                relationIdentifier={identifier}
                formatRemovalToastMessage={() =>
                  `”${permissionGroupName}” group removed from “${item.share.name}”`
                }
              />
            </MinimumTableCell>
          </TableComponents.TableRow>
        )
      }}
    />
  )
}

interface PermissionGroupMembershipsModalProps extends ModalInjectedProps {
  permissionGroup: WorkspacePermissionGroupFragment
}

const PermissionGroupMembershipsModal = (
  props: PermissionGroupMembershipsModalProps
) => {
  const { permissionGroup, hideModal } = props

  const [search, setSearch] = useState('')
  const { data } = useGetWorkspacePermissionGroupSharesQuery({
    variables: {
      permissionGroupIdentifier: permissionGroup.identifier,
      // We are mirroring the search field from shares that is the default tab
      // So we can get the counters from there
      search: '',
    },
    fetchPolicy: 'cache-and-network',
  })

  const documentsCount = data?.permissionGroup.shares.meta.totalCount
  const projectsCount = data?.permissionGroup.projects.meta.totalCount

  return (
    <Modal onCancel={hideModal}>
      <Modal.Header>Edit Project and Document Access</Modal.Header>
      <Modal.Body>
        <PermissionGroup permissionGroup={permissionGroup} />
        <Divider />

        <Tabs>
          <TabList>
            <Tab>
              {documentsCount}{' '}
              {pluralize('Document', 'Documents', documentsCount || 0)}
            </Tab>
            <Tab>
              {projectsCount}{' '}
              {pluralize('Project', 'Projects', projectsCount || 0)}
            </Tab>
          </TabList>
          <TabPanel>
            <DocumentsTable
              permissionGroupIdentifier={permissionGroup.identifier}
              permissionGroupName={permissionGroup.name}
              search={search}
              setSearch={setSearch}
            />
          </TabPanel>
          <TabPanel>
            <ProjectsTable
              permissionGroupIdentifier={permissionGroup.identifier}
              permissionGroupName={permissionGroup.name}
              search={search}
              setSearch={setSearch}
            />
          </TabPanel>
        </Tabs>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={hideModal}>
          Done
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default PermissionGroupMembershipsModal
