import React from 'react'

import { Sidebar } from '../../../workspace/components/Sidebar'
import { useShareWorkspaceSidebar } from '../../hooks/useShareWorkspaceSidebar'

interface ShareWorkspaceSidebarProps {
  workspaceIdentifier: string
}

const ShareWorkspaceSidebar: React.FC<ShareWorkspaceSidebarProps> = ({
  workspaceIdentifier,
}) => {
  const { shouldRenderWorkspaceSidebar, workspace } =
    useShareWorkspaceSidebar(workspaceIdentifier)

  if (shouldRenderWorkspaceSidebar) {
    return <Sidebar workspace={workspace!} />
  }

  return null
}

export default ShareWorkspaceSidebar
