import React from 'react'
import { useLocalStorage } from 'react-use'
import { WorkspaceSettingsBillingUpsellModal } from './WorkspaceSettingsBillingUpsellModal'
import { WorkspaceSettingsUpsellModal } from './WorkspaceSettingsUpsellModal'

import { Banner, useModalContext } from '@sketch/components'
import { LearnMoreText, UpsellWrapper } from './WorkspaceSettingsUpsell.styles'

type WorkspaceSettingsUpsellBannerProps = {
  bannerText: string
  localStorageKey: string
}

type UpsellBannerProps = {
  setUpsellVisible: React.Dispatch<React.SetStateAction<boolean | undefined>>
  bannerText: string
  localStorageKey: string
}

const UpsellBanner: React.FC<UpsellBannerProps> = ({
  setUpsellVisible,
  bannerText,
  localStorageKey,
}) => {
  const { showModal } = useModalContext()

  const onDismiss = () => setUpsellVisible(false)
  const onLearnMore = () =>
    localStorageKey === 'upsell-business-people'
      ? showModal(WorkspaceSettingsUpsellModal)
      : showModal(WorkspaceSettingsBillingUpsellModal)

  return (
    <UpsellWrapper>
      <Banner type="upsell" onDismiss={onDismiss} dismissible>
        {bannerText}{' '}
        <LearnMoreText data-testid="learn-more" onClick={onLearnMore}>
          Learn More
        </LearnMoreText>
      </Banner>
    </UpsellWrapper>
  )
}

const WorkspaceSettingsUpsellBannerContainer: React.FC<
  WorkspaceSettingsUpsellBannerProps
> = ({ bannerText, localStorageKey }) => {
  const [upsellVisible, setUpsellVisible] = useLocalStorage(
    localStorageKey,
    true
  )

  if (!upsellVisible) return null

  return (
    <UpsellBanner
      setUpsellVisible={setUpsellVisible}
      bannerText={bannerText}
      localStorageKey={localStorageKey}
    />
  )
}

export { WorkspaceSettingsUpsellBannerContainer as WorkspaceSettingsUpsellBanner }
