import React, { useEffect, useRef, useState } from 'react'
import { Markdown } from '@sketch/components'

import {
  Description,
  DescriptionWrapper,
  PencilButton,
  Pencil,
  DescriptionForm,
  DescriptionPreview,
  DescriptionTextArea,
} from './EditableDescription.styles'

interface DescriptionInputProps {
  initialValue: string
  inputHeight?: number
  onCancel: () => void
  onEdit: (newDescription: string) => void
}

const DescriptionInput: React.FC<DescriptionInputProps> = ({
  initialValue,
  inputHeight,
  onCancel,
  onEdit,
}) => {
  const [value, setValue] = useState(initialValue)
  const textAreaRef = useRef<HTMLTextAreaElement>(null)

  useEffect(() => {
    textAreaRef.current?.select()
  }, [])

  const createSubmit = (form: HTMLFormElement | null) => {
    // remove multiple new lines
    setValue(value.replaceAll(/(\r\n|\r|\n){2,}/g, '$1\n'))
    form?.dispatchEvent(new Event('submit', { cancelable: true }))
  }

  return (
    <DescriptionForm
      height={inputHeight}
      onSubmit={e => {
        e.preventDefault()
        onEdit(value)
      }}
    >
      <DescriptionPreview>{value}</DescriptionPreview>
      <DescriptionTextArea
        onChange={e => setValue(e.target.value)}
        onBlur={e => {
          createSubmit(e.currentTarget.form)
        }}
        onKeyDown={e => {
          // Make the "Enter" key + command / ctrl submit the change instead of a new line
          if (e.key === 'Enter' && (e.ctrlKey || e.metaKey)) {
            e.preventDefault()
            createSubmit(e.currentTarget.form)
          }

          if (e.key === 'Escape') {
            e.preventDefault()
            onCancel()
          }
        }}
        ref={textAreaRef}
        value={value}
        height={inputHeight}
      />
    </DescriptionForm>
  )
}

interface EditableDescriptionProps {
  onSubmit: (newDescription: string) => void | Promise<void>
  description: string
}
export const EditableDescription: React.FC<EditableDescriptionProps> = ({
  onSubmit,
  description,
}) => {
  const [isEditing, setEditing] = useState(false)
  const [descriptionHeight, setDescriptionHeight] = useState<
    number | undefined
  >(undefined)
  const descriptionRef = useRef<HTMLSpanElement>(null)

  useEffect(() => {
    if (!descriptionRef?.current?.clientHeight) return

    setDescriptionHeight(descriptionRef.current.clientHeight ?? undefined)
  }, [descriptionRef])

  return (
    <DescriptionWrapper>
      {isEditing ? (
        <DescriptionInput
          inputHeight={descriptionHeight}
          initialValue={description}
          onCancel={() => setEditing(false)}
          onEdit={async newDescription => {
            if (newDescription !== description) {
              await onSubmit(newDescription)
            }

            setEditing(false)
            setTimeout(() => {
              descriptionRef.current?.focus()
            })
          }}
        />
      ) : (
        <Description ref={descriptionRef} aria-label={description}>
          <Markdown>{description}</Markdown>
        </Description>
      )}
      <PencilButton onClick={() => setEditing(true)} disabled={isEditing}>
        <span className="sr-only">Edit Description</span>
        <Pencil />
      </PencilButton>
    </DescriptionWrapper>
  )
}
