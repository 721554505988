import React from 'react'
import { useMeasurements } from './useMeasurements'
import { Container, VisibleSegments, Tooltip } from './TruncateSegments.styles'
import { splitSegments } from './splitSegments'

export interface SegmentProps {
  id: string
  name: string
  node: React.ReactNode
}

export type SegmentsMap = Map<string, SegmentProps>

export interface TruncateSegmentsProps {
  segments: SegmentsMap
  separator: { element: React.ReactNode; width: number }
  ellipsis: { element: React.ReactNode; width: number }
  renderTooltip: (segmentIds: string[]) => React.ReactNode
}

/**
 * TruncateSegments component is used to display a number of segments within limited width,
 * if there are too many segments to fit in the container, the component will truncate the segments
 * and display an ellipsis.
 *
 * This component is useful for scenarios like breadcrumbs.
 */
export function TruncateSegments(props: TruncateSegmentsProps) {
  const { segments, separator, ellipsis, renderTooltip } = props
  const {
    containerWidth,
    isMeasured,
    measuredSegments,
    onRootRef,
    measurementsContainer,
  } = useMeasurements(props)

  const fittedSegments = splitSegments({
    config: {
      containerWidth,
      ellipsisWidth: ellipsis.width,
      separatorWidth: separator.width,
    },
    segments: measuredSegments,
  })

  return (
    <Container ref={onRootRef}>
      {/* must be placed as a direct child of the root element */}
      {measurementsContainer}
      <VisibleSegments>
        {isMeasured &&
          fittedSegments.map((segment, index) => {
            if (segment.kind === 'separator')
              return (
                <React.Fragment key={index}>{separator.element}</React.Fragment>
              )

            if (segment.kind === 'ellipsis') {
              return (
                <Tooltip
                  key={index}
                  content={renderTooltip(segment.segments)}
                  placement="bottom"
                >
                  {ellipsis.element}
                </Tooltip>
              )
            } else {
              const { id } = segment
              const content = segments.get(id)
              if (!content) return null

              return (
                <span style={{ maxWidth: segment.width + 1 + 'px' }} key={id}>
                  {content.node}
                </span>
              )
            }
          })}
      </VisibleSegments>
    </Container>
  )
}
