import React, { useState, useEffect, useMemo } from 'react'
import * as Sentry from '@sentry/browser'
import json from 'react-syntax-highlighter/dist/cjs/languages/hljs/json'
import { useThemeContext } from '@sketch/global-styles'
import {
  Flex,
  Button,
  Modal,
  useModalContext,
  ModalInjectedProps,
  Breakpoint,
  Text,
  LoadingPlaceholder,
} from '@sketch/components'
import {
  SyntaxHighlighter,
  syntaxHighlighterThemeLight,
  syntaxHighlighterThemeDark,
  ModalStyled,
  SyntaxPanel,
  SyntaxPanelContent,
  StyledColorFanIcon as ColorFanIcon,
  DownloadLink,
} from './ExportDesignTokens.styles'
import { ExportFormPanel } from './ExportFormPanel'
// importing the PublicTokenExport type to help with type inference
// and ExportableComponentType otherwise typescript will freak out
// eslint-disable-next-line no-restricted-imports
import {
  PublicTokenExport,
  TokenExportFormat,
  TokenColorFormat,
  ExportableComponentType,
} from '@sketch/gql-types/expansive'
import {
  useGetShareDesignTokenExportQuery,
  useUpdateSharePublicTokenExportMutation,
} from '@sketch/gql-types'
import {
  DocumentComponentsStateExported,
  useTriggerAsyncComponentsIngestionForExportTokens,
} from '../../components/ComponentsStateContext'

SyntaxHighlighter.registerLanguage('json', json)

interface ExportDesignTokensModalProps extends ModalInjectedProps {
  shareIdentifier: string
  versionShortId: string
  userCanEditExportUrl: boolean
  componentsState: DocumentComponentsStateExported
}

interface Include {
  colorVariables: boolean
  layerStyles: boolean
  textStyles: boolean
}

function getTokenTypesArray(include: {
  colorVariables: boolean
  layerStyles: boolean
  textStyles: boolean
}) {
  const tokenTypes: ExportableComponentType[] = []

  if (include.colorVariables) {
    tokenTypes.push('COLOR_VARIABLE')
  }

  if (include.layerStyles) {
    tokenTypes.push('LAYER_STYLE')
  }

  if (include.textStyles) {
    tokenTypes.push('TEXT_STYLE')
  }

  return tokenTypes
}

export const ExportDesignTokensModal = ({
  shareIdentifier,
  versionShortId,
  userCanEditExportUrl,
  componentsState,
}: ExportDesignTokensModalProps) => {
  const { hideModal } = useModalContext()
  const { theme: colorSchema } = useThemeContext()

  const [include, setInclude] = useState<Include>({
    colorVariables: true,
    layerStyles: false,
    textStyles: false,
  })
  const [format, setFormat] = useState<TokenExportFormat>('AMAZON')
  const [colorFormat, setColorFormat] = useState<TokenColorFormat>('RGBA')
  const [publicTokenExportUrl, setPublicTokenExportUrl] = useState<
    string | null
  >(null)
  const [publicTokenExport, setPublicTokenExport] =
    useState<PublicTokenExport>('DISABLED')

  const { loading, data, error, refetch } = useGetShareDesignTokenExportQuery({
    variables: {
      shareIdentifier,
      versionShortId,
      format,
      colorFormat: format !== 'W3C' ? colorFormat : 'HEX',
      tokenTypes: getTokenTypesArray(include),
    },
    skip: !shareIdentifier,
    fetchPolicy: 'cache-and-network',
  })

  useEffect(() => {
    setPublicTokenExport(data?.share?.publicTokenExport ?? 'DISABLED')
    setPublicTokenExportUrl(data?.share?.publicTokenExportUrl ?? null)
  }, [data])

  const unformattedCode = data?.share?.version?.document?.tokenExport?.data
  const hasTokens = unformattedCode !== '{}' && unformattedCode !== ''

  const { stateLoading, isProcessing } =
    useTriggerAsyncComponentsIngestionForExportTokens({
      versionShortId,
      shareIdentifier,
      refetch,
      componentsState,
      loadingComponents: loading,
    })

  // when we're trying to component statuses and we don't have tokens yet, let's assume we're loading
  const isLoadingRefetch = !!(isProcessing && !hasTokens)
  const isLoading = loading || stateLoading || isLoadingRefetch

  // `useTriggerAsyncComponentsIngestionForExportTokens` triggers the
  // components ingestion, so until the modal is opened we don't even know if
  // the are components to export.
  // Here we detect if there are no components to export to show an empty
  // message and disable the form controls.
  const isEmpty = !loading && !stateLoading && !isProcessing && !hasTokens

  const filename = useMemo(() => {
    if (!data) {
      return undefined
    }

    const downloadUrlSplit =
      data?.share?.version?.document?.tokenExport?.downloadUrl.split('/') ?? []

    const filenameWithParams = downloadUrlSplit[downloadUrlSplit.length - 1]

    return filenameWithParams?.substring(0, filenameWithParams?.indexOf('?'))
  }, [data])

  const [mutatePublicTokenExport, { loading: mutationLoading }] =
    useUpdateSharePublicTokenExportMutation({
      onError: error => Sentry.captureException(error),
      onCompleted: ({ shareUpdate }) =>
        setPublicTokenExportUrl(
          shareUpdate?.share?.publicTokenExportUrl ?? null
        ),
    })

  const everythingDisabled =
    include.colorVariables === false &&
    include.layerStyles === false &&
    include.textStyles === false

  // Only Editors + Viewers with Editing rights can edit this dropdown
  const disabledDropdown =
    !!mutationLoading ||
    !userCanEditExportUrl ||
    isLoading ||
    everythingDisabled

  const handleFormatChange = (format: TokenExportFormat) => {
    setFormat(format)

    if (format === 'W3C') {
      setColorFormat('HEX')
    }
  }

  const handleIncludeChange = (include: Include) => {
    setInclude(include)

    // layer or text styles can't handle another format
    if (include.layerStyles || include.textStyles) {
      handleFormatChange('W3C')
    }
  }

  const handlePublicLinkStatusChange = (
    publicTokenExportStatus: PublicTokenExport
  ) => {
    setPublicTokenExport(publicTokenExportStatus)

    mutatePublicTokenExport({
      variables: {
        shareIdentifier,
        shareParams: { publicTokenExport: publicTokenExportStatus },
        format,
        colorFormat,
        tokenTypes: getTokenTypesArray(include),
      },
    })
  }

  let code = ''
  let type = ''

  switch (format) {
    case 'AMAZON':
    case 'W3C':
      try {
        code = JSON.stringify(JSON.parse(unformattedCode ?? '{}'), null, 2)
        type = 'application/json; charset=UTF-8'
      } catch (e) {
        code = unformattedCode ?? ''
        type = 'text; charset=UTF-8'
      }
      break

    default:
      code = unformattedCode ?? ''
      type = 'text/css; charset=UTF-8'
  }

  const blob = new Blob([code], {
    type,
  })

  let syntaxContent = null

  // show loading state when generating
  if (isLoading) {
    syntaxContent = (
      <SyntaxPanelContent>
        <LoadingPlaceholder size="64px" />
        <Text textStyle="copy.primary.standard.E">
          Generating Design Tokens&hellip;
        </Text>
      </SyntaxPanelContent>
    )
    // show something different when there's no selection
  } else if (everythingDisabled) {
    syntaxContent = (
      <SyntaxPanelContent>
        <ColorFanIcon />
        <Text textStyle="copy.primary.standard.E" as="span">
          Select a Design Token
        </Text>
        <Text textStyle="copy.quaternary.standard.D" textAlign="center" pt={1}>
          Select at least one type of design <br /> token to export
        </Text>
      </SyntaxPanelContent>
    )
  } else if (isEmpty) {
    syntaxContent = (
      <SyntaxPanelContent>
        <ColorFanIcon />
        <Text textStyle="copy.primary.standard.E" as="span">
          No Tokens to Export
        </Text>
        <Text textStyle="copy.quaternary.standard.D" textAlign="center" pt={1}>
          This document doesn’t contain any components yet.
        </Text>
      </SyntaxPanelContent>
    )
  } else if (error) {
    syntaxContent = (
      <SyntaxPanelContent>
        <ColorFanIcon />
        <Text textStyle="copy.primary.standard.E" as="span">
          An error occurred
        </Text>
        <Text textStyle="copy.quaternary.standard.D" textAlign="center" pt={1}>
          An error occurred while loading the Tokens.
        </Text>
      </SyntaxPanelContent>
    )
  } else {
    syntaxContent = (
      <SyntaxHighlighter
        language="json"
        useInlineStyles={format !== 'CSS'}
        style={
          colorSchema === 'dark'
            ? syntaxHighlighterThemeDark
            : syntaxHighlighterThemeLight
        }
      >
        {code}
      </SyntaxHighlighter>
    )
  }

  return (
    <ModalStyled onCancel={hideModal}>
      <Modal.Body>
        <Flex width="100%" justifyContent="space-between" flexDirection="row">
          <ExportFormPanel
            include={include}
            setInclude={handleIncludeChange}
            format={format}
            handleFormatChange={handleFormatChange}
            colorFormat={colorFormat}
            setColorFormat={setColorFormat}
            disabledDropdown={disabledDropdown}
            disableAll={isEmpty ?? undefined}
            handlePublicLinkStatusChange={handlePublicLinkStatusChange}
            publicTokenExport={publicTokenExport}
            publicTokenExportUrl={publicTokenExportUrl}
            loading={isLoading}
          />
          <Breakpoint on="sm">
            <SyntaxPanel data-testid="syntax-panel">
              {syntaxContent}
            </SyntaxPanel>
          </Breakpoint>
        </Flex>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={hideModal}>
          Close
        </Button>
        {!isLoading && filename && blob && !everythingDisabled && hasTokens ? (
          <DownloadLink
            href={window.URL.createObjectURL(blob)}
            download={filename}
          >
            Download Tokens
          </DownloadLink>
        ) : (
          <Button variant="primary" disabled size="32">
            Download Tokens
          </Button>
        )}
      </Modal.Footer>
    </ModalStyled>
  )
}
