import { dataIdFromObject } from '@sketch/graphql-cache'

import { useOnEvent } from '@sketch/utils'

import { handleFetchMore } from '@sketch/components'

import { useGetStorageItemsQuery } from '@sketch/gql-types'

interface UseGetStorageItemsProps {
  workspaceId: string
  projectId: string
  search?: string
}

const storageItemsEntriesPath = ['project', 'storageItems', 'entries']

export const useGetStorageItems = ({
  workspaceId,
  projectId,
  search,
}: UseGetStorageItemsProps) => {
  const { data, fetchMore, refetch, ...rest } = useGetStorageItemsQuery({
    variables: {
      projectIdentifier: projectId,
      search,
      includeAllNestedLevels: !!search,
      after: null,
    },
    // This fetch policy is needed to make sure the nested projects are shown
    // correctly in the project view after restoring a project from trash
    fetchPolicy: 'cache-and-network',
    shouldInvalidatePrevious: (prev, curr) =>
      prev?.projectIdentifier !== curr?.projectIdentifier,
  })

  useOnEvent(
    'workspaceShareRefresh',
    ({ workspaceIdentifier, projectIdentifier }) => {
      // Not the workspace visible ignore then
      if (
        workspaceIdentifier !== workspaceId ||
        projectIdentifier !== projectId
      ) {
        return
      }

      refetch()
    }
  )

  useOnEvent('storageItemTransferred', ({ location }) => {
    const { destination } = location

    // Not the workspace visible ignore then
    if (
      !destination.project ||
      destination.project.identifier !== projectId ||
      destination.workspace.identifier !== workspaceId
    ) {
      return
    }

    refetch()
  })

  useOnEvent('nestedProjectCreated', ({ parentProjectId }) => {
    if (parentProjectId !== projectId) return

    refetch()
  })

  const afterProjectItems = data?.project?.storageItems.meta.after ?? null

  const fetchMoreFilled = handleFetchMore(fetchMore, storageItemsEntriesPath, {
    dataIdFromObject,
    after: afterProjectItems,
  })
  const storageItems = data?.project?.storageItems.entries

  const totalCounts = {
    storageItems: data?.project?.storageItems?.meta.totalCount ?? 0,
    shares: data?.project?.sharesCount ?? 0,
    projects: data?.project?.nestedProjectsCount ?? 0,
  }

  return {
    ...rest,
    storageItems,
    fetchMore: fetchMoreFilled,
    data,
    totalCounts,
  }
}
