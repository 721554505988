import React, { CSSProperties } from 'react'
import styled from 'styled-components'

import { Tooltip, Flex } from '@sketch/components'

import { noop } from '@sketch/utils'
import { useHandleCopyValue } from '@sketch/modules-common'
import { DirtyIconTooltip } from '../Style/DirtyIconTooltip'
import { IS_SHOW_DIRTY_ATTRIBUTES_ENABLED } from '../../constants'

/**
 * TYPES
 */
export interface HeaderProps {
  onCopy?: (value: string) => void
  copyValue?: string
  style?: CSSProperties
  className?: string
  children?: React.ReactNode
  dirtyAttributes?: { originalValue: string; originalProperty: string }
  /**
   * Allow for custom content on the right of the header when we want
   * something different from the usual dirtyAttributes tooltip.
   */
  extraRightContent?: React.ReactNode
}

/**
 * HELPER COMPONENTS
 */
const Container = styled.div`
  display: flex;
  align-content: center;
  align-items: center;

  /* Separation between any content and a header container */
  /* stylelint-disable-next-line selector-type-no-unknown */
  *:not(&) + & {
    margin-top: 32px;
  }
`

const CopiableHeader = styled.div<{ canCopy?: boolean }>`
  cursor: ${({ canCopy }) => (canCopy ? 'pointer' : undefined)};
  width: 100%;

  > div {
    width: 100%;
  }
`

export const HeaderTitle = styled.h1`
  font-size: ${({ theme }) => theme.fontSizes.B};
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
  text-transform: uppercase;
  margin: 0;
  display: flex;
  justify-content: space-between;
`

export const SubTitle = styled.h2`
  display: flex;
  align-items: center;

  margin: 0;
  font-size: ${({ theme }) => theme.fontSizes.D};
  font-weight: 700;
  line-height: 1;
  color: ${({ theme }) => theme.colors.foreground.secondary.B};
`

/**
 * MAIN COMPONENTS
 */
export const HeaderComponent: React.FC<HeaderProps> = ({
  onCopy = noop,
  copyValue,
  style,
  className,
  dirtyAttributes,
  extraRightContent,
  children,
}) => {
  // Overwrite value if dirty attributes are not enabled
  dirtyAttributes = IS_SHOW_DIRTY_ATTRIBUTES_ENABLED
    ? dirtyAttributes
    : undefined

  const {
    handleCopyValue,
    copyTooltipText,
    handleEnterCopiableArea,
    handleLeaveCopiableArea,
    copyTooltipVisible,
  } = useHandleCopyValue(copyValue ?? '')

  const handleMouseEnterLabel = () =>
    !copyValue ? null : handleEnterCopiableArea()
  const handleMouseLeaveLabel = () =>
    !copyValue ? null : handleLeaveCopiableArea()

  const handleClickHeader = () => {
    if (!copyValue) {
      return
    }
    onCopy(copyValue)
    handleCopyValue()
  }

  return (
    <Container>
      <CopiableHeader
        style={style}
        className={className}
        onClick={handleClickHeader}
        onMouseEnter={handleMouseEnterLabel}
        onMouseLeave={handleMouseLeaveLabel}
        canCopy={!!copyValue}
      >
        <Tooltip
          placement="left"
          spacing="10px"
          visible={copyTooltipVisible}
          content={copyTooltipText}
        >
          {children}
        </Tooltip>
      </CopiableHeader>
      {dirtyAttributes && (
        <Flex marginTop={1}>
          <DirtyIconTooltip
            originalProperty={dirtyAttributes.originalProperty}
            originalValue={dirtyAttributes.originalValue}
            preventLabelTooltip={handleLeaveCopiableArea}
          />
        </Flex>
      )}
      {extraRightContent}
    </Container>
  )
}

export const Header = styled(HeaderComponent)<HeaderProps>`
  display: flex;
  align-items: center;
  margin-right: 8px;
  padding: 8px 0;

  color: ${({ theme }) => theme.colors.foreground.secondary.D};

  .CopyToClipboard {
    visibility: hidden;
  }
  &:hover .CopyToClipboard {
    visibility: visible;
  }
`

export const MiniHeader = styled(SubTitle)`
  display: flex;
  align-items: center;
  margin-right: 8px;
  padding: 8px 0;
`
