import React, { useCallback, useMemo } from 'react'
import { useParams } from 'react-router'
import { RouteParams } from '@sketch/modules-common'

import {
  usePrototypeGetArtboardAtPosition,
  usePrototypeMapRelativePositionToCameraPosition,
  Rect,
} from '@sketch-hq/sketch-web-renderer'

import { AnnotationOverlayContext } from '../../../annotations/components'
import { AnnotationQueryVariablesProvider } from '../../../annotations/context'
import { MouseCoordinates } from '../../../annotations/types'
import { useVersioning } from '../../../versioning'

import {
  ExtendedPrototypeLayer,
  useGetPrototypeArtboards,
  usePrototypeComments,
  usePrototypeContext,
} from '../../hooks'
import { AnnotationDotFragment } from '@sketch/gql-types'

const useGetPrototypeArtboardPages = () => {
  const { manifest } = usePrototypeContext()

  return useMemo(() => {
    const pages = manifest?.contents.pages || []
    const pagesIdentifierByArtboardIdentifier: Record<string, string> = {}

    pages.forEach(page => {
      page.artboards?.forEach(({ id }) => {
        pagesIdentifierByArtboardIdentifier[id] = page.id
      })
    })

    return pagesIdentifierByArtboardIdentifier
  }, [manifest])
}

export const PrototypeAnnotationsContext: React.FC = ({ children }) => {
  const { share } = useVersioning()

  const artboards = useGetPrototypeArtboards()
  const pagesIdentifierByArtboardIdentifier = useGetPrototypeArtboardPages()

  const params = useParams<RouteParams<'PROTOTYPE_PLAYER'>>()
  const getArtboardAtPosition = usePrototypeGetArtboardAtPosition()
  const mapRelativePosition = usePrototypeMapRelativePositionToCameraPosition()

  const getPositionRelativeToOrigin = useCallback(
    (mouseCoordinates: MouseCoordinates) =>
      mapRelativePosition(mouseCoordinates.clientX, mouseCoordinates.clientY),
    [mapRelativePosition]
  )

  const [areCommentVisible] = usePrototypeComments()

  const subjects = useMemo(() => {
    const subjectsUUIDs = new Set(
      artboards.map(
        artboard => pagesIdentifierByArtboardIdentifier[artboard?.id]
      )
    )

    return Array.from(subjectsUUIDs).map(permanentId => ({
      type: 'PAGE' as const,
      permanentId,
    }))
  }, [artboards, pagesIdentifierByArtboardIdentifier])

  const initialArtboard = artboards[0] as ExtendedPrototypeLayer | undefined
  const mostForwardArtboard = artboards[artboards.length - 1]

  const getElementAtPosition = useCallback(
    async (mouseCoordinates: MouseCoordinates) => {
      const artboard = await getArtboardAtPosition(
        mouseCoordinates.clientX,
        mouseCoordinates.clientY
      )

      const foundArtboard = artboards.find(
        item => item.id === artboard?.getLayerUUID()
      )

      if (artboard && foundArtboard) {
        const permanentId = artboard.getLayerUUID()
        return {
          type: 'ARTBOARD' as const,
          permanentId: artboard.getLayerUUID(),
          bounds: foundArtboard.boundsWithScroll,
          permanentPageId: pagesIdentifierByArtboardIdentifier[permanentId],
        }
      }

      return null
    },
    [getArtboardAtPosition, artboards, pagesIdentifierByArtboardIdentifier]
  )

  const getDotAdditionalStyle = useCallback(
    (annotation: AnnotationDotFragment) => {
      if (
        annotation.currentSubject.__typename === 'Artboard' &&
        annotation.currentSubject.uuid !== mostForwardArtboard?.id
      ) {
        return {
          opacity: 0.5,
        }
      }
    },
    [mostForwardArtboard]
  )

  const artboard = artboards[0] as ExtendedPrototypeLayer | undefined

  const artboardCoordinatesByUuid = useMemo(() => {
    const coordinatesByUuid: Record<string, Rect> = {}

    artboards?.forEach(artboard => {
      coordinatesByUuid[artboard.id] = artboard.boundsWithScroll
    })

    return coordinatesByUuid
  }, [artboards])

  const referenceBounds = useMemo(
    () => ({
      x: 0,
      y: 0,
      width: artboard?.bounds?.width || 0,
      height: artboard?.bounds?.height || 0,
    }),
    [artboard]
  )

  const getPrefixCoordinates = useCallback(
    (annotation: AnnotationDotFragment) => {
      if (annotation.currentSubject.__typename === 'Artboard') {
        return artboardCoordinatesByUuid[annotation.currentSubject.uuid]
      }
    },
    [artboardCoordinatesByUuid]
  )

  const shouldRenderAnnotation = useCallback(
    (annotation: AnnotationDotFragment) => {
      if (annotation.currentSubject.__typename !== 'Artboard') {
        return false
      }

      const artboardUUID = annotation.currentSubject.uuid
      const artboardExists = !!artboards?.find(({ id }) => id === artboardUUID)

      if (!artboardExists) {
        return false
      }

      const annotationPrefixCoordinates = getPrefixCoordinates(annotation)

      const finalCoordinates = {
        x:
          (annotationPrefixCoordinates?.x || 0) +
          (annotation.coordinates?.x || 0),
        y:
          (annotationPrefixCoordinates?.y || 0) +
          (annotation.coordinates?.y || 0),
      }

      return (
        finalCoordinates.x <= referenceBounds.width &&
        finalCoordinates.y <= referenceBounds.height
      )
    },
    [getPrefixCoordinates, referenceBounds, artboards]
  )

  return (
    <AnnotationQueryVariablesProvider
      shareIdentifier={params.shareID || ''}
      subjects={subjects}
    >
      <AnnotationOverlayContext
        disabled={!share.commentsEnabled}
        getElementAtPosition={getElementAtPosition}
        getPositionRelativeToOrigin={getPositionRelativeToOrigin}
        resetKey={initialArtboard?.id}
        getDotAdditionalStyle={getDotAdditionalStyle}
        hidden={({
          placeDraftAnnotation,
          activeAnnotation,
          annotationInFlight,
        }) =>
          !(
            areCommentVisible ||
            placeDraftAnnotation ||
            annotationInFlight ||
            activeAnnotation
          )
        }
        shouldRenderAnnotation={shouldRenderAnnotation}
        isViewingLatestVersion
      >
        {children}
      </AnnotationOverlayContext>
    </AnnotationQueryVariablesProvider>
  )
}
