import styled, { css } from 'styled-components'
import {
  FlatButton,
  Input,
  InputWrapper,
  Checkbox,
  CheckboxLabel,
  ButtonUnstyled,
} from '@sketch/components'
import { breakpoint } from '@sketch/global-styles'

import { ReactComponent as ClockwiseArrowCircle } from '@sketch/icons/clockwise-arrow-circle-16'
import { ReactComponent as FolderClosedIcon } from '@sketch/icons/folder-closed-16'
import { ReactComponent as DocumentPrismIcon } from '@sketch/icons/documents-prism-16'
import { ReactComponent as FilterIconSvg } from '@sketch/icons/3-lines-filter-24'

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  gap: 8px;
`

export const FakeBackdrop = styled.div<{ isVisible: boolean }>(
  ({ theme, isVisible }) => css`
    position: fixed;
    z-index: ${theme.zIndex[7]};
    top: 0;
    left: 0;
    padding: 5vh 0; /* stylelint-disable-line scales/space */
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    background-color: ${theme.colors.overlay};
    user-select: none;
    transition: opacity ${theme.transitions.duration[2]};
    pointer-events: ${isVisible ? 'auto' : 'none'};
    opacity: ${isVisible ? 1 : 0};
  `
)

export const FakeModal = styled.div<{ isVisible: boolean }>(
  ({ theme, isVisible }) => css`
    position: absolute;
    z-index: ${theme.zIndex[8]};
    bottom: 0;
    left: 0;
    right: 0;
    transition: opacity ${theme.transitions.duration[2]};
    opacity: ${isVisible ? 1 : 0};
    pointer-events: ${isVisible ? 'auto' : 'none'};
    width: 100%;
    max-height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    overscroll-behavior: none;
    border-radius: 16px 16px 0 0;
    background-color: ${theme.colors.background.secondary.A};
    background-clip: padding-box;

    box-shadow:
      0 8px 16px 0 ${theme.colors.shadow.outer},
      0px 0px 0px 1px ${theme.colors.border.A};
  `
)

export const OptionsContent = styled.div<{ isMobile?: boolean }>`
  width: ${({ isMobile }) => (isMobile ? '100%' : '340px')};
  padding: 8px 0;
`

export const StyledClockwiseArrowCircle = styled(ClockwiseArrowCircle)`
  width: 12px;
  height: 12px;
`

export const ResetButton = styled(FlatButton)(
  ({ theme }) => css`
    color: ${theme.colors.foreground.secondary.D};
    font-size: ${theme.fontSizes.D};
    padding: 12px 8px;
    display: flex;
    align-items: center;
    cursor: pointer;
    position: absolute;
    right: 12px;
    top: 4px;

    ${StyledClockwiseArrowCircle} {
      margin-right: 8px;
    }
  `
)

export const DatesWrapper = styled.div(
  ({ theme }) => css`
    display: flex;
    color: ${theme.colors.foreground.secondary.C};
    font-size: ${theme.fontSizes.C};
    align-items: center;
    padding: 0 20px 12px;
    justify-content: space-between;

    ${InputWrapper} {
      width: 45%;

      ${breakpoint('sm')`
      width: 128px;
    `}
    }
  `
)

export const DatePicker = styled(Input).attrs({
  type: 'date',
  placeholderTextColor: 'red',
})<{ isDarkMode: boolean }>(
  ({ theme, isDarkMode }) => css`
    width: 100%;
    border-radius: 6px;
    border: 1px solid ${theme.colors.border.B};
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1) inset;
    background-color: ${theme.colors.background.secondary.B};
    margin: 0;
    color: ${theme.colors.state.disabled};
    font-size: ${theme.fontSizes.D};

    /* dark sorcery to style the browser's default calendar icon for the input type date */
    ::-webkit-calendar-picker-indicator {
      filter: ${isDarkMode ? 'invert(50%)' : undefined};
    }
  `
)

export const LocationWrapper = styled.div`
  max-height: 100px;
  overflow-y: auto;
  margin-top: 16px;
  /* 2px so the focus state isn't cut off  */
  padding-left: 2px; /* stylelint-disable-line scales/space */

  &:empty {
    display: none;
  }
`

export const LoadingWrapper = styled.div`
  display: flex;
  justify-content: center;
`

export const CheckboxCentered = styled(Checkbox)`
  ${CheckboxLabel} {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`

const IconBase = css`
  width: 16px;
  height: 16px;
  margin-right: 4px;
  margin-bottom: -4px;
  color: ${({ theme }) => theme.colors.foreground.secondary.D};
`

export const ProjectIcon = styled(FolderClosedIcon)`
  ${IconBase}
`

export const DocumentIcon = styled(DocumentPrismIcon)`
  ${IconBase}
`

export const FilterIcon = styled(FilterIconSvg)<{ $isActive?: boolean }>`
  color: ${({ theme }) => theme.colors.foreground.secondary.D};
  height: 24px;
  width: 24px;
`

export const FilterButton = styled(ButtonUnstyled)<{ $isActive?: boolean }>(
  ({ theme, $isActive }) => css`
    cursor: pointer;
    width: 40px;
    height: 32px;

    padding: 4px 8px;
    border-radius: ${theme.radii.large};
    color: ${theme.colors.background.secondary.A};

    &:hover {
      background-color: ${$isActive ? undefined : theme.colors.state.hover};

      ${FilterIcon} {
        color: ${theme.colors.foreground.secondary.A};
      }
    }

    background-color: ${$isActive ? theme.colors.sketch.F : undefined};

    ${FilterIcon} {
      color: ${$isActive ? theme.colors.sketch.A : undefined};
    }
  `
)

export const MobileWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  margin-bottom: 24px;
  gap: 8px;
  flex-direction: row-reverse;
`
