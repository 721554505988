import { BaseAnnotationFragment } from '@sketch/gql-types'
import React from 'react'
import { NavLinkProps, useLocation } from 'react-router-dom'
import { Banner, Link } from '@sketch/components'
import { useAnnotationQueryVariables } from '../../context'
import AnnotationListWrapper from '../../components/AnnotationListWrapper'
import AnnotationListStatusFilter from '../../components/AnnotationListStatusFilter'
import AnnotationListHeader from '../../components/AnnotationListHeader'
import { AnnotationStatusFilterWithoutUnread } from '../../types'
import ActiveOrResolvedAnnotationsList from '../../components/ActiveOrResolvedAnnotationsList'
import AllAnnotationsList from '../../components/AllAnnotationsList'
import { getLatestVersionURLFromCurrentLocation } from '../../../versioning/utils'
import { useAnnotationOverlayContext } from '../../hooks'

import { WarningText } from './AnnotationListContainer.styles'

interface ListProps {
  isCanvasLoading?: boolean
  activeAnnotationIdentifier?: string
  resolveAnnotationLink?: (
    annotation: BaseAnnotationFragment
  ) => NavLinkProps['to'] | undefined
}

interface AnnotationListContainerProps extends ListProps {
  navbarAction?: React.ReactNode
}

const AnnotationListContainer = (props: AnnotationListContainerProps) => {
  const { navbarAction, ...otherProps } = props

  const location = useLocation()
  const annotationOverlay = useAnnotationOverlayContext()

  const { annotationStatus } = useAnnotationQueryVariables('sidebar')
  const showAllAnnotations = annotationStatus === 'ALL'

  const isViewingLatestVersion =
    annotationOverlay && !annotationOverlay.isViewingLatestVersion

  /*
   A newer implementation for the list of annotations overrides  the variables 
   from "AnnotationQueryVariablesContext" and "useAnnotationQueryVariables". 
   -> This override happens in "AllAnnotationsList"
  */
  const Lists = showAllAnnotations
    ? AllAnnotationsList
    : ActiveOrResolvedAnnotationsList

  return (
    <AnnotationListWrapper data-testid="annotation-list">
      <AnnotationListHeader action={props.navbarAction}>
        <AnnotationListStatusFilter
          annotationStatus={
            (annotationStatus as AnnotationStatusFilterWithoutUnread) ||
            undefined
          }
        />
      </AnnotationListHeader>

      {isViewingLatestVersion && (
        <Banner type="warning" showIcon={false}>
          <WarningText>
            You&apos;re viewing an older version. Go to the{' '}
            <Link
              to={getLatestVersionURLFromCurrentLocation(location)}
              variant="secondary"
              isUnderlined
            >
              latest version
            </Link>{' '}
            to see and add comments.
          </WarningText>
        </Banner>
      )}

      <Lists
        shouldRenderAnnotation={annotationOverlay?.shouldRenderAnnotation}
        {...otherProps}
      />
    </AnnotationListWrapper>
  )
}

export default AnnotationListContainer
