import React, { useContext } from 'react'

import {
  Button,
  Caption,
  Banner,
  Modal,
  ModalInjectedProps,
} from '@sketch/components'
import { ToastContext } from '@sketch/toasts'

import { useResetScimTokenMutation } from '@sketch/gql-types'

interface ConfirmGenerateNewScimTokenModalProps extends ModalInjectedProps {
  workspaceId: string
  onSuccess: (scimToken: string) => void
}

export const ConfirmGenerateNewScimTokenModal: React.FC<
  ConfirmGenerateNewScimTokenModalProps
> = ({ workspaceId, onSuccess, hideModal }) => {
  const { showToast } = useContext(ToastContext)
  const [resetScimToken, { loading }] = useResetScimTokenMutation({
    variables: {
      identifier: workspaceId,
    },
    onError: 'show-toast',
    onCompleted: data => {
      showToast('New SCIM Token has been generated', 'positive')
      hideModal()
      onSuccess(data.resetScimToken.scimToken)
    },
  })

  return (
    <Modal
      title="Are you sure you want to generate a new SCIM token?"
      onCancel={hideModal}
    >
      <Modal.Body>
        <Caption>
          Generating a new SCIM token will cause the current token to expire.
          You can&#8217;t undo this action.
        </Caption>
        <Banner type="warning" showIcon={false}>
          Make sure you add the new token to your IdP settings. Without it, you
          won&#8217;t be able to automatically add or remove Members in the
          Workspace anymore.
        </Banner>
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={hideModal}
          size="40"
          variant="primary"
          disabled={loading}
        >
          Cancel
        </Button>
        <Button
          onClick={() => resetScimToken()}
          size="40"
          variant="negative-secondary"
          loading={loading}
          data-testid="scim-generate-new-token-confirm-button"
        >
          Generate New Token
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
