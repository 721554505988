import React, { useState, useContext } from 'react'
import { useDropzone, DropzoneOptions } from 'react-dropzone'

import { MAX_SIZE, useIsMountedRef } from '@sketch/utils'

// Modals
import { ManualSsoConfigurationModal } from '../../modals/ManualSsoConfigurationModal'

import { Link, ModalContext } from '@sketch/components'
import { useCustomerUpdateIdentityProviderConfig } from '../../../sso/operations'

import {
  Dropzone,
  Container,
  DropzoneInstructions,
  Label,
} from './SsoSamlConfiguration.styles'

interface SsoSamlNotConfiguredViewProps {
  customerId: string
}

const SsoSamlNotConfigured = (props: SsoSamlNotConfiguredViewProps) => {
  const { customerId } = props
  const [loading, setLoading] = useState(false)
  const { showModal } = useContext(ModalContext)

  const isMountedRef = useIsMountedRef()

  const { uploadIdentityProviderConfig } =
    useCustomerUpdateIdentityProviderConfig()

  const handleConfigureManuallyClick = (e: React.MouseEvent) => {
    e.stopPropagation()
    showModal(ManualSsoConfigurationModal, { customerId })
  }

  const handleOnDrop: DropzoneOptions['onDrop'] = async ([acceptedFile]) => {
    if (!acceptedFile) {
      return
    }

    setLoading(true)
    await uploadIdentityProviderConfig(customerId, acceptedFile)
    isMountedRef.current && setLoading(false)
  }

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: {
      'text/xml': ['.xml'],
      'application/samlmetadata+xml': [],
    },
    onDrop: handleOnDrop,
    maxSize: MAX_SIZE,
  })

  return (
    <>
      <Label>Import XML Metadata File</Label>
      <Container isLoading={loading}>
        <Dropzone
          data-testid="dropzone"
          isDragActive={isDragActive}
          {...getRootProps({ refKey: 'ref' })}
        >
          <input id="input" {...getInputProps()} />
          <DropzoneInstructions>
            Drag an drop XML metadata or{' '}
            <Link
              variant="secondary"
              onClick={handleConfigureManuallyClick}
              external
              isUnderlined
            >
              enter manually
            </Link>
          </DropzoneInstructions>
        </Dropzone>
      </Container>
    </>
  )
}

export default SsoSamlNotConfigured
