import styled, { css } from 'styled-components'

import { breakpoint } from '@sketch/global-styles'

import { ColumnProps } from './Grid.types'

export const Container = styled.div<ColumnProps>`
  ${props => css`
    grid-column: auto / span ${props?.xxs || props?.xs || 12};
    --grid-columns: ${props?.xxs || props?.xs || 12};

    ${breakpoint('xs')`
        grid-column: auto / span ${props?.xs || 12};
        --grid-columns: ${props?.xs || 12};
      `}

    ${breakpoint('sm')`
        grid-column: auto / span ${props?.sm};
        --grid-columns: ${props?.sm};
      `}

      ${breakpoint('md')`
        grid-column: auto / span ${props?.md};
        --grid-columns: ${props?.md};
      `}
    
      ${breakpoint('lg')`
        grid-column: auto / span ${props?.lg};
        --grid-columns: ${props?.lg};
      `}

      ${breakpoint('xl')`
        grid-column: auto / span ${props?.xl};
        --grid-columns: ${props?.xl};
      `}
  `};
`
