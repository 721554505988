import styled, { css } from 'styled-components'

export const Icon = styled.svg<{ $rotate?: boolean }>`
  width: 17px;
  height: 17px;

  /* This color should be "Light/colors.foreground.secondary.D" but given the icon composition */
  /* Opacity needs to be applied to the whole object */
  color: ${({ theme }) => theme.colors.foreground.secondary.A};
  opacity: 0.55;

  margin: 0px 8px;

  ${({ $rotate }) =>
    $rotate &&
    css`
      transform: rotate(90deg);
    `}
`
