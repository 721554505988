import styled, { css } from 'styled-components'
import { breakpoint } from '@sketch/global-styles'
import Selector from '../Selector'

export interface WrapperProps {
  $isModalVariant?: boolean
}

export const Wrapper = styled.div<WrapperProps>`
  position: absolute;
  right: 12px;
  top: 12px;
  z-index: 1;

  display: flex;

  background-color: transparent;
  box-shadow: none;
  border-radius: 6px;

  transform: translate3d(4px, -4px, 0);

  transition-duration: 250ms;
  transition-property: background-color, box-shadow, transform;

  ${({ $isModalVariant }) =>
    $isModalVariant &&
    css`
      top: 8px;
      right: 8px;

      [role='img'] {
        width: 24px;
        height: 24px;
      }

      ${Selector} {
        ${breakpoint('base', 'sm')`
          padding: 4px;
        `}
      }
    `}

  [data-scrolltop='false'] & {
    transform: translate3d(0, 0, 0);
    background-color: ${({ theme }) => theme.colors.background.secondary.A};
    box-shadow:
      ${({ theme }) => theme.shadows.boxShadow.outer},
      0px 0px 0px 1px ${({ theme }) => theme.colors.border.A};
  }
`
