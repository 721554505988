import React, { useState } from 'react'
import { Formik } from 'formik'
import * as Yup from 'yup'

import { Form, Modal, ModalInjectedProps } from '@sketch/components'

import { useToast } from '@sketch/toasts'

import VerifyPartnerForm from './VerifyPartnerForm'
import SendInvitationForm from './SendInvitationForm'

import { FormValues } from './types'
import {
  useGetPartnerLazyQuery,
  useInviteWorkspacePartnerMutation,
  GetWorkspaceMembershipsDocument,
} from '@sketch/gql-types'

interface InvitePartnerModalProps extends ModalInjectedProps {
  workspaceIdentifier: string
}

// Form
const INITIAL_VALUES = {
  email: '',
}

// Form Validation
const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email('This is not a valid email')
    .required('Email can’t be blank'),
})

export const InvitePartnerModal: React.FC<InvitePartnerModalProps> = ({
  hideModal,
  workspaceIdentifier,
}) => {
  const { showToast } = useToast()
  const [step, setStep] = useState<'verify' | 'sendInvitation'>('verify')
  const [showError, setShowError] = useState(false)

  const [getPartner, { data: partnerData, loading: loadingPartner }] =
    useGetPartnerLazyQuery({
      fetchPolicy: 'network-only',
      onCompleted: data => {
        if (data.partner) {
          setStep('sendInvitation')
        } else {
          setShowError(true)
        }
      },
    })

  const [inviteWorkspacePartner, { loading: loadingInviteWorkspacePartner }] =
    useInviteWorkspacePartnerMutation({
      onError: 'show-toast',
      onCompleted: () => {
        const partnerName = partnerData?.partner?.name

        showToast(
          `Invitation sent to ${partnerName} to join as a Sketch Partner`,
          'positive'
        )
        hideModal()
      },
      refetchQueries: [
        {
          query: GetWorkspaceMembershipsDocument,
          variables: {
            workspaceId: workspaceIdentifier,
            role: 'PARTNER',
          },
        },
      ],
    })

  const handleSubmit = async ({ email }: FormValues) => {
    switch (step) {
      case 'verify': {
        await getPartner({
          variables: {
            email,
          },
        })
        break
      }

      case 'sendInvitation': {
        if (loadingInviteWorkspacePartner) return
        inviteWorkspacePartner({
          variables: {
            input: {
              email,
              workspaceIdentifier,
            },
          },
        })
      }
    }
  }

  const title =
    step === 'verify' ? 'Invite a Sketch Partner?' : 'Ready to Send Invitation?'

  return (
    <Modal title={title} onCancel={hideModal}>
      <Formik
        validationSchema={validationSchema}
        initialValues={INITIAL_VALUES}
        onSubmit={handleSubmit}
      >
        {formikbag => {
          return (
            <Form>
              {step === 'verify' ? (
                <VerifyPartnerForm
                  error={showError}
                  loading={loadingPartner}
                  formikbag={formikbag}
                  hideModal={hideModal}
                />
              ) : (
                <SendInvitationForm
                  name={partnerData?.partner?.name!}
                  email={partnerData?.partner?.email!}
                  avatar={partnerData?.partner?.avatar?.small!}
                  loading={loadingInviteWorkspacePartner}
                  formikbag={formikbag}
                  hideModal={hideModal}
                />
              )}
            </Form>
          )
        }}
      </Formik>
    </Modal>
  )
}
