import { OverflowProperty } from 'csstype'
import styled, { css } from 'styled-components'
import {
  alignItems,
  bottom,
  border,
  BorderProps,
  borderRadius,
  BorderRadiusProps,
  color,
  ColorProps,
  display,
  flex,
  flexWrap,
  fontSize,
  fontStyle,
  fontWeight,
  height,
  HeightProps,
  justifyContent,
  left,
  maxHeight,
  MaxHeightProps,
  maxWidth,
  MaxWidthProps,
  minHeight,
  MinHeightProps,
  minWidth,
  MinWidthProps,
  order,
  position,
  right,
  space,
  SpaceProps,
  style,
  textAlign,
  top,
  verticalAlign,
  width,
  WidthProps,
  zIndex,
  system,
  RequiredTheme,
  Config,
  ConfigStyle,
} from 'styled-system'
import { getDisplayName } from '@sketch/utils'
import { lightTheme as theme } from '@sketch/global-styles'

const overflowX = style({
  prop: 'overflowX',
  cssProperty: 'overflowX',
})

interface OverflowX {
  overflowX?: OverflowProperty
}

/**
 * SpaceProps are unsafe, as they allow bug-prone ambiguities.
 *
 * Example:
 * <Box mt={12}> can result in either "margin-top: 48px" or "margin-top: 12px"
 * depending on the existance of `theme.space[12]` in the space scale. That is
 * an obvious issue when new spaces are added or removed.
 *
 * This issue is solved by introducing SafeSpaceProps and UnsafeSpaceProps:
 *   - SafeSpaceProps restrict space values to valid indices of `theme.space`.
 *
 *   - UnsafeSpaceProps are an escape hatch to the original behaviour, allowing
 *     custom values subject to the ambiguities pointed above. These can used
 *     as <Box UNSAFE_mt={9999}>.
 */

type SafeSpaceValue = Indices<typeof theme.space> | 'auto' | '0 auto'
export type SafeSpaceProps = SpaceProps<RequiredTheme, SafeSpaceValue>

type UnsafeSpaceProps = {
  [K in keyof SpaceProps as `UNSAFE_${K}`]?: SpaceProps[K]
}

// Create UNSAFE-prefixed styles reusing the configuration of original properties
type UnsafeSpaceConfig = Config & {
  [K in keyof UnsafeSpaceProps]?: ConfigStyle
}

const spaceConfig = (space.config ?? {}) as Config
const unsafeSpaceConfig: UnsafeSpaceConfig = Object.entries(spaceConfig).reduce(
  (config, [prop, propConfig]) => ({
    ...config,
    [`UNSAFE_${prop}`]: propConfig,
  }),
  {}
)
const unsafeSpace = system(unsafeSpaceConfig)

export interface BoxProps
  extends OverflowX,
    UnsafeSpaceProps,
    SafeSpaceProps,
    WidthProps,
    HeightProps,
    MaxWidthProps,
    MinWidthProps,
    MaxHeightProps,
    MinHeightProps,
    BorderProps,
    BorderRadiusProps,
    ColorProps {}
// // The following props were removed intentionally
// // read https://github.com/sketch-hq/Cloud/issues/2329
// // for more details
// // ------------------------------------------------
// // use withFlex to get the following properties:
// DisplayProps,
// FlexProps,
// FlexWrapProps,
// JustifyContentProps,
// AlignItemsProps,
// // ------------------------------------------------
// // use withAbsolute to get the following properties:
// PositionProps,
// TopProps,
// BottomProps,
// RightProps,
// LeftProps,
// ZIndexProps,
// // ------------------------------------------------
// // use withText to get the following properties:
// FontWeightProps,
// TextAlignProps,
// VerticalAlignProps,
// FontSizeProps,
// FontStyleProps,
// // ------------------------------------------------
// // The following properties weren't used as much
// // so it isn't added to Box component.
// // use styled-component to change these properties
// OrderProps

const boxCss = css`
  ${overflowX}
  ${unsafeSpace}
  ${space}
  ${width}
  ${height}
  ${border}
  ${maxWidth}
  ${minWidth}
  ${maxHeight}
  ${minHeight}
  ${color}
`

/*
 * Deprecated - the following props will be removed in the future!!
 * use one of (or combination of) withFlex, withAbsolute, withText
 *
 * These properties were left here just for backwards compatibility
 * and they should be accessed only from components written in JavaScript
 * (not TypeScript) files.
 */
const boxLegacyCss = css`
  ${display}
  ${flex}
  ${flexWrap}
  ${justifyContent}
  ${alignItems}

  ${position}
  ${top}
  ${bottom}
  ${borderRadius}
  ${right}
  ${left}
  ${zIndex}

  ${textAlign}
  ${fontSize}
  ${fontWeight}
  ${verticalAlign}
  ${fontStyle}

  ${order}
`

export function withBox<C extends AnyComponent>(Comp: C) {
  const styledComp = styled(Comp)<BoxProps>`
    ${boxCss}
    ${boxLegacyCss}
  `
  styledComp.displayName = `withBox(${getDisplayName(Comp)})`
  return styledComp
}
