import React from 'react'

// Compoments
import { Section, Skeleton } from '@sketch/components'
import WorkspaceSettingsProfileURL from '../../../community/containers/WorkspaceSettingsProfileURL'
import { PublishProfileSection } from '../../../community/containers/WorkspaceSettingsPublishProfile'

import { useGetWorkspaceProfileQuery } from '@sketch/gql-types'

export interface WorkspaceSettingsProfileProps {
  workspaceIdentifier: string
}

interface LoadingGuardProps {
  loading: boolean
  component: React.ReactElement
}

const LoadingGuard: React.FC<LoadingGuardProps> = ({
  loading,
  component: Component,
}) => {
  if (loading) {
    return <Skeleton width="100%" height="70px" />
  }

  return Component
}

export const WorkspaceSettingsProfile: React.FC<
  WorkspaceSettingsProfileProps
> = props => {
  const { workspaceIdentifier } = props

  const { data, loading, error } = useGetWorkspaceProfileQuery({
    variables: { workspaceIdentifier },
    fetchPolicy: 'cache-and-network',
  })

  const { identifier, isPublic, shortUrlName } = data?.workspaceProfile ?? {
    identifier: '',
  }

  return (
    <>
      <Section title="Visibility">
        <LoadingGuard
          loading={loading}
          component={
            <PublishProfileSection
              error={error}
              isPublic={isPublic}
              identifier={identifier}
            />
          }
        />
      </Section>

      <Section title="Workspace Public Profile URL">
        <LoadingGuard
          loading={loading}
          component={
            <WorkspaceSettingsProfileURL
              profileIdentifier={identifier}
              shortUrlName={shortUrlName!}
            />
          }
        />
      </Section>
    </>
  )
}
