import React, { useState, useMemo, useEffect } from 'react'
import throttle from 'lodash.throttle'
import { useDebounceValue } from './useDebounceValue'

interface UseResizableSidePanel {
  handleRef: React.MutableRefObject<HTMLElement>
  defaultWidth: number
  minWidth?: number
  maxWidth?: number
  side?: 'left' | 'right'
  touchDevices?: boolean
  onWidthUpdate?: (width: number) => void
}

/**
 * Hook that returns the updated width for an specific container based on a
 * handle ref, the only limitation is that the container needs to touch left
 * or side edge of the viewport.
 *
 * It works for both pointer and touch events.
 *
 * Important note: don't try to use onDrag/onDragStart/onDragOver,
 * Firefox has an old bug and it doesn't return screen coordinates
 * while dragging: https://bugzilla.mozilla.org/show_bug.cgi?id=505521
 */
export function useResizableSidePanel({
  handleRef,
  defaultWidth,
  minWidth = 0,
  maxWidth = document.documentElement.clientWidth,
  side = 'left',
  touchDevices = true,
  onWidthUpdate,
}: UseResizableSidePanel): number {
  const [width, setWidth] = useState(defaultWidth)

  // Debounces 250ms `onWidthUpdate`
  const widthDebounced = useDebounceValue(width, 250)
  useEffect(() => {
    onWidthUpdate?.(widthDebounced)
  }, [onWidthUpdate, widthDebounced])

  const dragWithMouse = useMemo(
    () =>
      throttle(({ x }: MouseEvent) => {
        const widthWithoutConstraints =
          side === 'left' ? x : document.documentElement.clientWidth - x

        // New width can have min and max constraints
        const newWidth =
          widthWithoutConstraints < minWidth
            ? minWidth
            : widthWithoutConstraints > maxWidth
              ? maxWidth
              : widthWithoutConstraints

        setWidth(newWidth)
      }, 100),
    [side, minWidth, maxWidth]
  )

  const dragWithFinger = useMemo(
    () =>
      throttle((event: TouchEvent) => {
        const x = event.changedTouches[0].clientX

        const widthWithoutConstraints =
          side === 'left' ? x : document.documentElement.clientWidth - x

        // New width can have min and max constraints
        const newWidth =
          widthWithoutConstraints < minWidth
            ? minWidth
            : widthWithoutConstraints > maxWidth
              ? maxWidth
              : widthWithoutConstraints

        setWidth(newWidth)
      }, 100),
    [side, minWidth, maxWidth]
  )

  useEffect(() => {
    if (!handleRef.current) {
      return
    }

    const handleNode = handleRef.current

    // ignore outside clicks while dragging
    const handleClick = (e: MouseEvent) => {
      e.stopImmediatePropagation()
    }

    const handleMouseDown = (e: MouseEvent) => {
      e.preventDefault()

      document.addEventListener('mousemove', dragWithMouse)
      document.addEventListener('click', handleClick, { capture: true })
      document.addEventListener('mouseup', removeMouseEvents)
    }

    const handleTouchStart = (e: TouchEvent) => {
      e.preventDefault()

      document.addEventListener('touchmove', dragWithFinger)
      document.addEventListener('touchend', removeTouchEvents)
    }

    const removeMouseEvents = () => {
      dragWithMouse.cancel()

      document.removeEventListener('mousemove', dragWithMouse)
      document.removeEventListener('mouseup', removeMouseEvents)

      setTimeout(() =>
        document.removeEventListener('click', handleClick, { capture: true })
      )
    }

    const removeTouchEvents = () => {
      dragWithFinger.cancel()

      document.removeEventListener('touchmove', dragWithFinger)
      document.removeEventListener('touchend', removeMouseEvents)
    }

    const resetPosition = () => {
      setWidth(defaultWidth)
    }

    handleNode.addEventListener('mousedown', handleMouseDown)
    if (touchDevices) {
      handleNode.addEventListener('touchstart', handleTouchStart)
    }
    handleNode.addEventListener('dblclick', resetPosition)

    return () => {
      handleNode.removeEventListener('mousedown', handleMouseDown)
      handleNode.removeEventListener('dblclick', resetPosition)

      if (touchDevices) {
        handleNode.removeEventListener('touchstart', handleTouchStart)
      }

      removeMouseEvents()
      removeTouchEvents()
    }
  }, [handleRef, dragWithFinger, dragWithMouse, defaultWidth, touchDevices])

  return width
}
