import React from 'react'

import { ReactComponent as ImagePlaceholder } from '@sketch/icons/document-64'

import { LoadingPlaceholder } from '@sketch/components'

import { getThumbnail } from '../../../shares/ComponentsWebView/utils'

import { ImageElement } from '../../../shares/components/DocumentItem/DocumentItem.styles'
import {
  Container,
  StyledStatusIcon,
  Thumbnail,
  ThumbnailWrapper,
  NoThumbnailWrapper,
} from './NestedProjectGridPreview.styles'

import { NestedProjectFragment } from '@sketch/gql-types'
import { isTruthy } from '@sketch/utils'

export interface NestedProjectPreviewProps {
  nestedProject: Pick<NestedProjectFragment, 'name' | 'previews'>
}

export const NestedProjectGridPreview = (props: NestedProjectPreviewProps) => {
  const { nestedProject } = props

  const previews = nestedProject.previews.slice(0, 4)

  return (
    <Container>
      <ThumbnailWrapper aria-label={`Project ${nestedProject.name}`}>
        {previews.map(({ name, file }, index) => {
          const thumbnails = (file?.thumbnails || []).filter(isTruthy)

          if (thumbnails.length === 0) {
            return (
              <NoThumbnailWrapper
                data-testid="nested-project-no-thumb"
                key={index}
              >
                <StyledStatusIcon
                  key={index}
                  as={ImagePlaceholder}
                  aria-label="No Image available"
                />
              </NoThumbnailWrapper>
            )
          } else {
            return (
              <Thumbnail
                key={index}
                alt={name}
                customImageElement={ImageElement}
                src={getThumbnail(thumbnails)}
                loadingPlaceholder={<LoadingPlaceholder size="16px" />}
              />
            )
          }
        })}
      </ThumbnailWrapper>
    </Container>
  )
}
