import React from 'react'
import { Formik } from 'formik'
import * as yup from 'yup'

import { Flex, Form, Input } from '@sketch/components'

import {
  useUpdateWorkspaceCustomerSsoIdentityProviderConfigMutation,
  IdentityProviderConfigFragment,
} from '@sketch/gql-types'

import { ErrorHandler } from '@sketch/tracing'

import {
  CertificateTextArea,
  SamlRadioButton,
  StyledCaption,
} from './SsoSamlSetupForm.styles'

enum Binding {
  Post = 'POST',
  Redirect = 'Redirect',
}

type SsoBindingType = IdentityProviderConfigFragment['binding']

const schema = yup.object().shape({
  binding: yup.string().oneOf(Object.values(Binding)),
  signInUrl: yup
    .string()
    .trim()
    .matches(/(http|https):\/\//, 'URL must start with http or https')
    .url('This is not a valid URL')
    .required('Enter a sign-in URL'),
  identityProviderCertificate: yup
    .string()
    .trim()
    .required('Identity Provider certificate is required'),
  issuerEntityId: yup.string().trim().required('Enter an EntityID'),
})

type Values = {
  binding: Binding
  signInUrl: string
  identityProviderCertificate: string
  issuerEntityId: string
}

const mapToBindingType = (binding: Binding): SsoBindingType => {
  switch (binding) {
    case Binding.Post:
      return 'POST'
    case Binding.Redirect:
      return 'REDIRECT'
    default:
      ErrorHandler.shouldNeverHappen(`Unknown binding ${binding}`)
      return 'POST'
  }
}

const mapFromBindingType = (binding: SsoBindingType): Binding => {
  switch (binding) {
    case 'POST':
      return Binding.Post
    case 'REDIRECT':
      return Binding.Redirect
    default:
      ErrorHandler.shouldNeverHappen(`Unknown binding type ${binding}`)
      return Binding.Post
  }
}

interface SsoSamlSetupFormProps {
  formId?: string
  customerId: string
  identityProviderConfig?: IdentityProviderConfigFragment
  disabled?: boolean
  onSuccess?: () => void
  onSubmitStart?: () => void
  onError?: (message?: string) => void
}

export const SsoSamlSetupForm = (props: SsoSamlSetupFormProps) => {
  const {
    formId,
    customerId,
    identityProviderConfig,
    disabled,
    onSuccess,
    onSubmitStart,
    onError,
  } = props

  const [updateIdentityProviderConfig] =
    useUpdateWorkspaceCustomerSsoIdentityProviderConfigMutation({
      onError: ({ message }) => {
        onError?.(message)
      },
      onCompleted: () => {
        onSuccess?.()
      },
    })

  const onSubmit = async (values: Values) => {
    onSubmitStart?.()

    return updateIdentityProviderConfig({
      variables: {
        customerId,
        config: {
          binding: mapToBindingType(values.binding),
          certificate: values.identityProviderCertificate,
          entityId: values.issuerEntityId,
          ssoUrl: values.signInUrl,
        },
      },
    })
  }

  const initialValues: Values = {
    binding: identityProviderConfig?.binding
      ? mapFromBindingType(identityProviderConfig!.binding)
      : Binding.Post,
    identityProviderCertificate:
      identityProviderConfig?.certificate?.trim() ?? '',
    issuerEntityId: identityProviderConfig?.entityId ?? '',
    signInUrl: identityProviderConfig?.ssoUrl ?? '',
  }

  return (
    <Formik
      enableReinitialize={
        /**
         * Forces Formik to re-render if initial values changed only if the
         * form is in read-only mode, that is, disabled. This let us upload an
         * XML file and automatically show the updated fields.
         */
        disabled
      }
      validationSchema={schema}
      onSubmit={onSubmit}
      initialValues={initialValues}
    >
      {formikProps => (
        <Form>
          <Flex flexDirection="column">
            <Form.Field
              name="signInUrl"
              label="Sign-In Page URL"
              errorText={
                formikProps.touched.signInUrl
                  ? formikProps.errors.signInUrl
                  : undefined
              }
              mt={6}
              mb={0}
            >
              <Input
                name="signInUrl"
                type="text"
                aria-label="Sign-In Page URL"
                placeholder="https://sso.acme.com/signin"
                value={formikProps.values.signInUrl}
                onChange={formikProps.handleChange}
                onBlur={formikProps.handleBlur}
                disabled={disabled}
              />
            </Form.Field>
            <StyledCaption mt={0} mb={0}>
              URL for signing in to your system
            </StyledCaption>
          </Flex>

          <Form.Field
            name="binding"
            label="Binding"
            errorText={
              formikProps.touched.binding
                ? formikProps.errors.binding
                : undefined
            }
            mt={4}
          >
            <Flex flexDirection="row">
              <SamlRadioButton
                label={Binding.Post}
                value={Binding.Post}
                checked={formikProps.values.binding === Binding.Post}
                name="binding"
                onChange={formikProps.handleChange}
                disabled={disabled}
              />
              <SamlRadioButton
                label={Binding.Redirect}
                value={Binding.Redirect}
                checked={formikProps.values.binding === Binding.Redirect}
                name="binding"
                onChange={formikProps.handleChange}
                disabled={disabled}
              />
            </Flex>
          </Form.Field>

          <Flex flexDirection="column" mt={1}>
            <Form.Field
              name="identityProviderCertificate"
              label="Identity Provider Certificate"
              errorText={
                formikProps.touched.identityProviderCertificate
                  ? formikProps.errors.identityProviderCertificate
                  : undefined
              }
              mb={0}
            >
              <CertificateTextArea
                data-testid="certificate-input"
                name="identityProviderCertificate"
                value={formikProps.values.identityProviderCertificate}
                onChange={formikProps.handleChange}
                onBlur={formikProps.handleBlur}
                aria-label="Certificate"
                disabled={disabled}
              />
            </Form.Field>
            <StyledCaption>
              Must contain the public key for Sketch to verify sign-in requests
            </StyledCaption>
          </Flex>

          <Form.Field
            name="issuerEntityId"
            label="Issuer Entity ID"
            errorText={
              formikProps.touched.issuerEntityId
                ? formikProps.errors.issuerEntityId
                : undefined
            }
            mt={4}
          >
            <Input
              name="issuerEntityId"
              type="text"
              value={formikProps.values.issuerEntityId}
              onChange={formikProps.handleChange}
              onBlur={formikProps.handleBlur}
              aria-label="Issuer Entity ID"
              disabled={disabled}
            />
          </Form.Field>

          {formId && <input type="submit" id={formId} hidden />}
        </Form>
      )}
    </Formik>
  )
}
