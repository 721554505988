import { useCallback, useEffect, useRef, useState } from 'react'

import { ErrorHandler } from '@sketch/tracing'

import {
  SharePublicPermissionsFragment,
  useUpdateSharePublicPermissionsMutation,
  usePublishShareMutation,
  useUnpublishShareMutation,
  UpdateSharePublicPermissionsMutationVariables,
  GetWorkspaceProfileIdentifierDocument,
  GetWorkspaceProfileIdentifierQueryVariables,
  GetProfilePublicationsDocument,
  GetProfilePublicationsQueryVariables,
} from '@sketch/gql-types'

import { useToast } from '@sketch/toasts'

// Usage of PublicAccessLevel and PrivateAccessLevel will not break the build
// eslint-disable-next-line no-restricted-imports
import { PublicAccessLevel } from '@sketch/gql-types/expansive'

export type ShareForPublicPermissions = Pick<
  SharePublicPermissionsFragment,
  | 'identifier'
  | 'name'
  | 'publicAccessLevel'
  | 'publicCommentsEnabled'
  | 'publicInspectEnabled'
  | 'userCanUpdateCommentsEnabled'
  | 'userCanPublish'
  | 'publication'
  | 'sharePrivacyIcon'
>

type UpdateSharePublicVariables = Partial<
  OmitSafe<UpdateSharePublicPermissionsMutationVariables['input'], 'identifier'>
>

export type ModalTypes =
  | 'confirm-private'
  | 'confirm-private-community'
  | 'confirm-publish-community'
  | 'confirm-unpublish-community'

const createShareOptimisticResponse = (
  share: ShareForPublicPermissions,
  publication: ShareForPublicPermissions['publication'],
  publicAccessLevel: ShareForPublicPermissions['publicAccessLevel']
) => ({
  ...share,

  __typename: 'Share' as const,
  identifier: share.identifier,
  publicAccessLevel,
  publicCommentsEnabled: share.publicCommentsEnabled,
  publicInspectEnabled: share.publicInspectEnabled,
  publication,
})

export interface UsePublicPermissionsProps {
  share: ShareForPublicPermissions
  workspaceIdentifier: string
}

const usePublicPermissions = (props: UsePublicPermissionsProps) => {
  const { share, workspaceIdentifier } = props
  const { showToast } = useToast()
  const [permissions, setPermissions] = useState({
    publicInspectEnabled: share.publicInspectEnabled,
    publicCommentsEnabled: share.publicCommentsEnabled,
    publicAccessLevel: share.publicAccessLevel,
  })

  const stableShareRef = useRef(share)
  useEffect(() => {
    stableShareRef.current = share
  }, [share])

  const refetchQueries = useCallback(() => {
    const getWorkspaceProfileVariables: GetWorkspaceProfileIdentifierQueryVariables =
      {
        identifier: workspaceIdentifier,
      }

    const getProfilePublicationsVariables: GetProfilePublicationsQueryVariables =
      {
        workspaceIdentifier: workspaceIdentifier,
      }

    return [
      {
        query: GetWorkspaceProfileIdentifierDocument,
        variables: getWorkspaceProfileVariables,
      },
      {
        query: GetProfilePublicationsDocument,
        variables: getProfilePublicationsVariables,
      },
    ]
  }, [workspaceIdentifier])

  const [_publishShare] = usePublishShareMutation({
    onError: 'show-toast',
    optimisticResponse: () => ({
      __typename: 'RootMutationType',
      publishShare: {
        __typename: 'PublishShareResponse',
        publication: {
          __typename: 'Publication',
          share: {
            ...createShareOptimisticResponse(
              share,
              {
                __typename: 'Publication',
                identifier: 'some-publish-identifier',
              },
              'VIEW'
            ),
          },
        },
      },
    }),
    onCompleted: () => {
      showToast(`“${share.name}” successfully published`)
    },
    refetchQueries,
    awaitRefetchQueries: true,
  })

  const publishShare = useCallback(() => {
    const share = stableShareRef.current

    return _publishShare({
      variables: {
        shareIdentifier: share.identifier,
        publicCommentsEnabled: share.publicCommentsEnabled,
        publicInspectEnabled: share.publicInspectEnabled,
      },
    })
  }, [_publishShare])

  const [_unpublishShare] = useUnpublishShareMutation({
    onError: 'show-toast',
    optimisticResponse: () => ({
      __typename: 'RootMutationType',
      unpublishShare: {
        __typename: 'UnpublishShareResponse',
        share: {
          ...createShareOptimisticResponse(share, null, 'NONE'),
        },
      },
    }),
    onCompleted: ({ unpublishShare }) => {
      showToast(`“${share.name}” successfully unpublished`)

      // Forcing the state to be updated since unpublishing a share
      // can cause the "publicAccessLevel" to be "NONE"
      const updatedShare = unpublishShare?.share
      updatedShare &&
        setPermissions(prev => ({
          ...prev,
          publicAccessLevel: updatedShare.publicAccessLevel,
        }))
    },
    refetchQueries,
    awaitRefetchQueries: true,
  })

  const unpublishShare = useCallback(
    ({ publicAccessLevel }: { publicAccessLevel: PublicAccessLevel }) => {
      const share = stableShareRef.current

      return _unpublishShare({
        variables: {
          shareIdentifier: share.identifier,
          publicAccessLevel,
          publicCommentsEnabled: share.publicCommentsEnabled,
          publicInspectEnabled: share.publicInspectEnabled,
        },
      })
    },
    [_unpublishShare]
  )

  const [_updatePublicPermissions] = useUpdateSharePublicPermissionsMutation({
    onError() {
      showToast(
        'There was a problem changing the public link permissions. Please try again.',
        'negative'
      )
    },
    onCompleted(data) {
      if (!data?.updateSharePublicPermissions) {
        ErrorHandler.shouldNeverHappen(
          'Share update workspace permissions mutation should return valid data'
        )
      }
    },
    optimisticResponse: variables => {
      setPermissions({
        publicInspectEnabled: variables.input.publicInspectEnabled,
        publicCommentsEnabled: variables.input.publicCommentsEnabled,
        publicAccessLevel: variables.input.publicAccessLevel,
      })

      return {
        __typename: 'RootMutationType',
        updateSharePublicPermissions: {
          __typename: 'Share',
          ...share,

          publicCommentsEnabled: variables.input.publicCommentsEnabled,
          publicAccessLevel: variables.input.publicAccessLevel,
          publicInspectEnabled: variables.input.publicInspectEnabled,
        },
      }
    },
  })

  const updatePublicPermissions = useCallback(
    (variables: UpdateSharePublicVariables) => {
      const share = stableShareRef.current

      return _updatePublicPermissions({
        variables: {
          input: {
            identifier: share.identifier,
            ...permissions,
            ...variables,
          },
        },
      })
    },
    [_updatePublicPermissions, permissions]
  )

  return {
    updatePublicPermissions,
    unpublishShare,
    publishShare,
    permissions,
  }
}

export default usePublicPermissions
