import { routes } from '@sketch/modules-common'
import { pick } from '@sketch/utils'

import { acceptShareInviteByInviteTokenRoutes } from 'cloud-frontend'

const ownInviteRoutes = pick(routes, [
  // 'WORKSPACE_INVITE',
  'SHARE_INVITE',
  'PROJECT_ACCEPT_INVITE',
])

export const inviteRoutes = {
  ...ownInviteRoutes,
  // TODO: move and flatten out AcceptShareInviteByInviteTokenView routing from AcceptShareInviteByInviteTokenView to AcceptInviteRoutes
  // see: https://github.com/sketch-hq/Cloud/issues/17153
  ...acceptShareInviteByInviteTokenRoutes,
}

export const allAcceptInviteRouteTemplates = Object.values(inviteRoutes).map(
  x => x.template()
)
