import { SKETCH_WEBSITE } from '@sketch/env-config'

import {
  FirstStepsCardGetPropsHook,
  FirstStepsStepId,
  StepVisibilityVariables,
} from './types'
import { STEPS_ID_MAP } from './constants'

/** @Todo import 32px version of this? */
import { ReactComponent as DocumentUploadIcon } from '@sketch/icons/document-upload-48'
import { ReactComponent as BookOpenIcon } from '@sketch/icons/book-open-48'
import { ReactComponent as PersonsIcon } from '@sketch/icons/persons-2-48'
import { ReactComponent as MacAppDownloadIcon } from '@sketch/icons/mac-app-download-48'
import { ReactComponent as LicenseDiamondIcon } from '@sketch/icons/license-diamond-48'
import { ReactComponent as WorkspaceIcon } from '@sketch/icons/workspace-48'
import { ReactComponent as EnvelopeCheckmarkIcon } from '@sketch/icons/envelope-checkmark-48'
import { ReactComponent as ShieldIcon } from '@sketch/icons/shield-48'
import { ReactComponent as GraduateCapIcon } from '@sketch/icons/graduate-cap-48'

import { useToast } from '@sketch/toasts'

import { useResendVerificationEmailMutation } from '@sketch/gql-types'
import {
  LaunchSketchAlert,
  useAnalytics,
  routes,
  useUserProfile,
} from '@sketch/modules-common'
import { useCallback } from 'react'
import { pluralize, useModalContext } from '@sketch/components'
import { useWorkspaceSettings } from '../../operations'
import { InviteMembersModal } from '../../modals'
import { useCurrentWorkspaceInPrivateWorkspaceRoute } from '../../hooks'

/**
 * For each possible step define dynamically the props that will be used by the
 * <FirstStepsCard /> to display the step in the banner. The function associated with each
 * step is a React custom hook that will be called inside <FirstStepsCard />. The normal
 * rules associated with the usage of hooks in React therefore apply.
 */
export const stepPropertiesHooksByStepId: Record<
  FirstStepsStepId,
  FirstStepsCardGetPropsHook
> = {
  DOWNLOAD_APP: () => {
    return {
      id: STEPS_ID_MAP.DOWNLOAD_APP,
      title: 'Get the Mac App',
      description:
        'Download the Mac app to create documents and start collaborating',
      icon: MacAppDownloadIcon,
      action: {
        link: {
          href: `${SKETCH_WEBSITE}/downloads/mac`,
          external: true,
        },
        text: 'Download',
      },
    }
  },
  VERIFY_EMAIL: () => {
    const { data: userProfile } = useUserProfile()
    const email = userProfile?.me?.email
    const { showToast } = useToast()
    const { trackEvent } = useAnalytics()

    const [resendVerificationEmail] = useResendVerificationEmailMutation({
      redirectErrors: true,
      onCompleted: () => showToast(`An email has been sent`),
      onError: 'show-toast',
    })

    return {
      id: STEPS_ID_MAP.VERIFY_EMAIL,
      title: 'Verify Your Email',
      description: `Check ${email} and confirm your email`,
      icon: EnvelopeCheckmarkIcon,
      action: {
        onClick: () => {
          trackEvent('ONBOARDING - resend verify email', {
            source: 'verify card',
          })
          resendVerificationEmail()
        },
        text: 'Resend Email',
      },
    }
  },
  DOCUMENTATION: () => {
    return {
      id: STEPS_ID_MAP.DOCUMENTATION,
      title: 'Get Better at Sketch',
      description: `Beginner or expert, here’s everything you need to make the most of Sketch`,
      icon: BookOpenIcon,
      action: {
        link: {
          href: `${SKETCH_WEBSITE}/docs`,
          // target: '_blank',
          external: true,
        },
        text: 'View Documentation',
      },
    }
  },
  UPLOAD_DOCUMENTS: () => {
    const { showModal } = useModalContext()

    const showOpenSketchModal = useCallback(() => {
      showModal(LaunchSketchAlert, {
        action: 'openInSketch',
        /* We leave the "shareId" empty on purpose since it's a new share */
        shareId: '',
      })
    }, [showModal])

    return {
      id: STEPS_ID_MAP.UPLOAD_DOCUMENTS,
      title: 'Save a Document to the Workspace',
      description: `Create and save a document in the Mac App to see it in your Workspace`,
      icon: DocumentUploadIcon,
      action: {
        onClick: showOpenSketchModal,
        text: 'Create Document',
      },
    }
  },
  COLLABORATION: () => {
    return {
      id: STEPS_ID_MAP.COLLABORATION,
      title: 'Start Collaborating',
      description: 'Invite people as Editors or Viewers to start collaborating',
      icon: PersonsIcon,
      action: {
        link: {
          to: routes.WORKSPACE_AGNOSTIC_SETTINGS_PEOPLE.create({}),
        },
        text: 'Invite People',
      },
    }
  },
  SUBSCRIBE: () => {
    return {
      id: STEPS_ID_MAP.SUBSCRIBE,
      title: 'Subscribe to Sketch',
      description: 'Subscribe to keep using Sketch after the trial ends',
      icon: LicenseDiamondIcon,
      action: {
        link: {
          to: routes.WORKSPACE_AGNOSTIC_SETTINGS_BILLING.create({}),
        },
        text: 'Set Up Subscription',
      },
    }
  },
  UPGRADE_PERSONAL_WORKSPACE: () => {
    return {
      id: STEPS_ID_MAP.UPGRADE_PERSONAL_WORKSPACE,
      title: 'Upgrade Your Legacy Workspace',
      description: 'Switch to a subscription, starting with a free trial',
      icon: WorkspaceIcon,
      action: {
        link: {
          to: routes.WORKSPACE_AGNOSTIC_SETTINGS_PEOPLE.create({}),
        },
        text: 'Start Upgrade',
      },
    }
  },
  ADD_EDITORS: () => {
    const workspace = useCurrentWorkspaceInPrivateWorkspaceRoute()
    const { data: workspaceSettings } = useWorkspaceSettings(workspace)

    const availableSeats =
      workspaceSettings?.workspace?.customer?.billing?.seats.availableSeats || 0

    const editorsCopy = pluralize('Editor', 'Editors', availableSeats)
    const title = `Ready to Add ${availableSeats} ${editorsCopy}`
    const description = pluralize(
      'Invite somebody to edit Sketch documents',
      'Invite people to edit Sketch documents',
      availableSeats
    )

    const { showRestrictedModal } = useModalContext()

    const handleShowModal = useCallback(() => {
      const isOnTrial =
        workspaceSettings.workspace?.customer?.billing?.status === 'TRIALING'
      const plan = workspaceSettings.customer?.subscriptionInfo?.currentPlan!
      const nextBillingCycleDate =
        workspaceSettings.customer?.subscriptionInfo.renewalDate!
      const seats = workspaceSettings.workspace?.customer?.billing?.seats!
      const paymentDetails = workspaceSettings.customer?.paymentDetails!
      const isScheduledToBeCancelled =
        !!workspaceSettings.workspace?.customer?.billing?.subscriptionEnd

      showRestrictedModal(InviteMembersModal, {
        workspaceId: workspaceSettings.workspace?.identifier!,
        customerId: workspaceSettings.customer?.id!,
        isOnTrial,
        nextBillingCycleDate,
        plan,
        seats,
        paymentDetails,
        isScheduledToBeCancelled,
      })
    }, [showRestrictedModal, workspaceSettings])

    return {
      id: STEPS_ID_MAP.ADD_EDITORS,
      title: title,
      description: description,
      icon: LicenseDiamondIcon,
      action: {
        onClick: handleShowModal,
        text: `Invite ${editorsCopy}`,
      },
    }
  },
  MFA: () => {
    return {
      id: STEPS_ID_MAP.MFA,
      title: 'Protect Your Account',
      description:
        'Use two-factor authentication (2FA) when you sign in to better protect your account',
      icon: ShieldIcon,
      action: {
        link: {
          to: routes.PERSONAL_SETTINGS.create({}),
        },
        text: 'Manage Account',
      },
    }
  },
  EDUCATION: () => {
    return {
      id: STEPS_ID_MAP.EDUCATION,
      title: 'Get a Free Workspace',
      description:
        'Sketch for Education offers free Workspaces to students and teachers',
      icon: GraduateCapIcon,
      action: {
        link: {
          href: `${SKETCH_WEBSITE}/store/education`,
          external: true,
        },
        text: 'Send Your Details',
      },
    }
  },
}

/**
 * For each step id, associate a function that we will use to determine if this
 * particular step should be shown or not based on a set of predefined variables
 * available to all functions. Feel free to add new variables in
 * `useStepVisibilityFilter` (useFirstSteps.ts) if needed.
 */
const stepVisibilityByStepId: Record<
  keyof typeof STEPS_ID_MAP,
  (variables: StepVisibilityVariables) => boolean
> = {
  UPGRADE_PERSONAL_WORKSPACE: ({ isPersonalWorkspace }) => isPersonalWorkspace,
  DOWNLOAD_APP: ({ isEditor, canSubscribeStripe }) =>
    isEditor && canSubscribeStripe,
  VERIFY_EMAIL: ({ isVerified }) => !isVerified,
  DOCUMENTATION: () => true,
  SUBSCRIBE: ({ isAdmin, hasSubscription }) => isAdmin && !hasSubscription,
  COLLABORATION: ({ isAdmin, hasSubscription, availableSeats }) =>
    isAdmin && hasSubscription && availableSeats === 0,
  ADD_EDITORS: ({ isAdmin, hasSubscription, availableSeats }) =>
    isAdmin && hasSubscription && availableSeats > 0,
  EDUCATION: ({ isEducationWorkspace }) => !isEducationWorkspace,
  MFA: ({ isMfaEnabled, isVerified, isSsoWorkspace }) =>
    !isMfaEnabled && isVerified && !isSsoWorkspace,
  UPLOAD_DOCUMENTS: ({ isAdmin, isEditor }) => isAdmin || isEditor,
}

/**
 * Check if a specific step should be visible to the user based on the stepVisibilityVariables.
 */
export function isStepVisible(
  stepId: FirstStepsStepId,
  stepVisibilityVariables: StepVisibilityVariables
) {
  const stepVisibilityFunction = stepVisibilityByStepId[stepId]

  return stepVisibilityFunction(stepVisibilityVariables)
}
