import React from 'react'

import { stripMarkdownParser } from '@sketch/utils'

import NotificationListItemAvatar from './NotificationListItemAvatar'

import {
  NotificationShareName,
  NotificationBody,
  Markdown,
} from '../NotificationListItem.styles'

import { formatAnnotationSubject } from './utils'

import { CommentNotificationFragment } from '@sketch/gql-types'
import { TruncateWithTooltip } from '@sketch/components'

interface CommentNotificationListItemProps {
  notification: CommentNotificationFragment
}

const CommentNotificationListItem: React.FC<
  CommentNotificationListItemProps
> = ({ notification }) => {
  const { share, comment } = notification
  const name = formatAnnotationSubject(comment?.annotation)
  const userAvatar = notification.user?.avatar?.small

  return (
    <>
      <NotificationShareName data-testid="notification-share-name">
        <TruncateWithTooltip>
          {share?.workspace.name} / {share?.name} {name && <>/ {name}</>}
        </TruncateWithTooltip>
      </NotificationShareName>

      <NotificationBody data-testid="notification-body">
        <NotificationListItemAvatar
          avatar={userAvatar ?? ''}
          name={notification.user?.name ?? ''}
        />
        <Markdown parser={stripMarkdownParser}>
          {notification?.comment?.body || ''}
        </Markdown>
      </NotificationBody>
    </>
  )
}

export default CommentNotificationListItem
