import { StorageItemFragment } from '@sketch/gql-types'
import { removeFromPaginated, removeQuery } from '@sketch/modules-common'
import { DataProxy } from 'apollo-cache'
import { updateProjectListCounts } from '../../../projects/operations'
import type { StorageItemTransferLocation } from './useTransferStorageItem'
import { ErrorHandler } from '@sketch/tracing'
import { castError } from '@sketch/utils'

export interface UpdateStorageItemsCacheArgs {
  cache: DataProxy
  location: StorageItemTransferLocation
  item: Pick<StorageItemFragment, '__typename' | 'identifier' | 'name'>
}

export const updateCacheAfterStorageItemTransferred = (
  args: UpdateStorageItemsCacheArgs
) => {
  const { cache, location, item } = args

  // Remove storage item from the previous project
  if (location.source.project) {
    const prevProject = location.source.project
    removeFromPaginated(cache, item, prevProject)

    // we are using custom counters for nested projects and shares
    // therefore we need to update them manually
    try {
      updateProjectListCounts(cache, prevProject.identifier, oldProject => {
        if (item.__typename === 'Share') {
          oldProject.sharesCount--
        } else {
          oldProject.nestedProjectsCount--
        }
      })
    } catch (e) {
      ErrorHandler.ignore(castError(e))
    }
  }

  const prevWorkspace = location.source.workspace
  const targetWorkspace = location.destination.workspace

  if (prevWorkspace.identifier !== targetWorkspace.identifier) {
    removeFromPaginated(cache, item, prevWorkspace)

    removeQuery(cache, key => {
      return (
        key.startsWith('$') &&
        key.includes(targetWorkspace.identifier) &&
        key.includes('.projects')
      )
    })
  }

  const destinationProject = location.destination.project
  if (destinationProject) {
    removeQuery(cache, key => {
      return (
        key.startsWith('$') &&
        key.includes(destinationProject.identifier) &&
        key.includes('.storageItems')
      )
    })
  }

  if (item.__typename === 'Share') {
    const destinationWorkspace = location.destination.workspace

    removeQuery(cache, key => {
      return (
        key.startsWith('$') &&
        key.includes(destinationWorkspace.identifier) &&
        key.includes('.shares')
      )
    })
  }
}
