import { Spinner, ErrorMessage, ModalInjectedProps } from '@sketch/components'
import {
  ProjectDetailsFragment,
  ShareInfoFragment,
  StorageItemFragment,
  useGetShareQuery,
} from '@sketch/gql-types'
import React from 'react'
import {
  Footnote,
  FootnoteText,
  ModalBody,
} from './MoveStorageItemModal.styles'

import {
  MoveStorageItemModalFooter,
  MoveStorageItemModalLayout,
} from './MoveStorageItemModalLayout'
import {
  ProjectPickerContent,
  TreeStateProvider,
  TreeNodeTypes,
  useCurrentTreeState,
  useIsCreatingProject,
} from '../../../projects/modals/ProjectPickerContent'
import { useMoveStorageItem } from './useMoveStorageItem'
import { useGetProjectDetailsQuery } from '@sketch/gql-types/src/generated/gqlTypes/apollo'
import {
  MoveStorageItemModalHeader,
  MoveStorageItemModalHeaderLoading,
} from './MoveStorageItemModalHeader'

interface MoveModalFooterProps {
  onClick: (data: TreeNodeTypes) => void
  loading: boolean
  itemType: 'Document' | 'Folder'
}

export const MoveModalFooter = (props: MoveModalFooterProps) => {
  const { onClick, loading, itemType } = props
  // WARNING: Use useCurrentTreeState subscribes to all changes in the tree state
  // and will re-render the component on every change and can lead to severely
  // degraded performance issues on large trees if not used correctly.
  //
  // To use it correctly - use it in the lowest possible node of React components tree
  // to avoid unnecessary re-renders of other components.
  const { selectedNode, currentIds } = useCurrentTreeState()

  const { isCreatingProject } = useIsCreatingProject()

  const payload = selectedNode?.payload
  const isEnabled =
    payload?.identifier && payload?.identifier !== currentIds.currentId

  return (
    <MoveStorageItemModalFooter
      disabled={!isEnabled}
      loading={loading}
      dimmed={isCreatingProject}
      itemType={itemType}
      onClick={() => {
        if (!payload) return
        onClick(payload)
      }}
    >
      <Footnote>
        <FootnoteText>
          {selectedNode?.payload &&
          selectedNode.payload.identifier !== currentIds.currentId ? (
            <>
              Move to <b>{selectedNode.payload.name}</b>
            </>
          ) : (
            <>
              Select a <b>project</b>
            </>
          )}
        </FootnoteText>
      </Footnote>
    </MoveStorageItemModalFooter>
  )
}

interface MoveModalInnerProps {
  item: ShareInfoFragment | ProjectDetailsFragment
  hideModal: () => void
}

const MoveModalInner = (props: MoveModalInnerProps) => {
  const { item, hideModal } = props
  const [moveStorageItem, { loading, ConfirmationModal }] = useMoveStorageItem(
    item,
    hideModal
  )

  if (ConfirmationModal) {
    return <ConfirmationModal loading={loading} />
  }

  return (
    <MoveStorageItemModalLayout>
      <MoveStorageItemModalHeader item={item} />
      <ProjectPickerContent />
      <MoveModalFooter
        itemType={item.__typename === 'Share' ? 'Document' : 'Folder'}
        loading={loading}
        onClick={data => moveStorageItem(data)}
      />
    </MoveStorageItemModalLayout>
  )
}

export interface MoveStorageItemModalProps extends ModalInjectedProps {
  item: Pick<StorageItemFragment, 'identifier' | '__typename'>
  workspaceIdentifier: string
  currentProjectIdentifier: string | null
}

export const MoveStorageItemModal: React.FC<
  MoveStorageItemModalProps
> = props => {
  const { item, workspaceIdentifier, currentProjectIdentifier, hideModal } =
    props

  const itemType = item.__typename === 'Share' ? 'Document' : 'Folder'

  const shareQuery = useGetShareQuery({
    variables: { shortId: item.identifier },
    skip: item.__typename !== 'Share',
  })

  const projectQuery = useGetProjectDetailsQuery({
    variables: { projectIdentifier: item.identifier },
    skip: item.__typename !== 'Project',
  })

  const loading = shareQuery.loading || projectQuery.loading
  const error = shareQuery.error || projectQuery.error

  if (loading) {
    return (
      <MoveStorageItemModalLayout>
        <MoveStorageItemModalHeaderLoading />
        <ModalBody>
          <Spinner.Flex primary />
        </ModalBody>
        <MoveStorageItemModalFooter itemType={itemType} disabled loading />
      </MoveStorageItemModalLayout>
    )
  }

  const share = shareQuery.data?.share
  const project = projectQuery.data?.project
  const itemFull = share || project

  if (error || !itemFull) {
    return (
      <MoveStorageItemModalLayout>
        <MoveStorageItemModalHeaderLoading />
        <ModalBody>
          <ErrorMessage.Generic />
        </ModalBody>
        <MoveStorageItemModalFooter itemType={itemType} disabled />
      </MoveStorageItemModalLayout>
    )
  }

  return (
    <TreeStateProvider
      current={{
        projectId: currentProjectIdentifier,
        workspaceId: workspaceIdentifier,
        selfProjectId: project?.identifier || null,
      }}
    >
      <MoveModalInner item={itemFull} hideModal={hideModal} />
    </TreeStateProvider>
  )
}
