export const SYSTEM_FONT =
  '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif'

/**
 * Font family design tokens.
 *
 * Typically defined as an array of scaled numerical pixel values, here we've
 * decided to define fontSizes as aliases pointing to rem value strings.
 *
 * For use with the following CSS properties:
 *
 *     font-family, font
 *
 * @see https://system-ui.com/theme
 */
export const fonts = {
  body: `Inter, ${SYSTEM_FONT}`,
  buttons: `Inter, ${SYSTEM_FONT}`,
  formLabels: `Inter, ${SYSTEM_FONT}`,
  headings: `Inter, ${SYSTEM_FONT}`,
  primary: `Inter, ${SYSTEM_FONT}`,
  monospace: 'Inter Mono, monospace',
} as const

/**
 * Font size design tokens.
 *
 * Typically defined as an array of scaled numerical pixel values, here we've
 * decided to define fontSizes as aliases pointing to rem value strings.
 *
 * For use with the following CSS properties:
 *
 *     font-size, font
 *
 * @see https://system-ui.com/theme
 */
export const fontSizes = {
  A: '0.625rem', // 10px
  B: '0.6875rem', // 11px
  C: '0.75rem', // 12px
  D: '0.8125rem', // 13px
  E: '0.875rem', // 14px
  F: '1rem', // 16px
  G: '1.25rem', // 20px
  H: '1.5rem', // 24px
  I: '2rem', // 32px
} as const

/**
 * Font weight design tokens.
 *
 * For use with the following CSS properties:
 *
 *     font-weight, font
 *
 * @see https://system-ui.com/theme
 */
export const fontWeights = {
  thin: 100,
  light: 300,
  regular: 400,
  medium: 500,
  semibold: 600,
  bold: 700,
} as const

/**
 * Line height design tokens.
 *
 * For use with the following CSS properties:
 *
 *     line-height, font
 *
 * @see https://system-ui.com/theme
 */
export const lineHeights = {
  A: 0,
  B: 1,
  C: 1.2,
  D: 1.3,
  E: 1.5,
  F: 1.6,
  default: 1.5,
} as const

/**
 * Text styles variants, with pros/cons of storing them in the theme instead in
 * our component (where we could access our theme object)
 *
 * Pros: collocation, wide usage (you can use it with a prop or accessing the
 *       theme text style)
 * Cons: no typed (it's just a "magic" string), silents errors
 */
export const textStyles = {
  // 1 Header
  // --------------------------------------------------------------------------
  header: {
    primary: {
      G: {
        fontSize: 'G',
        fontWeight: 'bold',
        lineHeight: 'F',
        color: 'foreground.secondary.A',
      },
      H: {
        fontSize: 'H',
        fontWeight: 'bold',
        lineHeight: 'D',
        color: 'foreground.secondary.A',
      },
      I: {
        fontSize: 'I',
        fontWeight: 'bold',
        lineHeight: 'D',
        color: 'foreground.secondary.A',
      },
    },
    disabled: {
      H: {
        fontSize: 'H',
        fontWeight: 'bold',
        lineHeight: 'D',
        color: 'state.disabled',
      },
    },
  },

  // 2 Subheader
  // --------------------------------------------------------------------------
  subheader: {
    primary: {
      standard: {
        B: {
          fontSize: 'B',
          fontWeight: 'semibold',
          lineHeight: 'C',
          color: 'foreground.secondary.A',
          textTransform: 'uppercase',
        },
      },
      bold: {
        B: {
          fontSize: 'B',
          fontWeight: 'semibold',
          lineHeight: 'C',
          color: 'foreground.secondary.A',
          textTransform: 'uppercase',
        },
        C: {
          fontSize: 'C',
          fontWeight: 'semibold',
          lineHeight: 'D',
          color: 'foreground.secondary.A',
          textTransform: 'uppercase',
        },
      },
    },
    secondary: {
      standard: {
        B: {
          fontSize: 'B',
          fontWeight: 'medium',
          lineHeight: 'C',
          color: 'foreground.secondary.B',
          textTransform: 'uppercase',
        },
      },
      bold: {
        B: {
          fontSize: 'B',
          fontWeight: 'semibold',
          lineHeight: 'C',
          color: 'foreground.secondary.B',
          textTransform: 'uppercase',
        },
      },
    },
    tertiary: {
      standard: {
        B: {
          fontSize: 'B',
          fontWeight: 'semibold',
          lineHeight: 'C',
          color: 'foreground.secondary.C',
          textTransform: 'uppercase',
        },
      },
      bold: {
        B: {
          fontSize: 'B',
          fontWeight: 'semibold',
          lineHeight: 'C',
          color: 'foreground.secondary.C',
          textTransform: 'uppercase',
        },
      },
    },
    quaternary: {
      standard: {
        B: {
          fontSize: 'B',
          fontWeight: 'medium',
          lineHeight: 'C',
          color: 'foreground.secondary.D',
          textTransform: 'uppercase',
        },
      },
      bold: {
        C: {
          fontSize: 'C',
          fontWeight: 'semibold',
          lineHeight: 'D',
          color: 'foreground.secondary.D',
          textTransform: 'uppercase',
        },
      },
    },
    disabled: {
      standard: {
        B: {
          fontSize: 'B',
          fontWeight: 'semibold',
          lineHeight: 'C',
          color: 'state.disabled',
          textTransform: 'uppercase',
        },
      },
    },
  },

  // 3 Copy
  // --------------------------------------------------------------------------
  copy: {
    primary: {
      standard: {
        C: {
          fontSize: 'C',
          lineHeight: 'D',
          color: 'foreground.secondary.A',
        },
        D: {
          fontSize: 'D',
          lineHeight: 'E',
          color: 'foreground.secondary.A',
        },
        E: {
          fontSize: 'E',
          lineHeight: 'E',
          color: 'foreground.secondary.A',
        },
        F: {
          fontSize: 'F',
          lineHeight: 'F',
          color: 'foreground.secondary.A',
        },
      },
      mono: {
        C: {
          fontFamily: 'monospace',
          fontSize: 'C',
          lineHeight: 'D',
          color: 'foreground.secondary.A',
        },
        E: {
          fontFamily: 'monospace',
          fontSize: 'E',
          lineHeight: 'E',
          color: 'foreground.secondary.A',
        },
      },
    },
    secondary: {
      standard: {
        C: {
          fontSize: 'C',
          lineHeight: 'D',
          color: 'foreground.secondary.B',
        },
        D: {
          fontSize: 'D',
          lineHeight: 'E',
          color: 'foreground.secondary.B',
        },
        E: {
          fontSize: 'E',
          lineHeight: 'E',
          color: 'foreground.secondary.B',
        },
        F: {
          fontSize: 'F',
          lineHeight: 'F',
          color: 'foreground.secondary.B',
        },
      },
      mono: {
        C: {
          fontFamily: 'monospace',
          fontSize: 'C',
          lineHeight: 'D',
          color: 'foreground.secondary.B',
        },
        D: {
          fontFamily: 'monospace',
          fontSize: 'D',
          lineHeight: 'E',
          color: 'foreground.secondary.B',
        },
      },
    },
    tertiary: {
      standard: {
        C: {
          fontSize: 'C',
          lineHeight: 'D',
          color: 'foreground.secondary.C',
        },
        D: {
          fontSize: 'D',
          lineHeight: 'E',
          color: 'foreground.secondary.C',
        },
        E: {
          fontSize: 'E',
          lineHeight: 'E',
          color: 'foreground.secondary.C',
        },
        F: {
          fontSize: 'F',
          lineHeight: 'F',
          color: 'foreground.secondary.C',
        },
      },
    },
    quaternary: {
      standard: {
        C: {
          fontSize: 'C',
          lineHeight: 'D',
          color: 'foreground.secondary.D',
        },
        D: {
          fontSize: 'D',
          lineHeight: 'E',
          color: 'foreground.secondary.D',
          fontWeight: 'semibold',
        },
        E: {
          fontSize: 'E',
          lineHeight: 'E',
          color: 'foreground.secondary.D',
        },
        F: {
          fontSize: 'F',
          lineHeight: 'F',
          color: 'foreground.secondary.D',
        },
      },
      italic: {
        D: {
          fontSize: 'D',
          fontStyle: 'italic',
          lineHeight: 'E',
          color: 'foreground.secondary.D',
        },
        E: {
          fontSize: 'E',
          fontStyle: 'italic',
          lineHeight: 'E',
          color: 'foreground.secondary.D',
        },
      },
    },
    disabled: {
      standard: {
        C: {
          fontSize: 'C',
          lineHeight: 'D',
          color: 'state.disabled',
        },
        D: {
          fontSize: 'D',
          lineHeight: 'E',
          color: 'state.disabled',
        },
        E: {
          fontSize: 'E',
          lineHeight: 'E',
          color: 'state.disabled',
        },
      },
      italic: {
        E: {
          fontSize: 'E',
          fontStyle: 'italic',
          lineHeight: 'E',
          color: 'state.disabled',
        },
      },
    },
    sketch: {
      standard: {
        C: {
          fontSize: 'C',
          lineHeight: 'D',
          color: 'sketch.A',
        },
        D: {
          fontSize: 'D',
          lineHeight: 'E',
          color: 'sketch.A',
        },
        E: {
          fontSize: 'E',
          lineHeight: 'E',
          color: 'sketch.A',
        },
      },
      mono: {
        C: {
          fontFamily: 'monospace',
          fontSize: 'C',
          lineHeight: 'D',
          color: 'sketch.A',
        },
      },
    },
    positive: {
      standard: {
        C: {
          fontSize: 'C',
          lineHeight: 'D',
          color: 'state.positive.A',
        },
        D: {
          fontSize: 'D',
          lineHeight: 'E',
          color: 'state.positive.A',
        },
        E: {
          fontSize: 'E',
          lineHeight: 'E',
          color: 'state.positive.A',
        },
      },
    },
    negative: {
      standard: {
        C: {
          fontSize: 'C',
          lineHeight: 'D',
          color: 'state.negative.A',
        },
        D: {
          fontSize: 'D',
          lineHeight: 'E',
          color: 'state.negative.A',
        },
      },
    },
    contrast: {
      standard: {
        D: {
          fontSize: 'D',
          lineHeight: 'E',
          color: 'foreground.primary.A',
        },
      },
    },
  },

  // 4 Button
  // --------------------------------------------------------------------------
  button: {
    primary: {
      standard: {
        C: {
          fontSize: 'C',
          fontWeight: 'semibold',
          lineHeight: 'D',
          color: 'foreground.primary.A',
        },
        D: {
          fontSize: 'D',
          fontWeight: 'semibold',
          lineHeight: 'E',
          color: 'foreground.primary.A',
        },
        E: {
          fontSize: 'E',
          fontWeight: 'semibold',
          lineHeight: 'E',
          color: 'foreground.primary.A',
        },
        F: {
          fontSize: 'F',
          fontWeight: 'semibold',
          lineHeight: 'F',
          color: 'foreground.primary.A',
        },
      },
      pressed: {
        C: {
          fontSize: 'C',
          fontWeight: 'semibold',
          lineHeight: 'D',
          color: 'foreground.primary.B',
        },
        D: {
          fontSize: 'D',
          fontWeight: 'semibold',
          lineHeight: 'E',
          color: 'foreground.primary.B',
        },
        E: {
          fontSize: 'E',
          fontWeight: 'semibold',
          lineHeight: 'E',
          color: 'foreground.primary.B',
        },
        F: {
          fontSize: 'F',
          fontWeight: 'semibold',
          lineHeight: 'F',
          color: 'foreground.primary.B',
        },
      },
    },
    secondary: {
      standard: {
        C: {
          fontSize: 'C',
          fontWeight: 'semibold',
          lineHeight: 'D',
          color: 'sketch.A',
        },
        D: {
          fontSize: 'D',
          fontWeight: 'semibold',
          lineHeight: 'E',
          color: 'sketch.A',
        },
        E: {
          fontSize: 'E',
          fontWeight: 'semibold',
          lineHeight: 'E',
          color: 'sketch.A',
        },
        F: {
          fontSize: 'F',
          fontWeight: 'semibold',
          lineHeight: 'F',
          color: 'sketch.A',
        },
      },
      disabled: {
        C: {
          fontSize: 'C',
          fontWeight: 'semibold',
          lineHeight: 'D',
          color: 'sketch.D',
        },
        D: {
          fontSize: 'D',
          fontWeight: 'semibold',
          lineHeight: 'E',
          color: 'sketch.D',
        },
        E: {
          fontSize: 'E',
          fontWeight: 'semibold',
          lineHeight: 'E',
          color: 'sketch.D',
        },
        F: {
          fontSize: 'F',
          fontWeight: 'semibold',
          lineHeight: 'F',
          color: 'sketch.D',
        },
      },
      toggled: {
        D: {
          fontSize: 'D',
          fontWeight: 'semibold',
          lineHeight: 'E',
          color: 'foreground.secondary.D',
        },
        E: {
          fontSize: 'E',
          fontWeight: 'semibold',
          lineHeight: 'E',
          color: 'foreground.secondary.D',
        },
      },
    },
    tertiary: {
      disabled: {
        D: {
          fontSize: 'D',
          fontWeight: 'semibold',
          lineHeight: 'E',
          color: 'state.disabled',
        },
        E: {
          fontSize: 'E',
          fontWeight: 'semibold',
          lineHeight: 'E',
          color: 'state.disabled',
        },
        F: {
          fontSize: 'F',
          fontWeight: 'semibold',
          lineHeight: 'F',
          color: 'state.disabled',
        },
      },
    },
  },

  // 5 Prominent
  // --------------------------------------------------------------------------
  prominent: {
    primary: {
      C: {
        fontSize: 'C',
        fontWeight: 'semibold',
        lineHeight: 'D',
        color: 'foreground.secondary.A',
      },
      D: {
        fontSize: 'D',
        fontWeight: 'semibold',
        lineHeight: 'E',
        color: 'foreground.secondary.A',
      },
      E: {
        fontSize: 'E',
        fontWeight: 'semibold',
        lineHeight: 'E',
        color: 'foreground.secondary.A',
      },
      F: {
        fontSize: 'F',
        fontWeight: 'semibold',
        lineHeight: 'E',
        color: 'foreground.secondary.A',
      },
    },
    secondary: {
      C: {
        fontSize: 'C',
        fontWeight: 'semibold',
        lineHeight: 'D',
        color: 'foreground.secondary.B',
      },
      D: {
        fontSize: 'D',
        fontWeight: 'semibold',
        lineHeight: 'E',
        color: 'foreground.secondary.B',
      },
      E: {
        fontSize: 'E',
        fontWeight: 'semibold',
        lineHeight: 'E',
        color: 'foreground.secondary.B',
      },
      F: {
        fontSize: 'F',
        fontWeight: 'semibold',
        lineHeight: 'F',
        color: 'foreground.secondary.B',
      },
    },
    tertiary: {
      C: {
        fontSize: 'C',
        fontWeight: 'semibold',
        lineHeight: 'D',
        color: 'foreground.secondary.C',
      },
      D: {
        fontSize: 'D',
        fontWeight: 'semibold',
        lineHeight: 'E',
        color: 'foreground.secondary.C',
      },
      E: {
        fontSize: 'E',
        fontWeight: 'semibold',
        lineHeight: 'E',
        color: 'foreground.secondary.C',
      },
      F: {
        fontSize: 'F',
        fontWeight: 'semibold',
        lineHeight: 'F',
        color: 'foreground.secondary.C',
      },
    },
    quaternary: {
      C: {
        fontSize: 'C',
        fontWeight: 'semibold',
        lineHeight: 'D',
        color: 'foreground.secondary.D',
      },
      D: {
        fontSize: 'D',
        fontWeight: 'semibold',
        lineHeight: 'E',
        color: 'foreground.secondary.D',
      },
      E: {
        fontSize: 'E',
        fontWeight: 'semibold',
        lineHeight: 'E',
        color: 'foreground.secondary.D',
      },
      F: {
        fontSize: 'F',
        fontWeight: 'semibold',
        lineHeight: 'F',
        color: 'foreground.secondary.D',
      },
    },
    sketch: {
      C: {
        fontSize: 'C',
        fontWeight: 'semibold',
        lineHeight: 'D',
        color: 'sketch.A',
      },
      D: {
        fontSize: 'D',
        fontWeight: 'semibold',
        lineHeight: 'E',
        color: 'sketch.A',
      },
      E: {
        fontSize: 'E',
        fontWeight: 'semibold',
        lineHeight: 'E',
        color: 'sketch.A',
      },
    },
    positive: {
      C: {
        fontSize: 'C',
        fontWeight: 'semibold',
        lineHeight: 'D',
        color: 'state.positive.A',
      },
    },
    contrast: {
      D: {
        fontSize: 'D',
        fontWeight: 'semibold',
        lineHeight: 'E',
        color: 'foreground.primary.A',
      },
    },
  },

  // 6 Link
  // --------------------------------------------------------------------------
  link: {
    primary: {
      C: {
        fontSize: 'C',
        fontWeight: 'semibold',
        lineHeight: 'D',
        textDecoration: 'underline',
        color: 'foreground.secondary.A',
      },
      D: {
        fontSize: 'D',
        fontWeight: 'semibold',
        lineHeight: 'E',
        textDecoration: 'underline',
        color: 'foreground.secondary.A',
      },
      E: {
        fontSize: 'E',
        fontWeight: 'semibold',
        lineHeight: 'E',
        textDecoration: 'underline',
        color: 'foreground.secondary.A',
      },
    },
    sketch: {
      C: {
        fontSize: 'C',
        fontWeight: 'semibold',
        lineHeight: 'D',
        textDecoration: 'underline',
        color: 'sketch.A',
      },
      D: {
        fontSize: 'D',
        fontWeight: 'semibold',
        lineHeight: 'E',
        textDecoration: 'underline',
        color: 'sketch.A',
      },
      E: {
        fontSize: 'E',
        fontWeight: 'semibold',
        lineHeight: 'E',
        textDecoration: 'underline',
        color: 'sketch.A',
      },
    },
  },

  // 7 Unread
  // --------------------------------------------------------------------------
  unread: {
    primary: {
      D: {
        fontSize: 'D',
        fontWeight: 'semibold',
        lineHeight: 'E',
        color: 'foreground.secondary.A',
      },
    },
    secondary: {
      D: {
        fontSize: 'D',
        fontWeight: 'semibold',
        lineHeight: 'E',
        color: 'foreground.secondary.B',
      },
    },
    ternary: {
      D: {
        fontSize: 'D',
        fontWeight: 'semibold',
        lineHeight: 'E',
        color: 'foreground.secondary.C',
      },
    },
    quaternary: {
      D: {
        fontSize: 'D',
        fontWeight: 'semibold',
        lineHeight: 'E',
        color: 'foreground.secondary.D',
      },
    },
    sketch: {
      D: {
        fontSize: 'D',
        fontWeight: 'semibold',
        lineHeight: 'E',
        color: 'sketch.A',
      },
    },
    disabled: {
      D: {
        fontSize: 'D',
        fontWeight: 'semibold',
        lineHeight: 'E',
        color: 'state.disabled',
      },
    },
  },

  // 8 Caption
  // --------------------------------------------------------------------------
  caption: {
    constrast: {
      B: {
        fontSize: 'B',
        fontWeight: 'semibold',
        lineHeight: 'C',
        color: 'foreground.primary.A',
      },
    },
    negative: {
      B: {
        fontSize: 'B',
        fontWeight: 'semibold',
        lineHeight: 'C',
        color: 'state.negative.A',
      },
    },
    primary: {
      B: {
        fontSize: 'B',
        fontWeight: 'semibold',
        lineHeight: 'C',
        color: 'foreground.secondary.A',
      },
    },
    secondary: {
      B: {
        fontSize: 'B',
        fontWeight: 'semibold',
        lineHeight: 'C',
        color: 'foreground.secondary.B',
      },
    },
    quaternary: {
      B: {
        fontSize: 'B',
        fontWeight: 'semibold',
        lineHeight: 'C',
        color: 'foreground.secondary.D',
      },
    },
    sketch: {
      B: {
        fontSize: 'B',
        fontWeight: 'semibold',
        lineHeight: 'C',
        color: 'sketch.A',
      },
    },
  },

  // 9 Inspect
  // --------------------------------------------------------------------------
  inspect: {
    A: {
      fontSize: 'A',
      fontWeight: 'medium',
      lineHeight: 'B',
      color: 'foreground.primary.A',
    },
  },
} as const
