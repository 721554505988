import {
  createGlobalStyle,
  css,
  DefaultTheme,
  Interpolation,
  ThemeProps,
} from 'styled-components'

import { getiOSVersion } from './utils'

interface DynamicGlobalStyleProps {
  userAgent: string
  // TODO: Make styles of Selector component self contained and remove this property
  //  see: https://github.com/sketch-hq/Cloud/issues/14547
  extraStyles?: Interpolation<ThemeProps<DefaultTheme>>
}

export const linkStyles = css(
  ({ theme }) => css`
    transition: color ${theme.transitions.duration[1]};
    text-decoration: none;
    white-space: nowrap;
    color: ${theme.colors.sketch.B};

    &:hover,
    &:active {
      color: ${theme.colors.sketch.A};
    }
  `
)

/**
 * Static and global styles
 */
export const StaticGlobalStyles = createGlobalStyle`
  :root {
    /* Avoids a warning from Reach UI to import the listbox styles */
    --reach-listbox: 1;
    --grid-columns: 12;
  }

  html {
    height: -webkit-fill-available;
    height: 100%;
  }

  html,
  body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    overflow-x: hidden;
  }

  *,
  *:before,
  *:after {
    box-sizing: border-box;
  }

  /* Only browsers supporting :focus-visible will remove the outline for :focus */
  :focus:not(:focus-visible) {
    outline: none;
  }

  /* Restore the native focus ring for buttons on Firefox, normalize.css is adding a 1px dotted outline as focus style but Firefox already implements a consistent focus ring (the dotted one is an old style) */
  button:-moz-focusring,
  [type='button']:-moz-focusring,
  [type='reset']:-moz-focusring,
  [type='submit']:-moz-focusring {
    outline: 5px auto Highlight;
  }

  .h5,
  .h6 {
    letter-spacing: initial;
  }

  .display-1 {
    font-size: 3.375rem; /* 54px */
  }

  p {
    margin: 0;
  }

  p + p {
    margin-top: 16px;
  }

  .dark-primary {
    color: rgba(0, 0, 0, 0.85);
  }

  .dark-secondary {
    color: rgba(0, 0, 0, 0.65);
  }

  .dark-tertiary {
    color: rgba(0, 0, 0, 0.4);
  }

  .light-primary {
    color: rgba(255, 255, 255, 0.85);
  }

  .light-secondary {
    color: rgba(255, 255, 255, 0.65);
  }

  .light-tertiary {
    color: rgba(255, 255, 255, 0.4);
  }

  /*
  This class basically hides a element to serve as a
  screen-reader element.

  source: https://www.scottohara.me/blog/2017/04/14/inclusively-hidden.html
  */
  .sr-only:not(:focus):not(:active) {
    display: inline;
    clip: rect(0 0 0 0);
    clip-path: inset(100%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
  }
`

/**
 * Global styles that uses dynamic values like props or theme
 */
export const DynamicGlobalStyles = createGlobalStyle<DynamicGlobalStyleProps>(
  ({ theme, userAgent, extraStyles }) => css`
    * {
      ${() => {
        const iOS = getiOSVersion(userAgent)

        if (!iOS || iOS >= 13) {
          return undefined
        }

        return { '-webkit-overflow-scrolling': 'touch' }
      }}
    }

    body {
      display: flex;
      flex-direction: column;
      min-height: 100%;
      height: 100%;
      font-family: ${theme.fonts.body};
      color: ${theme.colors.foreground.secondary.A};
      font-size: 16px;
      line-height: 1.5;
      background-color: ${theme.colors.background.secondary.B};
      transition: background-color 0.5s;
      transition: color 0.5s;

      /* Normalize font rendering, specially in Firefox where Inter renders too bold */
      -moz-osx-font-smoothing: grayscale;
      -webkit-font-smoothing: antialiased;
      font-weight: 400;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    .display-1,
    .h1,
    .h2,
    .h3,
    .h4,
    .h5,
    .h6,
    .caption {
      font-weight: ${theme.fontWeights.medium};
      font-style: normal;
    }

    h1,
    .h1 {
      font-size: 2.75rem; /* 44px */
      line-height: 1.1;
      margin-top: 48px;
      margin-bottom: 32px;
      font-weight: ${theme.fontWeights.bold};
    }

    h2,
    .h2 {
      font-size: ${theme.fontSizes.I}; /* 32px */
      line-height: 1.2;
      margin-top: 48px;
      margin-bottom: 24px;
    }

    h3,
    .h3 {
      font-size: 1.6875rem; /* 27px */
      line-height: 1.2;
      margin-top: 32px;
      margin-bottom: 16px;
    }

    h4,
    .h4 {
      font-size: 1.375rem; /* 22px */
      line-height: 1.3;
      margin-top: 32px;
      margin-bottom: 16px;
    }

    h5,
    .h5 {
      font-size: ${theme.fontSizes.F}; /* 16px */
      line-height: 1.5;
      margin-top: 32px;
      margin-bottom: 8px;
    }

    h6,
    .h6,
    .caption {
      font-size: ${theme.fontSizes.C}; /* 12px */
      line-height: 1.6;
      text-transform: uppercase;
      margin-top: 24px;
      margin-bottom: 8px;
    }

    a,
    .link {
      ${linkStyles};
    }

    p a {
      font-weight: ${theme.fontWeights.medium};
    }

    hr {
      height: 1px;
      background-color: ${theme.colors.border.A};
      border: none;
      margin: 24px 0;
    }

    small {
      font-size: ${theme.fontSizes.D}; /* 13px */
      line-height: 1.2;
    }

    .t-lead {
      font-size: ${theme.fontSizes.G}; /* 20px */
      line-height: 1;
    }

    .t-small {
      color: rgba(#000, 0.65);
      font-size: ${theme.fontSizes.D}; /* 13px */
      line-height: 1.2;
    }

    .t-small.t-hint {
      color: rgba(#000, 0.4);
    }

    /* Force the selection to use the primary tint when selecting something */
    ::selection {
      background: ${({ theme }) => theme.colors.sketch.E};
    }

    ${extraStyles || []}
  `
)
