import React from 'react'

import { Section, Separator, Header, HeaderTitle } from '../../components'

import { SketchFrameElement } from '../../../../../../inspector'
import { GridSettings } from '../GridSettings'
import { LayoutSettings } from '../LayoutSettings'

interface LayoutProps {
  element: SketchFrameElement
}

export const Layout = ({ element }: LayoutProps) => {
  if (!element.gridSettings?.isEnabled && !element.layoutSettings?.isEnabled) {
    return null
  }

  return (
    <>
      <Separator />
      <Section data-testid="inspector-sidebar-artboard-layout-settings">
        <Header>
          <HeaderTitle>Layout</HeaderTitle>
        </Header>

        {'gridSettings' in element && (
          <GridSettings grid={element.gridSettings} />
        )}

        {'layoutSettings' in element && (
          <LayoutSettings layout={element.layoutSettings} />
        )}
      </Section>
    </>
  )
}
