import {
  ShareMembershipInfoFragment,
  ShareListItemFragment,
  GetShareDocument,
  GetShareQueryVariables,
  useUpdateShareMembershipMutation,
  UpdateShareMembershipMutation,
} from '@sketch/gql-types'
import { useCallback, useReducer, useEffect } from 'react'
import { ErrorHandler } from '@sketch/tracing'
import { useToast } from '@sketch/toasts'
import { useModalContext } from '@sketch/components'

type PrivateAccessLevel = ShareMembershipInfoFragment['privateAccessLevel']

type Values = Pick<
  ShareMembershipInfoFragment,
  | 'privateAccessLevel'
  | 'userCanUpdateCommentsEnabled'
  | 'guestCommentsEnabled'
  | 'guestInspectEnabled'
>

type State = {
  initialValues: Values
  values: Values
  dialogOpen: boolean
  mutating: boolean
}

type Action =
  | { type: 'show-dialog' }
  | { type: 'hide-dialog' }
  | { type: 'set-mutating' }
  | { type: 'reset' }
  | { type: 'set-level'; payload: { level: PrivateAccessLevel } }
  | { type: 'set-comments-enabled'; payload: { enabled: boolean } }
  | { type: 'set-inspect-enabled'; payload: { enabled: boolean } }
  | { type: 'success'; payload: { values: Values } }
  | { type: 'optimistic-success'; payload: { values: Values } }

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'set-mutating':
      return { ...state, mutating: true }
    case 'show-dialog':
      return { ...state, dialogOpen: true }
    case 'hide-dialog':
      return { ...state, dialogOpen: false }
    case 'reset':
      return {
        ...state,
        dialogOpen: false,
        mutating: false,
        values: { ...state.initialValues },
      }
    case 'success':
      return {
        ...state,
        dialogOpen: false,
        mutating: false,
        values: action.payload.values,
        initialValues: action.payload.values,
      }
    case 'optimistic-success':
      return {
        ...state,
        values: action.payload.values,
      }
    case 'set-level':
      return {
        ...state,
        values: {
          ...state.values,
          privateAccessLevel: action.payload.level,
        },
      }
    case 'set-comments-enabled':
      return {
        ...state,
        values: {
          ...state.values,
          guestCommentsEnabled: action.payload.enabled,
        },
      }
    case 'set-inspect-enabled':
      return {
        ...state,
        values: {
          ...state.values,
          guestInspectEnabled: action.payload.enabled,
        },
      }
    default:
      return state
  }
}

const areValuesEqual = (a: Values, b: Values) =>
  a.privateAccessLevel === b.privateAccessLevel &&
  a.guestCommentsEnabled === b.guestCommentsEnabled &&
  a.guestInspectEnabled === b.guestInspectEnabled

interface UseUpdateMemberProps {
  member: ShareMembershipInfoFragment
  share: Pick<ShareListItemFragment, 'identifier' | 'userCanUpdateSettings'>
}

export const useUpdateMember = ({ share, member }: UseUpdateMemberProps) => {
  const { showToast } = useToast()
  const { hideModal } = useModalContext()
  const pending = member.inviteStatus === 'REQUESTED'

  const [{ initialValues, values, dialogOpen, mutating }, dispatch] =
    useReducer(reducer, {
      initialValues: member,
      values: member,
      dialogOpen: false,
      mutating: false,
    })

  const [updateMember] = useUpdateShareMembershipMutation({
    onCompleted(data) {
      if (!data?.updateShareMembership) {
        ErrorHandler.shouldNeverHappen(
          'Share update member permissions mutation should return valid data'
        )
        dispatch({ type: 'reset' })
        return
      }

      if (
        data.updateShareMembership?.shareMembership.share
          ?.userCanUpdateSettings === false
      ) {
        hideModal()
        showToast("You can no longer view this document's settings")
        return
      }

      dispatch({
        type: 'success',
        payload: { values: data.updateShareMembership.shareMembership },
      })
    },
    onError(error) {
      if (error.includesErrorCode('CONFIRMATION_REQUIRED')) {
        dispatch({ type: 'show-dialog' })
        return
      }
      dispatch({ type: 'reset' })
      showToast(
        'There was a problem changing the member permissions. Please try again.',
        'negative'
      )
    },
    refetchQueries: [
      {
        query: GetShareDocument,
        variables: {
          shortId: share.identifier,
        } as GetShareQueryVariables,
      },
    ],
  })

  const updateMemberConfirmed = useCallback(
    (confirmed: boolean = false) => {
      dispatch({ type: 'set-mutating' })
      return updateMember({
        variables: {
          input: {
            identifier: member.identifier,
            privateAccessLevel: values.privateAccessLevel,
            guestCommentsEnabled: values.guestCommentsEnabled,
            guestInspectEnabled: values.guestInspectEnabled,
            confirmed,
          },
        },
        optimisticResponse: vars => {
          const { input } = vars as ExcludeEmpty<typeof vars>

          const response: UpdateShareMembershipMutation = {
            __typename: 'RootMutationType',
            updateShareMembership: {
              __typename: 'UpdateShareMembershipResponse',
              shareMembership: {
                ...member,
                identifier: input.identifier,
                privateAccessLevel: input.privateAccessLevel!,
                guestCommentsEnabled: input.guestCommentsEnabled!,
                guestInspectEnabled: input.guestInspectEnabled!,
                share: {
                  __typename: 'Share',
                  identifier: share.identifier,
                  userCanUpdateSettings: share.userCanUpdateSettings,
                },
              },
            },
          }

          dispatch({
            type: 'optimistic-success',
            payload: {
              values: response.updateShareMembership.shareMembership,
            },
          })

          return response
        },
      })
    },
    [member, updateMember, values, share]
  )

  useEffect(() => {
    if (!mutating && !areValuesEqual(values, initialValues)) {
      updateMemberConfirmed()
    }
  }, [values, initialValues, updateMemberConfirmed, mutating])

  const getUpdateMemberConfirmDialogProps = useCallback(
    () => ({
      hideModal() {
        dispatch({ type: 'reset' })
      },
      onConfirm() {
        updateMemberConfirmed(true)
      },
      isModalOpen: true,
      confirmButton: {
        text: 'Confirm',
        intent: 'positive',
      } as const,
    }),
    [dispatch, updateMemberConfirmed]
  )

  const getUpdateMemberDropdownProps = useCallback(
    () => ({
      access: {
        kind: 'private',
        level: pending ? 'PENDING' : values.privateAccessLevel,
        setLevel: (level: PrivateAccessLevel) => {
          if (level === values.privateAccessLevel && pending) {
            // Handle the case where a PENDING member (inviteStatus REQUESTED)
            // has their access level re-set to the same value it currently is.
            // In this case we still need to manually trigger the mutation so
            // the BE auto-accepts their request, despite the values and
            // initialValues still being equal
            updateMemberConfirmed()
          } else {
            dispatch({ type: 'set-level', payload: { level } })
          }
        },
      } as const,
      guestCommentsEnabled: values.guestCommentsEnabled,
      guestInspectEnabled: values.guestInspectEnabled,
      setCommentsEnabled: (enabled: boolean) => {
        dispatch({ type: 'set-comments-enabled', payload: { enabled } })
      },
      setInspectEnabled: (enabled: boolean) => {
        dispatch({ type: 'set-inspect-enabled', payload: { enabled } })
      },
      canUpdateCommentsEnabled: values.userCanUpdateCommentsEnabled,
    }),
    [values, dispatch, pending, updateMemberConfirmed]
  )

  return {
    getUpdateMemberDropdownProps,
    getUpdateMemberConfirmDialogProps,
    updateMemberConfirmDialogOpen: dialogOpen,
    updateMemberLevel: values.privateAccessLevel,
  }
}
