import { useOnEvent } from '@sketch/utils'

import {
  useGetHasPendingPatchesQuery,
  useGetHasPendingPatchesLazyQuery,
} from '@sketch/gql-types'

export function useGetPendingPatches({
  shareIdentifier,
}: {
  shareIdentifier: string
}) {
  const [getHasPendingPatchesLazy] = useGetHasPendingPatchesLazyQuery({
    fetchPolicy: 'network-only',
    variables: { shareIdentifier },
  })

  useOnEvent('versionIsUploaded', () => {
    getHasPendingPatchesLazy()
  })

  useOnEvent('versionIsProcessed', () => {
    getHasPendingPatchesLazy()
  })

  const { data: hasPendingPatchesData, loading: hasPendingPatchesLoading } =
    useGetHasPendingPatchesQuery({
      variables: { shareIdentifier },
    })

  return (
    (!hasPendingPatchesLoading &&
      hasPendingPatchesData?.collaborativeEditingSession.hasPendingPatches) ??
    false
  )
}
