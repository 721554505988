import React from 'react'
import { Formik, FormikHelpers } from 'formik'

import { useToast } from '@sketch/toasts'
import { Button, Form, Modal, ModalInjectedProps } from '@sketch/components'

import BillingDetailsForm, {
  validationSchema,
} from '../../components/BillingDetailsForm'

import {
  useUpdateBillingDetailsMutation,
  BillingDetailsFragment,
} from '@sketch/gql-types'

export interface EditAddressDetailsModalProps extends ModalInjectedProps {
  customerId: string
  billingDetails: BillingDetailsFragment
}

type FormValues = {
  name: string
  email: string
  address: string
  city: string
  postalCode: string
  country: string
  taxId: string
  state: string
}

/**
 * EditAddressDetailsModal
 *
 * Renders the edit address modal in the Workspace Settings > Billing Page
 * within the Payment Details panel
 */
const EditAddressDetailsModal: React.FC<EditAddressDetailsModalProps> = ({
  customerId,
  billingDetails,
  hideModal,
}) => {
  const { showToast } = useToast()

  // MUTATIONS
  const [updateBillingDetails, { loading }] = useUpdateBillingDetailsMutation({
    onCompleted: () => {
      showToast('Your address information has been updated')
      hideModal()
    },
    onError: error => {
      showToast(error.message, 'negative')
    },
  })

  const handleOnSubmit = async (
    values: FormValues,
    actions: FormikHelpers<FormValues>
  ) => {
    const { name, email, address, city, postalCode, country, taxId, state } =
      values

    await updateBillingDetails({
      variables: {
        input: {
          customerId,
          email,
          name,
          taxId,
          address: {
            city,
            country,
            line1: address,
            postalCode,
            state,
          },
        },
      },
    })

    actions.setSubmitting(false)
  }

  const { name, email, address, taxId } = billingDetails

  const prefilledFormValues = {
    name,
    email,
    taxId: taxId?.id || '',
    address: address?.line1 || '',
    city: address?.city || '',
    postalCode: address?.postalCode || '',
    country: address?.country || '',
    state: address?.state || '',
  }

  return (
    <Modal onCancel={hideModal}>
      <Formik
        initialValues={prefilledFormValues}
        validationSchema={validationSchema}
        onSubmit={handleOnSubmit}
      >
        {({ errors, values, isSubmitting }) => (
          <Form>
            <Modal.Header>Billing Details</Modal.Header>
            <Modal.Body>
              <BillingDetailsForm<FormValues> showHeader={false} />
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                disabled={isSubmitting}
                onClick={hideModal}
                type="button"
              >
                Cancel
              </Button>
              <Button
                variant="primary"
                type="submit"
                disabled={isSubmitting || Object.keys(errors).length > 0}
                loading={isSubmitting || loading}
              >
                Update Billing Details
              </Button>
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  )
}

export default EditAddressDetailsModal
