import React from 'react'

import StickyCommentInput from '../StickyCommentInput'
import { CommentInputProps } from '../CommentInput/CommentInput'

import { WarningText } from './AnnotationCommentBox.styles'
import { Banner, Link } from '@sketch/components'
import { getLatestVersionURLFromCurrentLocation } from '../../../versioning/utils'
import { useLocation } from 'react-router'

interface AnnotationCommentBoxProps extends Omit<CommentInputProps, 'banner'> {
  showOlderVersionWarning: boolean
}

const VersionWarningText = () => {
  const location = useLocation()

  return (
    <Banner type="warning" showIcon={false}>
      <WarningText>
        You’re viewing an older version. Comments will be added to the{' '}
        <Link
          to={getLatestVersionURLFromCurrentLocation(location)}
          variant="secondary"
          isUnderlined
        >
          latest version
        </Link>
        .
      </WarningText>
    </Banner>
  )
}

const AnnotationCommentBox = ({
  showOlderVersionWarning,
  ...props
}: AnnotationCommentBoxProps) => {
  return (
    <StickyCommentInput
      banner={showOlderVersionWarning ? <VersionWarningText /> : undefined}
      {...props}
    />
  )
}

export default AnnotationCommentBox
