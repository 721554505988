import { useHistory } from 'react-router-dom'
import { ApolloClient } from 'apollo-client'
import { routes } from '@sketch/modules-common'
import { getProjectIdFromUrl, isViewingWorkspace } from '../../utils'
import { ErrorHandler } from '@sketch/tracing'
import { useEventDispatch } from '@sketch/utils'
import {
  GetProjectsDocument,
  GetProjectsQuery,
  GetProjectsQueryVariables,
  GetWorkspacesDocument,
  GetWorkspacesQuery,
  useProjectAccessChangedSubscription,
} from '@sketch/gql-types'
import { useToast } from '@sketch/toasts'
import { getToast } from '../../toasts'

declare module '@sketch/utils' {
  export interface EventsMap {
    projectAccessRevoked: {
      workspaceIdentifier: string
      projectIdentifier: string
      projectName: string
    }
  }
}

interface CheckWorkspaceAccessParams {
  workspaceIdentifier: string
  workspaces?: GetWorkspacesQuery
}

function checkWorkspaceAccess({
  workspaceIdentifier,
  workspaces,
}: CheckWorkspaceAccessParams) {
  const availableWorkspacesIdentifiers =
    workspaces?.me.workspaces.map(workspace => workspace.identifier) || []

  return availableWorkspacesIdentifiers.includes(workspaceIdentifier)
}

export const useProjectMembershipSubscriptions = () => {
  const history = useHistory()
  const { showToast } = useToast()

  const dispatchSharesRefresh = useEventDispatch('workspaceShareRefresh')
  const dispatchProjectAccessRevoked = useEventDispatch('projectAccessRevoked')

  /**
   * Project Access Changed Mutation
   *
   * Mutation fired when project access changes
   * for the current logged user.
   * Projects' list will be refreshed to reflect
   * the latest projects that user has access to.
   */
  useProjectAccessChangedSubscription({
    async onSubscriptionData({
      client,
      subscriptionData: { data, error, loading },
    }) {
      const responseData = data?.projectAccessChanged

      if (!responseData || error || loading) {
        ErrorHandler.shouldNeverHappen(
          'Received "projectAccessChanged" subscription data should always be valid one'
        )
        return
      }

      const { workspaceIdentifier } = responseData

      // We refetch the Workspaces to make sure the user
      // still has access to the current Workspace
      const { data: workspacesData } = await client.query<GetWorkspacesQuery>({
        query: GetWorkspacesDocument,
        fetchPolicy: 'network-only',
      })

      const hasWorkspaceAccess = checkWorkspaceAccess({
        workspaceIdentifier,
        workspaces: workspacesData,
      })

      // User loses access to the project
      if (responseData.__typename === 'ProjectAccessRevokedEvent') {
        const isUserViewingWorkspace = isViewingWorkspace(
          window.location.pathname,
          workspaceIdentifier
        )

        // Send user to the ENTRY route if he lost access to the Workspace and
        // is currently viewing it
        if (!hasWorkspaceAccess && isUserViewingWorkspace) {
          history.push(routes.ENTRY.create({}))

          return
        }

        // If the user is in a project Share, redirect him to the All Documents
        dispatchProjectAccessRevoked({
          workspaceIdentifier: responseData.workspaceIdentifier,
          projectIdentifier: responseData.project.identifier,
          projectName: responseData.project.name,
        })

        const shouldRedirect = isNestedProject(
          client,
          workspaceIdentifier,
          responseData.project.identifier,
          getProjectIdFromUrl() || ''
        )

        if (shouldRedirect) {
          showToast(
            getToast('PROJECT_ACCESS_REVOKED', responseData.project.name),
            'default'
          )

          history.push(
            routes.WORKSPACE_SHARES.create({ workspaceId: workspaceIdentifier })
          )
        }
      }

      if (hasWorkspaceAccess) {
        // Refetch the projects to reflect the projects that user has access to
        await client.query<GetProjectsQuery, GetProjectsQueryVariables>({
          query: GetProjectsDocument,
          variables: { workspaceId: workspaceIdentifier },
          fetchPolicy: 'network-only',
        })
      }

      // Force the Shares view to refresh for All Documents to refresh, if visible
      // see src/modules/shares/components/WorkspaceSharesList.tsx
      dispatchSharesRefresh({
        workspaceIdentifier,
      })
    },
  })
}

/**
 * If a project is being viewed, this function detects if it's a nested
 * project from an specific root project. This is needed because it can happen
 * that a user loses access to a project or nested project that is currently
 * being viewed, in this case we want to redirect the user to the All Documents view.
 */
const isNestedProject = (
  client: ApolloClient<object>,
  workspaceIdentifier: string,
  rootProjectId: string,
  projectId: string
) => {
  try {
    const projects = client.readQuery<
      GetProjectsQuery,
      GetProjectsQueryVariables
    >({
      query: GetProjectsDocument,
      variables: { workspaceId: workspaceIdentifier },
    })

    const projectEntries = projects?.workspace.projects.entries || []
    const currentProject = projectEntries.find(
      project => project.identifier === projectId
    )

    // if topLevelProject is null, it means it's a root project
    const rootProjectFromCurrent =
      currentProject?.topLevelProject?.identifier || currentProject?.identifier

    return rootProjectFromCurrent === rootProjectId
  } catch (error) {
    return false
  }
}
