import React, { useState, useRef, useEffect } from 'react'
import styled from 'styled-components'
import { useInView } from 'react-intersection-observer'

import { LearnMoreTooltip } from '../LearnMoreTooltip'

import {
  Title,
  Description,
  Wrapper,
  Pencil,
  EditInput,
  Form,
  PreviewTitle,
  PencilButton,
  EditInputWrapper,
  TitleButtonWrapper,
  ActionsWrapper,
  TitleWrapper,
} from './DocumentHeader.styles'

type EditReturn = Promise<any> | void
type EditHandler = (title: string) => EditReturn

interface TitleInputProps {
  value: string
  placeholder?: string
  onEdit: EditHandler
  onCancel: () => void
}

// TODO: Refactor DocumentHeader, see https://linear.app/sketch/issue/SWEB-574/refactor-document-header-component
const TitleInput: React.FC<TitleInputProps> = props => {
  const { value: externalValue, onEdit, placeholder, onCancel } = props

  const [value, setValue] = useState(externalValue)
  const textAreaRef = useRef<HTMLTextAreaElement>(null)

  useEffect(() => {
    textAreaRef.current?.select()
  }, [])

  // Make the text area appear selected when opened and
  // mount the escape button hook
  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        onCancel()
      }
    }

    window.addEventListener('keydown', handleKeyPress)

    return () => {
      window.removeEventListener('keydown', handleKeyPress)
    }
  }, [onCancel])

  const createSubmit = (form: HTMLFormElement | null) => {
    form?.dispatchEvent(new Event('submit', { cancelable: true }))
  }

  return (
    <EditInputWrapper>
      <PreviewTitle>{value}</PreviewTitle>
      <Form
        onSubmit={e => {
          e.preventDefault()
          onEdit(value.trim())
        }}
      >
        <EditInput
          placeholder={placeholder}
          onChange={e =>
            setValue(
              // Replace double space from triggering a new line
              e.target.value.replaceAll(/\s{2,}/g, ' ')
            )
          }
          onBlur={e => {
            createSubmit(e.currentTarget.form)
          }}
          onKeyPress={e => {
            // Make the "Enter" key submit the change instead of a new line
            if (e.key === 'Enter') {
              e.preventDefault()
              createSubmit(e.currentTarget.form)
            }
          }}
          ref={textAreaRef}
          value={value}
        />
      </Form>
    </EditInputWrapper>
  )
}

interface DocumentHeaderProps {
  className?: string
  title: string
  titlePlaceholder?: string
  description?: React.ReactNode
  learnMoreTooltipTitle?: string
  learnMoreTooltipURL?: string
  onEdit?: EditHandler
  actions?: React.ReactNode
  forceEdit?: boolean
  onClearForceEdit?: () => void
  breadcrumb?: React.ReactNode
  separateBreadcrumb?: boolean
  hideContent?: boolean
}

// TODO: Refactor DocumentHeader, see https://linear.app/sketch/issue/SWEB-574/refactor-document-header-component
const DocumentHeaderBase: React.FC<DocumentHeaderProps> = props => {
  const {
    actions,
    className,
    title,
    description,
    learnMoreTooltipTitle,
    learnMoreTooltipURL,
    onEdit,
    titlePlaceholder,
    forceEdit,
    onClearForceEdit,
    breadcrumb,
    separateBreadcrumb,
    hideContent,
  } = props

  const [isEditing, setEditing] = useState(forceEdit)

  const [wrapperRef, inView] = useInView()
  const headerRef = useRef<HTMLHeadingElement>(null)
  let titleElement = <Title>{title}</Title>

  // Set the "isEditing" true when the forceEdit is send
  useEffect(() => {
    if (forceEdit) {
      setEditing(true)
    }
  }, [forceEdit])

  // We are dismissing the Edit mode when the title is not visible
  // To prevent the user from scrolling while still having the edit mode ON
  useEffect(() => {
    if (!inView && isEditing) {
      setEditing(false)
    }
  }, [inView, isEditing])

  // Call "onClearForceEdit" when the edit is set to false
  useEffect(() => {
    if (isEditing && forceEdit) {
      return () => {
        onClearForceEdit?.()
      }
    }
  }, [isEditing, forceEdit, onClearForceEdit])

  if (isEditing && onEdit && !hideContent) {
    titleElement = (
      <TitleInput
        value={title}
        placeholder={titlePlaceholder}
        onEdit={async newTitle => {
          if (title !== newTitle) {
            await onEdit(newTitle)
          }

          setEditing(false)

          // Make the button gain focus again after the change
          setTimeout(() => {
            headerRef.current?.focus()
          })
        }}
        onCancel={() => {
          setEditing(false)
        }}
      />
    )
  } else if (onEdit) {
    titleElement = (
      <Title tabIndex={0} aria-label={title} ref={headerRef}>
        {title}
      </Title>
    )
  }

  return (
    <Wrapper className={className} aria-label="Document Header">
      {separateBreadcrumb && breadcrumb && <>{breadcrumb}</>}
      <TitleWrapper ref={wrapperRef}>
        {!separateBreadcrumb && breadcrumb && <>{breadcrumb}</>}
        <TitleButtonWrapper>
          {titleElement}
          {onEdit && (
            <PencilButton onClick={() => setEditing(true)} disabled={isEditing}>
              <span className="sr-only">Edit</span>
              <Pencil />
            </PencilButton>
          )}
        </TitleButtonWrapper>
      </TitleWrapper>
      {description && (
        <Description>
          {description}
          {learnMoreTooltipTitle && learnMoreTooltipURL && (
            <LearnMoreTooltip
              tooltipContent={learnMoreTooltipTitle}
              href={learnMoreTooltipURL}
              tooltipPlacement="top"
            />
          )}
        </Description>
      )}
      {actions && <ActionsWrapper>{actions}</ActionsWrapper>}
    </Wrapper>
  )
}

export const DocumentHeader = styled(DocumentHeaderBase)``
