import React from 'react'

import {
  Button,
  Link,
  Text,
  useModalContext,
  useResponsiveDropdown,
} from '@sketch/components'
import BillingInfoTable from '../BillingInfoTable'
import { ChangeBillingPlanModal } from '../../modals/ChangeBillingPlanModal'

// GQL Types
import {
  BillingSeatsInfoFragment,
  CloudBillingPlanFragment,
  PaymentDetailsFragment,
} from '@sketch/gql-types'

// Usage of BillingStatus enum is needed to make the expected strings match the BE ones
// eslint-disable-next-line no-restricted-imports
import { BillingStatus } from '@sketch/gql-types/expansive'

const YearlyToMonthlyWarning = () => {
  return (
    <Text textStyle="copy.tertiary.standard.C" mx={3}>
      To change to a Monthly subscription, please{' '}
      <Link
        href="https://www.sketch.com/support/contact/"
        external
        isUnderlined
        variant="primary"
        title="Contact Us"
      >
        contact our support team
      </Link>
    </Text>
  )
}

interface EditButtonProps {
  customerId: string
  subscriptionStatus: BillingStatus
  currentPlan?: CloudBillingPlanFragment
  seats: BillingSeatsInfoFragment
  paymentDetails?: PaymentDetailsFragment
  onWorkspaceSettingRefresh: () => Promise<void>
}

export const EditButton: React.FC<EditButtonProps> = ({
  customerId,
  currentPlan,
  seats,
  paymentDetails,
  onWorkspaceSettingRefresh,
  subscriptionStatus,
}) => {
  const { showModal } = useModalContext()
  const [yearlyToMonthlyAlertContent, yearlyToMonthlyProps] =
    useResponsiveDropdown({
      dropdown: YearlyToMonthlyWarning,
      placement: 'right',
      showOnHover: true,
      dropdownStyle: {
        width: '240px',
      },
    })

  if (currentPlan?.product !== 'TEAMS' || !(subscriptionStatus === 'ACTIVE')) {
    return null
  }

  return (
    <BillingInfoTable.Cell>
      {currentPlan.type === 'MONTHLY' ? (
        <Button
          variant="secondary"
          size="32"
          onClick={() =>
            showModal(ChangeBillingPlanModal, {
              currentPlan,
              customerId,
              onWorkspaceSettingRefresh,
              seats,
              paymentDetails,
            })
          }
        >
          Edit&hellip;
        </Button>
      ) : (
        <div {...yearlyToMonthlyProps}>
          <Button variant="secondary" size="32" disabled>
            Edit&hellip;
          </Button>
          {yearlyToMonthlyAlertContent}
        </div>
      )}
    </BillingInfoTable.Cell>
  )
}
