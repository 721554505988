import styled, { css } from 'styled-components'
import { Truncate } from '@sketch/components'

const WorkspaceNameText = styled(Truncate)(
  ({ theme }) => css`
    color: ${theme.colors.foreground.secondary.A};
    font-size: ${theme.fontSizes.E};
    line-height: ${theme.lineHeights.default};
    font-weight: 500;
  `
)

const HelpText = styled(Truncate)`
  width: 100%;
  color: ${({ theme }) => theme.colors.foreground.secondary.D};
  font-size: ${({ theme }) => theme.fontSizes.D};
`

const Wrapper = styled.div`
  display: flex;
  align-items: center;

  padding: 8px 8px 8px 12px;
  min-height: 64px;
`

const TextWrapper = styled.div`
  margin: 0 auto 0 12px;
  overflow: hidden;
`

export { WorkspaceNameText, HelpText, Wrapper, TextWrapper }
