import React from 'react'
import copy from 'copy-to-clipboard'

import { routes } from '@sketch/modules-common'

import { Flex, TruncateWithTooltip, CopyToClipboard } from '@sketch/components'

//Styles
import { StyledModal as Modal } from '../../../shares/modals/SharingModal/SharingModal.styles'
import {
  EmptyProjectIcon,
  PreviewContainer,
  PreviewImage,
  Title,
  Subtitle,
} from './ProjectSharingModal.styles'

// GraphQL
import {
  ProjectInSidebarAndHeaderFragment,
  useGetProjectSharesQuery,
} from '@sketch/gql-types'

interface ProjectSharingHeaderProps {
  project: ProjectInSidebarAndHeaderFragment
  workspaceId: string
  workspaceName: string
}

export const ProjectSharingHeader: React.FC<ProjectSharingHeaderProps> = ({
  project,
  workspaceId,
  workspaceName,
}) => {
  // get shares if none come from the props
  const { data } = useGetProjectSharesQuery({
    variables: {
      shortId: project.identifier,
    },
    fetchPolicy: 'cache-and-network',
  })

  const shares = data?.project.shares.entries ?? []
  const sharePreviews =
    shares
      .map(share => {
        const images = share.version?.document?.previewFile?.thumbnails ?? []
        const smallestThumbnail = images[images.length - 1] ?? null

        return smallestThumbnail?.url ?? null
      })
      .filter((item: any) => item !== null)
      .splice(0, 4) ?? []

  const handleCopyLink = () => {
    const projectLink = (
      project.deletedAt
        ? routes.WORKSPACE_TRASH_PROJECT
        : routes.WORKSPACE_PROJECT
    ).create({
      workspaceId,
      projectId: project.identifier,
    })

    const link = `${window.location.origin}${projectLink}`

    copy(link)
  }

  return (
    <Modal.Header>
      <Flex alignItems="center" mb={4}>
        <PreviewContainer>
          {sharePreviews.length ? (
            sharePreviews.map(image => (
              <PreviewImage key={image} src={image as string} alt="preview" />
            ))
          ) : (
            <EmptyProjectIcon />
          )}
        </PreviewContainer>

        <Flex ml={6} mr={6} flex={1} flexDirection="column">
          <Title>
            <strong>Share:</strong> {project.name}
          </Title>
          <Subtitle>
            <TruncateWithTooltip width={350}>
              {workspaceName}
            </TruncateWithTooltip>
          </Subtitle>
        </Flex>
        <CopyToClipboard
          size="40"
          showIcon={false}
          title="Copy Link"
          onCopy={handleCopyLink}
        />
      </Flex>
    </Modal.Header>
  )
}
