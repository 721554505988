import React from 'react'

import NotificationStatusDropdown from '../NotificationPartialStatusDropdown'
import NotificationStatusButton from '../NotificationStatusButton'
import useDocumentNotificationSubscription from '../../operations/useNotificationSubscription'
import { Tooltip, useResponsiveDropdown } from '@sketch/components'

// This is a valid use case to narrow down Component type
// eslint-disable-next-line no-restricted-imports
import { NotificationSubscriptionStatus } from '@sketch/gql-types/expansive'
import {
  ArtboardDetailInfoFragment,
  FrameDetailInfoFragment,
} from '@sketch/gql-types'

interface ArtboardSubscriptionStatusProps {
  shareIdentifier: string
  frameGroup: ArtboardDetailInfoFragment | FrameDetailInfoFragment
  documentVersionShortId: string
  notificationStatus: NotificationSubscriptionStatus
}

const tooltipCopy: {
  [key in NotificationSubscriptionStatus]: JSX.Element | string
} = {
  ON: 'Stop receiving updates on new comments',
  OFF: 'Receive updates on new comments',
  PARTIAL: 'You’re receiving updates on some comments in this document',
} as const

const ArtboardSubscriptionStatus = (props: ArtboardSubscriptionStatusProps) => {
  const {
    shareIdentifier,
    frameGroup,
    notificationStatus,
    documentVersionShortId,
  } = props

  const updateNotificationStatus = useDocumentNotificationSubscription()
  const [content, buttonProps] = useResponsiveDropdown({
    dropdown: NotificationStatusDropdown,
    dropdownProps: {
      type: 'artboard',
      notificationStatus: notificationStatus,
      onNotificationChange: state =>
        updateNotificationStatus({
          shareIdentifier,
          frameGroup,
          documentVersionShortId,
          state,
        }),
    },
    placement: 'bottom-end',
    usePortal: true,
  })

  const getButton = () => {
    if (notificationStatus === 'PARTIAL') {
      return (
        <>
          <NotificationStatusButton
            data-testid="artboard-notification-status"
            notificationStatus={notificationStatus}
            {...buttonProps}
          />
          {content}
        </>
      )
    } else {
      const newStatus = notificationStatus === 'ON' ? 'OFF' : 'ON'

      return (
        <NotificationStatusButton
          data-testid="artboard-notification-status"
          notificationStatus={notificationStatus}
          onClick={() => {
            updateNotificationStatus({
              shareIdentifier,
              frameGroup: frameGroup,
              documentVersionShortId,
              state: newStatus,
            })
          }}
        />
      )
    }
  }

  return (
    <Tooltip
      placement="bottom-start"
      content={tooltipCopy[notificationStatus]}
      disabled={buttonProps['aria-expanded'] === true}
    >
      {getButton()}
    </Tooltip>
  )
}

export default ArtboardSubscriptionStatus
