import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import {
  useFlag,
  EmbeddedErrorPage,
  RouteParams,
  RouteProps,
  DynamicLoadingPage,
} from '@sketch/modules-common'

// External components
import { Box, EditableText } from '@sketch/components'
import EditorView from '../../views/EditorView'
import { DsmTextEditor } from '../../components'
import { DocumentSidebarLayoutExtraProps } from '../../../shares/components/DocumentSidebarLayout'

import {
  PageWrapper,
  PageContent,
  PageSeparator as Separator,
  Title,
  Description,
} from './DocView.styles'

import { DesignSystem as DesignSystemType } from '../../types'

import { PrivateWorkspaceRouteExtraProps } from '../../../workspace/containers'
import {
  useGetDesignSystemQuery,
  useGetDesignSystemSectionsQuery,
} from '@sketch/gql-types'

const defaultEditorContent = {
  content: [
    {
      attrs: {
        level: 1,
      },
      content: [
        {
          text: 'Text editor playground',
          type: 'text',
        },
      ],
      type: 'heading',
    },
    {
      content: [
        {
          text: 'This is a description for the design system page, you can just click and start writing...',
          type: 'text',
        },
      ],
      type: 'paragraph',
    },
  ],
  type: 'doc',
}

export interface DocViewProps
  extends RouteProps<'DESIGN_SYSTEM_PAGES'>,
    PrivateWorkspaceRouteExtraProps {
  layoutProps: DocumentSidebarLayoutExtraProps
}

export const DocView: React.FC<DocViewProps> = ({ workspace, layoutProps }) => {
  const isDesignSystemsEditorOn = useFlag('design-systems-editor')
  const { designSystemId, pageId } =
    useParams<RouteParams<'DESIGN_SYSTEM_PAGES'>>()
  const [title, setTitle] = useState(pageId === 'new' ? '' : 'A title')
  const [description, setDescription] = useState(
    pageId === 'new' ? '' : 'A description'
  )

  const { data, loading, error } = useGetDesignSystemQuery({
    variables: { identifier: designSystemId },
  })

  const designSystem = data?.designSystem

  const {
    data: sectionsData,
    loading: sectionsLoading,
    error: sectionsError,
  } = useGetDesignSystemSectionsQuery({
    variables: {
      identifier: designSystem?.identifier || '',
    },
    skip: !designSystem?.identifier,
  })

  if (loading || sectionsLoading) {
    return <DynamicLoadingPage />
  }

  if (error || sectionsError || !isDesignSystemsEditorOn) {
    return <EmbeddedErrorPage for="unknown" />
  }

  const sections = sectionsData?.designSystem?.sections || []

  return (
    <EditorView
      layoutProps={layoutProps}
      designSystem={designSystem as DesignSystemType}
      sections={sections}
      workspace={workspace}
    >
      <PageWrapper>
        <PageContent>
          <Box mb={4}>
            <EditableText
              value={title}
              placeholder="New page title..."
              onEdit={setTitle}
              element={Title}
              noExtraStyling
            />
          </Box>
          <EditableText
            value={description}
            placeholder="New page description..."
            onEdit={setDescription}
            element={Description}
            noExtraStyling
            removePlaceholderMarginBottom
          />
          <Separator />
          <DsmTextEditor
            content={pageId === 'new' ? {} : defaultEditorContent}
          />
        </PageContent>
      </PageWrapper>
    </EditorView>
  )
}
