import React from 'react'
import { LinkProps } from 'react-router-dom'
import { Formik, FormikProps } from 'formik'
import * as yup from 'yup'

import {
  Form,
  Button,
  Flex,
  Link,
  Input,
  PasswordInput,
} from '@sketch/components'

import { ParsedError } from '@sketch/graphql-apollo/useMutation'
import { SignInMutation } from '@sketch/gql-types'
import { routes } from '../../routes'
import { useSignIn } from '../../libs/authentication'
import { validEmail } from '@sketch/utils'

type Values = typeof SIGN_IN_INITIAL_VALUES

export interface ExtendedFormikProps extends FormikProps<Values> {
  apiErrors?: ParsedError
  submitCopy?: string
}

export interface FieldsProps extends ExtendedFormikProps {
  hideForgotPasswordLink?: boolean
  forgotPasswordLink?: LinkProps['to']
}

const SIGN_IN_INITIAL_VALUES = {
  email: '',
  password: '',
}

const signInSchema = yup.object().shape({
  email: validEmail().required('Email can’t be blank'),
  password: yup.string().required('Password can’t be blank'),
})

export const Fields: React.FC<FieldsProps> = ({
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  hideForgotPasswordLink,
  forgotPasswordLink,
}) => (
  <>
    <Form.Field
      name="text"
      label="Email"
      errorText={touched.email ? errors.email : undefined}
    >
      <Input
        name="email"
        type="email"
        placeholder="Enter your email"
        defaultValue={values.email}
        onChange={handleChange}
        onBlur={handleBlur}
        autoFocus
      />
    </Form.Field>
    <Form.Field
      name="password"
      errorText={touched.password ? errors.password : undefined}
      label={
        <Flex justifyContent="space-between" alignItems="center">
          Password
          {!hideForgotPasswordLink && (
            <Link
              className="FormLabel__aside"
              variant="primary"
              isUnderlined
              tabIndex={-1}
              to={
                forgotPasswordLink
                  ? forgotPasswordLink
                  : { pathname: routes.FORGOT_PASSWORD.create({}) }
              }
            >
              Forgot Password?
            </Link>
          )}
        </Flex>
      }
    >
      <PasswordInput
        name="password"
        placeholder="Password"
        defaultValue={values.password}
        onChange={handleChange}
        onBlur={handleBlur}
      />
    </Form.Field>
  </>
)

export interface SubmitProps {
  isSubmitting: boolean
  disabled?: boolean
  submitCopy?: ExtendedFormikProps['submitCopy']
}

export const Submit: React.FC<SubmitProps> = ({
  isSubmitting,
  disabled,
  submitCopy = 'Sign In',
}) => (
  <Form.Footer>
    <Button
      variant="primary"
      type="submit"
      fill
      size="40"
      disabled={isSubmitting || disabled}
      loading={isSubmitting}
    >
      {submitCopy}
    </Button>
  </Form.Footer>
)

export const Errors: React.FC<ExtendedFormikProps> = ({ apiErrors }) =>
  apiErrors?.message ? (
    <Form.ErrorField>{apiErrors.message}</Form.ErrorField>
  ) : null

export const DefaultFormFields: React.FC<ExtendedFormikProps> = ({
  submitCopy,
  ...formProps
}) => (
  <>
    <Fields {...formProps} />
    <Errors {...formProps} />
    <Submit
      disabled={!(formProps.isValid && formProps.dirty)}
      isSubmitting={formProps.isSubmitting}
      submitCopy={submitCopy}
    />
  </>
)

export interface SignInFormProps {
  email?: string
  isLoading?: boolean
  submitCopy?: ExtendedFormikProps['submitCopy']
  children?: (formikProps: ExtendedFormikProps) => React.ReactNode
  onCompleted?: (data: SignInMutation) => void
  onSubmit?: (values: Values) => void
  error?: ParsedError
}

const SignInForm: React.FC<SignInFormProps> = ({
  children = DefaultFormFields,
  email = '',
  submitCopy = 'Sign In',
  isLoading,
  error: externalError,
  onCompleted,
  onSubmit,
}) => {
  const [signIn, { loading, error }] = useSignIn({
    onCompleted,
  })

  const handleSubmit = async ({ email, password }: Values) => {
    // Allows to use this form but without running the signIn hook
    if (onSubmit) {
      onSubmit({ email, password })
    } else {
      try {
        await signIn({ variables: { email, password } })
      } catch (e) {
        // The error handling should be taken care of by Apollo
      }
    }
  }

  const isSubmitting = !!(loading || isLoading)

  // This particular error will throw a toaster (check useParseErrorHandler)
  // and so we don't want to show it on the Errors field
  const isNetworkError = error?.networkErrorMessage === 'Failed to fetch'
  const apiErrors = (isNetworkError ? undefined : error) || externalError

  return (
    <Formik
      initialValues={{ ...SIGN_IN_INITIAL_VALUES, email }}
      onSubmit={handleSubmit}
      validationSchema={signInSchema}
      validateOnBlur={false}
    >
      {formikBag => (
        <Form>
          {children({
            ...formikBag,
            isSubmitting,
            apiErrors,
            submitCopy,
          })}
        </Form>
      )}
    </Formik>
  )
}

export default SignInForm
