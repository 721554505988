import { createContext, ReactNode } from 'react'

import { BannerType } from '../Banner.styles'
import { noop } from '@sketch/utils'

/**
 * if we import BannersMap from '../../index' then TypeScript allows to redeclare BannersMap
 * in other packages by pointing to `@sketch/components` instead of
 * `@sketch/components/dist/esm/long/path/to/BannersMap`, e.g.:
 * ```
 *  declare module '@sketch/components' {
 *    export interface BannersMap {
 *      exampleBannerId: ExampleBannerProps
 *    }
 *  }
 * ```
 */
import type { BannersMap } from '../../index'

type BannerId = keyof BannersMap

export type DismissBannerFn = (id: keyof BannersMap) => void

export interface BannerParameters {
  dismissible?: boolean
  onDismiss?: () => void
  message: ReactNode
  type?: BannerType
  id?: BannerId
}

export interface BannerItem {
  id: BannerId
  message: React.ReactNode
  type: BannerType
  dismissible: boolean
}

export interface DismissBannerContext {
  items: BannerItem[]
  showBanner: (parameters: BannerParameters) => {
    dismissBanner: () => void
  }
  dismissBanner: DismissBannerFn
}

const defaultBannerContextValue: DismissBannerContext = {
  items: [],
  showBanner: parameters => ({
    dismissBanner: noop,
  }),
  dismissBanner: () => {},
}

/**
 * Internal bannerContext.
 * The idea is not to import it directly in any other place other than this context directory.
 */
export const bannerContext = createContext(defaultBannerContextValue)
