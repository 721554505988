import React, { useState, useRef, useLayoutEffect } from 'react'
import { Placement } from '@popperjs/core'
import { Modifier } from 'react-popper'
import styled from 'styled-components'
import { useOnClickOutside } from '@sketch/utils'

import { PopoverContent } from './Content'
import { Popper, PopperChildrenProps, ForceUpdatePopper } from '../Popper'
import uniqueId from 'lodash.uniqueid'

export type PopoverChildrenProps =
  | ((props: PopperChildrenProps) => JSX.Element)
  | JSX.Element

export interface PopoverProps {
  children: PopoverChildrenProps
  popup: JSX.Element
  arrowComponent: React.ElementType
  contentPadding?: string
  fillContent?: boolean
  visible?: boolean
  spacing?: string
  placement?: Placement
  modifiers?: Modifier<any>[]
  contentStyle?: React.CSSProperties
  [propName: string]: any
  onClickOutside?: (e: MouseEvent) => void
  usePortal?: boolean
  disableFlip?: boolean
  className?: string
  newFeatureHighlight?: boolean
}

const accessibility = (visible: boolean) => ({
  'aria-haspopup': 'true',
  'aria-expanded': `${visible}`,
})

/**
 *
 * The Popover can be used instead of the Tooltip when you want more control in styling / content you want to display.
 *
 * It can have different placements, you can check all of them via the Properties table controls on Storybook.
 *
 */
const UnstyledPopover = ({
  popup,
  children,
  contentPadding = '0px',
  visible = false,
  spacing = '0px',
  fillContent = false,
  onClickOutside,
  usePortal = true,
  arrowComponent: Arrow,
  newFeatureHighlight = false,
  ...restProps
}: PopoverProps) => {
  const contentRef = useRef(null)
  const popoverRef = useRef(null)
  const updateRef = useRef<ForceUpdatePopper>(null)
  const [popoverId] = useState(uniqueId('popover'))

  useLayoutEffect(() => {
    updateRef.current?.()
  }, [popup])

  useOnClickOutside(
    contentRef,
    e => {
      // setTimeOut is needed in case we nest dropdowns, to delay this behavior
      // until the next tick (so we allow to the nested option item to be triggered)
      setTimeout(() => {
        onClickOutside?.(e)
      }, 0)
    },
    { includeRefs: [popoverRef] }
  )

  return (
    <Popper
      spacing={spacing}
      visible={visible}
      usePortal={usePortal}
      {...accessibility(visible)}
      {...restProps}
      popup={({ rect, arrowRef, arrowStyle, ...popperProps }) => {
        const update = updateRef as React.MutableRefObject<ForceUpdatePopper>
        update.current = popperProps.forceUpdate

        return (
          <span ref={popoverRef}>
            <PopoverContent
              id={popoverId}
              fillContent={fillContent}
              fillWidth={rect ? `${rect.width}px` : undefined}
              contentPadding={contentPadding}
              data-testid="popover-content"
              role="dialog"
              newFeatureHighlight={newFeatureHighlight}
              {...popperProps}
            >
              {Arrow && <Arrow ref={arrowRef} style={arrowStyle} />}
              {popup}
            </PopoverContent>
          </span>
        )
      }}
    >
      <div
        ref={contentRef}
        aria-haspopup="dialog"
        aria-expanded={visible}
        aria-controls={popoverId}
      >
        {children}
      </div>
    </Popper>
  )
}

export const Popover = styled(UnstyledPopover)``
