import React from 'react'

import { useModalContext } from '@sketch/components'
import { ExportDesignTokensModal } from './ExportDesignTokensModal'
import { useGetComponentsCountQuery } from '@sketch/gql-types'
import { useGetIdentifiers } from '../../hooks/useGetIdentifiers'
import { useVersioning } from '../../../versioning'
import { useUserSignedIn } from '@sketch/modules-common'
import { useComponentsState } from '../../components/ComponentsStateContext'
import {
  ExportContainer,
  ExportContainerSeparator as Separator,
  ExplanationText,
  ExportButton,
  ButtonText,
} from './ExportDesignTokens.styles'

import { ReactComponent as TokenIcon } from '@sketch/icons/token-16'
import { useThemeContext } from '@sketch/global-styles'

const SIDEBAR_BREAKPOINT = 210

export const ExportDesignTokensSidebarButton = () => {
  const { isDarkMode } = useThemeContext()

  const { shareIdentifier, versionShortId } = useGetIdentifiers()
  const componentsState = useComponentsState()
  const { showModal } = useModalContext()
  const isUserSignedIn = useUserSignedIn()
  const { share } = useVersioning()

  const { loading, data, error } = useGetComponentsCountQuery({
    variables: {
      shareIdentifier,
      versionShortId,
    },
    skip: !shareIdentifier || !versionShortId || !isUserSignedIn,
  })

  const componentCount = data?.share?.version?.document?.componentCount ?? 0
  const allTokensCount =
    componentCount !== 0
      ? componentCount.colorVar +
        componentCount.textStyle +
        componentCount.layerStyle +
        componentCount.symbol
      : 0

  if (loading || error || !allTokensCount || !isUserSignedIn) {
    return null
  }

  const sidebarWidth =
    document.getElementsByClassName('cwvSidebar')[0]?.scrollWidth
  const isNarrowSidebar = sidebarWidth < SIDEBAR_BREAKPOINT
  const buttonCopy = !isNarrowSidebar ? (
    <>Export Design Tokens&hellip;</>
  ) : (
    <>Design Tokens</>
  )

  const handleExportClick = () => {
    showModal(ExportDesignTokensModal, {
      shareIdentifier,
      userCanEditExportUrl: share.userAccessLevel === 'EDIT',
      versionShortId,
      componentsState,
    })
  }

  return (
    <ExportContainer $isDarkMode={isDarkMode}>
      <Separator />
      <ExplanationText>
        Extract Color Variables, Layer and Text Styles in various formats
      </ExplanationText>
      <ExportButton
        $narrowSidebar={isNarrowSidebar}
        size="32"
        onClick={handleExportClick}
      >
        <TokenIcon height={16} />
        <ButtonText>{buttonCopy}</ButtonText>
      </ExportButton>
    </ExportContainer>
  )
}
