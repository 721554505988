import { useEffect, useState } from 'react'
import { useStableHandler } from '@sketch/utils'

import { useArtboardEvents } from './useArtboardEvents'
import { useHandleArtboardsInteractions } from './useHandleArtboardsInteractions'
import { usePlaceDraftAnnotation } from '../../annotations/hooks'

// TODO: Rename this component to be consistent with FrameGroup
// https://linear.app/sketch/issue/SWEB-500/rename-common-components-between-artboards-and-frames-to

export const useArtboardSelected = (
  artboardsRefs: React.RefObject<Record<string, HTMLDivElement | null>>
) => {
  const { onGoToArtboard } = useHandleArtboardsInteractions()
  const [selectedArtboardUUID, setSelectedArtboardUUID] = useState<
    string | null
  >(null)
  const [placeDraftAnnotation] = usePlaceDraftAnnotation() || []

  /**
   * These function covers these scenarios:
   * - When an artboard is selected and the user is writing a comment, when the
   *   user clicks `⌘ + Enter` it should publish the comment, not navigate to
   *   the artboard
   * - When an artboard is selected and the user is writing a comment, when the
   *   user clicks `Enter` it should add a new line, not navigate to the artboard
   */
  const goToArtboardGuard: typeof onGoToArtboard = (...args) => {
    if (placeDraftAnnotation) {
      return
    }

    onGoToArtboard(...args)
  }

  const artboardEvents = useArtboardEvents(
    useStableHandler((event, eventType) => {
      const target = event.target instanceof HTMLElement ? event.target : null

      const artboardAsArray = Object.entries(artboardsRefs.current || {})
      const [artboardUUID] =
        artboardAsArray.find(([_, value]) => value?.contains(target)) || []

      if (
        eventType === 'click' &&
        artboardUUID &&
        selectedArtboardUUID !== artboardUUID
      ) {
        setSelectedArtboardUUID(artboardUUID)
      } else if (eventType === 'double-click' && artboardUUID) {
        onGoToArtboard(artboardUUID, 'doubleClick')
      } else if (
        eventType === 'cta-click' &&
        selectedArtboardUUID &&
        artboardsRefs.current?.[selectedArtboardUUID]?.contains(target)
      ) {
        onGoToArtboard(selectedArtboardUUID, 'CTA')
      }
    })
  )

  const handleWindowKeyPress = useStableHandler((event: KeyboardEvent) => {
    if (!selectedArtboardUUID) {
      return
    }

    if (event.key === 'Enter' && document.activeElement) {
      goToArtboardGuard(selectedArtboardUUID!, 'keyPressEnter')
    } else if (event.key === 'Escape') {
      setSelectedArtboardUUID(null)
    }
  })

  const handleWindowClick = useStableHandler(
    (event: MouseEvent | TouchEvent) => {
      // Checking if the click on the overlay was inside one of the artboards,
      // If not, we deselect the selected artboard
      for (const artboardId in artboardsRefs.current) {
        if (artboardsRefs.current[artboardId]?.contains(event.target as Node)) {
          return
        }
      }

      // if zoom or pages dropdown is clicked, we don't want to deselect the artboard
      const zoomDropdown = document.querySelector('[data-testid="zoom-toggle"]')
      const pagesDropdown = document.querySelector(
        '[data-testid="pages-selector-trigger"]'
      )

      const isZoomDropdownClicked = zoomDropdown?.contains(event.target as Node)
      const isPagesDropdownClicked = pagesDropdown?.contains(
        event.target as Node
      )

      if (isZoomDropdownClicked || isPagesDropdownClicked) {
        return
      }

      if (selectedArtboardUUID) setSelectedArtboardUUID(null)
    }
  )

  // Attaching a KeyPress event to the body, so we can detect when the user presses
  // the Enter key and launch the onGoToArtboard event if there is an artboard selected
  useEffect(() => {
    if (!selectedArtboardUUID) {
      return
    }

    window.document.body.addEventListener('keydown', handleWindowKeyPress)
    window.document.body.addEventListener('click', handleWindowClick)

    return () => {
      window.document.body.removeEventListener('keydown', handleWindowKeyPress)
      window.document.body.removeEventListener('click', handleWindowClick)
    }
  }, [handleWindowClick, handleWindowKeyPress, selectedArtboardUUID])

  return { artboardEvents, selectedArtboardUUID }
}
