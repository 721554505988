import React, { PropsWithChildren } from 'react'
import { VersionLink } from '../../../../versioning'
import { Tooltip, Link } from '@sketch/components'
import { SymbolMaster } from '../../../../inspector'
import { routes, useFlag } from '@sketch/modules-common'
import {
  ArtboardDetailInfoFragment,
  FrameDetailInfoFragment,
} from '@sketch/gql-types'

type ArtboardDetailInspectorSymbolLinkProps = PropsWithChildren<{
  symbolMaster: SymbolMaster | null
  frame?: ArtboardDetailInfoFragment | FrameDetailInfoFragment
  shareIdentifier?: string
}>

export function ArtboardDetailInspectorSymbolLink({
  symbolMaster,
  shareIdentifier,
  frame,
  children,
}: ArtboardDetailInspectorSymbolLinkProps) {
  // Remove this FF when "frame-web" is released
  // https://linear.app/sketch/issue/SWEB-578/remove-the-frame-web-ff
  const isFramesWebOn = useFlag('frames-web')

  // Data is not consistent for now, so we need to check that both exist
  // in order to link to a proper artboard (symbol masters are just arboards)
  if (!symbolMaster || !frame || !shareIdentifier) {
    return <SymbolUnavailable>{children}</SymbolUnavailable>
  }

  // When linking to a foreign symbol we always link to the latest version,
  // but for internal symbols we want a versioned link
  return symbolMaster.isForeign ? (
    <Link
      data-testid="inspector-symbol-link"
      to={
        (isFramesWebOn || frame.__typename === 'Frame'
          ? routes.FRAME.create({
              shareID: shareIdentifier,
              frameUUID: frame.uuid,
            })
          : routes.ARTBOARD_DETAIL.create({
              shareID: shareIdentifier,
              permanentArtboardShortId: frame.permanentArtboardShortId,
            })) + '#Inspect'
      }
    >
      {children}
    </Link>
  ) : (
    <VersionLink
      data-testid="inspector-symbol-link"
      to={
        isFramesWebOn || frame.__typename === 'Frame'
          ? {
              routeKey: 'FRAME',
              routeParams: {
                shareID: shareIdentifier,
                frameUUID: frame.uuid,
              },
              hash: '#Inspect',
            }
          : {
              routeKey: 'ARTBOARD_DETAIL',
              routeParams: {
                shareID: shareIdentifier,
                permanentArtboardShortId: frame.permanentArtboardShortId,
              },
              hash: '#Inspect',
            }
      }
    >
      {children}
    </VersionLink>
  )
}

export const SymbolUnavailable: React.FC = ({ children }) => (
  <Tooltip
    placement="top"
    content="This Symbol belongs to a Library that is unavailable"
  >
    {children}
  </Tooltip>
)
