import { intersperse, takeWhile } from '@sketch/utils'

export interface SplitConfig {
  separatorWidth: number
  ellipsisWidth: number
  containerWidth: number
}

export interface MeasuredSegment {
  id: string
  width: number
}

export interface SplitSegmentsProps {
  segments: MeasuredSegment[]
  config: SplitConfig
}

export interface VisibleSegment {
  kind: 'visible'
  id: string
  width: number
}
export interface SeparatorSegment {
  kind: 'separator'
  width: number
}
export interface EllipsisSegment {
  kind: 'ellipsis'
  width: number
  segments: string[]
}

const toVisible = (segment: MeasuredSegment): VisibleSegment => ({
  ...segment,
  kind: 'visible',
})
const toSeparator = (width: number): SeparatorSegment => ({
  kind: 'separator',
  width,
})

const toEllipsis = (
  config: SplitConfig,
  segments: MeasuredSegment[]
): EllipsisSegment => ({
  kind: 'ellipsis',
  width: config.ellipsisWidth,
  segments: segments.map(s => s.id),
})

export type SplitSegment = VisibleSegment | SeparatorSegment | EllipsisSegment

/**
 * Split given width into segments assuming that the first and last segments are already too wide to fit.
 */
function fitOvergrownSegments(
  config: SplitConfig,
  segments: MeasuredSegment[]
): SplitSegment[] {
  const { containerWidth, ellipsisWidth, separatorWidth } = config

  const firstSegment = segments[0]
  const lastSegment = segments[segments.length - 1]
  const middleSegments = segments.slice(1, -1)

  const ellipsisLength =
    middleSegments.length > 0 ? ellipsisWidth + config.separatorWidth : 0
  const middleLength = ellipsisLength + config.separatorWidth

  // Divide equally (as a starting point)
  const givenSpace = containerWidth - middleLength

  let fittedFirst = Math.min(firstSegment.width, givenSpace / 2)
  let fittedLast = Math.min(lastSegment.width, givenSpace / 2)

  // Calculate leftover space after assigning
  let leftover = givenSpace - (fittedFirst + fittedLast)

  if (leftover > 0) {
    // If A can still grow, give it leftover first
    const canGrowA = firstSegment.width - fittedFirst
    const growA = Math.min(canGrowA, leftover)
    fittedFirst += growA
    leftover -= growA

    // If there is still leftover, try to grow B
    if (leftover > 0) {
      const canGrowB = lastSegment.width - fittedLast
      const growB = Math.min(canGrowB, leftover)
      fittedLast += growB
      leftover -= growB
    }
  }

  return [
    { kind: 'visible', id: firstSegment.id, width: fittedFirst },
    { kind: 'separator', width: separatorWidth },
    ...(middleSegments.length > 0
      ? [
          {
            kind: 'ellipsis',
            width: ellipsisWidth,
            segments: middleSegments.map(s => s.id),
          } as const,
          { kind: 'separator', width: separatorWidth } as const,
        ]
      : []),
    { kind: 'visible', id: lastSegment.id, width: fittedLast },
  ]
}

/**
 * Try to fit as many segments as possible by adding segments from both ends.
 *
 * ---
 * How it works:
 * - first we are going to add first and last segments (They are always included)
 * - then we are going to add elements from both ends one by one, i.e. second-last, second, third-last, third and so on
 * - if element from one end doesn't fit, we are going to try element from the other end multiple times until we cannot fit any more segments from both ends
 * - all remaining segments are going to be replaced by ellipsis (and a tooltip)
 * - between each segment we are going to add a separator
 *
 * The result ensures that the order of segments is preserved and the ellipsis is always in the middle.
 *
 */
function fitMiddleSegments(
  config: SplitConfig,
  segments: MeasuredSegment[]
): SplitSegment[] {
  const firstSegment = segments[0]
  const lastSegment = segments[segments.length - 1]
  const middleSegments = segments.slice(1, -1)

  const fittingSegments: MeasuredSegment[] = [firstSegment, lastSegment]

  const getCurrentLength = () =>
    fittingSegments.reduce((acc, segment) => acc + segment.width, 0) +
    config.ellipsisWidth +
    fittingSegments.length * config.separatorWidth

  const isFitting = (segment: MeasuredSegment) =>
    getCurrentLength() + config.separatorWidth + segment.width <=
    config.containerWidth

  let failedAttemptCounts = 0
  let tryLast = true
  while (failedAttemptCounts < 2 && middleSegments.length > 0) {
    const segment = middleSegments[tryLast ? middleSegments.length - 1 : 0]

    if (isFitting(segment)) {
      fittingSegments.push(segment)
      middleSegments.splice(tryLast ? middleSegments.length - 1 : 0, 1)
      failedAttemptCounts = 0
    } else {
      failedAttemptCounts++
    }

    tryLast = !tryLast
  }

  const front = takeWhile(segments, s => fittingSegments.includes(s))
  const back = takeWhile([...segments].reverse(), s =>
    fittingSegments.includes(s)
  ).reverse()
  const middle = segments.filter(x => !fittingSegments.includes(x))
  const ellipsis = toEllipsis(config, middle)

  return intersperse(
    [...front.map(toVisible), ellipsis, ...back.map(toVisible)],
    toSeparator(config.separatorWidth)
  )
}

export function splitSegments(props: SplitSegmentsProps): SplitSegment[] {
  const { config, segments } = props
  const { containerWidth, ellipsisWidth, separatorWidth } = config

  const wantedWidth =
    segments.reduce((acc, segment) => acc + segment.width, 0) +
    separatorWidth * (segments.length - 1)

  // we can fit all segments
  if (wantedWidth <= containerWidth) {
    return intersperse(segments.map(toVisible), toSeparator(separatorWidth))
  }

  // if even first and last segments are too wide to fit
  // Here we are calculating structure:
  //       SEGMENT / ... / SEGMENT
  const middleLength =
    segments.length <= 2 ? separatorWidth : ellipsisWidth + separatorWidth * 2
  if (
    segments[0].width + middleLength + segments[segments.length - 1].width >
    containerWidth
  ) {
    return fitOvergrownSegments(config, segments)
  }

  // At this point we know that the first and last segments are not too wide to fit
  // But all segments together are too wide to fit
  //
  // Nonetheless, want to fit as many segments as possible.
  // We are going to try adding second-last segment, then second, then third-last, then third
  // and so on, until we cannot fit any more segments.

  return fitMiddleSegments(config, segments)
}
