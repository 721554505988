import {
  BaseAnnotationFragment,
  BaseCommentFragment,
  CommentFragment,
} from '@sketch/gql-types'

export const isAnnotationNewOrHasNewComments = (
  annotation?: BaseAnnotationFragment
) => isAnnotationNew(annotation) || annotation?.hasNewComments

export const isAnnotationNew = (annotation?: BaseAnnotationFragment) =>
  annotation?.isNew

export const isCommentNew = (comment?: CommentFragment) =>
  comment?.isRead === false

export const isAnnotationResolved = (annotation?: BaseAnnotationFragment) =>
  Boolean(annotation?.resolution !== null)

export const isAnnotationCommentRead = (annotation?: BaseCommentFragment) =>
  annotation?.isRead === true
