import React from 'react'
import * as yup from 'yup'
import { useFormikContext } from 'formik'

import { validEmail } from '@sketch/utils'
import { Tooltip } from '@sketch/components'
import { useIsTaxableCountry } from './useGetTaxableCountries'

import FormField from '../FormField'
import CountryField from '../CountryField'
import { Section } from '../CreateWorkspaceCommon'

import {
  ResponsiveInlineField,
  TitleWrapper,
  H3,
} from './BillingDetailsForm.styles'
import { BillingDetailsFormFields } from './types'

const REQUIRED_STATE_COUNTRIES = ['US']

export const validationSchema = yup.object().shape({
  name: yup.string().trim().required('Name is required'),
  email: validEmail().trim().required('Email is required'),
  address: yup.string().trim().required('Address is required'),
  country: yup.string().required('Country is required'),
  city: yup.string().trim().required('City is required'),
  state: yup.string().when('country', {
    is: 'US',
    then: schema => schema.trim().required('State is required'),
    otherwise: schema => schema.trim(),
  }),
  postalCode: yup.string().trim().required('Postal Code is required'),
  taxId: yup.string().trim(),
})

interface BillingDetailsFormProps {
  disabled?: boolean
  showHeader: boolean
}

const BillingDetailsForm = <FormValues extends BillingDetailsFormFields>({
  disabled,
  showHeader = true,
}: BillingDetailsFormProps) => {
  const { values } = useFormikContext<FormValues>()
  const isStateMandatory = REQUIRED_STATE_COUNTRIES.includes(values.country)
  const { isTaxable, loadingTaxableCountries, errorTaxableCountries } =
    useIsTaxableCountry<FormValues>()

  // Vat/Taxid input is only displayed once we are sure that the selected country supports the tax field
  const displayTaxIdInput = !loadingTaxableCountries && !errorTaxableCountries

  return (
    <>
      {showHeader && (
        <TitleWrapper>
          <H3>Billing Information</H3>
        </TitleWrapper>
      )}
      <Section>
        <FormField<FormValues>
          label="Name"
          help="Your name or your company’s name."
          name="name"
          disabled={disabled}
        />
        <FormField<FormValues>
          label="Billing Email"
          help="We’ll send billing notifications to this email"
          name="email"
          disabled={disabled}
        />
      </Section>
      <Section>
        <FormField<FormValues>
          label="Address"
          name="address"
          disabled={disabled}
        />
        <FormField<FormValues> label="City" name="city" disabled={disabled} />
        <ResponsiveInlineField>
          <FormField<FormValues>
            label={`State or Province ${isStateMandatory ? '' : ' (Optional)'}`}
            name="state"
            disabled={disabled}
          />
          <FormField<FormValues>
            label="ZIP or Postal Code"
            name="postalCode"
            disabled={disabled}
          />
        </ResponsiveInlineField>
        <CountryField name="country" disabled={disabled} />
      </Section>
      {displayTaxIdInput && (
        <Section>
          <ResponsiveInlineField>
            <Tooltip
              placement="top"
              content="VAT / Tax ID is not available for this country"
              disabled={isTaxable}
            >
              <FormField<FormValues>
                label="VAT or Tax ID (Optional)"
                placeholder="VAT or Tax ID"
                name="taxId"
                disabled={!isTaxable || disabled}
              />
            </Tooltip>
          </ResponsiveInlineField>
        </Section>
      )}
    </>
  )
}

export default BillingDetailsForm
