import React from 'react'
import styled from 'styled-components'

import { breakpoint } from '@sketch/global-styles'

import {
  SectionElement,
  TextAndButtonWrapper,
  Title,
  ActionWrapper,
  TitleAndActionsWrapper,
  LeftWrapper,
  RightWrapper,
} from './Section.styles'

interface SectionProps {
  className?: string
  'data-testid'?: string
  id?: string
  title: React.ReactNode
  text?: React.ReactNode
  action?: React.ReactNode
  align?: 'bottom' | 'center'
  children?: React.ReactNode
}

const getId = (id?: string) => {
  if (!id) return undefined
  return { id }
}

const SectionUnstyled = React.forwardRef<HTMLDivElement, SectionProps>(
  function Section(props, ref) {
    const {
      className,
      children,
      title,
      text,
      action,
      align = 'bottom',
      'data-testid': dataTestId,
      id,
    } = props

    return (
      <SectionElement
        ref={ref}
        className={className}
        data-testid={dataTestId}
        {...getId(id)}
      >
        <TitleAndActionsWrapper>
          <LeftWrapper>
            <Title>{title}</Title>
            {(text || action) &&
              (text ? (
                <TextAndButtonWrapper>
                  {text}
                  {action && align === 'bottom' && (
                    <ActionWrapper>{action}</ActionWrapper>
                  )}
                </TextAndButtonWrapper>
              ) : (
                action &&
                align === 'bottom' && <ActionWrapper>{action}</ActionWrapper>
              ))}
          </LeftWrapper>
          {action && align === 'center' && (
            <RightWrapper>
              <ActionWrapper>{action}</ActionWrapper>
            </RightWrapper>
          )}
        </TitleAndActionsWrapper>

        {children}
      </SectionElement>
    )
  }
)

export const Section = styled(SectionUnstyled)`
  & + & {
    margin-top: 40px;

    ${breakpoint('sm')`
      margin-top: 64px;
    `}
  }
`
