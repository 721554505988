import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Formik } from 'formik'
import { Form, Button, ModalInjectedProps } from '@sketch/components'
import {
  ModalBody,
  MinHeightModal,
  ModalFooterZIndex,
} from './CreateDesignSystemModal.styles'
import { FormEditCreate, validationSchema } from '../FormEditCreate'
import { routes } from '@sketch/modules-common'
import { useCreateDesignSystemMutation } from '@sketch/gql-types'
import { FormProps } from '../../types'

// Form
const INITIAL_VALUES: FormProps = {
  name: '',
  description: '',
  libraries: [],
}

interface CreateDesignSystemModalProps extends ModalInjectedProps {
  workspaceId: string
  loadLatestHandler?: () => Promise<any>
}

export const CreateDesignSystemModal = (
  props: CreateDesignSystemModalProps
) => {
  const { hideModal, workspaceId, loadLatestHandler } = props
  const [logo, setLogo] = useState<File | null>(null)
  const history = useHistory()

  const [createDesignSystem, { loading: submitting }] =
    useCreateDesignSystemMutation({
      onCompleted: ({
        createDesignSystem,
      }: {
        createDesignSystem: { identifier: string }
      }) => {
        history.push(
          routes.DESIGN_SYSTEM.create({
            workspaceId,
            designSystemId: createDesignSystem.identifier,
          })
        )

        loadLatestHandler?.()
      },
      onError: 'show-toast',
    })

  const handleSubmit = (values: FormProps) => {
    createDesignSystem({
      variables: {
        workspaceIdentifier: workspaceId,
        input: {
          name: values.name,
          description: values.description,
          shares: values.libraries,
          preview: logo,
        },
      },
    })
  }

  return (
    <MinHeightModal title="Create a Design System" onCancel={hideModal}>
      <Formik
        validationSchema={validationSchema}
        initialValues={INITIAL_VALUES}
        onSubmit={handleSubmit}
      >
        {formikbag => {
          const cantSubmit = !!(
            formikbag.values.libraries.length === 0 ||
            formikbag.values.name.trim() === ''
          )

          return (
            <Form>
              <ModalBody>
                <FormEditCreate
                  hideModal={hideModal}
                  onImageChange={setLogo}
                  formikbag={formikbag}
                  submitting={submitting}
                  workspaceId={workspaceId ?? ''}
                />
              </ModalBody>
              <ModalFooterZIndex>
                <Button disabled={submitting} onClick={hideModal}>
                  Cancel
                </Button>
                <Button
                  data-testid="create-ds-button"
                  type="submit"
                  variant="primary"
                  loading={submitting}
                  disabled={cantSubmit}
                  onClick={formikbag.submitForm}
                >
                  Create Design System
                </Button>
              </ModalFooterZIndex>
            </Form>
          )
        }}
      </Formik>
    </MinHeightModal>
  )
}
