import React from 'react'
import styled from 'styled-components'

import { Text, Button } from '@sketch/components'

import CommentTabMessageError from '../CommentTabMessageError'
import CommentSkeleton from '../CommentSkeleton'

import useGetAnnotations from '../../operations/useGetAnnotations'

import { useAnnotationQueryVariables } from '../../context'

import AnnotationList from '../AnnotationList'
import {
  AnnotationDotFragment,
  BaseAnnotationFragment,
} from '@sketch/gql-types'
import { NavLinkProps } from 'react-router-dom'

const StyledButton = styled(Button)`
  margin-top: 16px;
`

const PLACEHOLDER_COUNT = 3

// Same interface used in "containers/AnnotationsListContainer"
interface ListProps {
  isCanvasLoading?: boolean
  activeAnnotationIdentifier?: string
  resolveAnnotationLink?: (
    annotation: BaseAnnotationFragment
  ) => NavLinkProps['to'] | undefined
  shouldRenderAnnotation?: (
    annotation: AnnotationDotFragment,
    isActiveAnnotation: boolean
  ) => boolean
}

const ActiveOrResolvedAnnotationsList: React.FC<ListProps> = props => {
  const {
    activeAnnotationIdentifier,
    resolveAnnotationLink,
    shouldRenderAnnotation,
    isCanvasLoading,
  } = props

  const variables = useAnnotationQueryVariables('sidebar')
  const { annotationStatus } = variables

  const { data, error, loading, fetchMore, refetch } = useGetAnnotations({
    variables,
  })

  if (loading || isCanvasLoading) {
    return <CommentSkeleton count={PLACEHOLDER_COUNT} />
  }

  if (error || !data) {
    return (
      <CommentTabMessageError title="Unable to load comments">
        <Text textStyle="copy.quarternary.standard.D">
          There was an error loading comments.
        </Text>
        <StyledButton onClick={() => refetch()} variant="secondary" size="32">
          Try again
        </StyledButton>
      </CommentTabMessageError>
    )
  }

  const { entries, meta } = data.annotations

  return (
    <AnnotationList
      activeAnnotationIdentifier={activeAnnotationIdentifier}
      resolveAnnotationLink={resolveAnnotationLink}
      entries={entries}
      meta={meta}
      fetchMore={fetchMore}
      annotationStatus={annotationStatus || 'ACTIVE_ONLY'}
      shouldRenderAnnotation={shouldRenderAnnotation}
    />
  )
}

export default ActiveOrResolvedAnnotationsList
