import React from 'react'

import { PLAN_TO_DURATION_LABEL } from '../../constants'

import { formatPriceShort, dateFormat } from '@sketch/utils'
import { getDiscountError, getAppliedDiscount } from '../../utils'

import { Spinner, Button, pluralize, Text } from '@sketch/components'
import Discount, { DiscountError } from '../Discount'

import {
  Wrapper,
  InnerWrapper,
  Item,
  TrialText,
  Section,
  HalfDivider,
  Paragraph,
  StrongText,
  SmallText,
} from './BillSimulation.styles'

// GQL
import {
  useDraftBillingSummaryQuery,
  useDraftBillingSummaryLazyQuery,
  BillingPlanFragment,
} from '@sketch/gql-types'

export interface BillSimulationProps {
  plan: BillingPlanFragment
  trialEnd: string
  numberEditors: number
  numberExtraSeats: number
  showDiscountInput?: boolean
  discountCode?: string
  variant?: 'default' | 'partner'
  onValidDiscountCode?: (discountCode: string) => void
  onClearDiscountCode?: () => void
  discountError?: DiscountError
  onUpdateDiscountError?: (discountError?: DiscountError) => void
}

const formatPrice = (price: number) =>
  formatPriceShort(price, { trimDecimalIfZero: false })

const buildDate = (date: string) => dateFormat(new Date(date), 'en-US')

const addOneYear = (date: string) => {
  const nextYearDate = new Date(date)
  nextYearDate.setFullYear(nextYearDate.getFullYear() + 1)
  return dateFormat(nextYearDate)
}

const BillSimulation: React.FC<BillSimulationProps> = ({
  plan,
  trialEnd,
  numberEditors,
  numberExtraSeats,
  showDiscountInput,
  discountCode,
  variant = 'default',
  onValidDiscountCode,
  onClearDiscountCode,
  discountError,
  onUpdateDiscountError,
}) => {
  /*
    Get Billing Summary
    If we stored a valid discount code in the state, this summary
    will return a CouponInfo with the valid coupon
  */
  const { loading, data, error, refetch } = useDraftBillingSummaryQuery({
    variables: {
      planId: plan.id,
      seats: numberEditors,
      // Get the summary with the stored valid discount code
      promotionCode: discountCode,
    },
  })

  // The actual valid discount code
  const appliedDiscount = getAppliedDiscount(
    data?.draftBillingSummary?.couponInfo || undefined
  )

  // We first use this lazy query to preview if the discount is valid
  const [getSummaryPreviewWithDiscount, summaryPreviewWithDiscount] =
    useDraftBillingSummaryLazyQuery({
      fetchPolicy: 'network-only',
      onCompleted: draftBillingSummaryData => {
        onClearDiscountCode?.()

        const error = getDiscountError(
          summaryPreviewWithDiscount.data?.draftBillingSummary?.couponInfo ||
            undefined
        )
        onUpdateDiscountError?.(error)

        const couponInfo =
          draftBillingSummaryData?.draftBillingSummary?.couponInfo

        if (couponInfo && !couponInfo.errorCode) {
          onValidDiscountCode?.(couponInfo.promotionCode)
        }
      },
    })

  const handleApplyDiscount = (discountCode: string) =>
    getSummaryPreviewWithDiscount({
      variables: {
        planId: plan.id,
        seats: numberEditors + numberExtraSeats,
        promotionCode: discountCode,
      },
    })

  const getDiscountText = () => {
    if (!appliedDiscount) return

    if (variant === 'partner') {
      return `Sketch Partner Discount: ${appliedDiscount.percentOff}% Off`
    }

    return `${appliedDiscount.percentOff}% Off (${appliedDiscount.code})`
  }

  let children = null

  if (data && !error) {
    const { draftBillingSummary } = data
    const isOnTrial = new Date(trialEnd) > new Date()

    children = (
      <>
        {isOnTrial && (
          <TrialText>What you would be charged after your trial ends</TrialText>
        )}

        <InnerWrapper>
          {showDiscountInput && onClearDiscountCode && (
            <Item marginBottom singleColumn>
              <Discount
                onApplyDiscountCode={handleApplyDiscount}
                onClearDiscountCode={onClearDiscountCode}
                appliedDiscountCodes={discountCode ? [discountCode] : []}
                error={discountError}
                isDisabled={loading || summaryPreviewWithDiscount.loading}
                isLoading={summaryPreviewWithDiscount.loading}
              />
            </Item>
          )}

          <Item marginBottom>
            <div>
              {numberEditors} {pluralize('Editor', 'Editors', numberEditors)}
            </div>
            <div data-testid="editors-total">
              {formatPrice(plan.invoicePrice * numberEditors)}
            </div>
          </Item>

          {appliedDiscount && (
            <>
              <Item>
                <div>{getDiscountText()}</div>
                <div>{formatPrice(-appliedDiscount.price)}</div>
              </Item>
            </>
          )}
        </InnerWrapper>

        {appliedDiscount && (
          <Item>
            <StrongText>
              {variant === 'partner'
                ? `Total per ${PLAN_TO_DURATION_LABEL[plan.type]}`
                : 'Total'}
            </StrongText>
            <StrongText>
              {formatPrice(draftBillingSummary.billingCycleAmount)}
            </StrongText>
          </Item>
        )}
        {!appliedDiscount && (
          <Item>
            <Text textStyle="prominent.primary.F">
              Total per {PLAN_TO_DURATION_LABEL[plan.type]}
            </Text>
            <StrongText data-testid="invoice-total">
              {formatPrice(draftBillingSummary.billingCycleAmount)}
            </StrongText>
          </Item>
        )}

        {isOnTrial && !appliedDiscount && (
          <Item>
            <SmallText>
              Due on {buildDate(trialEnd)}, then every{' '}
              {PLAN_TO_DURATION_LABEL[plan.type].toLowerCase()}
            </SmallText>
          </Item>
        )}
        {isOnTrial && appliedDiscount && (
          <Item singleColumn={true}>
            <SmallText>
              Due on {buildDate(trialEnd)}, and valid until{' '}
              {addOneYear(trialEnd)}
            </SmallText>
          </Item>
        )}
      </>
    )
  } else if (loading) {
    children = (
      <Section>
        <HalfDivider>
          <Paragraph>Loading your bill summary…</Paragraph>
          <Spinner primary />
        </HalfDivider>
      </Section>
    )
  } else {
    children = (
      <Section>
        <HalfDivider>
          <Paragraph>Sorry — we could not update your summary.</Paragraph>
          <Button
            variant="primary"
            size="40"
            loading={loading}
            onClick={() => refetch()}
          >
            Refresh
          </Button>
        </HalfDivider>
      </Section>
    )
  }

  return <Wrapper>{children}</Wrapper>
}

export default BillSimulation
