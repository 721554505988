import React, { FC, useState, useEffect } from 'react'

import { IS_EMBEDDED } from '@sketch/constants'

import {
  useRenderPresentationMutation,
  VersionFragment,
} from '@sketch/gql-types'
import { useGetVersionQueryWithPolling } from '../../shares/operations'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { useOnEvent } from '@sketch/utils'
import {
  GenericErrorView,
  LoadingPage,
  getRenderStatus,
  useUserSignedIn,
} from '@sketch/modules-common'

import { extractVersionInformation } from './extractVersionInformation'
import {
  ShareVersionContext,
  ShareWithoutVersion,
  VersioningContext,
  VersioningContextBase,
} from './ShareVersionContext'
import {
  isUpgradeToLatestNeeded,
  UpgradeToLatestVersion,
  useUpgradeToLatestVersion,
} from './UpgradeToLatestVersion'
import { useVersionUpdateCallback } from './useVersionUpdateCallback'
import { useGetPendingPatches } from '../hooks/useGetPendingPatches'
import { LoadingMessage } from '../../shares/components/LoadingMessage'

export interface VersionUpdateHandlerProps
  extends Pick<RouteComponentProps, 'location' | 'history'> {
  shareIdentifier: string
  selectedVersionShortId: string
}

const VersionUpdateHandler: FC<VersionUpdateHandlerProps> = ({
  shareIdentifier,
  selectedVersionShortId,
  history,
  location,
}) => {
  const versionUpdateHandler = useVersionUpdateCallback({ location, history })
  useOnEvent('versionIsProcessed', share => {
    if (share.identifier === shareIdentifier) {
      versionUpdateHandler(share)
    }
  })

  const { upgradeToLatest } = useUpgradeToLatestVersion()
  useOnEvent('versionDeleted', ({ share, versionShortId, versionKind }) => {
    // in case user is looking at the version which just got deleted - upgrade to the latest version:
    // See for more details:
    // - https://github.com/sketch-hq/Cloud/issues/3892#issuecomment-657587833
    // - https://github.com/sketch-hq/Cloud/issues/4715
    if (
      share.identifier === shareIdentifier &&
      versionShortId === selectedVersionShortId
    ) {
      // We'll be automatically and aggressively pruning "auto" versions
      // and to avoid confusing users, we've opted to not show the toast
      // when upgrading them to the latest version.
      const skipToast = versionKind === 'AUTO'
      upgradeToLatest('deleted-version', skipToast)
    }
  })
  return null
}

export interface ShareVersionProviderProps extends RouteComponentProps {
  latestVersion: VersionFragment
  onVersionUpdateNeeded?: () => Promise<void>
  share: ShareWithoutVersion
}

export const ShareVersionProvider: FC<ShareVersionProviderProps> = ({
  location,
  onVersionUpdateNeeded,
  history,
  share,
  latestVersion,
  children,
}) => {
  const { matchKind, versionShortId: versionShortIdFromLocation } =
    extractVersionInformation(location.pathname)

  const isSignedIn = useUserSignedIn()

  // save the artboard thumbnail URL, so when we navigate into an artboard we can already load it
  const [artboardThumbnail, setArtboardThumbnail] = useState('')

  if (matchKind === 'none') {
    throw Error('The given location should be within the context of a document')
  }

  const latestVersionId = latestVersion.shortId!
  const versionShortId = versionShortIdFromLocation || latestVersionId
  const isViewingLatestVersion =
    matchKind === 'latest' ||
    (matchKind === 'specific' && versionShortIdFromLocation === latestVersionId)

  const hasPendingPatches = useGetPendingPatches({
    shareIdentifier: share.identifier,
  })

  const versioningContextBase: VersioningContextBase = {
    latestVersion,
    share,
    versionShortId,
    latestVersionId,
    onVersionUpdateNeeded: async () =>
      onVersionUpdateNeeded && (await onVersionUpdateNeeded()),
    isViewingLatestVersion,
    hasPendingPatches,
    setArtboardThumbnail,
    artboardThumbnail,
    renderStatus: null,
  }

  // NOTE: We use the `useGetVersionQueryWithPolling` hook so that anonymous
  // users are updated when the version finishes rendering.
  // The web app does not open websocket connections to SketchQL for anonymous
  // users so we need to use polling.
  // Without this, users could get stuck on a loading screen since they would
  // not be informed when the version finishes rendering.
  // Similarly, subscriptions are not used when the document is embedded.
  // We skip polling for signed in users (when not embedded) and rely on the
  // subscriptions
  const { data, loading, error } = useGetVersionQueryWithPolling({
    variables: {
      shareIdentifier: share.identifier,
      versionShortId,
    },
    skipPolling: isSignedIn && !IS_EMBEDDED,
    pollingIntervalMs: 5000,
  })

  const renderStatus = !loading
    ? (getRenderStatus(data?.share?.version?.document?.renderStatus ?? null) ??
      null)
    : null

  // TODO: Improve the status with a possible ERROR state
  // More context: https://sketch.slack.com/archives/C05STLR9PN0/p1696844738742039
  const presentationStatus =
    data?.share?.version?.presentationStatus ?? 'PENDING'

  const hasPresentationFile = presentationStatus === 'COMPLETED'

  const [renderPresentation] = useRenderPresentationMutation({
    onError: 'do-nothing',
  })

  const versionIdentifier = data?.share?.version?.identifier

  /* NOTE: render the presentation files for the current version if they are not
   *  available.

   *  Since presentation files are not stored permanently, and are not rendered
   *  at all for inactive workspaces (with the `light-ingest` feature), we may
   *  need to request a render of the presentation files in order to be able to
   *  view the document.
   */
  useEffect(() => {
    if (versionIdentifier && !hasPresentationFile) {
      renderPresentation({
        variables: { versionIdentifier },
      })
    }
  }, [versionIdentifier, hasPresentationFile, renderPresentation])

  if (loading) {
    return <LoadingPage />
  }

  const isProcessingPresentationFile = presentationStatus === 'PENDING'

  const shouldInvalidate =
    !!data?.share && data.share.version?.shortId !== versionShortId

  const value: VersioningContext =
    loading || !data?.share?.version || shouldInvalidate
      ? {
          ...versioningContextBase,
          loading: true,
        }
      : {
          ...versioningContextBase,
          loading: false,
          currentVersion: data.share.version,
          renderStatus,
        }

  if (isUpgradeToLatestNeeded(error)) {
    return (
      <ShareVersionContext.Provider value={value}>
        <UpgradeToLatestVersion error={error} />
      </ShareVersionContext.Provider>
    )
  }

  if (error || (!loading && !data?.share)) {
    return <GenericErrorView error={error} />
  }

  if (isProcessingPresentationFile) {
    return (
      <span data-testid="loading-presentation-file">
        <LoadingPage
          type="color-icon"
          size="48px"
          message={<LoadingMessage />}
          hideFooter
        />
      </span>
    )
  }

  return (
    <ShareVersionContext.Provider value={value}>
      <VersionUpdateHandler
        location={location}
        history={history}
        selectedVersionShortId={versionShortId}
        shareIdentifier={share.identifier}
      />
      {children}
    </ShareVersionContext.Provider>
  )
}

export default withRouter(ShareVersionProvider)
