import React from 'react'
import { useCanvasZoom } from '../../../../../components/WebRendererCanvas/hooks'
import { Zoom } from '../../../../../components/Zoom'
import { useGridAndLayoutPropForZoomComponent } from '../../../ArtboardDetailView'
import { MAX_ZOOM, MIN_ZOOM } from '../../../../../components/Zoom/constants'

export const FloatingZoom = () => {
  const [zoom, setZoom] = useCanvasZoom()
  const gridAndLayoutPropsForZoomComponent =
    useGridAndLayoutPropForZoomComponent()
  const preventiveGridAndLayout = gridAndLayoutPropsForZoomComponent

  return (
    <Zoom
      zoom={zoom}
      setZoom={setZoom}
      minZoom={MIN_ZOOM}
      maxZoom={MAX_ZOOM}
      gridAndLayout={preventiveGridAndLayout}
      explicitControls
    />
  )
}
