import React, { ReactNode, CSSProperties } from 'react'
import styled from 'styled-components'

import { Heading } from '../Heading'
import { Flex, Text } from '../Box'

const iconSizeToPixels = {
  small: 42,
  medium: 64,
  large: 72,
} as const

type IconSize = keyof typeof iconSizeToPixels

export interface TextProps {
  $dark?: boolean
}

export const Container = styled(Flex).attrs({
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
})`
  width: 100%;
  height: 100%;
  flex: 1 0 0;
`

export const Title = styled(Heading.H3)<TextProps>`
  max-width: 100%;
  text-align: center;

  margin-top: 20px;
  margin-bottom: 6px; /* stylelint-disable-line scales/space */

  font-size: 1rem;
  line-height: 1;

  color: ${({ theme, $dark }) =>
    $dark ? 'white' : theme.colors.foreground.secondary.B};
`

export const Body = styled(Text)<TextProps>`
  max-width: 100%;
  text-align: center;

  margin-top: 0;
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }

  font-size: 0.875rem;
  line-height: 1.5;
  color: ${({ theme, $dark }) =>
    $dark ? 'white' : theme.colors.foreground.secondary.D};

  b {
    font-weight: 400;
    color: ${({ theme, $dark }) =>
      $dark ? 'white' : theme.colors.foreground.secondary.A};
  }
`

interface IconContainerProps extends TextProps {
  iconSizePx: number
}

const IconContainer = styled.div<IconContainerProps>`
  width: ${props => props.iconSizePx}px;
  height: ${props => props.iconSizePx}px;

  opacity: 0.55;
  color: ${({ theme, $dark }) =>
    $dark ? 'white' : theme.colors.foreground.secondary.B};
`

type IconRenderer = (props: { width?: number; iconSize: IconSize }) => ReactNode

export interface ErrorMessageProps {
  // Icon component or React element
  icon: ReactNode | IconRenderer
  iconSize?: IconSize
  className?: string
  title?: string
  // Description of error state
  description?: string | ReactNode
  extra?: ReactNode
  // Dark theme, used for empty states in prototyping
  dark?: boolean
  style?: CSSProperties
}

export const ErrorMessage: React.FC<ErrorMessageProps> = props => {
  const {
    children,
    dark,
    description,
    extra,
    icon,
    iconSize = 'large',
    title,
    className,
  } = props
  const textContent = children || description
  const iconSizePixels = iconSizeToPixels[iconSize]

  let iconComponent
  if (icon && React.isValidElement(icon)) {
    iconComponent = (
      <IconContainer iconSizePx={iconSizePixels} $dark={dark}>
        {icon}
      </IconContainer>
    )
  } else if (icon && typeof icon === 'function') {
    iconComponent = icon({ width: iconSizePixels, iconSize })
  } else {
    iconComponent = null
  }

  return (
    <Container className={className} $dark={dark}>
      {iconComponent}
      {title && <Title $dark={dark}>{title}</Title>}
      {textContent && <Body $dark={dark}>{textContent}</Body>}
      {extra}
    </Container>
  )
}
