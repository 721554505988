import { DataProxy } from 'apollo-cache'
import { removeFromPaginated, removeQuery } from '@sketch/modules-common'
import { removeTrashedShare } from '../../../collections/operations'
import { ShareListItemFragment } from '@sketch/gql-types'

export interface HandleShareDeletedProps {
  cache: DataProxy
  identifier: string
  workspaceIdentifier: string
}

export const handleShareDeleted = (props: HandleShareDeletedProps) => {
  const { cache, identifier, workspaceIdentifier } = props

  const targetShare = { __typename: 'Share', identifier } satisfies Pick<
    ShareListItemFragment,
    'identifier' | '__typename'
  >

  // there still can be smaller fragments in the cache
  // let's remove all of them.
  removeFromPaginated(cache, targetShare, key => key.includes('.shares'))

  // TODO: Update counters, see https://linear.app/sketch/issue/SWEB-712
  removeFromPaginated(cache, targetShare, key => key.includes('.storageItems'))

  // Remove the share from any collections
  removeTrashedShare({ cache, identifier })

  // Remove from cache every query related with this workspace trash
  // This way the view will be refetched from scratch if needed to make sure
  // we always get the correct server information
  removeQuery(
    cache,
    key => key.includes(workspaceIdentifier) && key.includes('.workspaceTrash')
  )
}
