import { useRef, useEffect } from 'react'
import {
  AnalyticsEventName,
  AnalyticsEventNameWithoutProps,
  AnalyticsEventNameWithProps,
  AnalyticsEventPropsOf,
} from './types'

import { useAnalytics } from '../analytics.context'

export function useTrackFirstValueChange<
  TEventName extends AnalyticsEventNameWithProps,
>(
  fieldValue: any,
  eventName: TEventName,
  eventProps: AnalyticsEventPropsOf<TEventName>
): void

export function useTrackFirstValueChange<
  TEventName extends AnalyticsEventNameWithoutProps,
>(fieldValue: any, eventName: TEventName): void

/**
 * Send a tracking event the very first time value changes. If the value changes again after,
 * the tracking event will not be sent again.
 * This is typically used to send a tracking event when the user starts typing
 * in a form input field.
 */
export function useTrackFirstValueChange<TEventName extends AnalyticsEventName>(
  fieldValue: any,
  eventName: TEventName,
  eventProps?: TEventName extends AnalyticsEventNameWithProps
    ? AnalyticsEventPropsOf<TEventName>
    : never
) {
  const { trackEvent } = useAnalytics()
  const alreadyTrackedRef = useRef(false)
  useEffect(() => {
    // Track only the first edit
    if (fieldValue && !alreadyTrackedRef.current) {
      if (eventProps) {
        trackEvent(eventName as AnalyticsEventNameWithProps, eventProps)
      } else {
        trackEvent(eventName as AnalyticsEventNameWithoutProps)
      }
      alreadyTrackedRef.current = true
    }
  }, [fieldValue, eventName, eventProps, trackEvent])
}
