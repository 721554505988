import React, {
  useState,
  createContext,
  useContext,
  useMemo,
  useCallback,
} from 'react'
import { SearchOptionsObject } from '../components/Notifications/NotificationSearch/types'

interface NotificationSearchContextValues {
  search: string
  setSearch: React.Dispatch<React.SetStateAction<string>>
  options: SearchOptionsObject
  setOptions: React.Dispatch<React.SetStateAction<SearchOptionsObject>>
}

const defaultSearchOptions: SearchOptionsObject = {
  comments: false,
  replies: false,
  mentions: false,
  downloadableAssets: false,
  starredUpdates: false,
  location: null,
  users: [],
  from: '',
  to: '',
}

export const NotificationSearchContext =
  createContext<NotificationSearchContextValues>({
    search: '',
    setSearch: (value: React.SetStateAction<string>) => {},
    options: defaultSearchOptions,
    setOptions: (value: React.SetStateAction<SearchOptionsObject>) => {},
  })

export const useNotificationSearchContext = () => {
  const context = useContext(NotificationSearchContext)

  if (typeof context === 'undefined') {
    throw new Error(
      'useNotificationSearchContext must be used within a NotificationSearchProvider'
    )
  }

  const { search, setSearch, options, setOptions } = context

  const resetOptions = useCallback(
    () => setOptions(defaultSearchOptions),
    [setOptions]
  )

  const resetAll = useCallback(() => {
    resetOptions()
    setSearch('')
  }, [resetOptions, setSearch])

  const isOptionsEmpty = useCallback(() => {
    const {
      comments,
      replies,
      mentions,
      downloadableAssets,
      starredUpdates,
      location,
      users,
      from,
      to,
    } = context.options

    return (
      comments === false &&
      replies === false &&
      mentions === false &&
      downloadableAssets === false &&
      starredUpdates === false &&
      location === null &&
      users.length === 0 &&
      from === '' &&
      to === ''
    )
  }, [context.options])

  const values = useMemo(
    () => ({
      search,
      setSearch,
      options,
      setOptions,
      resetOptions,
      resetAll,
      isOptionsEmpty,
    }),
    [
      isOptionsEmpty,
      options,
      resetAll,
      resetOptions,
      search,
      setOptions,
      setSearch,
    ]
  )

  return values
}

interface NotificationSearchProviderProps {
  children: React.ReactNode
}

export const NotificationSearchProvider: React.FC<
  NotificationSearchProviderProps
> = ({ children }) => {
  const [search, setSearch] = useState<string>('')
  const [options, setOptions] =
    useState<SearchOptionsObject>(defaultSearchOptions)

  return (
    <NotificationSearchContext.Provider
      value={{ search, setSearch, options, setOptions }}
    >
      {children}
    </NotificationSearchContext.Provider>
  )
}
