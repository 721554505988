import React from 'react'

import { AccessLevelSelect as AccessLevelSelectRaw } from '@sketch/components'
import { AccessLevelSelectProps, AllowedAccessLevels } from './types'
import { getTriggerCopyFromAccessLevel } from './utils'

// We will rely on the typing from the parent components, that will
// check that the level and setLevel are one of the allowed levels
export const AccessLevelSelect = <T, U>({
  level,
  setLevel,
  disabled,
  commentsEnabled,
  hideCommentLabel,
  position,
  triggerButton,
  children,
  noAccessRedColor = true,
  title = 'Access',
}: AccessLevelSelectProps<T, U>) => {
  const label = getTriggerCopyFromAccessLevel(
    level as unknown as AllowedAccessLevels
  )

  return (
    <AccessLevelSelectRaw<T, U>
      label={label}
      {...{
        level,
        setLevel,
        disabled,
        commentsEnabled,
        hideCommentLabel,
        position,
        triggerButton,
        children,
        noAccessRedColor,
        title,
      }}
    />
  )
}
