import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

import { routes } from '@sketch/modules-common'

import {
  useForTablet,
  DocumentHeader,
  Markdown,
  Tooltip,
} from '@sketch/components'
import { useLayoutPortal } from '@sketch/utils'

import { CollectionHeaderActions } from './CollectionHeaderActions'

// Operations
import {
  useEditCollectionDescription,
  useRenameCollection,
} from '../../operations'

import { useToast } from '@sketch/toasts'

import { CollectionHeaderProps } from './types'
import {
  CollectionPreviewsFragment,
  ProjectInSidebarAndHeaderFragment,
} from '@sketch/gql-types'

import {
  BreadCrumb,
  Description,
  DescriptionPreview,
  DescriptionForm,
  DescriptionTextArea,
  DescriptionWrapper,
  PencilButton,
  Pencil,
} from './CollectionHeader.styles'
import { userIsEditorOrAdmin } from '../../../workspace/utils'

const StyledDocumentHeader = styled(DocumentHeader)`
  max-width: 100%;
`

interface EditableDescriptionProps {
  collection: CollectionPreviewsFragment
  description: string
}
const EditableDescription: React.FC<EditableDescriptionProps> = ({
  collection,
  description,
}) => {
  const [isEditing, setEditing] = useState(false)
  const [descriptionHeight, setDescriptionHeight] = useState<
    number | undefined
  >(undefined)
  const { showToast } = useToast()
  const editDescription = useEditCollectionDescription({
    collection,
    onCompleted: () => {
      showToast('Collection description updated successfully')
    },
  })
  const descriptionRef = useRef<HTMLSpanElement>(null)

  useEffect(() => {
    if (!descriptionRef?.current?.clientHeight) {
      return
    }

    setDescriptionHeight(descriptionRef.current.clientHeight ?? undefined)
  }, [descriptionRef])

  return (
    <DescriptionWrapper>
      {isEditing ? (
        <DescriptionInput
          inputHeight={descriptionHeight}
          collection={collection}
          initialValue={description}
          onCancel={() => setEditing(false)}
          onEdit={async newDescription => {
            if (newDescription !== description) {
              await editDescription({
                variables: {
                  identifier: collection.identifier,
                  description: newDescription === '' ? null : newDescription,
                },
              })
            }

            setEditing(false)
            setTimeout(() => {
              descriptionRef.current?.focus()
            })
          }}
        />
      ) : (
        <Description ref={descriptionRef} aria-label={description}>
          <Markdown>{description}</Markdown>
        </Description>
      )}
      <PencilButton onClick={() => setEditing(true)} disabled={isEditing}>
        <span className="sr-only">Edit Description</span>
        <Pencil />
      </PencilButton>
    </DescriptionWrapper>
  )
}

interface DescriptionInputProps {
  collection: CollectionPreviewsFragment
  initialValue: string
  inputHeight?: number
  onCancel: () => void
  onEdit: (newDescription: string) => void
}

const DescriptionInput: React.FC<DescriptionInputProps> = ({
  initialValue,
  inputHeight,
  onCancel,
  onEdit,
}) => {
  const [value, setValue] = useState(initialValue)
  const textAreaRef = useRef<HTMLTextAreaElement>(null)

  useEffect(() => {
    textAreaRef.current?.select()
  }, [])

  const createSubmit = (form: HTMLFormElement | null) => {
    // remove multiple new lines
    setValue(value.replaceAll(/(\r\n|\r|\n){2,}/g, '$1\n'))
    form?.dispatchEvent(new Event('submit', { cancelable: true }))
  }

  return (
    <DescriptionForm
      height={inputHeight}
      onSubmit={e => {
        e.preventDefault()
        onEdit(value)
      }}
    >
      <DescriptionPreview>{value}</DescriptionPreview>
      <DescriptionTextArea
        onChange={e => setValue(e.target.value)}
        onBlur={e => {
          createSubmit(e.currentTarget.form)
        }}
        onKeyDown={e => {
          // Make the "Enter" key + command / ctrl submit the change instead of a new line
          if (e.key === 'Enter' && (e.ctrlKey || e.metaKey)) {
            e.preventDefault()
            createSubmit(e.currentTarget.form)
          }

          if (e.key === 'Escape') {
            e.preventDefault()
            onCancel()
          }
        }}
        ref={textAreaRef}
        value={value}
        height={inputHeight}
      />
    </DescriptionForm>
  )
}

interface CollectionHeaderBreadcrumbsProps {
  workspaceId: string
  project: ProjectInSidebarAndHeaderFragment
}

const CollectionHeaderBreadcrumbs: React.FC<
  CollectionHeaderBreadcrumbsProps
> = ({ workspaceId, project }) => (
  <Tooltip
    content="Back to project"
    placement="top-start"
    spacing="8px"
    aria-label="Back to project"
    disableWhenTouchDevice
  >
    <Link
      to={routes.WORKSPACE_PROJECT.create({
        workspaceId,
        projectId: project.identifier,
      })}
    >
      <BreadCrumb>{project.name}</BreadCrumb>
    </Link>
  </Tooltip>
)

const CollectionHeader: React.FC<CollectionHeaderProps> = props => {
  const { project, collection, workspace } = props

  const isTabletAndBigger = useForTablet()

  const userCanManageCollection = userIsEditorOrAdmin(workspace)

  const HeaderPortal = useLayoutPortal('header-portal')
  const HeaderSlimPortal = useLayoutPortal('header-slim-portal')

  const { showToast } = useToast()
  const renameCollection = useRenameCollection({
    collection,
    projectIdentifier: project.identifier,
    onCompleted: () => {
      showToast('Collection was renamed successfully')
    },
  })

  const isMobile = !isTabletAndBigger
  const title = collection.name
  const description = collection?.description ?? undefined

  // Hide the Edit UI if the user doesn't have these permissions
  let handleEdit
  if (userCanManageCollection) {
    handleEdit = async (name: string) => {
      await renameCollection({
        variables: {
          identifier: collection.identifier,
          name,
        },
      })
    }
  }

  if (isMobile) {
    return (
      <StyledDocumentHeader
        title={title}
        description={
          description && (
            <EditableDescription
              collection={collection}
              description={description}
            />
          )
        }
        breadcrumb={
          <CollectionHeaderBreadcrumbs
            workspaceId={workspace.identifier}
            project={project}
          />
        }
        separateBreadcrumb
        titlePlaceholder="Collection name"
        actions={<CollectionHeaderActions {...props} />}
        onEdit={handleEdit}
      />
    )
  }

  return (
    <>
      <HeaderPortal>
        <StyledDocumentHeader
          title={title}
          description={
            description && (
              <EditableDescription
                collection={collection}
                description={description}
              />
            )
          }
          breadcrumb={
            <CollectionHeaderBreadcrumbs
              workspaceId={workspace.identifier}
              project={project}
            />
          }
          separateBreadcrumb
          titlePlaceholder="Collection name"
          actions={<CollectionHeaderActions {...props} />}
          onEdit={handleEdit}
        />
      </HeaderPortal>
      <HeaderSlimPortal>
        <StyledDocumentHeader
          title={title}
          titlePlaceholder="Collection name"
          actions={<CollectionHeaderActions {...props} headerSlim />}
          onEdit={handleEdit}
        />
      </HeaderSlimPortal>
    </>
  )
}

export default CollectionHeader
