import { useGetComponentsQuery, ComponentInfoFragment } from '@sketch/gql-types'

import type { GetComponent } from '../types'
// This is a valid use case to narrow down Component type
// eslint-disable-next-line no-restricted-imports
import { LayerStyle } from '@sketch/gql-types/expansive'

import { useGetIdentifiers } from '../../hooks/useGetIdentifiers'
import { useComponentsState } from '../../components/ComponentsStateContext'

const isLayerStyleComponent = (
  component?: ComponentInfoFragment
): component is LayerStyle => component?.__typename === 'LayerStyle'

export const useGetLayerStyles = ({ search, path }: GetComponent) => {
  const componentsState = useComponentsState()

  const { shareIdentifier, versionShortId, versionIdentifier } =
    useGetIdentifiers()

  const { loading, data, fetchMore, error } = useGetComponentsQuery({
    variables: {
      shareIdentifier,
      versionShortId,
      type: 'LAYER_STYLE',
      search,
      path,
    },
    skip: !!(search && path),
  })

  return {
    loading,
    fetchMore,
    shareIdentifier,
    versionIdentifier,
    documentVersion: data?.share?.version?.document?.documentVersion,
    compatibilityVersion:
      data?.share?.version?.document?.coeditCompatibilityVersion,
    canEditDescriptions:
      data?.share?.version?.document?.canEditDescriptions ?? false,
    renderStatusOfLatestVersion:
      data?.share?.latestVersionOfAnyState?.document?.renderStatus ?? null,
    entries:
      data?.share?.version?.document?.components.entries.filter(
        isLayerStyleComponent
      ) || [],
    hasComponentManifest: data?.share?.version?.hasComponentManifest,
    after: data?.share?.version?.document?.components.meta.after,
    componentsState,
    error,
  }
}
