import React, { useContext } from 'react'

import {
  Button,
  Link,
  Modal,
  useModalContext,
  ModalInjectedProps,
} from '@sketch/components'
import { ToastContext } from '@sketch/toasts'

import {
  GetUserSettingsDocument,
  GetUserSettingsQuery,
  GetUserSettingsQueryVariables,
  useRevokeSessionMutation,
} from '@sketch/gql-types'

// Modals
import ConfirmRevokeAllSessionsModal from '../ConfirmRevokeAllSessionsModal'

import {
  Title,
  Description,
} from './ConfirmRevokeIndividualSessionModal.styles'

import { countries } from '@sketch/constants'
import { dateFormat, getBrowserFromUserAgent } from '@sketch/utils'
import produce from 'immer'

// Auth
import {
  getAuthorizationFromSessionId,
  useSignOut,
} from '@sketch/modules-common'

interface ConfirmRevokeIndividualSessionModalProps extends ModalInjectedProps {
  id: string
  countryCode?: string
  deviceName?: string
  ipAddress?: string
  isCurrentOne?: boolean
  lastUpdateAt?: string
  userAgent?: string
  type?: string
}

const ConfirmRevokeIndividualSessionModal: React.FC<
  ConfirmRevokeIndividualSessionModalProps
> = ({
  hideModal,
  id,
  countryCode,
  deviceName,
  ipAddress,
  isCurrentOne,
  lastUpdateAt,
  userAgent,
  type,
}) => {
  const { showModal } = useModalContext()
  const auth = getAuthorizationFromSessionId(id)
  const signOut = useSignOut({
    revokeSessions: 'none',
    removeDataFromSessions:
      isCurrentOne && auth ? [auth.fragment.authToken] : 'none',
    reason: 'Revoking session in Sessions Management',
    redirectBackAfterLoginAgain: false,
  })
  const [revokeSession, { loading }] = useRevokeSessionMutation({
    variables: {
      id,
    },
    redirectErrors: false,
    onCompleted: result => {
      if (result.revokeSession?.successful) {
        showToast('Successfully signed out of device', 'positive')

        if (isCurrentOne) {
          signOut()
        }

        hideModal()
      }
    },
    update: (cache, { data, errors }) => {
      if (!data?.revokeSession.successful || (errors && errors.length > 0)) {
        return
      }

      const cachedQuery = cache.readQuery<
        GetUserSettingsQuery,
        GetUserSettingsQueryVariables
      >({
        query: GetUserSettingsDocument,
        variables: {},
      })

      if (!cachedQuery?.me) return

      const updatedMe = produce(cachedQuery.me, user => {
        if (!user.activeSessions || !user.activeSessions.entries) return

        user.activeSessions.entries = user.activeSessions.entries.filter(
          session => session?.id !== id
        )
      })

      cache.writeQuery<GetUserSettingsQuery, GetUserSettingsQueryVariables>({
        query: GetUserSettingsDocument,
        data: { __typename: 'RootQueryType', me: updatedMe },
      })
    },
    onError: 'show-toast',
  })
  const { showToast } = useContext(ToastContext)

  const sessionTypeLabel = {
    WEB: 'Sketch Web App',
    MAC_APP: 'Sketch App',
  }

  return (
    <Modal onCancel={hideModal}>
      <Modal.Body>
        <Title>Sign Out of Device?</Title>
        <Description>
          {type && (
            <>
              <strong>Type: </strong>
              {sessionTypeLabel[type as keyof typeof sessionTypeLabel]} (
              {type === 'WEB'
                ? getBrowserFromUserAgent(userAgent || '')
                : deviceName}
              )<br />
            </>
          )}
          {lastUpdateAt && (
            <>
              <strong>Time: </strong>
              {dateFormat(new Date(lastUpdateAt), 'en-GB', {
                day: 'numeric',
                month: 'long',
                year: 'numeric',
                hour: '2-digit',
                minute: '2-digit',
              })}
              <br />
            </>
          )}
          {countryCode && (
            <>
              <strong>Location: </strong>
              {countries[countryCode as keyof typeof countries]}
              <br />
            </>
          )}
          {ipAddress && (
            <>
              <strong>IP Address: </strong>
              {ipAddress}
              <br />
            </>
          )}
          <br />
          If you didn&apos;t sign in with this device, you should change your
          password and{' '}
          <Link
            onClick={e => {
              e.preventDefault()
              showModal(ConfirmRevokeAllSessionsModal)
            }}
            isUnderlined
            external
          >
            sign out of all devices
          </Link>
          .
        </Description>
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={hideModal}
          size="40"
          variant="secondary"
          disabled={loading}
        >
          Cancel
        </Button>
        <Button
          onClick={() => revokeSession()}
          size="40"
          variant="primary"
          loading={loading}
        >
          Sign Out
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default ConfirmRevokeIndividualSessionModal
