import { useCallback } from 'react'
import { RefetchQueriesFunction } from 'react-apollo'
import {
  ArtboardDetailInfoFragment,
  FrameDetailInfoFragment,
  SetNotificationSubscriptionMutationVariables,
  useSetNotificationSubscriptionMutation,
} from '@sketch/gql-types'

// This is a valid use case to narrow down Component type
// eslint-disable-next-line no-restricted-imports
import { NotificationSubscriptionState } from '@sketch/gql-types/expansive'

interface BaseCallbackArguments {
  state: NotificationSubscriptionState
}

interface ShareCallbackArguments extends BaseCallbackArguments {
  shareIdentifier: string
}

interface FrameGroupCallbackArguments extends BaseCallbackArguments {
  shareIdentifier: string
  frameGroup: ArtboardDetailInfoFragment | FrameDetailInfoFragment

  // Needed to make the mutation optimistic response
  documentVersionShortId: string
}

interface AnnotationCallbackArguments extends BaseCallbackArguments {
  annotationIdentifier: string
}

type CallbackArguments =
  | ShareCallbackArguments
  | FrameGroupCallbackArguments
  | AnnotationCallbackArguments

const normalizeVariables = (
  variables: CallbackArguments
): SetNotificationSubscriptionMutationVariables => {
  if ('frameGroup' in variables && variables.frameGroup) {
    const { frameGroup, ...otherVariables } = variables

    return {
      ...otherVariables,
      frameUUID: frameGroup.uuid,
    }
  }

  return variables
}

interface UseDocumentNotificationSubscriptionProps {
  onCompleted?: () => void
}

const useDocumentNotificationSubscription = (
  props?: UseDocumentNotificationSubscriptionProps
) => {
  const [changeNotification] = useSetNotificationSubscriptionMutation({
    onError: 'show-toast',
    onCompleted: props?.onCompleted,
  })
  return useCallback(
    (
      passedVariables: CallbackArguments,
      refetchQueries?: RefetchQueriesFunction
    ) => {
      const variables = normalizeVariables(passedVariables)

      changeNotification({
        variables,
        refetchQueries,
        optimisticResponse: optimisticVariables => {
          if (!('state' in optimisticVariables)) {
            throw new Error('Oops')
          }

          const { shareIdentifier, state, annotationIdentifier, frameUUID } =
            optimisticVariables

          return {
            __typename: 'RootMutationType',
            setNotificationSubscription: {
              __typename: 'SetNotificationSubscriptionResponse',

              share:
                shareIdentifier && !frameUUID
                  ? {
                      __typename: 'Share',
                      identifier: shareIdentifier!,
                      subscriptionStatus: state,
                    }
                  : null,

              artboard:
                shareIdentifier &&
                frameUUID &&
                'frameGroup' in passedVariables &&
                passedVariables.frameGroup.__typename === 'Artboard'
                  ? {
                      __typename: 'Artboard',
                      subscriptionStatus: state,
                      documentVersionShortId:
                        passedVariables.documentVersionShortId,
                      permanentArtboardShortId:
                        passedVariables.frameGroup.permanentArtboardShortId,
                    }
                  : null,

              frame:
                shareIdentifier &&
                frameUUID &&
                'frameGroup' in passedVariables &&
                passedVariables.frameGroup.__typename === 'Frame'
                  ? {
                      __typename: 'Frame',
                      subscriptionStatus: state,
                      documentVersionShortId:
                        passedVariables.documentVersionShortId,
                      identifier: passedVariables.frameGroup.identifier,
                    }
                  : null,

              annotation: annotationIdentifier
                ? {
                    __typename: 'Annotation',
                    identifier: annotationIdentifier,
                    subscriptionStatus: state,
                  }
                : null,
            },
          }
        },
      })
    },
    [changeNotification]
  )
}

export default useDocumentNotificationSubscription
