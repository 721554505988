export function takeWhile<T>(array: T[], predicate: (item: T) => boolean): T[] {
  const result: T[] = []
  for (const item of array) {
    if (!predicate(item)) {
      break // Stop as soon as condition is false
    }
    result.push(item)
  }
  return result
}
