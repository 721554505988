import { useAnalytics, useUserProfile } from '@sketch/modules-common'
import { ErrorHandler } from '@sketch/tracing'
import { useOnEvent, castError } from '@sketch/utils'
import { registrationManager } from '../client'
import { reportMetrics } from './reportMetrics'
import * as Sentry from '@sentry/browser'
import { getSentryExtras } from '../client/getSentryExtras'

/**
 * we really want that action would be performed only up to 1 time
 * per whole application load.
 * And we want to use something more reliable than useEffect
 */
let wasActionPerformed = false

const registerSw = async () => {
  if (!registrationManager) return
  const regManager = registrationManager

  try {
    await regManager.register()
  } catch (err) {
    const extras = await getSentryExtras()
    Sentry.withScope(scope => {
      scope.setExtras(extras)
      Sentry.captureException(err)
    })
  }

  /**
   * Helper methods which will be included to the bundle only on `dev` and `test` environments
   *
   * Also, here we don't keep references to the service worker, as actual reference to the service
   * worker can change over time. E.g. after unregistering and re-registering the service worker
   * in a different tab
   */
  if (
    process.env.REACT_APP_ENV === 'dev' ||
    process.env.REACT_APP_ENV === 'test'
  ) {
    try {
      const setClient = async () => {
        try {
          const client = await regManager.getClient()
          ;(window as any).__swClient = client
        } catch (e) {
          const err = castError(e)
          ErrorHandler.ignore(err)
        }
      }

      await setClient()

      const container = window.navigator.serviceWorker
      container.addEventListener('controllerchange', setClient)
    } catch (e) {
      const err = castError(e)
      ErrorHandler.ignore(err)
    }
  }
}

async function register() {
  if ('serviceWorker' in navigator) {
    if (document.readyState === 'complete') {
      await registerSw()
    } else {
      window.addEventListener('load', registerSw)
    }
  }
}

const useServiceWorker = () => {
  const analytics = useAnalytics()

  useOnEvent('signOut', async () => {
    const client = await registrationManager?.getClient()
    if (!client) return

    try {
      await client.hardRestart(true)
    } catch (err) {
      const extras = await getSentryExtras()
      Sentry.withScope(scope => {
        scope.setExtras(extras)
        Sentry.captureException(err)
      })
    }
  })

  const { data } = useUserProfile()

  if (
    // the action was already performed, we don't need to do anything more
    wasActionPerformed ||
    // data needed for the feature flags is still not loaded
    // skip this render and wait until the data will be loaded
    !data?.me
  ) {
    return
  }

  wasActionPerformed = true

  const perform = async () => {
    await register()
    await reportMetrics(analytics, true)
  }

  perform()
}

export const ServiceWorker = () => {
  useServiceWorker()
  return null
}
