import React from 'react'
import { useRouteMatch } from 'react-router-dom'

import { List, Icon, Link } from './ComponentsList.styles'

import { ReactComponent as SymbolIcon } from '@sketch/icons/symbol-16'
import { ReactComponent as PaintBrushIcon } from '@sketch/icons/paint-brush-16'
import { ReactComponent as TextIcon } from '@sketch/icons/text-format-16'
import { ReactComponent as ColorFanIcon } from '@sketch/icons/color-fan-16'

import { useAnalytics, isCwvSpecificRoute } from '@sketch/modules-common'
import { useSelectedGroupContext } from '../../context/SelectedGroupContext'
import { useGetIdentifiers } from '../../../hooks/useGetIdentifiers'

// Operations
import { useGetComponentsCount } from '../../operations'

export const ComponentsList = () => {
  const { shareIdentifier } = useGetIdentifiers()
  const { resetGroupSelect } = useSelectedGroupContext()
  const { path } = useRouteMatch()

  const { symbolsLabel, textStylesLabel, layerStylesLabel, colorVarsLabel } =
    useGetComponentsCount()

  const { trackEvent } = useAnalytics()

  return (
    <List>
      <li>
        <Link
          to={{
            routeKey: 'CWV_SYMBOLS',
            routeParams: {
              shareID: shareIdentifier,
            },
            state: {
              emptySearch: true,
            },
          }}
          selected={isCwvSpecificRoute(path, 'CWV_SYMBOLS')}
          onClick={() => {
            trackEvent('CWV - tab click', { type: 'symbols' })
            resetGroupSelect()
          }}
        >
          <Icon role="img" as={SymbolIcon} />
          {symbolsLabel}
        </Link>
      </li>
      <li>
        <Link
          to={{
            routeKey: 'CWV_TEXT_STYLES',
            routeParams: {
              shareID: shareIdentifier,
            },
            state: {
              emptySearch: true,
            },
          }}
          selected={isCwvSpecificRoute(path, 'CWV_TEXT_STYLES')}
          onClick={() => {
            trackEvent('CWV - tab click', { type: 'text styles' })
            resetGroupSelect()
          }}
        >
          <Icon role="img" as={TextIcon} />
          {textStylesLabel}
        </Link>
      </li>
      <li>
        <Link
          to={{
            routeKey: 'CWV_LAYER_STYLES',
            routeParams: {
              shareID: shareIdentifier,
            },
            state: {
              emptySearch: true,
            },
          }}
          selected={isCwvSpecificRoute(path, 'CWV_LAYER_STYLES')}
          onClick={() => {
            trackEvent('CWV - tab click', { type: 'layer styles' })
            resetGroupSelect()
          }}
        >
          <Icon role="img" as={PaintBrushIcon} />
          {layerStylesLabel}
        </Link>
      </li>
      <li>
        <Link
          to={{
            routeKey: 'CWV_COLOR_VARIABLES',
            routeParams: {
              shareID: shareIdentifier,
            },
            state: {
              emptySearch: true,
            },
          }}
          selected={isCwvSpecificRoute(path, 'CWV_COLOR_VARIABLES')}
          onClick={() => {
            trackEvent('CWV - tab click', { type: 'color variables' })
            resetGroupSelect()
          }}
        >
          <Icon role="img" as={ColorFanIcon} />
          {colorVarsLabel}
        </Link>
      </li>
    </List>
  )
}
