import React, { useState } from 'react'
import { Checkbox, Section } from '@sketch/components'
import { useToast } from '@sketch/toasts'
import { useUserUpdateMutation } from '@sketch/gql-types'

interface EmailNotificationsPanelProps {
  receiveEmailNotifications: boolean
}

export const EmailNotificationsPanel = ({
  receiveEmailNotifications,
}: EmailNotificationsPanelProps) => {
  const { showToast } = useToast()

  const [shouldReceiveEmailNotifications, setShouldReceiveEmailNotifications] =
    useState(receiveEmailNotifications)

  const [updateUser, { data, loading, error }] = useUserUpdateMutation({
    redirectErrors: false,
    onCompleted: data => {
      const { errors } = data.userUpdate || {}

      if (errors?.length! > 0) {
        const errorMessage = errors!.map(error => error?.message).join('; ')
        showToast(errorMessage, 'negative')
      }
    },
    onError: error => {
      // We revert the checked state
      setShouldReceiveEmailNotifications(!shouldReceiveEmailNotifications)
      showToast(error.message, 'negative')
    },
  })

  return (
    <Section title="Email Notifications">
      <Checkbox
        value={`${shouldReceiveEmailNotifications}`}
        name="receiveCommentsNotifications"
        checked={shouldReceiveEmailNotifications}
        loading={loading}
        success={!!data && !error}
        label="Comments"
        onChange={() => {
          setShouldReceiveEmailNotifications(!shouldReceiveEmailNotifications)
          updateUser({
            variables: {
              input: {
                receiveEmailNotifications: !shouldReceiveEmailNotifications,
              },
            },
          })
        }}
        help="Any comments made in documents that you follow"
      />
    </Section>
  )
}
