import styled, { css } from 'styled-components'
import { ButtonUnstyled, MenuLink } from '@sketch/components'
import { ReactComponent as ChevronRightIcon } from '@sketch/icons/chevron-right-16'

export const CollapsibleButton = styled(ButtonUnstyled).attrs({
  'data-testid': 'collapsible-trigger',
})`
  display: flex;
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
  margin-left: -4px;
  border-radius: ${({ theme }) => theme.radii.small};

  &:hover {
    background-color: ${({ theme }) => theme.colors.background.tertiary.C};
  }

  .active &:hover {
    background-color: ${({ theme }) => theme.colors.sketch.F};
  }

  & > svg {
    margin-right: 0;
  }
`

export const ChevronRight = styled(ChevronRightIcon)``

export const ChevronDown = styled(ChevronRightIcon)`
  transform: rotate(90deg);
`

export const NestedProjectsWrapper = styled.div<{ level: number }>(
  ({ level }) => css`
    ${MenuLink} {
      /* 12px padding per level + 12px existing padding */
      padding-left: calc(${level} * 12px + 12px);
    }
  `
)
