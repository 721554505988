import React, { useLayoutEffect } from 'react'
import isEqual from 'react-fast-compare'

import { AnnotationDotFragment } from '@sketch/gql-types'
import { ButtonUnstyled } from '@sketch/components'

import DraftAnnotationPopover from '../DraftAnnotationPopover'
import AnnotationDot from '../AnnotationDot'

import {
  useAnnotationPopover,
  useDotCoordinateStyle,
  useAnnotationOverlayContext,
} from '../../hooks'
import {
  ReferenceBounds,
  DraftAnnotation as DraftAnnotationType,
} from '../../types'

import { AnnotationDotWrapper } from './DraftAnnotation.styles'

interface DraftAnnotationProps {
  draftAnnotation: DraftAnnotationType
  referenceBounds: ReferenceBounds
  parentWrapper: React.RefObject<HTMLElement>
  onSubmit?: (annotation: AnnotationDotFragment) => void
}

const BUTTON_STOP_PROPAGATION = (event: React.MouseEvent) => {
  event.stopPropagation()
}

const DraftAnnotation = (props: DraftAnnotationProps) => {
  const { draftAnnotation, referenceBounds, parentWrapper, onSubmit } = props
  const { coordinates, context, prefixBounds } = draftAnnotation
  const annotationContext = useAnnotationOverlayContext()

  const style = useDotCoordinateStyle({
    coordinates,
    referenceBounds,
    prefixBounds,
  })

  const [content, { onClick, ref, ...buttonProps }, { update }] =
    useAnnotationPopover({
      parentWrapper,
      dropdown: DraftAnnotationPopover,
      dropdownProps: {
        coordinates,
        parameters: context,
        isViewingLatestVersion: Boolean(
          annotationContext?.isViewingLatestVersion
        ),
        onSubmit: ({ createAnnotation }) => {
          onSubmit?.(createAnnotation.annotation)
        },
      },
      onHide: () => {
        /**
         * When the user is on a mobile setting, and it's placing an annotation
         * the user will have a modal overlay the canvas (after the draft click)
         *
         * If the user clicks the modal backdrop (dismissing it) it will stay in a limbo, with a set annotation that can't be actioned.
         * This way we reset the draft annotation (that was already set) to allow the user
         * to pick a new position
         */
        annotationContext?.resetDraftAnnotation()
      },
    })

  /**
   * Update the position of the popover
   * when the coordinates are updated
   */
  useLayoutEffect(() => {
    update?.()
  }, [coordinates, update])

  return (
    <AnnotationDotWrapper ref={ref} style={style}>
      {/**
       * The button prop "onClick" is not using the popover one explicitly because
       * on desktop the draft annotation mode is always present after the user
       * set the first draft and until it submits it.
       *
       * This allows the user to move the draft around just by clicking on the canvas, but when it clicks on the annotation
       * dot it doesn't close the popover (default behaviour) it just ignores it
       *
       * https://github.com/sketch-hq/Cloud/issues/17868
       */}
      {content}
      <ButtonUnstyled {...buttonProps} onClick={BUTTON_STOP_PROPAGATION}>
        <AnnotationDot flavour="new" selected />
      </ButtonUnstyled>
    </AnnotationDotWrapper>
  )
}

export default React.memo(DraftAnnotation, isEqual)
