import React, { useState, useCallback } from 'react'

import { useToast } from '@sketch/toasts'
import {
  useForLargerThanMobile,
  Button,
  Modal,
  ModalInjectedProps,
} from '@sketch/components'

import { AuthAppsStep, QRCodeStep, CodesStep } from './components'

import { StyledOrderedBreadcrumbs } from './Setup2FAModal.styles'

// GQL
import {
  useSetupMfaMutation,
  useConfirmMfaSetupMutation,
  GetInitialUserDocument,
} from '@sketch/gql-types'

interface Setup2FAModalProps extends ModalInjectedProps {}

const BREADCRUMBS = [
  { content: 'Install App' },
  { content: 'Scan QR Code' },
  { content: 'Verify' },
]

const MOBILE_BREADCRUMBS = [
  { content: 'Install' },
  { content: 'Scan' },
  { content: 'Verify' },
]

const NEXT_BUTTON_COPY_PER_STEP = [
  'Scan QR Code',
  'Enter Verification Code',
  'Finish',
]

/**
 * Setup2FAModal
 *
 * This modal guides the user to install an authenticator application
 * and set up the Two-Factor Authentication.
 *
 * Used in Account Settings
 *
 */
export const Setup2FAModal: React.FC<Setup2FAModalProps> = ({ hideModal }) => {
  const { showToast } = useToast()
  const isForLargerThanMobile = useForLargerThanMobile()

  const [step, setStep] = useState(0)
  const [recoveryCodes, setRecoveryCodes] = useState<string[]>([])
  const [verficationError, setVerificationError] = useState<string>()

  // Returns a secret used to generate QR Code in the next step
  const [setupMfa, { data, loading: isLoadingSetupMfa }] = useSetupMfaMutation({
    onCompleted: () => {
      nextStep()
    },
    onError: ({ message }) => {
      showToast(message, 'negative')
    },
  })

  // Validates the Authenticator code the user inputs
  const [confirmMfaSetup, { loading: isLoadingConfirmMfa }] =
    useConfirmMfaSetupMutation({
      onCompleted: ({ confirmMfaSetup }) => {
        setRecoveryCodes(confirmMfaSetup.recoveryCodes.entries)

        nextStep()
      },
      onError: ({ message }) => {
        setVerificationError(message)
        showToast(message, 'negative')
      },
      refetchQueries: [{ query: GetInitialUserDocument }],
      awaitRefetchQueries: true,
    })

  const nextStep = () => {
    setStep(prev => {
      if (prev >= BREADCRUMBS.length - 1) {
        return prev
      }

      return prev + 1
    })
  }

  const handleNextStep = () => {
    // First Step
    // Run setupMfa and get the secret from the BE
    if (step === 0) {
      setupMfa()

      return
    }

    // Last Step
    // The modal should close in the last step if we have the recovery codes
    if (step === 2 && !!recoveryCodes.length) {
      hideModal()

      return
    }

    nextStep()
  }

  const handleVerificationCodeFilled = useCallback(
    (code: string) => {
      // Clear error
      setVerificationError(undefined)

      // Code from the Authenticator App
      confirmMfaSetup({ variables: { totp: code } })
    },
    [confirmMfaSetup]
  )

  const showNextButton = recoveryCodes.length || step !== 2
  const showCancelButton = !recoveryCodes.length
  const otpAuthUri = data?.setupMfa?.otpAuthUri

  return (
    <Modal onCancel={hideModal}>
      <Modal.Body>
        <StyledOrderedBreadcrumbs
          currentCrumb={step}
          crumbs={isForLargerThanMobile ? BREADCRUMBS : MOBILE_BREADCRUMBS}
          showAllInMobile
        />
        {step === 0 && <AuthAppsStep />}
        {step === 1 && <QRCodeStep otpAuthUri={otpAuthUri!} />}
        {step === 2 && (
          <CodesStep
            onFilled={handleVerificationCodeFilled}
            recoveryCodes={recoveryCodes}
            error={verficationError}
          />
        )}
      </Modal.Body>
      <Modal.Footer>
        {showCancelButton && (
          <Button
            onClick={hideModal}
            disabled={isLoadingSetupMfa}
            loading={isLoadingConfirmMfa}
            size="40"
          >
            Cancel
          </Button>
        )}
        {showNextButton && (
          <Button
            variant="primary"
            onClick={handleNextStep}
            loading={isLoadingSetupMfa || isLoadingConfirmMfa}
            size="40"
          >
            {NEXT_BUTTON_COPY_PER_STEP[step]}
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  )
}
