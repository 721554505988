import React, { FC, ReactNode } from 'react'
import { useRouteMatch } from 'react-router'
import { dataIdFromObject } from '@sketch/graphql-cache'
import {
  ArtboardDetailInfoFragment,
  FrameGroupDetailsFragment,
  GetArtboardsForDocumentQuery,
  GetArtboardsForPageQuery,
  GetFramesForDocumentQuery,
  GetFramesForPageQuery,
} from '@sketch/gql-types'
import { ApolloError, ObservableQuery, OperationVariables } from 'apollo-client'
import get from 'lodash.get'
import groupBy from 'lodash.groupby'
import { getFrameThumbnail } from '../../utils'
import { ResponsiveValues } from '@sketch/global-styles'
import {
  LoadingPlaceholder,
  Box,
  Tooltip,
  ErrorMessage,
  AspectRatioGrid,
  AspectRatioGridResponseProp,
  handleFetchMore,
  InfiniteList,
  useForDesktop,
} from '@sketch/components'
import Artboard from '../Artboard'
import PrototypeCard from '../PrototypeCard'
import { useVersioning } from '../../../versioning'
import { useContainerRefScroll } from '@sketch/utils'
import { useSketchWebSupport } from '../../PageCanvasView/hooks'
import { ReactComponent as CanvasTooltip } from '@sketch/icons/canvas-tooltip'
import { ReactComponent as DocumentIcon } from '@sketch/icons/document-64'
import { ReactComponent as MagGlassIcon } from '@sketch/icons/mag-glass-64'

import {
  Container,
  FullScreenContainer,
  StyledArrowRightCircleIcon,
  StyledCanvasViewIcon,
  StyledPageCanvasLink,
  StyledPageLink,
  StyledPageLinkLabel,
  StyledPageTitleContainer,
  StyledPill,
  StyledVersionLink,
  Title,
} from './FrameGroupsList.styles'
import { useAnalytics, isDocumentRoute, useFlag } from '@sketch/modules-common'
import { VersionLinkProps } from '../../../versioning/components/VersionLink/VersionLink'

type File = NonNullable<
  NonNullable<
    ArtboardDetailInfoFragment['files'] | FrameGroupDetailsFragment['files']
  >[0]
>

type ThumbnailType = NonNullable<NonNullable<File['thumbnails']>[0]>['type']

const VERTICAL_ROW_SPACE_GRID = [28, 28, 32] as [number, number, number]

const defaultColumns: AspectRatioGridResponseProp = [2, 2, 3, 4, 5, 6, 7]

const PageCanvasLinkTooltip: FC = ({ children }) => {
  const isFramesWebOn = useFlag('frames-web')

  return (
    <Tooltip
      content={
        <>
          <Box maxWidth={225}>
            View all {isFramesWebOn ? 'Frames' : 'Artboards'} as they appear in
            the original document, including elements outside{' '}
            {isFramesWebOn ? 'Frames' : 'Artboards'}.
          </Box>
          <Box marginTop={3}>
            <CanvasTooltip width={225} />
          </Box>
        </>
      }
      placement="bottom"
    >
      {children}
    </Tooltip>
  )
}

type FrameGroupsProps = {
  title: string
  shareID: string
  columns?: AspectRatioGridResponseProp
  pageUUID: string
  children?: ReactNode
  totalCount?: number
}

export const FrameGroups = ({
  title,
  columns = defaultColumns,
  totalCount,
  children,
  shareID,
  pageUUID,
}: FrameGroupsProps) => {
  const { trackEvent } = useAnalytics()
  const { getPathname } = useVersioning()
  const { path } = useRouteMatch()
  const isDocumentView = isDocumentRoute(path)
  const canvasSupport = useSketchWebSupport()

  const pagePath = getPathname({
    routeKey: 'SHARE_PAGE_VIEW',
    routeParams: { shareID, pageUUID },
  })

  const canvasPath = getPathname({
    routeKey: 'SHARE_PAGE_CANVAS_VIEW',
    routeParams: { shareID, pageUUID },
  })

  const showCanvasLink = canvasSupport.supported

  const onPageLinkClick = () => {
    trackEvent('DOCUMENT - open page', {
      dropdown: false,
    })
  }

  const onCanvasLinkClick = () => {
    trackEvent('DOCUMENT - open canvas', {
      isPageView: !isDocumentView,
    })
  }

  return (
    <Container>
      <StyledPageTitleContainer>
        <Title>
          <StyledPageLink
            to={pagePath}
            external={false}
            onClick={onPageLinkClick}
            // Avoids adding entries to the browser history if user clicks
            // on the link while already being on the page view.
            replace={!isDocumentView}
          >
            <StyledPageLinkLabel>{title}</StyledPageLinkLabel>
            {totalCount && (
              <StyledPill variant="secondary">{totalCount}</StyledPill>
            )}
            {isDocumentView && <StyledArrowRightCircleIcon />}
          </StyledPageLink>
        </Title>
        {showCanvasLink && (
          <PageCanvasLinkTooltip>
            <StyledPageCanvasLink onClick={onCanvasLinkClick} to={canvasPath}>
              <StyledCanvasViewIcon />
              Canvas View
            </StyledPageCanvasLink>
          </PageCanvasLinkTooltip>
        )}
      </StyledPageTitleContainer>
      <AspectRatioGrid
        verticalRowSpace={VERTICAL_ROW_SPACE_GRID}
        columns={columns}
        gutterSize={32}
      >
        {children}
      </AspectRatioGrid>
    </Container>
  )
}

interface FrameGroupsListProps {
  fetchMore: ObservableQuery<any, OperationVariables>['fetchMore']
  afterPath: string[]
  entriesPath: string[]
  data?:
    | GetArtboardsForDocumentQuery
    | GetArtboardsForPageQuery
    | GetFramesForDocumentQuery
    | GetFramesForPageQuery
  loading: boolean
  error?: ApolloError
  filterValue: string
  columns: ResponsiveValues<number>
  pageUUID?: string
  /**
   * Should FrameGroups with `belongToPrototype` `true` be rendered as Prototype
   * cards with a play button, or normal FrameGroup cards.
   */
  enablePrototypeCards?: boolean
}

/**
 * Handles an infinite list of FrameGroups fetched by either the
 * GetFramesForDocumentQuery or the GetFramesForPageQuery.
 */
export const FrameGroupsList = ({
  fetchMore,
  afterPath,
  entriesPath,
  data,
  loading,
  error,
  filterValue,
  columns,
  pageUUID,
  enablePrototypeCards,
}: FrameGroupsListProps) => {
  const isFramesWebOn = useFlag('frames-web')
  const isDesktop = useForDesktop()
  const { saveScrollTop } = useContainerRefScroll()
  const { setArtboardThumbnail } = useVersioning()
  const { path } = useRouteMatch()
  const isDocumentView = isDocumentRoute(path)
  const { trackEvent } = useAnalytics()

  if (loading) {
    return (
      <FullScreenContainer>
        <LoadingPlaceholder size="64px" />
      </FullScreenContainer>
    )
  }

  if (error || !data?.share) return <ErrorMessage.Generic />

  const { commentsEnabled = true, identifier: shareID } = data.share

  const entries = get(data, entriesPath, []) as FrameGroupDetailsFragment[]

  if (entries.length === 0) {
    if (filterValue) {
      return (
        <ErrorMessage
          title={`We couldn’t find any ${
            isFramesWebOn ? 'Frames' : 'Artboards'
          } with that name.`}
          icon={<MagGlassIcon />}
          iconSize="medium"
        >
          {isDesktop && <>Press the Escape key to return to your document.</>}
        </ErrorMessage>
      )
    }

    return (
      <ErrorMessage
        title="No pages in document"
        icon={<DocumentIcon />}
        iconSize="medium"
      >
        No pages have been added here yet.
      </ErrorMessage>
    )
  }

  const framesByPage = groupBy(entries, e => e.page?.identifier)
  const pages = Object.values(framesByPage)

  const handleFrameClick = (frame: FrameGroupDetailsFragment) => {
    saveScrollTop()
    setArtboardThumbnail(getFrameThumbnail(frame))

    trackEvent('DOCUMENT - open artboard', {
      isPageView: !isDocumentView,
    })
  }

  const getVersionLink = (
    frame: FrameGroupDetailsFragment
  ): VersionLinkProps<'ARTBOARD_DETAIL' | 'FRAME'>['to'] => {
    const state = { fromOverview: true, pageUUID }

    if (isFramesWebOn) {
      return {
        routeKey: 'FRAME',
        routeParams: {
          shareID,
          frameUUID: frame.uuid,
        },
        state,
      }
    } else {
      const artboard = frame as ArtboardDetailInfoFragment

      return {
        routeKey: 'ARTBOARD_DETAIL',
        routeParams: {
          shareID,
          permanentArtboardShortId: artboard.permanentArtboardShortId,
        },
        state,
      }
    }
  }

  return (
    <InfiniteList
      wrappingComponent={React.Fragment}
      renderLoading={() => <LoadingPlaceholder size="64px" />}
      onLoadMore={handleFetchMore(fetchMore, entriesPath, {
        dataIdFromObject,
        after: get(data, afterPath),
      })}
    >
      {pages.map(frames => (
        <FrameGroups
          key={frames[0]?.page?.uuid!}
          columns={columns}
          title={frames[0]?.page?.name!}
          shareID={shareID}
          pageUUID={frames[0]?.page?.uuid!}
          totalCount={frames.length}
        >
          {frames.map(frame =>
            enablePrototypeCards && frame.isFlowHome ? (
              <PrototypeCard
                artboardUUID={frame.uuid}
                key={frame.identifier}
                title={frame.name}
                src={getFrameThumbnail(frame)}
              />
            ) : (
              <StyledVersionLink
                data-testid="artboard-link"
                onClick={() => handleFrameClick(frame)}
                key={frame.identifier}
                to={getVersionLink(frame)}
              >
                <Artboard
                  title={frame.name}
                  search={filterValue}
                  imageSrc={getFrameThumbnail(frame)}
                  srcSet={
                    createThumbnailSrcSetForFiles(
                      frame?.files as File[],
                      'L'
                    ) ?? undefined
                  }
                  commentCount={commentsEnabled ? frame.annotationCount : 0}
                  hasNewComments={frame.unreadCount > 0}
                />
              </StyledVersionLink>
            )
          )}
        </FrameGroups>
      ))}
    </InfiniteList>
  )
}

export const createThumbnailSrcSetForFiles = (
  files: File[],
  type: ThumbnailType = 'L'
): string | null => {
  if (!files) return null

  return files
    .sort((a: File, b: File): number => {
      if (a.scale && b.scale) return a.scale - b.scale
      return 0
    })
    .map((a: File) => {
      const thumbnails = a.thumbnails ?? []
      const largeThumbnail = thumbnails.find(t => t?.type === type) // try to find the correct size
      const thumbnail = largeThumbnail ?? thumbnails[0] // fall back to first available thumbnail

      return thumbnail?.url ?? null
    })
    .filter(url => url !== null)
    .map((url, i) => `${url} ${i + 1}x`)
    .join(', ')
}
