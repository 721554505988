import * as React from "react";
import { forwardRef } from "react";
const ReactComponent = ({ title, titleId, ...props }, ref) => React.createElement("svg", { "data-testid": "flex-fill-h-17", xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 17 17", ref: ref, "aria-labelledby": titleId, ...props },
    title ? React.createElement("title", { id: titleId }, title) : null,
    React.createElement("g", { fill: "none" },
        React.createElement("path", { d: "M0 0h17v17H0z" }),
        React.createElement("path", { fill: "currentColor", fillRule: "evenodd", stroke: "currentColor", strokeLinecap: "round", strokeLinejoin: "round", d: "m12.5 10.25 2-1.75-2-1.75zm-8 0-2-1.75 2-1.75z" }),
        React.createElement("path", { stroke: "currentColor", strokeLinecap: "round", d: "M12.5 8.5h-8" })));
const ForwardRef = forwardRef(ReactComponent);
export { ForwardRef as ReactComponent };
