import React from 'react'

import { Modal, ModalInjectedProps, Button, Truncate } from '@sketch/components'
import MemberBadge from 'cloud-frontend/src/modules/workspace/components/MemberBadge/MemberBadge'
import { TableRow } from '@sketch/components/src/Table/Table.styles'
import WorkspaceLogo from '@sketch/components/src/WorkspaceLogo/WorkspaceLogo'
import { Table, TableCell } from './LinkedSSOWorkspacesModal.styles'
import { useUserProfile } from '@sketch/modules-common'
import { UserWorkspaceMembershipFragment } from '@sketch/gql-types'

interface LinkedSSOWorkspacesModalProps extends ModalInjectedProps {
  ssoWorkspaces: UserWorkspaceMembershipFragment[]
}

export const LinkedSSOWorkspacesModal: React.FC<
  LinkedSSOWorkspacesModalProps
> = ({ hideModal, ssoWorkspaces }) => {
  const { data: userData } = useUserProfile()
  const tableHeaders = [{ label: 'Workspace' }]

  return (
    <Modal
      onCancel={hideModal}
      title={`Linked to ${ssoWorkspaces.length} SSO Workspaces`}
    >
      <Modal.Body>
        <p>Your account {userData?.me.email} has access to:</p>

        <Table
          header={tableHeaders}
          hideHeader
          items={ssoWorkspaces}
          renderItem={membership => {
            return (
              <TableRow data-testid="sso-workspaces-table-row">
                <TableCell>
                  <WorkspaceLogo
                    size="32px"
                    src={membership.workspace.avatar?.small}
                    workspaceName={membership.workspace.name}
                  />
                </TableCell>
                <TableCell>SSO</TableCell>
                <TableCell>
                  <Truncate width={120}>{membership.workspace.name}</Truncate>
                </TableCell>
                <TableCell>
                  {membership.isEditor ? <>As an Editor</> : null}
                  {membership.workspace.userIsOwner ? (
                    <MemberBadge badge="owner">Owner</MemberBadge>
                  ) : null}
                </TableCell>
              </TableRow>
            )
          }}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={hideModal}>Close</Button>
      </Modal.Footer>
    </Modal>
  )
}
