import React, { useState } from 'react'

import { useToast } from '@sketch/toasts'

import { Modal, ModalInjectedProps } from '@sketch/components'

import { FirstStep, SecondStep, ThirdStep } from './components'

import { useGenerateMfaRecoveryCodesMutation } from '@sketch/gql-types'

interface RegenerateRecoveryCodesModalProps extends ModalInjectedProps {}

const TOTAL_STEPS = 3

/**
 *
 * This modal allows the user to regenerate recovery codes
 * for the 2FA
 *
 * Used in Account Settings
 *
 */
export const RegenerateRecoveryCodesModal: React.FC<
  RegenerateRecoveryCodesModalProps
> = ({ hideModal }) => {
  const [step, setStep] = useState(1)
  const { showToast } = useToast()

  const [generateMfaRecoveryCodes, { loading, data }] =
    useGenerateMfaRecoveryCodesMutation({
      onCompleted: () => {
        nextStep()
      },
      onError: ({ message }) => {
        showToast(message, 'negative')
      },
    })

  // Move to the next step
  const nextStep = () => {
    setStep(prev => {
      if (prev >= TOTAL_STEPS) {
        return prev
      }

      return prev + 1
    })
  }

  const handleNextStep = (goToStep?: number) => {
    // go to a particular step
    if (goToStep) {
      setStep(goToStep)

      return
    }

    // The modal should close in the last step
    if (step === 3) {
      hideModal()

      return
    }

    nextStep()
  }

  // Handles the password confirmation form submition
  const handleSubmit = ({ password }: { password: string }) => {
    generateMfaRecoveryCodes({
      variables: { password },
    })
  }

  const recoveryCodes = data?.generateMfaRecoveryCodes.recoveryCodes.entries

  return (
    <Modal onCancel={hideModal}>
      {step === 1 && <FirstStep onNextStep={handleNextStep} />}
      {step === 2 && <SecondStep onSubmit={handleSubmit} isLoading={loading} />}
      {step === 3 && (
        <ThirdStep onNextStep={handleNextStep} recoveryCodes={recoveryCodes!} />
      )}
    </Modal>
  )
}
