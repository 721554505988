import {
  BrowserOptions,
  Integrations as BrowserIntegrations,
} from '@sentry/browser'
import * as Integrations from '@sentry/integrations'

interface SentryConfiguration {
  environment: string
  release: string
  maxEvents: number
}

export const createSentryConfiguration = ({
  environment,
  release,
  maxEvents = 200,
}: SentryConfiguration) => {
  const allowUrls = [
    'sketch.com/static/js',
    '*.sketch.com/static/js',
    'sketch.cloud/static/js',
    '*.sketch.cloud/static/js',
    'sketch-cloud.netlify.com',
    'cloud.sketch.com',
    'localhost',
  ]
  const SESSION_MAX_EVENTS = maxEvents
  let eventsSent = 0

  const beforeSend: BrowserOptions['beforeSend'] = event => {
    // We want to avoid sending errors to Sentry if Cypress is the author of them
    // https://docs.cypress.io/faq/questions/using-cypress-faq#Is-there-any-way-to-detect-if-my-app-is-running-under-Cypress
    if (window.Cypress) {
      return null
    }

    if (eventsSent >= SESSION_MAX_EVENTS) {
      return null // Ignore the event
    }

    if (eventsSent === SESSION_MAX_EVENTS - 1) {
      // Customize the event to signal that the limit has been reached
      const LIMIT_REACHED = 'ERROR_CLIENT_REACHED_LIMIT'
      event.level = 'fatal'
      event.message = LIMIT_REACHED
    }

    eventsSent += 1
    return event
  }

  const beforeBreadcrumb: BrowserOptions['beforeBreadcrumb'] = (
    breadcrumb,
    hint
  ) => {
    if (breadcrumb.category === 'fetch') {
      if (!hint || !hint.input || !hint.input[1] || !hint.input[1].body) {
        return breadcrumb
      }

      /**
       * Add GraphQL-specific information to the breadcrumb like the operation
       * name and the variables we send. There is no need to filter out
       * sensitive information like passwords as it is already done in Sentry's
       * side.
       */
      const rawRequestBody = hint.input[1].body
      const operationNameMatch = rawRequestBody.match(/"operationName":"(.*?)"/)
      const variablesMatch = rawRequestBody.match(/"variables":({.*?})/)

      const operation = operationNameMatch ? operationNameMatch[1] : null
      const variables = variablesMatch ? variablesMatch[1] : null
      const error = hint.data instanceof Error ? hint.data : null

      return {
        ...breadcrumb,
        data: { ...breadcrumb.data, operation, variables, error },
      }
    }

    return breadcrumb
  }

  const serializationDepth = 6

  const config: BrowserOptions = {
    beforeSend,
    dsn: 'https://637095bbf5d341fcb66f091ecf4b2ad1@o258732.ingest.sentry.io/232441',
    environment,
    integrations: [
      new Integrations.ExtraErrorData({ depth: serializationDepth }),
      new Integrations.HttpClient({
        failedRequestTargets: allowUrls,
      }),
      new BrowserIntegrations.Breadcrumbs({
        console: false,
      }),
    ],
    beforeBreadcrumb,
    release,
    // ExtraErrorData.depth requires changing normalizeDepth to "ExraErrorData.depth + 1"
    // See: https://github.com/getsentry/sentry-javascript/issues/2539#issuecomment-616638746
    normalizeDepth: serializationDepth + 1,
    ignoreErrors: [
      'ResizeObserver loop limit exceeded',

      // See: https://github.com/sketch-hq/Cloud/issues/1714
      `evaluating 'window.__pad.performLoop'`,

      // This happens because we don't handle Query/Mutation errors
      // See: https://github.com/sketch-hq/Cloud/issues/998#issuecomment-514199836
      // We should be able to remove this after
      // https://github.com/sketch-hq/Cloud/issues/282 is closed.
      `Network error: Failed to fetch`,

      // These errors occur due to SafeLink crawler as described at
      // https://forum.sentry.io/t/unhandledrejection-non-error-promise-rejection-captured-with-value/14062
      'Non-Error promise rejection captured with value: Object Not Found Matching',
    ],
    allowUrls,
    tracesSampleRate: 0.2,
  }

  return config
}
