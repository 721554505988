import React from 'react'
import { Redirect, useLocation, useParams } from 'react-router'
import { useGetArtboardByPermanentArtboardShortIdQuery } from '@sketch/gql-types'
import { LocationDescriptorObject } from 'history'
import {
  DynamicLoadingPage,
  RouteParams,
  NotFoundView,
} from '@sketch/modules-common'
import { useVersioning } from '../../versioning'

/**
 * Redirects from the PROTOTYPE_LEGACY_ROUTE_1 route to the prototype player.
 */
export function PrototypeLegacyRoute1Redirect() {
  const { search } = useLocation()
  const { permanentArtboardShortId } =
    useParams<RouteParams<'PROTOTYPE_LEGACY_ROUTE_1'>>()
  const { getPathname, versionShortId, share } = useVersioning()

  const { data, loading } = useGetArtboardByPermanentArtboardShortIdQuery({
    variables: {
      documentVersionShortId: versionShortId,
      permanentArtboardShortId,
      shareIdentifier: share.identifier,
    },
  })

  const uuid = data?.artboard?.uuid

  if (loading) {
    return <DynamicLoadingPage />
  }

  if (!uuid) {
    return <NotFoundView />
  }

  const to: LocationDescriptorObject = {
    pathname: getPathname({
      routeKey: 'PROTOTYPE_PLAYER',
      routeParams: {
        shareID: share.identifier,
        prototypeArtboardUUID: uuid,
        currentArtboardUUID: uuid,
      },
    }),
    search,
  }

  return <Redirect to={to} push={false} />
}
