import React from 'react'

import { NavbarExtended } from '../NavbarExtended'
import { SplitLayout } from '../SplitLayout'

import {
  SidebarWrapper,
  LoadingSidebarWorkspace,
} from './LoadingWorkspaceSidebarLayout.styles'
import { LoadingState } from '../LoadingState'

interface LoadingWorkspaceSidebarLayoutProps {
  sidebar?: React.ReactNode
  header?: React.ReactNode
}

export const LoadingWorkspaceSidebarLayout: React.FC<
  LoadingWorkspaceSidebarLayoutProps
> = ({ sidebar, header }) => (
  <SplitLayout
    header={header || <NavbarExtended />}
    sidebar={
      <SidebarWrapper>{sidebar || <LoadingSidebarWorkspace />}</SidebarWrapper>
    }
    footer={null}
  >
    <LoadingState />
  </SplitLayout>
)
