import React, { useContext, useState } from 'react'
import { useHistory } from 'react-router-dom'

import {
  Caption,
  Tabs,
  TabPanel,
  TabList,
  Tab,
  Form,
  DecoratedInput,
  ModalContext,
} from '@sketch/components'
import Panel from '../Panel'

import { ReactComponent as CheckIcon } from '@sketch/icons/check-12'

import { ChooseSsoWorkspaceShortNameModal } from '../../modals/ChooseSsoWorkspaceShortNameModal'
import SetUpIdentityProvider from '../SetUpIdentityProvider'
import SetUpSketch from '../SetUpSketch'

import {
  ApprovedLabel,
  FormFieldRow,
  StyledButton,
  ApprovedWrapper,
  TabsWrapper,
  ShortnameWrapper,
} from './WorkspaceSettingsSsoSetupPanel.styles'

// Type
import { WorkspaceSsoConfigFragment } from '@sketch/gql-types'

interface WorkspaceSettingsSsoSetupPanelProps {
  customerId: string
  workspaceShortName: string
  isSsoActive: boolean
  config: WorkspaceSsoConfigFragment
}

const ApprovedSuffix = () => (
  <ApprovedWrapper>
    <CheckIcon width="16px" height="16px" />
    <ApprovedLabel>Available</ApprovedLabel>
  </ApprovedWrapper>
)

export const WorkspaceSettingsSsoSetupPanel: React.FC<
  WorkspaceSettingsSsoSetupPanelProps
> = ({ customerId, workspaceShortName, isSsoActive, config }) => {
  const { location } = useHistory()
  const { showModal } = useContext(ModalContext)

  const [selectedTabIndex, setSelectedTabIndex] = useState(
    location.hash.slice(1) === 'setupSketch' ? 1 : 0
  )

  return (
    <Panel>
      <Panel.Header>Set Up Single Sign-On (SSO)</Panel.Header>
      <Panel.Body>
        <Caption m={0}>
          Begin by choosing the SSO shortname that people in your Workspace will
          use to sign in.
        </Caption>
        <ShortnameWrapper>
          <Form.Field name="workspaceShortName" label="SSO Shortname">
            <FormFieldRow>
              <DecoratedInput
                value={workspaceShortName}
                label="SSO Shortname"
                suffixComponent={<ApprovedSuffix />}
              />
              <StyledButton
                ml={3}
                type="button"
                size="40"
                onClick={() =>
                  showModal(ChooseSsoWorkspaceShortNameModal, {
                    customerId,
                    initialValue: workspaceShortName,
                  })
                }
              >
                Edit&hellip;
              </StyledButton>
            </FormFieldRow>
          </Form.Field>
        </ShortnameWrapper>
        <TabsWrapper>
          <Tabs
            onTabSelected={setSelectedTabIndex}
            selectedTabIndex={selectedTabIndex}
          >
            <TabList>
              <Tab urlFragment="setupIdp">Set Up Identity Provider</Tab>
              <Tab urlFragment="setupSketch">Set Up Sketch</Tab>
            </TabList>
            <TabPanel>
              <SetUpIdentityProvider config={config} />
            </TabPanel>
            <TabPanel>
              <SetUpSketch
                customerId={customerId}
                config={config}
                isSsoActive={isSsoActive}
              />
            </TabPanel>
          </Tabs>
        </TabsWrapper>
      </Panel.Body>
    </Panel>
  )
}
