import { useMemo } from 'react'

import { useGetInitialUserQuery } from '@sketch/gql-types'
import { defaultFlags } from '@sketch/constants'
import { useDevToolsSetting } from '../settings'
import { FeatureFlagKeys, FeatureFlagsObject } from '../types'

const validFlagKeys = Object.keys(defaultFlags)

interface UseDevToolsFlags {
  backendFlags: FeatureFlagsObject
  localFlagOverrides: Partial<FeatureFlagsObject>
  setLocalFlagOverride: (flag: FeatureFlagKeys, value: boolean) => void
  clearLocalFlagOverride: (flag: FeatureFlagKeys) => void
  isOverridden: boolean
}

const formatArrayIntoObject = (flags: string[]) =>
  flags.reduce((acc, value) => ({ ...acc, [value]: true }), {})

const useBackendFlags = () => {
  const { data } = useGetInitialUserQuery({
    fetchPolicy: 'cache-only',
  })

  const flagsKey = data?.me?.featureFlags?.join(',')
  // flagsData is an array and it is used as a dependency
  // to other React Hooks. However, it is possible that the
  // to this array will change between rerenders, which cause
  // React Hooks to rerun and particularly `useEffect` causes
  // infinite loop. Here we are making that reference to flagsData
  // would change only when underlying data is changed
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const flagsData = useMemo(() => data?.me?.featureFlags, [flagsKey])

  const backendFlags = useMemo((): FeatureFlagsObject => {
    const featureFlags = flagsData?.map(f => f as string) ?? []

    return {
      ...defaultFlags,
      ...formatArrayIntoObject(featureFlags),
    }
  }, [flagsData])

  return backendFlags
}

const getOverrides = (
  baseFlags: Partial<FeatureFlagsObject>,
  backendFlags: FeatureFlagsObject
) => {
  const overrides = Object.fromEntries(
    (Object.entries(baseFlags) as [FeatureFlagKeys, boolean][]).filter(
      ([flagKey, value]) => {
        if (!validFlagKeys.includes(flagKey)) return false

        const backendValue = backendFlags[flagKey] ?? false
        return backendValue !== value
      }
    )
  ) as Partial<FeatureFlagsObject>

  return overrides
}

export const useDevToolsFlags = (): UseDevToolsFlags => {
  const [storedFlags, setStoredFlags] = useDevToolsSetting(
    'featureFlags.overrides'
  )
  const [isDevToolsTurnedOn] = useDevToolsSetting('general.isTurnedOn')

  const backendFlags = useBackendFlags()

  if (!isDevToolsTurnedOn) {
    return {
      backendFlags,
      clearLocalFlagOverride: () => {},
      isOverridden: false,
      localFlagOverrides: {},
      setLocalFlagOverride: () => {},
    }
  }

  const localFlagOverrides = getOverrides(storedFlags, backendFlags)

  const setLocalFlagOverride = (flag: FeatureFlagKeys, value: boolean) => {
    const newOverrides = getOverrides(
      { ...storedFlags, [flag]: value },
      backendFlags
    )
    setStoredFlags(newOverrides)
  }

  const clearLocalFlagOverride = (flag: FeatureFlagKeys) => {
    const newOverrides = Object.fromEntries(
      (
        Object.entries(localFlagOverrides) as [FeatureFlagKeys, boolean][]
      ).filter(([flagKey]) => flagKey !== flag)
    ) as Partial<FeatureFlagsObject>

    setStoredFlags(newOverrides)
  }

  const isOverridden = Object.keys(localFlagOverrides).length > 0

  return {
    localFlagOverrides: localFlagOverrides as any,
    setLocalFlagOverride,
    isOverridden,
    backendFlags,
    clearLocalFlagOverride,
  }
}
