import React, { useCallback, useEffect, useMemo, useReducer } from 'react'
import { HiddenSegments, Segment } from './TruncateSegments.styles'
import { TruncateSegmentsProps } from './TruncateSegments'

const rootId = Symbol('root')
const measurementsId = Symbol('measurements')

type Measurement = { ref: HTMLElement; width: number }
type ElementId = string | typeof rootId | typeof measurementsId
type Measurements = Map<ElementId, Measurement>

export const useMeasurements = (props: TruncateSegmentsProps) => {
  const elements = useMemo<Measurements>(() => new Map(), [])
  const [, forceRender] = useReducer(s => s + 1, 0)

  const measure = useCallback(
    (key: ElementId, ref: HTMLElement | null) => {
      if (!ref) return

      const currentWidth = elements.get(key)?.width
      const newWidth = ref?.offsetWidth || 0
      if (currentWidth !== newWidth) {
        elements.set(key, { ref, width: newWidth })
        forceRender()
      }
    },
    [elements]
  )

  const { segments } = props

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const segmentIds = useMemo(() => Array.from(segments.keys()), [segments.size])

  const isMeasured =
    segmentIds.every(id => (elements.get(id)?.width || 0) > 0) &&
    (elements.get(rootId)?.width || 0) > 0

  const rootEl = elements.get(rootId)?.ref
  const measurementsEl = elements.get(measurementsId)?.ref
  useEffect(() => {
    if (!rootEl || !measurementsEl) return

    const observer = new ResizeObserver(() => {
      measure(rootId, rootEl)
      measure(measurementsId, measurementsEl)
      segmentIds.forEach(id => measure(id, elements.get(id)?.ref || null))
    })

    observer.observe(rootEl) // in case we would resize the container (e.g. by resizing the browser tab)
    observer.observe(measurementsEl) // in case we would resize the content of the segments
    return () => observer.disconnect()
  }, [rootEl, measurementsEl, measure, segmentIds, elements])

  // Duplicated entries displayed in a list,
  // they are hidden from the UI and act as an accurate
  // measurements reference.
  const measurementsContainer = (
    <HiddenSegments ref={r => measure(measurementsId, r)}>
      {Array.from(segments.values()).map(s => (
        <Segment ref={r => measure(s.id, r)} key={s.id}>
          {s.node}
        </Segment>
      ))}
    </HiddenSegments>
  )

  const onRootRef = (ref: HTMLElement | null) => measure(rootId, ref)

  const measuredSegments = Array.from(segments.values()).map(s => ({
    ...s,
    width: elements.get(s.id)?.width || 0,
  }))

  const containerWidth = elements.get(rootId)?.width || 0

  return {
    measuredSegments,
    containerWidth,
    isMeasured,
    measurementsContainer,
    onRootRef,
  }
}
