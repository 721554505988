import React, { useState } from 'react'

import {
  RecoveryCode,
  VerificationCode,
  useSetUserAuthorization,
} from '@sketch/modules-common'

import { ParsedError } from '@sketch/graphql-apollo/useMutation'
import {
  ValidateMfaTotpMutation,
  ValidateMfaRecoveryCodeMutation,
} from '@sketch/gql-types'

interface MFAError {
  code: string
  message: string
  type: 'wrong_mfa_verification_code' | 'invalid_mfa_token'
}

interface MfaInterfaceProps {
  mfaToken: string
}

/**
 * After the user logs in successfuly in the SSO Linking account page
 * this component will  deal with the logic that toggles between the verification code
 * interface or the recovery code interface in the MFA verification step when
 * linking SSO accounts
 */
const MfaInterface: React.FC<MfaInterfaceProps> = ({ mfaToken }) => {
  const [showRecoveryCode, setShowRecoveryCode] = useState(false)
  const [verificationError, setVerificationError] = useState<string>()

  const setUserAuthorization = useSetUserAuthorization()

  const handleToggleInterface = () => {
    if (showRecoveryCode) {
      setVerificationError(undefined)
      setShowRecoveryCode(false)
    } else {
      setVerificationError(undefined)
      setShowRecoveryCode(true)
    }
  }

  const handleCompleted = (
    data: ValidateMfaTotpMutation | ValidateMfaRecoveryCodeMutation
  ) => {
    if ('validateMfaRecoveryCode' in data) {
      setUserAuthorization(data.validateMfaRecoveryCode.credentials)
    } else {
      setUserAuthorization(data.validateMfaTotp.credentials)
    }
  }

  const handleError = (error: ParsedError) => {
    // TODO: Improve returned error types from local resolvers
    // https://github.com/sketch-hq/Cloud/issues/11366
    const mfaError = error.message as unknown as MFAError

    setVerificationError(mfaError.message)
  }

  const handleVerificationCodeFilled = () => {
    // Clear error
    setVerificationError(undefined)
  }

  if (showRecoveryCode) {
    return (
      <RecoveryCode
        onCompleted={handleCompleted}
        mfaToken={mfaToken}
        toggleInterface={handleToggleInterface}
      />
    )
  }

  return (
    <VerificationCode
      onCompleted={handleCompleted}
      onError={handleError}
      onVerificationCodeFilled={handleVerificationCodeFilled}
      toggleInterface={handleToggleInterface}
      mfaToken={mfaToken}
      verificationError={verificationError}
    />
  )
}

export default MfaInterface
