import { useShareAndFrameForDocumentWithUuidQuery } from '@sketch/gql-types'
import { SymbolMaster } from '../../../../inspector'
import { useVersioning } from '../../../../versioning'

/**
 * The symbol master does not know the actual document identifier. We need to fetch
 * it based on the information we have (document name and uuid).
 */
export function useSymbolMasterDocument(symbolMaster: SymbolMaster) {
  const versionContext = useVersioning()

  const version =
    versionContext.loading === false
      ? versionContext.currentVersion
      : versionContext.latestVersion

  const documentName = symbolMaster.isForeign
    ? symbolMaster.documentName
    : version?.document?.name

  const documentUuid = symbolMaster.isForeign
    ? symbolMaster.documentUuid
    : version?.document?.uuid

  const symbolShareResponse = useShareAndFrameForDocumentWithUuidQuery({
    variables: {
      name: documentName!,
      uuid: documentUuid!,
      frameUUID: symbolMaster.artboardUuid,
    },
    skip: !documentName || !documentUuid,
  })

  const symbolShare = symbolShareResponse?.data?.shareForDocumentWithUuid

  return {
    isLoading: symbolShareResponse.loading,
    documentIdentifier: symbolShare?.version?.document?.identifier,
    shareIdentifier: symbolShare?.identifier,
    versionShortId: symbolShare?.version?.shortId,
    frame: symbolShare?.version?.document?.frame,
  }
}
