import { useQueryParams, useSetQueryParams } from '@sketch/modules-common'
import { usePrototypeContext } from './usePrototypeContext'
import { PrototypeResizeMode } from '@sketch-hq/sketch-web-renderer'
import { useCallback, useEffect } from 'react'

/**
 * Allows to set the retrieve the Prototype resize mode, and keeps the URL query param in sync
 */
export function useSyncedUrlPrototypeResizeMode() {
  const { resizeMode: playerResizeMode, setResizeMode: setResizeModePlayer } =
    usePrototypeContext()
  const { resizeMode: urlResizeMode } = useQueryParams<'PROTOTYPE_PLAYER'>()
  const setQueryParams = useSetQueryParams<'PROTOTYPE_PLAYER'>()

  const resizeMode =
    PrototypeResizeMode[urlResizeMode as PrototypeResizeMode] ??
    playerResizeMode

  useEffect(() => {
    if (!urlResizeMode) return

    const mode = PrototypeResizeMode[urlResizeMode as PrototypeResizeMode]

    if (!mode) return

    setResizeModePlayer(mode)
  }, [urlResizeMode, setResizeModePlayer])

  const setResizeMode = useCallback(
    (mode: PrototypeResizeMode) => {
      setQueryParams({ resizeMode: mode })
    },
    [setQueryParams]
  )

  return {
    resizeMode,
    setResizeMode,
  }
}
