import { useParams, useRouteMatch } from 'react-router'

import { isCwvSpecificRoute, routes } from '@sketch/modules-common'
import {
  useShareRenameMutation,
  useShareDescriptionChangeMutation,
  ShareAboutFragment,
} from '@sketch/gql-types'

import { AboutTabRouteParams } from './AboutTab.types'

export const useDocumentNameChange = (
  onError: ({ message }: { message: string }) => void
) =>
  useShareRenameMutation({
    onError,
    redirectErrors: true,
    optimisticResponse: ({ identifier, name }) => ({
      __typename: 'RootMutationType',
      shareUpdate: {
        __typename: 'ShareUpdatePayload',
        successful: true,
        share: {
          __typename: 'Share',
          identifier,
          name,
        },
        errors: [],
      },
    }),
  })[0]

export const useDocumentDescriptionChange = () =>
  useShareDescriptionChangeMutation({
    onError: 'show-toast',
    redirectErrors: true,
    optimisticResponse: ({ identifier, description }) => ({
      __typename: 'RootMutationType',
      shareUpdate: {
        __typename: 'ShareUpdatePayload',
        successful: true,
        share: {
          __typename: 'Share',
          identifier,
          description,
          extDescription: {
            __typename: 'PlainText',
            contents: description,
          },
        },
        errors: [],
      },
    }),
  })[0]

/**
 * __usePagesURL__
 *
 * Generates the URL used for the "Pages" link in the About sidebar. The link
 * should open the first page in a document when viewing all pages, and do
 * nothing if the user was already looking at a specific page in the document.
 *
 * @param {ShareAboutFragment} share - shared document (SEE: `ShareAboutFragment` gql type)
 *
 * @returns {string} URL used in About sidebar "Pages" link
 */
export function usePagesURL(share: ShareAboutFragment): string {
  const params = useParams<AboutTabRouteParams>()
  const { path } = useRouteMatch()

  const { pages } = share.version?.document || {}
  const firstPageId = pages?.entries[0]?.uuid || ''
  const currentPageId = params?.pageUUID || ''

  const isDocumentView = isCwvSpecificRoute(path, 'SHARE_VIEW')

  return routes.SHARE_PAGE_CANVAS_VIEW.create({
    shareID: share.identifier,
    pageUUID: isDocumentView ? firstPageId : currentPageId,
  })
}
