import React, { FC } from 'react'
import { Button, Modal, ModalInjectedProps } from '@sketch/components'

export interface RemoveShareFromProjectLayoutProps extends ModalInjectedProps {
  title: string
  button?: {
    loading?: boolean
    disabled?: boolean
    onClick?(): void
  }
}

export const RemoveShareFromProjectLayout: FC<
  RemoveShareFromProjectLayoutProps
> = props => {
  const { title, children, hideModal, button } = props

  return (
    <Modal title={title} onCancel={hideModal}>
      <Modal.Body>{children} </Modal.Body>
      <Modal.Footer>
        <Button onClick={hideModal}>Cancel</Button>
        <Button
          variant="primary"
          loading={button?.loading}
          disabled={button?.disabled}
          onClick={button?.onClick}
        >
          Remove Document
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
