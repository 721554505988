import styled, { css } from 'styled-components'

import { Image } from '../../../shares/components/DocumentItem/DocumentItem.styles'

export const Container = styled.div(
  ({ theme }) => css`
    position: relative;
    display: flex;
    flex-shrink: 0;

    width: 68px;
    height: 68px;
    padding: ${
      // the actual space is 4px, but the border is 1px
      4 - 1
    }px;
    border: 1px solid ${theme.colors.border.A};

    margin-right: 16px;
    background-color: ${theme.colors.background.tertiary.C};
    border-radius: 6px;
  `
)

export const ContainerInner = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const StyledStatusIcon = styled.div`
  height: 68px;
  width: 68px;
  /* This color should be "Light/colors.foreground.secondary.4" but given the icon composition */
  /* Opacity needs to be applied to the whole object */
  color: ${({ theme }) => theme.colors.foreground.secondary.A};
  opacity: 0.55;
`

export const NoItemsIcon = styled.div`
  width: 24px;
  height: 24px;
  /* This color should be "Light/colors.foreground.secondary.4" but given the icon composition */
  /* Opacity needs to be applied to the whole object */
  color: ${({ theme }) => theme.colors.foreground.secondary.A};
  opacity: 0.55;
`

export const Thumbnail = styled(Image)`
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  overflow: hidden;

  /* Create the border inside */
  :after {
    border-radius: 4px;

    content: '';
    box-shadow: inset 0 0 0 1px ${({ theme }) => theme.colors.border.A};
    position: absolute;

    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
`

export const ThumbnailsGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  column-gap: 4px;
  row-gap: 4px;

  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  position: absolute;
`

export const NoThumbnailWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  box-shadow: inset 0 0 0 1px ${({ theme }) => theme.colors.border.A};
`
