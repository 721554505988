import React, { useContext } from 'react'
import styled from 'styled-components'
import { Switch, Route, useParams, useHistory } from 'react-router-dom'

import { useGetPageQuery } from '@sketch/gql-types'
import {
  getAllTemplatesForVersionedRoute,
  RouteParams,
} from '@sketch/modules-common'
import { ShareVersionContext, useVersioning } from '../../versioning'
import { DocumentPrototypes } from './DocumentPrototypes'
import { ResponsiveValues } from '@sketch/global-styles'
import { useToast } from '@sketch/toasts'
import { DocumentFrames } from './DocumentFrames'
import { PageFrames } from './PageFrames'

const DocumentWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-self: center;
  min-height: 100%;
  flex: 1 1 auto;
`

interface DocumentProps {
  search: string
  columns: ResponsiveValues<number>
  showPrototypeArtboards: boolean
}

/**
 * Shows a document version with a `ready` render status. Three sub-routes are
 * handled - the main share view which lists all frames grouped by page, the
 * prototypes listing which lists all frames that are prototype start points
 * and the share page view which shows all frames for a specific page.
 */
export const Document = (props: DocumentProps) => {
  return (
    <DocumentWrapper data-testid="document">
      <Switch>
        <Route exact path={[...getAllTemplatesForVersionedRoute('SHARE_VIEW')]}>
          <DocumentFrames {...props} />
        </Route>
        <Route path={getAllTemplatesForVersionedRoute('SHARE_PAGE_VIEW')}>
          <DocumentPage {...props} />
        </Route>
        <Route path={getAllTemplatesForVersionedRoute('SHARE_PROTOTYPES')}>
          <DocumentPrototypes {...props} />
        </Route>
      </Switch>
    </DocumentWrapper>
  )
}

/** Document view filtered to a specific page instead of all of them */
function DocumentPage(props: DocumentProps) {
  useDocumentPageNotFoundRedirect()

  return <PageFrames {...props} />
}

/**
 * When we show a specific page of a document, we double check in the background
 * that the page UUID provided in the URL exists. If the page UUID doesn't exist
 * we redirect to the document view with all the pages.
 *
 * Since the other queries do not raise any error if the page does not exist, we need
 * to call this extra query to check if the page actually exists. We are doing it in the
 * background (without waiting for it) because in most cases the uuid URL will be valid
 * and we don't want to slow down the experience by waiting for an extra request.
 */
function useDocumentPageNotFoundRedirect() {
  const { shareID, pageUUID } = useParams<RouteParams<'SHARE_PAGE_VIEW'>>()
  const { versionShortId } = useContext(ShareVersionContext)!
  const { showToast } = useToast()
  const history = useHistory()
  const { getPathname } = useVersioning()

  useGetPageQuery({
    variables: {
      share: shareID,
      version: versionShortId,
      pageUUID: pageUUID,
    },
    onCompleted(data) {
      // Redirect if no page was found for this pageUUID
      const isValidPageUUID = Boolean(data?.share?.version?.document?.page)
      if (!isValidPageUUID) {
        showToast("The page in this document doesn't exist", 'negative')
        history.replace(
          getPathname({
            routeKey: 'SHARE_VIEW',
            routeParams: { shareID },
          })
        )
      }
    },
  })
}
