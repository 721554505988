import React, {
  useState,
  useEffect,
  useRef,
  MutableRefObject,
  useLayoutEffect,
  useMemo,
} from 'react'
import { useParams } from 'react-router-dom'
import { RouteParams } from '@sketch/modules-common'

import { ProjectListItem } from '../ProjectListItemOld'
import DisabledProjectItem from './DisabledProjectItem'

import {
  ListWrapper,
  DisabledProjectList,
  Separator,
} from './ProjectsList.styles'

import { ProjectLink } from '../../types.old'
import { WorkspaceMinimalFragment } from '@sketch/gql-types'

export interface ProjectListProps {
  items: ProjectLink[]
  canLoadMore?: boolean
  onLoadMore: () => Promise<any>
  onAdd?: () => void
  userCanEdit: boolean
  userCanAdminister: boolean
  workspace: WorkspaceMinimalFragment
  refToLast?: HTMLDivElement
  autofocusLastCreated?: MutableRefObject<boolean>
  isDisabled?: boolean
}

/**
 * ProjectsList
 *
 * This component visually show the list of the projects with the options button on hover.
 *
 * @param props
 * @param {TextLink[]} props.items - The list of project items.
 * @param {function} props.onDelete - OnClick handler for the Delete button inside the Dropdown.
 *
 * @example // Basic example
 * <List
 *  items={[{id: '1', name: 'Project 1', link: '/project/1'}]}
 *  onDelete={(id) => { delete(id)}}/>
 */
const ProjectsList: React.FC<ProjectListProps> = props => {
  const {
    items,
    canLoadMore,
    onLoadMore,
    userCanEdit,
    userCanAdminister,
    workspace,
    autofocusLastCreated,
    isDisabled,
  } = props

  // We are setting the same ref to every project in the list. In this way, is easy to focus
  // in the last one created
  const refToLast = useRef() as React.MutableRefObject<HTMLElement>
  // We want to know that we edited an item
  // and assure that the item has been rendered
  const [editedItem, setEditedItem] = useState('')

  // TODO: Remove this when migration to identifiers in project URLS
  // is completed. https://github.com/sketch-hq/Cloud/issues/12437
  const { projectId } = useParams<RouteParams<'WORKSPACE_PROJECT'>>()

  useEffect(() => {
    if (!autofocusLastCreated?.current) return

    refToLast?.current?.focus()
  }, [items.length, autofocusLastCreated])

  useLayoutEffect(() => {
    if (editedItem !== '') {
      refToLast?.current?.focus()
      setEditedItem('')
    }
  }, [editedItem])

  const projects = useMemo(
    () =>
      items.map(({ link, project }, index, items) => {
        const previousProjectIndex = Math.max(0, index - 1)

        const isCurrentProjectPinned = !project.pinnedByCurrentUserAt
        const isPreviousProjectPinned =
          !!items[previousProjectIndex].project.pinnedByCurrentUserAt

        const isLastPinnedItem =
          isCurrentProjectPinned && isPreviousProjectPinned

        // TODO: Remove this when migration to identifiers in project URLS
        // is completed. https://github.com/sketch-hq/Cloud/issues/12437
        const shouldHighlight = projectId === project.identifier

        return (
          <React.Fragment key={project.identifier}>
            {isLastPinnedItem && <Separator key="pinned-separator" />}
            <ProjectListItem
              key={project.identifier}
              project={project}
              link={link}
              userCanEdit={userCanEdit}
              userCanAdminister={userCanAdminister}
              workspace={workspace}
              refToLast={refToLast}
              setEditedItem={setEditedItem}
              forceHighlight={shouldHighlight}
            />
          </React.Fragment>
        )
      }),
    [items, projectId, userCanAdminister, userCanEdit, workspace]
  )

  // Inactive workspaces have the list of projects disabled
  // These items have alot less logic then the common project item
  // so we render a much simpler item with just the icon, name
  // and a tooltip for truncated names.
  if (isDisabled) {
    return (
      <DisabledProjectList>
        {items.map(({ project }) => (
          <DisabledProjectItem key={project.identifier} project={project} />
        ))}
      </DisabledProjectList>
    )
  }

  return (
    <ListWrapper canLoadMore={canLoadMore} onLoadMore={onLoadMore}>
      {projects}
    </ListWrapper>
  )
}

export default ProjectsList
