import React from 'react'
import PageList from '../../../components/PageList'

import {
  useResponsiveDropdown,
  useBreakpoint,
  Skeleton,
} from '@sketch/components'

import { Trigger, CurrentPage, Chevrons } from './PagesFloatingPanel.styles'

import { useGetPagesList } from '../../../hooks/useGetPagesList'

const DROPDOWN_STYLES = {
  minWidth: 160,
  overflowY: 'auto',
  maxHeight: '80vh',
} as const

interface PagesFloatingPanelProps {
  shareIdentifier: string
  versionShortId: string
  permanentPageIdentifier: string
}

export const PagesFloatingPanel: React.FC<PagesFloatingPanelProps> = ({
  shareIdentifier,
  versionShortId,
  permanentPageIdentifier: pageUUID,
}) => {
  const { pagesCount, loading, pages } = useGetPagesList({
    shareIdentifier,
    versionShortId,
  })

  const isMobile = !useBreakpoint('sm')

  const [content, buttonProps] = useResponsiveDropdown({
    dropdown: PageList,
    dropdownProps: {
      isDropdown: true,
    },
    usePortal: true,
    placement: isMobile ? 'top-start' : 'bottom-start',
    dropdownStyle: DROPDOWN_STYLES,
  })

  const isDataReady = !loading
  const selectedPage = pages.find(({ uuid }) => uuid === pageUUID)
  const onlyOnePage = !!(pagesCount && pagesCount === 1) && !!selectedPage

  return (
    <>
      <Trigger
        {...buttonProps}
        aria-label="Canvas pages list"
        data-testid="pages-selector-trigger"
        disabled={onlyOnePage}
      >
        <CurrentPage>
          {isDataReady ? (
            <>
              <em>Page:</em>{' '}
              {selectedPage ? selectedPage.name : 'Select page...'}
            </>
          ) : (
            <Skeleton height="16px" width="100px" />
          )}
        </CurrentPage>
        {!onlyOnePage && <Chevrons />}
      </Trigger>
      {!onlyOnePage && content}
    </>
  )
}
