import styled from 'styled-components'

import { withBox } from '../Box'
import { inputBaseStyles, inputBorderStyle, InputProps } from '../Input'

export const TextArea = styled(withBox('textarea'))<InputProps>`
  ${inputBaseStyles};
  ${inputBorderStyle};
  line-height: 1.5;
`
