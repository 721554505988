import {
  AnnotationSubjectFragment,
  GetInitialUserDocument,
  GetInitialUserQuery,
} from '@sketch/gql-types'
import { DataProxy } from 'apollo-cache'
import { NormalizedCacheObject } from 'apollo-cache-inmemory'
import ApolloClient from 'apollo-client'
import omit from 'lodash.omit'

import { AnnotationSubjectInput } from 'cloud-frontend'

import {
  GetIsMutationSuccessfulPredicates,
  SubscriptionsToMutationsMap,
} from './subscriptionsMutationsMap.types'

const getAnnotationSubjectToInput = (
  annotation: AnnotationSubjectFragment
): AnnotationSubjectInput | null => {
  if (annotation.currentSubject.__typename === 'UnprocessedSubject') {
    /**
     * This subject should never be used on FE, because we rely on
     * processed subjects only. If FE ever receives this subject it shouldn't
     * perform any action
     */
    return null
  } else if (annotation.currentSubject.__typename === 'Artboard') {
    return {
      type: 'ARTBOARD',
      permanentId: annotation.currentSubject.uuid,
    }
  } else if (annotation.currentSubject.__typename === 'Page') {
    return {
      type: 'PAGE',
      permanentId: annotation.currentSubject.pageUUID || '',
    }
  } else {
    return {
      type: 'COMPONENT',
      permanentId: annotation.currentSubject.uuid,
    }
  }
}

export const getIsMutationSuccessfulPredicates: GetIsMutationSuccessfulPredicates =
  {
    shareDelete: data => !!data.shareDelete?.successful,
    restoreShare: () => true,
    deleteSharePermanently: () => true,
    emptyWorkspaceTrash: () => true,
    deleteProject: data => data.deleteProject.successful,
    deleteProjectPermanently: () => true,
    restoreProject: () => true,
    addProjectMember: () => true,
    removeProjectMember: () => true,
    createAnnotation: () => true,
    createAnnotationComment: () => true,
    moveAnnotation: () => true,
    editComment: () => true,
    resolveAnnotation: () => true,
    unResolveAnnotation: () => true,
    archiveProject: () => true,
    unarchiveProject: () => true,
    updateVersionDescription: () => true,
    versionUpdateKind: () => true,
  }

const doesUserHaveIncludeHasUnreadCommentsFF = (cache: DataProxy) => {
  /**
   * This is a copy of the errorPreventiveCacheRead from utils
   * but since there was a circular dependency it was copied here.
   *
   * This should be removed when the FF "pages-unread-annotations" is
   */
  try {
    const query = cache.readQuery<GetInitialUserQuery>({
      query: GetInitialUserDocument,
    })

    return query?.me.featureFlags?.includes('pages-unread-annotations') || false
  } catch (e) {
    return false
  }
}

const shareChangedGetVariables: SubscriptionsToMutationsMap['shareChanged'][number]['getVariables'] =
  ({ shareChanged }) => {
    if (
      shareChanged.__typename === 'ShareDeletedEvent' ||
      shareChanged.__typename === 'ShareRestoredEvent'
    ) {
      return {
        identifier: shareChanged.share.identifier,
      }
    } else {
      return {
        identifier: shareChanged.shareIdentifier,
      }
    }
  }

export const getSubscriptionsToMutationsMap = (
  getClient: () => ApolloClient<NormalizedCacheObject>
): SubscriptionsToMutationsMap => ({
  versionUpdated: [
    {
      name: 'updateVersionDescription',
      getVariables: ({ versionUpdated }) => ({
        shareIdentifier: versionUpdated.share.identifier,
        versionIdentifier: versionUpdated.identifier,
        description: versionUpdated.description ?? '',
      }),
    },
    {
      name: 'versionUpdateKind',
      getVariables: ({ versionUpdated }) => ({
        versionIdentifier: versionUpdated.identifier,
        kind: versionUpdated.kind,
      }),
    },
  ],
  emptiedWorkspaceTrash: [
    {
      name: 'emptyWorkspaceTrash',
      getVariables: ({ emptiedWorkspaceTrash }) => ({
        identifier: emptiedWorkspaceTrash.workspaceIdentifier,
      }),
    },
  ],
  shareChanged: [
    {
      name: 'shareDelete',
      getVariables: shareChangedGetVariables,
    },
    {
      name: 'restoreShare',
      getVariables: shareChangedGetVariables,
    },
    {
      name: 'deleteSharePermanently',
      getVariables: shareChangedGetVariables,
    },
  ],
  projectChanged: [
    {
      name: 'deleteProject',
      getVariables: ({ projectChanged }) => ({
        projectId:
          (projectChanged.__typename === 'ProjectDeletedEvent' &&
            projectChanged.project.identifier) ||
          '',
      }),
    },
    {
      name: 'restoreProject',
      getVariables: ({ projectChanged }) => ({
        projectIdentifier:
          (projectChanged.__typename === 'ProjectRestoredEvent' &&
            projectChanged.project.identifier) ||
          '',
      }),
    },
    {
      name: 'deleteProjectPermanently',
      getVariables: ({ projectChanged }) => ({
        projectIdentifier:
          (projectChanged.__typename === 'ProjectPermanentlyDeletedEvent' &&
            projectChanged.projectIdentifier) ||
          '',
      }),
    },
    {
      name: 'archiveProject',
      getVariables: ({ projectChanged }) => ({
        projectIdentifier:
          (projectChanged.__typename === 'ProjectArchivedEvent' &&
            projectChanged.project.identifier) ||
          '',
      }),
    },
    {
      name: 'unarchiveProject',
      getVariables: ({ projectChanged }) => ({
        projectIdentifier:
          (projectChanged.__typename === 'ProjectUnarchivedEvent' &&
            projectChanged.project.identifier) ||
          '',
      }),
    },
  ],
  projectMembershipChanged: [
    {
      name: 'addProjectMember',
      getVariables: ({ projectChanged }) => {
        return {
          workspaceMembershipIdentifier:
            (projectChanged.__typename === 'ProjectMemberAddedEvent' &&
              projectChanged.membership.identifier) ||
            '',
          projectIdentifier:
            (projectChanged.__typename === 'ProjectMemberAddedEvent' &&
              projectChanged.project.identifier) ||
            '',
        }
      },
    },
    {
      name: 'removeProjectMember',
      getVariables: ({ projectChanged }) => {
        return {
          workspaceMembershipIdentifier:
            (projectChanged.__typename === 'ProjectMemberRemovedEvent' &&
              projectChanged.membershipIdentifier) ||
            '',
          projectIdentifier:
            (projectChanged.__typename === 'ProjectMemberRemovedEvent' &&
              projectChanged.project.identifier) ||
            '',
        }
      },
    },
  ],
  annotationCreated: [
    {
      name: 'createAnnotation',
      getVariables: ({ annotationCreated }) => ({
        shareIdentifier: annotationCreated.share.identifier,
        body: annotationCreated.firstComment.body,
        coordinates: omit(annotationCreated.coordinates, '__typename') || {
          x: 0,
          y: 0,
        },
        subject: getAnnotationSubjectToInput(annotationCreated)!,
        includeHasUnreadComments:
          doesUserHaveIncludeHasUnreadCommentsFF(getClient()),
      }),
    },
  ],
  annotationReplyCreated: [
    {
      name: 'createAnnotationComment',
      getVariables: ({ annotationReplyCreated }) => {
        return {
          body: annotationReplyCreated.body,
          identifier: annotationReplyCreated.annotation.identifier,
          includeHasUnreadComments:
            doesUserHaveIncludeHasUnreadCommentsFF(getClient()),
        }
      },
    },
  ],
  annotationMoved: [
    {
      name: 'moveAnnotation',
      getVariables: ({ annotationMoved }) => ({
        annotationIdentifier: annotationMoved.identifier,
        coordinates: {
          x: annotationMoved.coordinates!.x,
          y: annotationMoved.coordinates!.y,
        },
        subject: getAnnotationSubjectToInput(annotationMoved)!,
        includeHasUnreadComments:
          doesUserHaveIncludeHasUnreadCommentsFF(getClient()),
      }),
    },
  ],
  annotationReplyEdited: [
    {
      name: 'editComment',
      getVariables: ({ commentEdited }) => ({
        identifier: commentEdited.identifier,
        body: commentEdited.body,
      }),
    },
  ],
  annotationResolved: [
    {
      name: 'resolveAnnotation',
      getVariables: ({ annotationResolved }) => ({
        annotationIdentifier: annotationResolved.identifier,
      }),
    },
  ],
  annotationUnresolved: [
    {
      name: 'unResolveAnnotation',
      getVariables: ({ annotationUnresolved }) => ({
        annotationIdentifier: annotationUnresolved.identifier,
      }),
    },
  ],
})
