import { Kind, RequestRaw } from '@sketch/service-worker'

export class ServiceWorkerTimeoutError<K extends Kind> extends Error {
  public readonly type = 'Time out'
  constructor(
    public readonly kind: K,
    public readonly request: RequestRaw<K>
  ) {
    super(
      'sendRequest to service worker timed out, ' +
        `kind: "${kind}", request: "${JSON.stringify(request)}"`
    )
  }
}
export const isServiceWorkerTimeoutError = <K extends Kind = Kind>(
  maybeError: any,
  ...kind: K[]
): maybeError is ServiceWorkerTimeoutError<K> => {
  if (!maybeError || typeof maybeError !== 'object') return false
  if (!(maybeError instanceof ServiceWorkerTimeoutError)) return false

  if (kind.length === 0) return true
  return kind.includes(maybeError.kind)
}
