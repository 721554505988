import styled from 'styled-components'

import { Separator as BaseSeparator } from '@sketch/components'

export const ResolvedSeparator = styled.div`
  position: relative;
  width: 100%;
  padding-left: 8px;
  margin: 12px 0;

  color: ${({ theme }) => theme.colors.state.positive.A};
  font-size: ${({ theme }) => theme.fontSizes.B};
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
  text-transform: uppercase;

  ::after {
    content: '';
    position: absolute;
    right: 0;
    top: 50%;
    width: 180px;
    height: 1px;
    border-bottom: 1px solid ${({ theme }) => theme.colors.border.B};
    transform: translateY(-50%);
  }
`

export const Separator = styled(BaseSeparator)`
  margin: 8px 12px;
`

export const CommentNewSeparator = styled(Separator)`
  width: 100%;
  display: flex;

  align-items: center;

  line-height: 1;
  margin: 8px 0;
  padding: 0 8px;

  color: ${({ theme }) => theme.colors.sketch.A};

  font-size: 0.6875rem; /* 11px */
  font-weight: ${({ theme }) => theme.fontWeights.semibold};

  text-align: right;
  border-top: 0;

  ::after {
    display: block;
    content: '';

    flex: 1;
    margin-left: 8px;

    border-top: 1px solid ${({ theme }) => theme.colors.border.B};
  }
`
