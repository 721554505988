import React from 'react'
import { useRouteMatch } from 'react-router-dom'

import {
  isCwvRouteOnly,
  isDocumentRoute,
  isDocumentPageRoute,
  routes,
} from '@sketch/modules-common'

import {
  LinkSection,
  HeaderListItem,
  LinkListPrototypeCards,
  LinkListPrototypeCard,
  StyledDropdownNavLink,
} from './AboutTab.styles'

import { ReactComponent as PrototypeIcon } from '@sketch/icons/prototype-hotspot-16'
import { ReactComponent as PageIcon } from '@sketch/icons/canvas-page-16'
import { ReactComponent as ComponentsIcon } from '@sketch/icons/components-16'

// Qraphql
import { ShareAboutFragment } from '@sketch/gql-types'

import { usePagesURL } from './AboutTab.hooks'
import PrototypeCard from '../PrototypeCard'
import { getFrameThumbnail } from '../../utils'
import { Tooltip } from '@sketch/components'

interface AboutTabLinkSectionProps {
  share: ShareAboutFragment
}

const AboutTabLinkSection: React.FC<AboutTabLinkSectionProps> = ({ share }) => {
  const { path } = useRouteMatch()

  const { document } = share.version || {}
  const { pages, prototypes } = document || {}
  const prototypeArtboards = prototypes?.entries || []

  const pagesUrl = usePagesURL(share)
  const shareID = share.identifier

  const hasPrototypes = prototypeArtboards.length > 0

  const copyPerLink = {
    pages: 'Pages',
    components: 'Components',
    prototypes: 'Prototypes',
  }

  return (
    <LinkSection>
      <HeaderListItem>Views</HeaderListItem>
      {pages?.entries[0] && (
        <StyledDropdownNavLink
          key={copyPerLink['pages']}
          to={pagesUrl}
          isActive={() => isDocumentRoute(path) || isDocumentPageRoute(path)}
        >
          <PageIcon />
          {copyPerLink['pages']}
        </StyledDropdownNavLink>
      )}

      <StyledDropdownNavLink
        to={routes.CWV_SYMBOLS.create({ shareID })}
        isActive={() => isCwvRouteOnly(path)}
      >
        <ComponentsIcon />
        {copyPerLink['components']}
      </StyledDropdownNavLink>

      {prototypeArtboards.length > 0 && (
        <StyledDropdownNavLink to={routes.SHARE_PROTOTYPES.create({ shareID })}>
          <PrototypeIcon />
          {copyPerLink['prototypes']}
        </StyledDropdownNavLink>
      )}

      {hasPrototypes && (
        <LinkListPrototypeCards>
          {prototypeArtboards.map(artboard => (
            <LinkListPrototypeCard key={artboard.uuid}>
              <Tooltip
                content={artboard.name}
                placement="bottom"
                disableWhenTouchDevice
              >
                <PrototypeCard
                  artboardUUID={artboard.uuid}
                  title={artboard.name}
                  src={getFrameThumbnail(artboard)}
                  showTitle={false}
                  showDropdown={false}
                  iconSize={32}
                />
              </Tooltip>
            </LinkListPrototypeCard>
          ))}
        </LinkListPrototypeCards>
      )}
    </LinkSection>
  )
}

export default AboutTabLinkSection
