import styled from 'styled-components'
import { Text } from '@sketch/components'

export const DocumentName = styled(Text.H1).attrs({
  textStyle: 'copy.primary.standard.F',
})`
  overflow-wrap: break-word;
  font-weight: 500;
  line-height: ${({ theme }) => theme.lineHeights.default};
  margin: 0;
`

export const DocumentNameWrapper = styled.div`
  margin-bottom: 4px;
`
