import { WorkspaceMinimalFragment } from '@sketch/gql-types'

import { useIsProfileCreated } from '../../../../community/hooks'

import {
  useRefetchWorkspaceSettingsOnRouteChange,
  useWorkspaceSettings,
} from '../../../operations'

import {
  useCanSubscribeViaStripe,
  workspaceSettingsPermissions,
} from '../../../utils'

import { CanRenderOptions } from '../../../types'

export const useMenuPermissions = ({
  workspace,
}: {
  workspace: WorkspaceMinimalFragment
}) => {
  const { isProfileCreated } = useIsProfileCreated(workspace.identifier)

  const { data: workspaceSettingsData, refetch } =
    useWorkspaceSettings(workspace)

  useRefetchWorkspaceSettingsOnRouteChange(refetch, workspace.identifier)

  const { workspace: workspaceSettings, customer } = workspaceSettingsData || {}

  const { source, customerProvider, loading } = useCanSubscribeViaStripe(
    workspace.identifier,
    workspace.customer?.identifier
  )

  const {
    isPartner,
    isFinance,
    isGuest,
    showWorkspaceSettingsBilling,
    showWorkspaceSettingsGeneral,
    showWorkspaceSettingsSSO,
    showWorkspaceSettingsPeople,
    showWorkspacePermissionGroup,
    showWorkspaceSettingsProfile,
  } = workspaceSettingsPermissions({
    workspace,
    customerProvider,
    settings: workspaceSettings,
    customer,
    source,
    isProfileCreated,
  })

  const canRender: CanRenderOptions = {
    generalMenu: showWorkspaceSettingsGeneral,
    billingMenu: showWorkspaceSettingsBilling,
    permissionGroups: showWorkspacePermissionGroup,
    SSOMenu: showWorkspaceSettingsSSO,
    peopleMenu: showWorkspaceSettingsPeople,
    profileMenu: showWorkspaceSettingsProfile,
  }

  return { isFinance, isGuest, isPartner, loading, canRender }
}
