import React from 'react'

import {
  Flex,
  SegmentsMap,
  TruncateSegments,
  Skeleton,
} from '@sketch/components'
import {
  Header,
  Title,
  PreviewImage,
  PreviewImageEmpty,
  separatorWidth,
  SegmentSeparator,
  Subtitle,
  HeaderRight,
  TooltipContent,
  TooltipSegment,
  TooltipIcon,
  MoveLabel,
} from './MoveStorageItemModalHeader.styles'

import { ProjectDetailsFragment, ShareInfoFragment } from '@sketch/gql-types'
import { usePathToCurrent } from './getLocation'
import { useTreeState } from '../../../projects/modals/ProjectPickerContent'
import { NestedProjectModalPreview } from '../../../nestedProjects/components/NestedProjectModalPreview'

const ItemLocation: React.FC<{}> = () => {
  const treeState = useTreeState()
  const segments = usePathToCurrent()

  const directParentId = treeState.currentIds.currentId

  const segmentsMap: SegmentsMap = new Map()
  for (const entry of segments) {
    segmentsMap.set(entry.id, {
      id: entry.id,
      name: entry.name,
      node: (
        <Subtitle bold={entry.id === directParentId}>{entry.name}</Subtitle>
      ),
    })
  }

  return (
    <Subtitle>
      <TruncateSegments
        segments={segmentsMap}
        separator={{ element: <SegmentSeparator />, width: separatorWidth }}
        ellipsis={{ element: <span>…</span>, width: separatorWidth }}
        renderTooltip={segmentIds => {
          return (
            <TooltipContent>
              {segmentIds.map((id, index) => (
                <TooltipSegment key={id} depth={index}>
                  <TooltipIcon />
                  {segmentsMap.get(id)?.name}
                </TooltipSegment>
              ))}
            </TooltipContent>
          )
        }}
      />
      {
        // reduce jitter when loading
        segments.length === 0 && <>&nbsp;</>
      }
    </Subtitle>
  )
}

interface MoveStorageItemModalHeaderProps {
  item?:
    | Pick<
        ShareInfoFragment,
        '__typename' | 'version' | 'name' | 'project' | 'workspace'
      >
    | Pick<
        ProjectDetailsFragment,
        '__typename' | 'identifier' | 'name' | 'previews'
      >
}

export const ItemPreview = (props: MoveStorageItemModalHeaderProps) => {
  const { item } = props

  if (!item) {
    return <PreviewImageEmpty data-testid="move-storage-item-placeholder" />
  }

  if (item.__typename === 'Share') {
    const thumbnails = item?.version?.document?.previewFile?.thumbnails
    const image = thumbnails ? thumbnails[0]?.url : undefined

    if (!image) {
      return <PreviewImageEmpty data-testid="move-storage-item-placeholder" />
    }

    return <PreviewImage alt={item?.name ?? ''} src={image} />
  }

  if (item.__typename === 'Project') {
    return <NestedProjectModalPreview nestedProject={item} />
  }

  return <PreviewImageEmpty data-testid="move-storage-item-placeholder" />
}

export const MoveStorageItemModalHeader = (
  props: MoveStorageItemModalHeaderProps
) => {
  const { item } = props

  return (
    <Header>
      <Flex data-testid="move-document-modal-header" alignItems="center" mb={4}>
        <ItemPreview item={item} />

        <HeaderRight>
          <Title>
            <MoveLabel>Move:</MoveLabel> {item?.name ?? <>&hellip;</>}
          </Title>
          <ItemLocation />
        </HeaderRight>
      </Flex>
    </Header>
  )
}

export const MoveStorageItemModalHeaderLoading = () => {
  return (
    <Header>
      <Flex alignItems="center" mb={4}>
        <PreviewImageEmpty />

        <HeaderRight>
          <Title>
            <MoveLabel>Move:</MoveLabel>
            <Skeleton width="30%" height="20px" />
          </Title>
          <Subtitle>
            <Skeleton width="30%" height="13px" />
          </Subtitle>
        </HeaderRight>
      </Flex>
    </Header>
  )
}
