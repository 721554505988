/**
 * This component is based on the React TimeAgo component
 * https://github.com/nmn/react-timeago/blob/master/src/index.js
 *
 * In order to match the mac welcome window related time we have updated
 * the internals of react-timeago to match the code of
 *
 * https://github.com/sketch-hq/Sketch/blob/c0a3cb5a87877a58e619991a7925a363bcab4630/Modules/BCFoundation/Source/NSDate%2BBCFoundation.m
 */

import React, { useEffect, useState } from 'react'
import { getRelativeDate, timeConstants } from '@sketch/utils'

export function defaultFormatter(
  value: number,
  _unit: string,
  suffix: string
): string {
  const unit = value !== 1 ? _unit + 's' : _unit

  return value + ' ' + unit + ' ' + suffix
}

function dateParser(date: string | number | Date): Date {
  const parsed = new Date(date)
  if (!Number.isNaN(parsed.valueOf())) {
    return parsed
  }

  const parts: string[] = String(date).match(/\d+/g)!
  if (parts == null || parts.length <= 2) {
    return parsed
  } else {
    const [firstP, secondP, ...restPs] = parts.map(x => parseInt(x))
    const correctedParts = [firstP, secondP - 1, ...restPs]
    const isoDate = new Date(
      Date.UTC(...(correctedParts as Parameters<typeof Date.UTC>))
    )

    return isoDate
  }
}

export type Unit =
  | 'second'
  | 'minute'
  | 'hour'
  | 'day'
  | 'week'
  | 'month'
  | 'year'
export type Suffix = 'ago' | 'from now'
export type Formatter = (
  value: number,
  unit: Unit,
  suffix: Suffix,
  epochMilliseconds: number,
  nextFormatter: () => React.ReactNode,
  now: () => number
) => React.ReactNode

export interface DefaultTimeAgoProps {
  /** If the component should update itself over time */
  live?: boolean
  /** minimum amount of time in seconds between re-renders */
  minPeriod?: number
  /** Maximum time between re-renders in seconds. The component should update at least once every `x` seconds */
  maxPeriod?: number
  /** The container to render the string into. You could use a string like `span` or a custom component */
  component?: string | React.ComponentType<any>
  /**
   * A title used for setting the title attribute if a <time> HTML Element is used.
   */
  title?: string
  /** A function to decide how to format the date.
   * If you use this, react-timeago is basically acting like a glorified setInterval for you.
   */
  formatter?: Formatter
  /** The Date to display. An actual Date object or something that can be fed to new Date */
  date: string | number | Date
  /** A function that returns what Date.now would return. Primarily for server
   * date: string | number | Date		 +   * rendering.
   */
  now?: () => number
}

const defaultNow = () => Date.now()

export default function DefaultTimeAgo({
  date,
  formatter = defaultFormatter,
  component = 'time',
  live = true,
  minPeriod = 0,
  maxPeriod = timeConstants.WEEK,
  title,
  now = defaultNow,
  ...passDownProps
}: DefaultTimeAgoProps): null | React.ReactElement {
  const [timeNow, setTimeNow] = useState(now())
  useEffect(() => {
    if (!live) {
      return
    }
    const tick = () => {
      const then = dateParser(date).valueOf()
      if (!then) {
        // eslint-disable-next-line no-console
        console.warn('[react-timeago] Invalid Date provided')
        return 0
      }
      const seconds = Math.round(Math.abs(timeNow - then) / 1000)

      const unboundPeriod =
        seconds < timeConstants.MINUTE
          ? 1000
          : seconds < timeConstants.HOUR
            ? 1000 * timeConstants.MINUTE
            : seconds < timeConstants.DAY
              ? 1000 * timeConstants.HOUR
              : 1000 * timeConstants.WEEK

      const period = Math.min(
        Math.max(unboundPeriod, minPeriod * 1000),
        maxPeriod * 1000
      )

      if (period) {
        return setTimeout(() => {
          setTimeNow(now())
        }, period)
      }

      return 0
    }
    const timeoutId = tick()
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId)
      }
    }
  }, [date, live, maxPeriod, minPeriod, now, timeNow])

  const Component = component
  const then = dateParser(date).valueOf()
  if (!then) {
    return null
  }

  const seconds = Math.round(Math.abs(timeNow - then) / 1000)
  const suffix = then < timeNow ? 'ago' : 'from now'

  const [value, unit] = getRelativeDate(seconds)

  const passDownTitle =
    typeof title === 'undefined'
      ? typeof date === 'string'
        ? date
        : dateParser(date).toISOString().substr(0, 16).replace('T', ' ')
      : title

  const spreadProps =
    Component === 'time'
      ? { ...passDownProps, dateTime: dateParser(date).toISOString() }
      : passDownProps

  const nextFormatter = defaultFormatter.bind(null, value, unit, suffix)

  return (
    <Component {...spreadProps} title={passDownTitle}>
      {formatter(value, unit, suffix, then, nextFormatter, now)}
    </Component>
  )
}
