import React, { FC, useEffect, useRef, useState, ReactNode } from 'react'
import { InView } from 'react-intersection-observer'
import get from 'lodash.get'
import set from 'lodash.set'
import {
  ApolloQueryResult,
  FetchMoreOptions,
  FetchMoreQueryOptions,
  OperationVariables,
} from 'apollo-client'

import { Box, Flex } from '../Box'
import { Spinner } from '../Spinner'
import { ErrorHandler } from '@sketch/tracing'
import { excludeError, useIsMountedRef } from '@sketch/utils'

export interface FetchMoreConfig<TData = any, TVariables = OperationVariables> {
  after?: string | null
  fetchLatest?: boolean
  variables?: Partial<TVariables>
  dataIdFromObject(result: TData): string | null | undefined
  /**
   * Using preserveAfter will make sure that the fetchMore uses
   * the previous "after" effectively ignoring the new "after"
   * value returned by the BE.
   */
  preserveAfter?: boolean
  afterPath?: string[]
}

export type FetchMoreFunc<TData = any, TVariables = OperationVariables> = (
  fetchMoreOptions: FetchMoreQueryOptions<TVariables, keyof TVariables> &
    FetchMoreOptions<TData, TVariables>
) => Promise<ApolloQueryResult<TData>>

export interface InfiniteListProps {
  canLoadMore?: boolean
  renderLoading?: () => ReactNode
  onLoadMore: () => Promise<any>
  reversed?: boolean
  wrappingComponent?: React.ElementType
  placeholderItems?: React.ReactNode
  listRef?: React.Ref<HTMLElement>
}

/**
 * @deprecated use @see{ handleFetchMore }
 */
export const loadMore =
  <TData, TVariables extends OperationVariables>(
    fetchMore: FetchMoreFunc,
    after: string | null,
    entriesPath: string[]
  ) =>
  (): Promise<void> | ReturnType<FetchMoreFunc<TData, TVariables>> => {
    if (!after) return Promise.resolve()

    return fetchMore({
      variables: {
        after,
      },
      updateQuery: (previousResult, { fetchMoreResult }: any) => {
        return set(fetchMoreResult, entriesPath, [
          ...get(previousResult, entriesPath, []),
          ...get(fetchMoreResult, entriesPath, []),
        ])
      },
    })
  }

export const InfiniteList: FC<InfiniteListProps> = ({
  canLoadMore = false,
  children,
  renderLoading,
  reversed,
  onLoadMore,
  wrappingComponent: Wrapper = Box,
  placeholderItems = null,
  listRef,
  ...props
}) => {
  // On large displays, more than one page is requested. In this case,
  // scrollToBottom on componentDidMount is fallible.
  // userTriggeredScroll will be handy to help distinguish when the scroll is
  // triggered by the user or by the observable on the first load.
  const userTriggeredScroll = useRef<boolean>()

  const itemsEndRef = useRef<HTMLDivElement | null>(null)

  const [loading, setLoading] = useState(false)
  const isInViewStillVisible = useRef<boolean>()
  const isMountedRef = useIsMountedRef()

  const scrollToBottom = () => {
    window.requestAnimationFrame(() => {
      if (!itemsEndRef.current || !itemsEndRef.current.scrollIntoView) return
      itemsEndRef.current.scrollIntoView({ block: 'nearest' })
    })
  }

  const handleScroll = () => {
    userTriggeredScroll.current = true
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { capture: true })
    if (reversed) scrollToBottom()

    return () => {
      window.removeEventListener('scroll', handleScroll, { capture: true })
    }
  }, [reversed])

  useEffect(() => {
    if (!canLoadMore) {
      return
    }

    if (!loading && isInViewStillVisible.current) {
      loadMoreItems()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canLoadMore, loading])

  const loadMoreItems = () => {
    setLoading(true)

    return onLoadMore()
      .catch(e => {
        if (excludeError(e)) {
          ErrorHandler.ignore(
            e,
            'Workaround for an issue where apollo attempts to access a query which has already unmounted.'
          )
          return null
        }

        throw e
      })
      .finally(() => {
        if (!isMountedRef.current) {
          return
        }
        if (userTriggeredScroll.current) {
          userTriggeredScroll.current = false
        } else if (reversed) {
          scrollToBottom()
        }

        setLoading(false)
      })
  }

  const onChangeObserver = (inView: boolean) => {
    isInViewStillVisible.current = inView
    if (!inView) return

    loadMoreItems()
  }

  const listItems = [
    children,
    <Flex justifyContent="center" key={`infinite-list-loader`}>
      <InView onChange={onChangeObserver}>{null}</InView>
      {loading &&
        (renderLoading && typeof renderLoading === 'function' ? (
          renderLoading()
        ) : (
          <Spinner primary />
        ))}
    </Flex>,
    <React.Fragment key="placeholders">{placeholderItems}</React.Fragment>,
  ]

  return (
    <Wrapper ref={listRef} {...props}>
      {reversed ? listItems.reverse() : listItems}
      <div ref={itemsEndRef} />
    </Wrapper>
  )
}
