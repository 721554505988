import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import {
  useAnnotationOverlayContext,
  useMovingAnnotation,
} from './useAnnotationOverlay'

import {
  Coordinates,
  ArtboardContainerBounds,
  MouseCoordinates,
} from '../types'

const DEFAULT_DISTANCE = { x: 0, y: 0 }

/**
 * We want to round down or up depending on what edge we are on
 * This to deal with half pixels putting annotations just outside the edge of the artboard
 */
const calculateBoundedCoordinate = (
  coordinate: number,
  minBound: number,
  maxBound: number
) => {
  const outOfBoundsMin = coordinate < minBound
  const outOfBoundsMax = coordinate > maxBound
  if (outOfBoundsMin) {
    return Math.ceil(minBound)
  } else if (outOfBoundsMax) {
    return Math.floor(
      maxBound - 0.5
    ) /* max bound needs a little bit of extra leeway*/
  } else {
    return coordinate
  }
}

const getArtboardBoundedMousePosition = (
  event: MouseEvent,
  bounds: ArtboardContainerBounds
) => {
  const newX = calculateBoundedCoordinate(
    event.clientX,
    bounds.left,
    bounds.right
  )
  const newY = calculateBoundedCoordinate(
    event.clientY,
    bounds.top,
    bounds.bottom
  )

  return {
    clientX: newX,
    clientY: newY,
  }
}

const getDistance = (
  initialPosition: Coordinates,
  event: MouseEvent,
  bounds?: ArtboardContainerBounds
) => {
  const mousePosition = bounds
    ? getArtboardBoundedMousePosition(event, bounds)
    : event

  return {
    x: mousePosition.clientX - initialPosition.x,
    y: mousePosition.clientY - initialPosition.y,
  }
}

const hasDotMoved = (distance: Coordinates) =>
  Math.abs(distance.x) > 0 || Math.abs(distance.y) > 0

const getTranslateStyle = (
  distance: Coordinates,
  isAnnotationMoving: boolean
) => {
  const transform = `translate3d(${distance?.x}px, ${distance?.y}px, 0)`

  if (!isAnnotationMoving) {
    return { transform }
  }

  return {
    transition: 'none' as const,
    transform,
  }
}

interface UseTranslateDotProps {
  annotationIdentifier: string
  coordinates: Coordinates
  disabled?: boolean
  getArtboardContainerBounds?: () => ArtboardContainerBounds | undefined
  prefixBounds?: Coordinates
  onMoveDrop?: (finalDistance: MouseCoordinates) => void
  onClick?: (event: React.MouseEvent, hasMoved: boolean) => void
}

const useTranslateDot = (props: UseTranslateDotProps) => {
  const {
    annotationIdentifier,
    onMoveDrop,
    onClick,
    coordinates,
    getArtboardContainerBounds,
    prefixBounds,
    disabled,
  } = props

  /**
   * "initialPosition" marks the dot original position on the page
   * should be the one without any translate
   */
  const initialPosition = useRef<Coordinates | null>(null)

  /**
   * "mouseDownPosition" marks the beginning of the "grab" with this
   * we will eval if the dot has moved (even after consequent moves)
   *
   * this will help distinguish if we should allow to click or prevent it
   */
  const mouseDownPosition = useRef<Coordinates | null>(null)

  /**
   * "isDraggingDot" guards against two issues related to the `mouseup`
   * event, and the way internal values are reset:
   * 1. We don't want to reset `initialPosition` & `mouseDownPosition` while
   *    the dot is still being moved. This can occur when:
   *    - user releases mouse button (`onMouseUp` called, which changes the
   *      coordinates: reset `useEffect` call #1)
   *    - user quickly grabs the dot and keeps dragging (reset `useEffect` call #2,
   *      causing the dot position to differ from the cursor position)
   *    - user eventually releases the mouse button (`onMouseUp`, new coordinates,
   *      and `useEffect` call #3 that eventually bring mouse & dot position in sync)
   * 2. We want to avoid running `moveAnnotationRef.current` if there was no
   *    no drag action by the user. This occurs when the window `mouseup`
   *    listener fires as we close an open dot and de-select the Annotation,
   *    and causes an unnecessary `moveAnnotation` mutation to be fired.
   *
   * SEE: https://github.com/sketch-hq/Cloud/issues/14989
   */
  const isDraggingDot = useRef(false)

  /**
   * "distance" will save the distance from the original position to the
   * current one
   */
  const [distance, setDistance] = useState<Coordinates>(DEFAULT_DISTANCE)

  const annotationContext = useAnnotationOverlayContext()
  const [movingAnnotation, setMovingAnnotation] = useMovingAnnotation() || []
  const isAnnotationMoving = annotationIdentifier === movingAnnotation

  const hasMoved = isAnnotationMoving && hasDotMoved(distance)

  /**
   * We are preventing moving annotations when the user is not seeing the latest version,
   * mainly because any slight change might influence the subject and position, of a reality
   * that might no longer be the correct. Blocking the move action once its not the latest version
   * safeguards that
   */

  /**
   * Reset all the internal values
   * when the dot coordinates update
   */
  useEffect(() => {
    // Don't reset if the dot is still moving
    if (isDraggingDot.current) {
      return
    }

    initialPosition.current = null
    mouseDownPosition.current = null
    setDistance(DEFAULT_DISTANCE)
  }, [coordinates, prefixBounds])

  /**
   * Prevent this hook to be unmounted and leaving the
   * context hanging with "movingAnnotation" ON
   */
  useEffect(() => {
    const effectMovingAnnotation = setMovingAnnotation
    return () => {
      effectMovingAnnotation?.(null)
    }
  }, [setMovingAnnotation])

  /**
   * Save the moveAnnotation function so it doesn't trigger re-renders
   * and makes the use-effect depend on it
   */
  const moveAnnotationRef = useRef((event: MouseEvent) => {})
  useEffect(() => {
    moveAnnotationRef.current = (event: MouseEvent) => {
      // Do not run if the user was not moving the dot at the time the window
      // `mouseup` event was fired.
      if (!isDraggingDot.current) {
        return
      }

      // The user is no longer dragging the dot
      isDraggingDot.current = false

      setMovingAnnotation?.(null)

      if (!annotationContext?.isViewingLatestVersion) {
        return
      }

      const artboardContainerBounds = getArtboardContainerBounds?.()
      const finalDistance = getDistance(
        initialPosition.current || DEFAULT_DISTANCE,
        event,
        artboardContainerBounds
      )

      setDistance(finalDistance)
      setMovingAnnotation?.(null)

      const finalMousePosition = artboardContainerBounds
        ? getArtboardBoundedMousePosition(event, artboardContainerBounds)
        : event

      isAnnotationMoving && hasDotMoved(finalDistance)
        ? onMoveDrop?.(finalMousePosition)
        : setMovingAnnotation?.(null)
    }
  }, [
    isAnnotationMoving,
    onMoveDrop,
    setMovingAnnotation,
    getArtboardContainerBounds,
    annotationContext?.isViewingLatestVersion,
  ])

  /**
   * We listen to the window mouseup event because the element
   * mouseup event can get lost if the dot drags itself away from the cursor.
   *
   * Using a window event allows both events to be "independent" and
   * still force the mouseup event to exist somewhere.
   */
  useEffect(() => {
    if (!isAnnotationMoving) {
      return
    }

    const windowOnMouseUp = (event: MouseEvent) => {
      moveAnnotationRef.current(event)
    }

    window.addEventListener('mouseup', windowOnMouseUp)

    return () => {
      window.removeEventListener('mouseup', windowOnMouseUp)
    }
  }, [isAnnotationMoving])

  /**
   * Mount the document movement listeners
   * to track the mouse position
   */
  useEffect(() => {
    if (!isAnnotationMoving || !annotationContext?.isViewingLatestVersion) {
      return
    }

    const handleMouseMove = (event: MouseEvent) => {
      const artboardContainerBounds = getArtboardContainerBounds?.()

      setDistance(
        getDistance(
          initialPosition.current || DEFAULT_DISTANCE,
          event,
          artboardContainerBounds
        )
      )
    }

    window.addEventListener('mousemove', handleMouseMove)

    return () => {
      window.removeEventListener('mousemove', handleMouseMove)
    }
  }, [
    isAnnotationMoving,
    annotationIdentifier,
    getArtboardContainerBounds,
    annotationContext?.isViewingLatestVersion,
  ])

  const handleClick = useCallback(
    (event: React.MouseEvent) => {
      if (disabled) {
        onClick?.(event, false)
        return
      }

      if (!annotationContext?.isViewingLatestVersion) {
        return
      }

      const deltaX = (mouseDownPosition.current?.x || 0) - event.clientX
      const deltaY = (mouseDownPosition.current?.y || 0) - event.clientY

      const hasMoved = Math.abs(deltaX) > 0 || Math.abs(deltaY) > 0
      onClick?.(event, hasMoved)
    },
    [disabled, onClick, annotationContext?.isViewingLatestVersion]
  )

  const handleMouseDown = useCallback(
    (event: React.MouseEvent) => {
      // Prevent the link drag to take over
      event.preventDefault()

      if (disabled) {
        return
      }

      // Warns the context that this annotation is moving
      setMovingAnnotation?.(annotationIdentifier)

      const { clientX, clientY } = event
      const position = { x: clientX, y: clientY }

      mouseDownPosition.current = position

      // Since it's related with the origin position
      // we only set it once!
      if (!initialPosition.current) {
        initialPosition.current = position
      }

      isDraggingDot.current = true
    },
    [annotationIdentifier, disabled, setMovingAnnotation]
  )

  return useMemo(
    () => ({
      hasMoved,
      style: getTranslateStyle(distance, isAnnotationMoving),
      onClick: handleClick,
      onMouseDown: handleMouseDown,
    }),
    [distance, handleClick, handleMouseDown, hasMoved, isAnnotationMoving]
  )
}

export default useTranslateDot
