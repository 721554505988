import React, { FC, useContext } from 'react'

import { Dropdown, ErrorMessage, ModalContext } from '@sketch/components'
import {
  ShareListItemFragment,
  TrashedShareFragment,
  useGetShareWorkspaceQuery,
} from '@sketch/gql-types'
import { RestoreShareModal } from '../RestoreShareModal'
import { DeleteSharePermanentlyModal } from '../DeleteSharePermanentlyModal'

import { canRestoreShare } from '../../utils'

import { useGetTrashedShareDropdownOptions } from '../../operations/useGetTrashedShareDropdownOptions'

// ShareListItemFragment should be removed once BE returns the newer types in the getTrash
// https://github.com/sketch-hq/Cloud/issues/11226
export interface ShareCommonForTrashedActionsDropdown
  extends Pick<
    ShareListItemFragment | TrashedShareFragment,
    'name' | 'identifier' | 'project'
  > {
  version: {
    shortId: string
    identifier: string
  } | null
}

export type ShareForTrashedActionsDropdown =
  | (ShareCommonForTrashedActionsDropdown &
      Pick<ShareListItemFragment, '__typename'>)
  | (ShareCommonForTrashedActionsDropdown &
      Pick<TrashedShareFragment, '__typename' | 'workspace'>)

const useShareWorkspace = (share: ShareForTrashedActionsDropdown) => {
  const existingWorkspace =
    share.__typename === 'TrashedShare' ? share.workspace : null

  /**
   * TODO: use dedicated query to get trashed documents inside of trashed project
   *  see: https://github.com/sketch-hq/Cloud/issues/12873
   */
  const result = useGetShareWorkspaceQuery({
    variables: { shareIdentifier: share.identifier },
    skip: !!existingWorkspace,
  })

  const workspace = existingWorkspace || result.data?.share?.workspace

  return Object.assign(result, { workspace })
}

interface ShareActionsDropdownTrashedProps {
  share: ShareForTrashedActionsDropdown
}

export const ShareActionsDropdownTrashed: FC<
  ShareActionsDropdownTrashedProps
> = props => {
  const { showModal } = useContext(ModalContext)
  const { share } = props

  const dropdownOptions = useGetTrashedShareDropdownOptions({
    share: props.share,
    version: share.version!,
  })

  const shareWorkspace = useShareWorkspace(share)

  if (dropdownOptions.error || shareWorkspace.error) {
    return <ErrorMessage.Generic />
  }

  if (dropdownOptions.loading || shareWorkspace.loading)
    return (
      <>
        <Dropdown.Skeleton count={2} />
        <Dropdown.Divider />
        <Dropdown.Skeleton />
      </>
    )

  const isUserAdmin = shareWorkspace.workspace?.userRole === 'ADMIN'

  const { download, userAccessLevel } = dropdownOptions.data

  if (!(userAccessLevel !== 'VIEW' || isUserAdmin)) {
    return <ErrorMessage.Generic />
  }

  const shareName = share.name
  const shareIdentifier = share.identifier
  const shareProject = share.project
  const isInDraftsProject = share.project?.projectType === 'PERSONAL_DRAFTS'
  const userCanDeletePermanently = isUserAdmin || isInDraftsProject

  return (
    <>
      <Dropdown.Header>Document</Dropdown.Header>
      {download.isAvailable && (
        <Dropdown.Download href={download.url}>Download</Dropdown.Download>
      )}

      {(canRestoreShare({ userAccessLevel }) || isUserAdmin) && (
        <Dropdown.Item
          onClick={() =>
            showModal(RestoreShareModal, {
              name: shareName,
              shareIdentifier,
              isInDraftsProject,
              project: shareProject,
            })
          }
        >
          Restore&hellip;
        </Dropdown.Item>
      )}

      {userCanDeletePermanently && (
        <>
          <Dropdown.Divider />
          <Dropdown.Item
            intent="negative"
            onClick={() =>
              showModal(DeleteSharePermanentlyModal, {
                name: shareName,
                shareIdentifier,
                workspaceId: shareWorkspace.workspace?.identifier!,
              })
            }
          >
            Delete Permanently&hellip;
          </Dropdown.Item>
        </>
      )}
    </>
  )
}
