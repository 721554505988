import React, { useContext } from 'react'

import {
  Button,
  Caption,
  Banner,
  Modal,
  ModalInjectedProps,
} from '@sketch/components'
import { ToastContext } from '@sketch/toasts'

import { useDisableScimMutation } from '@sketch/gql-types'

interface ConfirmDisableScimModalProps extends ModalInjectedProps {
  workspaceId: string
  onSuccess: () => void
}

export const ConfirmDisableScimModal: React.FC<
  ConfirmDisableScimModalProps
> = ({ workspaceId, onSuccess, hideModal }) => {
  const { showToast } = useContext(ToastContext)
  const [disableScim, { loading }] = useDisableScimMutation({
    variables: {
      identifier: workspaceId,
    },
    onError: 'show-toast',
    onCompleted: () => {
      showToast('SCIM provisioning has been disabled', 'positive')
      hideModal()
      onSuccess()
    },
  })

  return (
    <Modal
      title="Are you sure you want to disable SCIM provisioning?"
      onCancel={hideModal}
    >
      <Modal.Body>
        <Caption>
          After you disable SCIM provisioning, Workspace Members can no longer
          be automatically added or removed based on your IdP&#8217;s settings.
          You can&#8217;t undo this action.
        </Caption>
        <Banner type="warning" showIcon={false}>
          Members can still sign up and join the Workspace via their identity
          provider, using SSO Just-in-Time &#40;JIT&#41; provisioning.
        </Banner>
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={hideModal}
          size="40"
          variant="primary"
          disabled={loading}
        >
          Cancel
        </Button>
        <Button
          onClick={() => disableScim()}
          size="40"
          variant="negative-secondary"
          loading={loading}
          data-testid="scim-disable-confirm-button"
        >
          Disable SCIM
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
