/**
 * Groups an array by a key function and returns a record of arrays
 * @param array The input array to group
 * @param keyFn Function that determines the grouping key for each element
 * @returns Record where keys are the group identifiers and values are arrays of elements
 */
export function groupBy<T, K extends string | number>(
  array: T[],
  keyFn: (value: T) => K
): Record<K, T[]> {
  return array.reduce(
    (grouped, currentValue) => {
      const key = keyFn(currentValue)

      // Initialize the array for this key if it doesn't exist
      if (!grouped[key]) grouped[key] = []

      // Add the current value to its group
      grouped[key].push(currentValue)

      return grouped
    },
    {} as Record<K, T[]>
  )
}
