import styled, { css } from 'styled-components'

import { truncateStyles, Flex, FlexProps } from '@sketch/components'
import { Image as ImageUnstyled } from '@sketch/modules-common'

const SymbolDetail = styled(Flex)<FlexProps & SymbolBoxProps>(
  ({ theme }) => css`
    font-size: ${theme.fontSizes.D};
    color: ${theme.colors.foreground.secondary.C};
    border: 1px solid ${theme.colors.border.A};
    border-radius: 5px;
    ${truncateStyles};
  `
)

interface SymbolBoxProps extends FlexProps {
  isClickable?: boolean
}

export const SymbolBox = styled(SymbolDetail)<SymbolBoxProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px;
  color: ${({ theme }) => theme.colors.foreground.secondary.D};
  cursor: ${props => (props.isClickable ? 'pointer' : 'auto')};
`

export const SymbolContainer = styled(Flex)<FlexProps>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
  color: ${({ theme }) => theme.colors.foreground.secondary.A};
  font-weight: 400;
  line-height: 1.2;
  ${truncateStyles};
`

export const SymbolPath = styled.span(
  ({ theme }) => css`
    color: ${theme.colors.foreground.secondary.D};
    font-size: ${theme.fontSizes.B};
  `
)

export const SymbolThumbnail = styled(ImageUnstyled)`
  display: flex;
  justify-content: center;

  img {
    max-width: 32px;
    max-height: 32px;
  }
`

export const SymbolTruncateTooltip = styled.span`
  word-break: break-all;
`
