import React from 'react'
import { useApolloClient } from 'react-apollo'
import { isEqual } from 'lodash'

import {
  getCachedQueriesByOperationName,
  removeFromPaginated,
} from '@sketch/modules-common'
import { GetAnnotationsQuery } from '@sketch/gql-types'

import useCommentSubscriptions from '../../hooks/useCommentSubscriptions'
import useAnnotationSubscriptions from '../../hooks/useAnnotationSubscriptions'
import useActiveAnnotation from '../../hooks/useActiveAnnotation'

import {
  updateAnnotationsInboxOrder,
  updateCommentsQuery,
  getCacheInputFromAnnotationSubject,
  updateAnnotationsQuery,
  getBaseAnnotation,
} from '../../utils/cache'

import {
  createNewAnnotationInCache,
  doesAnnotationBelongToQueryVariables,
  moveAnnotationInCache,
  resolveAnnotation,
  unResolveAnnotation,
} from '../../utils/annotationCacheOperations'

interface AnnotationSubscriptionsProps {
  shareIdentifier: string
  versionIdentifier: string
}

const AnnotationSubscriptions = (props: AnnotationSubscriptionsProps) => {
  const { shareIdentifier, versionIdentifier } = props

  const apollo = useApolloClient()
  const [activeAnnotationIdentifier, setActiveAnnotation] =
    useActiveAnnotation()

  useAnnotationSubscriptions(shareIdentifier, (type, data) => {
    if (type === 'deleted') {
      /**
       * In order to successfully remove an annotation from the list
       * we need to:
       * [programmatically]
       * - Close the annotation that was deleted if active [1]
       * - Remove the annotation from its annotations listing [2]
       *
       * [fragment BaseAnnotation refetch]
       * - Update the subject annotation counters
       */

      const identifier = data?.annotationDeleted?.identifier

      // [1] Close the annotation that was deleted if active
      if (identifier === activeAnnotationIdentifier) {
        setActiveAnnotation?.()
      }

      // [2] Remove the annotation from its annotations listing
      removeFromPaginated(
        apollo,
        { __typename: 'Annotation', identifier },
        () => true
      )
    } else if (type === 'created') {
      createNewAnnotationInCache(
        apollo,
        { shareIdentifier, versionIdentifier },
        data.annotationCreated
      )
    } else if (type === 'moved') {
      const cachedAnnotation =
        getBaseAnnotation(apollo, data.annotationMoved.identifier) || undefined

      moveAnnotationInCache(
        apollo,
        { shareIdentifier, versionIdentifier },
        data.annotationMoved,
        cachedAnnotation
      )
    } else if (type === 'resolved') {
      resolveAnnotation(
        apollo,
        { shareIdentifier, versionIdentifier },
        data.annotationResolved
      )
    } else if (type === 'unresolved') {
      unResolveAnnotation(
        apollo,
        { shareIdentifier, versionIdentifier },
        data.annotationUnresolved
      )
    }
  })

  useCommentSubscriptions(shareIdentifier, (type, data) => {
    if (type === 'deleted') {
      /**
       * In order to successfully remove a comment from the list
       * we need to:
       * [programmatically]
       * - Remove the comment from its annotation listing [1]
       * - Update the order of the inbox listings [2]
       *
       * [fragment BaseAnnotation refetch]
       * - Update the annotation comment counters
       * - Update the annotation.[hasNewComments, isNew]
       * - Update the annotation.latestCommentCreatedAt
       */
      const { identifier, annotation } = data.annotationReplyDeleted

      // [1] Remove the comment from its annotation listing
      removeFromPaginated(
        apollo,
        { __typename: 'Comment', identifier },
        () => true
      )

      // [2] Update the order of the inbox listings
      const subject = getCacheInputFromAnnotationSubject(annotation)
      if (subject === null) {
        /**
         * If the subject is "UnprocessedSubject", which should never happen (for FE)
         * "getCacheInputFromAnnotationSubject" will return null, because FE can't represent this
         * no need to proceed with the cache operations
         */
        return
      }

      const cachedListOfQueriesToUpdate = getCachedQueriesByOperationName(
        apollo,
        'getAnnotations',
        ({ variables }) =>
          doesAnnotationBelongToQueryVariables(
            { shareIdentifier, versionIdentifier },
            variables,
            subject
          )
      )

      cachedListOfQueriesToUpdate.forEach(({ variables }) => {
        if (
          variables.sort === 'NEW_FIRST' &&
          variables.annotationStatus === 'ACTIVE_ONLY'
        ) {
          updateAnnotationsInboxOrder(apollo, variables, annotation)
        }
      })
    } else if (type === 'created') {
      /**
       * In order to successfully add a comment to the list
       * we need to:
       * [programmatically]
       * - add the comment to its annotation listing [1]
       * - force the annotation to be the first on the inbox listing (because of the new replies) [2]
       *
       * [fragment BaseAnnotation refetch]
       * - Update the annotation comment counters
       * - Update the annotation.[hasNewComments, isNew]
       * - Update the annotation.latestCommentCreatedAt
       */
      const comment = data.annotationReplyCreated
      const { identifier } = comment.annotation

      // [1] Add the comment to its annotation listing
      const variablesComments = { annotationIdentifier: identifier }
      updateCommentsQuery(apollo, variablesComments, data => {
        const { comments } = data.annotation!

        // Validate if a comment with the same identifier already exists
        const doesCommentExist = comments.entries.find(
          ({ identifier }) => comment.identifier === identifier
        )

        if (doesCommentExist) {
          /**
           * If it exists we shouldn't proceed, apollo identifier
           * matching should take care of updating this entity
           */
          return
        }

        data.annotation!.comments.entries.unshift(comment)
        data.annotation!.comments.meta.totalCount++
      })

      // [2] Force the annotation to be the first on the inbox listing (because of the new replies)
      const subject = getCacheInputFromAnnotationSubject(comment.annotation)
      if (subject === null) {
        /**
         * If the subject is "UnprocessedSubject", which should never happen (for FE)
         * "getCacheInputFromAnnotationSubject" will return null, because FE can't represent this
         * no need to proceed with the cache operations
         */
        return
      }

      const cachedListOfQueriesToUpdate = getCachedQueriesByOperationName(
        apollo,
        'getAnnotations',
        ({ variables }) =>
          doesAnnotationBelongToQueryVariables(
            { shareIdentifier, versionIdentifier },
            variables,
            subject
          )
      )

      cachedListOfQueriesToUpdate.forEach(({ variables }) => {
        if (
          variables.sort === 'NEW_FIRST' &&
          variables.annotationStatus === 'ACTIVE_ONLY'
        ) {
          updateAnnotationsQuery(
            apollo,
            variables,
            (data: GetAnnotationsQuery) => {
              const { annotations } = data

              // Remove the annotation from the list if exists
              const annotationToRemoveIndex = annotations.entries.findIndex(
                ({ identifier }) => comment.annotation.identifier === identifier
              )

              if (annotationToRemoveIndex > -1) {
                annotations.entries.splice(annotationToRemoveIndex, 1)
              }

              // Re-add the updated annotation
              annotations.entries.unshift(comment.annotation)
            }
          )
        }
      })
    } else if (type === 'edited') {
      /**
       * In order to successfully update a comment to edited
       * we need to:
       * [fragment Comment refetch]
       * - Update the comment.body
       * - Update the comment.isEdited
       */
    } else if (type === 'marked-as-read') {
      /**
       * In order to successfully mark a comment as read
       * we need to:
       * [programmatically]
       * - force the annotation to be the first after all the read on the inbox listing (because of the new replies) [1]
       *
       * [fragment Comment refetch]
       * - Update the comment.isRead
       * - Update the annotation.[hasNewComments, isNew]
       */

      // [1] Force the annotation to be the first on the inbox listing (because of the new replies)
      const comments = data.commentsMarkedAsRead

      // We try to group by annotation to prevent additional work
      const annotationIdentifiers = new Set<string>()

      comments.forEach(comment => {
        const { annotation } = comment!

        if (annotationIdentifiers.has(annotation.identifier)) {
          // If already exists there it means already was updated
          return
        }

        annotationIdentifiers.add(annotation.identifier)

        const subject = getCacheInputFromAnnotationSubject(annotation)
        if (subject === null) {
          /**
           * If the subject is "UnprocessedSubject", which should never happen (for FE)
           * "getCacheInputFromAnnotationSubject" will return null, because FE can't represent this
           * no need to proceed with the cache operations
           */
          return
        }

        const cachedListOfQueriesToUpdate = getCachedQueriesByOperationName(
          apollo,
          'getAnnotations',
          ({ variables }) =>
            doesAnnotationBelongToQueryVariables(
              { shareIdentifier, versionIdentifier },
              variables,
              subject
            )
        )

        cachedListOfQueriesToUpdate.forEach(({ variables }) => {
          if (
            variables.sort === 'NEW_FIRST' &&
            variables.annotationStatus === 'ACTIVE_ONLY'
          ) {
            updateAnnotationsInboxOrder(apollo, variables, annotation)
          }
        })
      })
    }
  })

  return null
}

export default React.memo(AnnotationSubscriptions, isEqual)
