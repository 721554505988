import { dataIdFromObject } from '@sketch/graphql-cache'
import { handleFetchMore } from '@sketch/components'
import { useCanUseDesignSystemManager } from './useCanUseDesignSystemManager'
import {
  WorkspaceMinimalFragment,
  useGetWorkspaceDesignSystemsQuery,
} from '@sketch/gql-types'

const ENTRIES_PATH = ['workspace', 'designSystems', 'entries']
const AFTER_PATH = ['workspace', 'designSystems', 'meta', 'after']

interface UseGetDesignSystems {
  workspace: WorkspaceMinimalFragment
}

export const useGetDesignSystems = ({ workspace }: UseGetDesignSystems) => {
  const userIsGuest =
    workspace.userRole === 'GUEST' || workspace.userRole === undefined

  const {
    canUseDesignSystemManager,
    loading: canUseDesignSystemManagerLoading,
    error: canUseDesignSystemManagerError,
  } = useCanUseDesignSystemManager({
    workspace,
  })

  const { data, loading, fetchMore, refetch } =
    useGetWorkspaceDesignSystemsQuery({
      variables: { identifier: workspace.identifier },
      skip:
        userIsGuest ||
        !!canUseDesignSystemManagerLoading ||
        !!canUseDesignSystemManagerError ||
        !canUseDesignSystemManager,
    })

  const designSystems = data?.workspace?.designSystems?.entries ?? []
  const after = data?.workspace?.designSystems?.meta.after ?? null

  const reloadList = handleFetchMore(fetchMore, ENTRIES_PATH, {
    dataIdFromObject,
    fetchLatest: true,
    preserveAfter: true,
    afterPath: AFTER_PATH,
  })

  const onLoadMore = handleFetchMore(fetchMore, ENTRIES_PATH, {
    dataIdFromObject,
    after,
  })

  return {
    designSystems,
    after,
    loading,
    reloadList,
    onLoadMore,
    canUseDesignSystemManager,
    userIsGuest,
    refetch,
  }
}
