import { ToastLink } from '@sketch/components'
import { routes } from '@sketch/modules-common'
import React from 'react'
import {
  ProjectFragment,
  WorkspaceMinimalFragment,
  StorageItemFragment,
} from '@sketch/gql-types'
import { ShowToastFn } from '@sketch/toasts'
import type { StorageItemTransferLocation } from './useTransferStorageItem'

export interface MovedProjectToastProps {
  type: 'Document' | 'Folder'
  project: Pick<ProjectFragment, 'identifier' | 'name'>
  workspace: Pick<WorkspaceMinimalFragment, 'identifier' | 'name'>
}

export function MovedToProjectToast(props: MovedProjectToastProps) {
  const { type, project, workspace } = props
  return (
    <>
      {type} moved to &ldquo;
      <ToastLink
        to={routes.WORKSPACE_PROJECT.create({
          projectId: project.identifier,
          workspaceId: workspace.identifier,
        })}
      >
        {project.name}
      </ToastLink>
      &rdquo;
    </>
  )
}

export function RemovedFromProjectToast(props: MovedProjectToastProps) {
  const { type, project, workspace } = props
  return (
    <>
      {type} removed from &quot;
      <ToastLink
        to={routes.WORKSPACE_PROJECT.create({
          projectId: project.identifier,
          workspaceId: workspace.identifier,
        })}
      >
        {project.name}
      </ToastLink>
      &quot;
    </>
  )
}

export interface MovedToWorkspaceToastProps {
  type: 'Document' | 'Folder'
  project?: Pick<ProjectFragment, 'identifier' | 'name'>
  workspace: Pick<WorkspaceMinimalFragment, 'identifier' | 'name'>
}

export function MovedToWorkspaceToast(props: MovedToWorkspaceToastProps) {
  const { type, project, workspace } = props

  if (project) {
    return (
      <span>
        {type} moved to &ldquo;
        <ToastLink
          to={routes.WORKSPACE_PROJECT.create({
            projectId: project.identifier,
            workspaceId: workspace.identifier,
          })}
        >
          {project.name}
        </ToastLink>
        &rdquo; in &ldquo;
        <ToastLink
          to={routes.WORKSPACE_SHARES.create({
            workspaceId: workspace.identifier,
          })}
        >
          {workspace.name}
        </ToastLink>
        &rdquo; Workspace
      </span>
    )
  }

  return (
    <span>
      {type} moved to &ldquo;
      <ToastLink
        to={routes.WORKSPACE_SHARES.create({
          workspaceId: workspace.identifier,
        })}
      >
        {workspace.name}
      </ToastLink>
      &rdquo; Workspace
    </span>
  )
}

export interface ShowMovedSuccessToastArgs {
  location: StorageItemTransferLocation<'name'>
  showToast: ShowToastFn
  item: Pick<StorageItemFragment, '__typename' | 'identifier' | 'name'>
}
export const showMovedSuccessToast = (args: ShowMovedSuccessToastArgs) => {
  const { location, showToast, item } = args
  const type = item.__typename === 'Share' ? 'Document' : 'Folder'
  const { destination, source: current } = location

  if (destination.workspace.identifier !== current.workspace.identifier) {
    showToast(
      <MovedToWorkspaceToast
        project={destination.project}
        workspace={destination.workspace}
        type={type}
      />
    )
    return
  }
  if (!destination.project) {
    if (!current.project) {
      // this should never happen
      showToast(`${type} moved`)
    } else {
      showToast(
        <RemovedFromProjectToast
          workspace={current.workspace}
          project={current.project}
          type={type}
        />
      )
    }
    return
  }

  showToast(
    <MovedToProjectToast
      workspace={destination.workspace}
      project={destination.project}
      type={type}
    />
  )
}
