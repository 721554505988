import React from 'react'
import { LinkButton } from '@sketch/components'
import { ReactComponent as CommentsIcon } from '@sketch/icons/comments-64'

import InfoMessage from '../InfoMessage'
import { getLatestVersionURLFromCurrentLocation } from '../../../versioning/utils'
import { Location } from 'history'

interface VersionRestrictedWarningProps {
  location: Location
}

const VersionRestrictedWarning = (props: VersionRestrictedWarningProps) => {
  const { location } = props
  const latestVersionPath = getLatestVersionURLFromCurrentLocation(location)

  return (
    <>
      <InfoMessage
        title="Go to the latest version to see and add comments on this document."
        icon={CommentsIcon}
      >
        {latestVersionPath && (
          <>
            <br />
            <LinkButton to={latestVersionPath} variant="primary" size="32">
              See Latest Version
            </LinkButton>
          </>
        )}
      </InfoMessage>
    </>
  )
}

export default VersionRestrictedWarning
