import React, { useState } from 'react'

import {
  routes,
  useQueryParams,
  SignInForm,
  SignInFormExtendedFormikProps,
} from '@sketch/modules-common'
import { SKETCH_WEBSITE } from '@sketch/env-config'

import { Link, Panel } from '@sketch/components'

import {
  FormContainer,
  Description,
  PanelFooter,
  StyledButton,
  StyledLinkButton,
  Title,
} from '../SignInToLinkAccountView.styles'

import { SignInMutation } from '@sketch/gql-types'

interface SignInInterfaceProps {
  onCompleted: (data: SignInMutation) => void
  workspaceId: string
}

const SIGN_IN_FORM_ID = 'SignInForm'

export const FormFields: React.FC<
  SignInFormExtendedFormikProps
> = formProps => (
  <>
    <SignInForm.Fields {...formProps} />
    <SignInForm.Errors {...formProps} />
    <input type="submit" id={SIGN_IN_FORM_ID} hidden />
  </>
)

/**
 *
 * Renders the Sign In form in the first step for the SSO Account linking flow
 */
const SignInInterface: React.FC<SignInInterfaceProps> = ({
  workspaceId,
  onCompleted,
}) => {
  const [signInLoading, setSignInLoading] = useState(false)
  const queryParams = useQueryParams<'SSO_LINK_ACCOUNT'>()

  return (
    <>
      <Title>Link to Existing Sketch Account?</Title>
      <Panel.Body>
        <Description>
          Want to quickly switch between Workspaces? Link your single sign-on
          (SSO) account to your other Sketch accounts. &nbsp;
          <Link
            href={`${SKETCH_WEBSITE}/redirect/web/sso`}
            target="_blank"
            rel="noopener noreferrer"
            variant="primary"
            isUnderlined
            external
          >
            Learn more
          </Link>
        </Description>
        <FormContainer>
          <SignInForm onCompleted={onCompleted}>
            {formikBag => {
              setSignInLoading(formikBag.isSubmitting)
              return <FormFields {...formikBag} />
            }}
          </SignInForm>
        </FormContainer>
      </Panel.Body>
      <PanelFooter>
        <StyledLinkButton
          to={routes.SSO_TOS.create({
            workspaceId,
            query: queryParams,
          })}
          variant="secondary"
          disabled={signInLoading}
          size="40"
        >
          Don&apos;t Link
        </StyledLinkButton>
        <StyledButton
          variant="primary"
          loading={signInLoading}
          disabled={signInLoading}
          size="40"
        >
          <label htmlFor={SIGN_IN_FORM_ID}>Next</label>
        </StyledButton>
      </PanelFooter>
    </>
  )
}

export default SignInInterface
