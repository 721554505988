import {
  isServiceWorkerTimeoutError,
  registrationManager,
  ServiceWorkerClient,
} from '../../client'
import {
  AnalyticsContext,
  TelemetryMetric,
  ImageRequestOutcome,
} from '@sketch/modules-common'
import * as Sentry from '@sentry/browser'

import { getSentryExtras } from '../../client/getSentryExtras'
import { ErrorHandler } from '@sketch/tracing'

type RequestOutcomes = NonNullable<
  NonNullable<
    TelemetryMetric<'webapp_sw_image_requests_count'>['labels']
  >['outcome']
>

const reportServiceWorkerMetricsImpl = async (
  analytics: AnalyticsContext,
  client: ServiceWorkerClient,
  callIndex: number
) => {
  const metrics = await client.popMetrics()
  const { trackMetric } = analytics

  const trackEvicted = (
    reason: keyof (typeof metrics)['evicted'],
    value: number
  ) => {
    if (value <= 0) return

    trackMetric({
      id: 'webapp_sw_cached_image_evicted_count',
      value,
      labels: { reason },
    })
  }

  const trackImageRequest = (outcome: RequestOutcomes, value: number) => {
    if (value <= 0) return

    trackMetric({
      id: 'webapp_sw_image_requests_count',
      value,
      labels: { outcome },
    })
  }

  trackEvicted('maxAge', metrics.evicted.maxAge)
  trackEvicted('maxQuota', metrics.evicted.maxQuota)
  trackEvicted('unknown', metrics.evicted.unknown)

  Object.entries(metrics.imagesServed).forEach(([label, value]) => {
    trackImageRequest(label as ImageRequestOutcome, value || 0)
  })

  // TODO: Cleanup additional service worker sentry logging,
  //       https://github.com/sketch-hq/Cloud/issues/11830
  const { failed_network_cors_retry_passed, failed_network } =
    metrics.imagesServed

  if (
    (failed_network || failed_network_cors_retry_passed) &&
    // We are getting sufficiently enough events, actually even more than we need
    // So let's send just every ~10th event to Sentry and save our quota.
    Math.random() <= 0.1
  ) {
    const msg = `[Service worker] Failed to fetch images, reason: failed_network`
    const extras = await getSentryExtras(client)
    Sentry.withScope(scope => {
      scope.setExtras({
        ...extras,
        callIndex,
        failed_network_cors_retry_passed,
        failed_network,
      })
      Sentry.captureMessage(msg)
    })
  }
}

export const reportServiceWorkerMetrics = async (
  analytics: AnalyticsContext,
  callIndex: number
) => {
  let client: ServiceWorkerClient | undefined
  try {
    if (!registrationManager) return
    const client = await registrationManager.getClient()
    if (!client) return
    await reportServiceWorkerMetricsImpl(analytics, client, callIndex)
  } catch (err) {
    if (isServiceWorkerTimeoutError(err, 'metrics')) {
      // if the app is opened for more than 10 minutes
      if (performance.now() >= 1000 * 60 * 10) {
        // It seems that if the app is left idle
        // the browser might shut down the service worker
        // and in return - we'll get timeout errors.

        // To overcome this - simply ignore all timeout reports
        // generated by app older than 10 minutes.

        // If there will be a decrease in reports - we'll be able to notice
        // that using metric dashboards.
        ErrorHandler.ignore(err)
        return
      }
    }
    const extras = await getSentryExtras(client)
    Sentry.withScope(scope => {
      scope.setExtras({ ...extras, callIndex })
      Sentry.captureException(err)
    })
  }
}
