import styled, { css } from 'styled-components'

import { Button, ButtonIconWrapper, Dropdown } from '@sketch/components'

export const NotificationButton = styled(Button)`
  border: none;
  box-shadow: none;
  min-height: 16px;
  padding: 8px 12px;
  line-height: 0;

  /* This gap was added to prevent the sr-only test from having a space along side the icon */
  gap: 0;

  transition:
    100ms background-color linear,
    100ms color linear,
    100ms opacity linear;

  border-radius: ${({ theme }) => theme.radii.large};

  && [role='img'] {
    width: 16px;
    height: 16px;
  }

  background: none;

  ${ButtonIconWrapper} {
    margin: 0;
  }

  ${({ variant }) =>
    variant === 'secondary-untinted' &&
    css`
      && > * {
        opacity: 0.55;
      }
    `}

  ${({ theme, variant }) =>
    variant === 'secondary' &&
    css`
      &:hover {
        background-color: ${theme.colors.sketch.F};
      }
    `}

    &[aria-expanded='true'],
    &[aria-current='true'],
    &.active {
    background-color: ${({ theme }) => theme.colors.sketch.F};

    &:hover {
      background-color: ${({ theme }) => theme.colors.sketch.F};
    }

    [role='img'] {
      color: ${({ theme }) => theme.colors.sketch.A};
      opacity: 1;
    }
  }
`

export const DropdownButton = styled(Dropdown.Item)(
  ({ theme }) => css`
    display: flex;
    align-items: center;

    && [role='img'] {
      width: 16px;
      height: 16px;
      margin-right: 11px; /* stylelint-disable-line scales/space */
    }

    &[aria-checked='true'] {
      color: ${theme.colors.sketch.A};

      &:hover {
        background-color: ${theme.colors.sketch.F};
      }
    }
  `
)
