import React from 'react'
import { NetworkStatus } from 'apollo-client'

import { InlineSidebarError } from '../../../annotations/components'
import { useVersioning } from '../../../versioning'
import { useGetShareSidebarQuery, VersionFragment } from '@sketch/gql-types'
import { isReady, preRenderfarmStatus } from '@sketch/modules-common'

import AboutTabSkeleton from './AboutTabSkeleton'
import AboutTabHeaderSection from './AboutTabHeaderSection'
import AboutTabLinkSection from './AboutTabLinkSection'
import AboutTabExportSection from './AboutTabExportSection'

interface AboutTabProps {
  shareIdentifier: string
}

/**
 * __AboutTab__
 *
 * This component will contain most of the About sidebar logic
 */
const AboutTab: React.FC<AboutTabProps> = ({ shareIdentifier }) => {
  const { isViewingLatestVersion, latestVersionId, ...versioning } =
    useVersioning()

  const currentVersion = !versioning.loading ? versioning.currentVersion : null
  const previewFiles = currentVersion?.document?.previewFile?.thumbnails
  const document = (currentVersion as VersionFragment)?.document!
  const renderStatusFromVersion = currentVersion?.document?.renderStatus

  const { data, error, networkStatus } = useGetShareSidebarQuery({
    variables: { identifier: shareIdentifier, versionShortId: latestVersionId },
    // If this query is made while the document is still being processed for the
    // first time on the Render Farm we'll recieve inaccurate data, so hold the
    // query until the document has been processed
    skip: renderStatusFromVersion !== 'FINISHED',
  })

  // Let's only show the loading state while the query is initially loading,
  // not during the setVariables phase. The query can enter this phase when
  // the document gets updated while the user is looking at it and the latestVersionId
  // value changes - in this case its better to leave the view visible, and not
  // show the skeleton
  const loading = networkStatus < NetworkStatus.setVariables

  if (error) {
    return (
      <>
        <AboutTabSkeleton />
        <InlineSidebarError error={error} />
      </>
    )
  }

  if (loading || !data?.share) {
    return <AboutTabSkeleton />
  }

  const { share } = data

  const { userCanInspect, userCanUpdateSettings, userIsMember, updatedAt } =
    share

  const renderStatus =
    renderStatusFromVersion ||
    preRenderfarmStatus((previewFiles || []).length !== 0, updatedAt as string)

  const isDownloadAssetsVisible =
    document?.downloadAvailable &&
    (userCanInspect || userCanUpdateSettings || userIsMember) &&
    isReady(renderStatus)

  const componentCount = share.version?.document?.componentCount
  const componentsCountTotal =
    (componentCount?.symbol || 0) +
    (componentCount?.textStyle || 0) +
    (componentCount?.layerStyle || 0) +
    (componentCount?.colorVar || 0)

  return (
    <>
      <AboutTabHeaderSection share={share} />
      <AboutTabLinkSection share={share} />
      {currentVersion && isDownloadAssetsVisible && (
        <AboutTabExportSection
          updatedAt={currentVersion.updatedAt!}
          document={currentVersion.document!}
          shareIdentifier={shareIdentifier}
          versionShortId={currentVersion.shortId}
          userAccessLevel={share.userAccessLevel}
          componentsCountTotal={componentsCountTotal}
        />
      )}
    </>
  )
}

export default AboutTab
