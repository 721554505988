import { GraphQLError } from 'graphql'
import {
  getHeaders,
  getConfirmDisableMfaEndpoint,
  getRevokeOAuthEndpoint,
  getSignInEndpoint,
  getSignInWithSsoEndpoint,
  getValidateOneTimePasswordEndpoint,
} from '../utils'
import { formatCredentials } from '../oauth-fetcher'
// eslint-disable-next-line no-restricted-imports
import type { SignInResponse } from '@sketch/gql-types/expansive'
import type {
  SignInMutation,
  SignInWithSsoMutation,
  ValidateMfaRecoveryCodeMutation,
  ValidateMfaTotpMutation,
} from '@sketch/gql-types'
import type {
  MutationResolver,
  LocalResolver,
} from '@sketch/gql-types/resolvers'

type ResponseType =
  | SignInMutation['signIn']['__typename']
  | SignInWithSsoMutation['signInWithSso']['__typename']
  | ValidateMfaTotpMutation['validateMfaTotp']['__typename']
  | ValidateMfaRecoveryCodeMutation['validateMfaRecoveryCode']['__typename']

const SIGN_IN_WITH_SSO_ENDPOINT = getSignInWithSsoEndpoint()
const headers = getHeaders()

const formatOAuthResponseToGraphQL = async (
  responseType: ResponseType,
  response: Response
) => {
  const responseJson = await response.json()

  if (response.status === 200) {
    const credentials = formatCredentials(responseJson)

    // In case of a successful response but the credentials are not returned
    if (
      !credentials.authToken ||
      !credentials.refreshToken ||
      (responseType === 'SignInWithSsoResponse' && !responseJson.organization)
    ) {
      throw new Error('Something went wrong')
    }

    return {
      credentials,
      workspaceId: responseJson.organization,
      createdWorkspace: (responseJson.created_workspace || null) as
        | string
        | null,
    }
  }

  throw new Error(responseJson.message || 'Something went wrong')
}

const formatSignInResponseToGraphQL = async (
  responseType: ResponseType,
  response: Response
): Promise<SignInResponse> => {
  const responseJson = await response.clone().json()

  if (response.status === 401 && responseJson.mfa_token) {
    const { mfa_token } = responseJson

    return {
      __typename: 'MFACredentials',
      mfaToken: mfa_token,
    }
  }

  const { credentials, createdWorkspace } = await formatOAuthResponseToGraphQL(
    responseType,
    response
  )

  return {
    __typename: 'SignInCredentials',
    credentials,
    createdWorkspace,
  }
}

const formatValidateOneTimePasswordResponseToGraphQL = async (
  response: Response
) => {
  const responseJson = await response.json()

  if (response.ok) {
    const credentials = formatCredentials(responseJson)

    // In case of a successful response but the credentials are not returned
    if (!credentials.authToken || !credentials.refreshToken) {
      throw new Error('Something went wrong')
    }

    return {
      credentials,
      workspaceId: responseJson.organization,
      createdWorkspace: (responseJson.created_workspace || null) as
        | string
        | null,
    }
  }

  throw new GraphQLError(responseJson)
}

const signIn: MutationResolver<'signIn'> = async (
  _,
  { email, password, createWorkspace }
) => {
  const response = await fetch(getSignInEndpoint(), {
    method: 'post',
    body: JSON.stringify({
      email,
      password,
      grant_type: 'password',
      create_workspace: createWorkspace,
    }),
    headers,
  })

  const graphQLResponse = await formatSignInResponseToGraphQL(
    'MFACredentials',
    response
  )

  return graphQLResponse
}

const signInWithSso: MutationResolver<'signInWithSso'> = async (
  _,
  { exchangeToken }
) => {
  const response = await fetch(SIGN_IN_WITH_SSO_ENDPOINT, {
    method: 'post',
    body: JSON.stringify({ token: exchangeToken }),
    headers,
  })

  const graphQLResponse = await formatOAuthResponseToGraphQL(
    'SignInWithSsoResponse',
    response
  )

  return {
    __typename: 'SignInWithSsoResponse',
    ...graphQLResponse,
  }
}

const revokeOAuth: MutationResolver<'revokeOAuth'> = async (_, { token }) => {
  const response = await fetch(getRevokeOAuthEndpoint(), {
    method: 'post',
    body: JSON.stringify({ token }),
    headers,
  })

  if (response.status === 200) {
    return {
      __typename: 'RevokeOAuthResponse',
      successful: true,
    }
  }

  throw new Error('Something went wrong')
}

const validateMfaTotp: MutationResolver<'validateMfaTotp'> = async (
  _,
  { token, totp, createWorkspace }
) => {
  const response = await fetch(getValidateOneTimePasswordEndpoint(), {
    method: 'post',
    body: JSON.stringify({
      mfa_token: token,
      totp,
      grant_type: 'mfa',
      create_workspace: createWorkspace,
    }),
    headers,
  })

  const graphQLResponse =
    await formatValidateOneTimePasswordResponseToGraphQL(response)

  return {
    __typename: 'ValidateMFAResponse',
    ...graphQLResponse,
  }
}

const validateMfaRecoveryCode: MutationResolver<
  'validateMfaRecoveryCode'
> = async (_, { token, recoveryCode, createWorkspace }) => {
  const response = await fetch(getValidateOneTimePasswordEndpoint(), {
    method: 'post',
    body: JSON.stringify({
      mfa_token: token,
      recovery_code: recoveryCode,
      grant_type: 'mfa',
      create_workspace: createWorkspace,
    }),
    headers,
  })

  const graphQLResponse =
    await formatValidateOneTimePasswordResponseToGraphQL(response)

  return {
    __typename: 'ValidateMFAResponse',
    ...graphQLResponse,
  }
}

const signInAndDisableMfa: MutationResolver<'signInAndDisableMfa'> = async (
  _,
  { email, password, disableMfaToken }
) => {
  const response = await fetch(getConfirmDisableMfaEndpoint(), {
    method: 'post',
    body: JSON.stringify({
      email,
      password,
      disable_mfa_token: disableMfaToken,
      grant_type: 'password',
    }),
    headers,
  })

  const graphQLResponse = await formatOAuthResponseToGraphQL(
    'SignInCredentials',
    response
  )

  return {
    __typename: 'SignInAndDisableMfaResponse',
    credentials: graphQLResponse.credentials,
  }
}

export const Mutation: LocalResolver<'Mutation'> = {
  signIn,
  signInWithSso,
  revokeOAuth,
  validateMfaTotp,
  validateMfaRecoveryCode,
  signInAndDisableMfa,
}
