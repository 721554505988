import React from 'react'
import {
  Container,
  BreadcrumbsTrigger,
  DropdownItem,
  IconContainer,
  Ellipsis,
  TooltipItem,
  TooltipContent,
} from './ProjectsDropdown.styles'
import { Dropdown, Tooltip } from '@sketch/components'
import { Link } from 'react-router-dom'
import { routes } from '@sketch/modules-common'
import { ProjectFragment } from '@sketch/gql-types'
import { ProjectIcon } from '../../../projects/components/ProjectIcon'

export interface ProjectsDropdownProps {
  projects: Pick<ProjectFragment, 'identifier' | 'name'>[]
  workspaceId: string
}

export const ProjectsDropdown = (props: ProjectsDropdownProps) => {
  const { projects, workspaceId } = props
  const [dropdownVisible, setDropdownVisible] = React.useState(false)

  return (
    <Container>
      <Dropdown
        placement="bottom-start"
        onToggle={() => setDropdownVisible(!dropdownVisible)}
        visible={dropdownVisible}
        toggle={
          <Tooltip
            disabled={dropdownVisible}
            placement="bottom-start"
            content={
              <TooltipContent>
                {projects.map((project, index) => (
                  <TooltipItem depth={index} key={project.identifier}>
                    <IconContainer>
                      <ProjectIcon project={project} isOpen={true} />
                    </IconContainer>
                    {project.name}
                  </TooltipItem>
                ))}
              </TooltipContent>
            }
          >
            <BreadcrumbsTrigger>
              <Ellipsis />
            </BreadcrumbsTrigger>
          </Tooltip>
        }
      >
        {projects.map((project, index) => (
          <Link
            key={project.identifier}
            to={routes.WORKSPACE_PROJECT.create({
              workspaceId,
              projectId: project.identifier,
            })}
          >
            <DropdownItem depth={index}>
              <IconContainer>
                <ProjectIcon project={project} isOpen={true} />
              </IconContainer>
              {project.name}
            </DropdownItem>
          </Link>
        ))}
      </Dropdown>
    </Container>
  )
}
