import { useCallback } from 'react'
import { useHistory, useParams } from 'react-router-dom'

import { useVersioning } from '../../versioning'
import { useAnalytics, RouteParams, useFlag } from '@sketch/modules-common'

// TODO: Rename this component to be consistent with FrameGroup
// https://linear.app/sketch/issue/SWEB-500/rename-common-components-between-artboards-and-frames-to

type GoToArtboardEventActionType = 'keyPressEnter' | 'doubleClick' | 'CTA'

/**
 * Returns an onGoToArtboard callback.
 */
export function useHandleArtboardsInteractions() {
  const history = useHistory()
  const getArtboardViewUrlFromUUID = useGetArtboardViewUrlFromUUID()
  const { trackEvent } = useAnalytics()

  const onGoToArtboard = useCallback(
    (uuid: string, actionType: GoToArtboardEventActionType) => {
      trackEvent('CANVAS - open artboard', { actionType })
      history.push(getArtboardViewUrlFromUUID(uuid))
    },
    [trackEvent, history, getArtboardViewUrlFromUUID]
  )

  return {
    onGoToArtboard,
  }
}

/**
 * Provide a function that takes an artboard UUID and builds the URL
 * to access the artboard.
 */
function useGetArtboardViewUrlFromUUID() {
  const isFramesWebOn = useFlag('frames-web')

  const { getPathname } = useVersioning()
  const { shareID } = useParams<RouteParams<'SHARE_PAGE_CANVAS_VIEW'>>()

  const getArtboardUrlFromUUID = useCallback(
    (frameGroupUUID: string) => {
      if (isFramesWebOn) {
        return getPathname({
          routeKey: 'FRAME',
          routeParams: {
            shareID: shareID,
            frameUUID: frameGroupUUID,
          },
        })
      } else {
        return getPathname({
          routeKey: 'ARTBOARD_DETAIL_UUID',
          routeParams: {
            shareID: shareID,
            artboardUUID: frameGroupUUID,
          },
        })
      }
    },
    [isFramesWebOn, getPathname, shareID]
  )

  return getArtboardUrlFromUUID
}
