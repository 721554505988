import React, { useEffect, useState, useCallback, useRef, useMemo } from 'react'

import { useBreakpoint } from '../../Breakpoint'
import { Breakpoints } from '@sketch/global-styles'
import { ModalContainer } from '../ModalContainer'
import { DropdownContainer } from '../DropdownContainer'
import { createPopperModifiers, CreatePopperModifiersParams } from '../utils'
import { State } from '@popperjs/core'
import { useIsMountedRef } from '@sketch/utils'
import { usePopper } from './usePopper'
import { useDropdownIdentifiers } from './useDropdownIdentifiers'
import { useDropdownKeyEvents } from './useDropdownKeyEvents'

import type { HideTrigger, BaseResponsiveDropdownProps } from '../types'

const DEFAULT_HIDE_TRIGGERS: HideTrigger[] = [
  'clickedOutsideContent',
  'clickedInsideContent',
  'outsideViewport',
  'modalHide',
  'pressedEscape',
  'mouseLeave',
]

export type UseResponsiveDropdownProps<T extends BaseResponsiveDropdownProps> =
  CreatePopperModifiersParams & {
    dropdown: React.ComponentType<T>
    dropdownProps?: OmitSafe<T, keyof BaseResponsiveDropdownProps>
    dropdownBreakpoint?: Breakpoints
    useModalRoot?: boolean
    usePortal?: boolean
    hide?: HideTrigger[]
    onHide?: (trigger: HideTrigger) => void
    dropdownStyle?: React.CSSProperties
    modalStyle?: React.CSSProperties
    hideCancel?: boolean
    showOnHover?: boolean
  }

export interface TriggerProps
  extends Omit<Partial<React.HTMLAttributes<HTMLElement>>, 'children'> {
  /* This ref is "any" for a specific reason please check line 212  */
  ref?: React.Ref<any>
}

/**
 * useResponsiveDropdown
 *
 * This hook contains all the logic needed to allow a dropdown content
 * to be rendered either in a modal or dropdown way.
 *
 * Unlike the Dropdown component this one allows the trigger
 * to be connected using the props returned by this hook
 * which removes the unnecessary wrapper elements with additional refs
 */
export const useResponsiveDropdown = <T extends {}>({
  dropdown: Dropdown,
  dropdownBreakpoint = 'sm',
  placement,
  usePortal,
  useModalRoot,
  hide = DEFAULT_HIDE_TRIGGERS,
  offset,
  modifiers,
  onHide,
  modalStyle,
  dropdownStyle,
  dropdownProps,
  hideCancel = false,
  showOnHover = false,
}: UseResponsiveDropdownProps<T & BaseResponsiveDropdownProps>) => {
  /**
   * Initialization of the basic dropdown state
   */
  const triggerRef = useRef<HTMLElement | unknown>(null)
  const [triggerId, dropdownId] = useDropdownIdentifiers()
  const isComponentMounted = useIsMountedRef()

  const [visible, setVisible] = useState(false)
  const [firstUpdate, setFirstUpdate] = useState(false)
  const [forcePortal, setForcePortal] = useState(false)
  const [openedByKeyboard, setOpenedByKeyboard] = useState(false)
  const [popperElement, setPopperElement] = React.useState<HTMLElement | null>(
    null
  )
  const [hidden, setHidden] = useState(false)

  // Use ref as we need to be able to check in real time the state is hovering tooltip state
  // directly from event handlers.
  const isHoveringTooltipAreaRef = useRef(false)

  const timeout = useRef<ReturnType<typeof setTimeout> | null>(null)
  const isMobile = !useBreakpoint('xs')
  /**
   * We can't validate the final type of the ref passed
   * outside of the function. Therefore we can assume that is either a
   * HTMLElement or an unknown type. The next validation ensures we are using
   * a instance of a HTMLElement otherwise it's undefined
   */
  const triggerElement =
    triggerRef.current instanceof HTMLElement ? triggerRef.current : undefined

  const preventiveUsePortal = usePortal || false

  /**
   * Save when the popper first updates
   */
  const onFirstUpdate = useCallback(
    (state: Partial<State>) => {
      if (usePortal !== undefined) {
        return
      }

      /**
       * The following logic has been implemented to
       * prevent lists that have max-height/max-width + overflow from
       * trapping the popover or causing additional overflow when it shouldn't
       *
       * if that's the case the popover should be rendered in a portal
       *
       * ticket:
       * https://github.com/sketch-hq/Cloud/issues/13734
       */
      const popperBounds = state.elements?.popper.getClientRects()[0]
      const scrollParent = state.scrollParents?.reference[0]

      /**
       * We basically calculate the popover position + the scroll parents position
       * subtract them and this should give us the remaining available space to render
       * the popover
       *
       * if that remaining visual space is bigger than the available one
       * we force the portal
       */
      if (scrollParent instanceof Element && popperBounds) {
        const scrollParentBounds = scrollParent.getClientRects()[0]

        const deltaX =
          popperBounds.x - scrollParentBounds.x + popperBounds.width
        const deltaY =
          popperBounds.y - scrollParentBounds.y + popperBounds.height

        const fitX = deltaX < scrollParentBounds.width
        const fitY = deltaY < scrollParentBounds.height

        if (!fitX || !fitY) {
          setForcePortal(true)
        }
      }

      setFirstUpdate(true)
    },
    [usePortal]
  )

  const popperOptions = useMemo(
    () =>
      createPopperModifiers({ placement, offset, modifiers, onFirstUpdate }),
    [placement, offset, modifiers, onFirstUpdate]
  )

  const { styles, attributes, update } = usePopper(
    triggerElement,
    popperElement,
    popperOptions
  )

  const onHideRef = useRef(onHide)
  useEffect(() => {
    onHideRef.current = onHide
  }, [onHide])

  // When component unmounts, restart visibility
  useEffect(() => {
    return () => {
      setHidden(false)
    }
  }, [])

  const shouldRenderDropdown = useBreakpoint(dropdownBreakpoint)

  const onHideDropdown = useCallback(
    (trigger: HideTrigger, target?: Element | undefined) => {
      // If we passed unmountafterclick, we want the modal to unmount as usual instead of opacity 0
      // We search for the closest element with the data-unmountafterclick attribute
      // to check if we should unmount the modal after a click even if we click elements
      // that are children of the Item button
      // Example "ZoomDropdown" has 2 spans children of the button.
      const unmountAfterClick = target?.closest(
        '[data-unmountafterclick="true"]'
      )

      if (
        (!shouldRenderDropdown || target?.getAttribute('data-hassublevels')) &&
        trigger === 'clickedInsideContent' &&
        !unmountAfterClick
      ) {
        // make if opacity 0 instead removing the element altogether
        setHidden(true)
        return
      }

      isComponentMounted.current && setVisible(false)
      onHideRef.current?.(trigger)
    },
    [setVisible, isComponentMounted, shouldRenderDropdown]
  )

  const currentVariant = shouldRenderDropdown ? 'popover' : 'modal'
  const isUsingPortal = preventiveUsePortal || forcePortal

  /**
   * Add the keyboard + click events for the dropdown and the trigger
   */
  useDropdownKeyEvents({
    triggerRef,
    dropdownElement: popperElement,
    visible,
    openedByKeyboard,
    setOpenedByKeyboard,
    hide,
    onHide: onHideDropdown,
    isPortal: isUsingPortal,
  })

  const hideWhenOutsideWindow = hide.includes('outsideViewport')

  /**
   * "Render" of the dropdown content since it's going to be common
   * between the each representation
   */
  const innerContent = React.createElement(Dropdown, {
    ...(dropdownProps || {}),
    currentVariant,
    hide: onHideDropdown,
  } as T & BaseResponsiveDropdownProps)

  const contentAreaProps = {
    id: dropdownId,
    role: 'menu',
    'aria-owns': triggerId,
    'aria-label': document.getElementById(triggerId)?.textContent || undefined,
  }

  const handleOnMouseEnterTooltipArea = () => {
    isHoveringTooltipAreaRef.current = true

    if (timeout.current !== null) {
      clearTimeout(timeout.current)
    }
  }

  const handleOnMouseLeaveTooltipArea = () => {
    isHoveringTooltipAreaRef.current = false
    setVisible(false)
  }

  let content = null
  if (shouldRenderDropdown) {
    const hoverHandlers = showOnHover
      ? {
          onMouseEnter: handleOnMouseEnterTooltipArea,
          onMouseLeave: handleOnMouseLeaveTooltipArea,
        }
      : {}

    content = (
      <DropdownContainer
        ref={setPopperElement}
        onOutsideWindowHide={() => {
          // We should only hide if the popper "firstUpdate"
          // has been executed
          firstUpdate && onHideRef.current?.('outsideViewport')
        }}
        dropdownStyle={dropdownStyle}
        style={styles?.popper}
        hideWhenOutsideWindow={hideWhenOutsideWindow}
        visible={visible}
        portal={isUsingPortal}
        {...hoverHandlers}
        {...contentAreaProps}
        {...attributes?.popper}
      >
        {innerContent}
      </DropdownContainer>
    )
  } else {
    content = (
      <ModalContainer
        onHide={event => {
          event?.stopPropagation()
          onHideDropdown('modalHide')
        }}
        visible={visible}
        ref={setPopperElement}
        style={{ ...modalStyle, opacity: hidden ? 0 : undefined }}
        useModalRoot={useModalRoot}
        hideCancel={hideCancel}
        {...contentAreaProps}
      >
        {innerContent}
      </ModalContainer>
    )
  }

  /**
   * Return of the trigger props and dropdown UI so it can be
   * rendered together
   */
  const triggerProps: TriggerProps = {
    'aria-haspopup': 'menu',
    'aria-expanded': visible,
    'aria-controls': dropdownId,
    role: 'button',
    id: triggerId,
    ref: triggerRef,
    onKeyDown: async event => {
      // In addition to Enter or Space, we want to open the dropdown
      // when the arrow down is pressed
      if (event.key === 'ArrowDown') {
        await update?.()
        setVisible(true)
      }

      // Change state to highlight that dropdown was opened with keyboard,
      // this will trigger the focus on the first dropdown item
      if (['Enter', ' ', 'ArrowDown'].includes(event.key)) {
        // A 0 setTimeout is needed to make sure the dropdown content is ready
        setTimeout(() => {
          setOpenedByKeyboard(true)
        }, 0)
      }
    },
    onClick: useCallback(
      async event => {
        // Stop propagating the event because the dropdown might be included
        // in link parents
        if (event.currentTarget.nodeName === 'BUTTON') {
          event.preventDefault()
        }

        await update?.()

        // if it's going to become visible and we previously had set the modal as hidden, we need to reset
        if (!visible && hidden) {
          setHidden(false)
        }

        setVisible(state => !state)
        onHideRef.current?.('clickTrigger')
      },
      [update, hidden, visible]
    ),
    onMouseEnter: useCallback(
      async event => {
        if (!showOnHover || isMobile) return

        if (event.currentTarget.nodeName === 'BUTTON') {
          // Stop propagating the event because the dropdown might be included
          // in link parents
          event.preventDefault()
        }

        await update?.()
        setVisible(true)
      },
      [update, showOnHover, isMobile]
    ),
    onMouseLeave: useCallback(
      async event => {
        if (!showOnHover || isMobile) return

        // Stop propagating the event because the dropdown might be included
        // in link parents
        if (event.currentTarget.nodeName === 'BUTTON') {
          event.preventDefault()
        }

        await update?.()

        // setTimeout so the user can actually reach the options section
        timeout.current = setTimeout(() => {
          if (isHoveringTooltipAreaRef.current === true) {
            // Sometimes the mouse leave event on the dropdown trigger element is
            // sent after the mouse enter event on the tooltip. In that scenario
            // handleOnMouseEnterTooltipArea will never be called to stop the timeout
            // which would wrongly close the tooltip after the timeout.
            // We can instead ignore the trigger mouse leave event if we are already
            // hovering the tooltip by that time.
            return
          }
          setVisible(false)
        }, 150)
        onHideRef.current?.('mouseLeave')
      },
      [update, showOnHover, timeout, isMobile]
    ),
  }

  return [content, triggerProps, { visible, setVisible, update }] as const
}
