import { routes } from '@sketch/modules-common'

const workspaceSidebarLayoutRoutes = {
  WORKSPACE_DISCOVER: routes.WORKSPACE_DISCOVER.template(),
  WORKSPACE_PROJECT: routes.WORKSPACE_PROJECT.template(),
  WORKSPACE_SHARES: routes.WORKSPACE_SHARES.template(),
  WORKSPACE_LIBRARIES: routes.WORKSPACE_LIBRARIES.template(),
  WORKSPACE_TRASH: routes.WORKSPACE_TRASH.template(),
  WORKSPACE_DRAFTS: routes.WORKSPACE_DRAFTS.template(),
  WORKSPACE_SHARED_WITH_ME: routes.WORKSPACE_SHARED_WITH_ME.template(),
  WORKSPACE_TRASH_PROJECT: routes.WORKSPACE_TRASH_PROJECT.template(),
  WORKSPACE_COLLECTION: routes.WORKSPACE_COLLECTION.template(),
  WORKSPACE_TRASH_COLLECTION: routes.WORKSPACE_TRASH_COLLECTION.template(),
  WORKSPACE_TEMPLATES: routes.WORKSPACE_TEMPLATES.template(),
  WORKSPACE_PROFILE_EDIT: routes.WORKSPACE_PROFILE_EDIT.template(),
  WORKSPACE_ARCHIVE: routes.WORKSPACE_ARCHIVE.template(),
  WORKSPACE_SETTINGS: routes.WORKSPACE_SETTINGS.template(),
  UPDATES: routes.UPDATES.template(),
  UPDATES_READ: routes.UPDATES_READ.template(),
  PERSONAL_SETTINGS: routes.PERSONAL_SETTINGS.template(),
  PERSONAL_SETTINGS_UNSUBSCRIBE:
    routes.PERSONAL_SETTINGS_UNSUBSCRIBE.template(),
  PERSONAL_SETTINGS_ACCESS_TOKENS:
    routes.PERSONAL_SETTINGS_ACCESS_TOKENS.template(),
  PERSONAL_SETTINGS_ACCESS_TOKENS_CREATE:
    routes.PERSONAL_SETTINGS_ACCESS_TOKENS_CREATE.template(),
  APPEARANCE_SETTINGS: routes.APPEARANCE_SETTINGS.template(),
} as const

const workspaceSidebarLayoutRoutesSwitch = Object.fromEntries(
  Object.entries(workspaceSidebarLayoutRoutes).filter(([key]) =>
    key.includes('WORKSPACE_')
  )
)

export const workspaceSidebarLayoutRoutePaths = Object.values(
  workspaceSidebarLayoutRoutes
)

export const workspaceSidebarLayoutRoutesSwitchPaths = Object.values(
  workspaceSidebarLayoutRoutesSwitch
)
