import React, { FC } from 'react'
import { RouteParams } from '@sketch/modules-common'

import {
  useVersioning,
  isUpgradeToLatestNeeded,
  UpgradeToLatestVersion,
} from '../../../../versioning'
import { useGetSurroundingFramesQuery } from '@sketch/gql-types'
import { PaginationControls } from '@sketch/components'
import { useHistory, useLocation, useRouteMatch } from 'react-router'

interface LocationState {
  fromOverview?: boolean
}

interface FramePaginationControlsProps {
  current: number
  total: number
  showNumbers?: boolean
}

/**
 * Navigate between frames using keyboard left/right arrows
 */
export const FramePaginationControls: FC<FramePaginationControlsProps> = ({
  current,
  total,
  ...rest
}) => {
  const match = useRouteMatch<RouteParams<'FRAME'>>()
  const location = useLocation<LocationState>()
  const history = useHistory()

  const {
    versionShortId,
    getPathname,
    setArtboardThumbnail,
    artboardThumbnail,
  } = useVersioning()
  const { shareID, frameUUID } = match.params

  const fromOverview = location.state?.fromOverview

  const navigateTo = (pathname: string) =>
    history.push({
      pathname: pathname,
      state: { fromOverview },
    })

  const { data: frameData, error } = useGetSurroundingFramesQuery({
    variables: {
      frameUUID,
      documentVersionShortId: versionShortId,
      shareIdentifier: shareID,
    },
  })

  if (isUpgradeToLatestNeeded(error)) {
    return <UpgradeToLatestVersion error={error} />
  }

  const nextFrameUUID =
    frameData?.frame?.__typename === 'Frame' && frameData?.frame.next?.uuid

  const previousFrameUUID =
    frameData?.frame?.__typename === 'Frame' && frameData?.frame.previous?.uuid

  const currentPage = current ? current + 1 : 1

  const clearThumbnail = () =>
    artboardThumbnail !== '' && setArtboardThumbnail('')

  const handleClickNext = nextFrameUUID
    ? () => {
        const nextPathname = getPathname({
          routeKey: 'FRAME',
          routeParams: {
            shareID: shareID,
            frameUUID: nextFrameUUID,
          },
        })

        clearThumbnail()
        navigateTo(nextPathname)
      }
    : undefined

  const handleClickPrevious = previousFrameUUID
    ? () => {
        const previousPathname = getPathname({
          routeKey: 'FRAME',
          routeParams: {
            shareID: shareID,
            frameUUID: previousFrameUUID,
          },
        })
        clearThumbnail()
        navigateTo(previousPathname)
      }
    : undefined

  return (
    <PaginationControls
      current={currentPage}
      total={total}
      onClickNext={handleClickNext}
      onClickPrevious={handleClickPrevious}
      onlyKeyboardNavigation={true}
      {...rest}
    />
  )
}
