import React from 'react'

import {
  ToggleWrapper,
  IconWrapper,
  StyledChevron,
  RemoveFromInviteItem,
} from './RoleDropdown.styles'
import {
  Dropdown,
  Checkbox,
  FlatButton,
  RadioButton,
  useResponsiveDropdown,
  BaseResponsiveDropdownProps,
} from '@sketch/components'
import MemberBadge from '../MemberBadge/MemberBadge'
import { Modifier } from '@popperjs/core'

const POPOVER_MODIFIERS: Partial<Modifier<any, any>>[] = [
  /**
   * This modifier will allow the popover to be the same
   * width as the reference element
   */
  {
    name: 'forceScrollIfMaxHeight',
    phase: 'beforeWrite' as const,
    enabled: true,
    fn({ state }) {
      const { popper, reference } = state.elements || {}

      if (!(reference && reference instanceof HTMLElement)) {
        return
      }

      const { top, height } = reference.getBoundingClientRect()
      popper.style.maxHeight = `${window.innerHeight - (top + height + 20)}px`
      popper.style.height = '100%'
    },
  },
]

const DROPDOWN_WRAPPER_STYLE = {
  maxWidth: '266px',
  maxHeight: '100%',
  overflow: 'auto',
} as const

interface RoleDropdownProps {
  className?: string
  isEditor: boolean
  isAdmin: boolean
  isOwner?: boolean
  isRemovable?: boolean
  isDisabled?: boolean
  /** Disables the Editor Radio button */
  isEditorDisabled?: boolean
  /** Control badges visibility */
  showBadges?: boolean
  /** Controll admin checkbox visibility */
  showAdminToggle?: boolean
  /** To use within an input field */
  isEmbedded?: boolean
  onEditor?: () => void
  onViewer?: () => void
  onToggleAdmin?: () => void
  onRemove?: () => void
}

interface RoleDropdownContentProps extends BaseResponsiveDropdownProps {
  isEditor: boolean
  isAdmin: boolean
  isOwner?: boolean
  isRemovable?: boolean
  /** Disables the Editor Radio button */
  isEditorDisabled?: boolean
  /** Controll admin checkbox visibility */
  showAdminToggle?: boolean
  onEditor?: () => void
  onViewer?: () => void
  onToggleAdmin?: () => void
  onRemove?: () => void
}

const getToggleCopy = (isEditor: boolean, isEmbeded?: boolean) => {
  const viewerOrEditor = isEditor ? 'Editor' : 'Viewer'

  return isEmbeded ? `Add as ${viewerOrEditor}` : viewerOrEditor
}

const RoleDropdownContent = (props: RoleDropdownContentProps) => {
  const {
    onViewer,
    onEditor,
    onToggleAdmin,
    onRemove,
    isEditor,
    isAdmin,
    isRemovable,
    showAdminToggle,
    isEditorDisabled,
    hide,
  } = props

  const handleEditor = () => {
    hide()
    onEditor?.()
  }

  const handleViewer = () => {
    hide()
    onViewer?.()
  }

  const handleAdminToggle = () => {
    hide()
    onToggleAdmin?.()
  }

  const handleRemove = () => {
    // Dropdown options that are buttons, close the dropdown by default
    // so we don't need to "hide()"
    onRemove?.()
  }

  return (
    <>
      <Dropdown.Header>Access</Dropdown.Header>
      <Dropdown.Item className={!isEditor ? 'active' : ''}>
        <Dropdown.ItemDescription>
          <RadioButton
            checked={!isEditor}
            onChange={handleViewer}
            name="selected"
            label="Viewer"
            help="Browse, inspect and comment on documents in the web app only"
          />
        </Dropdown.ItemDescription>
      </Dropdown.Item>
      <Dropdown.Item className={isEditor ? 'active' : ''}>
        <Dropdown.ItemDescription>
          <RadioButton
            checked={isEditor}
            onChange={handleEditor}
            name="selected"
            label="Editor"
            help="All of the above, and create and edit documents in the Mac app"
            disabled={isEditorDisabled}
          />
        </Dropdown.ItemDescription>
      </Dropdown.Item>

      {showAdminToggle && (
        <>
          <Dropdown.Divider />
          <Dropdown.Header>Role</Dropdown.Header>
          <Dropdown.Item onClick={handleAdminToggle}>
            <Dropdown.ItemDescription>
              <Checkbox
                checked={isAdmin}
                onChange={handleAdminToggle}
                alt="checkbox"
                label={isAdmin ? 'Remove as Admin' : 'Admin'}
                help={
                  isAdmin
                    ? 'Can no longer manage the Workspace, add or remove members, and edit roles'
                    : 'Can manage the Workspace, add or remove members, and edit roles'
                }
              />
            </Dropdown.ItemDescription>
          </Dropdown.Item>
        </>
      )}

      {isRemovable && (
        <>
          <Dropdown.Divider />
          <RemoveFromInviteItem onClick={handleRemove}>
            <FlatButton variant="negative" size="40">
              Remove from Invites…
            </FlatButton>
          </RemoveFromInviteItem>
        </>
      )}
    </>
  )
}

/**
 * RoleDropdown
 *
 * Renders a button to:
 * * Select a user role (Editor / Viewer)
 * * Remove a user
 */
export const RoleDropdown: React.FC<RoleDropdownProps> = props => {
  const [content, buttonProps] = useResponsiveDropdown({
    dropdown: RoleDropdownContent,
    dropdownProps: props,
    placement: 'bottom-end',
    dropdownStyle: DROPDOWN_WRAPPER_STYLE,
    usePortal: true,
    modifiers: POPOVER_MODIFIERS,
  })

  const { isEditor, isAdmin, isOwner, isDisabled, showBadges, isEmbedded } =
    props

  const toggleCopy = getToggleCopy(isEditor, isEmbedded)

  return (
    <>
      <ToggleWrapper
        type="button"
        disabled={isDisabled}
        $isEmbedded={isEmbedded}
        {...buttonProps}
      >
        {toggleCopy}
        {showBadges && (
          <>
            {isOwner && <MemberBadge badge="owner" />}
            {isAdmin && <MemberBadge badge="admin" />}
          </>
        )}

        <IconWrapper>
          <StyledChevron />
        </IconWrapper>
      </ToggleWrapper>

      {content}
    </>
  )
}
