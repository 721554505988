import React, { FC } from 'react'
import { Formik } from 'formik'
import * as yup from 'yup'
import { useHistory, useLocation } from 'react-router-dom'

import { routes, useSetUserAuthorization } from '@sketch/modules-common'

import { Button, Form, Input } from '@sketch/components'

// GQL
import {
  useValidateMfaRecoveryCodeMutation,
  ValidateMfaRecoveryCodeMutation,
} from '@sketch/gql-types'

import { useToast } from '@sketch/toasts'
import { isPricingSourceRoute } from '../../../../utils'

interface MFAError {
  code: string
  message: string
  type: 'wrong_mfa_verification_code' | 'invalid_mfa_token'
}

interface LocationState {
  mfaToken: string
}

interface RecoveryCodeFormProps {
  onCompleted: (data: ValidateMfaRecoveryCodeMutation) => void
}

// Form validation
const validationSchema = yup.object().shape({
  code: yup.string().required('Enter a recovery code'),
})

const RecoveryCodeForm: FC<RecoveryCodeFormProps> = ({ onCompleted }) => {
  const history = useHistory()
  const location = useLocation<LocationState>()

  const { showToast } = useToast()

  const setUserAuthorization = useSetUserAuthorization()

  const [validateRecoveryCode, { loading }] =
    useValidateMfaRecoveryCodeMutation({
      onCompleted: data => {
        setUserAuthorization(data.validateMfaRecoveryCode.credentials)
        onCompleted(data)
      },
      onError: error => {
        // TODO: Improve returned error types from local resolvers
        // https://github.com/sketch-hq/Cloud/issues/11366
        const mfaError = error.message as unknown as MFAError

        showToast(mfaError.message, 'negative')

        if (mfaError.type === 'invalid_mfa_token') {
          history.push(routes.SIGN_IN.create({}), undefined)
        }
      },
    })

  const handleSubmit = (recoveryCode: string) => {
    validateRecoveryCode({
      variables: {
        token: location.state.mfaToken,
        recoveryCode,
        ...(isPricingSourceRoute(location) ? { createWorkspace: true } : {}),
      },
    })
  }

  return (
    <Formik
      initialValues={{ code: '' }}
      onSubmit={({ code }) => handleSubmit(code)}
      validationSchema={validationSchema}
    >
      {({ values, touched, errors, handleChange, handleBlur }) => (
        <Form>
          <Form.Field
            name="code"
            errorText={touched.code ? errors.code : undefined}
          >
            <Input
              type="text"
              name="code"
              placeholder="Enter recovery code"
              value={values.code}
              onChange={handleChange}
              onBlur={handleBlur}
              autoFocus
            />
          </Form.Field>
          <Form.Footer>
            <Button
              variant="primary-untinted"
              type="submit"
              fill
              size="40"
              disabled={loading}
              loading={loading}
            >
              Submit
            </Button>
          </Form.Footer>
        </Form>
      )}
    </Formik>
  )
}

export default RecoveryCodeForm
