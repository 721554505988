import React from 'react'
import Helmet from 'react-helmet'
import { Flex, Text } from '@sketch/components'
import HeaderBadge from '../../../shares/components/HeaderBadge'
import Breadcrumbs from '../../../shares/components/HeaderNavigation/Breadcrumbs'
import EditorNavbar from './EditorNavbar'
import EditorSidebar from './EditorSidebar'
import { ContentWrapper, StyledDSIcon, Header } from './EditorView.styles'
import {
  DesignSystem as DesignSystemType,
  DesignSystemSection,
} from '../../types'
import { WorkspaceMinimalFragment } from '@sketch/gql-types'
import { DocumentSidebarLayoutExtraProps } from '../../../shares/components/DocumentSidebarLayout'

interface EditorViewProps {
  designSystem: DesignSystemType
  workspace: WorkspaceMinimalFragment | undefined
  sections: DesignSystemSection[]
  layoutProps: DocumentSidebarLayoutExtraProps
}

const EditorView: React.FC<EditorViewProps> = ({
  children,
  workspace,
  designSystem,
  sections,
  layoutProps,
}) => {
  const { HeaderPortal, useOverrideLayoutProps, setSidebarLeftOpen } =
    layoutProps

  // Override default layout values
  useOverrideLayoutProps({
    title: `Design System - ${designSystem.name}`,
  })

  const userIsGuest =
    workspace?.userRole === 'GUEST' || workspace?.userRole === undefined

  if (!workspace) {
    return null
  }

  return (
    <>
      <Helmet>
        <meta name="theme-color" content="#F0F0F0" />
      </Helmet>

      <HeaderPortal>
        <Header>
          {workspace && (
            <HeaderBadge
              workspace={workspace}
              setSidebarLeftOpen={setSidebarLeftOpen}
            />
          )}
          <Flex mt={1} width="100%">
            <Breadcrumbs>
              <Flex alignItems="self-start">
                <StyledDSIcon />
                <Text
                  color="foreground.secondary.C"
                  fontSize="D"
                  fontWeight="medium"
                >
                  Design Systems
                </Text>
              </Flex>
              <Text
                color="foreground.secondary.B"
                fontSize="D"
                fontWeight="medium"
              >
                {designSystem.name}
              </Text>
            </Breadcrumbs>
          </Flex>
          <EditorNavbar userIsGuest={userIsGuest} designSystem={designSystem} />
        </Header>
      </HeaderPortal>

      <Flex flexDirection="row">
        <EditorSidebar
          workspace={workspace}
          designSystem={designSystem}
          sections={sections}
        />
        <ContentWrapper>{children}</ContentWrapper>
      </Flex>
    </>
  )
}
export default EditorView
