import { ErrorHandler } from '@sketch/tracing'
import { groupBy } from '@sketch/utils'

export interface TreeNodeData<Payload extends {}> {
  id: string
  // We are allowing to load tree bit by bit, therefore looking just at the loaded nodes is not enough
  // to tell if a node is a leaf (meaning that it doesn't have any children).
  // We ask the caller to explicitly mark nodes as leaves.
  //
  // So far we are using this flag to determine if a node can be opened or not.
  hasChildren: boolean
  payload: Payload | null
}

const ROOT_ID = 'root'

export interface SetChildrenTreeItemParams {
  id: string
  parentId: string | null
  hasChildren: boolean
}

class TreeBase<Payload extends {}> {
  static ROOT_ID = ROOT_ID

  /**
   * Holds the actual node data by node.id.
   */
  private nodes: Map<string, TreeNodeData<Payload>> = new Map()

  /**
   * For each nodeId → parentId (null if it's the root)
   */
  private parentMap: Map<string, string | null> = new Map()

  /**
   * For each parentId → array of child node IDs
   */
  private childrenMap: Map<string, string[]> = new Map()

  constructor() {
    this.createNode({ id: ROOT_ID, payload: null, hasChildren: true })
    this.setParentId(ROOT_ID, null)
  }

  /**
   * Checks if the node is a leaf (no children expected).
   * @returns `true` if leaf, `false` if not leaf, `null` if node not found
   */
  protected isLeaf(id: string): boolean | null {
    const node = this.getNode(id)
    if (!node) return null
    return !node.hasChildren
  }

  /**
   * Creates a new node and ensures `childrenMap` is initialized.
   */
  protected createNode(node: TreeNodeData<Payload>): TreeNodeData<Payload> {
    const id = node.id
    this.nodes.set(id, node)

    // Initialize children array for the newly created node if it doesn't exist
    if (!this.childrenMap.has(id)) this.childrenMap.set(id, [])

    return node
  }

  protected setParentId(
    childId: string | TreeNodeData<Payload>,
    parentId: string | null
  ): void {
    const nodeId = typeof childId === 'string' ? childId : childId.id
    this.parentMap.set(nodeId, parentId)
  }

  /**
   * Replaces a node's children IDs with a new array.
   */
  protected setChildrenIds(
    parentId: string | TreeNodeData<Payload>,
    newChildrenIds: string[]
  ): void {
    const nodeId = typeof parentId === 'string' ? parentId : parentId.id
    this.childrenMap.set(nodeId, newChildrenIds)
  }

  /**
   * Throws away existing children for a given parent and sets new children.
   */
  setChildren(
    parentId: string | null,
    children: TreeNodeData<Payload>[]
  ): void {
    const actualParentId = parentId ?? ROOT_ID
    const parentNode = this.getNode(actualParentId)
    if (!parentNode) {
      throw new Error(`Parent node with id "${actualParentId}" not found`)
    }

    if (children.some(child => child.id === ROOT_ID)) {
      throw new Error('Cannot add root node as a child')
    }

    // Remove existing children from the parent
    const existingChildrenIds = this.getChildrenIds(parentNode)
    for (const childId of existingChildrenIds) {
      this.nodes.delete(childId)
      this.parentMap.delete(childId)
      this.childrenMap.delete(childId)
    }

    // Add new children
    const newChildrenIds: string[] = []
    for (const childData of children) {
      const childNode = this.createNode(childData)
      this.setParentId(childNode.id, actualParentId)
      newChildrenIds.push(childNode.id)
    }

    this.setChildrenIds(actualParentId, newChildrenIds)
  }

  setChildrenTree<T extends Payload>(args: {
    list: T[]
    itemParamsMap: Map<T, SetChildrenTreeItemParams>
  }) {
    const { list, itemParamsMap } = args

    const groupedByParentId = groupBy(
      list,
      x => itemParamsMap.get(x)?.parentId || ROOT_ID
    )
    const groupsCount = Object.keys(groupedByParentId).length

    for (let i = 0; i < groupsCount; i++) {
      // pick first group whose parent is already in the tree
      let existingParentId: string | undefined
      for (const parentId in groupedByParentId) {
        if (!this.getNode(parentId)) continue
        existingParentId = parentId
        break
      }

      if (!existingParentId) {
        ErrorHandler.shouldNeverHappen(
          'There are non-root groups without a parent'
        )
        return this
      }

      // set children for the existing parent
      const children = groupedByParentId[existingParentId]
      this.setChildren(
        existingParentId,
        children.map(payload => {
          const itemParams = itemParamsMap.get(payload)
          if (!itemParams) {
            ErrorHandler.shouldNeverHappen(
              `Item params not found for the current tree item: ${JSON.stringify(
                payload
              )}`
            )
            return { id: '', payload, hasChildren: false }
          }

          const { id, hasChildren } = itemParams
          return { id, payload, hasChildren }
        })
      )

      // remove the processed group
      delete groupedByParentId[existingParentId]
    }
  }

  /**
   * Returns all leaf nodes (no children) under the given parent (recursively).
   */
  getLeafNodesFromParent(parentId: string): TreeNodeData<Payload>[] {
    const parent = this.getNode(parentId)
    if (!parent) return []

    const leafNodes: TreeNodeData<Payload>[] = []
    const stack: string[] = [parentId]

    while (stack.length) {
      const nodeId = stack.pop()!
      const node = this.getNode(nodeId)
      if (!node) continue

      const childrenIds = this.getChildrenIds(node)
      if (childrenIds.length === 0) {
        // This node has no children -> leaf
        leafNodes.push(node)
      } else {
        stack.push(...childrenIds)
      }
    }

    return leafNodes
  }

  // Helper methods
  getNode(id: string | undefined): TreeNodeData<Payload> | undefined {
    if (!id) return undefined
    return this.nodes.get(id)
  }

  getRoot(): TreeNodeData<Payload> {
    return this.getNode(ROOT_ID)!
  }

  getChildren(
    id: string | TreeNodeData<Payload> | undefined
  ): TreeNodeData<Payload>[] {
    if (!id) return []
    return this.getChildrenIds(id)
      .map(childId => this.nodes.get(childId))
      .filter((child): child is TreeNodeData<Payload> => !!child)
  }

  getChildrenIds(id: string | TreeNodeData<Payload>): string[] {
    const nodeId = typeof id === 'string' ? id : id.id
    return this.childrenMap.get(nodeId) ?? []
  }

  /**
   * @returns
   *  - `undefined` if the node is not found
   *  - `null` if the node is the root
   */
  getParent(
    id: string | TreeNodeData<Payload> | undefined
  ): TreeNodeData<Payload> | undefined | null {
    if (!id) return undefined
    const parentId = this.getParentId(id)
    if (parentId === null) return null
    if (!parentId) return undefined

    return this.getNode(parentId)
  }

  /**
   * @returns
   *  - `undefined` if the node doesn't exist in `parentMap`
   *  - `null` if the node is the root
   */
  getParentId(id: string | TreeNodeData<Payload>): string | null | undefined {
    const nodeId = typeof id === 'string' ? id : id.id
    return this.parentMap.get(nodeId)
  }

  // Navigation methods
  getNextSibling(id: string): TreeNodeData<Payload> | undefined {
    const parentId = this.getParentId(id)
    if (!parentId) return undefined // either root or doesn't exist

    const siblings = this.getChildrenIds(parentId)
    const index = siblings.indexOf(id)
    return index < siblings.length - 1
      ? this.getNode(siblings[index + 1])
      : undefined
  }

  getPrevSibling(id: string): TreeNodeData<Payload> | undefined {
    const node = this.getNode(id)
    if (!node) return undefined

    const parentId = this.getParentId(id)
    if (!parentId) return undefined

    const siblings = this.getChildrenIds(parentId)
    const index = siblings.indexOf(id)
    return index > 0 ? this.getNode(siblings[index - 1]) : undefined
  }

  getFirstChild(id: string): TreeNodeData<Payload> | undefined {
    const children = this.getChildrenIds(id)
    if (!children.length) return undefined
    return this.getNode(children[0])
  }

  getLastChild(id: string): TreeNodeData<Payload> | undefined {
    const children = this.getChildrenIds(id)
    if (!children.length) return undefined
    return this.getNode(children[children.length - 1])
  }
}

export class CollapsibleTree<
  Payload extends {} = {},
> extends TreeBase<Payload> {
  private expandedIds: Set<string> = new Set([ROOT_ID])

  setOpen(id: string | string[]) {
    const ids = typeof id === 'string' ? [id] : id
    for (const id of ids) {
      if (this.isLeaf(id)) continue
      this.expandedIds.add(id)
    }
  }

  setClosed(id: string | string[]) {
    const ids = typeof id === 'string' ? [id] : id
    for (const id of ids) {
      if (this.isLeaf(id)) continue
      this.expandedIds.delete(id)
    }
  }

  setIsOpen(id: string, isOpen: boolean) {
    if (isOpen) {
      this.setOpen(id)
    } else {
      this.setClosed(id)
    }
  }

  isOpen(id: string): boolean {
    return this.expandedIds.has(id)
  }

  getParentsPathToNode(id: string): string[] {
    const currentNode = this.getNode(id)
    if (!currentNode) return []

    // construct the path to the current node
    const parentsPath: string[] = []
    for (
      let parent = this.getParent(currentNode);
      parent;
      parent = this.getParent(parent)
    ) {
      parentsPath.push(parent.id)
    }
    parentsPath.reverse()
    return parentsPath
  }

  getPathToNode(id: string): string[] {
    return [...this.getParentsPathToNode(id), id]
  }

  isVisible(id: string): boolean {
    const parentsPath = this.getParentsPathToNode(id)
    return parentsPath.every(nodeId => this.isOpen(nodeId))
  }

  getFirstVisibleParent(id: string): TreeNodeData<Payload> | null {
    const parentsPath = this.getParentsPathToNode(id)
    if (!parentsPath.length) return null

    const firstVisibleParentId = parentsPath.find(p => !this.isOpen(p))

    return this.getNode(firstVisibleParentId) ?? null
  }

  getNextVisibleNode(id: string): TreeNodeData<Payload> | undefined {
    // if the current node is not visible, walk back up the tree
    // until we find a visible ancestor
    const node = this.isVisible(id)
      ? this.getNode(id)
      : this.getFirstVisibleParent(id)

    if (!node) return undefined

    // If node is expanded and has children, return first child
    if (this.isOpen(node.id) && this.getChildrenIds(node).length) {
      return this.getChildren(node)[0]
    }

    let currentNode = node
    // Try to get next sibling or its first expanded descendant
    while (currentNode) {
      const nextSibling = this.getNextSibling(currentNode.id)
      if (nextSibling) return nextSibling
      currentNode = this.getParent(currentNode)!
    }

    return undefined
  }

  /**
   * Gets the previous visible node in a depth-first walk, skipping collapsed subtrees.
   * Returns `null` if there's a collapsed ancestor that is the closest "visible" parent.
   */
  getPrevVisibleNode(id: string): TreeNodeData<Payload> | undefined | null {
    // If the current node is not visible, return the first visible parent (if any).
    if (!this.isVisible(id)) {
      return this.getFirstVisibleParent(id)
    }

    const node = this.getNode(id)
    if (!node) return undefined

    // If there's a previous sibling, return it or its deepest expanded descendant.
    const prevSibling = this.getPrevSibling(node.id)
    if (prevSibling) {
      if (this.isOpen(prevSibling.id)) {
        return this.getLastExpandedDescendant(prevSibling.id)
      }
      return prevSibling
    }

    // If no previous sibling, go up to the parent (unless the parent is root).
    const parentId = this.getParentId(node.id)
    if (parentId === ROOT_ID || parentId === null) {
      return undefined
    }
    if (parentId === undefined) {
      // parent not found, or node doesn't exist in parentMap
      return undefined
    }

    return this.getNode(parentId)
  }

  /**
   * Note: expanded !== visible
   * There can be a node that is expanded (or its parent is expanded), but still not visible
   * because one of its ancestors is collapsed
   */
  private getLastExpandedDescendant(
    id: string
  ): TreeNodeData<Payload> | undefined {
    const node = this.getNode(id)
    if (!node || !this.isOpen(id) || !this.getChildrenIds(node).length) {
      return node
    }

    const lastChild = this.getLastChild(id)
    if (!lastChild) return node

    return this.getLastExpandedDescendant(lastChild.id)
  }

  getFirstVisibleNode(): TreeNodeData<Payload> | undefined {
    return this.getFirstChild(ROOT_ID)
  }

  getLastVisibleNode(): TreeNodeData<Payload> | undefined {
    return this.getLastExpandedDescendant(ROOT_ID)
  }
}
