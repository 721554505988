import { isNetworkRequestInFlight } from 'apollo-client/core/networkStatus'

import { useProjectBanner } from '../useProjectBanner'

import { ShareSearchFilter } from '../../../shares/hooks/useSearchFilters'

import { useProjectData } from '../useProjectData'
import { useGetStorageItems } from './useGetStorageItems'
interface UseProjectsProps {
  workspaceId: string
  projectId: string
  search?: string
  filters?: ShareSearchFilter[]
}

export const useProject = ({
  workspaceId,
  projectId,
  search,
}: UseProjectsProps) => {
  const storageItemsQuery = useGetStorageItems({
    projectId,
    workspaceId,
    search,
  })
  const projectQuery = useProjectData(workspaceId, projectId)

  const project = projectQuery.currentProject
  useProjectBanner({ project, workspaceId })

  const loading =
    projectQuery.isInFlight ||
    isNetworkRequestInFlight(storageItemsQuery.networkStatus)

  const error = projectQuery.error || storageItemsQuery.error

  return {
    data: {
      project,
      storageItems: storageItemsQuery?.storageItems,
      breadcrumbs: projectQuery?.breadcrumbs,
    },
    loadMore: { storageItems: storageItemsQuery.fetchMore },
    loading,
    error,
    totalCount: storageItemsQuery.totalCounts,
  }
}
