import React, {
  FC,
  ComponentType,
  useState,
  useRef,
  useEffect,
  useCallback,
  ReactNode,
} from 'react'
import {
  OperationVariables,
  QueryResult,
  QueryComponentOptions,
} from 'react-apollo'
import { ApolloQueryResult } from 'apollo-client'

type RetryEvent<Data, TReturn = void> = (
  result: Pick<QueryResult<Data>, 'data' | 'error'> & { retryCount: number }
) => TReturn

export type RetryState = 'idle' | 'retrying' | 'success' | 'failed'

const DEFAULT_TIMEOUT = 3000

export interface RetryInfo {
  retryCount: number
  retryState: RetryState
}

export interface RetryQueryProps<Data, Variables> {
  retryTimeout?: number
  retryMax?: number
  retryKey?: (props: QueryResult<Data, Variables>) => string
  retryIf?: RetryEvent<Data, boolean>
}

export interface RetryQueryCompProps<Data, Variables>
  extends RetryQueryProps<Data, Variables> {
  children: (result: QueryResult<Data, Variables> & RetryInfo) => ReactNode
}

export const useQueryRetry = <Data, Variables = OperationVariables>(
  result: QueryResult<Data, Variables>,
  props: RetryQueryProps<Data, Variables>
) => {
  const {
    retryMax = 5,
    retryIf = () => false,
    retryKey = ({ variables }) => JSON.stringify({ variables }),
    retryTimeout = DEFAULT_TIMEOUT,
  }: typeof props = props

  const retryCount = useRef(0)
  const [retryState, setRetryState] = useState<RetryState>('idle')
  const isRetryPending = useRef(false)
  const timerHandle = useRef<number | undefined>(undefined)

  const startRetrying = useCallback(
    (refetch: () => Promise<ApolloQueryResult<Data>>) => {
      setRetryState('retrying')
      isRetryPending.current = true

      const cleanup = () => {
        isRetryPending.current = false
        if (timerHandle.current) {
          try {
            window.clearTimeout(timerHandle.current)
          } finally {
            timerHandle.current = undefined
          }
        }
      }

      const invokeRefetch = async () => {
        retryCount.current++
        let isDataValid: boolean
        try {
          const result = await refetch()
          const resultWithRetryInfo = {
            ...result,
            retryCount: retryCount.current,
            retryState,
          }
          isDataValid = !retryIf(resultWithRetryInfo)
        } catch {
          isDataValid = false
        }

        if (isDataValid) {
          setRetryState('success')
          cleanup()
        } else {
          if (retryCount.current < retryMax) {
            timerHandle.current = window.setTimeout(invokeRefetch, retryTimeout)
          } else {
            setRetryState('failed')
            cleanup()
          }
        }
      }
      timerHandle.current = window.setTimeout(invokeRefetch, retryTimeout)
    },
    [retryIf, retryMax, retryState, retryTimeout]
  )

  const cleanupDependency = retryKey && retryKey(result)
  useEffect(() => {
    return () => {
      const handle = timerHandle.current
      retryCount.current = 0
      setRetryState('idle')
      isRetryPending.current = false
      if (handle) {
        window.clearTimeout(handle as number)
      }
      timerHandle.current = undefined
    }
  }, [cleanupDependency])

  const resultWithRetryInfo = {
    ...result,
    retryCount: retryCount.current,
    retryState,
  }

  const isDataValid = !retryIf(resultWithRetryInfo)

  if (
    !result.loading &&
    !isDataValid &&
    !isRetryPending.current &&
    retryCount.current < retryMax
  ) {
    startRetrying(result.refetch)
  }

  if (isDataValid && !result.loading && retryState !== 'success') {
    setRetryState('success')
  }

  return resultWithRetryInfo
}

const QueryRetryComp = <Data, Variables = OperationVariables>(
  props: RetryQueryCompProps<Data, Variables> & {
    result: QueryResult<Data, Variables>
  }
) => {
  const { children, result: originalResult, ...retryProps } = props
  const result = useQueryRetry(originalResult, retryProps)
  return (
    <>
      {typeof children === 'function' && result
        ? children({ ...result, ...retryProps })
        : null}
    </>
  )
}

export type RetryQuery<Data = any, Variables = OperationVariables> = FC<
  RetryQueryCompProps<Data, Variables> &
    OmitSafe<QueryComponentOptions<Data, Variables>, 'children'>
>

/**
 * @deprecated, use useQueryRetry instead
 */
export function withQueryRetry<Data = any, Variables = OperationVariables>(
  /*
   * seems that there is an issue with typings, propTypes are marked as required:
   * https://github.com/DefinitelyTyped/DefinitelyTyped/issues/28249
   */
  QueryComponent: (
    props: QueryComponentOptions<Data, Variables>
  ) => ReactNode | null
) {
  const QueryComp = QueryComponent as ComponentType<
    QueryComponentOptions<Data, Variables>
  >

  const RetryQuery: RetryQuery<Data, Variables> = props => {
    const {
      children,
      retryMax = 5,
      retryIf = () => false,
      retryKey = ({ variables }) => JSON.stringify({ variables }),
      retryTimeout = DEFAULT_TIMEOUT,
      ...rest
    }: typeof props = props
    return (
      <QueryComp {...rest}>
        {result => {
          return (
            <QueryRetryComp<Data, Variables>
              retryIf={retryIf}
              retryMax={retryMax}
              retryKey={retryKey}
              retryTimeout={retryTimeout}
              // eslint-disable-next-line react/no-children-prop
              children={children}
              result={result}
            />
          )
        }}
      </QueryComp>
    )
  }
  return RetryQuery
}
