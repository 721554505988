import React, { FC, useEffect } from 'react'
import { ApolloError } from 'apollo-client'
import { matchPath, useHistory, useLocation } from 'react-router-dom'
import {
  getVersionedRoute,
  routes,
  VersionedRouteKeys,
  VersionedRouteParams,
} from '@sketch/modules-common'
import { useToast } from '@sketch/toasts'
import { LoadingState } from '@sketch/components'

import { useVersioning } from './useVersioning'

const matchVersioned = <T extends VersionedRouteKeys>(
  pathname: string,
  route: T
) => {
  const versionMatch = matchPath<VersionedRouteParams<T>>(pathname, {
    path: getVersionedRoute(route, false).template(),
    exact: false,
  })
  const latestMatch = matchPath<VersionedRouteParams<T>>(pathname, {
    path: getVersionedRoute(route, true).template(),
    exact: false,
  })

  return versionMatch || latestMatch
}

const getShareViewPath = (pathname: string) => {
  const shareMatch = matchVersioned(pathname, 'SHARE_VIEW')
  if (shareMatch) {
    const { shareID } = shareMatch.params
    return routes.SHARE_VIEW.create({ shareID })
  }

  return routes.ENTRY.create({})
}

const getLatestPath = (pathname: string) => {
  const prototypeMatch = matchVersioned(pathname, 'PROTOTYPE_PLAYER')
  if (prototypeMatch) {
    const { currentArtboardUUID, prototypeArtboardUUID, shareID } =
      prototypeMatch.params
    return routes.PROTOTYPE_PLAYER.create({
      shareID,
      currentArtboardUUID,
      prototypeArtboardUUID,
    })
  }

  const artboardMatch = matchVersioned(pathname, 'ARTBOARD_DETAIL')

  if (artboardMatch) {
    const { permanentArtboardShortId, shareID } = artboardMatch.params
    return routes.ARTBOARD_DETAIL.create({ permanentArtboardShortId, shareID })
  }

  const frameMatch = matchVersioned(pathname, 'FRAME')
  if (frameMatch) {
    const { frameUUID, shareID } = frameMatch.params
    return routes.FRAME.create({ frameUUID, shareID })
  }

  const sharePageMatch = matchVersioned(pathname, 'SHARE_PAGE_VIEW')
  if (sharePageMatch) {
    const { shareID, pageUUID } = sharePageMatch.params
    return routes.SHARE_PAGE_VIEW.create({ shareID, pageUUID })
  }

  return getShareViewPath(pathname)
}

type ErrorType =
  | 'unknown'
  | 'older-version-permission'
  | 'deleted-version'
  | 'forbidden-version'
  // While not strictly an error, we will want to upgrade users
  // who generate an autosave to the latest version of the document
  // and at the same time we don't want to duplicate `upgradeToLatest`
  | 'user-generated-autosave'

export const getErrorType = (error: ApolloError | undefined): ErrorType => {
  if (!error || !Array.isArray(error.graphQLErrors)) return 'unknown'

  const olderVersionError = error.graphQLErrors.some(e =>
    [
      'USER_CANT_VIEW_OLD_SHARE_VERSIONS',
      'USER_CANT_VIEW_VERSION_HISTORY',
    ].includes(e.extensions?.reason)
  )
  if (olderVersionError) return 'older-version-permission'

  const deletedError = error.graphQLErrors.some(
    e => e.extensions?.code === 'VERSION_DELETED'
  )
  if (deletedError) return 'deleted-version'

  const forbiddenVersionError = error.graphQLErrors.some(
    e => e.extensions?.reason === 'USER_CANT_VIEW_SHARE_VERSION'
  )
  if (forbiddenVersionError) return 'forbidden-version'

  return 'unknown'
}

export const isUpgradeToLatestNeeded = (
  error: ApolloError | undefined
): boolean => getErrorType(error) !== 'unknown'

const getUpgradeToLatestContext = (errorType: ErrorType) => {
  switch (errorType) {
    case 'deleted-version':
      return {
        kind: errorType,
        toast: (documentName: string) =>
          `The update of "${documentName}" has been deleted`,
        intent: 'negative',
        getPath: getLatestPath,
      } as const
    case 'older-version-permission':
    case 'forbidden-version':
    case 'user-generated-autosave':
    case 'unknown': {
      return {
        kind: errorType,
        toast: () => 'Document has been updated',
        intent: 'positive',
        getPath: getLatestPath,
      } as const
    }
  }
}

export const useUpgradeToLatestVersion = () => {
  const history = useHistory()
  const { pathname } = useLocation()
  const { showToast } = useToast()
  const { onVersionUpdateNeeded, share } = useVersioning()

  const upgradeToLatest = (
    errorType: ErrorType,
    skipToast: boolean = false
  ) => {
    onVersionUpdateNeeded && onVersionUpdateNeeded()

    const upgradeContext = getUpgradeToLatestContext(errorType)
    const targetPath = upgradeContext.getPath(pathname)

    const isDifferentTargetPath = targetPath !== pathname
    if (isDifferentTargetPath) {
      history.push(targetPath)
    }
    if (
      !skipToast &&
      (isDifferentTargetPath || errorType === 'deleted-version')
    ) {
      showToast?.(upgradeContext.toast(share.name), upgradeContext.intent)
    }
  }

  return { upgradeToLatest }
}

export interface UpgradeToLatestVersionProps {
  error: ApolloError | undefined
}
export const UpgradeToLatestVersion: FC<UpgradeToLatestVersionProps> = ({
  error,
}) => {
  const { upgradeToLatest } = useUpgradeToLatestVersion()

  useEffect(() => {
    upgradeToLatest(getErrorType(error))

    // We need to run this render method once
    // however, it is likely that it will be called
    // multiple times while the redirect is happening
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return <LoadingState />
}
