import React, { FC, PropsWithChildren } from 'react'
import { useModalContext } from '@sketch/components'
import {
  Footer,
  Button,
  ModalStyled,
} from './MoveStorageItemModalLayout.styles'

export interface MoveStorageItemModalLayoutProps {}

export const MoveStorageItemModalLayout: FC<
  MoveStorageItemModalLayoutProps
> = props => {
  const hideModal = useModalContext().hideModal

  const { children } = props

  return <ModalStyled onCancel={hideModal}>{children}</ModalStyled>
}

export interface MoveStorageItemModalFooterProps extends PropsWithChildren<{}> {
  disabled?: boolean
  loading?: boolean
  onClick?: () => void
  dimmed?: boolean
  itemType: 'Document' | 'Folder'
}
export const MoveStorageItemModalFooter: FC<
  MoveStorageItemModalFooterProps
> = props => {
  const hideModal = useModalContext().hideModal
  const { disabled, loading, onClick, children, dimmed, itemType } = props

  return (
    <Footer dimmed={dimmed}>
      {children}
      <Button onClick={hideModal}>Cancel</Button>
      <Button
        variant="primary"
        disabled={disabled}
        loading={loading}
        onClick={onClick}
      >
        Move {itemType}
      </Button>
    </Footer>
  )
}
