import styled, { DefaultTheme } from 'styled-components'

import { breakpoint } from '@sketch/global-styles'

export type BannerType = 'neutral' | 'positive'
export type BannerBorders = 'top' | 'bottom' | 'left' | 'right'
export type Size = 'smaller' | 'tiny'

export interface WrapperProps {
  $type: BannerType
  $rounded: boolean
  $borders: BannerBorders[]
  $size: Size
}

const getBackgroundColor = (type: BannerType, theme: DefaultTheme) => {
  switch (type) {
    case 'positive':
      return theme.colors.brand.green.default.C
    case 'neutral':
      return theme.colors.state.neutral.F
  }
}

const getColor = (type: BannerType, theme: DefaultTheme) => {
  switch (type) {
    case 'positive':
      return theme.colors.brand.green.foreground
    case 'neutral':
      return theme.colors.state.warning
  }
}

const getBorderColor = (type: BannerType, theme: DefaultTheme) => {
  switch (type) {
    case 'positive':
      return theme.colors.state.positive.E
    case 'neutral':
      return theme.colors.state.neutral.E
  }
}

export const IconWrapper = styled.div`
  width: 16px;
  height: 16px;
  margin: 0 8px 0 0;
  line-height: 0;
  flex-shrink: 0;
`

export const Wrapper = styled.div<WrapperProps>`
  ${({ theme, $rounded }) =>
    $rounded ? `border-radius: ${theme.radii.large}` : ''};

  ${({ theme, $type, $borders }) =>
    $borders.map(
      b => `border-${b}: 1px solid ${getBorderColor($type, theme)};`
    )};

  background-color: ${({ theme, $type }) => getBackgroundColor($type, theme)};
  padding: ${({ $size }) => ($size === 'tiny' ? '8px' : '16px')} 12px;
  display: flex;
  align-items: center;

  ${breakpoint('base', 'xs')`
    align-items: start;
  `}

  ${IconWrapper} {
    color: ${({ theme, $type }) => getColor($type, theme)};
  }
`
export const Description = styled.span`
  font-size: ${({ theme }) => theme.fontSizes.D};
  color: ${({ theme }) => theme.colors.foreground.secondary.B};
  font-weight: 400;
  line-height: ${({ theme }) => theme.lineHeights.default};
`

export const BannerContent = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
  align-items: center;

  ${breakpoint('base', 'xs')`
    flex-direction: column;
    align-items: flex-start;
  `}
`

export const DescriptionRow = styled.div`
  display: flex;
`

export const ButtonWrapper = styled.div`
  margin-left: 24px;

  ${breakpoint('base', 'xs')`
    margin: 12px 0 0 16px;
    padding-left: 6px;
  `}
`
