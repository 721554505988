import React from 'react'

import { Container } from './WorkspaceMembershipsFilter.styles'
import { Segment } from '@sketch/components'

interface WorkspaceFilterProps<T> {
  activeFilter: T
  totalMemberships: number
  totalSSOMemberships: number
  onChange: (value: T) => void
}

/*
 * WorkspaceFilter
 *
 * Renders the filter tabs for the Workspaces Memberships Table
 */
export const WorkspaceMembershipsFilter = <T,>({
  activeFilter,
  totalMemberships,
  totalSSOMemberships,
  onChange,
}: WorkspaceFilterProps<T>) => {
  return (
    <Container>
      <div onClick={() => onChange('all' as T)}>
        <Segment
          type="text"
          label={`All Workspaces (${totalMemberships})`}
          active={activeFilter === 'all'}
        />
      </div>
      <div onClick={() => onChange('sso' as T)}>
        <Segment
          type="text"
          label={`SSO (${totalSSOMemberships})`}
          active={activeFilter === 'sso'}
        />
      </div>
    </Container>
  )
}
