import styled from 'styled-components'

export const Separator = styled.span`
  width: 100%;
  display: flex;

  align-items: center;

  line-height: 1;

  color: ${({ theme }) => theme.colors.sketch.A};
  margin-left: -8px;

  font-size: 0.6875rem; /* 11px */
  font-weight: ${({ theme }) => theme.fontWeights.semibold};

  text-align: right;

  /*
   * When scrolling into view (check useEffect), this will
   * keep a nice spacing above the element.
  */
  scroll-margin-top: 60px;

  ::before {
    display: block;
    content: '';

    flex: 1;
    margin-right: 8px;

    border-top: 1px solid ${({ theme }) => theme.colors.border.B};
  }
`
