import { useUpdateVersionDescriptionMutation } from '@sketch/gql-types'

export interface UseUpdateDescriptionProps {
  versionIdentifier: string
  shareIdentifier: string
  onComplete?: () => void
}

export const useUpdateDescription = ({
  versionIdentifier,
  shareIdentifier,
  onComplete,
}: UseUpdateDescriptionProps) => {
  const [updateVersionDescription, { loading }] =
    useUpdateVersionDescriptionMutation({
      onCompleted: () => {
        onComplete?.()
      },
      onError: 'show-toast',
    })

  const updateDescription = (description: string) => {
    updateVersionDescription({
      variables: {
        versionIdentifier,
        shareIdentifier,
        description,
      },
    })
  }

  return {
    versionDescriptionLoading: loading,
    updateVersionDescription: updateDescription,
  }
}
