import { localStorageKeys } from '@sketch/constants'
import {
  QueryParams,
  RouteParams,
  useQueryParams,
} from '@sketch/modules-common'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useHistory, useParams } from 'react-router'
import { useEvent, usePan, useZoom } from '@sketch-hq/sketch-web-renderer'
import debounce from 'debounce'
import {
  getParsedItem,
  setStringifiedItem,
  useStableHandler,
} from '@sketch/utils'
import { stringify } from 'query-string'

/** Last camera position should only be used for 24h since last update, after that we consider the position as expired */
const LAST_CAMERA_POSITION_EXPIRATION_TIME = 24 * 60 * 60 * 1000

type LocalStorageCameraPosition = {
  posX: number
  posY: number
  zoom: number
  timestamp: number
}

interface CameraPositionFromLocalStorageProps {
  pageUUID: string
  versionShortId: string
}

export type CameraPositionByPageCanvasKey = Partial<
  Record<string, LocalStorageCameraPosition>
>

export type InitialCameraPosition = {
  initialPan:
    | { x: number; y: number; centerPointInViewport: boolean }
    | undefined
  initialZoom: number | undefined
}

/**
 * Read potential camera position set in URL or last position save in local storage
 * to potentially overwrite the default initial camera position of the canvas.
 *
 * Note: When the camera position is set in the URL, the URL is cleaned-up after being
 * read as we want local storage position to take over for the next canvas re-load.
 * (@see useInitialCameraPositionFromQueryParams for details)
 */
export function useInitialCameraPosition({
  pageUUID,
  versionShortId,
}: CameraPositionFromLocalStorageProps) {
  const cameraPositionFromQueryParams =
    useInitialCameraPositionFromQueryParams()
  const cameraPostionFromLocalStorage =
    useInitialCameraPositionFromLocalStorage({ pageUUID, versionShortId })

  if (cameraPositionFromQueryParams !== null) {
    return cameraPositionFromQueryParams
  }

  if (cameraPostionFromLocalStorage !== null) {
    return cameraPostionFromLocalStorage
  }

  return {
    initialPan: undefined,
    initialZoom: undefined,
  }
}

/* URL */

/**
 * Returns the initial position of the camera if it has been defined in the query params
 * of the URL (from "Copy link to position" shared link).
 * Once read, we remove the query params from the URL and replace the browser history entry,
 * to the new URL without the position. The clean-up of the URL is needed since from that point,
 * if the user refreshes or go back in the browser history we want to restore to the last camera
 * position saved in local storage.
 */
function useInitialCameraPositionFromQueryParams() {
  type InitialCameraPosFromUrl =
    | {
        pageUUID: string
        cameraPos: InitialCameraPosition | null
      }
    | undefined

  const { pageUUID } = useParams<RouteParams<'SHARE_PAGE_CANVAS_VIEW'>>()
  const queryParams = useQueryParams<'SHARE_PAGE_CANVAS_VIEW'>()

  /**
   * Save the values from the URL in a ref. We also save the pageUUID
   * to remember for which exact canvas this values are for.
   * We need to remember those values until the web-renderer is initialized,
   * after that we can throw them away. Since we don't know exactly when the
   * web-renderer is initialized, we throw them away when the user changes
   * to a different canvas.
   */
  const initialCameraPosRef = useRef<InitialCameraPosFromUrl>({
    pageUUID: pageUUID,
    cameraPos: extractInitialCameraStateFromQueryParams(queryParams),
  })

  /**
   * Throw away the initial values if the canvas is changed.
   * https://github.com/sketch-hq/Cloud/issues/11849
   */
  useEffect(() => {
    const initialCameraPos = initialCameraPosRef.current
    if (initialCameraPos && initialCameraPos.pageUUID !== pageUUID) {
      initialCameraPosRef.current = undefined
    }
  }, [pageUUID])

  useRemovePotentialCameraPosQueryParams()

  /**
   * Make sure to only return the initialCameraPos if the canvas has not changed.
   * Note: useEffect is not enough because useEffect executes after the render pass,
   * which means that first render the pageUUID changes  when initialCameraPosRef.current
   * has not been reset yet.
   * https://github.com/sketch-hq/Cloud/issues/11849
   */
  const initialCameraPos = initialCameraPosRef.current
  if (initialCameraPos && initialCameraPos.pageUUID === pageUUID) {
    return initialCameraPos.cameraPos
  }
  return null
}

function extractInitialCameraStateFromQueryParams(
  queryParams: QueryParams<'SHARE_PAGE_CANVAS_VIEW'>
): InitialCameraPosition | null {
  if (queryParams.posX && queryParams.posY && queryParams.zoom) {
    return {
      initialPan: {
        x: parseFloat(queryParams.posX),
        y: parseFloat(queryParams.posY),
        centerPointInViewport: true,
      },
      initialZoom: parseFloat(queryParams.zoom),
    }
  }

  return null
}

/**
 * Clean-up potential initial pan & zoom query params from the URL
 * once the component is mounted and the initial values have already
 * been extracted from the URL. If the user refreshes or goes back using
 * the browser history button, the position stored in local storage
 * should take over anyway.
 */
function useRemovePotentialCameraPosQueryParams() {
  const history = useHistory()
  const queryParams = useQueryParams<'SHARE_PAGE_CANVAS_VIEW'>()
  const [initialQueryParams] = useState(queryParams)

  const updateURLParams = useStableHandler(
    (params: Partial<Record<'search' | 'annotation', string>>) => {
      history.replace({
        search: stringify(params),
        hash: history.location.hash,
      })
    }
  )

  useEffect(() => {
    const { posX, posY, zoom, ...otherQueryParams } = initialQueryParams
    updateURLParams(otherQueryParams)
  }, [initialQueryParams, updateURLParams])
}

/** LOCAL STORAGE */

/**
 * Read local storage and return, if it exists, the object containing all the saved
 * "last camera positions" for each canvas.
 * - The object key is the canvas key.
 * - The value is an object representing the position of the camera.
 */
function getLastCameraPositions() {
  return getParsedItem<CameraPositionByPageCanvasKey>(
    localStorageKeys.lastCameraPositionsByPageCanvasKey
  )
}

/**
 * Build the canvas key for the currently open canvas.
 * The key is the one used to access the last canvas position in
 * the CameraPositionByPageKey object saved in local storage.
 */
function usePageCanvasKey({
  pageUUID,
  versionShortId,
}: CameraPositionFromLocalStorageProps) {
  const pageCanvasKey = `${versionShortId}:${pageUUID}`

  return pageCanvasKey
}

/**
 * Last camera positions saved in local storage should only be used for 24h after the last update.
 */
function isLocalStorageCameraPositionExpired(
  cameraPosition: LocalStorageCameraPosition
) {
  return (
    Date.now() - cameraPosition.timestamp > LAST_CAMERA_POSITION_EXPIRATION_TIME
  )
}

/**
 * Get the last position saved in local storage for the current canvas if it exists.
 */
function useInitialCameraPositionFromLocalStorage({
  pageUUID,
  versionShortId,
}: CameraPositionFromLocalStorageProps): InitialCameraPosition | null {
  const pageCanvasKey = usePageCanvasKey({ pageUUID, versionShortId })

  const cameraPositionsByPageCanvasKey = getLastCameraPositions()
  if (cameraPositionsByPageCanvasKey) {
    const localStorageCameraPosition =
      cameraPositionsByPageCanvasKey[pageCanvasKey]
    if (
      localStorageCameraPosition &&
      !isLocalStorageCameraPositionExpired(localStorageCameraPosition)
    ) {
      return {
        initialPan: {
          x: localStorageCameraPosition.posX,
          y: localStorageCameraPosition.posY,
          centerPointInViewport: false,
        },
        initialZoom: localStorageCameraPosition.zoom,
      }
    }
  }

  return null
}

/**
 * Watch zoom/pan changes and save the last position of the camera
 * in local storage after they changed. Zoom/pan changes are debounced
 * to only write to local storage when the user action is over.
 */
export function useSaveLastCameraPositionInLocalStorage({
  pageUUID,
  versionShortId,
}: CameraPositionFromLocalStorageProps) {
  const pageCanvasKey = usePageCanvasKey({ pageUUID, versionShortId })

  const pan = usePan()
  const zoom = useZoom()

  const panRef = useRef(pan)
  const zoomRef = useRef(zoom)

  panRef.current = pan
  zoomRef.current = zoom

  const writeCameraPositionToLocaleStorage = useCallback(
    (posX: number, posY: number, zoom: number) => {
      const timestamp = Date.now()
      const newCameraPosition: LocalStorageCameraPosition = {
        posX,
        posY,
        zoom,
        timestamp,
      }
      const cameraPositionsUpdated = updateCameraPositions(
        pageCanvasKey,
        newCameraPosition
      )

      setStringifiedItem(
        localStorageKeys.lastCameraPositionsByPageCanvasKey,
        cameraPositionsUpdated
      )
    },
    [pageCanvasKey]
  )

  const handleCameraEvent = useCallback(() => {
    if (!panRef.current || !zoomRef.current) {
      return
    }
    writeCameraPositionToLocaleStorage(
      panRef.current.x,
      panRef.current.y,
      zoomRef.current
    )
  }, [writeCameraPositionToLocaleStorage])

  const handleCameraEventDebounced = useMemo(
    () => debounce(handleCameraEvent, 400),
    [handleCameraEvent]
  )

  useEvent('cameraZoomChange', handleCameraEventDebounced)
  useEvent('cameraPanChange', handleCameraEventDebounced)
}

/**
 * Update the specified canvas key with a new camera position
 * in the object we use to store canvas positions in local storage.
 *
 * We also use this operation to remove all "expired" camera positions,
 * i.e. Remove all the canvas keys that have been last updated more than X time ago
 * and for which we therefore consider that we shouldn't restore to that position anymore.
 * We expire the last camera position after 24h for UX reasons but also to make sure
 * that this object in local storage does not grow indefinitely.
 */
function updateCameraPositions(
  pageCanvasKey: string,
  newCameraPosition: LocalStorageCameraPosition
): CameraPositionByPageCanvasKey {
  const lastCameraPositions = getLastCameraPositions() ?? {}

  // Build a new CameraPositionByPageCanvasKey object without the expired keys.
  const validCameraPositions = Object.keys(lastCameraPositions).reduce(
    (acc: CameraPositionByPageCanvasKey, canvasKey) => {
      const storedPosition = lastCameraPositions[canvasKey]
      if (
        storedPosition &&
        !isLocalStorageCameraPositionExpired(storedPosition)
      ) {
        acc[canvasKey] = lastCameraPositions[canvasKey]
      }
      return acc
    },
    {}
  )

  const cameraPositionsUpdated = {
    ...validCameraPositions,
    [pageCanvasKey]: newCameraPosition,
  }
  return cameraPositionsUpdated
}
