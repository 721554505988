import {
  isPersonalAuthorization,
  isSsoAuthorization,
  SsoAuthorization,
  useUserAuthorizations,
} from '@sketch/modules-common'
import { isSSOWorkspace } from '../../workspace/utils'
import { WorkspaceMinimalFragment } from '@sketch/gql-types'

export const useCheckCurrentAuthorizationForWorkspace = () => {
  const { activeAuthorization } = useUserAuthorizations()

  const checkAuthorization = function (
    workspace: Pick<WorkspaceMinimalFragment, 'identifier' | 'customer'>
  ) {
    const isCurrentWorkspaceSSO = isSSOWorkspace(workspace)
    const isLoggedInWithSSOAuth = isSsoAuthorization(activeAuthorization)
    const isLoggedInWithPersonalAuth =
      isPersonalAuthorization(activeAuthorization)

    if (isCurrentWorkspaceSSO) {
      const currentSSOAuthorization = activeAuthorization as SsoAuthorization

      return isLoggedInWithSSOAuth
        ? currentSSOAuthorization.workspaceId === workspace.identifier
        : false
    }

    if (isLoggedInWithPersonalAuth) {
      return true
    }

    return false
  }

  return { checkAuthorization }
}
