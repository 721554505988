import { buildGetElementTiming } from './buildGetElementTiming'
import { observe } from 'web-vitals/dist/modules/lib/observe'

import { ElementTimingEntry, ElementTimingHandler } from './types'

export const getElementTiming = buildGetElementTiming(
  (handler: ElementTimingHandler) => {
    const po = observe('element', entry => {
      handler(entry as any as ElementTimingEntry)
    })
    return {
      cleanup: () => {
        po?.disconnect()
      },
    }
  },
  'ElementTiming'
)
