import React from 'react'
import styled from 'styled-components'

import { useCollectionSearchArgument } from '../../../collections/hooks'

import { ModalInjectedProps } from '@sketch/components'
import ShareAccessList from '../../../shares/components/ShareAccessList'
import { RemoveShareFromProjectLayout } from './RemoveShareFromProjectLayout'

import {
  StyledDraftsWarningContainer,
  Separator,
} from './RemoveShareFromProject.styles'

// GraphQL
import {
  CollectionForSelectFragment,
  ProjectFragment,
  ShareInfoFragment,
} from '@sketch/gql-types'
import { useRemoveShareFromProject } from '../../operations'

export interface RemoveShareFromProjectProps extends ModalInjectedProps {
  project: ProjectFragment
  share: Pick<
    ShareInfoFragment,
    'identifier' | 'name' | 'workspace' | 'memberships'
  >
  collection?: CollectionForSelectFragment
}

const WordBreaker = styled.span`
  overflow-wrap: break-word;
`

export const RemoveShareFromProject: React.FC<
  RemoveShareFromProjectProps
> = props => {
  const { hideModal, project, share } = props
  const search = useCollectionSearchArgument()

  const [removeFromProject, { loading }] = useRemoveShareFromProject({
    search,
    ...props,
    onCompleted: () => {
      hideModal()
    },
  })

  const isDraft = project.projectType === 'PERSONAL_DRAFTS'

  return (
    <RemoveShareFromProjectLayout
      title={`Remove Document from ${isDraft ? 'My Drafts' : 'Project'}?`}
      hideModal={hideModal}
      button={{
        loading,
        onClick: () =>
          removeFromProject({
            projectId: project.identifier,
            shareId: share.identifier,
          }),
      }}
    >
      {isDraft ? (
        <StyledDraftsWarningContainer>
          <p>
            <WordBreaker>
              “<strong>{share.name}</strong>”
            </WordBreaker>{' '}
            will be removed from My Drafts. You can find it in the All Documents
            view and everyone in the Workspace can access it.
          </p>
          <br />
        </StyledDraftsWarningContainer>
      ) : (
        <p>
          <WordBreaker>
            “<strong>{share.name}</strong>”
          </WordBreaker>{' '}
          will be removed from the{' '}
          <WordBreaker>
            “<strong>{project.name}</strong>”
          </WordBreaker>{' '}
          Project. You can find it in the All Documents view.
        </p>
      )}
      <Separator />
      <ShareAccessList
        shareIdentifier={share.identifier}
        membershipCount={share.memberships?.meta.totalCount}
      />
    </RemoveShareFromProjectLayout>
  )
}
