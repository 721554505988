import { Text } from '@sketch/components'
import styled, { css } from 'styled-components'

export const Title = styled(Text.H1)`
  color: ${({ theme }) => theme.colors.foreground.secondary.A};
  margin: 0 0 16px 0;
  font-size: ${({ theme }) => theme.fontSizes.I};
  line-height: 1;
`

export const Subtitle = styled(Text.P)`
  color: ${({ theme }) => theme.colors.foreground.secondary.A};
  margin-bottom: 24px;
`

export const Feature = styled.div`
  color: ${({ theme }) => theme.colors.foreground.secondary.A};
  display: flex;
  align-items: top;
  margin-bottom: 12px;
  line-height: ${({ theme }) => theme.lineHeights.default};
`

export const FeatureImg = styled.img<{ isDarkModeActive: boolean }>`
  margin-right: 8px;
  width: 24px;
  height: 24px;

  // Invert the icon depending on the Dark Mode setting
  ${({ isDarkModeActive }) =>
    isDarkModeActive
      ? css`
          filter: saturate(0) invert(1);
        `
      : null}
`
