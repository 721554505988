import { localStorageKeys } from '@sketch/constants'
import { useLocalStorage } from '@sketch/global-styles/src/hooks'
import React, { useEffect, useImperativeHandle, useRef } from 'react'
import styled from 'styled-components'
import { useVersioning } from '../../ShareVersionContext'

const Wrapper = styled.div`
  padding: 8px 8px 8px 0; /* stylelint-disable-line scales/space */
  margin: 0;

  height: 100%;
  overflow-y: auto;
`

const defaultScrollState = {
  offsetHeight: 0,
  scrollHeight: 0,
  scrollTop: 0,
}

const ShareVersionsListWrapper: React.FC<React.ComponentProps<typeof Wrapper>> =
  React.forwardRef(function ShareVersionsListWrapper(props, ref) {
    const { children, ...listProps } = props
    const wrapperRef = useRef<HTMLUListElement | null>(null)
    const childCount = React.Children.count(children)

    useImperativeHandle(ref, () => wrapperRef.current)

    const { share, isViewingLatestVersion } = useVersioning()
    const initialShareIdentifier = useRef(share.identifier)

    const getInitialScrollState = () => {
      if (initialShareIdentifier.current !== share.identifier) {
        return defaultScrollState
      }

      if (isViewingLatestVersion) {
        return {
          offsetHeight: wrapperRef.current?.offsetHeight || 0,
          scrollHeight: wrapperRef.current?.scrollHeight || 0,
          scrollTop: wrapperRef.current?.scrollTop || 0,
        }
      }

      const storedScrollPosition = localStorage.getItem(
        localStorageKeys.shareVersionsScrollPosition
      )

      return storedScrollPosition
        ? JSON.parse(storedScrollPosition)
        : defaultScrollState
    }

    const [storedScrollState, setStoredScrollState] = useLocalStorage(
      localStorageKeys.shareVersionsScrollPosition,
      getInitialScrollState()
    )

    const savedWrapperState = useRef(storedScrollState)

    // Save scroll position on storage before unmount
    useEffect(() => {
      return () => {
        if (wrapperRef.current) {
          const state = {
            offsetHeight: wrapperRef.current.offsetHeight,
            scrollHeight: wrapperRef.current.scrollHeight,
            // eslint-disable-next-line react-hooks/exhaustive-deps
            scrollTop: wrapperRef.current.scrollTop,
          }
          setStoredScrollState(state)
          savedWrapperState.current = state
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // Reset scroll when share changes or viewing latest version
    useEffect(() => {
      if (
        initialShareIdentifier.current !== share.identifier ||
        isViewingLatestVersion
      ) {
        localStorage.removeItem(localStorageKeys.shareVersionsScrollPosition)
        initialShareIdentifier.current = share.identifier
      }
    }, [share.identifier, isViewingLatestVersion])

    // Maintain scroll position when children change
    useEffect(() => {
      if (!wrapperRef.current || !savedWrapperState.current) return

      window.requestAnimationFrame(() => {
        const wrapper = wrapperRef.current
        if (wrapper) {
          const { offsetHeight, scrollHeight, scrollTop } =
            savedWrapperState.current

          // If on the end of the list keep it there
          // because new items can be added and should be viewed
          if (offsetHeight + scrollTop === scrollHeight) {
            wrapper.scrollTop = wrapper.scrollHeight - wrapper.offsetHeight
          } else {
            wrapper.scrollTop = wrapper.scrollHeight - scrollHeight + scrollTop
          }
        }
      })
    }, [childCount, savedWrapperState])

    useEffect(() => {
      if (isViewingLatestVersion && wrapperRef.current) {
        const wrapper = wrapperRef.current
        const scrollTop = wrapper.scrollHeight - wrapper.offsetHeight

        wrapper.scrollTop = Math.max(0, scrollTop)
        savedWrapperState.current = {
          offsetHeight: wrapper.offsetHeight,
          scrollHeight: wrapper.scrollHeight,
          scrollTop: wrapper.scrollTop,
        }
      }
    }, [isViewingLatestVersion])

    return (
      <Wrapper ref={wrapperRef} {...listProps}>
        {children}
      </Wrapper>
    )
  })

export default ShareVersionsListWrapper
