import React from 'react'
import styled, { css } from 'styled-components'
import { NavLink } from 'react-router-dom'

import { withBox } from '../../Box'
import { truncateStyles } from '../../Truncate'
import { buttonUnstyledStyles } from '../../Button'
import { DropdownItemDescription } from '../DropdownItemDescription'
import { DropdownItemProps } from './types'

export const dropdownItemStyles = css<DropdownItemProps>(
  ({ theme, intent, disabled, minWidth }) => css`
    ${buttonUnstyledStyles};
    ${truncateStyles};
    display: block;
    width: 100%;
    min-width: ${minWidth ? minWidth : '150px'};
    white-space: initial;

    padding: 8px 12px;

    color: ${disabled
      ? theme.colors.foreground.secondary.D
      : intent === 'negative'
        ? theme.colors.ui.links.negative
        : theme.colors.foreground.secondary.B};
    font-size: ${theme.fontSizes.D};
    font-weight: ${theme.fontWeights.regular};
    line-height: ${({ theme }) => theme.lineHeights.default};
    text-align: left;

    border-radius: ${theme.radii.large};
    cursor: pointer;

    transition:
      background-color ${theme.transitions.duration[1]}
        ${theme.transitions.timing.easeOut},
      color ${theme.transitions.duration[1]}
        ${theme.transitions.timing.easeOutExponential};

    @media (hover: hover) {
      &:hover,
      &:focus-visible,
      &:active,
      &[aria-selected='true']:not([aria-disabled='true']) {
        background: ${theme.colors.background.tertiary.C};
        color: ${intent === 'negative'
          ? theme.colors.ui.links.negative
          : theme.colors.foreground.secondary.A};

        ${DropdownItemDescription} {
          color: ${theme.colors.foreground.secondary.B};
        }
      }
    }

    &[aria-disabled='true'] {
      color: ${theme.colors.foreground.secondary.D};
      pointer-events: none;
    }

    &.active,
    &.is-active, /* used for text editor */
    &[data-current] {
      background-color: ${theme.colors.sketch.F};
      color: ${theme.colors.sketch.A};

      font-weight: ${theme.fontWeights.medium};

      ${DropdownItemDescription} {
        color: ${theme.colors.foreground.secondary.D};
      }
    }
  `
)

export const ListItem = styled.li`
  list-style: none;
  padding: 0 8px;
`

export const DropdownItemAnchorStyle = styled.a`
  ${dropdownItemStyles};
`

export const DropdownItemNavLinkStyle = styled(({ ...rest }) => (
  <NavLink {...rest} />
))`
  ${dropdownItemStyles};
`

export const DropdownItemStyle = styled(withBox('button'))`
  ${dropdownItemStyles};
`

export const DropdownItemWrapper = styled.span`
  ${dropdownItemStyles};
`

export const DropdownShortcutText = styled.span`
  color: ${({ theme }) => theme.colors.foreground.secondary.D};
  margin-left: 16px;
  float: right;

  @media (hover: none) and (pointer: coarse) {
    /* Hide this so mobile devices should not see it */
    display: none;
  }
`
