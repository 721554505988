import styled, { css, keyframes } from 'styled-components'
import { withBox } from '../Box'
import type { InputProps } from './Input'

const getPadding = ({
  small,
  hasIcon,
  prefixWidth,
  hasRightIcon,
}: {
  small?: boolean
  hasIcon?: boolean
  hasRightIcon?: boolean
  prefixWidth?: number
}) => {
  const borderPadding = {
    small: prefixWidth ? `7px 16px 7px ${prefixWidth}px` : `7px 16px`,
    smallWithIcon: `10px 12px 10px 42px`,
    regular: prefixWidth
      ? `10px ${hasRightIcon ? '32px' : '12px'} 10px ${prefixWidth}px`
      : `10px ${hasRightIcon ? '32px' : '10px'} 10px 10px`,
    regularWithIcon: `10px 12px 10px 42px`,
  }

  if (small) {
    return hasIcon ? borderPadding.smallWithIcon : borderPadding.small
  }

  return hasIcon ? borderPadding.regularWithIcon : borderPadding.regular
}

export const inputBorderStyle = css<InputProps>`
  ${({
    small,
    disabled,
    invalid,
    theme,
    hasIcon,
    $hasRightIcon,
    $prefixWidth = 0,
  }) => css`
    width: 100%;
    padding: ${getPadding({
      small,
      hasIcon,
      hasRightIcon: $hasRightIcon,
      prefixWidth: $prefixWidth,
    })};

    border: none;
    border-radius: 6px;
    outline-offset: unset;

    transition:
      border-color ${theme.transitions.duration[1]}
        ${theme.transitions.timing.easeInOut},
      padding ${theme.transitions.duration[4]}
        ${theme.transitions.timing.easeOutExponential};

    ::placeholder {
      color: ${theme.colors.foreground.secondary.D};
      opacity: 1; /* Override Firefox default */
    }

    ::selection {
      background-color: ${invalid
        ? theme.colors.state.negative.E
        : theme.colors.sketch.E};
    }

    /* Add the disabled look and feel to the stripe inputs */
    ${disabled &&
    css`
      color: ${theme.colors.foreground.secondary.D};
      background-color: ${theme.colors.background.secondary.B};

      border: 1px dashed ${theme.colors.border.A};
    `};

    ${invalid &&
    css`
      border: 2px solid ${theme.colors.state.negative.A};
    `};

    &.StripeElement--focus {
      ${invalid &&
      css`
        border: none;
      `};
    }

    &.StripeElement--invalid {
      ${css`
        border: 2px solid ${theme.colors.state.negative.A};
      `};
    }
  `}
`

export const inputBaseStyles = css<InputProps>`
  ${({ small, stripeFakeFocus, $prefixWidth, theme }) => css`
    display: block;
    position: relative;

    height: ${small ? `38px` : `40px`};
    width: 100%;
    padding: ${$prefixWidth ? $prefixWidth : small ? `8px` : `10px`};

    background-color: ${theme.colors.background.tertiary.C};
    color: ${theme.colors.foreground.secondary.A};
    appearance: none;

    font-size: ${theme.fontSizes.E};

    ${stripeFakeFocus &&
    css`
      background-color: ${theme.colors.background.secondary.A};
    `}

    &:disabled {
      color: ${theme.colors.foreground.secondary.D};
      background-color: ${theme.colors.background.secondary.B};
    }

    /**
     * Work-around for the border animation noted in the issue
     * https://github.com/sketch-hq/Cloud/issues/595.
     *
     * Because the initial border color is "black" when adding style,
     * do to "transition: border-color 0.15s ease-in-out;" the color will animate
     * to the final color.
     *
     * making sure that the color is already the correct one before appending other classes
     * prevents that.
     */
    border-color: ${theme.colors.border.B};

    &[type='text'],
    &[type='email'],
    &[type='url'],
    &[type='password'],
    &[type='search'],
    &[type='button'],
    &[type='number'],
    &.StripeElement {
      ${inputBorderStyle}
    }
  `}
`

export const InputWithBox = styled(withBox('input'))<InputProps>`
  ${inputBaseStyles}
`

export const fadeInOut = keyframes`
  0% { opacity: 0; }
  100% { opacity: 1; }
`

export const Wrapper = styled.div`
  position: relative;
  width: 100%;
`

export const Prefix = styled.span<Pick<InputProps, 'small'>>`
  ${({ small, theme }) => css`
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
    color: ${theme.colors.state.disabled};
    padding-left: ${small ? '8px' : '12px'};
  `}
`

export const IconWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 12px;
  display: flex;
  color: ${({ theme }) => theme.colors.foreground.secondary.D};
  transform: translateY(-50%);
  z-index: 1;
  opacity: 1;

  animation: ${fadeInOut} ${({ theme }) => theme.transitions.duration[2]};
`

export const RightIconWrapper = styled.div<{ $small?: boolean }>`
  position: absolute;
  top: 50%;
  right: ${({ $small }) => ($small ? '8px' : '12px')};
  display: flex;
  color: ${({ theme }) => theme.colors.foreground.secondary.D};
  transform: translateY(-50%);
  z-index: 1;
  opacity: 1;

  animation: ${fadeInOut} ${({ theme }) => theme.transitions.duration[2]};
`
