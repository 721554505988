import styled from 'styled-components'
import { Truncate } from '@sketch/components'

const WorkspaceNameText = styled(Truncate)`
  color: ${({ theme }) => theme.colors.foreground.secondary.A};
  font-size: 0.875rem;
  line-height: ${({ theme }) => theme.lineHeights.default};
  font-weight: 500;
`

const WorkspaceHelpText = styled(Truncate)`
  width: 100%;
  color: ${({ theme }) => theme.colors.foreground.secondary.D};
  font-size: ${({ theme }) => theme.fontSizes.D};
`

export { WorkspaceHelpText, WorkspaceNameText }
