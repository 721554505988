import { parse } from 'query-string'
import { useEffect, useMemo, useRef } from 'react'
import { useLocation } from 'react-router'

// eslint-disable-next-line no-restricted-imports
import { AnnotationSortOrder } from '@sketch/gql-types/expansive'

import { AnnotationStatusFilter } from '../../../types'

const activeStatusParams = {
  sort: 'NEW_FIRST' as AnnotationSortOrder,
  annotationStatus: 'ACTIVE_ONLY' as AnnotationStatusFilter,
}

const resolvedStatusParams = {
  sort: 'LATEST_RESOLVED_FIRST' as AnnotationSortOrder,
  annotationStatus: 'RESOLVED_ONLY' as AnnotationStatusFilter,
}

const allStatusParams = {
  sort: 'LATEST_FIRST' as AnnotationSortOrder,
  annotationStatus: 'ALL' as AnnotationStatusFilter,
}

const getGQLQueryVarsFromAnnotationStatusFilter = (
  annotationStatusFilter?: string
) => {
  switch (annotationStatusFilter) {
    case 'all':
      return allStatusParams
    case 'resolved':
      return resolvedStatusParams
    case 'open':
      return activeStatusParams
    default:
      return activeStatusParams
  }
}

export const useAnnotationStatusFilter = () => {
  const location = useLocation()

  const queryParams = parse(location.search)
  const queryParamsRef = useRef(queryParams)

  useEffect(() => {
    queryParamsRef.current = queryParams
  }, [queryParams])

  const annotationStatusFilter = Array.isArray(queryParams.annotationStatus)
    ? queryParams.annotationStatus[0]
    : queryParams.annotationStatus || undefined

  const annotationSortAndStatusParams =
    getGQLQueryVarsFromAnnotationStatusFilter(
      annotationStatusFilter?.toLowerCase()
    )

  return useMemo(
    () => ({
      annotationStatusFilter,
      annotationQuerySort: annotationSortAndStatusParams.sort,
      annotationQueryStatus: annotationSortAndStatusParams.annotationStatus,
    }),
    [
      annotationSortAndStatusParams.annotationStatus,
      annotationSortAndStatusParams.sort,
      annotationStatusFilter,
    ]
  )
}
