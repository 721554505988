import React from 'react'
import { Text, Checkbox, Tooltip, Link } from '@sketch/components'
import { AccessLevelSelectStyledPill, useFlag } from '@sketch/modules-common'
import { useStableHandler } from '@sketch/utils'
import { ShareWithoutVersion } from '../../../versioning'
import usePublicPermissions from '../ShareSettingsModal/PublicPermissions/usePublicPermissions'
import { usePublicInlineModal } from '../ShareSettingsModal/PublicPermissions'
import { useGetWorkspaceQuery } from '@sketch/gql-types'
// Usage of PublicAccessLevel and PrivateAccessLevel will not break the build
// eslint-disable-next-line no-restricted-imports
import { PublicAccessLevel } from '@sketch/gql-types/expansive'
import {
  Arrow,
  QuestionMarkIcon,
  StyledCheckbox,
  StyledRadioButton,
} from './SharingModal.styles'

const LEARN_MORE_LINK =
  'https://www.sketch.com/docs/sharing-and-collaborating/sharing-and-viewing-documents/managing-share-settings/#sharing-a-document-via-public-link'

export const EveryoneTitle = ({ isPublic }: { isPublic: boolean }) => (
  <>Everyone ({!isPublic ? 'No Access' : 'Public'})</>
)

export const EveryonePanel = ({ share }: { share: ShareWithoutVersion }) => {
  const isPublishToCommunityEnabled = useFlag('publish-community')
  const [modal, openModal] = usePublicInlineModal(
    share.name,
    share.workspace.identifier
  )

  const { publishShare, unpublishShare, updatePublicPermissions, permissions } =
    usePublicPermissions({
      share,
      workspaceIdentifier: share.workspace.identifier,
    })

  const { data: dataWorkspace } = useGetWorkspaceQuery({
    fetchPolicy: 'cache-only',
    variables: { identifier: share.workspace.identifier },
    skip: !isPublishToCommunityEnabled,
  })

  const { publicInspectEnabled, publicCommentsEnabled, publicAccessLevel } =
    permissions

  const handleSetInspectEnabled = (e: React.ChangeEvent<HTMLInputElement>) => {
    const publicInspectEnabled = e?.target?.checked ?? false
    updatePublicPermissions({ publicInspectEnabled })
  }

  const handleSetCommentsEnabled = (e: React.ChangeEvent<HTMLInputElement>) => {
    const publicCommentsEnabled = e?.target?.checked ?? false
    updatePublicPermissions({ publicCommentsEnabled })
  }

  const handlePublicDocumentChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    handleSetLevel(e.target.value as PublicAccessLevel)
  }

  const handleSetLevel = async (level: PublicAccessLevel) => {
    const downgradeToNonePermission =
      publicAccessLevel === 'VIEW' && level === 'NONE'

    if (downgradeToNonePermission && share.publication) {
      openModal('confirm-private-community', () => {
        unpublishShare({ publicAccessLevel: level })
      })
    } else if (downgradeToNonePermission) {
      openModal('confirm-private', () => {
        updatePublicPermissions({ publicAccessLevel: level })
      })
    } else {
      updatePublicPermissions({ publicAccessLevel: level })
    }
  }

  const handleSetPublicationEnabled = useStableHandler(e => {
    const publicationEnabled = e?.target?.checked ?? false
    if (publicationEnabled) {
      openModal('confirm-publish-community', publishShare)
    } else {
      openModal('confirm-unpublish-community', () => {
        unpublishShare({ publicAccessLevel: 'VIEW' })
      })
    }
  })

  const level =
    (publicAccessLevel as string) === 'INSPECT' ? 'VIEW' : publicAccessLevel

  const isPublic = level === 'VIEW'

  const restrictedPublicLinks =
    dataWorkspace?.workspace.features.restrictedPublicLinks ?? false

  return (
    <>
      <Text textStyle="copy.tertiary.standard.E">
        Anyone with the link to this document&hellip;
      </Text>
      <StyledRadioButton
        name="publicDoc"
        label="Can view the document"
        checked={isPublic}
        value="VIEW"
        onChange={handlePublicDocumentChange}
      />
      <StyledRadioButton
        name="publicDoc"
        label="Cannot access the document"
        checked={!isPublic}
        disabled={isPublic && !!share.publication && !share.userCanPublish}
        value="NONE"
        onChange={handlePublicDocumentChange}
      />
      <Text textStyle="copy.tertiary.standard.E" pt={3}>
        Anyone with the public link can:
      </Text>
      <StyledCheckbox
        value={publicInspectEnabled ? 1 : 0}
        checked={publicInspectEnabled}
        name="inspectEnabled"
        data-testid="share-inspect"
        label="Inspect and Download"
        disabled={!isPublic}
        onChange={handleSetInspectEnabled}
      />
      <StyledCheckbox
        value={publicCommentsEnabled ? 1 : 0}
        checked={publicCommentsEnabled}
        name="commentsEnabled"
        data-testid="share-comments"
        label="Comment"
        disabled={!isPublic || !share.userCanUpdateCommentsEnabled}
        onChange={handleSetCommentsEnabled}
        help={
          <>
            Requires a Sketch account
            <Tooltip
              placement="top"
              disableWhenTouchDevice={false}
              tooltipContainerAs="span"
              content={
                <>
                  Learn More
                  <Arrow />
                </>
              }
            >
              <Link href={LEARN_MORE_LINK} variant="tertiary" external>
                <QuestionMarkIcon />
              </Link>
            </Tooltip>
          </>
        }
      />
      {((isPublishToCommunityEnabled && share.userCanPublish) ||
        share.publication) && (
        <>
          <Text textStyle="copy.tertiary.standard.E" pt={6}>
            Public Profile{' '}
            <AccessLevelSelectStyledPill variant="secondary">
              Admin
            </AccessLevelSelectStyledPill>
          </Text>
          <Checkbox
            data-testid="community-enabled"
            label="Publish to profile"
            help="Can be publicly viewed and downloaded"
            onChange={handleSetPublicationEnabled}
            checked={!!share.publication}
            disabled={
              !!restrictedPublicLinks || !isPublic || !share.userCanPublish
            }
          />
        </>
      )}
      {modal}
    </>
  )
}
