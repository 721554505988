import React, { useEffect } from 'react'

// Containers
import { useBanner } from '@sketch/components'

import { PersonalWorkspaceBanner } from './Banners'

// GQL
import { WorkspaceMinimalFragment } from '@sketch/gql-types'

type WorkspaceType = WorkspaceMinimalFragment['type']

interface PersonalWorkspaceBannersProps {
  workspaceId: string
  workspaceType: WorkspaceType
  isOwner: boolean
}

export const PersonalWorkspaceBanners: React.FC<
  PersonalWorkspaceBannersProps
> = props => {
  const { workspaceId, workspaceType, isOwner } = props
  const { showBanner } = useBanner()

  useEffect(() => {
    if (workspaceType === 'PERSONAL' && isOwner) {
      const banner = showBanner({
        id: 'upgrade-personal-workspace',
        message: <PersonalWorkspaceBanner workspaceId={workspaceId} />,
        type: 'warning',
      })

      return () => {
        banner?.dismissBanner()
      }
    }
  }, [showBanner, workspaceId, workspaceType, isOwner])

  return null
}
