import * as React from "react";
import { forwardRef } from "react";
const ReactComponent = ({ title, titleId, ...props }, ref) => React.createElement("svg", { "data-testid": "padding-horizontal-11", xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 11 11", ref: ref, "aria-labelledby": titleId, ...props },
    title ? React.createElement("title", { id: titleId }, title) : null,
    React.createElement("g", { fill: "none", fillRule: "evenodd" },
        React.createElement("rect", { width: 8, height: 8, x: 1.5, y: 1.5, stroke: "currentColor", opacity: 0.35, rx: 2.5 }),
        React.createElement("rect", { width: 1, height: 5, x: 3, y: 3, fill: "currentColor", rx: 0.5 }),
        React.createElement("rect", { width: 1, height: 5, x: 7, y: 3, fill: "currentColor", rx: 0.5 })));
const ForwardRef = forwardRef(ReactComponent);
export { ForwardRef as ReactComponent };
