import React from 'react'
import ProjectShareOld from '../ProjectSharesOld'
import ProjectShare, { ProjectSharesProps } from './ProjectShares'
import { useFlag } from '@sketch/modules-common'

export default function ProjectSharesSwitch(props: ProjectSharesProps) {
  const isNestedProjectsOn = useFlag('nested-projects')

  return isNestedProjectsOn ? (
    <ProjectShare {...props} />
  ) : (
    <ProjectShareOld {...props} />
  )
}
