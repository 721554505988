import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import isEqual from 'react-fast-compare'

import { ButtonUnstyled } from '@sketch/components'

import {
  AnnotationDotFragment,
  useGetAnnotationDotQuery,
} from '@sketch/gql-types'

import AnnotationPopover from '../AnnotationPopover'
import AnnotationDot from '../AnnotationDot'
import { AnnotationOverlayDotProps } from '../AnnotationOverlayDot'

import {
  useAnnotationOverlayContext,
  useAnnotationPopover,
  useDotCoordinateStyle,
  useTranslateDot,
} from '../../hooks'

import {
  GetArtboardBoundsSelector,
  GetPrefixCoordinatesSelector,
  ArtboardContainerBounds,
} from '../../types'

import {
  getAnnotationDotFlavour,
  getCleanAnnotationCoordinates,
} from '../../utils'

import { AnnotationDotWrapper } from './AnnotationOverlayActiveDot.styles'
import { useToast } from '@sketch/toasts'

const ANNOTATION_NOT_FOUND_ERROR_MESSAGE = 'Annotation not found'

interface AnnotationOverlayActiveDotProps
  extends OmitSafe<AnnotationOverlayDotProps, 'coordinates' | 'flavour'> {
  parentWrapper: React.RefObject<HTMLElement>
  onHide?: () => void
  onActiveAnnotation?: (annotation: AnnotationDotFragment) => void
  getPrefixCoordinates?: GetPrefixCoordinatesSelector
  getArtboardBounds?: GetArtboardBoundsSelector
  getArtboardContainerBounds?: () => ArtboardContainerBounds | undefined
  getDotAdditionalStyle?: (
    annotation: AnnotationDotFragment
  ) => React.CSSProperties | undefined
}

const AnnotationOverlayActiveDot = (props: AnnotationOverlayActiveDotProps) => {
  const {
    annotationIdentifier,
    parentWrapper,
    onHide,
    getPrefixCoordinates,
    getArtboardBounds,
    getArtboardContainerBounds,
    referenceBounds,
    onMoveDrop,
    onActiveAnnotation,
    getDotAdditionalStyle,
  } = props

  const overlayContext = useAnnotationOverlayContext()
  const { showToast } = useToast()

  /**
   * The "useGetAnnotationDotQuery" is using a cache-redirect to
   * use the already cached information about the annotations to
   * render the selected dot on it's correct position.
   *
   * This can be useful when:
   * - A legacy annotation need to be rendered and the list has already filter it
   * - Annotation dots are hidden and the user clicks on the sidebar to select it
   * - A new annotation optimistic version is on the cache and we need to render it
   *
   * This prevent having to save data objects in state and use cache only
   */
  const { data } = useGetAnnotationDotQuery({
    variables: { identifier: annotationIdentifier },
    fetchPolicy: 'cache-first',
    onError: error => {
      const errorMessage = error?.graphQLErrors.map(({ message }) => message)[0]
      const isNotFound = errorMessage === ANNOTATION_NOT_FOUND_ERROR_MESSAGE

      if (isNotFound) {
        showToast('Comment not found', 'negative')
        onHide?.()
      }
    },
  })

  const annotation = data?.annotation
  const { isOptimistic, resolution } = annotation || {}

  const coordinates = useMemo(
    () => getCleanAnnotationCoordinates(annotation?.coordinates),
    [annotation?.coordinates]
  )
  const prefixBounds = annotation
    ? getPrefixCoordinates?.(annotation)
    : undefined
  const artboardBounds = annotation
    ? getArtboardBounds?.(annotation)
    : undefined

  const coordinatesStyle = useDotCoordinateStyle({
    coordinates,
    referenceBounds,
    prefixBounds,
    artboardBounds,
  })

  /**
   * We are caching this created function because
   * we only want the "onActiveAnnotation" when a new annotation identifier
   * is set, so if there are changes on the annotation fields but the annotation identifier
   * is the same, those shouldn't call "onActiveAnnotation"
   *
   * The logic above should still allow the annotation parameter to be passed (updated)
   */
  const handleActiveAnnotation = useRef(
    () => annotation && onActiveAnnotation?.(annotation)
  )
  useEffect(() => {
    handleActiveAnnotation.current = () =>
      annotation && onActiveAnnotation?.(annotation)
  }, [annotation, onActiveAnnotation])

  useEffect(() => {
    handleActiveAnnotation?.current()
  }, [annotation?.identifier])

  const [isMoveMutationInFlight, setMoveMutationInFlight] = useState(false)

  const [content, { ref, onClick, ...buttonProps }, { update, setVisible }] =
    useAnnotationPopover({
      dropdown: AnnotationPopover,
      dropdownProps: {
        annotationIdentifier,
        cacheOnly: isOptimistic || isMoveMutationInFlight,
      },
      parentWrapper,
      onHide,
      hideCancel: true,
    })

  const { hasMoved, style, ...translateDot } = useTranslateDot({
    annotationIdentifier,
    coordinates,
    getArtboardContainerBounds,
    prefixBounds,
    disabled: !!resolution,
    onMoveDrop: useCallback(
      async mouseCoordinates => {
        setMoveMutationInFlight(true)
        await onMoveDrop?.(annotationIdentifier, mouseCoordinates)
        setMoveMutationInFlight(false)
      },
      [annotationIdentifier, onMoveDrop]
    ),
    onClick: useCallback(
      (event, hasMoved) => {
        /**
         * If the dot moved we want to prevent any action from happening
         * this will be the case for opening or closing the annotation
         */
        if (!hasMoved) {
          onClick?.(event as React.MouseEvent<HTMLAnchorElement>)
        }

        /**
         * Prevent the click event from propagating
         * so it doesn't reach the canvas and closes
         * the annotation
         */
        event.stopPropagation()
      },
      [onClick]
    ),
  })

  /**
   * Hide the annotation when the moving
   * so the user can move freely.
   */
  useEffect(() => {
    setVisible(!hasMoved)
  }, [hasMoved, setVisible])

  /**
   * If the annotation identifier updated
   * the active dot might be changed therefore we might need
   * to update the dropdown
   */
  useLayoutEffect(() => {
    update?.()
  }, [annotationIdentifier, update])

  const shouldRenderAnnotation = overlayContext?.shouldRenderAnnotation

  /**
   * Validate if the annotation requested is inside of the
   * scope the viewer is currently looking at. If not
   * the annotation shouldn't be visible.
   */
  useEffect(() => {
    if (annotation && !shouldRenderAnnotation?.(annotation, true)) {
      onHide?.()
    }
  }, [annotation, shouldRenderAnnotation, onHide])

  /**
   * If the annotation doesn't exist in cache
   * we shouldn't render anything. Also helps with the typescript
   * validations
   */
  if (!annotation) {
    return null
  }

  const additionalDotStyle = getDotAdditionalStyle?.(annotation)

  return (
    <AnnotationDotWrapper
      ref={ref}
      style={{ ...coordinatesStyle, ...style }}
      // This value is used here to signal "AnnotationOverlayDot" there an active annotation
      aria-current="true"
    >
      {content}
      <ButtonUnstyled {...buttonProps} {...translateDot}>
        <AnnotationDot
          flavour={getAnnotationDotFlavour(annotation)}
          selected
          style={additionalDotStyle}
        />
      </ButtonUnstyled>
    </AnnotationDotWrapper>
  )
}

export default React.memo(AnnotationOverlayActiveDot, isEqual)
