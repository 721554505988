import { useEffect, useRef, useState } from 'react'

export type ExportPreviewData = {
  blob: Blob
  layerWidth: number
  layerHeight: number
  imageWidth: number
  imageHeight: number
}

export function useExportPreview(
  getExportPreviewData: () => Promise<ExportPreviewData | null>
) {
  const createPreviewUrlRef = useRef(getExportPreviewData)
  createPreviewUrlRef.current = getExportPreviewData

  const [previewExportResult, setPreviewExportResult] =
    useState<ExportPreviewData | null>(null)

  useEffect(() => {
    const exportPreview = async () => {
      const createPreview = createPreviewUrlRef.current
      const exportResult = await createPreview()
      if (exportResult) {
        setPreviewExportResult(exportResult)
      }
    }

    exportPreview()
  }, [])

  return previewExportResult
}
