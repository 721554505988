import { useMutation as useOriginalMutation } from '@apollo/react-hooks'
import { OperationVariables } from 'apollo-client'
import { DocumentNode } from 'graphql'

import { useFixedMutationTuple } from './useFixedMutationTuple'
import { useErrorRedirect } from './useErrorRedirect'
import { useParseErrorHandler } from './useParseErrorHandler'
import {
  MutationHookOptions,
  MutationOptionsWithRedirectedErrors,
  MutationTuple,
} from './useMutation.types'

export function useMutation<
  Data extends Record<string, any>,
  Variables = OperationVariables,
>(
  mutation: DocumentNode,
  options: MutationHookOptions<Data, Variables>
): MutationTuple<Data, Variables> {
  const { onError, ignoreResults } = options || {}
  const { UNSAFE_ignoreResults } = (options ||
    {}) as MutationOptionsWithRedirectedErrors<Data, Variables>

  const { onUserErrorHandler, onApolloErrorHandler, onErrorDispatch } =
    useParseErrorHandler({ onError })

  const augmentedOptions = useErrorRedirect<Data, Variables>(options, {
    onUserErrorHandler,
  })

  const originalMutationTuple = useOriginalMutation(mutation, {
    ignoreResults: ignoreResults || UNSAFE_ignoreResults,
    ...options,
    // here we expect to optionally override `onCompleted`, `update` and `refetchQueries`
    // so we have to make sure that `augmentedOptions` are passed after general all `options`
    ...augmentedOptions,
    onError: onApolloErrorHandler,
  })

  const mutationTuple = useFixedMutationTuple({
    onApolloErrorHandler,
    onErrorDispatch,
    originalMutationTuple,
    redirectErrors: options.redirectErrors,
  })

  return mutationTuple
}
