import React, { useContext } from 'react'
import copy from 'copy-to-clipboard'
import { useHistory } from 'react-router'
import qs from 'qs'

import {
  Dropdown,
  useDropdownState,
  ModalContext,
  Flex,
} from '@sketch/components'
import { useToast } from '@sketch/toasts'
import {
  QueryParams,
  versionedRoutes,
  LaunchSketchAlert,
  useFlag,
} from '@sketch/modules-common'
import { IS_EMBEDDED } from '@sketch/constants'
import { useVersioning } from '../../../../versioning'

import { StyledCanvasContextMenu, AppIcon } from './CanvasContextMenu.styles'
import { ContextMenuMetaData, Coordinates } from './useCanvasContextMenu'
import { INSPECTOR_SEGMENT } from '../../../constants'
import {
  useAnnotationOverlayContext,
  useCreateDraftAnnotation,
} from '../../../../annotations/hooks'

type CanvasContextMenuProps = {
  permanentPageId: string
  contextMenuPos: Coordinates | null
  contextMenuMetaData: ContextMenuMetaData | null
  closeTooltip: () => void
  permitAddingAnnotations?: boolean
}

/**
 * Menu displayed when right clicking on the canvas.
 */
function CanvasContextMenu({
  contextMenuPos,
  contextMenuMetaData,
  closeTooltip,
  permanentPageId,
  permitAddingAnnotations,
}: CanvasContextMenuProps) {
  const { versionShortId, getPathname, share } = useVersioning()
  const { showToast } = useToast()
  const { showModal } = useContext(ModalContext)
  const history = useHistory()
  const isFramesWebOn = useFlag('frames-web')

  const annotationsContext = useAnnotationOverlayContext()
  const handleDraftAnnotationEvent = useCreateDraftAnnotation(share.identifier)

  const dropdown = useDropdownState({
    onToggle: closeTooltip,
  })

  if (contextMenuPos === null || !contextMenuMetaData) {
    return null
  }

  function handleCopyLinkToCoordinates() {
    // We want to always use a versioned route (rather than implicitly linking
    // to the latest version) so that URLs are not affected when a new version
    // is created.
    let currentPathname
    if (permanentPageId) {
      // Only include a pageUUID if the user has navigated away from the first page
      currentPathname = versionedRoutes.SHARE_PAGE_CANVAS_VIEW.VERSION.create({
        pageUUID: permanentPageId,
        shareID: share.identifier,
        versionShortId,
      })
    } else {
      currentPathname = versionedRoutes.SHARE_VIEW.VERSION.create({
        shareID: share.identifier,
        versionShortId,
      })
    }

    const queryParams: QueryParams<'SHARE_PAGE_CANVAS_VIEW'> = {
      posX: contextMenuMetaData!.canvasPanPosition.posX.toString(),
      posY: contextMenuMetaData!.canvasPanPosition.posY.toString(),
      zoom: contextMenuMetaData!.zoom.toString(),
    }

    const link = `${window.location.origin}${currentPathname}?${qs.stringify(
      queryParams
    )}`
    copy(link)
    showToast('Link copied')
    closeTooltip()
  }

  function handleCopyLinkToFrame() {
    if (!contextMenuMetaData?.artboardUUID) {
      return
    }
    const currentPathname = getPathname({
      routeKey: 'ARTBOARD_DETAIL_UUID',
      routeParams: {
        shareID: share.identifier,
        artboardUUID: contextMenuMetaData.artboardUUID,
      },
    })

    const link = `${window.location.origin}${currentPathname}`
    copy(link)
    showToast('Link copied')
    closeTooltip()
  }

  function handleEdit() {
    closeTooltip()
    showModal(LaunchSketchAlert, {
      shareId: share.identifier,
      pageUUID: permanentPageId,
      action: 'openInSketch',
    })
  }

  function handleInspectFrame() {
    if (!contextMenuMetaData?.artboardUUID) {
      return
    }

    const currentPathname = getPathname({
      routeKey: 'ARTBOARD_DETAIL_UUID',
      routeParams: {
        shareID: share.identifier,
        artboardUUID: contextMenuMetaData.artboardUUID,
      },
    })

    closeTooltip()
    history.push(`${currentPathname}#${INSPECTOR_SEGMENT}`)
  }

  const handleAddAnnotation = (event: React.MouseEvent) => {
    handleDraftAnnotationEvent(
      {
        clientX: contextMenuPos.posX,
        clientY: contextMenuPos.posY,
      },
      true
    )
    closeTooltip()
  }

  const hasFrameUUID = contextMenuMetaData.artboardUUID !== null

  const dropdownTitle = hasFrameUUID
    ? isFramesWebOn
      ? 'Frame'
      : 'Artboard'
    : 'Canvas'
  const showInspectFrame = share.userCanInspect && hasFrameUUID && !IS_EMBEDDED

  return (
    <StyledCanvasContextMenu
      {...dropdown}
      placement="bottom-start"
      spacing="0px"
      style={{
        left: contextMenuPos.posX,
        top: contextMenuPos.posY,
      }}
      visible={true}
      onClickOutside={closeTooltip}
    >
      <Dropdown.Header>{dropdownTitle}</Dropdown.Header>

      {contextMenuMetaData.artboardUUID !== null && (
        <Dropdown.Item onClick={handleCopyLinkToFrame}>Copy Link</Dropdown.Item>
      )}

      <Dropdown.Item onClick={handleCopyLinkToCoordinates}>
        Copy Link from Position
      </Dropdown.Item>

      {showInspectFrame && (
        <Dropdown.Item onClick={handleInspectFrame}>
          View {isFramesWebOn ? 'Frame' : 'Artboard'}
        </Dropdown.Item>
      )}

      {(share.commentsEnabled || share.userCanEdit) && <Dropdown.Divider />}

      {share.commentsEnabled && annotationsContext?.isViewingLatestVersion && (
        <Dropdown.Item onClick={handleAddAnnotation}>
          Add Comment…
        </Dropdown.Item>
      )}

      {share.userCanEdit && (
        <Dropdown.Item onClick={handleEdit}>
          <Flex alignItems="center">
            Edit in <AppIcon alt="Sketch" />
          </Flex>
        </Dropdown.Item>
      )}
    </StyledCanvasContextMenu>
  )
}

export default CanvasContextMenu
