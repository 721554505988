import React, { FC } from 'react'

import { localStorageKeys } from '@sketch/constants'

import { dateFormat, getParsedItem, setStringifiedItem } from '@sketch/utils'

import { WorkspaceApprovedModal } from '../WorkspaceApprovedModal'
import StickerEducation2x from '@sketch/icons/sticker-education-128@2x.png'
import StickerEducation from '@sketch/icons/sticker-education-128.png'

import { ModalInjectedProps } from '@sketch/components'
interface EducationWorkspaceApprovedModalProps extends ModalInjectedProps {
  endDate: string
  workspaceId: string
}

const EducationWorkspaceApprovedModal: FC<
  EducationWorkspaceApprovedModalProps
> = ({ hideModal, endDate, workspaceId }) => {
  const date = dateFormat(new Date(endDate))

  const handleHideModal = () => {
    // Grab all dismissed workspace ID's from local storage
    const previouslyDismissedIDs =
      getParsedItem(localStorageKeys.dismissedEducationApprovedModal) ?? []

    const currentDismissedIDs = [...previouslyDismissedIDs, workspaceId]

    // Set the current workspace ID to the list of dismissed modals
    setStringifiedItem(
      localStorageKeys.dismissedEducationApprovedModal,
      currentDismissedIDs
    )
    hideModal()
  }

  return (
    <WorkspaceApprovedModal
      title="Workspace Approved!"
      buttonText="Go to Workspace"
      description={`Good news — your Education Workspace was approved and is free to use
    until ${date}. Enjoy!`}
      image={{ src: StickerEducation, src2x: StickerEducation2x }}
      hideModal={handleHideModal}
    />
  )
}

export default EducationWorkspaceApprovedModal
