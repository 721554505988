import React, { useRef } from 'react'

import {
  TruncateWithTooltip,
  HighlightedText,
  useBreakpoint,
} from '@sketch/components'

import {
  ListGridItem,
  ItemImage,
  ItemName,
  ItemImageWrapper,
  ItemSecondary,
} from '../../components/Grid'

import useResponsiveImage from '../../hooks/useResponsiveImage'
import {
  useInspectContext,
  useComponentsDescriptionContext,
  ComponentDescriptionProps,
} from '../../context'
import { useOnClickOutside } from '@sketch/utils'

// This is a valid use case to validate TextStyle
// eslint-disable-next-line no-restricted-imports
import { LayerStyle } from '@sketch/gql-types/expansive'
import type { File } from '../../types'

import { useAnalytics, useBrowserSettings } from '@sketch/modules-common'

interface LayerStyleListItemProps {
  layerStyle: LayerStyle
  searchValue: string
  groupName?: string
  pendingDescription: ComponentDescriptionProps
}

export const LayerStyleListItem: React.FC<LayerStyleListItemProps> = ({
  layerStyle,
  searchValue,
  groupName,
  pendingDescription,
}) => {
  const isTabletOrBigger = useBreakpoint('sm')
  const isMobile = !isTabletOrBigger

  const { selectedItem, handleSelectItem } = useInspectContext()
  const { resetComponentDescriptionSelection, onComponentSelected } =
    useComponentsDescriptionContext()

  const [data, updateBrowserSettings] = useBrowserSettings()
  const isSidebarRightOpen = data?.sidebarRightOpen
  const { trackEvent } = useAnalytics()

  const contentRef = useRef(null)

  useOnClickOutside(
    contentRef,
    e => {
      handleSelectItem(null)
      resetComponentDescriptionSelection()
    },
    {
      includeSelectors: [
        '[data-testid=side-bar-right]',
        '[data-testid=popover-content]',
      ],
    }
  )

  const imageProps = useResponsiveImage({
    name: layerStyle.name,
    files: layerStyle.files as File[],
  })

  return (
    <ListGridItem
      data-component={layerStyle.identifier}
      onClick={() => {
        if (isMobile) {
          return
        }

        if (searchValue) {
          trackEvent('CWV - search click', { type: 'text styles' })
        }

        handleSelectItem(layerStyle.identifier)
        onComponentSelected(layerStyle.uuid, pendingDescription)

        if (!isSidebarRightOpen) {
          updateBrowserSettings({ sidebarRightOpen: true })
        }
      }}
      ref={contentRef}
    >
      <ItemImageWrapper
        isSelected={selectedItem === layerStyle.identifier}
        needsContrastingBackground={layerStyle.needsContrastingBackground}
      >
        <ItemImage {...imageProps} />
      </ItemImageWrapper>
      <ItemName>
        <TruncateWithTooltip>
          {searchValue !== '' ? (
            <HighlightedText search={searchValue}>
              {layerStyle.name}
            </HighlightedText>
          ) : (
            layerStyle.name
          )}
        </TruncateWithTooltip>
      </ItemName>
      {searchValue !== '' && (
        <ItemSecondary>
          <TruncateWithTooltip>
            <HighlightedText search={searchValue}>{groupName}</HighlightedText>
          </TruncateWithTooltip>
        </ItemSecondary>
      )}
    </ListGridItem>
  )
}
