import React from 'react'

import { ModalInjectedProps } from '@sketch/components'

import { useEditCollection } from '../../operations/useEditCollection'

import CollectionModalContent, { FormProps } from '../CollectionModalContent'

import {
  CollectionPreviewsFragment,
  ProjectFragment,
  useGetCollectionSharesUnpaginatedQuery,
} from '@sketch/gql-types'
import { ErrorHandler } from '@sketch/tracing'

interface ManageCollectionModalProps extends ModalInjectedProps {
  workspaceIdentifier: string
  project: ProjectFragment
  collection: CollectionPreviewsFragment
}

export const ManageCollectionModal = ({
  workspaceIdentifier,
  project,
  collection,
  hideModal,
}: ManageCollectionModalProps) => {
  // Used to pre-select shares when managing a collection
  const { data, loading: isLoadingCollectionShares } =
    useGetCollectionSharesUnpaginatedQuery({
      variables: {
        collectionIdentifier: collection.identifier,
        projectIdentifier: project.identifier,
      },
      onError: () => {
        ErrorHandler.shouldNeverHappen(
          'Failed to fetch collection data when managing collection'
        )
      },
      fetchPolicy: 'cache-and-network',
    })

  const shares = data?.project.collection.sharesUnpaginated ?? []

  const { editCollection, loading: isLoadingEditCollection } =
    useEditCollection({
      workspaceIdentifier,
      project,
      collection,
      hideModal,
      currentShares: shares,
    })

  const handleSubmit = (values: FormProps) => {
    editCollection({
      variables: {
        identifier: collection.identifier,
        name: values.name,
        description: values.description ?? '',
        shareIdentifiers: values.selectAll ? [] : (values.selectedShares ?? []),
        addAllProjectShares: values.selectAll,
      },
    })
  }

  const isLoading = isLoadingCollectionShares || isLoadingEditCollection

  return (
    <CollectionModalContent
      title={`Manage "${collection.name}"`}
      name={collection.name}
      description={collection?.description ?? ''}
      onSubmit={handleSubmit}
      collectionShares={shares}
      project={project}
      isLoading={isLoading}
      hideModal={hideModal}
    />
  )
}

export default ManageCollectionModal
