import React, { useState, useEffect } from 'react'
import { useToast } from '@sketch/toasts'
import {
  Checkbox,
  Banner,
  ConfirmationDialog,
  ModalInjectedProps,
} from '@sketch/components'
import { useVersionUpdateKind } from '../../hooks/useVersionUpdateKind'
import { ShareVersionDescription } from '../../components/ShareVersionDescription'
import { useUpdateDescription } from '../../hooks/useUpdateDescription'

interface StarUpdateModalProps extends ModalInjectedProps {
  versionIdentifier: string
  shareIdentifier: string
  description?: string
  isLibrary: boolean
}

export const StarUpdateModal = ({
  hideModal,
  isModalOpen,
  versionIdentifier,
  shareIdentifier,
  description,
  isLibrary,
}: StarUpdateModalProps) => {
  const { showToast } = useToast()
  const [skipModal, setSkipModal] = useState(false)
  const [newDescription, setNewDescription] = useState('')
  const [descriptionHasChanged, setDescriptionHasChanged] = useState(false)
  const [descriptionUpdateComplete, setDescriptionUpdateComplete] =
    useState(false)
  const [versionStarComplete, setVersionStarComplete] = useState(false)

  const [versionKindUpdate, { loading }] = useVersionUpdateKind({
    onCompleted: () => {
      showToast(<>A new starred Version has been created</>)
      setVersionStarComplete(true)
    },
    onError: message => showToast(message, 'negative'),
  })

  const { updateVersionDescription, versionDescriptionLoading } =
    useUpdateDescription({
      versionIdentifier,
      shareIdentifier,
      onComplete: () => setDescriptionUpdateComplete(true),
    })

  const getCopy = () => {
    if (isLibrary) {
      return (
        <>
          If you add a star to a Library version, users who subscribe to the
          Library will only receive updates for starred versions. Starred
          versions are also highlighted in the Workspace.
          <br />
          External viewers can only view the starred versions.
          <br /> Some comments on newer versions won’t be visible to those
          Viewers.
        </>
      )
    } else {
      return (
        <>
          If you share a link to this document, external Viewers can only see
          starred versions.
          <br />
          Some comments on newer versions won’t be visible to those Viewers.
        </>
      )
    }
  }

  const handleSkipModal = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSkipModal(event.target.checked)
  }

  const handleDescriptionChanged = (description: string) => {
    setDescriptionHasChanged(true)
    setNewDescription(description)
  }

  const onConfirm = () => {
    versionKindUpdate({ versionIdentifier, kind: 'PUBLISHED' })
    if (descriptionHasChanged && newDescription !== description) {
      updateVersionDescription(newDescription)
    } else {
      setDescriptionUpdateComplete(true)
    }
    localStorage.setItem(
      'user:preferences:skipStarModal',
      JSON.stringify(skipModal)
    )
  }

  const disabled = loading || versionDescriptionLoading

  useEffect(() => {
    if (descriptionUpdateComplete && versionStarComplete) {
      hideModal()
    }
  }, [descriptionUpdateComplete, versionStarComplete, hideModal])

  return (
    <ConfirmationDialog
      data-testid="create-star-update-modal"
      title={'Star this Version?'}
      hideModal={hideModal}
      isModalOpen={isModalOpen}
      cancelButton={{
        disabled: disabled,
      }}
      confirmButton={{
        variant: 'primary',
        text: 'Star Version',
        loading,
        disabled: disabled,
      }}
      onConfirm={onConfirm}
    >
      <ShareVersionDescription
        isStarred={true}
        description={description}
        handleOnChange={handleDescriptionChanged}
      />
      <Banner type="warning" showIcon={false}>
        {getCopy()}
      </Banner>
      <Checkbox
        checked={skipModal}
        onChange={handleSkipModal}
        label="Don’t ask me again"
      />
    </ConfirmationDialog>
  )
}
