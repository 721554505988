import React from 'react'
import styled from 'styled-components'

import { Flex } from '@sketch/components'

import copy from './copy'
import { capitalize, roundWithLocale } from '@sketch/utils'

import { ReactComponent as CornerRoundedIcon } from '@sketch/icons/corner.rounded.16'
import { ReactComponent as CornerAngledIcon } from '@sketch/icons/corner.angled.16'
import { ReactComponent as CornerSmoothIcon } from '@sketch/icons/corner.smooth.16'
import { ReactComponent as CornerInsideArcIcon } from '@sketch/icons/corner.inside.arc.16'
import { ReactComponent as CornerInsideSquareIcon } from '@sketch/icons/corner.inside.square.16'

import {
  Separator,
  Section,
  Header,
  HeaderTitle,
  AttributeList,
  FullCopyAttribute,
} from '../../components'
import { LayerCorners } from '../../../../../../inspector'

const CornerStyleText = styled.div`
  margin-left: 4px;
  line-height: 1.2;
`

const iconByCornerType: Record<LayerCorners['type'], typeof CornerRoundedIcon> =
  {
    rounded: CornerRoundedIcon,
    smooth: CornerSmoothIcon,
    angled: CornerAngledIcon,
    'inside arc': CornerInsideArcIcon,
    'inside square': CornerInsideSquareIcon,
  }

interface CornersProps {
  corners: LayerCorners
}

/**
 * Several layer types can have corners (rectangle, triangle, polygon or star),
 * and we also manage 2 ways of showing them, the deprecated `smoothCorners` or
 * the new one based on `cornerStyle` (coming from `layer.cornerStyle`).
 */
export const Corners: React.FC<CornersProps> = ({ corners }) => {
  const radius = corners.radius
  const hasRadius = radius.some(n => n !== 0)

  if (!hasRadius) {
    return null
  }

  // Normalized radius is the radius array simplified to have only one element
  // if all elements are the same
  const normalizedRadius = (
    hasRadius && radius.every(r => r === radius[0]) ? [radius[0]] : radius
  )
    .map(r => roundWithLocale(r, 2))
    .join(';')

  const sectionCopyValue = copy({
    radius: normalizedRadius,
    type: corners.type,
  })

  const Icon = iconByCornerType[corners.type]

  return (
    <>
      <Separator />
      <Section data-testid="inspector-sidebar-corners">
        <Header copyValue={sectionCopyValue}>
          <HeaderTitle>Corners</HeaderTitle>
        </Header>
        <AttributeList>
          <FullCopyAttribute
            label="Radius"
            value={normalizedRadius}
            copyValue={normalizedRadius}
          />
          <FullCopyAttribute
            label="Style"
            value={
              <Flex alignItems="center">
                <Icon width={16} />
                <CornerStyleText>{capitalize(corners.type)}</CornerStyleText>
              </Flex>
            }
            copyValue={capitalize(corners.type)}
          />
        </AttributeList>
      </Section>
    </>
  )
}
