import React, { useContext } from 'react'
import * as yup from 'yup'
import {
  useShareUpdateMutation,
  ShareUpdateMutation,
  DataWithoutUserErrors,
} from '@sketch/gql-types'
import { ToastContext } from '@sketch/toasts'
import {
  Button,
  Form,
  Input,
  Modal,
  ModalInjectedProps,
} from '@sketch/components'
import { Formik, FormikProps, FormikHelpers } from 'formik'
import { ErrorHandler } from '@sketch/tracing'

interface FormValues {
  name: string
}

interface RenameShareModalProps extends ModalInjectedProps {
  id: string
  name: string
}

const renameShareSchema = yup.object({
  name: yup
    .string()
    .trim()
    .max(248, 'Document name can be up to 248 characters long')
    .required('A document name is required'),
})

const RenameShareModal: React.FC<RenameShareModalProps> = props => {
  const { hideModal, id, name } = props
  const { showToast } = useContext(ToastContext)

  const onCompleted = (data: DataWithoutUserErrors<ShareUpdateMutation>) => {
    if (!data || !data.shareUpdate) {
      showToast('Something went wrong', 'negative')
      ErrorHandler.shouldNeverHappen.invalidMutationData('shareUpdate')
      return
    }

    showToast(`Document renamed to "${data.shareUpdate?.share?.name}"`)
    hideModal()
  }

  const [updateShare] = useShareUpdateMutation({
    redirectErrors: true,
    onCompleted,
    onError: 'show-toast',
  })

  const handleFormikRender = (formikBag: FormikProps<FormValues>) => {
    const { errors, values, touched, handleBlur, handleChange, isSubmitting } =
      formikBag

    return (
      <Form>
        <Modal.Body>
          <Form.Field
            name="name"
            label="Document Name"
            errorText={touched.name && errors.name ? errors.name : undefined}
          >
            <Input
              name="name"
              type="text"
              placeholder="Enter the document name..."
              value={values.name}
              onChange={handleChange}
              onBlur={handleBlur}
              disabled={isSubmitting}
              autoFocus
            />
          </Form.Field>
        </Modal.Body>
        <Modal.Footer>
          <Button disabled={isSubmitting} type="button" onClick={hideModal}>
            Cancel
          </Button>
          <Button
            loading={isSubmitting}
            type="submit"
            variant="primary"
            data-testid="submit-button"
          >
            Rename
          </Button>
        </Modal.Footer>
      </Form>
    )
  }

  const handleOnSubmit = async (
    values: FormValues,
    formikActions: FormikHelpers<FormValues>
  ) => {
    const trimmedValues = renameShareSchema.cast(values)

    formikActions.setSubmitting(true)

    await updateShare({
      variables: { identifier: id, share: { name: trimmedValues.name } },
    })

    formikActions.setSubmitting(false)
  }

  return (
    <Modal title="Rename Document" onCancel={hideModal}>
      <Formik
        initialValues={{
          name,
        }}
        onSubmit={handleOnSubmit}
        validationSchema={renameShareSchema}
        validateOnBlur={true}
      >
        {handleFormikRender}
      </Formik>
    </Modal>
  )
}

export { RenameShareModal }
