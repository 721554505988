import styled, { css } from 'styled-components'

export const Icon = styled.svg<{ $rotate?: boolean }>`
  width: 17px;
  height: 17px;

  margin-right: 6px; /* stylelint-disable-line scales/space */

  color: ${({ theme }) => theme.colors.foreground.secondary.A};
  opacity: 0.55;

  ${({ $rotate }) =>
    $rotate &&
    css`
      transform: rotate(90deg);
    `}
`

export const PaddingItem = styled.div`
  display: flex;
  align-items: center;
  flex: 0 1 50%;
  margin-top: 3px; /* stylelint-disable-line scales/space */
`

export const PaddingWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;

  width: 100%;

  ${Icon} {
    width: 11px;
    height: 11px;
    margin-right: 6px; /* stylelint-disable-line scales/space */
  }

  ${/* sc-selector */ PaddingItem}:nth-child(2) {
    ${Icon} {
      transform: rotate(90deg);
    }
  }

  ${/* sc-selector */ PaddingItem}:nth-child(3) {
    ${Icon} {
      transform: rotate(180deg);
    }
  }

  ${/* sc-selector */ PaddingItem}:nth-child(4) {
    ${Icon} {
      transform: rotate(270deg);
    }
  }
`
