import { Location } from 'history'
import { matchPath } from 'react-router'

import { ShareInfoFragment, VersionListItemFragment } from '@sketch/gql-types'
import { RouteParams, versionedRoutes } from '@sketch/modules-common'

export const isNewStarMoreRecent = (
  latestStar: VersionListItemFragment | undefined,
  newStarCreatedAt: string
) => {
  // if there's no previous star,
  // then this new save we're trying to promote should always take priority
  if (latestStar === undefined) {
    return true
  }

  return (
    new Date(latestStar.createdAt).getTime() <
    new Date(newStarCreatedAt).getTime()
  )
}

export const isSketchVersionGreaterThan = (a: string, b: string) =>
  a.localeCompare(b, undefined, { numeric: true }) === 1

/**
 * This method will allow us to inform BE if the user should receive "ALL" the
 * version update notifications or only "PUBLISHED" ones.
 *
 * We distinguish based on the "share.userAccessLevel" because BE is not preventing the send of new upload events when the
 * user is a viewer, so FE needs to enforce this selection. This means a viewer user would still receive events for non published versions incorrectly.
 * https://github.com/sketch-hq/Cloud/issues/19103
 *
 *
 * This hook is used on 2 distinct ways:
 * - Allowing the share/artboard version lists to be updated and notified of a new change via toast
 * - Upload the thumbnails of the documents on the listings
 *
 * For the first case and to prevent toast notifications to be span-out incorrectly we will use advantage of
 * knowing the share and checking "share.userAccessLevel". First 2 conditional statements
 *
 * If we aren't aware of the share, on the workspace listing we are not, we should allow "ALL" updated
 *
 * Quick gist:
 * - Know the exact share the version belongs -> mirror the "share.userAccessLevel"
 * - Don't know -> Let BE do the triage
 */
export const getPreferredStateVersionFromShare = (
  share?: ShareInfoFragment
) => {
  if (share && share.userAccessLevel === 'EDIT') {
    return 'ANY'
  } else if (share && share.userAccessLevel !== 'EDIT') {
    return 'PUBLISHED'
  }

  return 'ANY'
}

const getLatestVersionURLFromPath = (pathname: string) => {
  const matchFrameView = matchPath<RouteParams<'FRAME'>>(pathname, {
    path: versionedRoutes.FRAME.VERSION.template(),
    exact: true,
  })

  const matchArtboardView = matchPath<RouteParams<'ARTBOARD_DETAIL'>>(
    pathname,
    { path: versionedRoutes.ARTBOARD_DETAIL.VERSION.template(), exact: true }
  )

  const matchPageView = matchPath<RouteParams<'SHARE_PAGE_VIEW'>>(pathname, {
    path: versionedRoutes.SHARE_PAGE_VIEW.VERSION.template(),
    exact: true,
  })

  const matchShareView = matchPath<RouteParams<'SHARE_VIEW'>>(pathname, {
    path: versionedRoutes.SHARE_VIEW.VERSION.template(),
    exact: true,
  })

  switch (true) {
    case !!matchFrameView:
      return versionedRoutes.FRAME.LATEST.create({
        ...matchFrameView!.params,
        query: undefined,
      })
    case !!matchArtboardView:
      return versionedRoutes.ARTBOARD_DETAIL.LATEST.create({
        ...matchArtboardView!.params,
        query: undefined,
      })
    case !!matchPageView:
      return versionedRoutes.SHARE_PAGE_VIEW.LATEST.create({
        ...matchPageView!.params,
        query: undefined,
      })
    case !!matchShareView:
      return versionedRoutes.SHARE_VIEW.LATEST.create({
        ...matchShareView!.params,
        query: undefined,
      })
  }
}

export const getLatestVersionURLFromCurrentLocation = (location: Location) => {
  const { pathname, search, hash } = location

  const components = [getLatestVersionURLFromPath(pathname)]

  if (search) {
    components.push(search)
  }

  if (hash) {
    components.push(hash)
  }

  return components.join('')
}
