import React from 'react'
import copy from 'copy-to-clipboard'

import { Dropdown, useModalContext } from '@sketch/components'
import { useUserSignedIn } from '@sketch/modules-common'
import { DeleteVersionModal } from '../../modals/DeleteVersionModal'
import { VersionDescriptionModal } from '../../modals/VersionDescriptionModal'
import { useVersionStar } from '../../hooks/useVersionStar'
import { useVersionUnstar } from '../../hooks/useVersionUnstar'

import { DeleteShareModal } from '../../../shares/components/DeleteShareModal'

import {
  useGetWorkspacesForDuplicationQuery,
  VersionListItemFragment,
  ShareListItemFragment,
} from '@sketch/gql-types'
import PreventiveVersionLink from '../PreventiveVersionLink'

import { DropdownOption, DropdownListOption } from './VersionItemOptions.styles'
import { DuplicateShareOption } from '../../../shares/components/DuplicateShareOption'
import { ShareWithoutVersion } from '../../ShareVersionContext/ShareVersionContext'
import { getEligibleWorkspacesForDuplication } from '../../../workspace/utils/getEligibleWorkspacesForDuplication/getEligibleWorkspacesForDuplication'
import { useCheckCurrentAuthorizationForWorkspace } from '../../../sso/operations/useCheckCurrentAuthorizationForWorkspace'

interface ShareForShareDropdown
  extends Pick<
    ShareListItemFragment,
    | 'identifier'
    | 'name'
    | 'project'
    | 'userCanUpdateSettings'
    | 'publicAccessLevel'
    | 'shareType'
    | 'pinnedByCurrentUserAt'
    | 'collection'
  > {}

interface VersionItemOptionsProps {
  version: VersionListItemFragment
  isDocumentLibrary: boolean
  isLatestStaredVersion: boolean
  isSingleVersion: boolean
  isAuto: boolean
  userCanEdit: boolean
  userCanInspect: boolean
  isVersionSelected: boolean
  share: Pick<
    ShareWithoutVersion,
    | 'identifier'
    | 'name'
    | 'shareType'
    | 'userCanEdit'
    | 'userCanInspect'
    | 'publicAccessLevel'
    | 'userCanUpdateSettings'
    | 'pinnedByCurrentUserAt'
    | 'workspace'
    | 'project'
    | 'collection'
  >

  onDuplicateShareToDraftsClick: () => void
  onDuplicateShareToClick: () => void
}

const VersionItemOptions = (props: VersionItemOptionsProps) => {
  const {
    share,
    version,
    userCanEdit,
    userCanInspect,
    isLatestStaredVersion,
    isDocumentLibrary,
    isSingleVersion,
    isVersionSelected,
    isAuto,
    onDuplicateShareToDraftsClick,
    onDuplicateShareToClick,
  } = props

  const { identifier, name, workspace, publicAccessLevel } = share

  const { showModal } = useModalContext()
  const isSignedIn = useUserSignedIn()
  const versionIdentifier = version.identifier
  const versionDescription = version.description || ''

  const [starVersion] = useVersionStar({
    description: versionDescription,
    versionIdentifier,
    shareIdentifier: identifier,
    isLibrary: isDocumentLibrary,
    isNewLatest: isLatestStaredVersion,
  })

  const [unstarVersion] = useVersionUnstar({
    versionIdentifier,
    isLibrary: isDocumentLibrary,
  })

  const handleCopyLink = (event: React.MouseEvent<HTMLAnchorElement>) => {
    /* Prevent the link event from happening, just get the value */
    event.preventDefault()
    copy(event.currentTarget.href)
  }

  const handleDownloadLink = () => {
    window.location.replace(version.document!.url!)
  }

  const isStarred = version.kind === 'PUBLISHED'

  const DeleteVersion = () => (
    <Dropdown.Item
      intent="negative"
      onClick={() => {
        showModal(DeleteVersionModal, {
          createdAt: version.createdAt,
          creatorName: version.creator?.name || '',
          isActive: isVersionSelected,
          versionIdentifier,
          shareName: name,
          shareID: identifier,
        })
      }}
    >
      Delete&hellip;
    </Dropdown.Item>
  )

  const DeleteDocument = () => (
    <Dropdown.Item
      intent="negative"
      onClick={() => {
        showModal(DeleteShareModal, {
          name: name,
          shareShortId: identifier,
        })
      }}
    >
      Delete Document&hellip;
    </Dropdown.Item>
  )

  const UpdateDescription = () => {
    // Users shouldn't be able to update the description of auto versions
    if (isAuto) {
      return null
    }

    const UpdateDescriptionMenuText = version.description
      ? `Edit Description…`
      : `Add Description…`

    return (
      <Dropdown.Item
        data-testid="description-update-dropdown"
        onClick={event => {
          // stop event propogation so that underlying elements wouldn't be clicked
          event.preventDefault()
          showModal(VersionDescriptionModal, {
            isStarred,
            description: versionDescription,
            shareIdentifier: identifier,
            versionIdentifier,
          })
        }}
      >
        {UpdateDescriptionMenuText}
      </Dropdown.Item>
    )
  }

  const createOrDemoteStar = () => {
    const canStar = version.document?.renderStatus === 'FINISHED' && !isAuto
    if (!userCanEdit || !canStar) {
      return null
    }

    return isStarred ? (
      <Dropdown.Item
        data-testid="demote-star-update-dropdown"
        onClick={() => {
          unstarVersion()
        }}
      >
        Unstar Version&hellip;
      </Dropdown.Item>
    ) : (
      <Dropdown.Item
        data-testid="create-star-update-dropdown"
        onClick={() => {
          starVersion()
        }}
      >
        Star Version&hellip;
      </Dropdown.Item>
    )
  }

  const sketchVersion = version.document!.sketchVersion
  const downloadEnabled =
    (version.document?.url && version.document?.downloadAvailable) || false

  return (
    <div onClick={event => event.preventDefault()}>
      <DropdownListOption>
        <PreventiveVersionLink
          versionShortId={version.shortId}
          isLatestVersion={false}
          onClick={handleCopyLink}
        >
          <DropdownOption>Copy Link</DropdownOption>
        </PreventiveVersionLink>
      </DropdownListOption>
      {downloadEnabled && (
        <Dropdown.Item onClick={handleDownloadLink}>Download</Dropdown.Item>
      )}
      {isSignedIn && (
        <DuplicateShareLoading
          share={share}
          shareWorkspaceIdentifier={workspace.identifier}
          sharePublicAccess={publicAccessLevel}
          userCanInspect={userCanInspect}
          sketchVersion={sketchVersion || '43'}
          onDuplicateShareToDraftsClick={onDuplicateShareToDraftsClick}
          onDuplicateShareToClick={onDuplicateShareToClick}
        />
      )}
      {userCanEdit && <UpdateDescription />}
      {createOrDemoteStar()}
      {userCanEdit && (
        <>
          <Dropdown.Divider />
          {isSingleVersion ? <DeleteDocument /> : <DeleteVersion />}
        </>
      )}
    </div>
  )
}

export default VersionItemOptions

/**
 * Lazy Loaded Dropdown Options
 */

interface DuplicateShareLoadingProps {
  share: ShareForShareDropdown
  shareWorkspaceIdentifier: string
  sharePublicAccess: ShareWithoutVersion['publicAccessLevel']
  userCanInspect: boolean
  sketchVersion: string
  onDuplicateShareToDraftsClick: () => void
  onDuplicateShareToClick: () => void
}

const DuplicateShareLoading = (props: DuplicateShareLoadingProps) => {
  const {
    share,
    shareWorkspaceIdentifier,
    sharePublicAccess,
    userCanInspect,
    sketchVersion,
    onDuplicateShareToDraftsClick,
    onDuplicateShareToClick,
  } = props

  const { error, loading, data } = useGetWorkspacesForDuplicationQuery()
  const { checkAuthorization } = useCheckCurrentAuthorizationForWorkspace()

  if (loading) return <Dropdown.Skeleton />
  if (error || !data) return null

  const workspaces = data!.me.workspaces

  const { userCanDuplicate, currentWorkspace, otherWorkspaces } =
    getEligibleWorkspacesForDuplication(
      workspaces,
      shareWorkspaceIdentifier,
      sharePublicAccess,
      { hasAccessToWorkspaceCheckFn: checkAuthorization }
    )

  if (!userCanInspect || !userCanDuplicate || !sketchVersion) {
    return null
  }

  return (
    <DuplicateShareOption
      share={share}
      workspaceIdentifier={shareWorkspaceIdentifier}
      sketchVersion={sketchVersion}
      moreThanOneEligible={!currentWorkspace && Boolean(otherWorkspaces.length)}
      onDuplicateShareToDraftsClick={onDuplicateShareToDraftsClick}
      onDuplicateShareToClick={onDuplicateShareToClick}
    />
  )
}
