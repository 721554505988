import { ElementTimingId } from '@sketch/modules-common'
import { bindReporter } from 'web-vitals/dist/modules//lib/bindReporter'
import { getVisibilityWatcher } from 'web-vitals/dist/modules/lib/getVisibilityWatcher.js'
import { initMetric as initMetricRaw } from 'web-vitals/dist/modules/lib/initMetric.js'
import { onHidden } from 'web-vitals/dist/modules/lib/onHidden.js'
import { ReportHandler } from 'web-vitals/dist/modules/types.js'

import {
  ElementTimingEntry,
  ElementTimingHandler,
  ElementTimingMetric,
} from './types'

const initMetric = initMetricRaw as any as (
  name: ElementTimingMetric['name']
) => ElementTimingMetric

const reportedMetricIDs: Set<string> = new Set()

function isRectInViewport(rect: DOMRect) {
  const innerHeight =
    window.innerHeight || document.documentElement.clientHeight

  const innerWidth = window.innerWidth || document.documentElement.clientWidth
  return (
    // is the top left corner in the viewport
    (0 <= rect.top &&
      rect.top <= innerHeight &&
      0 <= rect.left &&
      rect.left <= innerWidth) ||
    // or the bottom right corner in the viewport
    (0 <= rect.bottom &&
      rect.bottom <= innerHeight &&
      0 <= rect.right &&
      rect.right <= innerWidth)
  )
}

const getElementsToWaitFor = (expectedElement: ElementTimingId) => {
  return Array.from(
    document.querySelectorAll(`[elementtiming="${expectedElement}"]`)
  )
    .map(el => ({
      element: el,
      isRectInViewport: isRectInViewport(el.getBoundingClientRect()),
    }))
    .filter(x => x.isRectInViewport)
    .map(x => x.element)
}

type ObserveFn = (handler: ElementTimingHandler) => { cleanup(): void }

export const buildGetElementTiming = (
  observeFn: ObserveFn,
  name: ElementTimingMetric['name']
) => {
  const getElementTiming = (
    onReport: ReportHandler,
    expectedElement: ElementTimingId
  ) => {
    const visibilityWatcher = getVisibilityWatcher()
    const metric = initMetric(name)

    let report: ReturnType<typeof bindReporter>
    let wasReported = false
    let remainingElements: Element[]
    let stopListening: () => void

    const entryHandler = (entry: ElementTimingEntry) => {
      if (wasReported || entry.identifier !== expectedElement) {
        return
      }

      // The startTime attribute returns the value of the renderTime if it is not 0,
      // and the value of the loadTime otherwise.
      const value = entry.startTime

      // If the page was hidden prior to paint time of the entry,
      // ignore it and stop listening for the new events - we will not report anything here
      if (value >= visibilityWatcher.firstHiddenTime) {
        stopListening?.()
        return
      }

      if (!remainingElements) {
        remainingElements = getElementsToWaitFor(expectedElement)
      }

      const element: Element = entry.element
      const elementIndex = remainingElements.indexOf(element)

      if (elementIndex < 0) {
        return
      }

      metric.value = value
      metric.entries.push(entry)

      remainingElements.splice(elementIndex, 1)
      if (remainingElements.length !== 0) {
        return
      }

      report?.()
    }

    const po = observeFn(entryHandler)

    if (po) {
      // we are interested in specific element, however, it is possible that there will be a number of elements
      // of the same type (e.g. list of artboard images).
      // Here we are allowing to report that elements have loaded if these events occur close to each other (max 200 ms apart).
      // However, everything after that we are considering as events created by future loading
      report = bindReporter(
        metric => {
          try {
            if (!wasReported) {
              wasReported = true
              onReport?.(metric)
            }
          } finally {
            stopListening()
          }
        },
        metric as any,
        true
      )

      stopListening = () => {
        if (!reportedMetricIDs.has(metric.id)) {
          reportedMetricIDs.add(metric.id)
          // po.takeRecords().map(entryHandler as PerformanceEntryHandler)
          po.cleanup()
        }
      }

      // Stop listening after any input.
      // While inputs are not stopping the Element Timing API to work (like in LCP case).
      // We might get incorrect responses if we would allow user to interact with the website and we still would
      // listen for new events.
      //
      // Imagine the case:
      //   User loads `shared-with-me` documents, and there are 0 documents there. Meaning that there are 0 elements
      //   which would trigger the PerformanceObserver handler. Nonetheless, there is nothing stopping the PerformanceObserver
      //   to listen for new events.
      //   Then user navigates to the `all-documents` tab, and there we get 5 documents. Finally `PerformanceObserver` is triggered
      //   And we calculate the page load time. Which can be insanely high, as user already spent some time on the page.
      //
      //   We also can't rely on route changed events, as those events can be made programmatically.
      ;['keydown', 'click'].forEach(type => {
        window.addEventListener(type, stopListening, {
          once: true,
          capture: true,
        })
      })

      onHidden(stopListening, true)
    }
  }
  return getElementTiming
}
