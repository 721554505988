import React from 'react'
import { useModalContext, Text, Link } from '@sketch/components'
import { LinkButton, CheckIcon, Wrapper } from './LinkNonSSO.styles'
import { LinkNonSSOModal } from './LinkNonSSOModal'
import {
  UserWorkspaceMembershipFragment,
  WorkspaceMinimalFragment,
} from '@sketch/gql-types'
import { LinkedSSOWorkspacesModal } from './LinkedSSOWorkspacesModal'

interface LinkNonSSOProps {
  linkedEmail?: string | null
  currentWorkspace: WorkspaceMinimalFragment
  ssoWorkspaces: UserWorkspaceMembershipFragment[]
  isSignedInWithSSO: boolean
}

export const LinkNonSSO = ({
  linkedEmail,
  currentWorkspace,
  ssoWorkspaces,
  isSignedInWithSSO,
}: LinkNonSSOProps) => {
  const { showModal } = useModalContext()

  if (linkedEmail) {
    return (
      <Wrapper>
        <CheckIcon />
        <Text textStyle="copy.primary.standard.C">
          {isSignedInWithSSO ? (
            <>
              Linked to <strong>{linkedEmail}</strong>
            </>
          ) : ssoWorkspaces.length > 1 ? (
            <>
              Linked to{' '}
              <Link
                onClick={e => {
                  e.preventDefault()
                  showModal(LinkedSSOWorkspacesModal, { ssoWorkspaces })
                }}
                isUnderlined
                external
                variant="secondary"
              >
                {ssoWorkspaces.length} <strong>SSO Workspaces</strong>
              </Link>
            </>
          ) : (
            <>
              Linked to SSO: <strong>{ssoWorkspaces[0].workspace.name}</strong>
            </>
          )}
        </Text>
      </Wrapper>
    )
  }

  return (
    <Wrapper>
      <LinkButton
        isUnderlined
        onClick={() =>
          showModal(LinkNonSSOModal, { workspaceName: currentWorkspace.name })
        }
      >
        Link to Non-SSO Account...
      </LinkButton>
    </Wrapper>
  )
}
