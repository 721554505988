import { useReducer, useEffect, useCallback } from 'react'
import {
  ShareInfoFragment,
  GetSharePermissionsDocument,
  GetSharePermissionsQueryVariables,
  useUpdateShareParentPermissionsMutation,
  UpdateShareParentPermissionsMutation,
} from '@sketch/gql-types'
import { useToast } from '@sketch/toasts'
import { ErrorHandler } from '@sketch/tracing'
import { useModalContext } from '@sketch/components'
import { ParentAccessLevel } from '@sketch/modules-common'

type ShareForUpdateWorkspacePermissions = Pick<
  ShareInfoFragment,
  'parentAccessLevel' | 'identifier' | 'userCanUpdateSettings'
>

type State = {
  initialValues: ShareForUpdateWorkspacePermissions
  values: ShareForUpdateWorkspacePermissions
  dialogOpen: boolean
  mutating: boolean
}

type Action =
  | { type: 'show-confirmation-modal' }
  | { type: 'hide-confirmation-modal' }
  | { type: 'reset' }
  | { type: 'set-level'; payload: { level: ParentAccessLevel } }
  | { type: 'success'; payload: { values: ShareForUpdateWorkspacePermissions } }
  | { type: 'set-mutating' }
  | {
      type: 'optimistic-success'
      payload: { values: ShareForUpdateWorkspacePermissions }
    }

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'show-confirmation-modal':
      return { ...state, dialogOpen: true }
    case 'hide-confirmation-modal':
      return { ...state, dialogOpen: false }
    case 'set-mutating':
      return { ...state, mutating: true }
    case 'reset':
      return {
        ...state,
        dialogOpen: false,
        values: { ...state.initialValues },
        mutating: false,
      }
    case 'success':
      return {
        ...state,
        values: action.payload.values,
        initialValues: action.payload.values,
        mutating: false,
      }
    case 'optimistic-success':
      return {
        ...state,
        values: action.payload.values,
      }
    case 'set-level':
      return {
        ...state,
        values: {
          ...state.values,
          parentAccessLevel: action.payload.level,
        },
      }
    default:
      return state
  }
}

interface UseUpdateShareParentPermissionsProps {
  share: ShareForUpdateWorkspacePermissions
}

const areValuesEqual = (
  a: ShareForUpdateWorkspacePermissions,
  b: ShareForUpdateWorkspacePermissions
) => a.parentAccessLevel === b.parentAccessLevel

export const useUpdateShareParentPermissions = ({
  share,
}: UseUpdateShareParentPermissionsProps) => {
  const { showToast } = useToast()
  const { hideModal } = useModalContext()

  const [{ initialValues, values, dialogOpen, mutating }, dispatch] =
    useReducer(reducer, {
      initialValues: share,
      values: share,
      dialogOpen: false,
      mutating: false,
    })

  const [updateParentPermissions, { loading: updateParentPermissionsLoading }] =
    useUpdateShareParentPermissionsMutation({
      onCompleted(data) {
        if (!data?.updateShareParentPermissions) {
          ErrorHandler.shouldNeverHappen(
            'Share update workspace permissions mutation should return valid data'
          )
          dispatch({ type: 'reset' })
          return
        }
        if (data.updateShareParentPermissions.userCanUpdateSettings === false) {
          hideModal()
          showToast("You can no longer view this document's settings")
          return
        }
        dispatch({
          type: 'success',
          payload: { values: data.updateShareParentPermissions },
        })
      },
      onError(error) {
        if (error.includesErrorCode('CONFIRMATION_REQUIRED')) {
          dispatch({ type: 'show-confirmation-modal' })
          return
        }

        dispatch({ type: 'reset' })
        showToast(
          'There was a problem changing the workspace permissions. Please try again.',
          'negative'
        )
      },
      refetchQueries: [
        // To understand why we are (re)fetching getSharePermissions fragment
        // see: https://github.com/sketch-hq/cloud-frontend/pull/4644#discussion_r798048773
        {
          query: GetSharePermissionsDocument,
          variables: {
            shareIdentifier: share.identifier,
          } as GetSharePermissionsQueryVariables,
        },
      ],
    })

  const mutate = useCallback(
    (confirmed: boolean = false) => {
      dispatch({ type: 'set-mutating' })
      return updateParentPermissions({
        variables: {
          input: {
            identifier: share.identifier,
            parentAccessLevel: values.parentAccessLevel,
            confirmed,
          },
        },
        optimisticResponse: vars => {
          const { input } = vars as ExcludeEmpty<typeof vars>

          const response: UpdateShareParentPermissionsMutation = {
            __typename: 'RootMutationType',
            updateShareParentPermissions: {
              __typename: 'Share',
              identifier: input.identifier,
              userCanUpdateSettings: share.userCanUpdateSettings,
              parentAccessLevel: input.parentAccessLevel,
            },
          }

          dispatch({
            type: 'optimistic-success',
            payload: {
              values: response.updateShareParentPermissions!,
            },
          })

          return response
        },
      })
    },
    [share, values, updateParentPermissions]
  )

  useEffect(() => {
    if (!mutating && !areValuesEqual(values, initialValues)) {
      mutate()
    }
  }, [values, initialValues, mutate, mutating])

  const getUpdateShareParentPermissionsConfirmDialogProps = useCallback(
    () => ({
      hideModal() {
        dispatch({ type: 'reset' })
      },
      onConfirm() {
        dispatch({ type: 'hide-confirmation-modal' })
        mutate(true)
      },
      isModalOpen: true,
      confirmButton: {
        text: 'Confirm',
        intent: 'positive',
        loading: updateParentPermissionsLoading,
      } as const,
    }),
    [dispatch, mutate, updateParentPermissionsLoading]
  )

  const getUpdateShareParentPermissionsDropdownProps = useCallback(
    () => ({
      level: values.parentAccessLevel,
      setLevel: (level: ParentAccessLevel) =>
        dispatch({ type: 'set-level', payload: { level } }),
      loading: updateParentPermissionsLoading,
    }),
    [values, dispatch, updateParentPermissionsLoading]
  )

  return {
    updateShareParentPermissionsLoading: updateParentPermissionsLoading,
    getUpdateShareParentPermissionsDropdownProps,
    getUpdateShareParentPermissionsConfirmDialogProps,
    updateShareParentPermissionsConfirmDialogOpen: dialogOpen,
    shareParentAccessLevel: values.parentAccessLevel,
  }
}
