import React from 'react'
import {
  Section,
  Header,
  HeaderTitle,
  Separator,
  AttributeList,
  FullCopyAttribute,
} from '../../components'

import {
  StackPadding,
  StackProperties,
} from '../../../../../../inspector/types/InspectorTypes/Stack'
import {
  CrossAxisAlignment,
  MainAxisAlignment,
  StackDirection,
} from '../../../../../../inspector'

import { Icon, PaddingItem, PaddingWrapper } from './Stack.styles'

// Icons
import { ReactComponent as HorizontalIcon } from '@sketch/icons/flex-horizontal-17'
import { ReactComponent as VerticalIcon } from '@sketch/icons/flex-vertical-17'

import { ReactComponent as JustifyStartIcon } from '@sketch/icons/flex-justify-start-17'
import { ReactComponent as JustifyCenterIcon } from '@sketch/icons/flex-justify-center-17'
import { ReactComponent as JustifyEndIcon } from '@sketch/icons/flex-justify-end-17'
import { ReactComponent as JustifyEvenlyIcon } from '@sketch/icons/flex-justify-evenly-17'
import { ReactComponent as JustifyAroundIcon } from '@sketch/icons/flex-justify-around-17'
import { ReactComponent as JustifyBetweenIcon } from '@sketch/icons/flex-justify-between-17'

import { ReactComponent as FillHIcon } from '@sketch/icons/flex-fill-h-17'
import { ReactComponent as FillVIcon } from '@sketch/icons/flex-fill-v-17'

import { ReactComponent as AlignStartIcon } from '@sketch/icons/flex-align-start-17'
import { ReactComponent as AlignCenterIcon } from '@sketch/icons/flex-align-center-17'
import { ReactComponent as AlignEndIcon } from '@sketch/icons/flex-align-end-17'

import { ReactComponent as PaddingPairedIcon } from '@sketch/icons/padding-paired-17'
import { ReactComponent as PaddingHorizontalIcon } from '@sketch/icons/padding-horizontal-11'
import { ReactComponent as PaddingLeftIcon } from '@sketch/icons/padding-left-11'

interface StackProps {
  stack: StackProperties
}

const renderDirection = (direction: StackDirection) => {
  if (direction === StackDirection.Horizontal) {
    return (
      <>
        <Icon as={HorizontalIcon} /> Horizontal
      </>
    )
  }

  return (
    <>
      <Icon as={VerticalIcon} /> Vertical
    </>
  )
}

const renderMainAxisAlignment = (
  direction: StackDirection,
  mainAxisAlignment: MainAxisAlignment
) => {
  switch (mainAxisAlignment) {
    case MainAxisAlignment.Start:
      return (
        <>
          <Icon
            as={JustifyStartIcon}
            $rotate={direction === StackDirection.Vertical}
          />{' '}
          Start
        </>
      )
    case MainAxisAlignment.Center:
      return (
        <>
          <Icon
            as={JustifyCenterIcon}
            $rotate={direction === StackDirection.Vertical}
          />{' '}
          Center
        </>
      )
    case MainAxisAlignment.End:
      return (
        <>
          <Icon
            as={JustifyEndIcon}
            $rotate={direction === StackDirection.Vertical}
          />{' '}
          End
        </>
      )
    case MainAxisAlignment.SpaceAround:
      return (
        <>
          <Icon
            as={JustifyAroundIcon}
            $rotate={direction === StackDirection.Vertical}
          />{' '}
          Space Around
        </>
      )
    case MainAxisAlignment.SpaceBetween:
      return (
        <>
          <Icon
            as={JustifyBetweenIcon}
            $rotate={direction === StackDirection.Vertical}
          />{' '}
          Space Between
        </>
      )
    case MainAxisAlignment.SpaceEvenly:
      return (
        <>
          <Icon
            as={JustifyEvenlyIcon}
            $rotate={direction === StackDirection.Vertical}
          />{' '}
          Evenly
        </>
      )
  }
}

const renderCrossAxisAlignment = (
  direction: StackDirection,
  crossAxisAlignment: CrossAxisAlignment
) => {
  switch (crossAxisAlignment) {
    case CrossAxisAlignment.Start:
      return (
        <>
          <Icon
            as={AlignStartIcon}
            $rotate={direction === StackDirection.Horizontal}
          />{' '}
          Start
        </>
      )
    case CrossAxisAlignment.Center:
      return (
        <>
          <Icon
            as={AlignCenterIcon}
            $rotate={direction === StackDirection.Horizontal}
          />{' '}
          Center
        </>
      )
    case CrossAxisAlignment.End:
      return (
        <>
          <Icon
            as={AlignEndIcon}
            $rotate={direction === StackDirection.Horizontal}
          />{' '}
          End
        </>
      )
  }
}

const renderGap = (
  direction: StackDirection,
  gap: number,
  mainAxisAlignment: MainAxisAlignment
) => {
  if (
    [
      MainAxisAlignment.SpaceAround,
      MainAxisAlignment.SpaceBetween,
      MainAxisAlignment.SpaceEvenly,
    ].includes(mainAxisAlignment)
  ) {
    return 'auto'
  }

  if (direction === StackDirection.Horizontal) {
    return (
      <>
        <Icon as={FillHIcon} /> {gap}px
      </>
    )
  }

  return (
    <>
      <Icon as={FillVIcon} /> {gap}px
    </>
  )
}

const renderPadding = (padding: StackPadding) => {
  const uniquePaddings = new Set([
    padding.top,
    padding.right,
    padding.bottom,
    padding.left,
  ])

  if (uniquePaddings.size === 1) {
    return (
      <>
        <Icon as={PaddingPairedIcon} /> {padding.top}px
      </>
    )
  } else if (padding.top === padding.bottom && uniquePaddings.size === 2) {
    return (
      <PaddingWrapper>
        <PaddingItem>
          <Icon as={PaddingHorizontalIcon} /> {padding.left}px
        </PaddingItem>
        <PaddingItem>
          <Icon as={PaddingHorizontalIcon} /> {padding.top}px
        </PaddingItem>
      </PaddingWrapper>
    )
  }

  return (
    <PaddingWrapper>
      <PaddingItem>
        <Icon as={PaddingLeftIcon} /> {padding.left}px
      </PaddingItem>
      <PaddingItem>
        <Icon as={PaddingLeftIcon} /> {padding.top}px
      </PaddingItem>
      <PaddingItem>
        <Icon as={PaddingLeftIcon} /> {padding.right}px
      </PaddingItem>
      <PaddingItem>
        <Icon as={PaddingLeftIcon} /> {padding.bottom}px
      </PaddingItem>
    </PaddingWrapper>
  )
}

const copyValuePadding = (padding: StackPadding) => {
  const uniquePaddings = new Set([
    padding.top,
    padding.right,
    padding.bottom,
    padding.left,
  ])

  if (uniquePaddings.size === 1) {
    return `${padding.top}px`
  } else if (padding.top === padding.bottom && uniquePaddings.size === 2) {
    return `${padding.top}px ${padding.right}px`
  }

  return `${padding.top}px ${padding.right}px ${padding.bottom}px ${padding.left}px`
}

export const Stack = ({ stack }: StackProps) => {
  const { crossAxisAlignment, direction, gap, mainAxisAlignment, padding } =
    stack

  return (
    <>
      <Separator />
      <Section data-testid="inspector-sidebar-stack">
        <Header>
          <HeaderTitle>Stack</HeaderTitle>
        </Header>
        <AttributeList>
          <FullCopyAttribute
            label="Direction"
            value={renderDirection(direction)}
            copyValue={direction}
          />

          <FullCopyAttribute
            label="Justify"
            value={renderMainAxisAlignment(direction, mainAxisAlignment)}
            copyValue={mainAxisAlignment}
          />

          <FullCopyAttribute
            label="Gap"
            value={renderGap(direction, gap, mainAxisAlignment)}
            copyValue={`${gap}px`}
          />

          {crossAxisAlignment !== CrossAxisAlignment.None && (
            <FullCopyAttribute
              label="Alignment"
              value={renderCrossAxisAlignment(direction, crossAxisAlignment)}
              copyValue={crossAxisAlignment}
            />
          )}

          <FullCopyAttribute
            label="Padding"
            value={renderPadding(padding)}
            copyValue={copyValuePadding(padding)}
          />
        </AttributeList>
      </Section>
    </>
  )
}
