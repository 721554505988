import React from 'react'
import {
  Section,
  Header,
  HeaderTitle,
  Separator,
  AttributeList,
  FullCopyAttribute,
} from '../../components'
import { roundWithLocale } from '@sketch/utils'
import copy from './copy'
import { PRLayerSizingBehaviourEnum } from '@sketch-hq/sketch-web-renderer'
import { MeasureSizingBehaviour } from '../MeasureSizingBehaviour'

interface PositionAndSizeProps {
  rotate?: number
  x: number
  y: number
  width: number
  widthBehaviour?: PRLayerSizingBehaviourEnum
  height: number
  heightBehaviour?: PRLayerSizingBehaviourEnum
}

export const PositionAndSize = ({
  rotate = 0,
  x,
  y,
  width,
  height,
  widthBehaviour,
  heightBehaviour,
}: PositionAndSizeProps) => {
  const roundedX = roundWithLocale(x, 2)
  const roundedY = roundWithLocale(y, 2)
  // The raw rotate value in the pr file works counter-clockwise, but users think
  // in clockwise rotation, so flip the sign
  const reversedRotate = rotate !== 0 ? -rotate : rotate
  const roundedRotate = `${roundWithLocale(reversedRotate, 2)}°`
  const roundedWidth = roundWithLocale(width, 2)
  const roundedHeight = roundWithLocale(height, 2)

  const sectionCopyValue = copy({
    rotate: roundedRotate,
    x: roundedX,
    y: roundedY,
    width: roundedWidth,
    height: roundedHeight,
  })

  return (
    <>
      <Separator />
      <Section data-testid="inspector-sidebar-position">
        <Header copyValue={sectionCopyValue}>
          <HeaderTitle>Position and Size</HeaderTitle>
        </Header>
        <AttributeList>
          <FullCopyAttribute
            label="Position"
            value={`${roundedX} X · ${roundedY} Y`}
          />
          <FullCopyAttribute label="Rotate" value={roundedRotate} />
          <FullCopyAttribute
            label="Width"
            copyValue={roundedWidth}
            value={
              <>
                {roundedWidth}{' '}
                <MeasureSizingBehaviour behaviour={widthBehaviour} />
              </>
            }
          />
          <FullCopyAttribute
            label="Height"
            copyValue={roundedHeight}
            value={
              <>
                {roundedHeight}{' '}
                <MeasureSizingBehaviour behaviour={heightBehaviour} rotate />
              </>
            }
          />
        </AttributeList>
      </Section>
    </>
  )
}
