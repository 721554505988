import React from 'react'

import {
  Button,
  Form,
  Input,
  Modal,
  ModalInjectedProps,
} from '@sketch/components'
import { Formik, FormikErrors, FormikProps } from 'formik'
import * as yup from 'yup'
import { useUpdateWorkspaceCustomerSsoShortNameMutation } from '@sketch/gql-types'
import { useToast } from '@sketch/toasts'

import { StyledCaption, Text } from './ChooseSsoWorkspaceShortNameModal.styles'

type Values = { workspaceShortName: string }

const firstCaption = '3-16 characters.'
const secondCaption = 'including letters, numbers and hyphens.'
const thirdCaption = 'No spaces or other special characters.'

const workspaceShortNameSchema = yup.object().shape({
  workspaceShortName: yup
    .string()
    .trim()
    .min(3, () => `length`)
    .max(16, () => `length`)
    .matches(/^([A-Za-z0-9]|-)*$/, 'invalid_characters')
    .matches(/^[A-Za-z0-9]([A-Za-z0-9]|-)+[A-Za-z0-9]$/, 'format'),
})

const ShortNameRules = ({ error }: { error?: string }) => {
  return (
    <>
      {error && error.search('length') !== -1 ? (
        <b>{firstCaption}</b>
      ) : (
        firstCaption
      )}{' '}
      {secondCaption}{' '}
      {error && error.search('invalid_characters') !== -1 ? (
        <b>{thirdCaption}</b>
      ) : (
        thirdCaption
      )}
    </>
  )
}

const ModalBody = ({
  values,
  errors,
  isSubmitting,
  handleBlur,
  handleChange,
}: FormikProps<Values>) => {
  const hasError = errors.workspaceShortName

  return (
    <Modal.Body>
      <Text>Try a name that&apos;s short and easy to remember.</Text>

      <Form.Field mb={1} name="workspaceShortName" label="SSO Shortname">
        <Input
          type="text"
          name="workspaceShortName"
          placeholder="acme"
          value={values.workspaceShortName}
          onChange={handleChange}
          onBlur={handleBlur}
          disabled={isSubmitting}
          autoFocus
        />
      </Form.Field>

      <StyledCaption mt={4} hasError={hasError}>
        <ShortNameRules error={errors.workspaceShortName} />
      </StyledCaption>
    </Modal.Body>
  )
}

const Footer = ({
  isLoading,
  onCancel,
}: {
  isLoading: boolean
  onCancel: () => void
}) => (
  <Modal.Footer>
    <Button type="button" onClick={onCancel} disabled={isLoading}>
      Cancel
    </Button>
    <Button
      type="submit"
      variant="primary"
      loading={isLoading}
      disabled={isLoading}
    >
      Submit
    </Button>
  </Modal.Footer>
)

interface ChooseSsoWorkspaceShortNameModalProps extends ModalInjectedProps {
  initialValue?: string
  customerId: string
}

const validateForm = (values: Values): FormikErrors<Values> => {
  try {
    workspaceShortNameSchema.validateSync(values, {
      abortEarly: false,
      strict: false,
    })
    // TODO: Handle type casting of caught errors
    //  see: https://github.com/sketch-hq/Cloud/issues/12858
  } catch (e: any) {
    return { workspaceShortName: e.errors.join(',') }
  }

  return {}
}

export const ChooseSsoWorkspaceShortNameModal: React.FC<
  ChooseSsoWorkspaceShortNameModalProps
> = props => {
  const { initialValue, customerId, hideModal } = props
  const { showToast } = useToast()

  const [updateShortName] = useUpdateWorkspaceCustomerSsoShortNameMutation({
    onCompleted: () => {
      showToast('SSO configuration successfully saved', 'positive')
      hideModal()
    },
    onError: 'show-toast',
  })

  const onSubmit = async (values: Values) => {
    const { workspaceShortName } = workspaceShortNameSchema.cast(values)

    try {
      // This is already being validated, but since yup.cast can return
      // undefined we need this extra check.
      await updateShortName({
        variables: { customerId, shortName: workspaceShortName! },
      })
    } catch (error) {
      // We can ignore the errors from this promise because the
      // apollo hook takes care of it
    }
  }

  return (
    <Modal onCancel={hideModal}>
      <Formik
        validate={validateForm}
        onSubmit={onSubmit}
        initialValues={{ workspaceShortName: initialValue ?? '' }}
        validateOnBlur={false}
      >
        {formikProps => (
          <Form>
            <Modal.Header>Choose an SSO Shortname</Modal.Header>
            <ModalBody {...formikProps} />
            <Footer isLoading={formikProps.isSubmitting} onCancel={hideModal} />
          </Form>
        )}
      </Formik>
    </Modal>
  )
}
