import React from 'react'
import { Route, Switch, useRouteMatch } from 'react-router'
import styled from 'styled-components'

import {
  isPersonalSettingsRoute,
  isDiscoverRoute,
  routes,
  useFlag,
  IndexLayoutOld as BaseIndexLayoutOld,
  IndexLayoutOldMain,
} from '@sketch/modules-common'
import {
  PrivateWorkspaceRoute,
  WorkspaceDocumentsView,
  WorkspaceDocumentsViewRoutesProps,
  PersonalAuthRoute,
  PrivateRoute,
  Updates,
  UpdatesRoutesProps,
  WorkspaceProfileEditView,
  WorkspaceSidebarLayout,
  WorkspaceSidebarLayoutExtraProps,
  useVersionSubscriptions,
  WorkspaceSettingsView,
} from 'cloud-frontend'

import { UserSettingsView } from '@sketch/sub-app-settings'
import { ErrorMessage, Link } from '@sketch/components'

import { ReactComponent as ExclamationMarkIcon } from '@sketch/icons/exclamation-mark-triangle-64'

import { workspaceSidebarLayoutRoutesSwitchPaths } from './routes'

const DiscoverView = React.lazy(
  () => import('cloud-frontend/src/modules/workspace/views/DiscoverView')
)

export const IndexLayoutOld = styled(BaseIndexLayoutOld)`
  ${IndexLayoutOldMain} {
    flex: 1;
    display: flex;
    align-items: center;

    margin-top: 0;
  }
`
interface WorkspaceSidebarLayoutRoutesSwitchProps {
  layoutProps: WorkspaceSidebarLayoutExtraProps
}

const WorkspaceSidebarLayoutRoutesSwitch = (
  props: WorkspaceSidebarLayoutRoutesSwitchProps
) => {
  // TODO: Remove profiles FF
  // https://github.com/sketch-hq/Cloud/issues/14920
  const isProfileFFEnabled = useFlag('profiles')
  const { layoutProps } = props

  return (
    <Switch>
      <PrivateWorkspaceRoute
        path={routes.WORKSPACE_DISCOVER.template()}
        render={routeProps => <DiscoverView {...layoutProps} {...routeProps} />}
      />

      <PrivateWorkspaceRoute
        exact
        path={[
          routes.WORKSPACE_PROJECT.template(),
          routes.WORKSPACE_SHARES.template(),
          routes.WORKSPACE_LIBRARIES.template(),
          routes.WORKSPACE_TRASH.template(),
          routes.WORKSPACE_DRAFTS.template(),
          routes.WORKSPACE_SHARED_WITH_ME.template(),
          routes.WORKSPACE_TRASH_PROJECT.template(),
          routes.WORKSPACE_COLLECTION.template(),
          routes.WORKSPACE_TRASH_COLLECTION.template(),
          routes.WORKSPACE_ARCHIVE.template(),
        ]}
        render={routeProps => (
          <WorkspaceDocumentsView
            {...layoutProps}
            {...(routeProps as WorkspaceDocumentsViewRoutesProps)}
          />
        )}
      />

      {isProfileFFEnabled && (
        <PrivateWorkspaceRoute
          exact
          path={routes.WORKSPACE_PROFILE_EDIT.template()}
          render={routeProps => (
            <WorkspaceProfileEditView {...layoutProps} {...routeProps} />
          )}
        />
      )}

      <PrivateWorkspaceRoute
        exact
        path={routes.WORKSPACE_TEMPLATES.template()}
        render={routeProps => (
          <WorkspaceDocumentsView
            {...layoutProps}
            {...(routeProps as WorkspaceDocumentsViewRoutesProps)}
          />
        )}
      />

      <PrivateWorkspaceRoute
        path={routes.WORKSPACE_SETTINGS.template()}
        component={WorkspaceSettingsView}
      />
    </Switch>
  )
}

interface NonWorkspaceSidebarLayoutRoutesSwitchProps {
  layoutProps: WorkspaceSidebarLayoutExtraProps
}

const NonWorkspaceSidebarLayoutRoutesSwitch = (
  props: NonWorkspaceSidebarLayoutRoutesSwitchProps
) => (
  <Switch>
    <PrivateRoute
      exact
      path={routes.UPDATES.template()}
      render={routeProps => (
        <Updates
          {...props.layoutProps}
          {...(routeProps as UpdatesRoutesProps)}
        />
      )}
    />

    <PrivateRoute
      exact
      path={routes.UPDATES_READ.template()}
      render={routeProps => (
        <Updates
          {...props.layoutProps}
          {...(routeProps as UpdatesRoutesProps)}
        />
      )}
    />

    {/* User */}
    <PersonalAuthRoute
      exact
      path={[
        routes.PERSONAL_SETTINGS.template(),
        routes.PERSONAL_SETTINGS_UNSUBSCRIBE.template(),
      ]}
    >
      <PrivateRoute
        render={routeProps => (
          <UserSettingsView
            {...props.layoutProps}
            {...(routeProps as WorkspaceDocumentsViewRoutesProps)}
          />
        )}
      />
    </PersonalAuthRoute>

    {/*
      Access Tokens can be created with either SSO or Personal
      credentials, so although they are under `/settings/...` we don't
      want to enforce the use of Personal credentials as it could lead to
      incorrect credentials being used when creating tokens.
    */}
    <PrivateRoute
      exact
      path={[
        routes.PERSONAL_SETTINGS_ACCESS_TOKENS.template(),
        routes.PERSONAL_SETTINGS_ACCESS_TOKENS_CREATE.template(),
        routes.APPEARANCE_SETTINGS.create({}),
      ]}
      render={routeProps => (
        <UserSettingsView
          {...props.layoutProps}
          {...(routeProps as WorkspaceDocumentsViewRoutesProps)}
        />
      )}
    />
  </Switch>
)

export const WorkspaceSidebarLayoutRoutes = () => {
  const { path } = useRouteMatch()
  useVersionSubscriptions()

  return (
    <Switch>
      <PrivateWorkspaceRoute
        path={workspaceSidebarLayoutRoutesSwitchPaths}
        render={routeProps => (
          <WorkspaceSidebarLayout
            hideFooter={isDiscoverRoute(path)}
            hideHeader={isPersonalSettingsRoute(path)}
            render={layoutProps => (
              <WorkspaceSidebarLayoutRoutesSwitch layoutProps={layoutProps} />
            )}
          />
        )}
        renderErrorView={() => (
          <IndexLayoutOld headerLink="workspace-shares" ignoreWorkspaceMatch>
            <ErrorMessage
              icon={<ExclamationMarkIcon />}
              title="We cannot find the page you’re looking for."
              description={
                <Link
                  to={routes.WORKSPACE_AGNOSTIC_SHARES.create({})}
                  isUnderlined
                >
                  Go to your documents view
                </Link>
              }
            />
          </IndexLayoutOld>
        )}
      />

      <Route
        render={() => (
          <WorkspaceSidebarLayout
            hideFooter={isDiscoverRoute(path)}
            hideHeader={isPersonalSettingsRoute(path)}
            render={layoutProps => (
              <NonWorkspaceSidebarLayoutRoutesSwitch
                layoutProps={layoutProps}
              />
            )}
          />
        )}
      />
    </Switch>
  )
}
