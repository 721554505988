import React, { useRef, useState, useEffect } from 'react'

import { dataIdFromObject } from '@sketch/graphql-cache'
import { LoadPageSeparator, handleFetchMore } from '@sketch/components'

import ShareVersionsListWrapper from '../ShareVersionsListWrapper'
import VersionItemPlaceholder from '../VersionItemPlaceholder'
import { LatestStar } from '../LatestStar'
import InlineSidebarError from '../InlineSidebarError'
import { ShareVersionsItem } from './ShareVersionsItem'

import { useVersioning } from '../../ShareVersionContext/'
import useGetShareVersions from '../../hooks/useGetShareVersions'

import { SharePresenceUsers, Wrapper } from './ShareVersions.styles'

const ENTRIES_PATH = ['share', 'versionHistory', 'entries']
const PAGE_SIZE = 20

interface ShareVersionsProps {
  shareIdentifier: string
}

const ShareVersions = (props: ShareVersionsProps) => {
  const { shareIdentifier } = props

  const { latestVersion, share, versionShortId } = useVersioning()
  const versionListRef = useRef<HTMLDivElement>(null)
  const [listHasScroll, setListHasScroll] = useState(false)
  const latestStarVersionRef = useRef<string | null>(null)

  // only show the latest star panel when the list has scroll
  const checkListScroll = () => {
    const listElement = document.querySelectorAll(
      '[aria-label="share-versions-list"]'
    )[0]

    if (listElement) {
      setListHasScroll(listElement.scrollHeight > listElement.clientHeight)
    }
  }

  useEffect(() => {
    window.addEventListener('resize', checkListScroll)

    return () => {
      window.removeEventListener('resize', checkListScroll)
    }
  }, [])

  const { loading, error, data, fetchMore } =
    useGetShareVersions(shareIdentifier)

  useEffect(() => {
    // make sure we trigger the latest star panel only when the versions have loaded
    if (data?.share?.versionHistory) {
      checkListScroll()
    }
  }, [data?.share?.versionHistory])

  if (loading || error || !data?.share) {
    return (
      <ShareVersionsListWrapper>
        <VersionItemPlaceholder count={5} />
        {error && <InlineSidebarError error={error} />}
      </ShareVersionsListWrapper>
    )
  }

  const { entries, meta } = data.share.versionHistory || {}
  const versions = [...entries].reverse()
  const { lastStarredVersion } = data.share

  let latestStar = null
  const latestRef = latestStarVersionRef.current
    ? (latestStar = versions.find(
        v => v.identifier === latestStarVersionRef.current
      ))
    : null

  // if we have a latestStarVersionRef and it's more recent than lastStarredVersion
  // OR
  // If we have unstarred the latest update, we can check the ref if there's any data there
  if (
    latestRef &&
    (Date.parse(latestRef?.createdAt as string) >
      Date.parse(lastStarredVersion?.createdAt as string) ||
      lastStarredVersion?.kind !== 'PUBLISHED')
  ) {
    latestStar = latestRef
  }

  // otherwise, if the query returns the latest published, we can safely use that
  else if (lastStarredVersion?.kind === 'PUBLISHED') {
    latestStar = lastStarredVersion
  }

  /* Create the page loader for InfiniteList */
  const handleLoadMore = handleFetchMore(fetchMore, ENTRIES_PATH, {
    dataIdFromObject,
    after: meta?.after,
  })

  return (
    <Wrapper>
      {listHasScroll && <LatestStar latestStar={latestStar} />}
      <ShareVersionsListWrapper
        aria-label="share-versions-list"
        data-testid="share-versions-list"
        ref={versionListRef}
      >
        <VersionItemPlaceholder
          count={Math.min(PAGE_SIZE, meta.totalCount - entries.length)}
        />

        {meta.after && (
          <LoadPageSeparator key={meta.after} loadNewPage={handleLoadMore} />
        )}

        {versions.map(version => {
          if (
            version.__typename === 'Version' &&
            version.kind === 'PUBLISHED'
          ) {
            latestStarVersionRef.current = version.identifier
          }

          const position = version?.order ?? undefined

          return (
            <ShareVersionsItem
              key={version.identifier}
              version={version}
              share={share}
              position={position}
              isLatestVersion={version.identifier === latestVersion.identifier}
              isLatestStared={
                version.identifier === latestStarVersionRef.current
              }
              isSingleVersion={meta?.totalCount === 1}
              isVersionSelected={
                version.__typename === 'Version' &&
                version.shortId === versionShortId
              }
            />
          )
        })}
        <SharePresenceUsers shareIdentifier={shareIdentifier} />
      </ShareVersionsListWrapper>
    </Wrapper>
  )
}

export default ShareVersions
