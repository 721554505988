import {
  useShareForDocumentWithUuidQuery,
  useGetComponentWithUuidQuery,
  VersionFragment,
  ComponentInfoFragment,
} from '@sketch/gql-types'

// This is a valid use case to narrow down ComponentInfoFragment type
// eslint-disable-next-line no-restricted-imports
import { LayerStyle, TextStyle } from '@sketch/gql-types/expansive'

interface Args {
  componentId?: string
  externalDocumentId?: string | null
  externalDocumentName?: string | null
  isForeign?: boolean
  shareIdentifier: string
  currentVersion?: VersionFragment
}

export const isLayerStyle = (
  component?: ComponentInfoFragment
): component is LayerStyle => component?.__typename === 'LayerStyle'

export const isTextStyle = (
  component?: ComponentInfoFragment
): component is TextStyle => component?.__typename === 'TextStyle'

/**
 * This hooks retrieves all the needed data to render a component link in the
 * inspector sidebar, for both internal and external components (layer style,
 * text style or color variable).
 *
 * The logic is a bit convoluted because we need to perform a couple of chained
 * queries. For local components we only need to query the component itself
 * (mainly to retrieve the preview image), but for foreign components we need
 * to first retrieve the related share to extract the document identifier, that
 * will be needed to retrieve the component data.
 */
export function useGetComponentWithUuid({
  componentId,
  externalDocumentId,
  externalDocumentName,
  isForeign,
  shareIdentifier,
  currentVersion,
}: Args) {
  // To get the foreign version document ID, we need to query for:
  // Share -> version -> document -> identifier
  const {
    data: externalShareData,
    loading: externalShareLoading,
    // This error could mean different things, a library that is not upload to
    // the web app or a library you don't have access. We could check the error
    // codes if we need an specific message to user: NOT_FOUND, UNAUTHORIZED
    error: errorExternalShare,
  } = useShareForDocumentWithUuidQuery({
    variables: {
      name: externalDocumentName ?? '',
      uuid: externalDocumentId ?? '',
    },
    skip: !externalDocumentName || !externalDocumentId,
  })

  // isForeign being false implies that externalDocumentName and
  // externalDocumentId are null
  const shareID = isForeign
    ? externalShareData?.shareForDocumentWithUuid?.identifier
    : shareIdentifier

  const documentID = isForeign
    ? externalShareData?.shareForDocumentWithUuid?.version?.document?.identifier
    : currentVersion?.document?.identifier

  const versionShortId = isForeign
    ? externalShareData?.shareForDocumentWithUuid?.version?.shortId
    : currentVersion?.shortId

  const { data: componentData, loading: componentLoading } =
    useGetComponentWithUuidQuery({
      variables: {
        documentId: documentID ?? '',
        uuid: componentId ?? '',
      },
      skip: !documentID || !componentId || !!errorExternalShare,
    })

  const component = componentData?.componentInDocumentWithUuid

  const params = component
    ? {
        ...(component?.path && {
          // same approach as `prepareGroupURL` utils function
          g: encodeURIComponent(component.path.substring(0, 500)),
        }),
        ...(component?.identifier && { component: component.identifier }),
      }
    : ''

  const searchParams = new URLSearchParams(params).toString()

  return {
    loading: externalShareLoading || componentLoading,
    shareID: errorExternalShare ? undefined : shareID,
    // Needed because in some situations `externalDocumentName` is not updated
    externalShareName: externalShareData?.shareForDocumentWithUuid?.name,
    versionShortId: versionShortId!,
    component,
    // Note: when `errorExternalShare` is present, the related data is not
    // updated (it takes the previous `data` result from the query), so we
    // can't relay on `externalShareData` when there is an `errorExternalShare`,
    // looks like an Apollo problem
    componentUnavailable: !!errorExternalShare,
    searchParams,
  }
}
