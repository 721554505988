import React from 'react'

import { dateFormat, formatByteCount } from '@sketch/utils'
import {
  DocumentInfo as DocumentInfoWrapper,
  DocumentInfoTitle,
  DocumentInfoItemWrapper,
  DocumentInfoItemTitle,
  Separator,
  StyledRadioButton,
} from './DocumentInfoModal.styles'
import { Box, Text } from '@sketch/components'

import {
  ShareInfoFragment,
  VersionFragment,
  useGetWorkspaceQuery,
} from '@sketch/gql-types'

/**
 * TYPES
 */
interface DocumentInfoItemProps {
  title: string
  text?: string | null
}

export interface ShareForDocumentInfo
  extends Pick<ShareInfoFragment, 'identifier' | 'createdAt' | 'shareType'> {
  workspace: Pick<
    NonNullable<ShareInfoFragment['workspace']>,
    'name' | 'identifier'
  >
}

type VersionDocument = NonNullable<VersionFragment['document']>
export interface VersionForDocumentInfo
  extends Pick<VersionFragment, 'createdAt'> {
  document: Pick<VersionDocument, 'colorspace' | 'size'> | null
}

export interface VersionsForDocumentInfo {
  currentVersion: VersionForDocumentInfo | null
  latestVersion: VersionForDocumentInfo | null
}

export interface DocumentInfoProps {
  share: ShareForDocumentInfo
  version: VersionsForDocumentInfo
  shareType: ShareForDocumentInfo['shareType']
  handleShareType: (shareType: ShareForDocumentInfo['shareType']) => void
}

/**
 * COMPONENTS
 */
const DocumentInfoItem = ({ title, text }: DocumentInfoItemProps) => {
  if (!text) return null

  return (
    <DocumentInfoItemWrapper>
      <DocumentInfoItemTitle>{title}</DocumentInfoItemTitle>
      <Text>{text}</Text>
    </DocumentInfoItemWrapper>
  )
}

const DocumentInfo = ({
  share,
  version,
  shareType,
  handleShareType,
}: DocumentInfoProps) => {
  const { createdAt, workspace } = share
  const { currentVersion, latestVersion } = version
  const sizeInBytes = currentVersion?.document?.size
  const lastUpdate = latestVersion?.createdAt

  // Get current workspace to check if it's active
  const { data: workspaceData } = useGetWorkspaceQuery({
    variables: { identifier: share.workspace.identifier },
    fetchPolicy: 'cache-only',
  })
  const isInactive = workspaceData?.workspace.status === 'INACTIVE'

  const handleShareTypeChange = async (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newShareType = e.target.value
    handleShareType(newShareType as ShareForDocumentInfo['shareType'])
  }

  return (
    <>
      <Separator />

      <DocumentInfoTitle>Set As</DocumentInfoTitle>
      <Box mt={5}>
        <StyledRadioButton
          name="shareType"
          label="Document"
          help="A collaborative design document (default)"
          value="STANDARD"
          checked={shareType === 'STANDARD'}
          onChange={handleShareTypeChange}
          disabled={isInactive}
        />

        <StyledRadioButton
          name="shareType"
          label="Library"
          help="A group of shareable Symbols, Styles and Color Variables"
          value="LIBRARY"
          checked={shareType === 'LIBRARY'}
          onChange={handleShareTypeChange}
          disabled={isInactive}
        />

        <StyledRadioButton
          name="shareType"
          label="Template"
          help="A reusable Workspace document with pre-populated content"
          value="TEMPLATE"
          checked={shareType === 'TEMPLATE'}
          onChange={handleShareTypeChange}
          disabled={isInactive}
        />
      </Box>

      <Separator />

      <DocumentInfoTitle>Document Info</DocumentInfoTitle>
      <DocumentInfoWrapper>
        <DocumentInfoItem
          title="Created On"
          text={dateFormat(new Date(createdAt as string))}
        />
        <DocumentInfoItem title="Located In" text={workspace.name} />

        <DocumentInfoItem
          title="Last Updated On"
          text={dateFormat(new Date(lastUpdate as string))}
        />
        <DocumentInfoItem
          title="Color Profile"
          text={currentVersion?.document?.colorspace || 'Unmanaged'}
        />
        <DocumentInfoItem
          title="File Size"
          text={formatByteCount(sizeInBytes as number)}
        />
      </DocumentInfoWrapper>
    </>
  )
}

export default DocumentInfo
