import React, { useState, useEffect } from 'react'
import { useRouteMatch } from 'react-router-dom'
import * as Collapsible from '@radix-ui/react-collapsible'

import { Truncate } from '@sketch/components'
import { CollapsibleIcon, Link } from './GroupsList.styles'
import SkeletonGroupsList from './SkeletonGroupsList'

import type { ComponentsTree } from '../../types'

import { getGroupRoute, prepareGroupURL, getGroupFromURL } from '../../utils'

// Operations
import { useGetIdentifiers } from '../../../hooks/useGetIdentifiers'

// This is a valid use case to validate version.cocument.componentsState
// eslint-disable-next-line no-restricted-imports
import { DocumentComponentsState } from '@sketch/gql-types/expansive'

export interface GroupItemProps {
  name: string
  path: string
  children?: ComponentsTree[]
  level: number
  selected: string
  openBranches?: string[]
  componentsState?: DocumentComponentsState
}

export interface GroupsListProps {
  componentsTree: ComponentsTree[]
  level?: number
  selected: string
  componentsState?: DocumentComponentsState
}

/**
 * COMPONENTS
 */
const GroupItem: React.FC<GroupItemProps> = ({
  name,
  path,
  children,
  level,
  selected,
  openBranches,
  componentsState,
}) => {
  const [isOpen, setOpen] = useState(false)
  const { shareIdentifier } = useGetIdentifiers()
  const routeMatch = useRouteMatch()

  const routeKey = getGroupRoute(routeMatch.path)

  useEffect(() => {
    if (openBranches?.includes?.(path ?? '')) {
      setOpen(true)
    } else {
      setOpen(false)
    }
  }, [openBranches, path])

  const handleLinkClick = (e: React.SyntheticEvent) => {
    // If the item is selected and expanded the click just collapses it,
    // in any other case we open and perform the query (via onSelect)
    if (selected === path || isOpen) {
      e.preventDefault()
      setOpen(false)
    } else {
      setOpen(true)
    }
  }

  const handleCollapsibleClick = (e: React.SyntheticEvent) => {
    e.preventDefault()
    setOpen(true)
  }

  const search =
    name === 'View All' ? undefined : prepareGroupURL(path.split('/'))

  return (
    <Collapsible.Root
      asChild
      open={isOpen}
      onOpenChange={setOpen as (isOpen: boolean) => void}
    >
      <li>
        <Link
          to={{
            routeKey,
            routeParams: {
              shareID: shareIdentifier,
            },
            search,
          }}
          level={level}
          selected={selected === path}
          onClick={handleLinkClick}
        >
          <Truncate>{name}</Truncate>
          {children?.length ? (
            <Collapsible.Trigger asChild>
              <CollapsibleIcon
                selected={selected === path}
                isOpen={isOpen}
                onClick={handleCollapsibleClick}
              />
            </Collapsible.Trigger>
          ) : null}
        </Link>
        {children?.length ? (
          <Collapsible.Content>
            <GroupsList
              level={level + 1}
              componentsTree={children}
              selected={selected}
              componentsState={componentsState}
            />
          </Collapsible.Content>
        ) : null}
      </li>
    </Collapsible.Root>
  )
}

export const GroupsList: React.FC<GroupsListProps> = ({
  componentsTree,
  level = 0,
  selected,
  componentsState,
}) => {
  const group = getGroupFromURL()
  const [openBranches, setOpenBranches] = useState<string[]>([])

  // with a group string, get which branches are meant to be open
  // example: selected group has the path 'a/b/c'
  // therefore the open branches will be the ones with the paths 'a', 'a/b' and 'a/b/c'
  function getPath(group: string | null): string[] {
    if (!group || group === '') {
      return []
    }

    const path = []
    const groupArray = group.split('/')

    for (let i = 1; i < groupArray.length; i++) {
      path.push(groupArray.slice(0, i).join('/'))
    }

    path.push(group)

    return path
  }

  useEffect(() => {
    const openBranches = getPath(group)

    setOpenBranches(openBranches)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [group])

  if (componentsState === 'PROCESSING') {
    return <SkeletonGroupsList />
  }

  return (
    <ul>
      {level === 0 && (
        <GroupItem
          name="View All"
          path="View All"
          openBranches={openBranches}
          selected={selected}
          level={level}
          componentsState={componentsState}
        />
      )}
      {componentsTree.map(leaf => (
        <GroupItem
          key={leaf.name}
          name={leaf.name}
          path={leaf.path}
          selected={selected}
          openBranches={openBranches}
          level={level}
          componentsState={componentsState}
        >
          {leaf.children}
        </GroupItem>
      ))}
    </ul>
  )
}
