import React, { useState } from 'react'
import copy from 'copy-to-clipboard'
import {
  Flex,
  Text,
  Button,
  Tooltip,
  CopyToClipboard,
  useForTablet,
  Link,
  useResponsiveDropdown,
} from '@sketch/components'
import {
  CheckboxStyled as Checkbox,
  SeparatorStyled as Separator,
  ToggleButton,
  DropdownItem,
  ToggleButtonText,
  DropdownContent,
  ExportSettingsTitle,
  ModalTitle,
  SettingsContainer,
  ExportSetting,
  ColorSetting,
  DropdownWrapper,
  StyledQuestionMarkIcon,
  DropdownWrapperTwoColumns,
  DropdownContentTwoColumns,
} from './ExportDesignTokens.styles'
import {
  DropdownInnerContainer,
  DropdownChevrons,
} from '../../../workspace/containers/WorkspacesDropdown/WorkspacesDropdown.styles'
import { ReactComponent as LibraryIcon } from '@sketch/icons/link-sharing-24'

// importing the PublicTokenExport type to help with type inference
// eslint-disable-next-line no-restricted-imports
import {
  TokenColorFormat,
  PublicTokenExport,
  TokenExportFormat,
} from '@sketch/gql-types/expansive'

enum ColorFormatDisplay {
  RGBA = 'RGB',
  HSLA = 'HSL',
  HEX = 'Hex',
}

enum ExportFormatDisplay {
  AMAZON = 'JSON',
  CSS = 'CSS',
  W3C = 'Design Tokens File (beta)',
}

enum ToggleVisibilityDisplay {
  LATEST_STARRED = 'Enabled for latest starred version',
  LATEST = 'Enabled for latest document update',
  DISABLED = 'Disabled',
}

interface ExportFormPanelProps {
  include: {
    colorVariables: boolean
    layerStyles: boolean
    textStyles: boolean
  }
  setInclude: (input: {
    colorVariables: boolean
    layerStyles: boolean
    textStyles: boolean
  }) => void
  format: TokenExportFormat
  handleFormatChange: (format: TokenExportFormat) => void
  colorFormat: TokenColorFormat
  setColorFormat: (input: TokenColorFormat) => void
  disabledDropdown: boolean
  // We want to disable all form controls when there are no tokens to export
  disableAll?: boolean
  handlePublicLinkStatusChange: (publicTokenExport: PublicTokenExport) => void
  publicTokenExport: PublicTokenExport
  publicTokenExportUrl: string | null
  loading: boolean
}

type ToggleVisibilityDropdownProps = {
  publicTokenExport: string
  handlePublicLinkStatusChange: (type: PublicTokenExport) => void
}

type FormatSelectButtonProps = {
  format: string
  handleFormatChange: (format: TokenExportFormat) => void
}

type ColorFormatSelectButtonProps = {
  colorFormat: string
  handleFormatChange: (format: TokenColorFormat) => void
}

const ToggleVisibilityDropdown = ({
  publicTokenExport,
  handlePublicLinkStatusChange,
}: ToggleVisibilityDropdownProps) => (
  <DropdownContent>
    <DropdownItem
      active={publicTokenExport === 'LATEST'}
      onClick={() => handlePublicLinkStatusChange('LATEST')}
    >
      {ToggleVisibilityDisplay.LATEST}
    </DropdownItem>
    <DropdownItem
      active={publicTokenExport === 'LATEST_STARRED'}
      onClick={() => handlePublicLinkStatusChange('LATEST_STARRED')}
    >
      {ToggleVisibilityDisplay.LATEST_STARRED}
    </DropdownItem>
    <DropdownItem
      active={publicTokenExport === 'DISABLED'}
      onClick={() => handlePublicLinkStatusChange('DISABLED')}
    >
      {ToggleVisibilityDisplay.DISABLED}
    </DropdownItem>
  </DropdownContent>
)

const FormatSelectButton = ({
  format,
  handleFormatChange,
}: FormatSelectButtonProps) => (
  <DropdownContentTwoColumns>
    <DropdownItem
      active={format === 'AMAZON'}
      onClick={() => handleFormatChange('AMAZON')}
    >
      {ExportFormatDisplay.AMAZON}
    </DropdownItem>
    <DropdownItem
      active={format === 'CSS'}
      onClick={() => handleFormatChange('CSS')}
    >
      {ExportFormatDisplay.CSS}
    </DropdownItem>
    <DropdownItem
      active={format === 'W3C'}
      onClick={() => handleFormatChange('W3C')}
    >
      {ExportFormatDisplay.W3C}
    </DropdownItem>
  </DropdownContentTwoColumns>
)

const ColorFormatSelectButton = ({
  colorFormat,
  handleFormatChange,
}: ColorFormatSelectButtonProps) => (
  <DropdownContentTwoColumns>
    <DropdownItem
      active={colorFormat === 'RGBA'}
      onClick={() => handleFormatChange('RGBA')}
    >
      {ColorFormatDisplay.RGBA}
    </DropdownItem>
    <DropdownItem
      active={colorFormat === 'HEX'}
      onClick={() => handleFormatChange('HEX')}
    >
      {ColorFormatDisplay.HEX}
    </DropdownItem>
    <DropdownItem
      active={colorFormat === 'HSLA'}
      onClick={() => handleFormatChange('HSLA')}
    >
      {ColorFormatDisplay.HSLA}
    </DropdownItem>
  </DropdownContentTwoColumns>
)

export const ExportFormPanel = ({
  include,
  setInclude,
  format,
  handleFormatChange,
  colorFormat,
  setColorFormat,
  disabledDropdown,
  disableAll,
  handlePublicLinkStatusChange,
  publicTokenExport,
  publicTokenExportUrl,
  loading,
}: ExportFormPanelProps) => {
  const isFormatDisabled = include.layerStyles || include.textStyles
  const [tooltipVisible, setTooltipVisible] = useState(false)
  const [tooltipHelpVisible, setTooltipHelpVisible] = useState(false)
  const isTabletAndBigger = useForTablet()
  const [toggleVisibilityContent, toggleVisibilityButtonProps] =
    useResponsiveDropdown({
      dropdown: ToggleVisibilityDropdown,
      dropdownProps: {
        publicTokenExport,
        handlePublicLinkStatusChange,
      },
      placement: 'bottom-end',
      usePortal: true,
    })
  const [formatSelectContent, formatSelectButtonProps] = useResponsiveDropdown({
    dropdown: FormatSelectButton,
    dropdownProps: {
      format,
      handleFormatChange,
    },
    placement: 'bottom-end',
    usePortal: true,
  })
  const [colorFormatSelectContent, colorFormatSelectButtonProps] =
    useResponsiveDropdown({
      dropdown: ColorFormatSelectButton,
      dropdownProps: {
        colorFormat,
        handleFormatChange: setColorFormat,
      },
      placement: 'bottom-end',
      usePortal: true,
    })

  const handleCopyClick = () => {
    if (!publicTokenExportUrl) {
      return
    }

    copy(publicTokenExportUrl)

    setTooltipVisible(true)

    window.setTimeout(() => {
      setTooltipVisible(false)
    }, CopyToClipboard.animationDuration)
  }

  const isColorFormatDisabled = format === 'W3C'

  return (
    <Flex flexGrow={1} pr={isTabletAndBigger ? 8 : 0} flexDirection="column">
      <ModalTitle>Export Design Tokens</ModalTitle>
      <Text textStyle="copy.tertiary.standard.E">
        Use design tokens to extract Color Variables, Layer and Text Styles in
        various formats, and use them in your code.{' '}
        <Tooltip
          content="How to use design tokens"
          placement="top"
          visible={tooltipHelpVisible}
          style={{
            display: 'inline',
          }}
        >
          <Link
            external
            // @TODO: update this link when the docs are ready
            href="https://www.sketch.com/docs/developer-handoff"
          >
            <StyledQuestionMarkIcon
              // using the controlled tooltip because there's a bug with tooltips and modals
              // when you open a modal with an uncontrolled tooltip, it auto shows the tooltip
              onMouseEnter={() => setTooltipHelpVisible(true)}
              onMouseLeave={() => setTooltipHelpVisible(false)}
            />
          </Link>
        </Tooltip>
      </Text>
      <ExportSettingsTitle>Include design tokens for:</ExportSettingsTitle>
      <Checkbox
        value={include.colorVariables ? 1 : 0}
        name="colorVariables"
        checked={include.colorVariables}
        data-testid="include-color-variables"
        label="Color Variables"
        onChange={() =>
          setInclude({
            ...include,
            colorVariables: !include.colorVariables,
          })
        }
      />
      <Checkbox
        value={include.layerStyles ? 1 : 0}
        name="layerStyles"
        checked={include.layerStyles}
        data-testid="include-layer-styles"
        label="Layer Styles"
        onChange={() =>
          setInclude({
            ...include,
            layerStyles: !include.layerStyles,
          })
        }
      />
      <Checkbox
        value={include.textStyles ? 1 : 0}
        name="textStyles"
        checked={include.textStyles}
        data-testid="include-text-styles"
        label="Text Styles"
        onChange={() =>
          setInclude({
            ...include,
            textStyles: !include.textStyles,
          })
        }
      />

      <SettingsContainer>
        <ExportSetting>
          <ExportSettingsTitle>Export settings</ExportSettingsTitle>
          <DropdownWrapperTwoColumns>
            <ToggleButton
              disabled={isFormatDisabled || disableAll}
              {...formatSelectButtonProps}
            >
              <DropdownInnerContainer>
                {isFormatDisabled ? (
                  <Tooltip
                    placement="top"
                    content="Layer and Text Styles can only be exported as design tokens"
                  >
                    <ToggleButtonText>
                      {ExportFormatDisplay[format]}
                    </ToggleButtonText>
                  </Tooltip>
                ) : (
                  <ToggleButtonText>
                    {ExportFormatDisplay[format]}
                  </ToggleButtonText>
                )}
                <DropdownChevrons />
              </DropdownInnerContainer>
            </ToggleButton>
            {formatSelectContent}
          </DropdownWrapperTwoColumns>
        </ExportSetting>
        <ColorSetting>
          <ExportSettingsTitle>Color</ExportSettingsTitle>
          <DropdownWrapperTwoColumns>
            <ToggleButton
              disabled={isColorFormatDisabled || disableAll}
              {...colorFormatSelectButtonProps}
            >
              <DropdownInnerContainer>
                {isColorFormatDisabled ? (
                  <Tooltip
                    placement="top"
                    content="W3C Standards only support hex"
                  >
                    <ToggleButtonText>
                      {ColorFormatDisplay[colorFormat]}
                    </ToggleButtonText>
                  </Tooltip>
                ) : (
                  <ToggleButtonText>
                    {ColorFormatDisplay[colorFormat]}
                  </ToggleButtonText>
                )}
                <DropdownChevrons />
              </DropdownInnerContainer>
            </ToggleButton>
            {colorFormatSelectContent}
          </DropdownWrapperTwoColumns>
        </ColorSetting>
      </SettingsContainer>

      <Separator />

      <Text as="span" textStyle="copy.tertiary.standard.E">
        Public Link
      </Text>
      <Flex width="100%" justifyContent="space-between" mt={2} flexWrap="wrap">
        <DropdownWrapper>
          <ToggleButton
            disabled={disabledDropdown || disableAll}
            {...toggleVisibilityButtonProps}
          >
            <DropdownInnerContainer>
              <LibraryIcon height={24} />
              <ToggleButtonText>
                {ToggleVisibilityDisplay[publicTokenExport]}
              </ToggleButtonText>
              <DropdownChevrons />
            </DropdownInnerContainer>
          </ToggleButton>
          {toggleVisibilityContent}
        </DropdownWrapper>

        {publicTokenExport === 'DISABLED' || disableAll ? (
          <Tooltip
            placement="top"
            content="Sharing public links is currently disabled for this document"
          >
            <Button size="40" disabled>
              Copy Link
            </Button>
          </Tooltip>
        ) : (
          <Tooltip visible={tooltipVisible} placement="top" content="Copied">
            <Button
              size="40"
              disabled={loading || !publicTokenExportUrl}
              onClick={handleCopyClick}
            >
              Copy Link
            </Button>
          </Tooltip>
        )}
      </Flex>
    </Flex>
  )
}
