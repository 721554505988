import {
  ApolloLink,
  FetchResult,
  NextLink,
  Observable,
  Operation,
} from 'apollo-link'
import { getMainDefinition } from 'apollo-utilities'

import { MutationsTracker } from './MutationsTracker'

type GetIsMutationSuccessfulPredicatesLoose = {
  [mutationName: string]: (data: any) => boolean
}

type MutationsMapObjLoose = {
  name: string
  getVariables: (data: any) => {}
}

type SubscriptionsToMutationsMapLoose = {
  [subscriptionName: string]: MutationsMapObjLoose[]
}

type ShouldTrackMutationPredicatesLoose = {
  [subscriptionName: string]: true | (() => boolean)
}

export interface IgnoreSubscriptionsLinkProps {
  tracker: MutationsTracker
  isMutationSuccessful: GetIsMutationSuccessfulPredicatesLoose
  subscriptionsToMutationsMap: SubscriptionsToMutationsMapLoose
  shouldTrackMutation: ShouldTrackMutationPredicatesLoose
}

export class IgnoreSubscriptionsLink extends ApolloLink {
  private readonly mutationsTracker: MutationsTracker
  private readonly getIsMutationSuccessfulPredicates: GetIsMutationSuccessfulPredicatesLoose
  private readonly subscriptionsToMutationsMap: SubscriptionsToMutationsMapLoose
  private readonly shouldTrackMutationPredicates: ShouldTrackMutationPredicatesLoose

  constructor({
    tracker,
    isMutationSuccessful,
    subscriptionsToMutationsMap,
    shouldTrackMutation,
  }: IgnoreSubscriptionsLinkProps) {
    super()
    this.mutationsTracker = tracker
    this.getIsMutationSuccessfulPredicates = isMutationSuccessful
    this.subscriptionsToMutationsMap = subscriptionsToMutationsMap
    this.shouldTrackMutationPredicates = shouldTrackMutation
  }

  private shouldTrackMutation(mutationName: string): boolean {
    if (!mutationName) return false
    const shouldTrack = this.shouldTrackMutationPredicates[mutationName]

    if (shouldTrack === true) return true
    if (!shouldTrack) return false

    return shouldTrack()
  }

  private onMutationOperation = (operation: Operation, forward: NextLink) => {
    const { operationName, variables } = operation

    if (!this.shouldTrackMutation(operationName)) {
      // if mutation isn't even tracked, forward
      // mutation result and end all execution.
      return forward(operation)
    }

    this.mutationsTracker.registerMutation(operationName, variables)

    return new Observable<FetchResult>(observer => {
      const subscription = forward(operation).subscribe({
        next: result => {
          const getIsMutationSuccessful =
            this.getIsMutationSuccessfulPredicates[operationName]

          if (!getIsMutationSuccessful(result.data)) {
            // mutation failed, however, the error was returned in the success path
            // see https://github.com/sketch-hq/Cloud/issues/4292
            this.mutationsTracker.unregisterMutation(operationName, variables)
          }
          observer.next(result)
        },
        error: error => {
          this.mutationsTracker.unregisterMutation(operationName, variables)
          observer.error(error)
        },
        complete: () => {
          observer.complete.call(observer)
        },
      })

      return () => {
        if (subscription) subscription.unsubscribe()
      }
    })
  }

  private onSubscriptionOperation = (
    operation: Operation,
    forward: NextLink
  ) => {
    const { operationName } = operation
    const mutations = this.subscriptionsToMutationsMap[operationName] || []

    const trackedMutations = mutations.filter(({ name: mutationName }) =>
      this.shouldTrackMutation(mutationName)
    )

    if (trackedMutations.length === 0) {
      // if corresponding mutation isn't even tracked, forward
      // subscription result and end all execution.
      return forward(operation)
    }

    return new Observable<FetchResult>(observer => {
      const subscription = forward(operation).subscribe({
        next: result => {
          if (!result.data) {
            observer.next(result)
            return
          }

          const firedMutations = mutations.filter(({ getVariables, name }) => {
            const variables = getVariables(result.data)
            return this.mutationsTracker.wasMutationFired(name, variables)
          })

          if (firedMutations.length > 0) {
            firedMutations.forEach(({ getVariables, name }) => {
              const variables = getVariables(result.data)
              this.mutationsTracker.unregisterMutation(name, variables)
            })

            return
          }

          observer.next(result)
        },
        error: error => {
          observer.error(error)
        },
        complete: observer.complete.bind(observer),
      })

      return () => {
        if (subscription) subscription.unsubscribe()
      }
    })
  }

  public request(operation: Operation, forward: NextLink) {
    const definition = getMainDefinition(operation.query)

    if (definition.kind === 'FragmentDefinition') {
      return forward(operation)
    }

    switch (definition.operation) {
      case 'mutation':
        return this.onMutationOperation(operation, forward)
      case 'subscription':
        return this.onSubscriptionOperation(operation, forward)
      default:
        return forward(operation)
    }
  }
}
