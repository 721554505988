import { useEffect, useRef } from 'react'
import { useVersioning } from '../../versioning'

/**
 * useAnnotationVersionBlocking
 *
 * This hook returns true when:
 * - The user sees annotations on the latest version
 * - The user sees annotations on a once latest version while a new version is already pushed (maybe work in progress, so we don't disturb)
 *
 * This hook returns false when:
 * - The user opens a older version without having seen it previously
 * - The user navigates from the latest to an older version
 */
export const useAnnotationVersionBlocking = () => {
  const { versionShortId, latestVersion } = useVersioning()

  const versionWhenMounting = useRef(versionShortId)
  const previousLatestVersion = useRef(latestVersion)

  useEffect(() => {
    /**
     * Reset the "versionWhenMounting" when the user accesses the
     * latest version available
     */
    if (versionShortId === latestVersion.shortId) {
      versionWhenMounting.current = versionShortId
      previousLatestVersion.current = latestVersion
    }
  }, [latestVersion, versionShortId])

  return versionShortId === previousLatestVersion.current.shortId
}
