import React, { useState } from 'react'
import styled from 'styled-components'

import { Button, Modal, ModalInjectedProps } from '@sketch/components'
import { useToast } from '@sketch/toasts'

import { SsoSamlSetupForm } from '../../components/SsoSamlSetupForm'

import { IdentityProviderConfigFragment } from '@sketch/gql-types'

interface ManualSsoConfigurationProps extends ModalInjectedProps {
  customerId: string
  identityProviderConfig?: IdentityProviderConfigFragment
}

const StyledModal = styled(Modal)`
  max-width: 595px;
`

export const ManualSsoConfigurationModal: React.FC<
  ManualSsoConfigurationProps
> = ({ customerId, identityProviderConfig, hideModal }) => {
  const formId = 'manual-sso-config'

  const { showToast } = useToast()
  const [loading, setLoading] = useState(false)

  const onSuccess = () => {
    showToast('SSO configuration successfully saved', 'positive')
    setLoading(false)
    hideModal()
  }

  const onError = (message?: string) => {
    setLoading(false)
    showToast(
      message ?? 'Something went wrong. Please try again later',
      'negative'
    )
  }

  return (
    <StyledModal onCancel={hideModal}>
      <Modal.Header>Manually Configure SAML</Modal.Header>
      <Modal.Body>
        <SsoSamlSetupForm
          formId={formId}
          customerId={customerId}
          identityProviderConfig={identityProviderConfig}
          onSuccess={onSuccess}
          onSubmitStart={() => setLoading(true)}
          onError={onError}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={hideModal} disabled={loading} size="40">
          Cancel
        </Button>
        <Button
          as="label"
          htmlFor={formId}
          variant="primary"
          loading={loading}
          disabled={loading}
          size="40"
        >
          Save Changes
        </Button>
      </Modal.Footer>
    </StyledModal>
  )
}
