import React from 'react'
import { Location } from 'history'

import { SKETCH_WEBSITE } from '@sketch/env-config'

import {
  RouteProps,
  routes,
  getIsForInAppPresentation,
  IndexLayoutOldTitle,
  IndexLayoutOldSubtitle,
  useQueryParams,
  IndexLayoutContent,
  sourceToSsoSignInSource,
  IndexLayoutExtraProps,
  IndexLayoutHeaderLink,
} from '@sketch/modules-common'

import SsoSignInForm from './SsoSignInForm'

import {
  SsoInformationAndFaqWrapper,
  StyledLink,
  Wrapper,
  Line,
} from './SsoSignInForCloud.styles'

export const SsoSignInForCloud: React.FC<
  RouteProps<'SSO_SIGN_IN'> & IndexLayoutExtraProps
> = props => {
  const { HeaderPortal } = props
  const { state } = props.location as Location<{ from?: Location }>
  const { source = 'cloud', team, shortname } = useQueryParams<'SSO_SIGN_IN'>()

  const isForInAppPresentation = getIsForInAppPresentation()

  const teamName = team || shortname
  const formSource = isForInAppPresentation
    ? 'sketch'
    : (sourceToSsoSignInSource[source] ?? 'cloud')
  const target = isForInAppPresentation ? '_self' : '_blank'

  return (
    <IndexLayoutContent center="horizontal" marginTop paddingHorizontal>
      <HeaderPortal>
        <IndexLayoutHeaderLink />
      </HeaderPortal>
      <Wrapper>
        <IndexLayoutOldTitle>Enter SSO Shortname</IndexLayoutOldTitle>
        <IndexLayoutOldSubtitle>
          Enter your Workspace&apos;s single sign-on (SSO) name
        </IndexLayoutOldSubtitle>
        <SsoSignInForm
          source={formSource}
          teamName={teamName}
          from={state?.from?.pathname}
        />
        <SsoInformationAndFaqWrapper>
          <Line />
          <StyledLink
            href={`${SKETCH_WEBSITE}/redirect/web/sso`}
            target={target}
            rel="noopener noreferrer"
            variant="primary"
            isUnderlined
            external
          >
            How Single Sign-On (SSO) works
          </StyledLink>
          {!isForInAppPresentation && (
            <>
              <StyledLink
                variant="primary"
                isUnderlined
                to={{ pathname: routes.SIGN_IN.create({}), state }}
              >
                Sign in with email and password
              </StyledLink>
            </>
          )}
        </SsoInformationAndFaqWrapper>
      </Wrapper>
    </IndexLayoutContent>
  )
}
