import {
  RouteParams,
  routes,
  VersionedRouteKeys,
  VersionedRouteParams,
  versionedRoutes,
} from '@sketch/modules-common'
import qs from 'query-string'
import { match, matchPath } from 'react-router-dom'
import { Route } from 'typesafe-react-router'

type Routes = typeof routes
type VersionedRoutes = {
  [key in VersionedRouteKeys]: (typeof versionedRoutes)[key]['VERSION']
}

type MatchedNonVersionedRoutes = {
  [type in keyof Routes]: {
    type: type
    route: Routes[type]
    template: string
    pathname: string
    match: match<RouteParams<type>>
  }
}[keyof Routes]

type MatchedVersionedRoutes = {
  [type in VersionedRouteKeys]: {
    type: `${type}_VERSIONED`
    route: VersionedRoutes[type]
    template: string
    pathname: string
    match: match<VersionedRouteParams<type>>
  }
}[VersionedRouteKeys]

export type MatchedRoute = MatchedNonVersionedRoutes | MatchedVersionedRoutes
export type MismatchedRoute = {
  type: 'UNKNOWN'
  route: undefined
  template: undefined
  pathname: string
  match: undefined
}

type RouteTemplate = {
  type: string
  template: string
  route: Route<any, any>
}

let _allRoutes: RouteTemplate[] | null = null
const getAllRoutes = (): RouteTemplate[] => {
  if (_allRoutes) return _allRoutes

  const nonVersionedRoutes = Object.entries(routes).map(([name, route]) => ({
    type: name,
    route,
    template: route.template(),
  }))

  const tempVersionedRoutes = Object.entries(versionedRoutes).map(
    ([name, route]) => ({
      type: `${name}_VERSIONED`,
      route: route.VERSION,
      template: route.VERSION.template(),
    })
  )

  _allRoutes = [...nonVersionedRoutes, ...tempVersionedRoutes]

  return _allRoutes
}

// this cache is really nearly negligible optimization
// however, we intend to use `getMatchingRoute` mostly on the initial
// load of the app where execution speeds are important.
// And it is possible that `getMatchingRoute` will be used
// from multiple locations, also having in mind that this
// function can eventually end up in a render method -
// so it makes sense to put a small performance improvement
const _cache: { [key: string]: MatchedRoute } = {}

export const getMatchingRoute = (
  pathname: string
): MatchedRoute | MismatchedRoute => {
  if (_cache[pathname]) {
    return _cache[pathname]
  }

  const allRoutes = getAllRoutes()

  for (const entry of allRoutes) {
    const match = matchPath(pathname, {
      path: entry.template,
      exact: true,
      strict: false,
    })
    if (match) {
      const matchedRoute: MatchedRoute = {
        match,
        pathname,
        route: entry.route,
        template: entry.template,
        type: entry.type as any,
      }

      _cache[pathname] = matchedRoute

      return matchedRoute
    }
  }

  return {
    type: 'UNKNOWN',
    match: undefined,
    pathname,
    route: undefined,
    template: undefined,
  }
}

export const getKeysFromQueryParameters = (queryString: string): string[] => {
  const parsedQS = qs.parse(queryString) as Record<string, string>
  return Object.keys(parsedQS)
}

/**
 * getTemplateURLWithParams
 *
 * Returns a string with a template URL matched
 * with the original URL and query string params
 * added at the end
 */
export const getTemplateURLWithParams = (
  location: Pick<Location, 'pathname' | 'search'>,
  excludedRoutes: string[] = []
) => {
  const queryStringKeys = getKeysFromQueryParameters(location.search)

  const queryStringKeysForURL = queryStringKeys?.length
    ? `?${queryStringKeys.join('&')}`
    : ''

  const matchingRoute = getMatchingRoute(location.pathname)

  const shouldNotReplaceByTemplate = excludedRoutes.find(
    route => route === matchingRoute.template
  )

  const pathname = shouldNotReplaceByTemplate
    ? matchingRoute.pathname
    : matchingRoute.template

  return `${window.location.origin}${pathname}${queryStringKeysForURL}`
}
