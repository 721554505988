import { useToast } from '@sketch/toasts'
import { useMoveShareToProject } from '../../projects/operations'
import { useShareTransferToWorkspace } from './useShareTransferToWorkspace'
import { ProjectFragment, TransferShareMutation } from '@sketch/gql-types'

interface UseMoveShareToProps {
  onWorkspaceTransferCompleted: (data: TransferShareMutation) => void
  onProjectTransferCompleted: (project: ProjectFragment) => void
}

export const useMoveShareTo = function (props: UseMoveShareToProps) {
  const { onProjectTransferCompleted, onWorkspaceTransferCompleted } = props
  const { showToast } = useToast()

  const onMutationError = (errorMessage: string) =>
    showToast(errorMessage, 'negative')

  const [moveShareToProject, { loading: moveShareToProjectLoading }] =
    useMoveShareToProject({
      onCompleted: onProjectTransferCompleted,
      onError: onMutationError,
    })

  const [moveShareToWorkspace, { loading: moveShareToWorkspaceLoading }] =
    useShareTransferToWorkspace({
      onCompleted: onWorkspaceTransferCompleted,
      onError: onMutationError,
    })

  return {
    moveShareToProject,
    moveShareToWorkspace,
    loading: moveShareToProjectLoading || moveShareToWorkspaceLoading,
  }
}
