import React from 'react'
import styled, { css } from 'styled-components'
import classNames from 'classnames'

import { mergeRefs } from '@sketch/utils'

import { Button, ButtonProps } from '../Button'
import { Tooltip } from '../Tooltip'

type MenuIconButtonProps = ButtonProps & {
  active?: boolean
  description?: boolean
  $isHighlighted?: boolean
}

const IconButton = styled(Button).attrs<{ $isHighlighted?: boolean }>({
  size: '32',
})(
  ({ theme, $isHighlighted }) => css`
    padding: 4px 8px;
    box-shadow: none;
    border: none;
    position: relative;
    width: 40px;
    background-color: transparent;

    [role='img'] {
      color: ${theme.colors.foreground.secondary.A};
      opacity: 0.55;
      width: 24px;
    }

    :hover {
      [role='img'] {
        opacity: 1;
      }
    }

    &[disabled] {
      opacity: 0.8;

      /* This will allow the hover events to be triggered however the click action wont*/
      pointer-events: auto;
    }

    & + & {
      margin: 0;
    }

    &[aria-expanded='true'],
    &[aria-current='true'],
    &.active {
      background-color: ${theme.colors.sketch.F};

      &:hover {
        background-color: ${theme.colors.sketch.F};
      }

      [role='img'] {
        color: ${theme.colors.sketch.A};
        opacity: 1;
      }
    }

    ${$isHighlighted &&
    css`
      &::after {
        content: '';
        background-color: ${theme.colors.sketch.A};
        position: absolute;
        top: 4px;
        right: 7px;
        border-radius: 50%;
        height: 8px;
        width: 8px;
        border: 1px solid ${theme.colors.background.secondary.B};
      }
    `}
  `
)

export const MenuIconButton = React.forwardRef(function MenuIconButton(
  { active, description, className, ...otherProps }: MenuIconButtonProps,
  externalRef
) {
  if (description) {
    return (
      <Tooltip content={description} placement="bottom" disableWhenTouchDevice>
        {({ ref: tooltipRef, ...tooltipProps }) => (
          <IconButton
            className={classNames(className, active && 'active')}
            ref={mergeRefs([externalRef, tooltipRef])}
            {...otherProps}
            {...tooltipProps}
          />
        )}
      </Tooltip>
    )
  }

  return (
    <IconButton
      ref={externalRef}
      className={classNames(className, active && 'active')}
      {...otherProps}
    />
  )
})
