import { ApolloLink } from 'apollo-link'
import { getMainDefinition } from 'apollo-utilities'

export interface OperationTimestamps {
  [key: string]: number | undefined
}

interface CreateOperationsTimestampLinkProps {
  /**
   * Gathers a dictionary of `[operations names, timestamp of first operation response]` values.
   *
   * Timestamps are calculated from the beginning of the app load.
   *
   * If passed, this object will be mutated
   */
  timestampsRef?: OperationTimestamps
}

export const createOperationsTimestampLink = ({
  timestampsRef = {},
}: CreateOperationsTimestampLinkProps = {}) => {
  const apolloLink = new ApolloLink((operation, forward) => {
    return forward(operation).map(result => {
      const { query } = operation
      const mainDefinition = getMainDefinition(query)
      const definitionName = mainDefinition.name?.value ?? 'unknown'

      if (timestampsRef[definitionName] === undefined) {
        timestampsRef[definitionName] =
          window?.performance?.now() || Number.POSITIVE_INFINITY
      }

      return result
    })
  })

  return { apolloLink, timestampsRef }
}

export const { apolloLink: operationsTimestampLink, timestampsRef } =
  createOperationsTimestampLink()
