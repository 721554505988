import React, { useCallback } from 'react'
import { Formik, FormikHelpers } from 'formik'
import * as Yup from 'yup'

import { useToast } from '@sketch/toasts'
import { Form, Button, Input, Panel } from '@sketch/components'

import HelpNote from './HelpNote'

import {
  Title,
  FormContainer,
  Note,
  StyledFakeLinkButton,
  Description,
  Paragraph,
} from './RecoveryCode.styles'

import {
  ValidateMfaRecoveryCodeMutation,
  useValidateMfaRecoveryCodeMutation,
} from '@sketch/gql-types'

interface RecoveryCodeProps {
  onCompleted?: ((data: ValidateMfaRecoveryCodeMutation) => void) | undefined
  mfaToken: string
  toggleInterface: () => void
  hideHeader?: boolean
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
}

interface MFAError {
  code: string
  message: string
  type: 'wrong_mfa_verification_code' | 'invalid_mfa_token'
}

type Values = typeof INITIAL_VALUES

const INITIAL_VALUES = {
  recoveryCode: '',
}

const VALIDATION_SCHEMA = Yup.object().shape({
  recoveryCode: Yup.string().trim().required('Enter a recovery code'),
})

/**
 * Renders the UI that allows the user to enter the recovery code
 */
export const RecoveryCode: React.FC<RecoveryCodeProps> = ({
  onCompleted,
  mfaToken,
  toggleInterface,
  hideHeader,
  onChange,
}) => {
  const { showToast } = useToast()

  // Recovery Code Validation
  const [validateRecoveryCode, { loading }] =
    useValidateMfaRecoveryCodeMutation({
      onCompleted,
      onError: error => {
        // TODO: Improve returned error types from local resolvers
        // https://github.com/sketch-hq/Cloud/issues/11366
        const mfaError = error.message as unknown as MFAError

        showToast(mfaError.message, 'negative')
      },
    })

  const handleSubmitRecoveryCode = useCallback(
    (values: Values, actions: FormikHelpers<Values>) => {
      validateRecoveryCode({
        variables: {
          token: mfaToken,
          recoveryCode: values.recoveryCode,
        },
      })

      actions.setSubmitting(false)
    },
    [mfaToken, validateRecoveryCode]
  )

  if (hideHeader) {
    return (
      <>
        <Formik
          initialValues={INITIAL_VALUES}
          // blank onSubmit because we want the form to be controlled
          onSubmit={() => {}}
          validationSchema={VALIDATION_SCHEMA}
        >
          {({ values, handleChange, errors, touched }) => (
            <Form>
              <Form.Field
                name="text"
                label="Recovery Code"
                errorText={
                  touched.recoveryCode ? errors.recoveryCode : undefined
                }
              >
                <Input
                  name="recoveryCode"
                  type="text"
                  value={values.recoveryCode}
                  onChange={e => {
                    onChange?.(e)
                    handleChange(e)
                  }}
                />
              </Form.Field>
            </Form>
          )}
        </Formik>
        <Note>
          <Paragraph>
            Authenticator app working again?{' '}
            <StyledFakeLinkButton onClick={toggleInterface} isUnderlined>
              Enter verification code
            </StyledFakeLinkButton>
            <br />
            <HelpNote />
          </Paragraph>
        </Note>
      </>
    )
  }

  return (
    <>
      <Title>Enter Recovery Code</Title>
      <Panel.Body>
        <Description>
          Can’t access your authenticator app? Enter a recovery code instead.
        </Description>
        <FormContainer>
          <>
            <Formik
              initialValues={INITIAL_VALUES}
              onSubmit={handleSubmitRecoveryCode}
              validationSchema={VALIDATION_SCHEMA}
            >
              {({ values, handleChange, errors, touched, isSubmitting }) => (
                <Form>
                  <Form.Field
                    name="text"
                    errorText={
                      touched.recoveryCode ? errors.recoveryCode : undefined
                    }
                  >
                    <Input
                      name="recoveryCode"
                      placeholder="Enter recovery code"
                      type="text"
                      value={values.recoveryCode}
                      onChange={handleChange}
                    />
                  </Form.Field>
                  <Button
                    type="submit"
                    variant="primary-untinted"
                    loading={isSubmitting || loading}
                    fill
                    size="40"
                  >
                    Submit
                  </Button>
                </Form>
              )}
            </Formik>
            <Note>
              <Paragraph>
                Authenticator app working again?{' '}
                <StyledFakeLinkButton
                  variant="tertiary"
                  onClick={toggleInterface}
                  isUnderlined
                >
                  Enter verification code
                </StyledFakeLinkButton>
                <br />
                <HelpNote />
              </Paragraph>
            </Note>
          </>
        </FormContainer>
      </Panel.Body>
    </>
  )
}

export default RecoveryCode
