import {
  DiscoverSectionFragment,
  DiscoverItemFragment,
  PermissionsFragment,
  LinkFragment,
  SizeFragment,
} from '@sketch/gql-types'

export const defaultPermissionsValues: Partial<PermissionsFragment> = {
  __typename: 'Permissions',
  audience: null,
  categories: null,
  roles: null,
}

export const defaultLinkValues: Partial<LinkFragment> = {
  __typename: 'Link',
  icon: null,
  text: null,
  variant: null,
}

export const defaultSizeValues: Partial<SizeFragment> = {
  __typename: 'Size',
  lg: null,
  md: null,
  sm: null,
  xl: null,
  xs: null,
}

export const defaultSectionSubtitleValues: Partial<
  DiscoverSectionFragment['subTitle']
> = {
  __typename: 'SectionSubTitle',
  visibility: {
    __typename: 'Visibility',
    hiddenForFF: null,
    hideOnBreakpoint: null,
  },
}

export const defaultItemMobileValues: Partial<DiscoverItemFragment['mobile']> =
  {
    __typename: 'ItemMobile',
    description: null,
    icon: null,
  }

export const defaultResponsiveImageValues: Partial<
  DiscoverItemFragment['image']
> = {
  __typename: 'ResponsiveImage',
  alt: null,
  source: {
    __typename: 'ResponsiveSource',
    xs: null,
    sm: null,
    md: null,
    lg: null,
    xl: null,
  },
}

export const defaultSingleImageValues: Partial<DiscoverItemFragment['image']> =
  {
    __typename: 'SingleImage',
    alt: null,
  }

export const defaultVideoValues: Partial<DiscoverItemFragment['video']> = {
  __typename: 'Video',
  standard: {
    __typename: 'VideoSource',
    backgroundColor: null,
    src: null,
    poster: null,
  },
  retina: {
    __typename: 'VideoSource',
    backgroundColor: null,
    src: null,
    poster: null,
  },
}
