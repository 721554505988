import React from 'react'
import {
  StyledDefaultSelectorButton,
  StyledStrongSelectorButton,
  StyledHeaderSelectorButton,
  SelectorButtonChevronsUpDown,
  SelectorButtonChevronHorizontal,
  SelectorButtonChevronsUpDownIconNegative,
  SelectorButtonChevronVertical,
} from './SelectorButton.styles'
import { SelectorButtonProps } from './types'

const components = {
  default: StyledDefaultSelectorButton,
  strong: StyledStrongSelectorButton,
  header: StyledHeaderSelectorButton,
} as const

const icons = {
  chevrons: SelectorButtonChevronsUpDown,
  bottomArrow: SelectorButtonChevronVertical,
  backArrow: SelectorButtonChevronHorizontal,
  negative: SelectorButtonChevronsUpDownIconNegative,
  default: () => null,
} as const

/**
 * SelectorButton maps to the Primitive/Actions/Selector component in Prism Web.
 *
 * It's designed to be a multi-purpose action - sometimes acting as a Dropdown
 * toggle button where needed, and at others another type of basic button.
 *
 * Variants
 *
 *   Strong (bordered)
 *   Header (strong uppercase label)
 *
 * Icons
 *
 *   Icon display is kept as an implementation detail to increase component
 *   encapuslation, at the expense of flexibility at the call site. Icons can be
 *   toggled on and off via the boolean props `chevrons` and `backArrow`, with
 *   more added as needed.
 *
 * Note: This component does not currently implement all possible variations
 * seen in Prism Web, but we can add to it over time.
 */
export const SelectorButton = React.forwardRef<
  HTMLButtonElement,
  SelectorButtonProps
>(function SelectorButton({ label, variant = 'default', ...rest }, ref) {
  const Comp = components[variant]
  const Icon =
    icons[
      rest.chevronVertical
        ? 'bottomArrow'
        : rest.backArrow
          ? 'backArrow'
          : rest.chevrons
            ? rest.$colorRed
              ? 'negative'
              : 'chevrons'
            : 'default'
    ]

  return (
    <Comp type="button" {...rest} ref={ref}>
      {label}
      <Icon disabled={rest.disabled} />
    </Comp>
  )
})
