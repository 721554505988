import React, { ReactNode } from 'react'

import {
  Dropdown,
  SelectDropdownLinkItem,
  SelectDropdownActionDropdown,
  useModalContext,
  ToastLink,
} from '@sketch/components'

import {
  routes,
  DroppableLinkItem,
  useGetMyDraftsProject,
  useFlag,
} from '@sketch/modules-common'

// Menu items components
import { DesignSystemsNotEnabled } from '../../../designSystems/components/DesignSystemsSidebar/DesignSystemsNotEnabled'
import { PublicProfileSidebarItem } from '../../../community/components/PublicProfileSidebarItem'

import { icons } from './icons'

import { WorkspaceMinimalFragment } from '@sketch/gql-types'
import { MoveShareToMyDrafts } from '../../../projects/modals/MoveShareToMyDrafts'
import { useToast } from '@sketch/toasts'
import { ProjectsMenu } from '../../../projects/components/ProjectsMenu'

export interface MenuItem {
  item: ReactNode
  hidden?: boolean
  key: string
  isActive?: boolean
}

export const menuItemsText = {
  account: 'Account',
  updates: 'Updates',
  settings: 'People & Settings',
  people: 'People',
  allDocuments: 'All Documents',
  sharedWithMe: 'Shared with Me',
  libraries: 'Libraries',
  designSystems: 'Design Systems',
  templates: 'Templates',
  trash: 'Trash',
  drafts: 'My Drafts',
  help: 'Help with Sketch',
  discover: 'Discover',
  archive: 'Archive',
}

type WorkspaceMenuItemsProps = {
  workspace: WorkspaceMinimalFragment
  canUseDesignSystemManager: boolean
  hasDraftsProject: boolean
  canUserAdministerShares: boolean
  handleOnEmptyTrash: () => void
  deleteShare: ({ identifier }: { identifier: string }) => void
  isProfileEnabled: boolean
  isArchiveFeatureEnabled: boolean
  isCurrentProjectArchived: boolean
}

interface MyDraftsMenuItemProps {
  workspace: WorkspaceMinimalFragment
}

const MyDraftsMenuItem: React.FC<MyDraftsMenuItemProps> = ({ workspace }) => {
  const { showModal } = useModalContext()
  const { showToast } = useToast()

  const draftsProject = useGetMyDraftsProject(workspace.identifier)

  const isNestedProjectsOn = useFlag('nested-projects')

  return isNestedProjectsOn ? (
    <ProjectsMenu workspace={workspace} showDraftsProjects />
  ) : (
    <DroppableLinkItem
      icon={icons.MyDraftsProject}
      text={menuItemsText.drafts}
      to={routes.WORKSPACE_DRAFTS.create({ workspaceId: workspace.identifier })}
      onDropped={({ share }) => {
        const isInMyDraftsProject =
          share?.project?.identifier === draftsProject?.identifier

        if (!share || !draftsProject) {
          return
        }

        if (isInMyDraftsProject) {
          showToast(
            <>
              Document is already in &ldquo;
              <ToastLink
                to={routes.WORKSPACE_PROJECT.create({
                  projectId: draftsProject.identifier,
                  workspaceId: workspace.identifier,
                })}
              >
                My Drafts
              </ToastLink>
              &rdquo;
            </>
          )

          return
        }

        showModal(MoveShareToMyDrafts, {
          workspaceIdentifier: workspace.identifier,
          share,
          draftsProject,
        })
      }}
    />
  )
}

export const getWorkspaceMenuItems = ({
  workspace,
  canUseDesignSystemManager,
  hasDraftsProject,
  canUserAdministerShares,
  handleOnEmptyTrash,
  deleteShare,
  isProfileEnabled,
  isArchiveFeatureEnabled,
  isCurrentProjectArchived,
}: WorkspaceMenuItemsProps): MenuItem[] => {
  const isFinance = workspace.userRole === 'FINANCE'
  const isAdmin = workspace.userRole === 'ADMIN'
  const workspaceId = workspace.identifier
  const getIsActive = (route: string) => window.location.pathname === route

  return [
    {
      item: (
        <SelectDropdownLinkItem
          icon={icons.AllDocuments}
          text={menuItemsText.allDocuments}
          to={routes.WORKSPACE_SHARES.create({ workspaceId })}
        />
      ),
      hidden: isFinance,
      key: 'all-documents',
      isActive: getIsActive(routes.WORKSPACE_SHARES.create({ workspaceId })),
    },
    {
      item: <MyDraftsMenuItem workspace={workspace} />,
      hidden: isFinance || !hasDraftsProject,
      key: 'drafts',
      isActive: getIsActive(routes.WORKSPACE_DRAFTS.create({ workspaceId })),
    },
    {
      item: (
        <SelectDropdownLinkItem
          icon={icons.SharedWithMe}
          text={menuItemsText.sharedWithMe}
          to={routes.WORKSPACE_SHARED_WITH_ME.create({ workspaceId })}
        />
      ),
      hidden: isFinance,
      key: 'shared-with-me',
      isActive: getIsActive(
        routes.WORKSPACE_SHARED_WITH_ME.create({ workspaceId })
      ),
    },
    {
      item: (
        <PublicProfileSidebarItem workspaceId={workspaceId} isAdmin={isAdmin} />
      ),
      hidden: !isProfileEnabled || isFinance,
      key: 'publicProfile',
      isActive: getIsActive(
        routes.WORKSPACE_PROFILE_EDIT.create({ workspaceId })
      ),
    },
    {
      item: (
        <SelectDropdownLinkItem
          icon={icons.LinkLibrary}
          text={menuItemsText.libraries}
          to={routes.WORKSPACE_LIBRARIES.create({ workspaceId })}
        />
      ),
      hidden: isFinance,
      key: 'libraries',
      isActive: getIsActive(routes.WORKSPACE_LIBRARIES.create({ workspaceId })),
    },
    {
      item: <DesignSystemsNotEnabled />,
      hidden: canUseDesignSystemManager,
      key: 'designSystems',
    },
    {
      item: (
        <SelectDropdownLinkItem
          icon={icons.TemplateIcon}
          text={menuItemsText.templates}
          to={routes.WORKSPACE_TEMPLATES.create({ workspaceId })}
        />
      ),
      hidden: isFinance,
      key: 'templates',
      isActive: getIsActive(routes.WORKSPACE_TEMPLATES.create({ workspaceId })),
    },
    {
      item: (
        <SelectDropdownLinkItem
          icon={icons.Archive}
          text={menuItemsText.archive}
          to={routes.WORKSPACE_ARCHIVE.create({ workspaceId })}
          data-testid="sidebar-archived"
          isActive={match => !!match || isCurrentProjectArchived}
        />
      ),
      hidden: isFinance || !isArchiveFeatureEnabled,
      key: 'archive',
      isActive:
        getIsActive(routes.WORKSPACE_ARCHIVE.create({ workspaceId })) ||
        isCurrentProjectArchived,
    },
    {
      item: (
        <DroppableLinkItem
          icon={icons.Trash}
          text={menuItemsText.trash}
          to={routes.WORKSPACE_TRASH.create({ workspaceId })}
          action={
            isAdmin ? (
              <SelectDropdownActionDropdown
                text="Trash Options"
                content={
                  <>
                    <Dropdown.Header>Trash</Dropdown.Header>
                    <Dropdown.Item
                      intent="negative"
                      onClick={e => {
                        e.preventDefault()
                        handleOnEmptyTrash()
                      }}
                    >
                      Empty&hellip;
                    </Dropdown.Item>
                  </>
                }
              />
            ) : null
          }
          onDropped={({ shareId }) => deleteShare({ identifier: shareId })}
        />
      ),
      hidden: isFinance || !canUserAdministerShares,
      key: 'trash',
      isActive: getIsActive(routes.WORKSPACE_TRASH.create({ workspaceId })),
    },
  ]
}
