import React from 'react'
import styled from 'styled-components'

import { dataIdFromObject } from '@sketch/graphql-cache'
import {
  ArtboardDetailInfoFragment,
  FrameGroupDetailsFragment,
  GetFramesForPageDocument,
  useQuery,
  GetArtboardsForPageDocument,
  FrameDetailInfoFragment,
} from '@sketch/gql-types'

import {
  Dropdown,
  ButtonUnstyled,
  InfiniteList,
  handleFetchMore,
  useResponsiveDropdown,
} from '@sketch/components'
import { useFlag } from '@sketch/modules-common'

import { useVersioning } from '../../../versioning'

import NavigationItem from './NavigationItem'

import { ChevronsIcon, DropdownItem } from './NavigationItem.styles'

const DROPDOWN_STYLE = {
  maxHeight: '80vh',
  overflow: 'auto',
} as const

interface FramesNavigationItemProps {
  shareIdentifier: string
  frameGroup: FrameGroupDetailsFragment
}

const FrameSelectorButton = styled(ButtonUnstyled)`
  min-width: 0px;
  max-width: 100%;
`

const FramesDropdown = ({
  frameGroup,
  shareIdentifier,
}: FramesNavigationItemProps) => {
  const isFramesWebOn = useFlag('frames-web')
  const pageUUID = frameGroup?.page?.uuid ?? null

  const { versionShortId, getPathname } = useVersioning()

  const { data, fetchMore, loading } = useQuery(
    isFramesWebOn ? GetFramesForPageDocument : GetArtboardsForPageDocument,
    {
      variables: {
        identifier: shareIdentifier,
        versionShortId,
        pageUUID,
      },
    }
  )

  // get frames for current page
  const frames: (ArtboardDetailInfoFragment | FrameDetailInfoFragment)[] =
    data?.share?.version?.document?.page?.[
      isFramesWebOn ? 'frames' : 'artboards'
    ]?.entries ?? []

  const after =
    data?.share?.version?.document?.page?.[
      isFramesWebOn ? 'frames' : 'artboards'
    ]?.meta?.after ?? null

  const onLoadMore = handleFetchMore(
    fetchMore,
    [
      'share',
      'version',
      'document',
      'page',
      isFramesWebOn ? 'frames' : 'artboards',
      'entries',
    ],
    {
      dataIdFromObject,
      after,
    }
  )

  const getFrameLink = (
    frame: ArtboardDetailInfoFragment | FrameDetailInfoFragment
  ) =>
    !isFramesWebOn && frame.__typename === 'Artboard'
      ? getPathname({
          routeKey: 'ARTBOARD_DETAIL',
          routeParams: {
            shareID: shareIdentifier,
            permanentArtboardShortId: frame.permanentArtboardShortId!,
          },
        })
      : getPathname({
          routeKey: 'FRAME',
          routeParams: {
            shareID: shareIdentifier,
            frameUUID: frame.uuid!,
          },
        })

  if (loading) {
    return (
      <>
        <Dropdown.Header>
          {isFramesWebOn ? 'Frames' : 'Artboards'}
        </Dropdown.Header>
        <Dropdown.Skeleton />
      </>
    )
  }

  return (
    <InfiniteList onLoadMore={onLoadMore}>
      <Dropdown.Header>
        {isFramesWebOn ? 'Frames' : 'Artboards'}
      </Dropdown.Header>
      {frames.map(frame => (
        <DropdownItem
          key={frame.identifier}
          isActive={match =>
            !!match || frame.identifier === frameGroup?.identifier
          }
          to={getFrameLink(frame)}
        >
          {frame.name}
        </DropdownItem>
      ))}
    </InfiniteList>
  )
}

export const FramesNavigationItem = (props: FramesNavigationItemProps) => {
  const [content, buttonProps] = useResponsiveDropdown({
    dropdown: FramesDropdown,
    dropdownProps: props,
    dropdownStyle: DROPDOWN_STYLE,
  })

  const frameCount = props.frameGroup?.page?.artboardCount || 0

  const isFramesWebOn = useFlag('frames-web')
  const navigationItemIcon = isFramesWebOn ? 'frame' : 'artboard'

  if (frameCount === 1) {
    return (
      <NavigationItem
        icon={navigationItemIcon}
        label={props.frameGroup.name || ''}
      />
    )
  }

  return (
    <>
      <FrameSelectorButton data-testid="artboard-selector" {...buttonProps}>
        <NavigationItem
          icon={navigationItemIcon}
          label={props.frameGroup.name || ''}
        />
        {frameCount > 1 && <ChevronsIcon />}
      </FrameSelectorButton>
      {content}
    </>
  )
}
