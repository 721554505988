import { FrameGroupDetailsFragment } from '@sketch/gql-types'

export const getFrameThumbnail = (
  frame?: FrameGroupDetailsFragment,
  type: 'L' | 'M' | 'S' | 'XL' = 'L'
) => {
  const file = frame?.files[0]

  const thumbnails = file?.thumbnails ?? []
  const thumbnail = thumbnails.find(t => t?.type === type)

  return (thumbnail || file)?.url ?? ''
}
