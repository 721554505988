import React from 'react'

import {
  StyledName,
  StyledSubtitleTextWrapper,
  GridWrapperLeft,
  GridWrapperRight,
  GridCollectionItemOption,
} from '../CollectionItem/CollectionItem.styles'

import { CollectionPreviewsFragment } from '@sketch/gql-types'

interface CollectionItemGridViewEmptyStateProps {
  dropdownContent: React.ReactNode
  collection: CollectionPreviewsFragment
}

const CollectionItemGridViewEmptyState: React.FC<
  CollectionItemGridViewEmptyStateProps
> = ({ dropdownContent, collection }) => {
  return (
    <>
      <GridWrapperLeft />
      <GridWrapperRight>
        <StyledName>{collection.name}</StyledName>
        <StyledSubtitleTextWrapper>
          <b className="shrink">No Documents</b>
        </StyledSubtitleTextWrapper>
      </GridWrapperRight>
      {dropdownContent && (
        <GridCollectionItemOption aria-label="Documentasd Options">
          {dropdownContent}
        </GridCollectionItemOption>
      )}
    </>
  )
}

export default CollectionItemGridViewEmptyState
