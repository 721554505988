import styled from 'styled-components'
import { Dropdown } from '@sketch/components'

export const Container = styled.div`
  display: flex;
  align-items: center;
`

export const BreadcrumbsTrigger = styled.button`
  margin: 0;
  padding: 0;
  cursor: pointer;
  border: none;
  background: none;

  display: flex;
  align-items: center;

  transition: color ${({ theme }) => theme.transitions.duration[0]};
  color: ${({ theme }) => theme.colors.foreground.secondary.D};

  :hover {
    color: ${({ theme }) => theme.colors.foreground.secondary.A};
  }
`

export const Ellipsis = styled.div.attrs({ children: '…' })`
  width: 16px;
  height: 16px;
`

export const IconContainer = styled.div`
  width: 16px;
  height: 16px;
  margin-right: 8px;
  flex-shrink: 0;
`

export const DropdownItem = styled(Dropdown.Item)<{ depth?: number }>`
  display: flex;

  padding-left: ${({ depth }) => (depth || 0) * 16 + 16}px;
  align-items: center;
`

export const TooltipContent = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 4px 0;
  margin: 0;
`

export const TooltipItem = styled.li<{ depth?: number }>`
  display: flex;

  padding-left: ${({ depth }) => (depth || 0) * 8 + 8}px;
  align-items: center;
`
