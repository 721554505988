import { useCallback, useMemo } from 'react'
import { PRMarinaRect, usePRFile, usePan } from '@sketch-hq/sketch-web-renderer'

import { usePerformanceOptimizedZoom } from './usePerformanceOptimizedZoom'

/**
 * Take as input a PRMarinaRect (with units relative to the scene) and return a corresponding rect
 * relative to the canvas HTML element based on the current zoom and pan values.
 * This is useful for positioning overlays on top of the canvas.
 */
export function useMapPRMarinaRectToCanvasRelativeRect(
  bounds: PRMarinaRect | null
) {
  const mapPointToCanvasRelativePosition =
    useGetMapPointToCanvasRelativePosition()
  const mapSizeToCanvasRelativeSize = useGetMapSizeToCanvasRelativeSize()

  return useMemo(() => {
    if (!bounds) {
      return
    }

    const width = mapSizeToCanvasRelativeSize(bounds.width)
    const height = mapSizeToCanvasRelativeSize(bounds.height)
    const position = mapPointToCanvasRelativePosition(bounds)

    if (position === null || height === null || width === null) {
      return null
    }

    return { ...position, width, height }
  }, [bounds, mapPointToCanvasRelativePosition, mapSizeToCanvasRelativeSize])
}

function useRootElementExtent() {
  const prFile = usePRFile()

  return useMemo<PRMarinaRect | null>(
    () => prFile?.getRootNode()?.getAbsoluteExtent() ?? null,
    [prFile]
  )
}

/**
 * Returns a function that take as input some coordinates (with units relative to the scene)
 * and return corresponding coordinates relative to the canvas HTML element based on the current zoom and pan values.
 * This is useful for positioning overlays on top of the canvas.
 */
export function useGetMapPointToCanvasRelativePosition() {
  const zoom = usePerformanceOptimizedZoom()
  const pan = usePan() ?? { x: 0, y: 0 }

  const rootElementExtent = useRootElementExtent()

  return useCallback(
    (point: { x: number; y: number }) => {
      if (!rootElementExtent) {
        return null
      }

      const x = (pan.x + point.x - rootElementExtent.x) * zoom
      const y = (pan.y + point.y - rootElementExtent.y) * zoom

      return { x, y }
    },
    [pan.x, pan.y, rootElementExtent, zoom]
  )
}

/**
 * Returns a function that take as input a size/distance (with unit relative to the scene)
 * and return a corresponding size relative to the canvas HTML element based on the current zoom and pan values.
 * This is useful for positioning overlays on top of the canvas.
 */
export function useGetMapSizeToCanvasRelativeSize() {
  const zoom = usePerformanceOptimizedZoom()

  const rootElementExtent = useRootElementExtent()

  return useCallback(
    (size: number) => {
      if (!rootElementExtent) {
        return null
      }

      return size * zoom
    },
    [rootElementExtent, zoom]
  )
}
