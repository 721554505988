import { DataProxy } from 'apollo-cache'
import produce from 'immer'

import {
  GetUserSettingsDocument,
  GetUserSettingsQuery,
  GetUserSettingsQueryVariables,
  GetWorkspaceMembershipsQuery,
} from '@sketch/gql-types'
import { removeFromPaginated } from '@sketch/modules-common'

export const removeFromUserMemberships = (
  cache: DataProxy,
  memberId: string
) => {
  try {
    const currentState = cache.readQuery<
      GetUserSettingsQuery,
      GetUserSettingsQueryVariables
    >({
      query: GetUserSettingsDocument,
    })

    if (!currentState) {
      return
    }

    cache.writeQuery<GetUserSettingsQuery, GetUserSettingsQueryVariables>({
      query: GetUserSettingsDocument,

      data: produce(currentState, draftState => {
        if (draftState.me.workspaceMemberships) {
          draftState.me.workspaceMemberships!.entries =
            draftState.me.workspaceMemberships.entries.filter(
              x => x.identifier !== memberId
            )
        }
      }),
    })
  } catch (err) {
    // It is possible that query consisting "me.organizationMemberships" wasn't loaded,
    // therefore - there is nothing to
  }
}

type Workspace = Pick<
  GetWorkspaceMembershipsQuery['workspace'],
  'identifier' | '__typename'
>
type WorkspaceMembership = Pick<
  NonNullable<
    NonNullable<
      GetWorkspaceMembershipsQuery['workspace']['memberships']['list']
    >['entries']
  >[0],
  'identifier' | '__typename'
>

export const removeUserFromWorkspace = (
  cache: DataProxy,
  memberId: string,
  workspaceId: string
) => {
  removeFromPaginated<WorkspaceMembership, Workspace>(
    cache,
    {
      identifier: memberId,
      __typename: 'WorkspaceMembership',
    },
    {
      identifier: workspaceId,
      __typename: 'Workspace',
    }
  )
}
