import React, { FC } from 'react'
import { RouteComponentProps, matchPath, withRouter } from 'react-router-dom'
import { LoadingPage } from '../LoadingPage'
import { IS_EMBEDDED } from '@sketch/constants'
import {
  versionedRouteKeys,
  VersionedRouteKeys,
  getRouteTemplate,
  getAllTemplatesForVersionedRoute,
  RouteKeys,
} from '../../routes'
import { DynamicLoadingPropsByKey } from './DynamicLoadingPage.types'

export type RouteBasedLoadingPageProps = RouteComponentProps &
  DynamicLoadingPropsByKey<any>

const isVersionedRouteKey = (
  routeKey: RouteKeys
): routeKey is VersionedRouteKeys => {
  return versionedRouteKeys.includes(routeKey as VersionedRouteKeys)
}

const isMatching = (
  pathname: string,
  routeKey: RouteKeys,
  exact = true
): boolean => {
  const routePaths = isVersionedRouteKey(routeKey)
    ? getAllTemplatesForVersionedRoute(routeKey)
    : [getRouteTemplate(routeKey)]

  const match = matchPath(pathname, {
    path: routePaths,
    exact,
  })

  return !!match
}

// TODO: Split LoadingPage and DynamicLoadingPage https://github.com/sketch-hq/Cloud/issues/16626
const RouteBasedLoadingPage: FC<RouteBasedLoadingPageProps> = props => {
  const { location } = props

  // We want to maintain full control over what is rendered while embedded
  // therefore return null early here, rather than attempting to return
  // a dynamic loading state
  if (IS_EMBEDDED) return null

  const match = (routeKey: RouteKeys, exact = true) =>
    isMatching(location.pathname, routeKey, exact)

  switch (true) {
    case match('ARTBOARD_DETAIL'):
    case match('ARTBOARD_DETAIL_UUID'):
    case match('FRAME'):
    case match('ANNOTATION_REDIRECT'):
    case match('SHARE_VIEW'):
    case match('SHARE_PAGE_CANVAS_VIEW'):
      return <LoadingPage hideFooter />
    case match('SSO_LINK_ACCOUNT'):
    case match('SSO_TOS'):
      return <LoadingPage.Sso />
    case match('WORKSPACE_SUBSCRIBE'):
    case match('WORKSPACE_CREATE'):
    case match('WORKSPACE_CREATE_DONE'):
    case match('WORKSPACE_INVITE'):
      return <LoadingPage.WorkspaceSubscribe />
    case match('SIGN_UP'):
      return <LoadingPage.IndexLayout />
    case match('SIGN_IN'):
    case match('FORGOT_PASSWORD'):
    case match('RESET_PASSWORD'):
      return <LoadingPage.IndexLayout />
    case match('UPDATES'):
    case match('PERSONAL_SETTINGS'):
    case match('PERSONAL_SETTINGS_ACCESS_TOKENS', false):
    case match('WORKSPACE', false):
      return (
        <LoadingPage.WorkspaceSidebar
          {...(props as DynamicLoadingPropsByKey<'WORKSPACE'>)}
        />
      )
    default:
      return <LoadingPage />
  }
}

export default withRouter(RouteBasedLoadingPage)
