import React from 'react'

import { Footer } from '../Footer'

import { Wrapper, Main, FooterWrapper, Content } from './IndexLayout.styles'

import { IndexLayoutHeader, IndexLayoutHeaderProps } from './IndexLayoutHeader'

export interface IndexLayoutProps extends IndexLayoutHeaderProps {
  className?: string
  isCreatingWorkspace?: boolean
  fullWidth?: boolean
  maxContainerWidth?: string
  showFooter?: boolean
  ignoreWorkspaceMatch?: boolean
}

export const IndexLayout: React.FC<IndexLayoutProps> = ({
  className,
  children,
  headerLink,
  disableLogoLink,
  isCreatingWorkspace,
  fullWidth,
  maxContainerWidth,
  showFooter = true,
  ignoreWorkspaceMatch,
}) => {
  return (
    <Wrapper className={className}>
      <IndexLayoutHeader
        headerLink={headerLink}
        disableLogoLink={disableLogoLink}
        ignoreWorkspaceMatch={ignoreWorkspaceMatch}
      />
      <Content>
        <Main
          isCreatingWorkspace={isCreatingWorkspace}
          fullWidth={fullWidth}
          maxContainerWidth={maxContainerWidth}
        >
          {children}
        </Main>
        {showFooter && (
          <FooterWrapper>
            <Footer />
          </FooterWrapper>
        )}
      </Content>
    </Wrapper>
  )
}
