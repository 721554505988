import React, { useMemo, useState } from 'react'
import { useHistory } from 'react-router'

import { ErrorHandler } from '@sketch/tracing'
import { routes } from '@sketch/modules-common'

import {
  useForLargerThanMobile,
  Button,
  Modal,
  ModalInjectedProps,
  Text,
  pluralize,
} from '@sketch/components'

import FooterActions, { FooterActionsProps } from './components/FooterActions'

import SelectDocuments from './components/SelectDocuments'
import SendToTrash from './components/SendToTrash'
import Confirmation from './components/Confirmation'

import {
  CounterContainer,
  StyledModal,
  StyledModalBody,
  StyledOrderedBreadcrumbs,
} from './ReviewLargeDocumentsModal.styles'

import {
  useGetWorkspaceHeaviestSharesQuery,
  ShareWithSizeFragment,
  useSharesDeleteMutation,
  useGetWorkspaceQuery,
} from '@sketch/gql-types'
import { SelectedItemsProps } from './components/types'

interface ReviewLargeDocumentsModalProps extends ModalInjectedProps {
  workspaceId: string
}

const BREADCRUMBS = [
  { content: 'Select Documents' },
  { content: 'Send to Trash' },
  { content: 'Confirmation' },
]

const MOBILE_BREADCRUMBS = [
  { content: 'Select' },
  { content: 'Review' },
  { content: 'Confirm' },
]

const SelectedDocumentsCounter: React.FC<{ number: number }> = ({ number }) => (
  <CounterContainer>
    <Text textStyle="copy.quaternary.standard.D">
      {number} {pluralize('Document', 'Documents', number)} selected
    </Text>
  </CounterContainer>
)

export const ReviewLargeDocumentsModal: React.FC<
  ReviewLargeDocumentsModalProps
> = ({ hideModal, workspaceId }) => {
  const history = useHistory()
  const isForLargerThanMobile = useForLargerThanMobile()
  const [step, setStep] = useState(0)
  const [isSelectAll, setSelectAll] = useState(false)
  const [selectedItems, setSelectedItems] = useState<SelectedItemsProps[]>([])
  const [deletedItems, setDeletedItems] = useState<string[]>([])

  const { data: workspaceData } = useGetWorkspaceQuery({
    variables: { identifier: workspaceId },
  })

  const workspaceName = workspaceData?.workspace?.name

  const {
    data,
    loading: isLoadingHeaviestShares,
    error,
  } = useGetWorkspaceHeaviestSharesQuery({
    variables: {
      identifier: workspaceId,
    },
    // Keep the list of documents updated in case someone moves documents
    // from and to Trash (Trashed documents are not listed)
    fetchPolicy: 'cache-and-network',
  })

  const [sharesDelete, { loading: isLoadingDeleteShares }] =
    useSharesDeleteMutation({
      onCompleted: ({ sharesDelete }) => {
        const deletedIdentifiers = sharesDelete?.shares.map(
          shares => shares.identifier
        )

        if (deletedIdentifiers?.length) {
          setDeletedItems(prev => [...prev, ...deletedIdentifiers])
        }

        nextStep()
      },
      onError: 'show-toast',
    })

  if (error) {
    ErrorHandler.shouldNeverHappen('Error fetching workspace largest documents')
  }

  const shares = useMemo(() => data?.workspaceHeaviestShares ?? [], [data])

  const selectedSharesWithSize = useMemo(
    () =>
      shares
        .reduce((result, current) => {
          const selected = selectedItems.find(
            item => item.identifier === current.share.identifier
          )
          if (selected) {
            result.push(current)
          }
          return result
        }, [] as ShareWithSizeFragment[])
        .reverse(),
    [shares, selectedItems]
  )

  // When the "sharesDelete" mutation is successful we grab the identifiers of deleted items and filter those from the list of shares
  // this will allow the user to go back to the first step and see the updated list of shares
  const sharesWithoutDeletedItems = useMemo(
    () =>
      shares.filter(share => !deletedItems.includes(share.share.identifier)),
    [shares, deletedItems]
  )

  const goToFirstStep = () => {
    setSelectAll(false)
    setSelectedItems([])
    setStep(0)
  }

  const nextStep = () => {
    setStep(prev => prev + 1)
  }
  const previousStep = () => {
    setStep(prev => prev - 1)
  }

  const handleSendToTrash: FooterActionsProps['onSendToTrash'] = ({
    shareIdentifiers,
  }) => {
    sharesDelete({
      variables: {
        identifiers: shareIdentifiers,
      },
    })
  }

  const handleViewTrash = () => {
    history.push(routes.WORKSPACE_TRASH.create({ workspaceId }))
  }

  const handleChange = (identifier: string) => {
    setSelectedItems(prev => {
      const item = prev.find(item => item.identifier === identifier)
      if (item) {
        return prev.filter(item => item.identifier !== identifier)
      }

      return [...prev, { identifier, selected: true }]
    })
  }

  const handleSelectAll = () => {
    setSelectAll(prevSelectAll => {
      setSelectedItems(prevSelectedItems => {
        if (prevSelectedItems.length === shares.length) {
          return []
        }

        return shares.map(share => ({
          identifier: share.share.identifier,
          selected: true,
        }))
      })
      return !prevSelectAll
    })
  }

  const loading = isLoadingHeaviestShares || isLoadingDeleteShares
  const totalCount = shares.length
  const numberSelectedDocuments = isSelectAll
    ? totalCount
    : selectedItems.length

  return (
    <StyledModal onCancel={hideModal}>
      <StyledModalBody>
        <StyledOrderedBreadcrumbs
          currentCrumb={step}
          crumbs={isForLargerThanMobile ? BREADCRUMBS : MOBILE_BREADCRUMBS}
          showAllInMobile
        />
        {step === 0 && (
          <SelectDocuments
            shares={sharesWithoutDeletedItems}
            selectedItems={selectedItems}
            onChange={handleChange}
            onSelectAll={handleSelectAll}
            isSelectAll={isSelectAll}
            loading={loading}
            workspaceName={workspaceName!}
          />
        )}
        {step === 1 && (
          <SendToTrash
            numberSelectedDocuments={numberSelectedDocuments}
            sharesWithSize={selectedSharesWithSize}
          />
        )}
        {step === 2 && (
          <Confirmation
            numberSelectedDocuments={numberSelectedDocuments}
            sharesWithSize={selectedSharesWithSize}
          />
        )}
      </StyledModalBody>
      <Modal.Footer
        leftContent={
          <>
            {step === 0 && (
              <SelectedDocumentsCounter number={numberSelectedDocuments} />
            )}
            {step === 2 && (
              <Button
                variant="secondary"
                onClick={hideModal}
                disabled={loading}
                loading={loading}
                size="40"
              >
                Close
              </Button>
            )}
          </>
        }
      >
        <FooterActions
          disabled={loading}
          loading={loading}
          step={step}
          onSendToTrash={handleSendToTrash}
          onViewTrash={handleViewTrash}
          goToFirstStep={goToFirstStep}
          nextStep={nextStep}
          previousStep={previousStep}
          hideModal={hideModal}
          selectedItems={selectedItems}
        />
      </Modal.Footer>
    </StyledModal>
  )
}

export default ReviewLargeDocumentsModal
