import React from 'react'
import { roundWithLocale } from '@sketch/utils'
import { AttributeList, FullCopyAttribute, MiniHeader } from '../../components'
import { GridSettings as GridSettingsType } from '../../../../../../inspector'

export const GridSettings = ({
  grid,
}: {
  grid: GridSettingsType | undefined
}) => {
  if (!grid || !grid.isEnabled) {
    return null
  }

  const { size, thickLineStep } = grid

  return (
    <>
      <MiniHeader>Grid Settings</MiniHeader>
      <AttributeList>
        {size !== undefined ? (
          <FullCopyAttribute
            label="Grid Size"
            copyValue={`${size}px`}
            value={`${roundWithLocale(size, 2)}px`}
          />
        ) : null}
        {thickLineStep !== undefined ? (
          <FullCopyAttribute
            label="Thick line"
            copyValue={`${thickLineStep}px`}
            value={`Every ${roundWithLocale(thickLineStep, 2)} blocks`}
          />
        ) : null}
      </AttributeList>
    </>
  )
}
