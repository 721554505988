import React, { useLayoutEffect, useRef, useState } from 'react'
import { Pill, Popper, ForceUpdatePopper } from '@sketch/components'

import {
  PersonIcon,
  InvitePeopleFieldWrapper,
  InvitePeopleInputWrapper,
  InvitePeopleInputCombobox,
  InvitePeopleIconWrapper,
  InvitePeopleInputValueSelected,
  InvitePeopleInputHeader,
  InvitePeopleInput,
  AutocompleteList,
  AutocompleteItem,
  AutocompleteItemAvatar,
  AutocompleteItemContent,
  AutocompleteItemText,
  PrimaryText,
  SecondaryText,
} from './InvitePeopleField.styles'

import type { InvitePeopleFieldProps } from './types'
import {
  InvitableUserFragment,
  WorkspacePermissionGroupFragment,
} from '@sketch/gql-types'
import { PermissionGroupItem } from '../PermissionGroupItem'

interface AutoCompleteUserProps
  extends Pick<
    InvitePeopleFieldProps['autocomplete']['combobox'],
    'getItemProps'
  > {
  item: InvitableUserFragment
  index: number
}

const AutoCompleteUser = ({
  item,
  index,
  getItemProps,
}: AutoCompleteUserProps) => (
  <AutocompleteItem
    key={item.identifier}
    data-testid="autocomplete-list-item"
    {...getItemProps({ item, index })}
  >
    <AutocompleteItemContent>
      <AutocompleteItemAvatar
        size={32}
        src={item.user?.avatar?.small || ''}
        name={item?.user?.name || ''}
      />
      <AutocompleteItemText>
        <PrimaryText>
          {item?.user?.name}
          {item.role === 'GUEST' && (
            <>
              {' '}
              <Pill variant="guest">Guest</Pill>
            </>
          )}
          {item.role === 'ADMIN' && (
            <>
              {' '}
              <Pill variant="admin">Admin</Pill>
            </>
          )}
        </PrimaryText>
        <SecondaryText>{item?.user?.email}</SecondaryText>
      </AutocompleteItemText>
    </AutocompleteItemContent>
  </AutocompleteItem>
)

interface AutoCompleteGroupProps
  extends Pick<
    InvitePeopleFieldProps['autocomplete']['combobox'],
    'getItemProps'
  > {
  item: WorkspacePermissionGroupFragment
  index: number
}

const AutoCompleteGroup = ({
  item,
  index,
  getItemProps,
}: AutoCompleteGroupProps) => (
  <AutocompleteItem
    key={item.identifier}
    data-testid="autocomplete-list-item"
    {...getItemProps({ item, index })}
  >
    <PermissionGroupItem permissionGroup={item} />
  </AutocompleteItem>
)

export const InvitePeopleField = ({
  containerRef,
  action,
  dynamicAction,
  placeholder: inputPlaceholder,
  autocomplete: {
    items,
    formik: { handleBlur },
    combobox: {
      getComboboxProps,
      getInputProps,
      getMenuProps,
      isOpen,
      getItemProps,
      reset: resetCombobox,
      selectedItem,
    },
    valueSelected,
    isValueSelected,
  },
  className,
  label,
  data1pIgnore,
  disabled,
}: InvitePeopleFieldProps) => {
  const [deleteSelectedValue, setDeleteSelectedValue] = useState(false)
  const [isFocused, setIsFocused] = useState(false)
  const updateRef = useRef<ForceUpdatePopper>(null)

  useLayoutEffect(() => {
    updateRef.current?.()
  }, [isOpen])

  const handleOnKeyDown = (e: React.KeyboardEvent) => {
    const isUserDeleting = e.key === 'Backspace' || e.key === 'Delete'

    // If user do anything but delete when value is selected
    // for deletion, restore the state
    if (deleteSelectedValue && !isUserDeleting) {
      setDeleteSelectedValue(false)
      return
    }

    if (isUserDeleting && isValueSelected) {
      // If user has the input ready to be deleted,
      // the next time the user presses backspace or deleted
      // it will be deleted
      if (!deleteSelectedValue) {
        e.preventDefault()
        setDeleteSelectedValue(true)
        return
      }

      // If the value is ready to be deleted and the user presses
      // again the deletion key, it will be deleted
      setDeleteSelectedValue(false)
      resetCombobox()
    }
  }

  const {
    value: inputValue,
    placeholder,
    ...inputProps
  } = getInputProps({
    onBlur: handleBlur,
    placeholder: inputPlaceholder,
    autoComplete: 'off',
    type: 'text',
    name: 'invitation',
    onKeyDown: handleOnKeyDown,
    disabled,
  })

  return (
    <InvitePeopleFieldWrapper className={className}>
      <Popper
        placement="bottom-start"
        data-testid="autocomplete-member"
        visible={true}
        usePortal={true}
        spacing="-1px"
        popup={({ rect, ...popperProps }) => {
          const menuProps = getMenuProps()
          const update = updateRef
          update.current = popperProps.forceUpdate

          // Match Popup Width with Reference Size
          const popupWidth = rect?.width || 'auto'
          popperProps.style.width = popupWidth

          return (
            <AutocompleteList
              {...popperProps}
              {...menuProps}
              ref={ref => {
                menuProps.ref(ref)
                popperProps.ref(ref)
              }}
            >
              {isOpen &&
                items.map((item, index) => {
                  if (item.__typename === 'PermissionGroup') {
                    return (
                      <AutoCompleteGroup
                        key={item.identifier}
                        index={index}
                        item={item}
                        getItemProps={getItemProps}
                      />
                    )
                  }

                  return (
                    <AutoCompleteUser
                      key={item.identifier}
                      index={index}
                      item={item}
                      getItemProps={getItemProps}
                    />
                  )
                })}
            </AutocompleteList>
          )
        }}
      >
        {label && <InvitePeopleInputHeader>{label}</InvitePeopleInputHeader>}
        <InvitePeopleInputWrapper
          focus={isFocused}
          aria-disabled={disabled}
          ref={containerRef}
        >
          <InvitePeopleIconWrapper>
            <PersonIcon />
          </InvitePeopleIconWrapper>
          {isValueSelected && (
            <InvitePeopleInputValueSelected
              aria-label="Selected user"
              deleteable={deleteSelectedValue}
            >
              {valueSelected}
            </InvitePeopleInputValueSelected>
          )}
          <InvitePeopleInputCombobox {...getComboboxProps()}>
            <InvitePeopleInput
              data-1p-ignore={data1pIgnore}
              isReadyToDeleteValue={deleteSelectedValue}
              // Note: don't use `onChange` and `values` props here (from Formik),
              // it breaks the Downshift behavior, `useAutocomplete` already
              // keeps in sync Downshift and Formik
              {...inputProps}
              value={isValueSelected ? '' : inputValue}
              placeholder={isValueSelected ? '' : placeholder}
              onFocus={() => setIsFocused(true)}
              onBlur={() => setIsFocused(false)}
            />
          </InvitePeopleInputCombobox>
          {dynamicAction ? dynamicAction({ selectedItem }) : action}
        </InvitePeopleInputWrapper>
      </Popper>
    </InvitePeopleFieldWrapper>
  )
}
