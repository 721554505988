import React from 'react'

import {
  Avatar,
  Container,
  TextContainer,
  Text,
  TextOverflow,
} from './MemberCard.styles'

import MemberBadge, { BadgeType } from '../MemberBadge/MemberBadge'
export interface MemberCardProps {
  name?: string
  email: string
  avatarSrc?: string | null
  className?: string
  badges?: BadgeType[]
}

const MemberCard: React.FC<MemberCardProps> = props => {
  const { className, badges, name, email, avatarSrc } = props
  const [primaryText, secondaryText] = name ? [name, email] : [email]

  return (
    <Container className={className}>
      <Avatar name={primaryText} src={avatarSrc || undefined} />
      <TextContainer>
        <Text>
          <TextOverflow>{primaryText}</TextOverflow>
          {badges?.map(badge => <MemberBadge key={badge} badge={badge} />)}
        </Text>
        {secondaryText && <Text>{secondaryText}</Text>}
      </TextContainer>
    </Container>
  )
}

export default MemberCard
