import React, { useState } from 'react'
import {
  Checkbox,
  ConfirmationDialog,
  ModalInjectedProps,
} from '@sketch/components'

import { useGetRootProject } from '../../operations'

export interface ConfirmRemoveMemberModalProps extends ModalInjectedProps {
  projectName: string
  projectIdentifier: string
  workspaceIdentifier: string
  onConfirm: () => Promise<void>
  onCancelAction: () => void
}

export interface RemoveProjectAccessModalProps extends ModalInjectedProps {
  projectName: string
  onConfirm: (removeShareMemberships: boolean) => void
  onCancelAction: () => void
  memberName: string
}

export const ConfirmRemoveMemberModal = ({
  hideModal,
  isModalOpen,
  projectName,
  projectIdentifier,
  workspaceIdentifier,
  onConfirm,
  onCancelAction,
}: ConfirmRemoveMemberModalProps) => {
  const [loading, setLoading] = useState(false)

  const rootProject = useGetRootProject(workspaceIdentifier, projectIdentifier)
  const rootProjectIdentifier = rootProject?.project?.identifier
  const isNestedProject =
    rootProjectIdentifier && rootProjectIdentifier !== projectIdentifier

  const onConfirmWithButtonControl = async () => {
    setLoading(true)
    try {
      await onConfirm()
    } finally {
      setLoading(false)
    }
  }

  return (
    <ConfirmationDialog
      title="Leave Project?"
      hideModal={hideModal}
      isModalOpen={isModalOpen}
      onCancelAction={onCancelAction}
      confirmButton={{
        variant: 'primary',
        text: 'Leave Project',
        disabled: loading,
      }}
      cancelButton={{ disabled: loading }}
      onConfirm={onConfirmWithButtonControl}
    >
      {isNestedProject ? (
        <p>
          Leaving “{projectName}” will also remove your access to “
          {rootProject.project.name}” and any other subfolders
        </p>
      ) : (
        <p>
          If you leave, you&lsquo;ll no longer have access to “{projectName}”
        </p>
      )}
    </ConfirmationDialog>
  )
}

export const RemoveProjectAccessModal = ({
  hideModal,
  isModalOpen,
  onCancelAction,
  onConfirm,
  memberName,
  projectName,
}: RemoveProjectAccessModalProps) => {
  const [removeShareMemberships, setRemoveShareMemberships] = useState(false)

  return (
    <ConfirmationDialog
      title="Remove Access to this Project?"
      hideModal={hideModal}
      isModalOpen={isModalOpen}
      onCancelAction={onCancelAction}
      confirmButton={{
        variant: 'primary',
        text: 'Remove Access',
      }}
      onConfirm={() => onConfirm(removeShareMemberships)}
    >
      <p>
        {memberName} was manually added to some documents in “{projectName}”.
        You can remove their access to these documents too.
      </p>

      <Checkbox
        label={`Also remove ${memberName} from all documents in this project`}
        checked={removeShareMemberships}
        onChange={() => setRemoveShareMemberships(!removeShareMemberships)}
      />
    </ConfirmationDialog>
  )
}
