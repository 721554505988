import React from 'react'

import { routes } from '@sketch/modules-common'
import { LinkButton } from '@sketch/components'

import * as S from './WorkspaceSettingsSubscribePanel.styles'

interface WorkspaceSettingsSubscribePanelProps {
  workspaceId: string
}

export const WorkspaceSettingsSubscribePanel: React.FC<
  WorkspaceSettingsSubscribePanelProps
> = ({ workspaceId }) => (
  <S.StyledLandscapeBannerCard
    title="Enjoying Sketch?"
    data-testid="upgrade-sketch-banner-card"
    description="To keep using this Workspace after the trial ends, set up a subscription."
    icon={<S.StyledLicenseDiamondIcon />}
    action={
      <LinkButton
        to={routes.WORKSPACE_SUBSCRIBE.create({
          workspaceId,
        })}
        variant="primary"
        size="40"
      >
        Set Up a Subscription
      </LinkButton>
    }
  />
)
