import React, { useMemo, createContext, useContext } from 'react'
import { useLocalStorage } from 'react-use'

export type FrameGroupContext = {
  isGridEnabled: boolean
  isLayoutEnabled: boolean
  /** Invert the isGridEnabled value  */
  toggleGrid: () => void
  /** Invert the isLayoutEnabled value  */
  toggleLayout: () => void
}

export const frameGroupContext = createContext<FrameGroupContext | undefined>(
  undefined
)

type FrameGroupContextProviderProps = {
  children: React.ReactNode
}
export function FrameGroupContextProvider({
  children,
}: FrameGroupContextProviderProps) {
  const [isGridEnabled, setIsGridEnabled] = useLocalStorage(
    'inspector_grid_enabled',
    false
  )
  const [isLayoutEnabled, setIsLayoutEnabled] = useLocalStorage(
    'inspector_layout_enabled',
    false
  )

  const contextValue = useMemo((): FrameGroupContext => {
    const isGridActive = isGridEnabled || false
    const isLayoutActive = isLayoutEnabled || false

    return {
      isGridEnabled: isGridActive,
      toggleGrid: () => {
        setIsGridEnabled(!isGridActive)
      },
      isLayoutEnabled: isLayoutActive,
      toggleLayout: () => {
        setIsLayoutEnabled(!isLayoutActive)
      },
    }
  }, [isGridEnabled, isLayoutEnabled, setIsGridEnabled, setIsLayoutEnabled])

  return (
    <frameGroupContext.Provider value={contextValue}>
      {children}
    </frameGroupContext.Provider>
  )
}

export function useFrameGroupContext() {
  const value = useContext(frameGroupContext)

  if (typeof value === 'undefined') {
    throw new Error(
      'useFrameGroupContext must be used within an FrameGroupContextProvider component'
    )
  }

  return value
}
