export interface Pagination {
  current: number | undefined
  total: number | undefined
}

export interface Style {
  borders?: Border[]
  borderOptions?: BorderOptions
  fills?: Fill[]
  appearance?: Appearance
  text?: TextStyleProps
  shadows?: Shadow[]
  innerShadows?: Shadow[]
  blur?: Blur
  isDirty?: boolean
  originalSharedStyleValues?: {
    borders?: Border[]
    borderOptions?: BorderOptions
    fills?: Fill[]
    appearance?: Appearance
    text?: TextStyleProps
    shadows?: Shadow[]
    innerShadows?: Shadow[]
    blur?: Blur
  }
}

export interface Shadow extends EnablableSection {
  color: Color
  offsetX: number
  offsetY: number
  blurRadius: number
  spread?: number
  appearance?: Appearance
  blendMode?: string
}

export enum BlurType {
  Gaussian = 'Gaussian Blur',
  Motion = 'Motion Blur',
  Zoom = 'Zoom Blur',
  Background = 'Background Blur',
}

export interface BlurPoint {
  x: number
  y: number
}

export interface Blur extends EnablableSection {
  type: 'motion' | 'zoom' | 'background' | 'gaussian'
  center?: BlurPoint
  motionAngle?: number
  radius?: number
  saturation?: number
  blendMode?: string
}

// ColorFormat and UserPreferences should come from our schema once implemented
// in graphql
export enum ColorFormat {
  HEX,
  RGB,
  HSL,
  OBJC_NSCOLOR,
  SWIFT_NSCOLOR,
  OBJC_UICOLOR,
  SWIFT_UICOLOR,
}

export interface Size {
  width: number
  height: number
}

export type Rect = Point & Size

export type RectEdge = 'minX' | 'maxX' | 'minY' | 'maxY'

export type Axis = 'x' | 'y'

export interface Preset {
  name: string
  width: number
  height: number
}

export const layerTypes = [
  'MSImmutableHotspotLayer',
  'bitmap',
  'group',
  'oval',
  'polygon',
  'rectangle',
  'shapeGroup',
  'shapePath',
  'star',
  'text',
  'triangle',
  'slice',
] as const

export type LayerType = (typeof layerTypes)[number]

export interface Point {
  x: number
  y: number
}

export interface Appearance {
  opacity: number
  blendMode: string
}

export interface GradientStops {
  color: { red: number; green: number; blue: number; alpha: number }
  position: number
}

export enum GradientType {
  Linear = 'linear',
  Radial = 'radial',
  Angular = 'angular',
}

export interface Gradient {
  stops: GradientStops[]
  from: Point
  to: Point
  type: GradientType
  appearance?: Appearance
}

export interface ColorValues {
  red: number
  green: number
  blue: number
  // Alpha parameter (0-1)
  alpha?: number
}

export interface Color extends ColorValues {
  // ID of the colour variable, `colorVariableID` is always filled, in order to check if the color is a color variable we need to check `colorVariableName`
  colorVariableID?: string
  // ID of the colour variable in its source library
  colorVariableRemoteID?: string
  // Name of the colour variable
  colorVariableName?: string
  // Name of the library the colour variable comes from (only for foreign color variable)
  colorVariableSourceLibraryName?: string
  // ID of the source library
  colorVariableSourceLibraryID?: string
}

export interface ColorVariable extends Color {
  colorVariableName: string
}

export enum FillType {
  Color = 'color',
  Gradient = 'gradient',
  Pattern = 'pattern',
}

export enum ImageFillType {
  Fit = 'fit',
  Fill = 'fill',
  Stretch = 'stretch',
  Tile = 'tile',
}

export enum BorderPosition {
  Center = 'center',
  Inside = 'inside',
  Outside = 'outside',
}

export type EnablableSection = {
  isEnabled: boolean
}

export interface Border extends EnablableSection {
  fillType: FillType
  color?: Color
  gradient?: Gradient
  position: BorderPosition
  thickness: number
  appearance: Appearance
  blendMode?: string
}

export interface BorderOptions {
  dashPattern: number[] | null
  isEnabled: boolean
  lineCapStyle: 'butt' | 'round' | 'square'
  lineJoinStyle: 'miter' | 'round' | 'bevel'
}

export interface Fill extends EnablableSection {
  type: FillType
  color?: Color
  gradient?: Gradient
  patternFillType?: ImageFillType
  patternTileScale?: number
  appearance: Appearance
  noiseIndex?: number
  noiseIntensity?: number
  blendMode?: string
}

export enum TextAlignment {
  Left = 'left',
  Right = 'right',
  Top = 'top',
  Bottom = 'bottom',
  Center = 'center',
  Justified = 'justified',
  Middle = 'middle',
}
export type HorizontalTextAlignment = Exclude<
  TextAlignment,
  TextAlignment.Top | TextAlignment.Middle | TextAlignment.Bottom
>

export type VerticalTextAlignment = Exclude<
  TextAlignment,
  | TextAlignment.Left
  | TextAlignment.Center
  | TextAlignment.Right
  | TextAlignment.Justified
>
export interface ParagraphStyle {
  spacing?: number
  minLineHeight?: number
  maxLineHeight?: number
  alignment?: HorizontalTextAlignment
}

export interface Font {
  family?: string
  weight?: string
  weightName?: string
  size?: number
  style?: string
  originalValue?: string
}

export enum TextDecoration {
  LineThrough = 'line-through',
  Underline = 'underline',
}

export enum TextTransform {
  Uppercase = 'uppercase',
  Lowercase = 'lowercase',
}

export interface TextAttributes {
  font?: Font
  color?: Color
  letterSpacing?: number
  paragraphStyle?: ParagraphStyle
  verticalAlignment?: VerticalTextAlignment
  transform?: TextTransform
  decoration?: TextDecoration
}

export interface TextPartialString {
  location: number
  length: number
  attributes: TextAttributes
}

export interface TextAttributedString {
  string: string
  attributes: TextPartialString[]
}

export interface TextProps {
  attributedString: TextAttributedString
  style?: Style
}

export interface TextStyleProps extends TextAttributes {
  verticalAlignment?:
    | TextAlignment.Top
    | TextAlignment.Middle
    | TextAlignment.Bottom
}

export interface TextStyle {
  text?: TextAttributes | null
}
