import React from 'react'
import { Tooltip } from '@sketch/components'
import { useHandleCopyValue, CopiableArea } from '@sketch/modules-common'
import {
  Attribute,
  AttributeLabel,
  AttributeValue,
  AttributeDisplay,
} from '../Attribute'
import { DirtyIconTooltip } from '../Style'
import { IS_SHOW_DIRTY_ATTRIBUTES_ENABLED } from '../../constants'

interface FullCopyAttributeProps {
  label: string
  value: string | React.ReactNode
  copyValue?: string
  display?: AttributeDisplay
  dirtyAttributes?: { originalValue: string; originalProperty: string }
  isTextStyle?: boolean
}

export function FullCopyAttribute({
  copyValue,
  display,
  label,
  value,
  dirtyAttributes,
  isTextStyle = false,
}: FullCopyAttributeProps) {
  // Overwrite value if dirty attributes are not enabled
  dirtyAttributes = IS_SHOW_DIRTY_ATTRIBUTES_ENABLED
    ? dirtyAttributes
    : undefined

  const {
    handleCopyValue,
    copyTooltipText,
    handleEnterCopiableArea,
    handleLeaveCopiableArea,
    copyTooltipVisible,
  } = useHandleCopyValue(copyValue ?? `${value}`)

  return (
    <CopiableArea
      onClick={handleCopyValue}
      onMouseEnter={handleEnterCopiableArea}
      onMouseLeave={handleLeaveCopiableArea}
    >
      <Tooltip
        placement="left"
        spacing="10px"
        visible={copyTooltipVisible}
        content={copyTooltipText}
      >
        <Attribute noCopy display={display ?? undefined}>
          <AttributeLabel>
            {/* the extra handleEnterCopiableArea is to make sure the copy tooltip is triggered after leaving the dirty icon tooltip */}
            <div
              aria-hidden
              onMouseEnter={
                dirtyAttributes ? handleEnterCopiableArea : undefined
              }
            >
              {label}
            </div>{' '}
            {dirtyAttributes && (
              <DirtyIconTooltip
                originalProperty={dirtyAttributes.originalProperty}
                originalValue={dirtyAttributes.originalValue}
                preventLabelTooltip={handleLeaveCopiableArea}
                isTextStyle={isTextStyle}
              />
            )}
          </AttributeLabel>
          <AttributeValue
            valueString={copyValue}
            label={label}
            onMouseEnter={dirtyAttributes ? handleEnterCopiableArea : undefined}
          >
            {value}
          </AttributeValue>
        </Attribute>
      </Tooltip>
    </CopiableArea>
  )
}
