import React from 'react'
import { useRouteMatch } from 'react-router-dom'
import styled, { css } from 'styled-components'
import VersionLink from '../../../../versioning/components/VersionLink'
import { truncateStyles } from '@sketch/components'
import { useGetIdentifiers } from '../../../hooks/useGetIdentifiers'
import { getGroupRoute, prepareGroupURL } from '../../utils'

type GroupNameProps = React.PropsWithChildren<{
  groups: string
}>

const GroupContainer = styled.span`
  display: flex;
  align-items: baseline;
`

const GroupNameMain = styled.span(
  ({ theme }) => css`
    font-size: ${theme.fontSizes.G};
    font-weight: ${theme.fontWeights.bold};
    white-space: nowrap;
    margin: 0;
    padding: 0;
    ${truncateStyles};
  `
)

const GroupNameMainWithSpacing = styled(GroupNameMain)(
  ({ theme }) => css`
    font-size: ${theme.fontSizes.G};
    font-weight: ${theme.fontWeights.bold};
  `
)

const GroupNameSecondary = styled(GroupNameMain)(
  ({ theme }) => css`
    font-size: ${theme.fontSizes.D};
    font-weight: ${theme.fontWeights.medium};
    color: ${theme.colors.foreground.secondary.C};
  `
)

export const GroupLink = styled(VersionLink)`
  color: inherit;
  font-weight: inherit;
`

const GroupTruncate = styled.div`
  ${truncateStyles};
  flex-shrink: 4;
  display: inline-block;
`

const In = styled(props => <div {...props}>in</div>)(
  ({ theme }) => css`
    font-size: ${theme.fontSizes.D};
    font-weight: ${theme.fontWeights.regular};
    margin: 0 4px 0 8px;
    color: ${theme.colors.foreground.secondary.D};
  `
)

export const GroupName = ({ groups }: GroupNameProps) => {
  const { shareIdentifier } = useGetIdentifiers()
  const { path } = useRouteMatch()

  if (groups === '') {
    return (
      <GroupContainer>
        <GroupNameMain> </GroupNameMain>
      </GroupContainer>
    )
  }

  const getURLSearchQuery = (groups: string[]) => {
    if (!groups.length) {
      return undefined
    }

    return prepareGroupURL(groups)
  }

  const routeKey = getGroupRoute(path)
  const groupsArray = groups.split('/')

  if (groupsArray.length === 1) {
    return (
      <GroupContainer>
        <GroupNameMain>
          <GroupLink
            to={{
              routeKey,
              routeParams: {
                shareID: shareIdentifier,
              },
              search: getURLSearchQuery([groupsArray[0]]),
            }}
          >
            {groupsArray[0]}
          </GroupLink>
        </GroupNameMain>
      </GroupContainer>
    )
  }

  return (
    <GroupContainer>
      <GroupNameMainWithSpacing>
        <GroupLink
          to={{
            routeKey,
            routeParams: {
              shareID: shareIdentifier,
            },
            search: getURLSearchQuery(groupsArray),
          }}
        >
          {groupsArray.pop()}
        </GroupLink>
      </GroupNameMainWithSpacing>

      <In />
      <GroupTruncate>
        {groupsArray.map((group, key) => (
          <GroupNameSecondary key={`${group}${key}`}>
            <GroupLink
              to={{
                routeKey,
                routeParams: {
                  shareID: shareIdentifier,
                },
                search: getURLSearchQuery(groupsArray.slice(0, key + 1)),
              }}
            >
              {key === 0 ? group : ` / ${group}`}
            </GroupLink>
          </GroupNameSecondary>
        ))}
      </GroupTruncate>
    </GroupContainer>
  )
}
