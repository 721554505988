import { isTruthy } from '@sketch/utils'
import { isNetworkRequestInFlight } from 'apollo-client/core/networkStatus'
import { useGetProjects } from '../../operations'
import { ProjectLink, BreadcrumbsData } from '../../types'
import {
  useGetWorkspaceQuery,
  useGetProjectDetailsQuery,
} from '@sketch/gql-types'
import { ApolloError } from 'apollo-client'

const getParentProjects = (
  projectsById: { [x: string]: ProjectLink },
  currentProjectId: string
) => {
  const projectIds: string[] = []

  let parentId = projectsById[currentProjectId]?.project.parentProjectIdentifier
  while (parentId) {
    projectIds.push(parentId)
    parentId = projectsById[parentId]?.project.parentProjectIdentifier
  }

  const parentProjects = projectIds
    .map(id => projectsById[id]?.project)
    .filter(isTruthy)
  return parentProjects
}

export const useProjectData = (
  workspaceId: string,
  currentProjectId: string
) => {
  // we are fetching all projects and using just a small part of it
  // because we know that query should be already cached
  // if this ever changes we should refactor this to use a new query.
  const {
    networkStatus: projectsNetworkStatus,
    error: projectsError,
    allProjects,
    projectsById,
  } = useGetProjects({ workspaceId })

  const {
    data,
    networkStatus: workspaceNetworksStatus,
    error: workspaceError,
  } = useGetWorkspaceQuery({ variables: { identifier: workspaceId } })

  const {
    data: projectData,
    networkStatus: projectNetworkStatus,
    error: projectError,
  } = useGetProjectDetailsQuery({
    variables: { projectIdentifier: currentProjectId },
  })

  const currentProject = projectData?.project
  const parentProjectId = currentProject?.parentProjectIdentifier || null

  const parentProject =
    (parentProjectId && projectsById[parentProjectId]?.project) || null

  const siblingProjects =
    allProjects?.filter(x => x.parentProjectIdentifier === parentProjectId) ||
    []

  const parentProjects = getParentProjects(projectsById, currentProjectId)
  const workspace = data?.workspace

  if (!workspace) {
    return { error: new ApolloError({ errorMessage: 'Workspace not found' }) }
  }

  const breadcrumbs: BreadcrumbsData = {
    siblingProjects,
    parentProjects,
    workspace,
    parentProject,
    currentProject: currentProject || null,
  }

  const isInFlight =
    isNetworkRequestInFlight(projectNetworkStatus) ||
    isNetworkRequestInFlight(projectsNetworkStatus) ||
    isNetworkRequestInFlight(workspaceNetworksStatus)

  return {
    isInFlight,
    error: projectError || projectsError || workspaceError,
    breadcrumbs,
    currentProject,
  }
}
