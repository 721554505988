import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { AnnotationDotFragment } from '@sketch/gql-types'

import useActiveAnnotation from '../../hooks/useActiveAnnotation'
import { Coordinates, DraftAnnotation, MouseCoordinates } from '../../types'

import {
  AnnotationOverlayContextStatus,
  AnnotationOverlayContextAvailable,
} from './AnnotationOverlayContext.types'

type GetElementAtPositionType =
  AnnotationOverlayContextAvailable['getElementAtPosition']
type AnnotationState = Pick<
  AnnotationOverlayContextAvailable,
  | 'activeAnnotation'
  | 'placeDraftAnnotation'
  | 'movingAnnotation'
  | 'annotationInFlight'
>

const UNAVAILABLE_STATE = { status: 'unavailable' } as const

const SAFEGUARDED_SHOULD_RENDER = () => true

export const Context =
  React.createContext<AnnotationOverlayContextStatus>(UNAVAILABLE_STATE)

interface AnnotationOverlayContextProps {
  disabled?: boolean
  hidden?: boolean | ((state: AnnotationState) => boolean)
  getPositionRelativeToOrigin: (
    mouseCoordinates: MouseCoordinates
  ) => Coordinates | null
  getElementAtPosition: GetElementAtPositionType
  onDraftAnnotation?: () => void
  onMovingAnnotation?: (movingAnnotation: boolean) => void
  isViewingLatestVersion: boolean
  resetKey?: string
  getDotAdditionalStyle?: (
    annotation: AnnotationDotFragment
  ) => React.CSSProperties | undefined
  shouldRenderAnnotation?: (
    annotation: AnnotationDotFragment,
    isActiveAnnotation: boolean
  ) => boolean
}

const AnnotationOverlayContext: React.FC<
  AnnotationOverlayContextProps
> = props => {
  const {
    children,
    disabled,
    hidden: controlledHidden = false,
    onDraftAnnotation,
    onMovingAnnotation,
    isViewingLatestVersion,
    getPositionRelativeToOrigin,
    getElementAtPosition,
    resetKey,
    getDotAdditionalStyle,
    shouldRenderAnnotation,
  } = props

  const [activeAnnotation, setActiveAnnotation] = useActiveAnnotation()

  // Internal State
  const previousResetKey = useRef(resetKey)
  const [placeDraftAnnotation, _setPlaceDraftAnnotation] = useState(false)
  const [movingAnnotation, setMovingAnnotation] = useState<string | null>(null)
  const [annotationInFlight, setAnnotationInFlight] = useState(false)
  const [draftAnnotation, addDraftAnnotation] =
    useState<DraftAnnotation | null>(null)

  const [draftAnnotationComment, setDraftAnnotationComment] = useState<
    string | null
  >(null)

  const setPlaceDraftAnnotation = useCallback((value: boolean) => {
    _setPlaceDraftAnnotation(value)

    // Remove the draft annotation when the add mode is closed
    if (value === false) {
      addDraftAnnotation(null)
      setDraftAnnotationComment(null)
    }
  }, [])

  /**
   * Call the "onDraftAnnotation" when the placeDraftAnnotation is active
   */
  useEffect(() => {
    if (placeDraftAnnotation) {
      onDraftAnnotation?.()
      setActiveAnnotation()
    }
  }, [onDraftAnnotation, placeDraftAnnotation, setActiveAnnotation])

  /**
   * Cache the "onMovingAnnotation" to prevent the function
   * to re-trigger the useEffect
   */
  const onMovingAnnotationRef = useRef(onMovingAnnotation)
  useEffect(() => {
    onMovingAnnotationRef.current = onMovingAnnotation
  }, [onMovingAnnotation])

  /**
   * Cache the "shouldRenderAnnotation" to prevent the function
   * to re-trigger the useEffect
   */
  const guardedShouldRenderAnnotation =
    shouldRenderAnnotation || SAFEGUARDED_SHOULD_RENDER

  /**
   * Call the "onMovingAnnotation" when an annotation is being moved
   */
  useEffect(() => {
    onMovingAnnotationRef.current?.(!!movingAnnotation)
  }, [movingAnnotation])

  const hidden =
    typeof controlledHidden === 'function'
      ? controlledHidden({
          activeAnnotation,
          annotationInFlight,
          movingAnnotation,
          placeDraftAnnotation,
        })
      : controlledHidden

  useEffect(() => {
    /**
     * If the reset key changes we are defaulting all internal
     * state to the original value. We could have used a "key"
     * to totally reset the context value to the origin, however
     * this would trigger a full re-render on the children as well
     * which is something avoidable. And would probably re-show loading
     * states
     */
    if (resetKey !== previousResetKey.current) {
      _setPlaceDraftAnnotation(false)
      setMovingAnnotation(null)
      setAnnotationInFlight(false)
      addDraftAnnotation(null)
      setDraftAnnotationComment(null)
    }

    previousResetKey.current = resetKey
  }, [resetKey])

  const state: AnnotationOverlayContextAvailable = useMemo(
    () => ({
      status: 'available' as const,
      hidden,
      placeDraftAnnotation,
      setPlaceDraftAnnotation,
      isViewingLatestVersion,
      draftAnnotation,
      draftAnnotationComment,
      setDraftAnnotationComment,
      addDraftAnnotation,
      movingAnnotation,
      setMovingAnnotation,
      setActiveAnnotation,
      setAnnotationInFlight,
      activeAnnotation,
      annotationInFlight,
      resetDraftAnnotation: () => addDraftAnnotation(null),
      getPositionRelativeToOrigin,
      getElementAtPosition,
      getDotAdditionalStyle,
      shouldRenderAnnotation: guardedShouldRenderAnnotation,
    }),
    [
      placeDraftAnnotation,
      draftAnnotation,
      addDraftAnnotation,
      setPlaceDraftAnnotation,
      movingAnnotation,
      setMovingAnnotation,
      setActiveAnnotation,
      setAnnotationInFlight,
      activeAnnotation,
      annotationInFlight,
      draftAnnotationComment,
      setDraftAnnotationComment,
      isViewingLatestVersion,
      getPositionRelativeToOrigin,
      getElementAtPosition,
      getDotAdditionalStyle,
      hidden,
      guardedShouldRenderAnnotation,
    ]
  )

  return (
    <Context.Provider value={disabled ? UNAVAILABLE_STATE : state}>
      {children}
    </Context.Provider>
  )
}

export default AnnotationOverlayContext
