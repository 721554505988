import React, { useRef } from 'react'

import {
  TruncateWithTooltip,
  HighlightedText,
  useBreakpoint,
} from '@sketch/components'

import { ListGridItem, ItemSecondary } from '../../components/Grid'
import {
  ColorContainer,
  ColorSwatch,
  ColorDetailName,
  ColorDetail,
  ColorDetailSearch,
  SwatchContainer,
  ColorSwatchBackground,
} from './ColorVariables.styles'

import { roundWithLocale, useOnClickOutside } from '@sketch/utils'
import { rgbaToHex } from '../../utils'
import { useAnalytics, useBrowserSettings } from '@sketch/modules-common'

import {
  useInspectContext,
  useComponentsDescriptionContext,
  ComponentDescriptionProps,
} from '../../context'

import type { ColorVariableParsed } from '../../types'

interface ColorVariablesListItemProps {
  color: ColorVariableParsed
  searchValue: string
  groupName: string
  pendingDescription: ComponentDescriptionProps
}

export const ColorVariablesListItem: React.FC<ColorVariablesListItemProps> = ({
  color,
  searchValue,
  groupName,
  pendingDescription,
}) => {
  const isTabletOrBigger = useBreakpoint('sm')
  const isMobile = !isTabletOrBigger

  const { trackEvent } = useAnalytics()
  const { selectedItem, handleSelectItem } = useInspectContext()
  const { resetComponentDescriptionSelection, onComponentSelected } =
    useComponentsDescriptionContext()

  const [data, updateBrowserSettings] = useBrowserSettings()
  const isSidebarRightOpen = data?.sidebarRightOpen

  const contentRef = useRef(null)

  useOnClickOutside(
    contentRef,
    e => {
      handleSelectItem(null)
      resetComponentDescriptionSelection()
    },
    {
      includeSelectors: [
        '[data-testid=side-bar-right]',
        '[data-testid=popover-content]',
      ],
    }
  )

  const handleClick = () => {
    if (isMobile) {
      return
    }

    if (searchValue) {
      trackEvent('CWV - search click', { type: 'color variables' })
    }

    handleSelectItem(color.identifier)
    onComponentSelected(color.uuid, pendingDescription)

    if (!isSidebarRightOpen) {
      updateBrowserSettings({
        sidebarRightOpen: true,
      })
    }
  }

  const colorDetails =
    searchValue !== '' ? (
      <>
        <ColorDetailName>
          <TruncateWithTooltip>
            <HighlightedText search={searchValue}>{color.name}</HighlightedText>
          </TruncateWithTooltip>
        </ColorDetailName>{' '}
        <ItemSecondary>{groupName}</ItemSecondary>
        <TruncateWithTooltip>
          <ColorDetailSearch>
            {rgbaToHex(color.rgbaValue)} —{' '}
            {roundWithLocale(color.alpha * 100, 2)}%
          </ColorDetailSearch>
        </TruncateWithTooltip>
      </>
    ) : (
      <>
        <ColorDetailName>
          <TruncateWithTooltip>{color.name}</TruncateWithTooltip>
        </ColorDetailName>{' '}
        <TruncateWithTooltip>
          <ColorDetail>
            {rgbaToHex(color.rgbaValue)} —{' '}
            {roundWithLocale(color.alpha * 100, 2)}%
          </ColorDetail>
        </TruncateWithTooltip>
      </>
    )

  return (
    <ListGridItem
      data-component={color.identifier}
      key={color.identifier}
      onClick={handleClick}
      ref={contentRef}
      data-testid="color-variable-list-item"
    >
      <ColorContainer isSelected={selectedItem === color.identifier}>
        <SwatchContainer>
          <ColorSwatchBackground />
          <ColorSwatch backgroundColor={color.rgbaValue!} />
        </SwatchContainer>
      </ColorContainer>
      {colorDetails}
    </ListGridItem>
  )
}
