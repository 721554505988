import React from 'react'
import styled, { keyframes, css } from 'styled-components'

import {
  Text,
  Flex,
  Button,
  Modal,
  ModalInjectedProps,
} from '@sketch/components'
import { ReactComponent as DSLoadingIcon } from '@sketch/icons/design-system-64'
import { useCreateDesignSystemDocsMutation } from '@sketch/gql-types'

const DSLoadingIconAnimated = styled(DSLoadingIcon)(
  ({ theme }) => css`
    color: ${theme.colors.foreground.secondary.A};
    height: 64px;
    width: 64px;
    margin-bottom: 24px;
    animation: ${keyframes`
  0% {
    transform: rotateZ(0deg);
  }

  100% {
    transform: rotateZ(360deg);
  }`} 1600ms linear infinite;
  `
)

// this override is necessary because two Text elements always result on a 20px padding on the first one
const LoadingTitle = styled(Text)`
  padding-bottom: 4px !important; /* stylelint-disable-line declaration-no-important */
`

interface CreateDSDocumentationModalProps extends ModalInjectedProps {
  openEditMode: () => void
  designSystemId: string
}

export const CreateDSDocumentationModal: React.FC<
  CreateDSDocumentationModalProps
> = ({ hideModal, openEditMode, designSystemId }) => {
  const [createDesignSystemDocs, { loading }] =
    useCreateDesignSystemDocsMutation({
      onError: 'show-toast',
      onCompleted: () => {
        openEditMode()
        hideModal()
      },
    })

  const handleAddDocumentation = () => {
    createDesignSystemDocs({ variables: { designSystemId } })
  }

  if (loading) {
    return (
      <Modal onCancel={() => null}>
        <Modal.Body>
          <Flex alignItems="center" flexDirection="column" width="100%">
            <DSLoadingIconAnimated />
            <LoadingTitle textStyle="copy.primary.standard.E">
              Preparing&hellip;
            </LoadingTitle>
            <Text textStyle="copy.quaternary.standard.D">
              Setting up documentation tools.
            </Text>
          </Flex>
        </Modal.Body>
      </Modal>
    )
  }

  return (
    <Modal title="Add Documentation to Design System?" onCancel={hideModal}>
      <Modal.Body>
        <Text>
          You can add your own documentation to your design system, such as
          design principles or guidelines on how to use components.
        </Text>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={hideModal}>Cancel</Button>
        <Button onClick={handleAddDocumentation} variant="primary">
          Add Documentation
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
