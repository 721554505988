import React from 'react'
import { useLocalStorage } from 'react-use'

import { StorageKey, localStorageKeys } from '@sketch/constants'

import {
  ArrowWrapper,
  CloseIconContainer,
  StyledLinkButton,
} from './NewFeatureHighlightPopover.styles'

import { NewFeatureHighlightPopoverBody } from '../NewFeatureHighlightPopoverBody'

import { Box, Flex } from '../Box'
import { Popover, PopoverProps } from '../Popover'
import {
  Button,
  ButtonUnstyled,
  ButtonVariant,
  LinkButtonProps,
} from '../Button'
import { NewFeatureHighlight } from '../NewFeatureHighlight'

//Icons
import { ReactComponent as CloseIcon } from '@sketch/icons/cross-16'

type FeaturePopoverLocations = 'header' | 'sidebar'

export interface NewFeatureHighlightPopoverProps {
  onConfirm?: () => void
  highlightPlacement: FeaturePopoverLocations
  titleText: string
  bodyText: string
  confirmCopy?: string
  buttonVariant?: ButtonVariant
  buttonLinkProps?: LinkButtonProps
  localStorageKey: StorageKey
  closeButtonTestId?: string
  show?: boolean
  showPrimaryCloseButton?: boolean
  placement?: PopoverProps['placement']
  modifiers?: PopoverProps['modifiers']
}

const Arrow = React.forwardRef<HTMLDivElement>(function Arrow(props, ref) {
  return (
    <ArrowWrapper {...props} ref={ref}>
      <NewFeatureHighlight />
    </ArrowWrapper>
  )
})

export const NewFeatureHighlightPopover: React.FC<
  NewFeatureHighlightPopoverProps
> = ({
  onConfirm,
  confirmCopy,
  closeButtonTestId = 'new-feature-hightlight-close',
  buttonLinkProps,
  titleText,
  bodyText,
  localStorageKey,
  show = true,
  children,
  showPrimaryCloseButton,
  placement,
  modifiers,
}) => {
  const [introductionSeen, setIntroductionSeen] = useLocalStorage(
    localStorageKeys[localStorageKey],
    false
  )

  const closePopover = () => setIntroductionSeen(true)

  if (introductionSeen || !show) {
    return <>{children}</>
  }

  const onConfirmClick = () => {
    closePopover()
    onConfirm?.()
  }

  const getButton = () => {
    if (!buttonLinkProps && !confirmCopy) {
      return undefined
    }

    if (buttonLinkProps) {
      return (
        <StyledLinkButton
          variant="secondary"
          size="24"
          type="button"
          {...buttonLinkProps}
        >
          {buttonLinkProps.children}
        </StyledLinkButton>
      )
    } else {
      return (
        <Button variant="primary" size="24" onClick={onConfirmClick}>
          {confirmCopy}
        </Button>
      )
    }
  }

  return (
    <Popover
      arrowComponent={Arrow}
      placement={placement}
      modifiers={modifiers}
      newFeatureHighlight={true}
      popup={
        <Box p={4} width={262}>
          <CloseIconContainer>
            <ButtonUnstyled
              onClick={closePopover}
              data-testid={closeButtonTestId}
            >
              <CloseIcon width="16px" />
            </ButtonUnstyled>
          </CloseIconContainer>
          <NewFeatureHighlightPopoverBody
            titleText={titleText}
            bodyText={bodyText}
          />
          {getButton() ? (
            <Flex mt={4} justifyContent="flex-end">
              {getButton()}
              {showPrimaryCloseButton && (
                <Button variant="primary" size="24" onClick={onConfirmClick}>
                  Close
                </Button>
              )}
            </Flex>
          ) : null}
        </Box>
      }
      visible={!introductionSeen}
    >
      {children}
    </Popover>
  )
}
