/*
 * A component that allows GraphQL Subscriptions to be re-used across the
 * `<ProjectsView />` and `<CollectionsView />`
 *
 * When we are viewing a project or collection, we want to subscribe to
 * collection events using `useCollectionSubscriptions()`. Since these
 * subscriptons are scoped to the project, we want to re-use them when
 * navigating between a project and its collections.
 *
 * This component is responsible for setting up the subscriptions and then
 * deciding whether to render `<ProjectsView />` or `<CollectionsView />`.
 *
 * `<ProjectsView />` is used for both "STANDARD" projects and "DRAFT"
 * projects, which means that `params.projectId` will be undefined if the user
 * is visiting `/workspace/id/drafts`. In this case `<ProjectsView />` will
 * fetch the identifier of the drafts folder on mount. We provide a
 * `setProjectIdentifier` prop so that it can update the projectIdentifier
 * given to `useCollectionSubscriptions()`.
 */
import React from 'react'
import { RouteComponentProps, Route, Switch } from 'react-router-dom'

// Views
import ProjectsView from '../ProjectsView'
import CollectionsView from '../../../collections/views/CollectionView'

import { RouteParams, routes } from '@sketch/modules-common'

import { useCollectionSubscriptions } from '../../../collections/hooks'

// GraphQL
import { WorkspaceMinimalFragment } from '@sketch/gql-types'

export type ProjectsAndCollectionsViewRouteProps = RouteComponentProps<
  Partial<
    | RouteParams<'WORKSPACE_PROJECT'>
    | RouteParams<'WORKSPACE_DRAFTS'>
    | RouteParams<'WORKSPACE_COLLECTION'>
  >
>

type ProjectsViewProps = RouteComponentProps<
  Partial<RouteParams<'WORKSPACE_PROJECT'> | RouteParams<'WORKSPACE_DRAFTS'>>
>
type CollectionsViewProps = RouteComponentProps<
  Partial<RouteParams<'WORKSPACE_COLLECTION'>>
>

interface ProjectsAndCollectionsViewProps
  extends ProjectsAndCollectionsViewRouteProps {
  workspace: WorkspaceMinimalFragment
}

const ProjectsAndCollectionsView: React.FC<ProjectsAndCollectionsViewProps> = ({
  workspace,
  match: { params },
}) => {
  const projectIdentifier = 'projectId' in params ? params.projectId : undefined

  const { setProjectIdentifier } = useCollectionSubscriptions({
    projectIdentifier,
  })

  return (
    <Switch>
      <Route
        exact
        path={[
          routes.WORKSPACE_PROJECT.template(),
          routes.WORKSPACE_DRAFTS.template(),
        ]}
        render={({ match: { params } }: ProjectsViewProps) => (
          <ProjectsView
            workspace={workspace}
            projectId={projectIdentifier}
            setProjectIdentifier={setProjectIdentifier}
          />
        )}
      />
      <Route
        exact
        path={[routes.WORKSPACE_COLLECTION.template()]}
        render={({ match: { params } }: CollectionsViewProps) => (
          <CollectionsView
            workspace={workspace}
            projectId={params.projectId!}
            collectionId={params.collectionId!}
          />
        )}
      />
    </Switch>
  )
}

export default ProjectsAndCollectionsView
