import React from 'react'

import { ProjectFragment } from '@sketch/gql-types'
import { formatProjectName } from '../../modules/projects/utils'

/**
 * Display the project name based on its type property.
 * Use this component instead of project.name when you want the drafts folder
 * to be displayed with a padlock icon.
 */
interface ProjectNameProps {
  project: Pick<ProjectFragment, 'projectType' | 'name'> | null
}

export default function ProjectName({ project }: ProjectNameProps) {
  if (!project) {
    return null
  }

  return <>{formatProjectName(project)}</>
}
