import { useCallback } from 'react'
import { useSyncedUrlPrototypeResizeMode } from './useSyncedUrlResizeMode'
import { PrototypeResizeMode } from '@sketch-hq/sketch-web-renderer'
import { useKey } from 'react-use'
import { shortcuts, keyCodeWithShift, keyWithoutModifier } from '@sketch/utils'

/**
 * Provides keyboard shortcuts for changing the prototype resize mode
 */
export function usePrototypeResizeModeShortcuts() {
  const { setResizeMode } = useSyncedUrlPrototypeResizeMode()

  const setToActualSize = useCallback(
    () => setResizeMode(PrototypeResizeMode.ActualSize),
    [setResizeMode]
  )

  const setToFit = useCallback(
    () => setResizeMode(PrototypeResizeMode.Fit),
    [setResizeMode]
  )

  const setToFillWidth = useCallback(
    () => setResizeMode(PrototypeResizeMode.FillWidth),
    [setResizeMode]
  )

  useKey(
    keyWithoutModifier(shortcuts.prototypeSetToActualSize),
    setToActualSize
  )
  useKey(keyCodeWithShift(shortcuts.prototypeSetToFit), setToFit)
  useKey(keyCodeWithShift(shortcuts.prototypeSetToFillWidth), setToFillWidth)
}
