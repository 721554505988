/**
 * Space design tokens.
 *
 * The space key is a specially-named scale intended for use with margin,
 * padding, and other layout-related CSS properties. A space scale can be
 * defined as either a plain object or an array, but by convention an array is
 * preferred. This is an intentional constraint that makes it difficult to add
 * "one-off" or "in-between" sizes that could lead to unwanted and rippling
 * affects to layout.
 *
 * For use with the following CSS properties:
 *
 *     margin, margin-top, margin-right, margin-bottom, margin-left,
 *     margin-block, margin-block-end, margin-block-start, margin-inline,
 *     margin-inline-end, margin-inline-start, padding, padding-top,
 *     padding-right, padding-bottom, padding-left, padding-block,
 *     padding-block-end, padding-block-start, padding-inline,
 *     padding-inline-end, padding-inline-start, inset, inset-block,
 *     inset-block-end, inset-block-start, inset-inline, inset-inline-end,
 *     inset-inline-start, top, right, bottom, left, grid-gap,
 *     grid-column-gap, grid-row-gap, gap, column-gap, row-gap
 *
 * @see https://system-ui.com/theme
 */
export const space = [
  0, 4, 8, 12, 16, 20, 24, 28, 32, 36, 40, 44, 48, 52, 56, 60, 64,
] as const
