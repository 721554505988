import React, {
  useState,
  useEffect,
  useRef,
  MutableRefObject,
  useLayoutEffect,
  useMemo,
} from 'react'

import { ProjectListItem } from '../ProjectListItem'
import DisabledProjectItem from './DisabledProjectItem'

import {
  ListWrapper,
  DisabledProjectList,
  Separator,
} from './ProjectsList.styles'

import { ProjectLink } from '../../types'
import { WorkspaceMinimalFragment } from '@sketch/gql-types'

export interface ProjectListProps {
  pinned?: ProjectLink[]
  projects: ProjectLink[]
  canLoadMore?: boolean
  onLoadMore: () => Promise<any>
  onAdd?: () => void
  workspace: WorkspaceMinimalFragment
  refToLast?: HTMLDivElement
  autofocusLastCreated?: MutableRefObject<boolean>
  isDisabled?: boolean
}

/**
 * ProjectsList
 *
 * This component visually show the list of the projects with the options button on hover.
 *
 * @param props
 * @param {TextLink[]} props.items - The list of project projects.
 * @param {function} props.onDelete - OnClick handler for the Delete button inside the Dropdown.
 *
 * @example // Basic example
 * <List
 *  items={[{id: '1', name: 'Project 1', link: '/project/1'}]}
 *  onDelete={(id) => { delete(id)}}/>
 */
const ProjectsList: React.FC<ProjectListProps> = props => {
  const {
    projects,
    pinned = [],
    canLoadMore,
    onLoadMore,
    workspace,
    autofocusLastCreated,
    isDisabled,
  } = props

  // We are setting the same ref to every project in the list. In this way, is easy to focus
  // in the last one created
  const refToLast = useRef() as React.MutableRefObject<HTMLElement>

  // We want to know that we edited an item
  // and assure that the item has been rendered
  const [editedItem, setEditedItem] = useState('')

  useEffect(() => {
    if (!autofocusLastCreated?.current) return

    refToLast?.current?.focus()
  }, [projects.length, autofocusLastCreated])

  useLayoutEffect(() => {
    if (editedItem !== '') {
      refToLast?.current?.focus()
      setEditedItem('')
    }
  }, [editedItem])

  const pinnedItems = useMemo(() => {
    return pinned.length ? (
      <>
        {pinned.map(({ link, project, nestedProjects }) => (
          <ProjectListItem
            key={project.identifier}
            project={project}
            link={link}
            workspace={workspace}
            refToLast={refToLast}
            setEditedItem={setEditedItem}
            nestedProjects={nestedProjects}
          />
        ))}
        <Separator key="pinned-separator" />
      </>
    ) : null
  }, [pinned, workspace])

  const projectsItems = useMemo(() => {
    return projects.map(({ link, project, nestedProjects }) => (
      <ProjectListItem
        key={project.identifier}
        project={project}
        link={link}
        workspace={workspace}
        refToLast={refToLast}
        setEditedItem={setEditedItem}
        nestedProjects={nestedProjects}
        isProjectList
      />
    ))
  }, [projects, workspace])

  // Inactive workspaces have the list of projects disabled
  // These items have alot less logic then the common project item
  // so we render a much simpler item with just the icon, name
  // and a tooltip for truncated names.
  if (isDisabled) {
    return (
      <DisabledProjectList>
        {projects.map(({ project }) => (
          <DisabledProjectItem key={project.identifier} project={project} />
        ))}
      </DisabledProjectList>
    )
  }

  return (
    <ListWrapper canLoadMore={canLoadMore} onLoadMore={onLoadMore}>
      {pinnedItems}
      {projectsItems}
    </ListWrapper>
  )
}

export default React.memo(ProjectsList)
