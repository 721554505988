import React from 'react'
import styled, { CSSProperties, css } from 'styled-components'

import { usePerformanceOptimizedZoom } from '../../../hooks/inspector'

type GridProps = { gridSize: number; bolder?: boolean }

const Grid = styled.div.attrs(({ gridSize }: GridProps) => {
  const style: CSSProperties = {
    backgroundSize: `${gridSize}px ${gridSize}px`,
  }
  return { style }
})<GridProps>(
  ({ bolder }) => css`
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1;
    pointer-events: none;

    background-image:
      repeating-linear-gradient(
        rgba(254, 26, 24, ${bolder ? 0.6 : 0.3}) 0 1px,
        transparent 1px 100%
      ),
      repeating-linear-gradient(
        90deg,
        rgba(254, 26, 24, ${bolder ? 0.6 : 0.3}) 0 1px,
        transparent 1px 100%
      );
  `
)

type OverlayGridProps = {
  isEnabled: boolean
  gridSize: number
  thickLineStep: number
}
export const OverlayGrid = ({
  isEnabled,
  gridSize,
  thickLineStep,
}: OverlayGridProps) => {
  const zoomFactor = usePerformanceOptimizedZoom()

  if (!isEnabled) {
    return null
  }

  const gridSizeAfterZoom = gridSize * zoomFactor

  return (
    <>
      <Grid gridSize={gridSizeAfterZoom * thickLineStep} bolder />
      <Grid gridSize={gridSizeAfterZoom} />
    </>
  )
}
