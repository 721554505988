import styled, { css } from 'styled-components'

import { Button } from '../Button'
import { Modal } from '../Modal'

export const CancelButton = styled(Button)`
  &&& {
    margin: 8px 0 0;

    &:only-child {
      margin: 0;
    }
  }
`

export const ModalHeader = styled(Modal.Header)`
  padding: 10px 8px 0; /* stylelint-disable-line scales/space */
`

export const ModalBody = styled(Modal.Body)`
  &&& {
    margin-top: 0;
    padding: 8px 0;
    border-bottom: none;
  }
`

export const ModalFooter = styled.div<{ hasOverflow?: boolean }>(
  ({ theme, hasOverflow }) => css`
    display: flex;
    flex-direction: column;
    padding: 8px;

    border-top: ${hasOverflow
      ? `1px solid ${theme.colors.border.A}`
      : undefined};
    text-align: right;

    position: sticky;
    bottom: 0;

    /* Hide the content when it scrolls */
    background: ${theme.colors.background.secondary.A};
  `
)

export const DropdownWrapper = styled.div`
  position: relative;

  display: flex;
  flex-direction: column;
  padding: 8px 0;

  min-width: 100px;
  overflow: hidden;

  box-shadow: ${({ theme }) => theme.shadows.boxShadow.outer};
  border: ${({ theme }) => `1px solid ${theme.colors.border.A}`};

  border-radius: ${({ theme }) => theme.radii.xxlarge};

  background: ${({ theme }) => theme.colors.background.secondary.A};
  background-clip: padding-box;

  /* Make the popover on top of the modal */
  z-index: ${({ theme }) => theme.zIndex[8]};

  cursor: auto; /* This ensures the cursor style of the dropdown items is respected */
`

export const DropdownStyleWrapper = styled.div`
  will-change: contents;

  /* Make the popover on top of the modal */
  position: relative;
  z-index: ${({ theme }) => theme.zIndex[8]};
`
