import React from 'react'

import { useGetWorkspacePermissionGroupMembersQuery } from '@sketch/gql-types'
import {
  ErrorMessage,
  LoadPageSeparator,
  TableComponents,
  handleFetchMore,
} from '@sketch/components'
import { dataIdFromObject } from '@sketch/graphql-cache'

import { MemberCard } from '../MemberCard'
import EmptyState from '../../../shares/components/EmptyState'

import {
  MemberCardCell,
  MembersTable,
  MembersTableWrapper,
  MinimalTableCell,
  SkeletonAvatar,
  SkeletonName,
} from './PermissionGroupMemberTable.styles'

const TABLE_HEADERS = [{ label: 'Member' }, { label: 'action' }]
const ENTRIES_PATH = ['permissionGroup', 'members', 'entries']
const MAXIMUM_MEMBERS_PER_PAGE = 20

interface PermissionGroupMemberTableProps {
  permissionGroupIdentifier: string
  emptyStateDescription?: string | null
  renderAction?: (identifier: string) => React.ReactNode
}

const PermissionGroupMemberTable = (props: PermissionGroupMemberTableProps) => {
  const {
    permissionGroupIdentifier,
    renderAction,
    emptyStateDescription = 'Type in Members’ names or emails to add them.',
  } = props
  const { loading, data, error, fetchMore } =
    useGetWorkspacePermissionGroupMembersQuery({
      variables: { permissionGroupIdentifier },
    })

  if (loading) {
    return (
      <MembersTableWrapper>
        <MembersTable
          header={TABLE_HEADERS}
          hideHeader
          items={Array.from({ length: 2 })}
          renderItem={() => (
            <TableComponents.TableRow>
              <TableComponents.TableCell>
                <SkeletonAvatar />
                <SkeletonName />
              </TableComponents.TableCell>
              <TableComponents.TableCell />
            </TableComponents.TableRow>
          )}
        />
      </MembersTableWrapper>
    )
  }

  if (error || !data) {
    return <ErrorMessage.Generic />
  }

  const { after, totalCount } = data.permissionGroup.members.meta
  const members = data.permissionGroup.members.entries || []

  const tableItems = [
    ...members,
    ...Array.from({
      length: Math.min(totalCount - members.length, MAXIMUM_MEMBERS_PER_PAGE),
    }).map((_, index) =>
      index === 0 ? ('next-page' as const) : ('placeholder' as const)
    ),
  ]

  if (members.length === 0) {
    return (
      <EmptyState
        title="No Group members"
        icon="person-circle"
        description={emptyStateDescription}
      />
    )
  }

  return (
    <MembersTableWrapper>
      <MembersTable
        header={TABLE_HEADERS}
        hideHeader
        items={tableItems}
        renderItem={value => {
          if (value === 'placeholder' || value === 'next-page') {
            return (
              <TableComponents.TableRow>
                <TableComponents.TableCell>
                  <SkeletonAvatar />
                  <SkeletonName />

                  {value === 'next-page' && after && (
                    <LoadPageSeparator
                      key={after}
                      loadNewPage={handleFetchMore(fetchMore, ENTRIES_PATH, {
                        dataIdFromObject,
                        after: after,
                      })}
                    />
                  )}
                </TableComponents.TableCell>
                <TableComponents.TableCell />
              </TableComponents.TableRow>
            )
          }

          const {
            identifier,
            workspaceMember: { user },
          } = value

          return (
            <TableComponents.TableRow data-testid="permission-group-member">
              <MemberCardCell>
                <MemberCard
                  name={user!.name}
                  avatarSrc={user!.avatar!.large}
                  email={user!.email}
                />
              </MemberCardCell>
              <MinimalTableCell>{renderAction?.(identifier)}</MinimalTableCell>
            </TableComponents.TableRow>
          )
        }}
      />
    </MembersTableWrapper>
  )
}

export default PermissionGroupMemberTable
