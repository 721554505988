import React from 'react'

import * as S from './WorkspaceSidebarNoticeCanceledSubscription.styles'
import { WorkspaceNoticeComponentProps } from '../types'
import {
  BillingWorkspaceNoticeFragment,
  WorkspaceMinimalFragment,
  useGetBillingForWorkspaceNoticeQuery,
  useGetPaymentDetailsQuery,
} from '@sketch/gql-types'
// eslint-disable-next-line no-restricted-imports
import { PaymentDetailsType } from '@sketch/gql-types/expansive'

import { routes } from '@sketch/modules-common'

import { ReactComponent as LicenseIconMonochrome } from '@sketch/icons/license-diamond-monochrome-16'
import { ReactComponent as ExclamationMarkTriangleMonochromeIcon } from '@sketch/icons/exclamation-mark-triangle-monochrome-16'

import { WorkspaceSidebarNoticeContainer } from '../WorkspaceSidebarNoticeContainer'
import {
  NoticeContentTemplateWithOptionalActionAndLearnMore,
  NoticeContentTemplateWithOptionalActionAndLearnMoreProps,
} from '../NoticeContentTemplates'
import { dateFormat } from '@sketch/utils'
import { useCanSubscribeViaStripe } from '../../../utils'
import {
  canManageSubscription,
  isOrWillBeCanceledSubscription,
  isWillBeCanceledSubscription,
} from '../workspaceStatusUtils'
import { SvgComponent } from '@sketch/icons'

type WorkspaceSidebarNoticeCanceledSubscriptionProps =
  WorkspaceNoticeComponentProps

const IOS_SUBSCRIPTION_DOCUMENTATION_LINK =
  'https://support.apple.com/en-us/guide/iphone/iph3dfd91de/ios'

const MAC_SUBSCRIPTION_DOCUMENTATION_LINK =
  'https://support.apple.com/en-us/guide/app-store/fire5f3a0745/mac'

const MAC_PAYMENT_DETAILS_DOCUMENTATION_LINK =
  'https://support.apple.com/en-us/HT201266'

const getSubscriptionLink = (source: string | undefined) => {
  if (source === 'macappstore') return MAC_SUBSCRIPTION_DOCUMENTATION_LINK
  if (source === 'ios') return IOS_SUBSCRIPTION_DOCUMENTATION_LINK
  return ''
}

export function WorkspaceSidebarNoticeCanceledSubscription({
  workspace,
}: WorkspaceSidebarNoticeCanceledSubscriptionProps) {
  const billingQuery = useGetBillingForWorkspaceNoticeQuery({
    variables: { identifier: workspace.identifier },
  })
  const { data, loading: paymentDetailsLoading } = useGetPaymentDetailsQuery({
    variables: { customerId: workspace.customer!.identifier },
  })
  const billing = billingQuery.data?.workspace.customer?.billing

  const {
    source,
    loading: loadingStripeSub,
    canSubscribeStripe,
  } = useCanSubscribeViaStripe(
    workspace.identifier,
    workspace.customer?.identifier
  )

  if (
    billingQuery.loading ||
    billingQuery.error ||
    !billing ||
    loadingStripeSub ||
    paymentDetailsLoading
  ) {
    return null
  }

  if (!isOrWillBeCanceledSubscription(billing)) {
    // We are not expecting to end up in that state.
    return null
  }

  const appStoreLink = getSubscriptionLink(source)
  const noticeContentData = getNoticeContentData(
    billing,
    workspace,
    data?.customer?.paymentDetails?.type,
    canSubscribeStripe,
    appStoreLink
  )

  return (
    <WorkspaceSidebarNoticeContainer>
      <NoticeContentTemplateWithOptionalActionAndLearnMore
        action={noticeContentData.action}
        icon={noticeContentData.icon}
        learnMoreLink={noticeContentData.learnMore}
        title={noticeContentData.title}
      >
        {noticeContentData.details && (
          <S.DetailsParagraph>{noticeContentData.details}</S.DetailsParagraph>
        )}
      </NoticeContentTemplateWithOptionalActionAndLearnMore>
    </WorkspaceSidebarNoticeContainer>
  )
}

type TemplateButtonAction =
  NoticeContentTemplateWithOptionalActionAndLearnMoreProps['action']
type TemplateLearnMoreLink =
  NoticeContentTemplateWithOptionalActionAndLearnMoreProps['learnMoreLink']

type NoticeContentData = {
  title: string
  icon: SvgComponent
  details: string | null
  action?: TemplateButtonAction
  learnMore?: TemplateLearnMoreLink
}

/** Get different notice content based on reason of cancelation */
function getNoticeContentData(
  billing: BillingWorkspaceNoticeFragment,
  workspace: WorkspaceMinimalFragment,
  paymentDetailsType: PaymentDetailsType | undefined,
  canSubscribeStripe: boolean,
  appStoreLink: string
): NoticeContentData {
  const billingSettingRoute = routes.WORKSPACE_SETTINGS_BILLING.create({
    workspaceId: workspace.identifier,
  })
  const subscribeRoute = routes.WORKSPACE_SUBSCRIBE.create({
    workspaceId: workspace.identifier,
  })

  const hasRoleToPerformAction = canManageSubscription(workspace.userRole)

  const updatePaymentDetailsAction: TemplateButtonAction | undefined =
    hasRoleToPerformAction && canSubscribeStripe
      ? {
          label: 'Review Payment Details',
          link: { to: billingSettingRoute },
        }
      : undefined

  const resubscribeAction: TemplateButtonAction | undefined =
    hasRoleToPerformAction &&
    paymentDetailsType !== 'INVOICE' &&
    canSubscribeStripe
      ? {
          label: 'Subscribe',
          link: { to: subscribeRoute },
        }
      : undefined

  if (isWillBeCanceledSubscription(billing)) {
    return {
      title: 'Subscription canceled',
      icon: LicenseIconMonochrome,
      details: `You can keep using Sketch until ${dateFormat(
        new Date(billing.subscriptionEnd!)
      )}. Come back soon!`,
    }
  }

  if (billing.status === 'PAST_DUE') {
    return {
      title: 'Your payment is due',
      icon: ExclamationMarkTriangleMonochromeIcon,
      details: hasRoleToPerformAction
        ? 'Please check your billing details or contact customer support'
        : 'Please ask your Workspace admin to check your billing details',
      action: updatePaymentDetailsAction,
      learnMore: !canSubscribeStripe
        ? {
            external: true,
            href: MAC_PAYMENT_DETAILS_DOCUMENTATION_LINK,
          }
        : undefined,
    }
  }

  if (billing.status === 'UNPAID' || billing.status === 'INCOMPLETE_EXPIRED') {
    return {
      title: 'Subscription canceled',
      icon: ExclamationMarkTriangleMonochromeIcon,
      details: 'Something went wrong with a recent payment',
      action: updatePaymentDetailsAction,
      learnMore: !canSubscribeStripe
        ? {
            external: true,
            href: MAC_PAYMENT_DETAILS_DOCUMENTATION_LINK,
          }
        : undefined,
    }
  }

  if (billing.status === 'INCOMPLETE') {
    return {
      title: 'We were unable to validate your card',
      icon: ExclamationMarkTriangleMonochromeIcon,
      details: null,
      action: updatePaymentDetailsAction,
      learnMore: !canSubscribeStripe
        ? {
            external: true,
            href: MAC_PAYMENT_DETAILS_DOCUMENTATION_LINK,
          }
        : undefined,
    }
  }

  return {
    title: `Your subscription expired on ${dateFormat(
      new Date(billing.subscriptionEnd!)
    )}`,
    icon: LicenseIconMonochrome,
    details: null,
    action: resubscribeAction,
    learnMore: !canSubscribeStripe
      ? {
          external: true,
          href: appStoreLink,
        }
      : undefined,
  }
}
