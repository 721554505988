import { ConfirmationModalGenericProps } from './useConfirmationModal'
import React, { useState } from 'react'
import { Modal, Text, RadioButton, Button } from '@sketch/components'
import {
  MovingTextWrapper,
  StyledSeparator,
  Bold,
  DangerRadioButton,
} from './ConfirmTransferModal.styles'
import ShareAccessList from '../../../shares/components/ShareAccessList'
import {
  ProjectFragment,
  ShareInfoFragment,
  WorkspaceMinimalFragment,
} from '@sketch/gql-types'

type MembershipOperation = 'TRANSFER_NON_SSO' | 'DELETE_ALL'

export interface ConfirmDocumentTransferModalProps
  extends ConfirmationModalGenericProps<{
    membershipsOperation: MembershipOperation
  }> {
  targetWorkspace: WorkspaceMinimalFragment
  share: Pick<ShareInfoFragment, 'identifier' | 'memberships'>
  project:
    | Pick<ProjectFragment, 'identifier' | 'name' | 'workspaceAccessLevel'>
    | undefined
}

export const ConfirmDocumentTransferModal = (
  props: ConfirmDocumentTransferModalProps
) => {
  const { onCancel, onConfirm, loading, targetWorkspace, share, project } =
    props
  const [membershipsOperation, setMembershipsOperation] =
    useState<MembershipOperation>('TRANSFER_NON_SSO')

  const handleTransferMembershipsOptionChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setMembershipsOperation(e.target.value as MembershipOperation)
  }

  return (
    <Modal onCancel={onCancel}>
      <Modal.Header>Move Document?</Modal.Header>
      <Modal.Body>
        <MovingTextWrapper>
          <Text>
            The document and all its comments will be moved to{' '}
            <Bold>{targetWorkspace.name}</Bold>. Do you want to keep sharing the
            document with previously invited people?
          </Text>
          <RadioButton
            name="transfer-option"
            label="Yes, share it with the same people"
            checked={membershipsOperation === 'TRANSFER_NON_SSO'}
            value="TRANSFER_NON_SSO"
            onChange={handleTransferMembershipsOptionChange}
          />
          <DangerRadioButton
            name="transfer-option"
            label="No, remove previously invited people"
            checked={membershipsOperation === 'DELETE_ALL'}
            value="DELETE_ALL"
            onChange={handleTransferMembershipsOptionChange}
          />
        </MovingTextWrapper>

        <StyledSeparator />
        <ShareAccessList
          shareIdentifier={share.identifier}
          membershipCount={share.memberships?.meta.totalCount}
          destination={{ project, workspace: targetWorkspace }}
          hideMembersList={membershipsOperation === 'DELETE_ALL'}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onCancel}>Cancel</Button>
        <Button
          variant="primary"
          loading={loading}
          onClick={() => onConfirm({ membershipsOperation })}
        >
          Move Document
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
