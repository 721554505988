import styled, { css } from 'styled-components'

import { ButtonUnstyled, Text, Dropdown } from '@sketch/components'
import { breakpoint } from '@sketch/global-styles'

export const Trigger = styled(ButtonUnstyled)(
  ({ theme, disabled }) => css`
    position: absolute;
    bottom: 59px; /* 51px mobile toolbar + 8px extra space */
    left: 8px;
    z-index: 100;

    display: flex;
    align-items: center;

    padding: 6px 8px 6px 12px; /* stylelint-disable-line scales/space */
    background-color: ${theme.colors.buttons.pageSelector.default};
    border: 1px solid ${theme.colors.border.A};
    border-radius: 5px;

    &:hover {
      background-color: ${disabled
        ? undefined
        : theme.colors.buttons.pageSelector.hover};
    }

    &:active {
      background-color: ${disabled
        ? undefined
        : theme.colors.buttons.pageSelector.active};
      border: 1px solid transparent;
    }

    ${breakpoint('sm')`
      bottom: auto;
      top: 8px;
    `}
  `
)

export const CurrentPage = styled(Text).attrs({
  textStyle: 'copy.primary.standard.D',
})`
  display: block;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 50vw;

  margin-right: 4px;

  em {
    font-style: normal;
    color: ${props => props.theme.colors.foreground.secondary.D};
  }
`

export const Chevrons = styled(Dropdown.Chevrons)`
  color: ${props => props.theme.colors.foreground.secondary.D};
`
