import React from 'react'

import {
  AssetReadyIcon,
  NotificationDescription,
  FailedAsset,
  FailedIcon,
  NotificationShareName,
  NotificationBody,
  DownloadAsset,
} from '../NotificationListItem.styles'

import { formatByteCount } from '@sketch/utils'

import { DownloadableAssetRenderedNotificationFragment } from '@sketch/gql-types'
import { TruncateWithTooltip } from '@sketch/components'

interface DownloadableAssetReadyProps {
  notification: DownloadableAssetRenderedNotificationFragment
  assetPath: string
  fileSizeInBytes: number
  markNotificationAsRead: () => void
}

const DownloadableAssetReady: React.FC<DownloadableAssetReadyProps> = ({
  notification,
  assetPath,
  fileSizeInBytes,
  markNotificationAsRead,
}) => {
  const onClick = (e: React.SyntheticEvent) => {
    e.preventDefault()
    window.open(assetPath, '_self')
    markNotificationAsRead()
  }

  return (
    <>
      <NotificationShareName data-testid="notification-share-name">
        <TruncateWithTooltip>
          {notification?.share?.workspace.name} / {notification?.share?.name}
        </TruncateWithTooltip>
      </NotificationShareName>
      <NotificationBody data-testid="notification-body">
        <AssetReadyIcon />
        <div>
          Your assets are ready to{' '}
          <DownloadAsset onClick={onClick} data-testid="download-assets-button">
            download (ZIP {formatByteCount(fileSizeInBytes)})
          </DownloadAsset>
        </div>
      </NotificationBody>
    </>
  )
}

interface DownloadableAssetFailedProps {
  notification: DownloadableAssetRenderedNotificationFragment
}

const DownloadableAssetFailed: React.FC<DownloadableAssetFailedProps> = ({
  notification,
}) => {
  return (
    <>
      <NotificationShareName data-testid="notification-share-name">
        <TruncateWithTooltip>
          {notification?.share?.workspace.name} / {notification?.share?.name}
        </TruncateWithTooltip>
      </NotificationShareName>
      <NotificationDescription data-testid="notification-description">
        <FailedAsset>
          <FailedIcon /> Failed to export assets
        </FailedAsset>
      </NotificationDescription>
      <NotificationBody data-testid="notification-body">
        Please try again, if this error persists, try uploading your document
        again.
      </NotificationBody>
    </>
  )
}

const getDownloadableAsset = (
  notification: DownloadableAssetRenderedNotificationFragment
) => {
  const maybeAsset =
    notification.notifiedVersion?.document?.downloadableAssets[0]
  if (maybeAsset?.status !== 'AVAILABLE') {
    return undefined
  }

  return maybeAsset
}

interface DownloadableAssetNotificationListItemProps {
  notification: DownloadableAssetRenderedNotificationFragment
  markNotificationAsRead: () => void
}

const DownloadableAssetNotificationListItem: React.FC<
  DownloadableAssetNotificationListItemProps
> = ({ notification, markNotificationAsRead }) => {
  const maybeAsset = getDownloadableAsset(notification)

  if (maybeAsset && maybeAsset.path) {
    return (
      <DownloadableAssetReady
        notification={notification}
        assetPath={maybeAsset.path}
        fileSizeInBytes={maybeAsset.fileSizeInBytes!}
        markNotificationAsRead={markNotificationAsRead}
      />
    )
  } else {
    return <DownloadableAssetFailed notification={notification} />
  }
}

export default DownloadableAssetNotificationListItem
