import { createContext, useContext } from 'react'

interface SearchComponentsContextValues {
  search: string
}

export const SearchComponentsContext =
  createContext<SearchComponentsContextValues>({
    search: '',
  })

export const useSearchComponentsContext = () => {
  const context = useContext(SearchComponentsContext)!

  return context
}
