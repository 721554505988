import { useSetUserAuthorization } from '../useSetUserAuthorization'
import { useAnalytics } from '../../../analytics'

import {
  GetInitialUserDocument,
  GetUserSettingsDocument,
  SignInMutation,
  useSignInMutation,
} from '@sketch/gql-types'

export const useSignIn = ({
  onCompleted,
  onError,
}: {
  onCompleted?: (data: SignInMutation) => void
  onError?: (message: string) => void
}) => {
  const setUserAuthorization = useSetUserAuthorization()
  const { trackEvent } = useAnalytics()

  const signInMutation = useSignInMutation({
    refetchQueries: ({ data }) =>
      data.signIn.__typename === 'SignInCredentials'
        ? [
            {
              query: GetInitialUserDocument,
            },
            {
              query: GetUserSettingsDocument,
            },
          ]
        : [],
    awaitRefetchQueries: true,
    update: (_, { data }) => {
      if (data?.signIn.__typename !== 'SignInCredentials') return

      setUserAuthorization(data.signIn!.credentials!)
    },
    onCompleted: data => {
      onCompleted?.(data)

      trackEvent('Successful SignIn', { type: 'password' })
    },
    onError: error => {
      onError?.(error.message)
    },
  })

  return signInMutation
}
