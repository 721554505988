import styled from 'styled-components'

import {
  PersonAvatar,
  Truncate,
  WorkspaceLogo,
  Text,
  ProjectLogo,
} from '@sketch/components'
import { WorkspaceRow } from '@sketch/modules-common'
import { ProjectRow } from '../ProjectRow'

export const Section = styled.section`
  max-height: 200px;
  overflow-x: auto;
`

export const Title = styled(Text.H1).attrs({
  textStyle: 'copy.tertiary.standard.E',
})`
  margin: 0;
`

export const List = styled.ul`
  list-style: none;
  padding: 0;
`

export const Row = styled.li`
  display: flex;
  align-items: center;

  border-radius: 6px;
  padding: 8px 20px 8px 8px;

  :nth-child(odd) {
    background-color: ${({ theme }) => theme.colors.background.secondary.B};
  }
`

export const Name = styled(Truncate)`
  margin-left: 8px;

  font-size: 0.8125rem;
  font-weight: 500;
  line-height: ${({ theme }) => theme.lineHeights.default};

  color: ${({ theme }) => theme.colors.foreground.secondary.A};
`

export const Permission = styled(Text.Span).attrs({
  textStyle: 'copy.tertiary.standard.D',
})`
  margin-left: auto;
  padding-left: 8px;
`

export const PlaceholderAvatar = styled(PersonAvatar)`
  background-color: ${({ theme }) => theme.colors.background.tertiary.B};
`

export const PlaceholderWorkspaceAvatar = styled(WorkspaceLogo)`
  background-color: ${({ theme }) => theme.colors.background.tertiary.B};
`

export const ErrorText = styled.span`
  color: ${({ theme }) => theme.colors.state.negative.A};
`

export const MoreMembers = styled.span`
  padding: 8px 20px 8px 0;
  display: flex;
  align-items: center;
`

export const StyledProjectRow = styled(ProjectRow)`
  padding-left: 8px;
  padding-right: 20px;
  background-color: ${({ theme }) => theme.colors.background.secondary.B};

  ${ProjectLogo} {
    width: 24px;
    height: 24px;
    background-color: transparent;
    border: 1px solid ${({ theme }) => theme.colors.border.A};
    border-radius: 4px;
  }
`

export const StyledWorkspaceRow = styled(WorkspaceRow)`
  padding-left: 8px;
  padding-right: 20px;
  background-color: ${({ theme }) => theme.colors.background.secondary.B};
`
