import React, { FC } from 'react'
import { Button, Modal, ModalInjectedProps } from '@sketch/components'

import { Bold } from './MoveShareToProject.styles'

export interface MoveShareToProjectModalLayoutProps extends ModalInjectedProps {
  share: { name: string }
  button?: {
    loading?: boolean
    disabled?: boolean
    onClick?(): void
  }
}

export const MoveShareToProjectModalLayout: FC<
  MoveShareToProjectModalLayoutProps
> = props => {
  const { share, children, hideModal, button } = props

  return (
    <Modal title="Move Document?" onCancel={hideModal}>
      <Modal.Body>
        Choose where you want to move “<Bold>{share.name}</Bold>”.
        <br />
        <br />
        {children}
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={hideModal}>Cancel</Button>
        <Button
          variant="primary"
          disabled={button?.disabled}
          loading={button?.loading}
          onClick={button?.onClick}
        >
          Move Document
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
