import React from 'react'
import { dataIdFromObject } from '@sketch/graphql-cache'

import { LoadPageSeparator, handleFetchMore } from '@sketch/components'

import VersionItem from '../VersionItem'
import ShareVersionsListWrapper from '../ShareVersionsListWrapper'
import PreventiveRevisionLink from '../PreventiveRevisionLink'
import { useVersioning } from '../../ShareVersionContext'
import VersionItemPlaceholder from '../VersionItemPlaceholder'
import InlineSidebarError from '../InlineSidebarError'
import { Star } from '../StarVersion'

import { useGetFrameRevisions } from '../../hooks/useGetFrameRevisions'

import {
  FrameGroupRevisionDeletedFragment,
  FrameGroupRevisionFragment,
  FrameGroupRevisionUnavailableFragment,
} from '@sketch/gql-types'
import { useFlag, versionedRoutes } from '@sketch/modules-common'

const ENTRIES_PATH = ['frame', 'revisions', 'entries']
const PAGE_SIZE = 20

type Revision =
  | FrameGroupRevisionFragment
  | FrameGroupRevisionDeletedFragment
  | FrameGroupRevisionUnavailableFragment

interface FrameRevisionsItemProps {
  revision: Revision
  position?: number
  frameIdentifier: string
  shareIdentifier: string
  frameUUID: string
  getPathname: ReturnType<typeof useVersioning>['getPathname']
}

const FrameRevisionsItem = (props: FrameRevisionsItemProps) => {
  const { revision, position, shareIdentifier, frameUUID, frameIdentifier } =
    props
  const isFramesWebOn = useFlag('frames-web')

  const authorName = revision.creator.name ?? null

  if (
    revision.__typename === 'RevisionDeleted' ||
    revision.__typename === 'RevisionUnavailable'
  ) {
    return (
      <VersionItem
        order={position}
        authorName={authorName}
        creationDate={revision.createdAt}
        deleted={revision.__typename === 'RevisionDeleted'}
      />
    )
  }

  const isAuto = revision.version?.kind === 'AUTO'
  const revisionStarred = revision.kind === 'PUBLISHED'

  const getRevisionLink = () => {
    if (isFramesWebOn && revision.frame?.__typename === 'Frame') {
      return revision.frame?.documentVersionShortId
        ? versionedRoutes.FRAME.VERSION.create({
            frameUUID,
            shareID: shareIdentifier,
            versionShortId: revision.frame.documentVersionShortId,
          })
        : undefined
    } else {
      return revision.frame?.documentVersionShortId
        ? versionedRoutes.ARTBOARD_DETAIL.VERSION.create({
            permanentArtboardShortId: frameUUID,
            shareID: shareIdentifier,
            versionShortId: revision.frame.documentVersionShortId,
          })
        : undefined
    }
  }

  return (
    <PreventiveRevisionLink
      key={revision.identifier}
      isActive={match => !!match || frameIdentifier === revision.identifier}
      to={getRevisionLink()}
    >
      <VersionItem
        key={revision.identifier}
        authorName={authorName}
        creationDate={revision.createdAt}
        order={position}
        description={revision.version?.description || undefined}
        isStarred={revisionStarred}
        star={revisionStarred && (() => <Star isStarred />)}
        isAuto={isAuto}
        renderStatus={
          /* We force the finished status because only exists finished revisions */
          'FINISHED'
        }
      />
    </PreventiveRevisionLink>
  )
}

interface FrameRevisionsProps {
  shareIdentifier: string
  frameUUID: string
  frameIdentifier: string
}

export const FrameRevisions = (props: FrameRevisionsProps) => {
  const { shareIdentifier, frameUUID, frameIdentifier } = props

  const { getPathname } = useVersioning()
  const { loading, data, error, fetchMore } = useGetFrameRevisions(
    shareIdentifier,
    frameUUID
  )

  if (loading || error || !data?.frame) {
    return (
      <ShareVersionsListWrapper>
        <VersionItemPlaceholder count={5} />
        {error && <InlineSidebarError error={error} />}
      </ShareVersionsListWrapper>
    )
  }

  const { entries, meta } = data.frame.revisions
  const revisions = [...(entries || [])].reverse()

  const handleLoadMore = handleFetchMore(fetchMore, ENTRIES_PATH, {
    dataIdFromObject,
    after: meta?.after,
  })

  return (
    <ShareVersionsListWrapper data-testid="artboard-revisions-list">
      <VersionItemPlaceholder
        count={Math.min(PAGE_SIZE, meta.totalCount - entries.length)}
      />

      {meta.after && (
        <LoadPageSeparator key={meta.after} loadNewPage={handleLoadMore} />
      )}

      {revisions.map(revision => (
        <FrameRevisionsItem
          key={revision.identifier}
          shareIdentifier={shareIdentifier}
          revision={revision}
          frameIdentifier={frameIdentifier}
          frameUUID={frameUUID}
          position={revision.order || undefined}
          getPathname={getPathname}
        />
      ))}
    </ShareVersionsListWrapper>
  )
}
