import { castError } from '@sketch/utils'
import ApolloClient from 'apollo-client'
import { DataProxy } from 'apollo-cache'
import produce from 'immer'
import { ErrorHandler } from '@sketch/tracing'
import {
  GetProjectMembershipsQuery,
  GetProjectMembershipsDocument,
  ProjectMembershipFragment,
  GetAccessProjectMembershipsDocument,
  GetAccessProjectMembershipsQuery,
  RemoveProjectMemberMutation,
} from '@sketch/gql-types'

interface AddProjectMembershipProps {
  cache: DataProxy | ApolloClient<object>
  projectIdentifier: string
  membership: ProjectMembershipFragment
}

interface RemoveProjectMembershipProps {
  cache: DataProxy
  projectIdentifier: string
  membershipIdentifier: string
}

interface RemoveProjectMembershipAccessModalProps {
  cache: DataProxy
  data?: RemoveProjectMemberMutation | null
  workspaceIdentifier: string
  workspaceMembershipIdentifier: string
}

export const addProjectMembership = ({
  cache,
  projectIdentifier,
  membership,
}: AddProjectMembershipProps) => {
  try {
    const queryDefinition = {
      query: GetProjectMembershipsDocument,
      variables: { projectIdentifier },
    }

    const projectMembershipsQueryData =
      cache.readQuery<GetProjectMembershipsQuery>(queryDefinition)
    if (!projectMembershipsQueryData) {
      return
    }

    const newProjectMembershipsQuery = produce(
      projectMembershipsQueryData,
      queryData => {
        const projectMemberships = queryData.project.members?.entries || []
        const updatedMemberships = [membership, ...projectMemberships]

        const isProjectMembershipAdded = projectMemberships.find(
          projectMembership =>
            projectMembership.identifier === membership.identifier
        )

        if (isProjectMembershipAdded || !queryData.project.members) {
          return
        }

        queryData.project.members.entries = updatedMemberships
        queryData.project.members.meta.totalCount = updatedMemberships.length
      }
    )

    cache.writeQuery<GetProjectMembershipsQuery>({
      ...queryDefinition,
      data: newProjectMembershipsQuery,
    })
  } catch (e) {
    const error = castError(e)
    // Reading from the cache can throw when the query doesn't exist.
    // We ignore this error because the worst that will happen is that a
    // removed member will not disappear without re-opening the modal.
    ErrorHandler.ignore(error)
  }
}

export const removeProjectMembership = ({
  cache,
  projectIdentifier,
  membershipIdentifier,
}: RemoveProjectMembershipProps) => {
  try {
    const queryDefinition = {
      query: GetProjectMembershipsDocument,
      variables: { projectIdentifier },
    }

    const projectMembershipsQueryData =
      cache.readQuery<GetProjectMembershipsQuery>(queryDefinition)
    if (!projectMembershipsQueryData) {
      return
    }

    const newProjectMembershipsQuery = produce(
      projectMembershipsQueryData,
      queryData => {
        const projectMemberships = queryData.project.members?.entries || []
        const updatedMemberships = projectMemberships.filter(
          membership => membership.identifier !== membershipIdentifier
        )

        if (!queryData.project.members) {
          return
        }

        queryData.project.members.entries = updatedMemberships
        queryData.project.members.meta.totalCount = updatedMemberships.length
      }
    )

    cache.writeQuery<GetProjectMembershipsQuery>({
      ...queryDefinition,
      data: newProjectMembershipsQuery,
    })
  } catch (e) {
    const error = castError(e)
    // Reading from the cache can throw when the query doesn't exist.
    // We ignore this error because the worst that will happen is that a
    // removed member will not disappear without re-opening the modal.
    ErrorHandler.ignore(error)
  }
}

export const removeProjectMembershipUserAccessModal = ({
  cache,
  data,
  workspaceIdentifier,
  workspaceMembershipIdentifier,
}: RemoveProjectMembershipAccessModalProps) => {
  try {
    const queryDefinition = {
      query: GetAccessProjectMembershipsDocument,
      variables: {
        workspaceIdentifier,
        workspaceMembershipIdentifier,
        projectSearch: { name: '' },
      },
    }

    const projectMembershipsQueryData =
      cache.readQuery<GetAccessProjectMembershipsQuery>(queryDefinition)

    if (!projectMembershipsQueryData) {
      return
    }

    const newProjectMembershipsQuery = produce(
      projectMembershipsQueryData,
      queryData => {
        const projectMemberships =
          queryData.workspace.workspaceMembership?.projectMemberships.entries
        const deletedProjectMembershipId =
          data?.removeProjectMember.project.identifier

        if (!projectMemberships || !queryData.workspace.workspaceMembership) {
          return
        }

        const updatedMemberships = projectMemberships.filter(
          membership =>
            membership.project.identifier !== deletedProjectMembershipId
        )

        queryData.workspace.workspaceMembership.projectMemberships.entries =
          updatedMemberships
        queryData.workspace.workspaceMembership.projectMemberships.meta.totalCount =
          updatedMemberships.length
      }
    )

    cache.writeQuery<GetAccessProjectMembershipsQuery>({
      ...queryDefinition,
      data: newProjectMembershipsQuery,
    })
  } catch (e) {
    const error = castError(e)
    // Reading from the cache can throw when the query doesn't exist.
    // We ignore this error because the worst that will happen is that a
    // removed member will not disappear without re-opening the modal.
    ErrorHandler.ignore(error)
  }
}
