import { WorkspaceMinimalFragment } from '@sketch/gql-types'

import {
  StorageItemLocation,
  StorageItemTransferLocation,
} from '../../operations'
import {
  TreeNodeTypes,
  useTreeState,
} from '../../../projects/modals/ProjectPickerContent'
import { TreeState } from '../../../projects/modals/ProjectPickerContent/state.base'
import { isTruthy } from '@sketch/utils'
import { useEffect, useState } from 'react'

const findTargetWorkspace = (
  targetId: string,
  treeState: TreeState<TreeNodeTypes>
) => {
  const path = treeState.tree.getPathToNode(targetId)

  for (const pathId of path) {
    const node = treeState.tree.getNode(pathId)
    if (node?.payload?.__typename === 'Workspace') {
      return node.payload as WorkspaceMinimalFragment
    }
  }
}

export const getCurrentLocation = (
  treeState: TreeState<TreeNodeTypes>
): StorageItemLocation<'name'> | null => {
  const currentIds = treeState.currentIds
  const workspaceNode = treeState.tree.getNode(currentIds.workspaceId)?.payload
  const workspace =
    workspaceNode?.__typename === 'Workspace' ? workspaceNode : null

  if (!workspace) return null

  const projectNode = currentIds.projectId
    ? treeState.tree.getNode(currentIds.projectId)?.payload
    : null
  const project =
    projectNode?.__typename === 'Project' ? projectNode : undefined

  return { workspace, project }
}

export const getTargetLocation = (
  target: TreeNodeTypes,
  targetWorkspace: WorkspaceMinimalFragment | undefined
): StorageItemLocation<'name'> | null => {
  const targetProject = target.__typename === 'Project' ? target : null

  if (!targetWorkspace) return null

  return { workspace: targetWorkspace, project: targetProject || undefined }
}

export const getLocations = (
  target: TreeNodeTypes,
  treeState: TreeState<TreeNodeTypes>
) => {
  const currentLocation = getCurrentLocation(treeState)
  const targetWorkspace = findTargetWorkspace(target.identifier, treeState)
  const targetLocation = getTargetLocation(target, targetWorkspace)

  const transferLocation: StorageItemTransferLocation<'name'> | null =
    currentLocation && targetLocation
      ? { source: currentLocation, destination: targetLocation }
      : null

  return { transferLocation, targetWorkspace }
}

export interface PathSegment {
  id: string
  name: string
}
export const getPathToCurrent = (
  treeState: TreeState<TreeNodeTypes>
): PathSegment[] => {
  const currentNodeId = treeState.currentIds.currentId
  const pathIds = treeState.tree.getPathToNode(currentNodeId)

  return pathIds
    .map(x => {
      const payload = treeState.tree.getNode(x)?.payload
      if (!payload) return null
      return { id: payload.identifier, name: payload.name }
    })
    .filter(isTruthy)
}

export const usePathToCurrent = () => {
  const treeState = useTreeState()

  const [path, setPath] = useState<PathSegment[]>([])

  useEffect(() => {
    const currentNodeId = treeState.currentIds.currentId
    return treeState.registerUpdateListener(currentNodeId, reason => {
      setPath(getPathToCurrent(treeState))
    })
  }, [treeState])

  return path
}
