import React, { FC, useEffect } from 'react'

import { Hoverable, HoverableProps } from 'react-event-as-prop'
import { Toast } from './Toast'
import { Variant } from './Toast.types'

const DISMISS_TIMEOUT_MS = 5000

interface ToastContainerProps {
  id: string
  onDismiss: (id: string) => void
  variant: Variant
  autoDismiss?: boolean
  children: React.ReactNode
}

const ToastContainerRaw = ({
  id,
  hovered,
  onDismiss,
  autoDismiss = true,
  ...props
}: ToastContainerProps & HoverableProps) => {
  useEffect(() => {
    let dismissTimeout: number | null

    const dismiss = () => {
      if (!autoDismiss) {
        return
      }

      // Reset timeout if user hovers over notification
      if (hovered && dismissTimeout) {
        clearTimeout(dismissTimeout)
        dismissTimeout = null
        return
      }

      // Don't assign new timeout if timeout is already assigned
      if (dismissTimeout) return

      dismissTimeout = window.setTimeout(
        () => onDismiss(id),
        DISMISS_TIMEOUT_MS
      )
    }

    dismiss()

    return () => {
      if (dismissTimeout) clearTimeout(dismissTimeout)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hovered])

  return (
    <Toast id={id} hovered={hovered} onClose={() => onDismiss(id)} {...props} />
  )
}

export const ToastContainer: FC<ToastContainerProps> =
  Hoverable(ToastContainerRaw)
