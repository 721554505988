import styled, { css } from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 280px;
`

export const Title = styled.h1(
  ({ theme }) => css`
    font-size: ${theme.fontSizes.E};
    font-weight: ${theme.fontWeights.regular};
    line-height: ${theme.lineHeights.default};
    color: ${theme.colors.foreground.secondary.A};
    margin: 24px 0 0;
  `
)

export const Description = styled.p(
  ({ theme }) => css`
    font-size: ${theme.fontSizes.D};
    line-height: ${theme.lineHeights.default};
    color: ${theme.colors.foreground.secondary.D};
    margin-bottom: 18px; /* stylelint-disable-line scales/space */
    text-align: center;
  `
)
