import React, { useMemo } from 'react'
import styled, { CSSProperties, css } from 'styled-components'

import { usePerformanceOptimizedZoom } from '../../../hooks/inspector'
import { LayoutSettings } from '../../../../../inspector'

type LayoutBaseProps = {
  $width?: number
  $height?: number
  horizontalOffset?: number
}
const LayoutBase = styled.div.attrs(({ $width, $height }: LayoutBaseProps) => {
  const style: CSSProperties = {
    width: $width ? `${$width}px` : `100%`,
    height: $height ? `${$height}px` : `100%`,
  }
  return { style }
})<LayoutBaseProps>(
  ({ horizontalOffset }) => css`
    position: absolute;
    z-index: 1;
    pointer-events: none;

    left: ${horizontalOffset}px;
  `
)

const LayoutVertical = styled(LayoutBase)<{
  columnWidth: number
  gutterWidth: number
  verticalOffsetWithGutter?: number
}>(
  ({ columnWidth, gutterWidth, verticalOffsetWithGutter }) => css`
    background-image: repeating-linear-gradient(
      90deg,
      rgba(254, 26, 24, 0.1) 0 ${columnWidth}px,
      transparent ${gutterWidth}px 100%
    );
    background-size: ${columnWidth + gutterWidth}px
      ${columnWidth + columnWidth}px;

    left: ${verticalOffsetWithGutter
      ? `${verticalOffsetWithGutter}px`
      : undefined};
  `
)

const LayoutHorizontal = styled(LayoutBase)<{
  rowHeight: number
  gutterHeight: number
}>(
  ({ rowHeight, gutterHeight }) => css`
    background-image: repeating-linear-gradient(
      rgba(254, 26, 24, 0.1) 0 ${rowHeight}px,
      transparent ${rowHeight}px 100%
    );
    background-size: ${rowHeight + gutterHeight}px ${rowHeight + gutterHeight}px;
  `
)

const HorizontalLines = styled(LayoutBase)<{
  horizontalLinesHeight: number
}>(
  ({ horizontalLinesHeight }) => css`
    background-image: repeating-linear-gradient(
      #fdd2d2 0 1px,
      transparent 1px 100%
    );
    background-size: ${horizontalLinesHeight}px ${horizontalLinesHeight}px;
  `
)

type OverlayLayoutProps = {
  isEnabled: boolean
  layoutSettings: LayoutSettings
  artboardWidth: number
  artboardHeight: number
}
export const OverlayLayout = ({
  isEnabled,
  layoutSettings,
  artboardWidth,
  artboardHeight,
}: OverlayLayoutProps) => {
  const { drawHorizontal, drawHorizontalLines, drawVertical } = layoutSettings
  const layoutComputedData = useLayoutComputedData(
    layoutSettings,
    artboardWidth
  )

  if (!isEnabled || !layoutComputedData) {
    return null
  }

  const {
    columnWidthAfterZoom,
    gutterWidthAfterZoom,
    gutterHeightAfterZoom,
    horizontalOffsetAfterZoom,
    verticalLayoutWidth,
    totalWidthAfterZoom,
    horizontalWidthAfterOffset,
    layerHeight,
    verticalOffsetWithGutter,
    rowHeightAfterZoom,
  } = layoutComputedData

  return (
    <>
      {drawVertical && (
        <LayoutVertical
          gutterWidth={gutterWidthAfterZoom}
          columnWidth={columnWidthAfterZoom}
          $width={verticalLayoutWidth}
          horizontalOffset={horizontalOffsetAfterZoom}
          verticalOffsetWithGutter={verticalOffsetWithGutter}
        />
      )}
      {drawHorizontal && (
        <LayoutHorizontal
          gutterHeight={gutterHeightAfterZoom}
          rowHeight={rowHeightAfterZoom}
          $width={horizontalWidthAfterOffset}
          $height={layerHeight > artboardHeight ? artboardHeight : layerHeight}
          horizontalOffset={horizontalOffsetAfterZoom}
        />
      )}
      {drawHorizontalLines && (
        <HorizontalLines
          $height={layerHeight}
          $width={
            totalWidthAfterZoom > artboardWidth
              ? artboardWidth
              : totalWidthAfterZoom
          }
          horizontalOffset={horizontalOffsetAfterZoom}
          horizontalLinesHeight={gutterHeightAfterZoom}
        />
      )}
    </>
  )
}

function useLayoutComputedData(
  layoutSettings: LayoutSettings,
  artboardWidth: number
) {
  const {
    columnWidth,
    gutterHeight,
    gutterWidth,
    horizontalOffset,
    numberOfColumns,
    numberOfRows,
    totalWidth,
    rowHeightMultiplication,
    guttersOutside,
  } = layoutSettings

  const zoomFactor = usePerformanceOptimizedZoom()

  return useMemo(() => {
    const horizontalOffsetAfterZoom = horizontalOffset * zoomFactor
    const totalWidthAfterZoom = totalWidth * zoomFactor

    const columnWidthAfterZoom = columnWidth * zoomFactor
    const gutterWidthAfterZoom = gutterWidth * zoomFactor

    const gutterHeightAfterZoom = gutterHeight * zoomFactor
    const rowHeightAfterZoom = rowHeightMultiplication * gutterHeightAfterZoom

    // rather than using totalWidth from the settings, se use the width taken by the number of columns we want to show
    // however, for horizontal lines and rows, we'll use the totalWidth from the settings
    const layerWidth =
      numberOfColumns * (columnWidthAfterZoom + gutterWidthAfterZoom)

    const layerHeight =
      numberOfRows * (rowHeightAfterZoom + gutterHeightAfterZoom)

    // do not allow offsets bigger than the artboard (total overflow)
    if (horizontalOffsetAfterZoom >= artboardWidth) {
      return null
    }

    // all other cases (offset not overflowing completely but still have a bit off)
    // get trimmed to the artboard width
    const horizontalWidthAfterOffset =
      totalWidthAfterZoom + horizontalOffsetAfterZoom > artboardWidth
        ? artboardWidth - horizontalOffsetAfterZoom
        : totalWidthAfterZoom

    let verticalOffsetWithGutter = guttersOutside
      ? // we need to consider the offset and the gutter to calculate the `left` position
        // and apparently the gutter value to consider on the outside is only half of the gutter width on the mac
        // why is that? no one really know, nor it makes much sense, but here we are
        (horizontalOffsetAfterZoom ?? 0) + gutterWidthAfterZoom / 2
      : undefined

    // do not allow negative values so there's no overflow
    if (verticalOffsetWithGutter && verticalOffsetWithGutter < 0) {
      verticalOffsetWithGutter = 0
    }

    let verticalLayoutWidth = layerWidth

    // without gutters, we consider the offset only
    if (!guttersOutside) {
      if (layerWidth + horizontalOffsetAfterZoom > artboardWidth) {
        verticalLayoutWidth = artboardWidth - horizontalOffsetAfterZoom
      }
    } else {
      if (
        verticalOffsetWithGutter &&
        layerWidth + verticalOffsetWithGutter > artboardWidth
      ) {
        verticalLayoutWidth = artboardWidth - verticalOffsetWithGutter
      }
    }

    return {
      gutterWidthAfterZoom,
      columnWidthAfterZoom,
      verticalOffsetWithGutter,
      verticalLayoutWidth,
      horizontalOffsetAfterZoom,
      totalWidthAfterZoom,
      horizontalWidthAfterOffset,
      gutterHeightAfterZoom,
      layerHeight,
      rowHeightAfterZoom,
    }
  }, [
    artboardWidth,
    columnWidth,
    gutterHeight,
    gutterWidth,
    guttersOutside,
    horizontalOffset,
    numberOfColumns,
    numberOfRows,
    rowHeightMultiplication,
    totalWidth,
    zoomFactor,
  ])
}
