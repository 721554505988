import React, { useCallback, useEffect, useRef } from 'react'
import { AnnotationsOverlay } from '../../../../canvas/components'
import {
  usePan,
  useRootNodeAbsoluteExtent,
  useZoom,
} from '@sketch-hq/sketch-web-renderer'

import { ReferenceBounds } from '../../../../annotations/types'

interface ArtboardAnnotationOverlayProps {
  parentWrapper: React.RefObject<HTMLElement>
  activeAnnotationIdentifier?: string
  permanentArtboardId: string
}

const ArtboardAnnotationOverlay = (props: ArtboardAnnotationOverlayProps) => {
  const { parentWrapper, activeAnnotationIdentifier, permanentArtboardId } =
    props

  const rootExtent = useRootNodeAbsoluteExtent()
  const scale = useZoom() || 1
  const pan = usePan()

  const cachedPan = useRef(pan)
  useEffect(() => {
    cachedPan.current = pan
  }, [pan])

  const cachedScale = useRef(scale)
  useEffect(() => {
    cachedScale.current = scale
  }, [scale])

  const getArtboardContainerBounds = useCallback(
    (artboardCoordinatesByUuid: Record<string, ReferenceBounds>) => {
      const artboardBounds = artboardCoordinatesByUuid[permanentArtboardId]

      if (
        artboardBounds &&
        rootExtent &&
        cachedPan.current &&
        parentWrapper.current
      ) {
        const parentBounds = parentWrapper.current.getBoundingClientRect()

        const top =
          (cachedPan.current.y + artboardBounds.y - rootExtent.y) *
            cachedScale.current +
          parentBounds.y
        const left =
          (cachedPan.current.x + artboardBounds.x - rootExtent.x) *
            cachedScale.current +
          parentBounds.x

        return {
          top,
          bottom: top + artboardBounds.height * cachedScale.current,
          left,
          right: left + artboardBounds.width * cachedScale.current,
        }
      }
    },
    [parentWrapper, permanentArtboardId, rootExtent]
  )

  return (
    <AnnotationsOverlay
      getArtboardContainerBounds={getArtboardContainerBounds}
      activeAnnotationIdentifier={activeAnnotationIdentifier}
      parentWrapper={parentWrapper}
    />
  )
}

export default ArtboardAnnotationOverlay
