import { useApolloClient } from 'react-apollo'
import { DataProxy } from 'apollo-cache'

import { useStableHandler } from '@sketch/utils'
import { ErrorHandler } from '@sketch/tracing'

import {
  createAnnotationResolutionInfoFragment,
  createInitialUserQuery,
} from '../../utils'

import { useAnnotationQueryVariables } from '../../context'

import {
  AnnotationResolutionInfoFragment,
  AnnotationSubjectFragment,
  useResolveAnnotationMutation,
  useUnResolveAnnotationMutation,
} from '@sketch/gql-types'

import {
  getAnnotationResolution,
  getAnnotationSubject,
} from '../../utils/cache'

import {
  resolveAnnotation,
  unResolveAnnotation,
} from '../../utils/annotationCacheOperations'

export type ToggleResolveAnnotationCallback = ReturnType<
  typeof useToggleResolveAnnotation
>

type OptimistResponse = AnnotationSubjectFragment &
  AnnotationResolutionInfoFragment

const createResolveOptimistic = (
  apollo: DataProxy,
  identifier: string,
  resolution: AnnotationResolutionInfoFragment['resolution']
) => {
  const annotationSubject = getAnnotationSubject(apollo, identifier)

  return {
    annotation: {
      __typename: 'Annotation',
      identifier,
      resolution,
      ...annotationSubject,
    } as OptimistResponse,
  }
}

export const useToggleResolveAnnotation = () => {
  const client = useApolloClient()

  const { shareIdentifier, versionIdentifier } =
    useAnnotationQueryVariables('default')

  const [resolve] = useResolveAnnotationMutation({
    onError: 'unsafe-throw-exception',
    update: (cache, { data }) => {
      if (!data?.resolveAnnotation) {
        return
      }

      const { annotation } = data.resolveAnnotation
      resolveAnnotation(
        client,
        { shareIdentifier, versionIdentifier },
        annotation
      )
    },
  })

  const [unResolve] = useUnResolveAnnotationMutation({
    onError: 'unsafe-throw-exception',
    update: (cache, { data }) => {
      if (!data?.unresolveAnnotation) {
        return
      }

      const { annotation } = data.unresolveAnnotation
      unResolveAnnotation(
        client,
        { shareIdentifier, versionIdentifier },
        annotation
      )
    },
  })

  return useStableHandler(async (annotationIdentifier: string) => {
    const annotation = getAnnotationResolution(client, annotationIdentifier)

    if (!annotation) {
      ErrorHandler.shouldNeverHappen(
        `Error resolving/unresolving: could not find an Annotation with ID: ${annotationIdentifier}`
      )
      return
    }

    if (!annotation?.resolution) {
      return resolve({
        variables: { annotationIdentifier },
        optimisticResponse: () => {
          const user = createInitialUserQuery(client)
          const resolution = createAnnotationResolutionInfoFragment(user.me)

          return {
            __typename: 'RootMutationType',
            resolveAnnotation: {
              __typename: 'ResolveAnnotationResponse',
              ...createResolveOptimistic(
                client,
                annotationIdentifier,
                resolution
              ),
            },
          }
        },
      })
    }

    return unResolve({
      variables: { annotationIdentifier },
      optimisticResponse: () => ({
        __typename: 'RootMutationType',
        unresolveAnnotation: {
          __typename: 'UnresolveAnnotationResponse',
          ...createResolveOptimistic(client, annotationIdentifier, null),
        },
      }),
    })
  })
}
