import React, { useEffect } from 'react'
import groupBy from 'lodash.groupby'
import uniqWith from 'lodash.uniqwith'
import partition from 'lodash.partition'
import { ExportFormat, VisibleScaleType } from '../../../../../../inspector'
import { ErrorHandler } from '@sketch/tracing'
import { ExportFormat as ExportFormatGQL } from '@sketch/gql-types/typemapImports'

import { Spinner, Text, Tooltip, downloadFile, Flex } from '@sketch/components'

import { usePrevious } from '@sketch/utils'

import { ReactComponent as ExportAssetsIcon } from '@sketch/icons/arrow-down-to-line-16'

import {
  VersionFragment,
  useRenderDownloadableAssetsMutation,
} from '@sketch/gql-types'

import {
  ExportValue,
  ExportsSection,
  ExportAsButton,
  HeaderContainer,
  ExportValueSize,
  ExportValueLabel,
  ExportValueAction,
} from './Export.styles'

import { AttributeList, Header, Separator, HeaderTitle } from '../../components'
import { useExportModal } from './ExportModal'
import { useToast } from '@sketch/toasts'

//-----------------------------------------------------------------------------
// UTILS

const isSVGExport = (xport: ExportFormat): boolean =>
  xport.fileFormat.toLowerCase() === 'svg'

const isAxisConstrainedExport = (xport: ExportFormat): boolean =>
  xport.visibleScaleType === VisibleScaleType.Width ||
  xport.visibleScaleType === VisibleScaleType.Height

const stringForExport = (xport: ExportFormat): string => {
  if (xport.fileFormat.toLowerCase() === 'svg') {
    return 'Scalable'
  }

  switch (xport.visibleScaleType) {
    case VisibleScaleType.Width:
      return `${xport.absoluteSize}w`

    case VisibleScaleType.Height:
      return `${xport.absoluteSize}h`

    case VisibleScaleType.Scale:
      return `${xport.scale}x`
  }
}

const stringForExports = (exports: ExportFormat[]): string => {
  const [axisConstrainedExports, scaledExports] = partition(
    uniqWith(exports, isSVGExport),
    isAxisConstrainedExport
  )

  const axisStrings = axisConstrainedExports
    .sort((a, b) => a.absoluteSize - b.absoluteSize)
    .sort((a, b) => a.visibleScaleType - b.visibleScaleType)

  const scaledStrings = scaledExports.sort((a, b) => a.scale - b.scale)
  const uniqueStrings = [...new Set([...scaledStrings, ...axisStrings])]

  return uniqueStrings.map(stringForExport).join(', ')
}

type ErrorType = 'default' | 'forbidden' | undefined

interface IndividualAssetsProps {
  layerOrArtboardId: string
  formats?: ExportFormat['fileFormat'][]
  scales?: number[]
  currentVersion: VersionFragment
}

// React hook to manage the logic to request individual assets
const useRequestIndividualAssets = ({
  layerOrArtboardId,
  formats = [],
  scales = [],
  currentVersion,
}: IndividualAssetsProps) => {
  // Mutation to request individual assets, for now this process is
  // synchronously for layer assets so it will return the asset ready to download
  const [renderAssets, { data, loading, error }] =
    useRenderDownloadableAssetsMutation({
      redirectErrors: false,
      variables: {
        documentIdentifier: currentVersion.document?.identifier || '',
        input: {
          layerUuids: [layerOrArtboardId],
          formats: formats as ExportFormatGQL[],
          scales,
        },
      },
      onError: 'unsafe-throw-exception',
    })

  const shouldShowError =
    (error || data?.renderDownloadableAssets?.errors.length) && !loading

  const errorType: ErrorType = shouldShowError
    ? (data?.renderDownloadableAssets?.errors || []).find(
        error => error?.code === 'FORBIDDEN'
      )
      ? 'forbidden'
      : 'default'
    : undefined

  const asset = data?.renderDownloadableAssets?.downloadableAsset
  const previousAsset = usePrevious(asset)

  // Automatically downloads the asset when it's ready
  useEffect(() => {
    if (asset?.path && !previousAsset && !shouldShowError) {
      downloadFile(asset!.path!)
    }
  }, [asset, previousAsset, shouldShowError])

  return { renderAssets, loading, errorType }
}

//-----------------------------------------------------------------------------
// COMPONENTS:
//  - ErrorText: shows different types of errors when downloading an asset
//  - ExportButton: button to download an individual asset
//  - Export: download info for every format (title, value and button)
//  - Exports: exported component that builds the rest of the pieces

interface ErrorTextProps {
  type: ErrorType
}

const ErrorText: React.FC<ErrorTextProps> = ({ type = 'default' }) => {
  const text = {
    default:
      'Assets export failed. Please try again or re-upload your document.',
    forbidden:
      'This document does not allow asset exports. Please contact the document owner.',
  }

  return (
    <Text marginBottom={0} textAlign="center">
      {text[type]}
    </Text>
  )
}

interface ExportProps
  extends Pick<IndividualAssetsProps, 'layerOrArtboardId' | 'currentVersion'> {
  format: ExportFormat['fileFormat']
  exportByName: ExportFormat[]
}

const Export = ({
  format,
  exportByName,
  currentVersion,
  layerOrArtboardId,
}: ExportProps) => {
  const { renderAssets, loading, errorType } = useRequestIndividualAssets({
    layerOrArtboardId: layerOrArtboardId,
    formats: [format.toUpperCase()] as ExportFormat['fileFormat'][],
    scales: exportByName
      ? // Note: this is just simple way to keep unique items: `Array.from(new Set([1,2,2]))` returns [1,2]
        Array.from(new Set(exportByName.map(e => e.scale)))
      : [],
    currentVersion,
  })

  useShowExportErrorIfNeeded(errorType)

  return (
    <Tooltip content={`Download ${format}`} placement="top">
      {({ ref, ...props }) => (
        <ExportValue
          ref={ref}
          data-testid="download-asset-request"
          onClick={() => renderAssets()}
          {...props}
        >
          <ExportValueLabel>{format.toUpperCase()}</ExportValueLabel>
          <ExportValueSize>{stringForExports(exportByName)}</ExportValueSize>
          <ExportValueAction>
            {loading ? (
              <Spinner primary size="12px" />
            ) : (
              <ExportAssetsIcon width={16} height={16} color="grey" />
            )}
          </ExportValueAction>
        </ExportValue>
      )}
    </Tooltip>
  )
}

interface ExportsProps {
  exportType: 'artboard' | 'layer'
  exportFormats: ExportFormat[]
  currentVersion: VersionFragment
  hasOtherExportableLayers?: boolean
  exportableAssetId?: string
  nodeIdentifier: bigint
  elementName: string
}

export const Exports = ({
  exportFormats,
  currentVersion,
  exportableAssetId,
  nodeIdentifier,
  elementName,
}: ExportsProps) => {
  const hasExports = Boolean(exportFormats.length)
  // We can have multiple export using the same file format but different scales, let's group them.
  const exportByFileFormat = groupBy(exportFormats, 'fileFormat')
  // Cast because TS Object.keys looses the specific type
  const availableFileFormatNames = Object.keys(exportByFileFormat) as Array<
    ExportFormat['fileFormat']
  >

  let content: React.ReactNode = null

  if (!hasExports) {
    content = null
  } else if (!exportableAssetId) {
    ErrorHandler.shouldNeverHappen(
      'We expect exportableAssetId to always be present if there are export formats'
    )
    return null
  } else {
    content = (
      <>
        <HeaderContainer>
          <Header>
            <HeaderTitle>Export</HeaderTitle>
          </Header>
        </HeaderContainer>
        <AttributeList>
          {availableFileFormatNames.map(format => (
            <Export
              key={format}
              format={format}
              exportByName={exportByFileFormat[format]}
              layerOrArtboardId={exportableAssetId}
              currentVersion={currentVersion}
            />
          ))}
        </AttributeList>
      </>
    )
  }

  return (
    <>
      <Separator />
      <ExportsSection data-testid="inspector-sidebar-exports">
        {content}
        <Flex>
          {exportableAssetId && availableFileFormatNames.length > 1 && (
            <ExportAllButton
              currentVersion={currentVersion}
              layerOrArtboardId={exportableAssetId}
            />
          )}
          <ExportAs nodeIdentifier={nodeIdentifier} elementName={elementName} />
        </Flex>
      </ExportsSection>
    </>
  )
}

type ExportAsProps = {
  nodeIdentifier: bigint
  elementName: string
}

function ExportAs({ nodeIdentifier, elementName }: ExportAsProps) {
  const { openExportModal } = useExportModal()

  return (
    <ExportAsButton
      size="24"
      onClick={() => openExportModal(nodeIdentifier, elementName)}
    >
      Export as…
    </ExportAsButton>
  )
}

function ExportAllButton({
  layerOrArtboardId,
  currentVersion,
}: Pick<IndividualAssetsProps, 'layerOrArtboardId' | 'currentVersion'>) {
  const { renderAssets, loading, errorType } = useRequestIndividualAssets({
    layerOrArtboardId,
    currentVersion,
  })

  useShowExportErrorIfNeeded(errorType)

  return (
    <ExportAsButton
      data-testid="download-asset-all-request"
      size="24"
      onClick={() => renderAssets()}
      loading={loading}
    >
      Download All
    </ExportAsButton>
  )
}

/**
 * When an errorType is provided, display the
 * corresponding error message as a toast.
 */
function useShowExportErrorIfNeeded(errorType: ErrorType) {
  const { showToast } = useToast()
  useEffect(() => {
    if (errorType) {
      showToast(<ErrorText type={errorType} />, 'negative')
    }
  }, [errorType, showToast])
}
