import React, { ReactNode, useState, useEffect } from 'react'

import { Tooltip } from '@sketch/components'
import { ColorFormat, ColorVariable } from '../../../../../../types'
import { ColorDot } from '../ColorDot'
import PopoverTrigger from './PopoverTrigger'
import {
  ClickableArea,
  ColorInfo,
  ColorNameInPopover,
  ColorPathInPopover,
  PopupVariableInfo,
  ColorAttributeContainer,
  LinkStyled,
  VersionLinkStyled,
  IconWrapper,
  StyledPopover,
  Chevron,
} from './ColorVariablePopover.styles'

import { useVersioning } from '../../../../../../../versioning'
import { routes, useHandleCopyValue } from '@sketch/modules-common'
import { ReactComponent as ColorVariableIcon } from '@sketch/icons/color-fan-16'

import { useGetComponentWithUuid } from '../../../useGetComponentWithUuid'
import { formatPath } from '../../../../../utils'

/**
 * UTILS
 */
const colorVariableInfo = (
  colorVariableName: string,
  colorVariableSourceLibraryName?: string | null
) => {
  const groups = colorVariableName.split('/')

  const name = groups.length ? groups[groups.length - 1] : colorVariableName

  // Remove color variable name from path (removes last item)
  groups.splice(-1, 1)

  const library = colorVariableSourceLibraryName
    ? colorVariableSourceLibraryName.trim()
    : 'This Document'

  const path = groups.length ? `${library}/${formatPath(groups)}` : library

  return [name, path] as const
}

/**
 * HELPER COMPONENTS
 */
const LinkUnavailable: React.FC = ({ children }) => (
  <Tooltip
    placement="top"
    content="This color belongs to a Library that is unavailable"
  >
    {children}
  </Tooltip>
)

const ColorNamePath = ({ name, path }: { name: string; path: string }) => {
  const {
    handleCopyValue,
    copyTooltipText,
    handleEnterCopiableArea,
    handleLeaveCopiableArea,
    copyTooltipVisible,
  } = useHandleCopyValue(name)

  return (
    <ColorInfo>
      <Tooltip
        placement="left"
        spacing="10px"
        visible={copyTooltipVisible}
        content={copyTooltipText}
      >
        <ClickableArea
          onClick={handleCopyValue}
          onMouseEnter={handleEnterCopiableArea}
          onMouseLeave={handleLeaveCopiableArea}
        >
          <ColorNameInPopover>{name}</ColorNameInPopover>
        </ClickableArea>
      </Tooltip>
      <ColorPathInPopover>{path}</ColorPathInPopover>
    </ColorInfo>
  )
}

/**
 * MAIN COMPONENT
 */
export const ColorVariablePopover: React.FC<
  ColorVariable & {
    onColorFormatChange: (f: ColorFormat) => void
    colorFormat: ColorFormat
    copyValue: string
    dropdown: ReactNode
  }
> = ({
  red,
  green,
  blue,
  alpha = 1,
  colorFormat,
  copyValue,
  colorVariableID,
  colorVariableRemoteID,
  colorVariableName,
  colorVariableSourceLibraryID,
  colorVariableSourceLibraryName,
  dropdown: colorDropdown,
}) => {
  const [isPopoverOpen, setPopoverOpen] = useState(false)
  const {
    handleCopyValue,
    copyTooltipText,
    handleEnterCopiableArea,
    handleLeaveCopiableArea,
    copyTooltipVisible,
  } = useHandleCopyValue(copyValue)

  // Every time the color variable changes the popover should hide
  useEffect(() => {
    setPopoverOpen(false)
  }, [colorVariableName])

  const versionContext = useVersioning()
  const { share: shareFromContext } = versionContext
  const { currentVersion } = (!versionContext.loading && versionContext) || {}

  // isForeign should be part of Color type, but for now it can be derived
  const isForeign = !!(
    colorVariableSourceLibraryID && colorVariableSourceLibraryName
  )

  const {
    shareID,
    externalShareName,
    componentUnavailable,
    searchParams,
    component,
  } = useGetComponentWithUuid({
    componentId: isForeign ? colorVariableRemoteID : colorVariableID,
    externalDocumentId: colorVariableSourceLibraryID,
    externalDocumentName: colorVariableSourceLibraryName,
    isForeign,
    shareIdentifier: shareFromContext.identifier,
    currentVersion,
  })

  const [name, path] = colorVariableInfo(
    component?.name || colorVariableName,
    // externalShareName has wrong value when there is an error with the query,
    // that's why we need to provide a fallback using colorVariableSourceLibraryName
    componentUnavailable ? colorVariableSourceLibraryName : externalShareName
  )

  const colorAttribute = (
    <Tooltip
      placement="left"
      spacing="10px"
      visible={copyTooltipVisible}
      content={copyTooltipText}
    >
      <ClickableArea
        onClick={handleCopyValue}
        onMouseEnter={handleEnterCopiableArea}
        onMouseLeave={handleLeaveCopiableArea}
      >
        <ColorDot
          red={red}
          green={green}
          blue={blue}
          alpha={alpha}
          format={colorFormat}
        />
      </ClickableArea>
    </Tooltip>
  )

  const popupContent = <ColorNamePath name={name} path={path} />

  const linkExternalComponent = componentUnavailable ? (
    <LinkUnavailable>{popupContent}</LinkUnavailable>
  ) : (
    <>
      <LinkStyled
        to={{
          pathname: routes.CWV_COLOR_VARIABLES.create({
            shareID: shareID!,
          }),
          search: searchParams,
        }}
      >
        <IconWrapper>
          <ColorVariableIcon />
        </IconWrapper>
      </LinkStyled>
      {popupContent}
      <LinkStyled
        to={{
          pathname: routes.CWV_COLOR_VARIABLES.create({
            shareID: shareID!,
          }),
          search: searchParams,
        }}
      >
        <Chevron />
      </LinkStyled>
    </>
  )

  const linkInternalComponent = (
    <>
      <VersionLinkStyled
        to={{
          routeKey: 'CWV_COLOR_VARIABLES',
          routeParams: {
            shareID: shareFromContext.identifier,
          },
          search: searchParams,
        }}
      >
        <IconWrapper>
          <ColorVariableIcon />
        </IconWrapper>
      </VersionLinkStyled>
      {popupContent}
      <VersionLinkStyled
        to={{
          routeKey: 'CWV_COLOR_VARIABLES',
          routeParams: {
            shareID: shareFromContext.identifier,
          },
          search: searchParams,
        }}
      >
        <Chevron />
      </VersionLinkStyled>
    </>
  )

  // Note: when linking to a foreign component we always link to the latest
  // version, but for internal components we want a versioned link
  const wrapperLink = isForeign ? linkExternalComponent : linkInternalComponent

  const popup = (
    <>
      <PopupVariableInfo>{wrapperLink}</PopupVariableInfo>
      <ColorAttributeContainer>
        {colorAttribute}
        {colorDropdown}
      </ColorAttributeContainer>
    </>
  )

  return (
    <StyledPopover
      visible={isPopoverOpen}
      placement="top"
      onClickOutside={() => setPopoverOpen(false)}
      popup={popup}
      data-testid="color-variable-popover"
    >
      <PopoverTrigger
        red={red}
        green={green}
        blue={blue}
        alpha={alpha}
        name={name}
        path={path}
        openPopover={() => setPopoverOpen(true)}
      />
    </StyledPopover>
  )
}
