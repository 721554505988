import React, { useCallback, useRef } from 'react'
import { useFindElementFromCanvasRelativePositionInScreenSpace } from '../useFindElementFromCanvasRelativePositionInScreenSpace'
import { findPointRelativeToCanvasInScreenSpaceFromMouseEvent } from './findPointRelativeToCanvasInScreenSpaceFromMouseEvent'
import { SketchElement } from '../../../../../../inspector'

const MAX_CLICK_DRAG_DELTA = 10

interface MousePosition {
  x: number
  y: number
}

/**
 * Hook creating the handler for the click event on the canvas.
 */
export function useCanvasClickHandler(
  sketchSceneRootElement: SketchElement | null,
  onClick: (element: SketchElement | null) => void,
  doesSketchElementAllowUserInteraction?: (
    rootElement: SketchElement,
    element: SketchElement,
    event: React.MouseEvent<HTMLElement>
  ) => boolean
) {
  const originalPointerPosition = useRef<MousePosition | null>(null)

  const findTargetElementFromCanvasRelativePositionInScreen =
    useFindElementFromCanvasRelativePositionInScreenSpace(
      sketchSceneRootElement
    )

  const clickHandler = useCallback(
    (reactEvent: React.MouseEvent<HTMLElement>) => {
      const safeGuardedOriginalPositionX =
        originalPointerPosition.current?.x || reactEvent.clientX
      const safeGuardedOriginalPositionY =
        originalPointerPosition.current?.y || reactEvent.clientY

      const deltaX = safeGuardedOriginalPositionX - reactEvent.clientX
      const deltaY = safeGuardedOriginalPositionY - reactEvent.clientY

      if (
        Math.abs(deltaX) > MAX_CLICK_DRAG_DELTA ||
        Math.abs(deltaY) > MAX_CLICK_DRAG_DELTA
      ) {
        return
      }

      const pointRelativeToCanvasInScreenSpace =
        findPointRelativeToCanvasInScreenSpaceFromMouseEvent(reactEvent)

      if (!pointRelativeToCanvasInScreenSpace || !sketchSceneRootElement) {
        return null
      }

      const matchingElement =
        findTargetElementFromCanvasRelativePositionInScreen(
          pointRelativeToCanvasInScreenSpace,
          element =>
            doesSketchElementAllowUserInteraction?.(
              sketchSceneRootElement,
              element,
              reactEvent
            ) ?? true
        )
      onClick(matchingElement)
    },
    [
      doesSketchElementAllowUserInteraction,
      findTargetElementFromCanvasRelativePositionInScreen,
      sketchSceneRootElement,
      onClick,
    ]
  )

  const mouseDownHandle = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      originalPointerPosition.current = { x: event.clientX, y: event.clientY }
    },
    []
  )

  return { clickHandler, mouseDownHandle }
}
