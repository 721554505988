import { useEffect, useMemo, useRef } from 'react'
import { Stripe, StripeElements } from '@stripe/stripe-js'
import { CardNumberElement } from '@stripe/react-stripe-js'

import { castError } from '@sketch/utils'
import {
  confirmPendingSetupIntent,
  updateWorkspaceTosAgreed,
} from '../../utils'

import {
  useSubscribeCustomerToPlanMutation,
  GetWorkspaceDocument,
  useCreateSetupIntentMutation,
} from '@sketch/gql-types'

// eslint-disable-next-line no-restricted-imports
import { CreditCardPaymentDetails } from '@sketch/gql-types/expansive'

import { SubscribeToPlanWithBilling } from '../../types'
import { useToast } from '@sketch/toasts'

interface Listeners {
  onComplete: () => void
  onError: (error: Error) => void
}

export const useSubscribeToPlan = (
  workspaceId: string,
  stripe?: Stripe,
  elements?: StripeElements,
  pendingSCAToken?: string | undefined | null,
  listeners?: Listeners
) => {
  const cachedListeners = useRef(listeners)
  const { showToast } = useToast()
  useEffect(() => {
    cachedListeners.current = listeners
  }, [listeners])
  const [createSetupIntent] = useCreateSetupIntentMutation({
    onError: 'show-toast',
  })

  const [subscribe, { data }] = useSubscribeCustomerToPlanMutation({
    onError: 'unsafe-throw-exception',
    update: (cache, { data }) => {
      /* Some error prevented the customer to be queried */
      if (!data?.subscribeCustomerToPlan?.customer) {
        return
      }

      updateWorkspaceTosAgreed(cache, { workspaceId, tosAgreed: true })
    },
    refetchQueries: [
      {
        // Workspace subscribed successfuly, so lets update its status
        // this allows expired workspaces to show the correct empty state
        // after the subscribe
        query: GetWorkspaceDocument,
        variables: { identifier: workspaceId },
      },
    ],
  })

  const isSetupIntent = (localPendingSCAToken: string) =>
    localPendingSCAToken && localPendingSCAToken.startsWith('seti_')

  const subscribeCustomer = useMemo(() => {
    const subscribeCustomerWithPMId = async (
      customerId: string,
      state: SubscribeToPlanWithBilling,
      tos: boolean
    ) => {
      try {
        if (!stripe || !elements) {
          showToast('Stripe module is not available', 'negative')
          throw new Error('Stripe module is not available')
        }

        // We must always use the existing setup intent pending token, and create a new one if it doesn't exist
        let localPendingSCAToken = pendingSCAToken
        if (!localPendingSCAToken || !isSetupIntent(localPendingSCAToken)) {
          const { data } = await createSetupIntent({
            variables: {
              input: {
                customerId,
              },
            },
          })

          localPendingSCAToken = data?.createSetupIntent?.pendingScaToken
        }

        const cardElement = elements.getElement(CardNumberElement)

        if (!localPendingSCAToken) {
          throw new Error('Error creating setup intent')
        }

        const response = await stripe.confirmCardSetup(localPendingSCAToken, {
          payment_method: {
            card: cardElement!,
          },
        })

        // Currently, this case will happen only if the user fails VAT ID validation in the useSubscribeCustomerToPlanMutation
        const paymentMethod =
          response.setupIntent?.payment_method ||
          response.error?.setup_intent?.payment_method

        if (response && response.error && !paymentMethod) {
          showToast(
            response.error.message ||
              'An error has occurred, try again or use a different credit card.',
            'negative'
          )
          return response.error
        }

        if (!paymentMethod)
          throw new Error('Error creating your payment method')

        const { plan, billing, editors, discountCode } = state
        const { name, email, address, cardName, taxId, ...otherBilling } =
          billing

        const { data, error } = await subscribe({
          variables: {
            input: {
              customerId,
              planId: plan.id,
              paymentSource: paymentMethod as string,
              tosAgreed: tos,
              promotionCode: discountCode || null,
              billingDetails: {
                name,
                email,
                taxId,
                address: {
                  line1: address,
                  ...otherBilling,
                },
              },
              seats: editors,
            },
          },
        })

        if (error) {
          throw new Error('Error attaching the Credit Card to your account')
        }

        // When subscribing, we should ask the user to confirm any pending token that might be generated.
        // By default, a new Setup Intent is created when the subscription's default payment method is updated
        const paymentDetails = data?.subscribeCustomerToPlan?.customer
          ?.paymentDetails as CreditCardPaymentDetails

        if (
          data?.subscribeCustomerToPlan?.pendingScaToken &&
          paymentDetails?.id
        ) {
          const resp = await confirmPendingSetupIntent(
            stripe,
            data?.subscribeCustomerToPlan?.pendingScaToken,
            paymentDetails?.id
          )

          // If there's an error at this point, we should redirect the user anyway, and let him fix it from the Billing tab
          if (resp?.error) {
            showToast(
              resp.error.message ||
                'An error has occurred, try again or use a different credit card.',
              'negative'
            )
            return
          }
        }

        cachedListeners.current?.onComplete()
      } catch (e) {
        const error = castError(e)
        // We wanna make sure the onError listener is called for
        // all errors occurred in this call
        cachedListeners.current?.onError?.(error)

        // But we also wanna propagate the errors to the
        // function callers because they might wanna do some
        // specific handling like Inline field error
        throw error
      }
    }

    return subscribeCustomerWithPMId
  }, [
    elements,
    stripe,
    showToast,
    subscribe,
    createSetupIntent,
    pendingSCAToken,
  ])

  return [subscribeCustomer, data] as const
}
