import React from 'react'

import { Button, Modal, ModalInjectedProps } from '@sketch/components'
import {
  GetProjectsDocument,
  GetProjectsQueryVariables,
  useUnarchiveProjectMutation,
} from '@sketch/gql-types'
import { useToast } from '@sketch/toasts'
import { removeFromPaginated, removeQuery } from '@sketch/modules-common'
import { useEventDispatch } from '@sketch/utils'

interface UnarchiveProjectModalProps extends ModalInjectedProps {
  projectIdentifier: string
  workspaceIdentifier: string
  projectName: string
}

const UnarchiveProjectModal = (props: UnarchiveProjectModalProps) => {
  const { hideModal, projectIdentifier, projectName, workspaceIdentifier } =
    props
  const { showToast } = useToast()
  const dispatchSharesRefresh = useEventDispatch('workspaceShareRefresh')

  const [unarchiveProject, { loading }] = useUnarchiveProjectMutation({
    variables: { projectIdentifier },
    onError: 'show-toast',
    onCompleted: () => {
      showToast(`“${projectName}” unarchived`)
      hideModal()
    },
    update: cache => {
      // Remove the project from the project from the archived listing
      removeFromPaginated(
        cache,
        { __typename: 'Project', identifier: projectIdentifier },
        key => key.includes('projects')
      )

      // Remove from cache every query related with this workspace shares
      // This way the view will be refetched from scratch if needed to make sure
      // we always get the correct server information
      //
      // "key.includes('"archived":"ARCHIVED"')"
      // We also remove the archived listing to make sure it's refreshed
      removeQuery(
        cache,
        key =>
          key.includes(workspaceIdentifier) &&
          (key.includes('.shares') || key.includes('"archived":"ARCHIVED"'))
      )

      // Force any visible query to refresh
      dispatchSharesRefresh({
        workspaceIdentifier,
        projectIdentifier,
      })
    },
    refetchQueries: () => [
      {
        query: GetProjectsDocument,
        variables: {
          workspaceId: workspaceIdentifier,
        } as GetProjectsQueryVariables,
      },
    ],
  })

  return (
    <Modal onCancel={hideModal}>
      <Modal.Header>Unarchive Project?</Modal.Header>
      <Modal.Body>
        Unarchiving “{projectName}” will send it back to your projects.
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={hideModal}>Cancel</Button>
        <Button
          variant="primary"
          onClick={() => unarchiveProject()}
          loading={loading}
        >
          Unarchive
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default UnarchiveProjectModal
