import React from 'react'

import { useCreateCollection } from '../../operations'

import { ModalInjectedProps } from '@sketch/components'

import { ProjectFragment, useGetShareListItemQuery } from '@sketch/gql-types'
import CollectionModalContent, { FormProps } from '../CollectionModalContent'
import { ErrorHandler } from '@sketch/tracing'

interface CreateCollectionModalProps extends ModalInjectedProps {
  project: ProjectFragment
  workspaceIdentifier: string
  shareIdentifier?: string
}

export const CreateCollectionModal = (props: CreateCollectionModalProps) => {
  const { project, workspaceIdentifier, shareIdentifier, hideModal } = props

  const { data, loading: isLoadingShare } = useGetShareListItemQuery({
    variables: {
      shareIdentifier: shareIdentifier ?? '',
    },
    skip: !shareIdentifier,
    onError: () => {
      ErrorHandler.shouldNeverHappen(
        'Failed to fetch share used to create a new collection'
      )
    },
  })

  const { loading: isLoadingCreateCollection, createCollection } =
    useCreateCollection({
      projectIdentifier: project.identifier,
      workspaceIdentifier,
      hideModal,
    })

  const handleSubmit = (values: FormProps) => {
    createCollection({
      variables: {
        projectIdentifier: project.identifier,
        name: values.name,
        description: values.description ?? '',
        shareIdentifiers: values.selectAll ? [] : (values.selectedShares ?? []),
        addAllProjectShares: values.selectAll,
      },
    })
  }

  const createCollectionFromShare = data?.share ? [data.share] : []
  const isLoading = isLoadingCreateCollection || isLoadingShare

  return (
    <CollectionModalContent
      title="Create a Collection"
      onSubmit={handleSubmit}
      collectionShares={createCollectionFromShare}
      project={project}
      isLoading={isLoading}
      hideModal={hideModal}
    />
  )
}

export default CreateCollectionModal
