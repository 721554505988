import React from 'react'
import { Dropdown } from '@sketch/components'

import {
  useAnnotationOverlayContext,
  useHideAnnotationDots,
  usePlaceDraftAnnotation,
} from '../../hooks'
import { useNotificationSubscription } from '../../operations'
import { useGetActiveSidebarTab } from '../../../shares/components/ShareSidebarTabContext'
import {
  ArtboardDetailInfoFragment,
  FrameDetailInfoFragment,
} from '@sketch/gql-types'

const HIDE_ANNOTATION_DOTS_COPY = {
  false: 'Hide Comments',
  true: 'Show Comments',
} as const

interface AnnotationDropdownActionsProps {
  shareIdentifier: string
  subscriptionStatus: string
  frameGroup?: ArtboardDetailInfoFragment | FrameDetailInfoFragment
}

export const AnnotationDropdownActions = (
  props: AnnotationDropdownActionsProps
) => {
  const { shareIdentifier, frameGroup, subscriptionStatus } = props

  const annotationContext = useAnnotationOverlayContext()
  const [placeAnnotation, setPlaceAnnotation] = usePlaceDraftAnnotation() || []
  const [hideAnnotations, setHideAnnotations] = useHideAnnotationDots() || []
  const updateNotificationStatus = useNotificationSubscription()
  const activePanel = useGetActiveSidebarTab()

  // When the Inspect panel is active, we don't want to show some comment
  // related options
  const isInspectPanelActive = activePanel === 'Inspect'

  if (hideAnnotations === undefined) {
    return null
  }

  const canAddComment =
    !isInspectPanelActive &&
    !placeAnnotation &&
    setPlaceAnnotation &&
    annotationContext?.isViewingLatestVersion

  return (
    <>
      {!isInspectPanelActive && annotationContext?.isViewingLatestVersion && (
        <Dropdown.Item
          disabled={!annotationContext?.isViewingLatestVersion}
          onClick={() => setHideAnnotations?.(!hideAnnotations)}
        >
          {HIDE_ANNOTATION_DOTS_COPY[`${hideAnnotations}` as const]}
          <Dropdown.Item.ShortcutText>Shift N</Dropdown.Item.ShortcutText>
        </Dropdown.Item>
      )}

      {canAddComment && (
        <Dropdown.Item
          onClick={event => {
            /**
             * We are stopping the propagation of this event
             * to prevent it from the "DraftAnnotationNotifier" onClick catching it and
             * disabling the place annotation mode right away
             */
            event.stopPropagation()
            setPlaceAnnotation(true)
          }}
        >
          Add a comment
          <Dropdown.Item.ShortcutText>N</Dropdown.Item.ShortcutText>
        </Dropdown.Item>
      )}

      {subscriptionStatus !== 'ON' && (
        <Dropdown.Item
          onClick={() => {
            updateNotificationStatus({
              shareIdentifier,
              frameGroup,
              state: 'ON',
            })
          }}
        >
          Follow Comments
        </Dropdown.Item>
      )}

      {subscriptionStatus === 'ON' && (
        <Dropdown.Item
          onClick={() => {
            updateNotificationStatus({
              shareIdentifier,
              frameGroup,
              state: 'OFF',
            })
          }}
        >
          Unfollow Comments
        </Dropdown.Item>
      )}
    </>
  )
}
