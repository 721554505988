import { useEffect } from 'react'
import {
  WorkspaceMinimalFragment,
  useGetDocumentListSettingsQuery,
} from '@sketch/gql-types'

/**
 * This hook is used to force the header filter "Include documents in projects"
 * to be checked when the workspace is inactive.
 *
 * @param workspaceStatus The status of the workspace
 */
export const useForceIncludeDocsInProjects = (
  workspaceStatus: WorkspaceMinimalFragment['status']
) => {
  const isWorkspaceInactive = workspaceStatus === 'INACTIVE'
  const { updateQuery } = useGetDocumentListSettingsQuery()

  // If workspace is inactive we force the header filter
  // "Include documents in projects" to be checked, this way the user can see
  // all documents even if there are docs inside nested projects (a user can't
  // expand nested projects in an inactive workspace)
  useEffect(() => {
    if (isWorkspaceInactive) {
      updateQuery(previous => ({
        ...previous,
        showDocumentsInProjects: true,
      }))
    }
  }, [isWorkspaceInactive, updateQuery])
}
