import React from 'react'

import { useSearchFilters } from '../../hooks/useSearchFilters'

import { Button, LinkButton } from '@sketch/components'
import { OpenInMacButton, routes } from '@sketch/modules-common'
import { isMac } from '@sketch/utils'

import EmptyState from '../EmptyState'
import RenewSubEmptyState from '../RenewSubEmptyState'
import DocumentListEmptyState from '../DocumentListEmptyState'

import {
  GetWorkspaceUploadsRestrictedForQuery,
  WorkspaceMinimalFragment,
} from '@sketch/gql-types'

type UploadsRestrictedForType =
  GetWorkspaceUploadsRestrictedForQuery['workspace']['uploadsRestrictedFor']

const EMPTY_STATE_COPY = {
  DEFAULT: {
    title: 'Your Workspace hasn’t created anything yet',
    description: 'Only Editors can create documents.',
    icon: 'document',
  },
  DEFAULT_EDITOR: {
    title: 'Your Workspace hasn’t created anything yet',
    description: 'Create and upload a document in Sketch to see it here.',
    icon: 'document',
  },
  TEMPLATE: {
    title: 'No templates added yet',
    description:
      "To change your document into a template, go to the document's settings and select Set As: Template. You can also add Sketch’s free templates here too. ",
    icon: 'template',
    editorialContent: 'empty-state-templates',
    actions: (
      <Button
        buttonStyle="primary-32"
        as="a"
        href="https://www.sketch.com/profile/sketch-templates/"
        rel="noopener noreferrer"
        target="_blank"
      >
        View Sketch Templates
      </Button>
    ),
  },
  SHARED_WITH_ME: {
    title: 'No documents shared with you yet',
    description:
      'When somebody else in the Workspace shares a Sketch document with you via email or mention, you’ll see it here.',
    icon: 'person-circle',
    editorialContent: 'empty-state-shared-with-me',
  },
  LIBRARIES: (workspaceId: string) =>
    ({
      title: 'No Libraries added yet',
      description:
        'To change your document into a Library, go to the document’s settings and select Set As: Library.',
      icon: 'link',
      editorialContent: 'empty-state-libraries',
      actions: (
        <LinkButton
          buttonStyle="primary-32"
          to={routes.WORKSPACE_SHARES.create({ workspaceId })}
        >
          View All Documents
        </LinkButton>
      ),
    }) as const,
  LIBRARIES_EDITOR: (workspaceId: string) =>
    ({
      title: 'No Libraries added yet',
      description:
        'To change your document into a Library, go to the document’s settings and select Set As: Library.',
      icon: 'link',
      editorialContent: 'empty-state-libraries',
      actions: isMac() ? (
        <>
          <OpenInMacButton />
          <LinkButton
            buttonStyle="secondary-32"
            to={routes.WORKSPACE_SHARES.create({ workspaceId })}
          >
            View All Documents
          </LinkButton>
        </>
      ) : (
        <LinkButton
          buttonStyle="primary-32"
          to={routes.WORKSPACE_SHARES.create({ workspaceId })}
        >
          View All Documents
        </LinkButton>
      ),
    }) as const,
  LIBRARIES_GUEST: {
    title: 'No Libraries',
    description: 'No Libraries added yet.',
    icon: 'link',
    editorialContent: 'empty-state-libraries',
  },
  NO_PROJECT: {
    title: 'Nothing to see here',
    description: 'There are no documents to show here.',
    icon: 'document',
  },
  ARCHIVE_PROJECTS: {
    title: 'No archived projects yet',
    description: 'Choose a project, open the menu and select Archive.',
  },
  ALL_DOCUMENTS_EDITOR: {
    title: 'No documents in the Workspace yet',
    description:
      'Create and save a document in the Mac app to see it here — or start by browsing our free templates.',
    icon: 'document',
    actions: (
      <>
        {isMac() ? <OpenInMacButton /> : null}
        <Button
          buttonStyle="secondary-32"
          as="a"
          href="https://www.sketch.com/profile/sketch-templates/"
          rel="noopener noreferrer"
          target="_blank"
        >
          View Sketch Templates
        </Button>
      </>
    ),
  },
} as const

type GetEmptyStateCopy<E extends keyof typeof EMPTY_STATE_COPY> =
  (typeof EMPTY_STATE_COPY)[E]

/**
 * getEmptyStateCopy
 *
 * Validate that the empty state type is correct.
 * when the update to typescript 4.9 occurs we could use the "satisfies"
 */
const getEmptyStateCopy = <E extends keyof typeof EMPTY_STATE_COPY>(
  filter: E
): GetEmptyStateCopy<E> => EMPTY_STATE_COPY[filter]

interface ShareEmptyStateProps {
  filter:
    | 'LIBRARY'
    | 'NO_PROJECT'
    | 'SHARED_WITH_ME'
    | 'TEMPLATE'
    | 'ARCHIVE_PROJECTS'
    | null
  search?: string
  workspace: WorkspaceMinimalFragment
  workspaceHasDocuments?: boolean | null
  uploadsRestrictedFor: UploadsRestrictedForType
}

export const ShareEmptyState: React.FC<ShareEmptyStateProps> = ({
  filter,
  search,
  workspace,
  workspaceHasDocuments,
  uploadsRestrictedFor,
}) => {
  const { userCanEdit, userRole, status } = workspace
  const { filters } = useSearchFilters()

  if (status === 'INACTIVE' && [null, 'NO_PROJECT'].includes(filter)) {
    return <RenewSubEmptyState workspace={workspace} />
  }

  if (search || filters.length > 0) {
    return (
      <EmptyState
        icon="search"
        title="No search results"
        description="No document or Editor names matched your search."
        search={search}
        filters={filters}
      />
    )
  }

  if (filter === 'LIBRARY' && userRole === 'GUEST') {
    return <DocumentListEmptyState {...getEmptyStateCopy('LIBRARIES_GUEST')} />
  }

  if (filter === 'LIBRARY' && userCanEdit) {
    return (
      <DocumentListEmptyState
        {...getEmptyStateCopy('LIBRARIES_EDITOR')(workspace.identifier)}
      />
    )
  }

  if (filter === 'LIBRARY') {
    return (
      <DocumentListEmptyState
        {...getEmptyStateCopy('LIBRARIES')(workspace.identifier)}
      />
    )
  }

  if (filter === 'ARCHIVE_PROJECTS') {
    return <DocumentListEmptyState {...getEmptyStateCopy('ARCHIVE_PROJECTS')} />
  }

  if (filter === 'SHARED_WITH_ME') {
    return <DocumentListEmptyState {...getEmptyStateCopy('SHARED_WITH_ME')} />
  }

  if (filter === 'TEMPLATE') {
    return <DocumentListEmptyState {...getEmptyStateCopy('TEMPLATE')} />
  }

  if (filter === 'NO_PROJECT') {
    return <DocumentListEmptyState {...getEmptyStateCopy('NO_PROJECT')} />
  }

  if (filter === null && userCanEdit) {
    return (
      <DocumentListEmptyState {...getEmptyStateCopy('ALL_DOCUMENTS_EDITOR')} />
    )
  }

  /**
   * If empty state is not handled by any of the filters above
   * then we resolve to a default one
   */

  const emptyStateProps = userCanEdit
    ? getEmptyStateCopy('DEFAULT_EDITOR')
    : getEmptyStateCopy('DEFAULT')

  return <DocumentListEmptyState {...emptyStateProps} />
}

export default ShareEmptyState
