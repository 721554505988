/* eslint-disable no-console */
declare global {
  interface Window {
    debug: Debug
    safari: boolean
  }
}

enum LogMode {
  off = 'off',
  memory = 'memory',
  console = 'console',
}

type Logger = (message?: any, ...optionalParams: any[]) => void

class Debug {
  private static getLogMode = () => {
    const defaultMode = () => {
      switch (process.env.REACT_APP_ENV) {
        case 'production':
        case 'test':
          return LogMode.off

        default:
          return LogMode.memory
      }
    }

    try {
      switch (localStorage.getItem('debug.logMode')) {
        case 'off':
          return LogMode.off
        case 'memory':
          return LogMode.memory
        case 'console':
          return LogMode.console

        default:
          return defaultMode()
      }
    } catch {
      return LogMode.memory
    }
  }

  readonly messages: any[] = []
  readonly logMode = Debug.getLogMode()

  constructor(readonly logger: Logger = console.log) {
    this.logger = logger || console.log
  }

  public log: Logger = (message, ...optionalParams) => {
    const mode = this.logMode

    if (mode === LogMode.off) {
      return
    }

    if (mode === LogMode.memory || mode === LogMode.console) {
      this.messages.push([message, ...optionalParams])
    }

    if (mode === LogMode.console) {
      this.logger(message, ...optionalParams)
    }
  }
}

const debug = new Debug()
window.debug = debug
const log = debug.log

export { LogMode, Debug, log }
