import React from 'react'

import {
  AvatarStack,
  PersonAvatar,
  Tooltip,
  Separator,
} from '@sketch/components'

import {
  AvatarTitle,
  AvatarTooltipWrapper,
  AvatarTooltipContent,
  PersonName,
} from './DocumentPresenceCreatorAvatars.styles'

import * as Presence from '../../../presence/types'

interface DocumentPresenceCreatorAvatarsProps {
  avatars: AvatarItem[]
  isEditing: boolean
  showTooltipText?: boolean
}

export interface AvatarItem {
  isMe: boolean
  name: string
  displayName: string
  avatar: string
  identifier: string
  action: Presence.Action
}

const PresenceAvatar: React.FC<{ src: string; name: string }> = props => {
  const { src, name } = props
  return <PersonAvatar size="16px" name={name} flavour="image" src={src} />
}

const PresenceAvatarList: React.FC<{
  title: string
  avatars: AvatarItem[]
}> = props => {
  const { title, avatars } = props
  return (
    <>
      <AvatarTitle>{title}</AvatarTitle>
      {avatars.map(({ identifier, displayName: name, avatar }) => (
        <AvatarTooltipWrapper key={identifier}>
          <PresenceAvatar src={avatar} name={name} />
          <PersonName>{name}</PersonName>
        </AvatarTooltipWrapper>
      ))}
    </>
  )
}

const PresenceTooltip: React.FC<{ avatars: AvatarItem[] }> = props => {
  const { avatars } = props
  const viewers = avatars.filter(({ action }) => action === 'viewing')
  const editors = avatars.filter(({ action }) => action === 'editing')

  const content = (
    <AvatarTooltipContent>
      {editors.length > 0 && (
        <PresenceAvatarList title="EDITING" avatars={editors} />
      )}
      {editors.length > 0 && viewers.length > 0 && <Separator />}
      {viewers.length > 0 && (
        <PresenceAvatarList title="VIEWING" avatars={viewers} />
      )}
    </AvatarTooltipContent>
  )

  return <AvatarStackTooltip avatars={avatars} content={content} />
}

const CreatorTooltip: React.FC<{ avatars: AvatarItem[] }> = props => {
  const { avatars } = props
  const content = (
    <PresenceAvatarList title="LAST UPDATED BY" avatars={avatars} />
  )

  return <AvatarStackTooltip avatars={avatars} content={content} />
}

const AvatarStackTooltip: React.FC<{
  avatars: AvatarItem[]
  content: JSX.Element
}> = props => {
  const { avatars, content } = props

  return (
    <Tooltip
      placement="bottom-start"
      content={content}
      spacing="3px"
      scrollable
    >
      <AvatarStack size="16px" totalCount={avatars.length}>
        {avatars.map(({ identifier, displayName, avatar }) => (
          <PresenceAvatar key={identifier} src={avatar} name={displayName} />
        ))}
      </AvatarStack>
    </Tooltip>
  )
}

const DocumentPresenceCreatorAvatars: React.FC<
  DocumentPresenceCreatorAvatarsProps
> = props => {
  const { avatars, isEditing } = props

  if (isEditing) {
    return <PresenceTooltip avatars={avatars} />
  } else {
    return <CreatorTooltip avatars={avatars} />
  }
}

export default DocumentPresenceCreatorAvatars
