import React from 'react'

import { Dropdown, useModalContext } from '@sketch/components'
import { MoveStorageItemModal } from '../../../storageItems'
import type { ShareForShareDropdown } from './ShareActionsDropdown'

interface MoveShareActionProps {
  share: ShareForShareDropdown
  workspaceIdentifier: string
}

export const MoveShareAction = (props: MoveShareActionProps) => {
  const { share, workspaceIdentifier } = props
  const { showModal } = useModalContext()

  return (
    <Dropdown.Item
      onClick={() =>
        showModal(MoveStorageItemModal, {
          item: share,
          workspaceIdentifier,
          currentProjectIdentifier: share.project?.identifier || null,
        })
      }
    >
      Move To&hellip;
    </Dropdown.Item>
  )
}
