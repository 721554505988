import React from 'react'
import styled, { css } from 'styled-components'

import {
  Caption,
  Truncate,
  Dropdown,
  navbarTitleCss,
  Pill,
  SsoTag,
} from '@sketch/components'
import { breakpoint } from '@sketch/global-styles'

export const WorkspaceName = styled(Truncate)<{
  $isSignedOutLabelVisible?: boolean
}>`
  line-height: ${({ theme }) => theme.lineHeights.default};
  text-align: left;
  font-size: ${({ theme }) => theme.fontSizes.D};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  max-width: ${({ $isSignedOutLabelVisible }) =>
    $isSignedOutLabelVisible ? '72px' : '100%'};
`

export const SelectedWorkspaceName = styled(WorkspaceName)`
  font-size: ${({ theme }) => theme.fontSizes.E};

  ${breakpoint('base', 'xxs')`
    display: none;
  `}
`

export const DropdownContentContainer = styled.div`
  clip-path: inset(0px 0px 0px 0px round 12px);
  max-height: 80vh;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 8px 0;
  padding-bottom: 0;

  ${breakpoint('sm')`
  max-width: 320px;
  margin-bottom: 8px;
  `}
`

// Workspace selector trigger
export const DropdownInnerContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  border: 2px solid transparent;
  border-radius: 6px;

  [data-testid='workspace-logo'] {
    margin-right: 0;
  }
`

export const DropdownTarget = styled.button.attrs({
  'data-testid': 'navbar-title',
})(
  ({ theme }) => css`
    ${navbarTitleCss};

    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0;
    padding: 0;
    background: none;
    outline-offset: -2px; /* To avoid cutting focus ring */

    ${WorkspaceName} {
      ${breakpoint('sm')`
        flex: 1;
      `}
    }
  `
)

export const DropdownDivider = styled(Dropdown.Divider)`
  margin: 8px 0;
  width: 100%;
`

export const DropdownHeader = styled(Dropdown.Header)`
  margin-left: 20px;
`

export const DropdownChevrons = styled(Dropdown.Chevrons)`
  margin-left: auto;
  flex-shrink: 0;
  color: ${({ theme }) => theme.colors.foreground.secondary.D};
`

interface ExpiredProps {
  $expired?: boolean
}

const Label = styled(Caption)<ExpiredProps>`
  ${({ $expired, theme }) => css`
    font-size: ${theme.fontSizes.C};
    text-align: end;
    color: unset;
    opacity: ${$expired ? 1 : 0.45};
    margin: 0 0 0 8px;

    a:not(.active) & {
      color: ${theme.colors.foreground.secondary.D};
    }
  `}
`

// Resetting line-height as in mobile it's not aligned with
// Expired label
export const StyledSsoTag = styled(SsoTag)`
  && {
    line-height: 1;
  }
`

const ExpiredLabel = ({ className }: { className?: string }) => (
  <Label className={className} $expired={true}>
    Expired
  </Label>
)

// This tweak is because is inheriting somewhere padding-bottom: 20px;
// and in mobile is very noticeable
export const StyledExpiredLabel = styled(ExpiredLabel)`
  && {
    padding-bottom: 0;
    margin-left: 8px;
  }
`

export const SignedOutLabel = ({
  expired,
  className,
}: {
  expired: boolean
  className?: string
}) => (
  <Label className={className} expired={expired}>
    Signed out
  </Label>
)

export const DropdownLinkItem = styled(Dropdown.Item.NavLink)<ExpiredProps>(
  ({ theme, $expired }) => css`
    user-select: none;
    display: flex;
    align-items: center;
    font-size: ${theme.fontSizes.D};
    font-weight: ${theme.fontWeights.medium};

    ${WorkspaceName} {
      opacity: ${$expired ? 0.45 : 1};
    }
  `
)

export const DropdownLinkItemMainContent = styled.div`
  display: flex;
  align-items: center;
  /* Prevent overflow */
  min-width: 0;
  flex-grow: 1;
  pointer-events: none;

  ${breakpoint('sm')`
    pointer-events: auto;
  `};
`

export const DrodpwonItemSubtitle = styled.div(
  ({ theme }) => css`
    font-size: ${theme.fontSizes.C};
    color: ${theme.colors.foreground.secondary.D};
  `
)

export const CreateWorkspaceButtonWrapper = styled.div(
  ({ theme }) => css`
    position: sticky;
    bottom: 0;
    background: ${theme.colors.background.secondary.A};
  `
)

export const StyledTrialPill = styled(Pill)`
  margin-left: ${({ theme }) => `${theme.space[2]}px`};
  flex-shrink: 0;
`
