import React from 'react'

import { PublicationThumbnailSkeleton } from '../PublicationThumbnail'
import { Container, NameSkeleton } from './GridPublicationItem.styles'

export interface GridPublicationItemPlaceholderProps {
  className?: string
}

export const GridPublicationItemPlaceholder: React.FC<
  GridPublicationItemPlaceholderProps
> = ({ className, children }) => {
  return (
    <Container className={className}>
      {children}
      <PublicationThumbnailSkeleton />
      <NameSkeleton />
    </Container>
  )
}
