import { Spinner, ErrorMessage } from '@sketch/components'
import {
  ProjectFragment,
  ShareInfoFragment,
  useGetShareQuery,
} from '@sketch/gql-types'
import React, { useRef } from 'react'

import {
  RemoveShareFromProject,
  RemoveShareFromProjectProps,
} from './RemoveShareFromProject'
import { RemoveShareFromProjectLayout } from './RemoveShareFromProjectLayout'

export interface RemoveShareFromProjectModalProps
  extends OmitSafe<RemoveShareFromProjectProps, 'share' | 'project'> {
  share: Pick<ShareInfoFragment, 'identifier'>
}

export const RemoveShareFromProjectModal: React.FC<
  RemoveShareFromProjectModalProps
> = props => {
  const { share: existingShare, ...rest } = props
  const projectRef = useRef<ProjectFragment | null>(null)

  const title = `Remove Document from …`
  const { hideModal } = rest
  const { loading, error, data } = useGetShareQuery({
    variables: { shortId: props.share.identifier },
  })

  if (loading) {
    return (
      <RemoveShareFromProjectLayout
        hideModal={hideModal}
        title={title}
        button={{ disabled: true, loading: false }}
      >
        <Spinner.Flex primary />
      </RemoveShareFromProjectLayout>
    )
  }

  const share = data?.share

  // Once the mutation will be performed, we'll receive project equal to null
  // and we'll still render the modal and we'll still depend that that `project`
  // would be a proper object.
  // However, we'll also close the the modal very quickly, so we can simply memoise the
  // `project` object
  if (share?.project) {
    projectRef.current = share?.project
  }

  if (error || !share || !projectRef.current) {
    return (
      <RemoveShareFromProjectLayout
        hideModal={hideModal}
        title={title}
        button={{ disabled: true, loading: false }}
      >
        <ErrorMessage.Generic />
      </RemoveShareFromProjectLayout>
    )
  }

  return (
    <RemoveShareFromProject
      {...rest}
      share={share}
      project={projectRef.current}
    />
  )
}
