import { useState } from 'react'

export interface HoverEventHandlers {
  onMouseOver: () => void
  onMouseEnter: () => void
  onMouseLeave: () => void
}

export function useHover(): [
  boolean,
  HoverEventHandlers,
  React.Dispatch<React.SetStateAction<boolean>>,
] {
  const [hovered, setHovered] = useState(false)
  const eventHandlers: HoverEventHandlers = {
    onMouseOver: () => setHovered(true),
    onMouseEnter: () => setHovered(true),
    onMouseLeave: () => setHovered(false),
  }

  return [hovered, eventHandlers, setHovered]
}
