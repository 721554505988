import React, {
  createContext,
  Dispatch,
  RefObject,
  SetStateAction,
  ReactNode,
  useState,
  useEffect,
  useContext,
} from 'react'

interface ContainerRefScrollContextValues {
  containerRef: RefObject<HTMLDivElement> | null
  scrollY: number
  isProviderAvailable: boolean
}

const initialContextvalues = {
  containerRef: null,
  scrollY: 0,
  isProviderAvailable: false,
}

const ContainerRefScrollContext = createContext<
  [
    ContainerRefScrollContextValues,
    Dispatch<SetStateAction<ContainerRefScrollContextValues>>,
  ]
>([initialContextvalues, () => {}])

export function ContainerRefScrollProvider({
  children,
}: {
  children: ReactNode
}) {
  const [value, setValue] =
    useState<ContainerRefScrollContextValues>(initialContextvalues)

  return (
    <ContainerRefScrollContext.Provider
      value={[{ ...value, isProviderAvailable: true }, setValue]}
    >
      {children}
    </ContainerRefScrollContext.Provider>
  )
}

/**
 * Saves the scroll position for the given element.
 * Can be used in cases, where the scroll needs to be restored between component
 * transitions.
 */
export function useContainerRefScroll() {
  const [context, setContextValue] = useContext(ContainerRefScrollContext)
  const { containerRef, scrollY } = context

  const setContainerRef = (containerRef: RefObject<HTMLDivElement> | null) => {
    // At this point does not make snese to set the container ref if
    // ContainerRefScrollProvider was not yet initialized.
    // We don't want to throw an error here, because in some cases the
    // containing element can be lazy loaded and only available later.
    if (!context.isProviderAvailable) return

    setContextValue({
      ...context,
      containerRef,
    })
  }

  const saveScrollTop = () =>
    containerRef?.current?.scrollTop !== undefined &&
    setContextValue({ ...context, scrollY: containerRef.current.scrollTop })

  const scrollToElement = () => {
    if (!containerRef?.current) {
      return
    }

    if (scrollY === 0) {
      // Force the initial scroll position to be at the top on initial load
      // (mainly for Safari)
      containerRef.current.scrollTop = 0
    } else {
      containerRef.current.scrollTop = scrollY
    }
  }

  // running the scroll after page updates
  useEffect(() => {
    window.requestAnimationFrame(() => {
      scrollToElement()
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [containerRef])

  return {
    setContainerRef,
    saveScrollTop,
  }
}
