import React from 'react'

import { stripMarkdownParser } from '@sketch/utils'

import NotificationListItemAvatar from './NotificationListItemAvatar'

import {
  NotificationShareName,
  NotificationBody,
  Markdown,
} from '../NotificationListItem.styles'

import { formatAnnotationSubject } from './utils'

import { MentionNotificationFragment } from '@sketch/gql-types'
import { TruncateWithTooltip } from '@sketch/components'

interface MentionNotificationListItemProps {
  notification: MentionNotificationFragment
}

const MentionNotificationListItem: React.FC<
  MentionNotificationListItemProps
> = ({ notification }) => {
  const { share, comment, user } = notification
  const userAvatar = user?.avatar?.small
  let shareName

  // BE is returning `FORBIDDEN` code and `USER_CANT_VIEW_SHARE` reason
  // when the user doesn't have access to comments on Artboards (when the user
  // really doesn't have access to a share the notification is not
  // returned from BE even if the user was mentioned)
  if (share) {
    const name = formatAnnotationSubject(comment?.annotation)
    shareName = (
      <>
        {share?.workspace.name} / {share?.name} {name && <>/ {name}</>}
      </>
    )
  } else {
    shareName = notification.title
  }

  const body = notification?.comment
    ? notification?.comment?.body || ''
    : `${notification?.user?.name} mentioned you`

  return (
    <>
      <NotificationShareName data-testid="notification-share-name">
        <TruncateWithTooltip>{shareName}</TruncateWithTooltip>
      </NotificationShareName>
      <NotificationBody data-testid="notification-body">
        <NotificationListItemAvatar
          avatar={userAvatar ?? ''}
          name={notification.user?.name ?? ''}
        />
        <Markdown parser={stripMarkdownParser}>{body}</Markdown>
      </NotificationBody>
    </>
  )
}

export default MentionNotificationListItem
