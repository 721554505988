import { NetworkStatus } from 'apollo-client'

import { dataIdFromObject } from '@sketch/graphql-cache'

import {
  GetTrashQuery,
  GetTrashQueryVariables,
  ShareListItemFragment,
  TrashedProjectFragment,
  TrashedShareFragment,
  useGetTrashQuery,
} from '@sketch/gql-types'

import { handleFetchMore } from '@sketch/components'
import { ErrorHandler } from '@sketch/tracing'
import { useOnEvent } from '@sketch/utils'

export interface GetTrashProps {
  workspaceId: string
  search?: string
  skip?: boolean
}

const trashItemsPath = ['workspaceTrash', 'entries']

export const useGetTrash = (props: GetTrashProps) => {
  const { workspaceId, search = '' } = props

  const variables = {
    identifier: workspaceId,
    after: null,
    search: {
      name: search,
    },
  }

  const { loading, data, error, fetchMore, refetch, networkStatus } =
    useGetTrashQuery({
      fetchPolicy: 'cache-and-network',
      variables,
    })

  useOnEvent('workspaceTrashRefresh', ({ workspaceIdentifier }) => {
    if (workspaceIdentifier !== workspaceId) {
      // Not the workspace visible ignore then
      return
    }

    refetch()
  })

  const itemsCount = data?.workspaceTrash.entries.length

  if (
    (loading && !data?.workspaceTrash) ||
    // We force the spinner to show when the trash is empty and
    // the "No documents in trash exist" is presented. This makes
    // for a smoother transaction
    //
    // the refetch occurs:
    // - When the trash empty event is triggered
    // - When a document is deleted and the trash list is invalid
    (networkStatus === NetworkStatus.refetch && itemsCount === 0)
  ) {
    return { kind: 'loading' } as const
  }

  if (error) {
    return { kind: 'error', reason: error.message } as const
  }
  if (!data) {
    const reason = 'data should always be truthy in final state' as string
    ErrorHandler.shouldNeverHappen(reason)
    return { kind: 'error', reason } as const
  }

  const { entries, meta } = data.workspaceTrash
  const { totalCount, after } = meta

  const typedEntries = entries as (
    | ShareListItemFragment
    | TrashedShareFragment
    | TrashedProjectFragment
  )[]

  const items = typedEntries.filter(
    ({ __typename }) =>
      __typename === 'Share' ||
      __typename === 'TrashedShare' ||
      __typename === 'TrashedProject'
  ) as (ShareListItemFragment | TrashedShareFragment | TrashedProjectFragment)[]

  return {
    kind: 'data',
    items,
    loading,
    totalCount,
    fetchMore: handleFetchMore<GetTrashQuery, GetTrashQueryVariables>(
      fetchMore,
      trashItemsPath,
      { after, dataIdFromObject }
    ),
  } as const
}
