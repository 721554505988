import { useEffect, useRef } from 'react'
import { useFlag } from '@sketch/modules-common'

import {
  AnnotationCreatedSubscription,
  AnnotationDeletedSubscription,
  AnnotationMovedDocument,
  AnnotationMovedSubscription,
  AnnotationResolvedSubscription,
  AnnotationUnresolvedSubscription,
  useAnnotationCreatedSubscription,
  useAnnotationDeletedSubscription,
  useAnnotationMovedSubscription,
  useAnnotationResolvedSubscription,
  useAnnotationUnresolvedSubscription,
} from '@sketch/gql-types'
import { useVersioning } from '../../versioning'

type OnSubscription =
  | ['created', AnnotationCreatedSubscription]
  | ['deleted', AnnotationDeletedSubscription]
  | ['moved', AnnotationMovedSubscription]
  | ['resolved', AnnotationResolvedSubscription]
  | ['unresolved', AnnotationUnresolvedSubscription]

const useAnnotationSubscriptions = (
  shareIdentifier: string,
  onSubscriptionData?: (...params: OnSubscription) => void
) => {
  const includeHasUnreadComments = useFlag('pages-unread-annotations')
  const variables = { shareIdentifier, includeHasUnreadComments }

  const { isViewingLatestVersion } = useVersioning()

  const onSubscriptionRef = useRef(onSubscriptionData)
  useEffect(() => {
    onSubscriptionRef.current = onSubscriptionData
  }, [onSubscriptionData])

  useAnnotationCreatedSubscription({
    variables,
    onSubscriptionData: ({ subscriptionData }) => {
      subscriptionData.data &&
        onSubscriptionRef.current?.('created', subscriptionData.data)
    },
  })

  useAnnotationDeletedSubscription({
    variables,
    onSubscriptionData: ({ subscriptionData }) => {
      subscriptionData.data &&
        onSubscriptionRef.current?.('deleted', subscriptionData.data)
    },
  })

  useAnnotationMovedSubscription({
    variables,
    fetchPolicy: 'no-cache',
    onSubscriptionData: ({ subscriptionData, client }) => {
      if (!subscriptionData.data) {
        return
      }

      /**
       * If the user is not on the latest version moving the annotation
       * might force the user to be loose the current context, this way
       * we show the warning banner, but clock the object from moving
       */
      if (!isViewingLatestVersion) {
        return
      }

      onSubscriptionRef.current?.('moved', subscriptionData.data)
      client.cache.write<AnnotationMovedSubscription>({
        variables,
        query: AnnotationMovedDocument,
        result: subscriptionData.data,
        dataId: 'ROOT_SUBSCRIPTION',
      })

      client.queryManager.broadcastQueries()
    },
  })

  useAnnotationResolvedSubscription({
    variables: { shareIdentifier },
    onSubscriptionData: ({ subscriptionData }) => {
      subscriptionData.data &&
        onSubscriptionRef.current?.('resolved', subscriptionData.data)
    },
  })

  useAnnotationUnresolvedSubscription({
    variables: { shareIdentifier },
    onSubscriptionData: ({ subscriptionData }) => {
      subscriptionData.data &&
        onSubscriptionRef.current?.('unresolved', subscriptionData.data)
    },
  })
}

export default useAnnotationSubscriptions
