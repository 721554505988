import { useCallback, useEffect, useRef } from 'react'
import { useNotificationSubscription } from '../../annotations/operations'
import { GetArtboardsForDocumentDocument } from '@sketch/gql-types'
import { useToast } from '@sketch/toasts'

interface UseUnfollowShareFromEmailProps {
  shareIdentifier: string
  shareName: string
  versionShortId: string
  subscriptionStatus: 'ON' | 'OFF' | 'PARTIAL'
}

export const useUnfollowShareFromEmail = (
  props: UseUnfollowShareFromEmailProps
) => {
  const { shareIdentifier, shareName, subscriptionStatus, versionShortId } =
    props

  const { showToast } = useToast()
  const updateShareNotification = useNotificationSubscription({
    onCompleted: () => {
      showToast(`No longer following “${shareName}”`)
    },
  })

  /**
   * The following useCallback and useRef logic is
   * only here to make sure the function that is called in the
   * useEffect at the mount state is updated.
   *
   * Why ?
   *
   * Forcing useEffect to have 0 values on the dependencies array causes some issues when
   * calling functions, because the values might be outdated when the function is called.
   *
   * That's why this logic exists, to allow the function to be called on the most up to date
   * state independently of the value of the needed parties
   *
   * Hence why this set of instructions is needed.
   *
   * - UseCallback
   *   Make sure that the called code is always updated and have a easy way to be called
   *   it will also remove the dependencies of the useEffect that updates the ref.
   *
   * - UseRef
   *   This will allow the value to be accessible inside the useEffect without any forcing
   *   a dependency or a warning. The value of useRef is always the same, their internals might
   *   change.
   *
   * - UseEffect
   *   Allow the refs value to be updated if the mutation value given from the use callback changes.
   */
  const mutation = useCallback(() => {
    if (subscriptionStatus === 'OFF') {
      return
    }

    updateShareNotification({ shareIdentifier, state: 'OFF' }, () => [
      {
        query: GetArtboardsForDocumentDocument,
        variables: { identifier: shareIdentifier, versionShortId },
      },
    ])
  }, [
    shareIdentifier,
    versionShortId,
    updateShareNotification,
    subscriptionStatus,
  ])

  const mutationRef = useRef(mutation)
  useEffect(() => {
    mutationRef.current = mutation
  }, [mutation])

  useEffect(() => {
    mutationRef.current()
  }, [])
}
