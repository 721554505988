import React, { useState } from 'react'
import { Button, Modal, ModalInjectedProps } from '@sketch/components'

import { DocumentInfoModalQuery } from './DocumentInfoModalQuery'
import { useShareTypeChangeMutation } from '@sketch/gql-types'
import { matchPath, useLocation } from 'react-router'
import { removeFromPaginated, routes } from '@sketch/modules-common'
import { DataProxy } from 'apollo-cache'
import { useEventDispatch } from '@sketch/utils'
import { useToast } from '@sketch/toasts'
import { ShareWithoutVersion } from '../../../versioning'

export interface DocumentInfoModalProps extends ModalInjectedProps {
  share: Pick<ShareWithoutVersion, 'identifier' | 'shareType'>
  versions: {
    current: { shortId: string }
    latest: { shortId: string }
  }
  workspaceIdentifier: string
}

export const DocumentInfoModal = (props: DocumentInfoModalProps) => {
  const { hideModal, share, versions, workspaceIdentifier } = props

  const { showToast } = useToast()
  const location = useLocation()
  const [shareType, setShareType] = useState(share.shareType)

  const dispatchSharesRefresh = useEventDispatch('workspaceShareRefresh')

  const refetchShares = () => {
    // Refactor this and send an event to listen in the
    // library view when removing the FF
    // https://github.com/sketch-hq/Cloud/issues/14156
    const isLibraryView = matchPath(location.pathname, {
      path: routes.WORKSPACE_LIBRARIES.template(),
      exact: true,
    })

    const isTemplatesView = matchPath(location.pathname, {
      path: routes.WORKSPACE_TEMPLATES.template(),
      exact: true,
    })

    if (!isLibraryView && !isTemplatesView) {
      return []
    }

    dispatchSharesRefresh({ workspaceIdentifier })
  }

  const onUpdateType = (cache: DataProxy, shareSection: string) => {
    // Remove all possible awareness of this Share from the filter query
    removeFromPaginated(
      cache,
      { __typename: 'Share', identifier: share.identifier },
      key => key.includes(`"filter":"${shareSection}"`)
    )

    refetchShares()
  }

  const [updateShareType, { loading }] = useShareTypeChangeMutation({
    variables: { identifier: share.identifier, type: shareType },
    redirectErrors: true,
    onCompleted: () => {
      showToast('Document settings have been updated', 'positive')
      hideModal()
    },
    onError: () => {
      showToast(
        'Unable to update document settings. Please try again later',
        'negative'
      )
    },
    update: (cache, { data }) =>
      onUpdateType(cache, data?.shareUpdate?.share?.shareType as string),
  })

  return (
    <Modal onCancel={hideModal}>
      <Modal.Header>Document Settings</Modal.Header>
      <Modal.Body>
        <DocumentInfoModalQuery
          share={share}
          versions={versions}
          workspaceIdentifier={workspaceIdentifier}
          shareType={shareType}
          handleShareType={setShareType}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={hideModal} disabled={loading}>
          Cancel
        </Button>
        <Button
          variant="primary"
          onClick={() => updateShareType()}
          loading={loading}
        >
          Update
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
