export const INSPECTOR_URL_HASH = '#Inspector'

// Sidebar tab Segments
export const ABOUT_SEGMENT = 'About' as const
export const COMMENT_SEGMENT = 'Comment' as const
export const VERSION_SEGMENT = 'Version' as const
export const INSPECTOR_SEGMENT = 'Inspect' as const

export const SEGMENT_VALUES = [
  ABOUT_SEGMENT,
  COMMENT_SEGMENT,
  VERSION_SEGMENT,
  INSPECTOR_SEGMENT,
]

export type SegmentValues = (typeof SEGMENT_VALUES)[number]

// Panels available for each document related route
export const PANELS_AVAILABLE = {
  documentView: [
    ABOUT_SEGMENT,
    VERSION_SEGMENT,
    INSPECTOR_SEGMENT,
    COMMENT_SEGMENT,
  ],
  pageCanvasView: [
    ABOUT_SEGMENT,
    VERSION_SEGMENT,
    INSPECTOR_SEGMENT,
    COMMENT_SEGMENT,
  ],
  pageLegacyView: [ABOUT_SEGMENT, VERSION_SEGMENT],
  artboardView: [VERSION_SEGMENT, INSPECTOR_SEGMENT, COMMENT_SEGMENT],
  componentsView: [ABOUT_SEGMENT, VERSION_SEGMENT],
  prototypeView: [ABOUT_SEGMENT, VERSION_SEGMENT],
}
