import { createContext } from 'react'

// Usage of AnnotationSortOrder, AnnotationStatusFilter will not break the build
// eslint-disable-next-line no-restricted-imports
import { AnnotationSortOrder } from '@sketch/gql-types/expansive'
import {
  AnnotationStatusFilterWithoutUnread,
  AnnotationSubject,
} from '../../types'

export interface AnnotationQueryVariablesContextValue {
  shareIdentifier: string
  versionIdentifier: string
  subjects: AnnotationSubject[]
  annotationStatus: AnnotationStatusFilterWithoutUnread
  annotationSort: AnnotationSortOrder
}

export const AnnotationQueryVariablesContext =
  createContext<AnnotationQueryVariablesContextValue | null>(null)
