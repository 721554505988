import React from 'react'

import { routes } from '@sketch/modules-common'
import { localStorageKeys } from '@sketch/constants'

import { setStringifiedItem, getParsedItem } from '@sketch/utils'

//Components
import { Modal, ModalInjectedProps } from '@sketch/components'

import {
  SubTitle,
  Columns,
  Column,
  Paragraph,
  Note,
  StyledModal,
  StyledButton,
  StyledLinkButton,
  List,
  Item,
  Checkmark,
} from './WorkspaceWelcomeModal.styles'

interface WorkspaceWelcomeModalProps extends ModalInjectedProps {
  workspaceId: string
}

const WorkspaceWelcomeModal: React.FC<WorkspaceWelcomeModalProps> = ({
  hideModal,
  workspaceId,
}) => {
  const handleHideModal = () => {
    // Grab all dismissed workspace ID's from local storage
    const previouslyDismissedIDs =
      getParsedItem(localStorageKeys.dismissedWelcomeModal) ?? []

    const currentDismissedIDs = [...previouslyDismissedIDs, workspaceId]

    // Set the current workspace ID to the list of dismissed modals
    setStringifiedItem(
      localStorageKeys.dismissedWelcomeModal,
      currentDismissedIDs
    )
    hideModal()
  }

  return (
    <StyledModal onCancel={handleHideModal}>
      <Modal.Body>
        <Columns>
          <Column>
            <SubTitle>Try Sketch for Free</SubTitle>
            <List>
              <Item>
                <Checkmark />
                Everything unlocked for 30 days
              </Item>
              <Item>
                <Checkmark />
                Access our world-class Mac app
              </Item>
              <Item>
                <Checkmark />A Workspace for storing files
              </Item>
              <Item>
                <Checkmark />
                Powerful collaboration tools
              </Item>
            </List>
            <StyledButton
              variant="secondary-untinted"
              onClick={handleHideModal}
              size="40"
            >
              Continue to Workspace
            </StyledButton>
          </Column>
          <Column withBackground>
            <SubTitle>Then Just $10 per Month</SubTitle>
            <Paragraph>
              After your trial ends, subscribe for only $10 per month (billed
              yearly) – or $12 per month (billed monthly).
            </Paragraph>
            <StyledLinkButton
              variant="primary-untinted"
              to={routes.WORKSPACE_SUBSCRIBE.create({
                workspaceId,
              })}
              onClick={handleHideModal}
              size="40"
            >
              Subscribe Now
            </StyledLinkButton>
          </Column>
        </Columns>
        <Note>
          After the trial ends you will no longer have access to Workspaces or
          the Mac app. Expired Workspaces become inactive after 90 days.
        </Note>
      </Modal.Body>
    </StyledModal>
  )
}

export default WorkspaceWelcomeModal
