import React from 'react'
import { useDropzone, DropzoneOptions } from 'react-dropzone'

import { MAX_SIZE } from '@sketch/utils'
import { Tooltip, WorkspaceLogo } from '@sketch/components'

import {
  PreviewContainer,
  Container,
  Dropzone,
  RemoveButton,
  ImageIcon,
  ImageIconWrapper,
} from './UploadWorkspaceLogo.styles'

interface PreviewProps {
  loading: boolean
  workspaceName: string
  imageURL?: string
  onRemove?: () => void
  placeholderIcon?: React.ReactElement
}

const ACCEPTED_FILES = {
  'image/png': [],
  'image/jpeg': [],
  'image/jpg': [],
}

const megabytesToBytes = (n: number): number => n * 1024 * 1024

const Preview: React.FC<PreviewProps> = props => {
  const { loading, workspaceName, imageURL, onRemove } = props

  return (
    <PreviewContainer>
      <WorkspaceLogo workspaceName={workspaceName} src={imageURL} size="64px" />
      <RemoveButton
        onClick={onRemove}
        disabled={!imageURL || loading}
        data-testid="workspace-logo-remove-button"
      />
    </PreviewContainer>
  )
}

interface UploadWorkspaceLogoProps {
  logo?: string
  workspaceName: string
  inputId?: string
  onDrop: (file: File) => Promise<void> | void
  onError?: (message: string) => void
  onRemove?: () => void
}

export const UploadWorkspaceLogo: React.FC<
  UploadWorkspaceLogoProps
> = props => {
  const { logo, workspaceName, inputId, onDrop, onError, onRemove } = props

  const handleOnDrop: DropzoneOptions['onDrop'] = async ([acceptedFile]) => {
    if (!acceptedFile) {
      const maxFileSize = MAX_SIZE / megabytesToBytes(1)

      onError?.(
        `Please upload a png or jpg with a maximum size of ${maxFileSize}MB`
      )
      return
    }

    await onDrop(acceptedFile)
  }

  const { getRootProps, getInputProps, isDragActive, isFocused } = useDropzone({
    accept: ACCEPTED_FILES,
    maxSize: MAX_SIZE,
    onDrop: handleOnDrop,
  })

  return (
    <Container>
      <input id={inputId} {...getInputProps()} />

      {logo && (
        <Preview
          loading={false}
          imageURL={logo}
          workspaceName={workspaceName}
          onRemove={onRemove}
        />
      )}

      <ImageIconWrapper $dark={!!logo || isFocused}>
        <ImageIcon />
      </ImageIconWrapper>

      <Tooltip
        content={logo ? 'Replace Workspace Image' : 'Upload Workspace Image'}
        placement="top"
      >
        {props => (
          <Dropzone
            data-testid="dropzone"
            isDragActive={isDragActive}
            {...getRootProps({ refKey: 'ref' })}
            {...props}
          />
        )}
      </Tooltip>
    </Container>
  )
}
