import React, { useEffect } from 'react'

import { useModalContext, useBanner } from '@sketch/components'

import RestoreProject from '../../modals/RestoreProject'

import { ProjectFragment } from '@sketch/gql-types'

import { BannerButton } from '../../components/ProjectShares/ProjectShares.styles'

interface UseProjectBannerProps {
  project?: Pick<
    ProjectFragment,
    'deletedAt' | 'archivedAt' | '__typename' | 'identifier' | 'name'
  >
  workspaceId: string
}

export const useProjectBanner = ({
  project,
  workspaceId,
}: UseProjectBannerProps) => {
  const { showBanner } = useBanner()
  const { showModal } = useModalContext()

  useEffect(() => {
    if (project?.deletedAt) {
      const { dismissBanner } = showBanner({
        type: 'information',
        message: (
          <>
            The <b>“{project.name}”</b> project was deleted.{' '}
            <BannerButton
              onClick={() => {
                showModal(RestoreProject, { project, workspaceId })
              }}
            >
              Restore the project
            </BannerButton>
            , to edit its documents.
          </>
        ),
      })

      return dismissBanner
    }

    if (project?.archivedAt) {
      const { dismissBanner } = showBanner({
        type: 'information',
        message: (
          <>
            The <b>“{project.name}”</b> project is archived.{' '}
          </>
        ),
      })

      return dismissBanner
    }
  }, [showModal, showBanner, project, workspaceId])
}
