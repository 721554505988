import React, { useEffect, useRef } from 'react'
import { Route, RouteProps, useHistory, useLocation } from 'react-router'
import { Location } from 'history'

import {
  GenericErrorView,
  routes,
  useUserSignedIn,
  useUserAuthorizations,
  useSignOut,
  IndexLayoutOldTitle,
  IndexLayoutOldSubtitle,
} from '@sketch/modules-common'
import { useGetInitialUserQuery } from '@sketch/gql-types'
import { Link, LoadingState, Pill } from '@sketch/components'
import {
  ContinueButton,
  SignOutWrapper,
  UserAvatar,
  UserName,
  Wrapper,
  IndexLayoutContent,
  SwitchAccount,
  InnerUserName,
  InnerUserEmail,
} from './PublicOnlyRoute.styles'
import { useSignInToUnauthorizedWorkspace } from 'cloud-frontend'

type RedirectPath = ((location: Location) => string | undefined) | string

interface PublicOnlyRouteProps extends RouteProps {
  redirectPath?: RedirectPath
}

const getRedirectPath = (location: Location, redirectPath?: RedirectPath) => {
  if (typeof redirectPath === 'function') {
    return redirectPath(location) || routes.ENTRY.create({})
  }

  return redirectPath || routes.ENTRY.create({})
}

const PublicOnlyRoute: React.FC<PublicOnlyRouteProps> = props => {
  const { children, component, render, redirectPath, ...routeProps } = props

  const location = useLocation()
  const history = useHistory()

  const isSignedIn = useUserSignedIn()
  const signOut = useSignOut({
    location,
    reason: 'Error fetching InitialUser',
  })
  const { activeAuthorization } = useUserAuthorizations()
  const { signInToPersonalAccount } = useSignInToUnauthorizedWorkspace()

  // "useSignOut" function is not memoized making it
  // and not ideal usage inside hooks because the
  // function caller changes forcing the action
  // to be called multiple times
  const cachedSignOut = useRef(signOut)
  useEffect(() => {
    cachedSignOut.current = signOut
  }, [signOut])

  const { data, loading, error } = useGetInitialUserQuery({
    skip: !isSignedIn,
    fetchPolicy: 'network-only',
  })

  // We will use the "error" to make sure the profile is loaded
  // because we might have a old version in cache, and that will
  // be the one served
  const hasUserProfile = data?.me?.identifier && !error
  const sessionExpired = !loading && !hasUserProfile

  const componentRendered = useRef(false)

  if (!isSignedIn || sessionExpired || componentRendered.current) {
    componentRendered.current = true
    return <Route {...props} />
  }

  if (isSignedIn && hasUserProfile) {
    const user = data.me!
    const userFirstName = user.name?.split(' ')[0]

    /**
     * If the user is signed-in and has valid credentials
     */

    return (
      <IndexLayoutContent center="horizontal" marginTop paddingHorizontal>
        <IndexLayoutOldTitle>
          Welcome back, <UserName>{userFirstName}</UserName>
        </IndexLayoutOldTitle>

        <IndexLayoutOldSubtitle>
          Continue to your signed-in account, or switch to a different one.
        </IndexLayoutOldSubtitle>

        <Wrapper>
          <UserAvatar size="64px" name={user.name!} src={user.avatar?.small} />
          <InnerUserName textStyle="prominent.primary.E">
            {user.name}
          </InnerUserName>
          <InnerUserEmail textStyle="copy.quaternary.standard.D" as="span">
            {user.email}
            {activeAuthorization?.type === 'sso' && (
              <>
                {' '}
                <Pill variant="secondary">SSO</Pill>
              </>
            )}
          </InnerUserEmail>

          <ContinueButton
            variant="primary"
            to={getRedirectPath(location, redirectPath)}
            fill
          >
            Continue with this account
          </ContinueButton>

          <SwitchAccount
            variant="secondary"
            onClick={() => {
              signInToPersonalAccount({
                ignoreUserProfile: true,
                onCompleted: () => {
                  history.replace(getRedirectPath(location, redirectPath))
                },
              })
            }}
            fill
          >
            Switch account
          </SwitchAccount>
        </Wrapper>

        <SignOutWrapper>
          <Link
            variant="primary"
            size="small"
            isUnderlined
            to={routes.SIGN_OUT.template()}
          >
            Sign out
          </Link>
        </SignOutWrapper>
      </IndexLayoutContent>
    )
  }

  return (
    <Route
      {...routeProps}
      render={() => {
        if (loading) {
          return <LoadingState />
        }

        return <GenericErrorView error="Error on PublicOnlyRoute" isInLayout />
      }}
    />
  )
}

export default PublicOnlyRoute
