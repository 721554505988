import {
  PRFileMarina,
  PRMarinaNode,
  PRMarinaRect,
  safePtrAccess,
} from '@sketch-hq/sketch-web-renderer'
import {
  BlendMode,
  ElementType,
  SketchElement,
  SketchSymbolInstanceLayerElement,
  isFrameElement,
  isGroupElement,
} from '../../../../../../inspector'
import { createSketchElement } from './createSketchElement'

/**
 * Context to share information and access it from any point of the recursion.
 */
type BuildSketchSceneContext = {
  /**
   * The bounds of the container node that can be Page is we are looking at the page view
   * or artboard if we are looking at an individual artboard.
   */
  containerNodeBounds: PRMarinaRect
  /**
   * True when we are inside a maskees node, which means that the children layers are
   * masked by an other layer.
   */
  isInsideMaskeesNode: boolean
  /**
   * When parentSymbolLayerElement exist inside the context, it means that
   * the current element is a child of a symbol instance.
   */
  parentSymbolLayerElement?: SketchSymbolInstanceLayerElement
  /**
   * We treat descendants of locked layers as locked, so this value let's us
   * track that.
   */
  isInsideLockedParent: boolean
  isInsideGroupWithExports: boolean
  isInsideGroupWithStyles: boolean

  /**
   * A relevant artboard we can relate to when showing guides line
   * a parent artboard for example
   */
  artboardAncestorElement?: SketchElement
}

/**
 * Create the tree of layers to match the one in the Sketch app based on the prFile.
 */
export function buildSketchSceneTreeFromPRFile(
  prFile: PRFileMarina
): SketchElement | null {
  /**
   * We are checking if the prFile smart-pointer is still "alive"
   * that it hasn't been disposed in the meanwhile.
   *
   * There's no point on continuing if it has been disposed because it
   * will return errors like:
   *
   * "BindingError: Cannot pass deleted object as a pointer of type"
   *
   * or
   *
   * https://sketch.slack.com/archives/C06D1D3JLBC/p1706701603985239?thread_ts=1706652289.849899&cid=C06D1D3JLBC
   */
  if (!safePtrAccess(prFile)) {
    return null
  }

  const prFileMarinaRootNode = prFile.getRootNode()
  if (!prFileMarinaRootNode) {
    return null
  }

  /** The bounds of the artboard root. */
  const containerNodeBounds = prFileMarinaRootNode.getAbsoluteBounds()

  const rootElement = getSketchElementListToUseAsChildren(
    prFileMarinaRootNode,
    {
      containerNodeBounds,
      isInsideMaskeesNode: false,
      isInsideLockedParent: false,
      isInsideGroupWithExports: false,
      isInsideGroupWithStyles: false,
    }
  )[0]

  return rootElement ?? null
}

/**
 * Function recursively rebuilding the tree of layers like in the Sketch app.
 * Note: The tree of marina node is a mostly used for rendering and contains extra nodes
 * that are not part of the layers created by the designer and therefore we don't want to show
 * in the Sketch elements tree.
 *
 * This function recursively goes through nodes and remove the nodes that are not layers (i.e. sketch elements)
 * and flatten the structure of the mask nodes when needed.
 */
function getSketchElementListToUseAsChildren(
  currentMarinaNode: PRMarinaNode,
  context: BuildSketchSceneContext
) {
  const currentSketchElement = currentMarinaNode.isLayer()
    ? createSketchElement(currentMarinaNode, {
        isMaskedByOtherLayer: context.isInsideMaskeesNode,
        containerNodeBounds: context.containerNodeBounds,
        parentSymbolLayerElement: context.parentSymbolLayerElement,
        isInsideLockedParent: context.isInsideLockedParent,
        isInsideGroupWithExports: context.isInsideGroupWithExports,
        isInsideGroupWithStyles: context.isInsideGroupWithStyles,
        artboardAncestorElement: context.artboardAncestorElement,
      })
    : null

  const shouldKeepNode = Boolean(currentSketchElement)

  // List of sub nodes we still need to visit
  const prMarinaNodeChildrenVector = currentMarinaNode.getChildren()

  // This is the list of Sketch element derived from prMarinaNodeChildrenVector.
  // i.e. the list of sub layers under currentMarinaNode
  let sketchElementsToUseAsChildren: SketchElement[] = []

  const contextForChildren = getContextForChildren(
    context,
    currentMarinaNode,
    currentSketchElement
  )

  for (let i = 0; i < prMarinaNodeChildrenVector.size(); i++) {
    const childPrMarinaNode = prMarinaNodeChildrenVector.get(i)
    if (childPrMarinaNode) {
      // If we are were not skipping node elementsListForChild would always be one element. But because,
      // we can remove nodes, we can inherit multiple children elements from the sub nodes.
      const elementsListForChild = getSketchElementListToUseAsChildren(
        childPrMarinaNode,
        contextForChildren
      )

      // Insert before to get the list in reverse order.
      // The children are reversed because the vector contains the layers in the rendering order,
      // so bottom layer is first. When showing the tree of layers we want to show top layer first
      // like in Sketch app.
      sketchElementsToUseAsChildren = [
        ...elementsListForChild,
        ...sketchElementsToUseAsChildren,
      ]
    }
  }

  if (!shouldKeepNode) {
    // We are skipping the current node that was supposed to be used as a child but
    // we want to keep the potential elements in the sub nodes and attach
    // them directly to the parent instead of to the the current node.
    return sketchElementsToUseAsChildren
  }

  // Typescript can't resolve on it's own that currentSketchElement is defined if we have shouldKeepNode true
  const nonNullableCurrentSketchElement = currentSketchElement as SketchElement

  if (isMaskLayerToFlatten(currentMarinaNode)) {
    /** Flatten the structure of the mask, @see isMaskLayerToFlatten for more details */
    // "Maskee" node children that are layers become siblings of the mask layer
    return [...sketchElementsToUseAsChildren, nonNullableCurrentSketchElement]
  }

  nonNullableCurrentSketchElement.children = sketchElementsToUseAsChildren

  return [nonNullableCurrentSketchElement]
}

/**
 * When a layer was converted to mask, the marina scene node structure can change based
 * on the style of the layer (if layer has border or not). The "Maskees" node containing
 * the nodes of the layers affected by the mask can change position.
 *
 * - In the case where the mask has border, the Maskee node is just a sibling of the mask layer.
 * - In the case where the layer does not have borders, the mask layer becomes the parent of a "Clip" layer which has for children the layers affected by the mask.
 *
 * The latter structure needs to be flatten when representing the layers tree so it is presented
 * like in the Sketch app.
 *
 * With borders (already flattened):
 * Parent
 * |__MyMask
 * |____...
 * |____Clip
 * |__Maskees
 * |____LayerAffectedByMask
 *
 * Without borders (needs to be flattened)):
 * Parent
 * |__MyMask
 * |____...
 * |____Clip
 * |______Maskees
 * |________LayerAffectedByMask
 *
 */
function isMaskLayerToFlatten(prMarinaNode: PRMarinaNode) {
  const clipNodeChild = findInChildren(prMarinaNode, isClipNode)

  if (clipNodeChild) {
    const maskeesNode = findInChildren(clipNodeChild, isMaskeesNode)
    if (maskeesNode) {
      return true
    }
  }

  return false
}

/** Check if the node is the special "Maskees" node. */
function isMaskeesNode(prMarinaNode: PRMarinaNode) {
  return prMarinaNode.getName() === 'Maskees' && !prMarinaNode.isLayer()
}

/** Check if the node is the special "Clip" node. */
function isClipNode(prMarinaNode: PRMarinaNode) {
  return prMarinaNode.getName() === 'Clip' && !prMarinaNode.isLayer()
}

/**
 * Find the first child that matches the predicate
 */
function findInChildren(
  prMarinaNode: PRMarinaNode,
  predicate: (prMarinaNode: PRMarinaNode) => boolean
) {
  const prMarinaNodeChildrenVector = prMarinaNode.getChildren()

  for (let i = 0; i < prMarinaNodeChildrenVector.size(); i++) {
    const childPrMarinaNode = prMarinaNodeChildrenVector.get(i)
    if (childPrMarinaNode && predicate(childPrMarinaNode)) {
      return childPrMarinaNode
    }
  }
}

/**
 * Build the context object that will be passed to children.
 * This function manages dynamic values like isInsideMaskeesNode value
 */
function getContextForChildren(
  context: BuildSketchSceneContext,
  currentMarinaNode: PRMarinaNode,
  currentSketchElement: SketchElement | null
) {
  const contextForChildren = {
    ...context,
  }

  // Update isInsideMaskeesNode if needed
  if (isMaskeesNode(currentMarinaNode)) {
    contextForChildren.isInsideMaskeesNode = true
  } else if (
    contextForChildren.isInsideMaskeesNode === true &&
    currentMarinaNode.isLayer()
  ) {
    // isInsideMaskeesNode should only be valid for 1 layer level:
    // If isInsideMaskeesNode is already true, reset the flag to false,
    // unless the current node is not a layer.
    contextForChildren.isInsideMaskeesNode = false
  }

  if (currentSketchElement?.type === ElementType.SymbolInstance) {
    contextForChildren.parentSymbolLayerElement = currentSketchElement
  }

  if (currentSketchElement && isFrameElement(currentSketchElement)) {
    contextForChildren.artboardAncestorElement = currentSketchElement
    contextForChildren.containerNodeBounds =
      currentMarinaNode.getAbsoluteBounds()
  }

  contextForChildren.isInsideLockedParent =
    contextForChildren.isInsideLockedParent ||
    (currentSketchElement &&
      'isLocked' in currentSketchElement &&
      currentSketchElement.isLocked) ||
    false

  contextForChildren.isInsideGroupWithExports =
    contextForChildren.isInsideGroupWithExports ||
    (currentSketchElement && isGroupLayerWithExports(currentSketchElement)) ||
    false

  contextForChildren.isInsideGroupWithStyles =
    contextForChildren.isInsideGroupWithStyles ||
    (currentSketchElement && isGroupLayerWithStyles(currentSketchElement)) ||
    false

  return contextForChildren
}

function isGroupLayerWithExports(element: SketchElement) {
  if (!isGroupElement(element)) {
    return false
  }

  const exportFormats = element.exportFormats

  return exportFormats.length > 0
}

function isGroupLayerWithStyles(element: SketchElement) {
  if (!isGroupElement(element)) {
    return false
  }

  const style = element.style
  const opacity = style?.appearance?.opacity ?? 1

  const enabledFills = element.style.fills.filter(fill => fill.isEnabled)
  const enabledShadows = element.style.shadows.filter(
    shadow => shadow.isEnabled
  )

  const hasFills = Boolean(enabledFills.length)
  const hasShadows = Boolean(enabledShadows.length)

  const hasOpacityChanged = opacity < 1
  const hasBlendModeChanged = style?.appearance?.blendMode !== BlendMode.Normal

  const isLayerStyled =
    hasFills || hasShadows || hasOpacityChanged || hasBlendModeChanged

  return isLayerStyled
}
