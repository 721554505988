import React from 'react'
import { ColorFormat } from '../../../../../../types'
import copy from './copy'
import { capitalize } from '@sketch/utils'
import {
  Attribute,
  AttributeLabel,
  AttributeList,
  FullCopyAttribute,
  Header,
  SubTitle,
  FillImage,
  GradientAttribute,
  Color,
} from '../../../components'
import { getDirtyAttributes } from '../../../components/Style/DirtyIconTooltip'
import {
  FillType,
  BlendMode,
  Color as ColorType,
  Fill,
  ElementType,
} from '../../../../../../../inspector'

export interface FillsProps {
  fills: Fill[]
  onColorFormatChange: (f: ColorFormat) => void
  colorFormat: ColorFormat
  layerType?: ElementType
  originalValues?: Fill[]
  /**
   *`true` when the data also has at least one item in `tints`
   */
  hasTints?: boolean
}

function getDirtyHeaderAttributes(
  originalValues: Fill[] | undefined,
  currentNumberOfFills: number
) {
  if (!originalValues) {
    return undefined
  }

  // Before we used to have null values or empty objects in the original
  // array of values when there was no fill. This was done so that the number of fills between
  // the original values and the current values was always matching. Now the values are just removed
  // completely  from the original values array so we need to check if the number of items in the array
  // has changed.
  // This is a fix for https://github.com/sketch-hq/Cloud/issues/17932
  const hasExtraFillNewCheck = originalValues.length < currentNumberOfFills

  // This condition is legacy, not sure if this can still happen
  // in the marina data. Keeping it for now as it could come back with
  // presentation data fix https://github.com/sketch-hq/Cloud/issues/17878
  const hasExtraFillOldCheck =
    originalValues.length &&
    (originalValues[0] === null ||
      Object.values(originalValues[0]).length === 0)

  // this indicates that previously the layer style did NOT have fills, but now it does
  if (hasExtraFillNewCheck || hasExtraFillOldCheck) {
    return { originalValue: 'disabled', originalProperty: 'Fill value' }
  }

  return undefined
}

const isBlendModeVisible = (blendMode: BlendMode) =>
  blendMode !== BlendMode.Normal

const renderFill = (
  {
    isEnabled,
    type,
    color,
    gradient,
    patternFillType,
    patternTileScale,
    appearance,
  }: Fill,
  {
    onColorFormatChange,
    colorFormat,
  }: {
    onColorFormatChange: (f: ColorFormat) => void
    colorFormat: ColorFormat
  },
  i: number,
  originalValue?: Fill
) => {
  if (!isEnabled) {
    return null
  }

  const blendMode = appearance?.blendMode
  const opacity = appearance?.opacity ?? 1

  let renderedType = null
  switch (type) {
    case FillType.Color: {
      renderedType = color ? (
        <Color
          {...color}
          onColorFormatChange={onColorFormatChange}
          colorFormat={colorFormat}
          dirtyAttributes={
            originalValue?.color
              ? {
                  originalValue: originalValue?.color as ColorType,
                  originalProperty: 'Color',
                }
              : undefined
          }
        />
      ) : null
      break
    }
    case FillType.Gradient: {
      renderedType = gradient ? (
        <GradientAttribute
          gradient={gradient}
          onColorFormatChange={onColorFormatChange}
          colorFormat={colorFormat}
          parentPropAppearance={appearance}
          dirtyAttributes={
            originalValue?.gradient || originalValue?.appearance
              ? {
                  originalGradientValue: originalValue.gradient,
                  originalParentPropAppearanceValue: originalValue?.appearance,
                }
              : undefined
          }
        />
      ) : null
      break
    }
    case FillType.Pattern: {
      renderedType = patternFillType ? (
        <Attribute display="inline">
          <AttributeLabel>Image</AttributeLabel>
          <FillImage
            opacity={opacity}
            fill={patternFillType}
            sizePercentage={patternTileScale}
          />
        </Attribute>
      ) : null
      break
    }
    default:
      renderedType = null
  }

  return (
    <AttributeList key={`Fill${i}`} data-testid="fill">
      {renderedType}
      {isBlendModeVisible(blendMode) && (
        <FullCopyAttribute
          label="Blend Mode"
          value={capitalize(blendMode)}
          dirtyAttributes={getDirtyAttributes({
            originalValueKey: 'blendMode',
            valueToDisplay: originalValue?.appearance.blendMode,
            originalValues: originalValue,
            labelToDisplay: 'Blend Mode',
          })}
        />
      )}
    </AttributeList>
  )
}

export const Fills: React.FC<FillsProps> = ({
  fills,
  colorFormat,
  onColorFormatChange,
  layerType,
  originalValues,
  hasTints,
}) => {
  // TODO: move this processing to the `useGetInspectorData` once https://github.com/sketch-hq/Cloud/issues/3052 is done
  const reversedFills = [...fills].reverse()
  const reversedOriginalValues = originalValues
    ? [...originalValues].reverse()
    : undefined

  const renderedFills = reversedFills
    .map((fill, index) =>
      renderFill(
        fill,
        { colorFormat, onColorFormatChange },
        index,
        reversedOriginalValues ? reversedOriginalValues[index] : undefined
      )
    )
    .filter(element => element !== null)

  const copySectionValue = copy(reversedFills, colorFormat, layerType)

  const dirtyHeaderAttributes = getDirtyHeaderAttributes(
    originalValues,
    fills.length
  )

  // For older versions (older than 102), we treat fills in groups as tints.
  // Newer mac app versions support Tints for groups, there's a new `tints`
  // field to differentiate it from Fills. So if we have `tints` we know this
  // is the new version and should treat `fills` as `fills`.
  // This is not 100% accurate but it's the best we can do for now.
  const label = hasTints
    ? 'Fills'
    : layerType === ElementType.Group ||
        layerType === ElementType.SymbolInstance
      ? 'Tints'
      : 'Fills'

  return renderedFills.length > 0 ? (
    <>
      <Header
        copyValue={copySectionValue}
        dirtyAttributes={dirtyHeaderAttributes}
      >
        <SubTitle>{label}</SubTitle>
      </Header>
      {renderedFills}
    </>
  ) : null
}
