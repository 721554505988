import React from 'react'

import { ReactComponent as DocumentIcon } from '@sketch/icons/document-64'
import { ReactComponent as FolderIcon } from '@sketch/icons/folder-closed-16'

import { LoadingPlaceholder } from '@sketch/components'

import { getThumbnail } from '../../../shares/ComponentsWebView/utils'

import { ImageElement } from '../../../shares/components/DocumentItem/DocumentItem.styles'
import {
  Container,
  StyledStatusIcon,
  Thumbnail,
  ThumbnailsGrid,
  NoThumbnailWrapper,
  ContainerInner,
  NoItemsIcon,
} from './NestedProjectModalPreview.styles'

import { NestedProjectFragment } from '@sketch/gql-types'
import { isTruthy } from '@sketch/utils'

export interface NestedProjectModalPreviewProps {
  nestedProject: Pick<NestedProjectFragment, 'name' | 'previews'>
}

export const NestedProjectModalPreview = (
  props: NestedProjectModalPreviewProps
) => {
  const { nestedProject } = props

  const previews = nestedProject.previews.slice(0, 4)

  return (
    <Container>
      <ContainerInner>
        {previews.length === 0 ? (
          <NoItemsIcon as={FolderIcon} aria-label="No Image available" />
        ) : (
          <ThumbnailsGrid aria-label={`Project ${nestedProject.name}`}>
            {previews.map(({ name, file }, index) => {
              const thumbnails = (file?.thumbnails || []).filter(isTruthy)

              if (thumbnails.length === 0) {
                return (
                  <NoThumbnailWrapper
                    data-testid="nested-project-no-thumb"
                    key={index}
                  >
                    <StyledStatusIcon
                      key={index}
                      as={DocumentIcon}
                      aria-label="No Image available"
                    />
                  </NoThumbnailWrapper>
                )
              } else {
                return (
                  <Thumbnail
                    key={index}
                    alt={name}
                    customImageElement={ImageElement}
                    src={getThumbnail(thumbnails)}
                    loadingPlaceholder={<LoadingPlaceholder size="16px" />}
                  />
                )
              }
            })}
          </ThumbnailsGrid>
        )}
      </ContainerInner>
    </Container>
  )
}
