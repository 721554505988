import styled from 'styled-components'

import { Button } from '@sketch/components'

export const ButtonPill = styled(Button).attrs({
  variant: 'primary-untinted',
})`
  padding: 8px 10px;
`

export const AvatarWrapper = styled.div`
  margin-right: 8px;
`

export const WorkspaceName = styled.span`
  font-size: ${({ theme }) => theme.fontSizes.E};
  line-height: ${({ theme }) => theme.lineHeights.default};
  color: ${({ theme }) => theme.colors.ui.buttons.foreground};

  &:hover {
    color: ${({ theme }) => theme.colors.ui.buttons.foregroundHover};
  }

  &:active {
    color: ${({ theme }) => theme.colors.ui.buttons.foregroundActive};
  }
`
