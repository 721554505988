import styled, { css } from 'styled-components'
import { TruncateWithTooltip } from '@sketch/components'

const CommentAuthor = styled(TruncateWithTooltip)(
  ({ theme }) => css`
    color: ${theme.colors.foreground.secondary.A};
    font-size: ${theme.fontSizes.D};
    font-weight: ${theme.fontWeights.medium};
    line-height: ${theme.lineHeights.default};
  `
)

export default CommentAuthor
