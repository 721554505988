import styled from 'styled-components'
import { breakpoint } from '@sketch/global-styles'
import { Text } from '../Box'

export const SectionElement = styled(Text.Section).attrs({
  textStyle: 'copy.tertiary.standard.E',
})``

export const TitleAndActionsWrapper = styled.div`
  display: flex;
  margin-bottom: 16px;

  ${breakpoint('base', 'xs')`
    flex-direction: column;
  `}
`

export const Title = styled(Text.H1).attrs({
  textStyle: 'header.primary.G',
})`
  display: flex;
  margin: 0 0 4px;
`

export const TextAndButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: flex-start;

  margin-bottom: 16px;

  ${breakpoint('sm')`
    flex-direction: row;
  `}
`

export const ActionWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 8px 0 0;

  ${breakpoint('sm')`
    margin: 0 0 0 48px;
  `}
`

export const LeftWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 0;
`

export const RightWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex: 1 0;

  ${breakpoint('base', 'xs')`
    justify-content: flex-start;
  `}
`
