import React from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import { Link, Text, Form, ApolloError } from '@sketch/components'

import {
  Panel as StyledPanel,
  InvitedEmail,
  BodyPanel,
  PanelFooter,
  Content,
} from './shared'
import { ParsedError } from '@sketch/graphql-apollo/useMutation'
import {
  IndexLayoutOldTitle,
  routes,
  RedirectBadge,
  Inviter,
  useUserProfile,
  SignInForm,
} from '@sketch/modules-common'
import queryString from 'query-string'

interface SignInAndAcceptMembershipPanelProps {
  invitedEmail?: string
  inviter: Inviter
  resourceName: string
  inviteToken: string
  acceptMembership(): void
  acceptMembershipError?: ParsedError
  acceptMembershipLoading: boolean
}

const SignInAndAcceptMembershipPanel: React.FC<
  SignInAndAcceptMembershipPanelProps
> = ({
  invitedEmail,
  inviter,
  resourceName,
  inviteToken,
  acceptMembership,
  acceptMembershipError,
  acceptMembershipLoading,
}) => {
  const location = useLocation()
  const history = useHistory()
  const query = queryString.parse(location.search)
  const { data: profile } = useUserProfile()

  const userEmail = profile?.me.email

  return (
    <StyledPanel>
      <RedirectBadge
        from={location.pathname}
        documentInvitation={{ inviter, documentName: resourceName }}
      />

      <SignInForm
        email={invitedEmail}
        onCompleted={({ signIn }) => {
          if (signIn.__typename === 'MFACredentials') {
            history.push(routes.VERIFICATION_CODE.create({}), {
              mfaToken: signIn.mfaToken,
              from: routes.SHARE_ACCEPT_INVITE.create({
                query,
              }),
            })
            return
          }

          if (invitedEmail === userEmail) {
            acceptMembership()
          } else {
            history.push(
              routes.SHARE_ACCEPT_INVITE.create({
                query,
              }),
              {
                from: location.pathname,
              }
            )
          }
        }}
      >
        {formProps => (
          <>
            <BodyPanel>
              <IndexLayoutOldTitle>Sign in to Sketch</IndexLayoutOldTitle>
              <Content mb={32}>
                To access the document now, sign in to{' '}
                <InvitedEmail>
                  <strong>{invitedEmail}</strong>
                </InvitedEmail>
                . Alternatively, sign in or{' '}
                <Link
                  to={{
                    pathname: routes.SHARE_ACCEPT_INVITE_SIGN_UP.create({}),
                    search: `?token=${inviteToken}`,
                  }}
                  variant="secondary"
                  isUnderlined
                >
                  sign up
                </Link>{' '}
                with a different email and request access.
              </Content>

              <SignInForm.Fields
                {...formProps}
                forgotPasswordLink={{
                  pathname: routes.SHARE_ACCEPT_INVITE_FORGOT_PASSWORD.create(
                    {}
                  ),
                  search: `?token=${inviteToken}`,
                }}
              />
            </BodyPanel>
            <PanelFooter>
              {acceptMembershipError && (
                <ApolloError
                  style={{ textAlign: 'left' }}
                  my={4}
                  error={acceptMembershipError}
                />
              )}
              {formProps.status && (
                <Form.Error>
                  <Text textAlign="center" mt={0}>
                    {formProps.status}
                  </Text>
                </Form.Error>
              )}
              {formProps?.apiErrors?.messages?.map(
                (message, index) =>
                  message && (
                    <Form.Error key={index}>
                      <Text textAlign="center" mt={0}>
                        {message}
                      </Text>
                    </Form.Error>
                  )
              )}
              <SignInForm.Submit
                isSubmitting={formProps.isSubmitting || acceptMembershipLoading}
              />
            </PanelFooter>
          </>
        )}
      </SignInForm>
    </StyledPanel>
  )
}

export default SignInAndAcceptMembershipPanel
