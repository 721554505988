import React, { useEffect, useRef } from 'react'

import { dataIdFromObject } from '@sketch/graphql-cache'
import {
  LoadingPlaceholder,
  Flex,
  handleFetchMore,
  InfiniteList,
  LoadingState,
} from '@sketch/components'
import { Layout } from '../../components/Layout'
import EmptyState from '../../../components/EmptyState'
import LoadingError from '../../components/Layout/LoadingError'
import { GroupName, ListGridSeparator } from '../../components/Grid'
import { TextGrid, TextGroup } from './TextStyles.styles'
import { TextStyleItem } from './TextStyleItem'

import useScrollReset from '../../hooks/useScrollReset'
import { useSearchComponentsContext } from '../../context/SearchComponentsContext'
import { useSelectedGroupContext } from '../../context/SelectedGroupContext'
import { useTrackTimeInComponentPage, useComponentLink } from '../../hooks'
import { useGetComponentsCount, useGetTextStyles } from '../../operations'
import { useGetPendingDescriptionQuery } from '@sketch/gql-types'
import { useFlag, useAnalytics, getRenderStatus } from '@sketch/modules-common'

// This is a valid use case to validate TextStyle
// eslint-disable-next-line no-restricted-imports
import { TextStyle } from '@sketch/gql-types/expansive'

import { groupStyles } from '../../utils'
import { Center } from '../../components/Center'
import ComponentsEmptyState from '../../../components/ComponentsEmptyState'

const TextStylesList = ({
  searchValue,
  path,
}: {
  searchValue: string
  path: string | null
}) => {
  useScrollReset()

  const isComponentDescriptionsOn = useFlag('components-description')

  const { trackEvent } = useAnalytics()

  const prevSearchRef = useRef<string | undefined>()

  const {
    shareIdentifier,
    versionIdentifier,
    documentVersion,
    compatibilityVersion,
    canEditDescriptions,
    renderStatusOfLatestVersion,
    entries,
    after,
    fetchMore,
    loading,
    error,
    hasComponentManifest,
    componentsState,
  } = useGetTextStyles({
    search: searchValue,
    path: path === 'View All' ? null : path,
  })

  const { data: pendingDescriptionsData, loading: pendingDescriptionsLoading } =
    useGetPendingDescriptionQuery({
      variables: {
        shareIdentifier,
        versionIdentifier: versionIdentifier || '',
      },
      skip:
        loading ||
        !versionIdentifier ||
        getRenderStatus(renderStatusOfLatestVersion) === 'ready' ||
        !isComponentDescriptionsOn,
    })

  // Allows us to link to an specific component (automatically selecting it and
  // showing the inspector data)
  useComponentLink(loading)

  useEffect(() => {
    if (searchValue) {
      trackEvent('CWV - search', { type: 'text styles' })
    }
  }, [searchValue, trackEvent])

  useEffect(() => {
    // only update the searchValue after it has finished loading
    if (!loading) {
      prevSearchRef.current = searchValue
    }
  }, [searchValue, loading])

  if (error) {
    return <LoadingError error={error} componentType="Text Styles" />
  }

  const switchingGroupToSearch =
    (path !== 'View All' || path === null) && searchValue

  const isLoadingSearch =
    loading &&
    searchValue &&
    (prevSearchRef.current === undefined ||
      prevSearchRef.current !== searchValue)

  if (
    (loading && !after) ||
    switchingGroupToSearch ||
    isLoadingSearch ||
    pendingDescriptionsLoading
  ) {
    return (
      <Flex justifyContent="center" flex="auto">
        <LoadingPlaceholder size="64px" />
      </Flex>
    )
  }

  if (!hasComponentManifest || componentsState === 'CANNOT_PROCESS') {
    return (
      <EmptyState
        title="Components aren’t available for this version"
        description=""
      />
    )
  }

  if (entries.length === 0) {
    if (searchValue !== '') {
      return (
        <EmptyState
          title={`No search results for "${searchValue}"`}
          description="No search results."
          icon="search"
        />
      )
    }

    if (path !== '' && path !== 'View All') {
      return (
        <EmptyState
          title={`The group "${path}" does not exist in this version.`}
          description=""
          icon="textStyle"
        />
      )
    }

    return (
      <EmptyState
        title="No Text Styles"
        description={
          <>
            This Document does not contain any{' '}
            <a href="https://www.sketch.com/docs/designing/styling/text-styles/">
              Text Styles
            </a>
            .
          </>
        }
        icon="textStyle"
      />
    )
  }

  const textGroups = groupStyles(entries)
  const groupsNames = Object.keys(textGroups)

  return (
    <InfiniteList
      canLoadMore={after !== null}
      onLoadMore={handleFetchMore(
        fetchMore,
        ['share', 'version', 'document', 'components', 'entries'],
        { dataIdFromObject, after: after || null }
      )}
    >
      {groupsNames.map((groupName, key) => (
        <TextGroup key={`${groupName}-${versionIdentifier}`}>
          {key !== 0 && <ListGridSeparator />}
          {groupName !== ' ' && <GroupName groups={groupName} />}
          <TextGrid>
            {textGroups[groupName].map((textStyle: TextStyle) => {
              const editingComponent = (
                pendingDescriptionsData?.collaborativeEditingSession
                  .componentEdits || []
              ).find(
                component =>
                  (component.componentUuid as string).toUpperCase() ===
                  textStyle.uuid
              )

              const pendingDescription = {
                documentVersion: documentVersion!,
                baseVersionIdentifier: versionIdentifier,
                compatibilityVersion: compatibilityVersion!,
                canEditDescriptions,
                shareIdentifier,
                componentUuid: textStyle.uuid,
                description:
                  editingComponent?.description || textStyle.description,
              }

              return (
                <TextStyleItem
                  key={`${textStyle.identifier}-${versionIdentifier}`}
                  textStyle={textStyle}
                  searchValue={searchValue}
                  pendingDescription={pendingDescription}
                />
              )
            })}
          </TextGrid>
        </TextGroup>
      ))}
    </InfiniteList>
  )
}

interface TextStylesProps {
  userIsEditor: boolean
}

export const TextStyles: React.FC<TextStylesProps> = ({ userIsEditor }) => {
  const { totalComponents, loading, componentsState } = useGetComponentsCount()
  const { search } = useSearchComponentsContext()
  const { selected, setSelected } = useSelectedGroupContext()

  useTrackTimeInComponentPage('text-styles')

  if (
    componentsState === 'CAN_PROCESS' ||
    componentsState === 'PROCESSING' ||
    loading ||
    totalComponents === false
  ) {
    return (
      <Center>
        <div>
          <LoadingState />
          <p>Loading components…</p>
        </div>
      </Center>
    )
  }

  if (totalComponents === 0) {
    return <ComponentsEmptyState userIsEditor={userIsEditor} />
  }

  if (search) {
    setSelected('View All')
  }

  return (
    <Layout>
      <TextStylesList searchValue={search} path={selected} />
    </Layout>
  )
}
