import React, { useRef } from 'react'
import * as yup from 'yup'

import { Formik, FormikProps } from 'formik'
import {
  WorkspaceMinimalFragment,
  WorkspaceMembershipFragment,
  ProjectMembershipFragment,
} from '@sketch/gql-types'
import {
  InvitePeopleField,
  FieldAutoCompleteFormValues,
  FieldAutoCompleteMembersFilter,
  useFieldAutocomplete,
} from '@sketch/modules-common'
import { AddProjectMemberPermissionsAccessSelect } from '../../components/ProjectMemberPermissionsAccessSelect/ProjectMemberPermissionsAccessSelect'
import {
  InviteButton,
  MemberInviteSection,
  NonMemberError,
} from './ProjectSharingModal.styles'
import { projectShareErrors } from '../../../../utils/guestInviteValidation'
import { getInviteLimitMessage, useMemberLimit } from '../../../workspace/hooks'
import { MemberLimitMessage } from '../../../workspace/components/MemberLimitMessage'

const LIMIT_INVITE_SAFEGUARD = { helpText: undefined, tooltip: undefined }

interface FormValues extends FieldAutoCompleteFormValues {
  guestCommentsEnabled: boolean
  guestInspectEnabled: boolean
}

// Form Schema
const FORM_INITIAL_VALUES: FormValues = {
  email: null,
  workspaceMembershipIdentifier: null,
  groupIdentifier: null,
  shouldValidate: false,
  guestCommentsEnabled: true,
  guestInspectEnabled: true,
}

const memberFormSchema = yup.object().shape({
  shouldValidate: yup.string().oneOf(['email', 'user', 'group']).required(),
  email: yup
    .string()
    .nullable()
    .max(255)
    .when('shouldValidate', {
      is: 'email',
      then: schema => schema.trim().lowercase().email().required(),
    }),
  workspaceMembershipIdentifier: yup
    .string()
    .nullable()
    .when('shouldValidate', {
      is: 'user',
      then: schema => schema.required(),
    }),
  groupIdentifier: yup
    .string()
    .nullable()
    .when('shouldValidate', {
      is: 'group',
      then: schema => schema.required(),
    }),

  guestCommentsEnabled: yup.boolean(),
  guestInspectEnabled: yup.boolean(),
})

type OnEntityAdd = (values: FormValues) => void

interface AddMemberFormProps {
  workspace: WorkspaceMinimalFragment
  membersList?: WorkspaceMembershipFragment[]
  onMemberAdd: OnEntityAdd
  onGroupAdd: OnEntityAdd | null
  projectIdentifier: string
  hideLabel?: boolean
  filterMembers?: FieldAutoCompleteMembersFilter
  removeSelf?: boolean
}

export const AddMemberForm = ({
  workspace,
  projectIdentifier,
  membersList = [],
  onMemberAdd,
  onGroupAdd,
  hideLabel,
  filterMembers,
  removeSelf = false,
}: AddMemberFormProps) => {
  return (
    <Formik
      initialValues={FORM_INITIAL_VALUES}
      onSubmit={async (values, { resetForm }) => {
        if (values.groupIdentifier) {
          await onGroupAdd?.(values)
        } else {
          await onMemberAdd(values)
        }

        resetForm()
      }}
      validationSchema={memberFormSchema}
      validateOnChange={true}
      validateOnMount={true}
    >
      {formikBag => (
        <form
          onSubmit={e => {
            e.preventDefault()
          }}
        >
          <FormikRender
            formikBag={formikBag}
            membersList={membersList}
            projectIdentifier={projectIdentifier}
            workspaceIdentifier={workspace.identifier}
            hideLabel={hideLabel}
            filterMembers={filterMembers}
            removeSelf={removeSelf}
          />
        </form>
      )}
    </Formik>
  )
}

interface FormikRenderProps {
  formikBag: FormikProps<typeof FORM_INITIAL_VALUES>
  projectIdentifier: string
  workspaceIdentifier: string
  membersList: WorkspaceMembershipFragment[]
  hideLabel?: boolean
  filterMembers?: FieldAutoCompleteMembersFilter
  removeSelf?: boolean
}

const FormikRender = ({
  formikBag,
  workspaceIdentifier,
  projectIdentifier,
  membersList,
  hideLabel,
  filterMembers,
  removeSelf,
}: FormikRenderProps) => {
  const containerRef = useRef<HTMLInputElement>(null)

  const autocomplete = useFieldAutocomplete({
    projectIdentifier,
    workspaceIdentifier,
    excludeItems: membersList,
    removeSelf,
    filter: filterMembers,
  })

  const limit = useMemberLimit(workspaceIdentifier)

  /**
   * Given this component is common to the members and guest tabs
   * but we only wanna show the limits to the guest members we need to
   * safeguard it from showing
   */
  const safeguardedLimit = filterMembers === 'Guests' ? limit : null

  const { helpText } = safeguardedLimit
    ? getInviteLimitMessage(safeguardedLimit)
    : LIMIT_INVITE_SAFEGUARD

  const showError =
    filterMembers &&
    !autocomplete.membersLoading &&
    projectShareErrors.includes(formikBag.errors.email ?? '')

  return (
    <>
      <MemberInviteSection>
        <InvitePeopleField
          containerRef={containerRef}
          autocomplete={autocomplete}
          placeholder="Add a name or email address"
          label={hideLabel ? '' : 'Manage Access'}
          dynamicAction={props => {
            const workspaceMembership =
              props.selectedItem as Partial<WorkspaceMembershipFragment>
            const membership = {
              workspaceMembership: workspaceMembership ?? {
                __typename: 'WorkspaceMembership',
                role: 'GUEST',
              },
              privateAccessLevel: workspaceMembership?.isEditor
                ? ('EDIT' as ProjectMembershipFragment['privateAccessLevel'])
                : ('VIEW' as ProjectMembershipFragment['privateAccessLevel']),
            }

            return (
              <AddProjectMemberPermissionsAccessSelect
                member={membership}
                guestCommentsEnabled={formikBag.values.guestCommentsEnabled}
                guestInspectEnabled={formikBag.values.guestInspectEnabled}
                onPermissionChange={(permission, value) => {
                  formikBag.setFieldValue(permission, value)
                }}
                onlyMembers={filterMembers === 'Members'}
                disabled={safeguardedLimit?.hasReachedLimit}
              />
            )
          }}
          disabled={safeguardedLimit?.hasReachedLimit}
        />
        <InviteButton
          type="submit"
          name="Add member to project"
          disabled={!formikBag.isValid || autocomplete.membersLoading}
          loading={formikBag.isSubmitting}
          onClick={async () => {
            // Form is submitted here instead of <form />
            // because we need to reset the Combobox, and
            // the autocomplete is created in this component
            await formikBag.submitForm()
            autocomplete.combobox.reset()
          }}
        >
          Add
        </InviteButton>
      </MemberInviteSection>
      {showError && <NonMemberError>{formikBag.errors.email}</NonMemberError>}
      {safeguardedLimit && <MemberLimitMessage>{helpText}</MemberLimitMessage>}
    </>
  )
}
