import { DocumentNode } from 'graphql'
import ApolloClient, { ObservableQuery } from 'apollo-client'

import { localStorageKeys } from '@sketch/constants'
import { NormalizedCacheObject } from 'apollo-cache-inmemory'
import { getParsedItem, setStringifiedItem } from '@sketch/utils'

type StorageKey = keyof typeof localStorageKeys
type StorageValue = (typeof localStorageKeys)[StorageKey]

type Apollo = ApolloClient<NormalizedCacheObject>

const queueStorageUpdate = (storageValue: StorageValue, data: any) => {
  setTimeout(() => setStringifiedItem(storageValue, data))
}

export const createStoragePersist = (apolloClient: Apollo) => {
  const storage = new WeakMap<DocumentNode, [StorageValue, ObservableQuery]>()

  const syncQueryWithStorage = (
    query: DocumentNode,
    storageKey: StorageKey
  ) => {
    const observableItem = storage.get(query) || []
    const storageValue = localStorageKeys[storageKey]

    const savedStorageValue = observableItem[0]
    let observable = observableItem[1]

    /* if no "observable" means it's the first time the listener is set */
    if (!observable) {
      const previousSavedQuery = getParsedItem(storageValue)

      if (previousSavedQuery) {
        apolloClient.writeQuery({
          query,
          data: previousSavedQuery,
        })
      }

      observable = apolloClient.watchQuery({
        query,
        fetchPolicy: 'cache-only',
      })
    }

    /* We are already subscribed to these updates (same key, same query) */
    if (savedStorageValue === storageValue) {
      return
    }

    observable.subscribe(({ data }) => {
      queueStorageUpdate(storageValue, data)
    })

    storage.set(query, [storageValue, observable])
  }

  return {
    syncQueryWithStorage,
  }
}
