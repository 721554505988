import React from 'react'

import { useIsNodeContentTruncated } from '@sketch/utils'

import { Breakpoint } from '../../Breakpoint'
import { Truncate } from '../../Truncate'
import { badgeForMemberRole, isInvitationRequested } from '../utils'
import { getNameOrEmailFromMembership } from './utils'

import {
  TooltipWithTruncatedContent,
  Subtitle,
  StyledTruncate,
} from './BaseListRow.styles'

import type { BaseListRowInfoProps } from '../types'

export const BaseListRowInfo = ({
  title,
  subtitle,
  role,
  inviteStatus,
  isCurrentUser,
}: BaseListRowInfoProps) => {
  const [isSubtitleTruncated, subtitleRef] =
    useIsNodeContentTruncated<HTMLDivElement>()
  const [isTitleTruncated, titleRef] =
    useIsNodeContentTruncated<HTMLDivElement>()

  const content = getNameOrEmailFromMembership(isCurrentUser, title, subtitle)
  const differentTitleAndSubtitle = content !== subtitle

  const invitationRequested = inviteStatus
    ? isInvitationRequested(inviteStatus)
    : undefined

  return (
    <Truncate>
      <TooltipWithTruncatedContent
        placement="top"
        spacing="10px"
        content={title}
        disabled={!isTitleTruncated}
      >
        <StyledTruncate
          ref={titleRef}
          invitationRequested={invitationRequested}
        >
          {content}
        </StyledTruncate>
        <Breakpoint on="xs">
          {badgeForMemberRole({
            role,
            inviteStatus,
          })}
        </Breakpoint>
      </TooltipWithTruncatedContent>
      {subtitle && differentTitleAndSubtitle ? (
        <TooltipWithTruncatedContent
          placement="top"
          spacing="10px"
          content={subtitle}
          disabled={!isSubtitleTruncated}
        >
          <Subtitle ref={subtitleRef}>{subtitle}</Subtitle>
        </TooltipWithTruncatedContent>
      ) : null}
    </Truncate>
  )
}
