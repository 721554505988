import {
  Container,
  ChevronIcon,
  ChevronButton,
  BreadcrumbsTrigger,
  BreadcrumbsTriggerText,
  ChevronUpDownIcon,
  DropdownItem,
  IconContainer,
} from './ProjectsBreadcrumbsDropdown.styles'
import React from 'react'
import { BreadcrumbsData } from '../../types'
import { ProjectIcon } from '../../components/ProjectIcon'
import { Dropdown, WorkspaceLogo, Tooltip } from '@sketch/components'
import { Link } from 'react-router-dom'
import { routes } from '@sketch/modules-common'

export interface ProjectsBreadcrumbsDropdownProps {
  data: BreadcrumbsData | undefined
}

export const ProjectsBreadcrumbsDropdown = (
  props: ProjectsBreadcrumbsDropdownProps
) => {
  const { data } = props
  if (!data?.parentProject || !data?.currentProject) return null

  const {
    parentProject,
    parentProjects,
    siblingProjects,
    workspace,
    currentProject,
  } = data

  const maxDepth = parentProjects.length

  return (
    <Container>
      <Tooltip content="Back to Project" placement="top-start">
        <Link
          to={routes.WORKSPACE_PROJECT.create({
            workspaceId: workspace.identifier,
            projectId: parentProject.identifier,
          })}
        >
          <ChevronButton>
            <ChevronIcon />
          </ChevronButton>
        </Link>
      </Tooltip>
      <Dropdown
        placement="bottom-start"
        toggle={
          <BreadcrumbsTrigger>
            <BreadcrumbsTriggerText>
              {parentProject.name}
            </BreadcrumbsTriggerText>
            <ChevronUpDownIcon />
          </BreadcrumbsTrigger>
        }
      >
        <Link
          to={routes.WORKSPACE_SHARES.create({
            workspaceId: workspace.identifier,
          })}
        >
          <DropdownItem>
            <IconContainer>
              <WorkspaceLogo
                size="16px"
                workspaceName={workspace.name}
                src={workspace.avatar?.small || workspace.avatar?.large}
              />
            </IconContainer>
            {workspace.name}
          </DropdownItem>
        </Link>
        {parentProjects.reverse().map((project, index) => (
          <Link
            key={project.identifier}
            to={routes.WORKSPACE_PROJECT.create({
              workspaceId: workspace.identifier,
              projectId: project.identifier,
            })}
          >
            <DropdownItem depth={index + 1}>
              <IconContainer>
                <ProjectIcon project={project} isOpen={true} />
              </IconContainer>
              {project.name}
            </DropdownItem>
          </Link>
        ))}
        {siblingProjects.map(project => (
          <Link
            key={project.identifier}
            to={routes.WORKSPACE_PROJECT.create({
              workspaceId: workspace.identifier,
              projectId: project.identifier,
            })}
          >
            <DropdownItem
              key={project.identifier}
              aria-current={project.identifier === currentProject.identifier}
              depth={maxDepth + 1}
            >
              <IconContainer>
                <ProjectIcon project={project} isOpen={false} />
              </IconContainer>
              {project.name}
            </DropdownItem>
          </Link>
        ))}
      </Dropdown>
    </Container>
  )
}
