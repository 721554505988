import React, { useEffect } from 'react'
import styled from 'styled-components'

import {
  Button,
  flatButtonStyles,
  downloadFile,
  Modal,
  ModalInjectedProps,
} from '@sketch/components'

import { setSketchUrlScheme } from '../../utils'

import { SKETCH_WEBSITE } from '@sketch/env-config'

type ActionProps = {
  shareId: string
  pageUUID?: string
}
// Possible actions to launch Sketch app
const sketchActions = {
  addLibrary: ({ shareId }: ActionProps) =>
    setSketchUrlScheme(`add-library/cloud/${shareId}`),
  newFromTemplate: ({ shareId }: ActionProps) =>
    setSketchUrlScheme(`new-from-template/cloud/${shareId}`),
  openInSketch: ({ shareId, pageUUID }: ActionProps) => {
    let url
    if (pageUUID) {
      url = `${SKETCH_WEBSITE}/s/${shareId}/p/${pageUUID}`
    } else {
      url = `${SKETCH_WEBSITE}/s/${shareId}`
    }
    return setSketchUrlScheme(url)
  },
}

type SketchAction = keyof typeof sketchActions

type LaunchSketchType = {
  shareId: string
  pageUUID?: string
  action: SketchAction
}

// Custom hook to group the logic to launch the Sketch app
function useSketchUrlSchema({ shareId, pageUUID, action }: LaunchSketchType) {
  useEffect(() => {
    const getSketchActionUrl = (
      shareId: string,
      action: SketchAction
    ): string =>
      sketchActions[action]({
        shareId,
        pageUUID,
      })

    downloadFile(getSketchActionUrl(shareId, action))
  }, [shareId, action, pageUUID])
}

interface LaunchSketchAlertProps extends ModalInjectedProps {
  shareId: string
  pageUUID?: string
  action: SketchAction
}

const Anchor = styled.a.attrs({
  variant: 'primary',
  intent: 'primary',
})`
  ${flatButtonStyles};
  padding: 0;
`

const LaunchSketchAlert: React.FC<LaunchSketchAlertProps> = ({
  shareId,
  hideModal,
  action,
  pageUUID,
}) => {
  useSketchUrlSchema({ shareId, action, pageUUID })

  return (
    <Modal onCancel={hideModal}>
      <Modal.Header>Launching the Sketch Mac App…</Modal.Header>
      <Modal.Body>
        If nothing happens, you may need to{' '}
        <Anchor
          href={`${SKETCH_WEBSITE}/get`}
          target="_blank"
          rel="noopener noreferrer"
        >
          install the Mac app
        </Anchor>{' '}
        and try again.
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={hideModal}>Close</Button>
      </Modal.Footer>
    </Modal>
  )
}

export { LaunchSketchAlert }
