import { Modal, Button as ButtonBase } from '@sketch/components'
import { breakpoint } from '@sketch/global-styles'
import styled, { css } from 'styled-components'

export const ModalStyled = styled(Modal)`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  min-height: 532px;
  height: 40vh;

  ${breakpoint('sm')`
    resize: vertical;
    border-bottom-right-radius: 4px;
  `}
`

export const Footer = styled.div<{ dimmed?: boolean }>`
  border-top: 1px solid ${({ theme }) => theme.colors.border.A};
  margin-top: auto;

  display: flex;
  width: 100%;
  justify-content: flex-end;
  padding: 16px;

  & > * {
    ${({ dimmed }) =>
      dimmed &&
      css`
        opacity: 0.3;
        pointer-events: none;
      `}
  }
`

export const Button = styled(ButtonBase)`
  flex-grow: 0;
`
