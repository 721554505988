import React, { useContext } from 'react'
import {
  ArtboardDetailInfoFragment,
  GetArtboardsForPageDocument,
  GetArtboardsForPageQuery,
  GetFramesForPageDocument,
  GetFramesForPageQuery,
  useQuery,
} from '@sketch/gql-types'
import { useParams } from 'react-router-dom'
import { OperationVariables } from 'apollo-client'
import get from 'lodash.get'
import {
  RetryQueryProps,
  useQueryRetry,
  RouteParams,
  useFlag,
} from '@sketch/modules-common'
import { ResponsiveValues } from '@sketch/global-styles'
import { ShareVersionContext } from '../../../versioning'
import { FrameGroupsList } from '../FrameGroupsList'

type RetryIfBuilder = (
  path: string[]
) => RetryQueryProps<any, OperationVariables>['retryIf']

const buildRetryIf: RetryIfBuilder =
  path =>
  ({ data, error }) => {
    if (error) return true

    const artboards = get(data, path, []) as ArtboardDetailInfoFragment[]
    const files = artboards.map(a => a.files).flat()

    return files.some(file => !file?.url)
  }

interface PageFramesProps {
  search: string
  columns: ResponsiveValues<number>
}

/**
 * Handles a list of frames scoped to a specific share document
 * version page.
 */
export const PageFrames = ({ search, columns }: PageFramesProps) => {
  const isFramesWebOn = useFlag('frames-web')
  const entriesPath = [
    'share',
    'version',
    'document',
    'page',
    isFramesWebOn ? 'frames' : 'artboards',
    'entries',
  ]
  const afterPath = [
    'share',
    'version',
    'document',
    'page',
    isFramesWebOn ? 'frames' : 'artboards',
    'meta',
    'after',
  ]

  const { versionShortId } = useContext(ShareVersionContext)!
  const { shareID, pageUUID } = useParams<RouteParams<'SHARE_PAGE_VIEW'>>()

  const query = useQuery<GetFramesForPageQuery | GetArtboardsForPageQuery>(
    isFramesWebOn ? GetFramesForPageDocument : GetArtboardsForPageDocument,
    { variables: { identifier: shareID, versionShortId, search, pageUUID } }
  )

  const response = useQueryRetry(query, {
    retryIf: buildRetryIf(entriesPath),
  })

  const responseProps = {
    fetchMore: response.fetchMore,
    data: response.data,
    loading: response.loading,
    error: response.error,
    filterValue: search,
  }

  return (
    <FrameGroupsList
      fetchMore={responseProps.fetchMore}
      afterPath={afterPath}
      entriesPath={entriesPath}
      pageUUID={pageUUID}
      data={responseProps.data}
      loading={responseProps.loading}
      error={responseProps.error}
      columns={columns}
      filterValue={search}
    />
  )
}
