import { ApolloError } from 'apollo-client'
import {
  useGetProjectCollectionsForSelectLazyQuery,
  GetProjectCollectionsForSelectQueryVariables,
  CollectionForSelectFragment,
  QueryLazyOptions,
} from '@sketch/gql-types'

type GetCollections = (
  options?: QueryLazyOptions<GetProjectCollectionsForSelectQueryVariables>
) => void

interface QueryResult {
  data: CollectionForSelectFragment[]
  loading: boolean
  error?: ApolloError
}

export const useGetCollectionsForSelectLazy = (): [
  GetCollections,
  QueryResult,
] => {
  const [getCollections, { data, ...rest }] =
    useGetProjectCollectionsForSelectLazyQuery()

  // To simplify the logic for clients, we transform the data into
  // `CollectionForSelectFragment[]` so that they don't need to do any type
  // coercion.
  const transformedData: CollectionForSelectFragment[] = (
    data?.project.collectionsUnpaginated ?? []
  ).map(({ identifier, name }) => ({
    __typename: 'Collection',
    identifier,
    name,
  }))

  return [getCollections, { data: transformedData, ...rest }]
}
