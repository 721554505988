import React from 'react'
import { useVersioning } from '../../../versioning'
import { Tooltip, useResponsiveDropdown } from '@sketch/components'

import NotificationStatusButton from '../NotificationStatusButton'
import NotificationStatusDropdown from '../NotificationPartialStatusDropdown'

// This is a valid use case to narrow down Component type
// eslint-disable-next-line no-restricted-imports
import { NotificationSubscriptionStatus } from '@sketch/gql-types/expansive'

import useDocumentNotificationSubscription from '../../operations/useNotificationSubscription'

interface ShareSubscriptionStatusProps {
  shareIdentifier: string
}

const tooltipCopy: {
  [key in NotificationSubscriptionStatus]: JSX.Element | string
} = {
  ON: 'Stop receiving updates on new comments',
  OFF: 'Receive updates on new comments',
  PARTIAL: 'You’re receiving updates on some comments in this document',
} as const

const ShareSubscriptionStatus: React.FC<
  ShareSubscriptionStatusProps
> = props => {
  const { shareIdentifier } = props
  const updateNotificationStatus = useDocumentNotificationSubscription()
  const { share } = useVersioning()

  const notificationStatus = share.subscriptionStatus

  const [content, buttonProps] = useResponsiveDropdown({
    dropdown: NotificationStatusDropdown,
    dropdownProps: {
      type: 'share',
      notificationStatus,
      onNotificationChange: state =>
        updateNotificationStatus({ shareIdentifier, state }),
    },
    placement: 'bottom-end',
  })

  const getButton = () => {
    if (notificationStatus === 'PARTIAL') {
      return (
        <>
          <NotificationStatusButton
            data-testid="document-notification-status"
            notificationStatus={notificationStatus}
            {...buttonProps}
          />
          {content}
        </>
      )
    } else {
      const newStatus = notificationStatus === 'ON' ? 'OFF' : 'ON'
      return (
        <NotificationStatusButton
          data-testid="document-notification-status"
          notificationStatus={notificationStatus}
          onClick={() => {
            updateNotificationStatus({
              shareIdentifier,
              state: newStatus,
            })
          }}
        />
      )
    }
  }

  return (
    <Tooltip
      placement="bottom-start"
      content={tooltipCopy[notificationStatus]}
      disabled={buttonProps['aria-expanded'] === true}
    >
      {getButton()}
    </Tooltip>
  )
}

export default ShareSubscriptionStatus
