import React from 'react'
import styled from 'styled-components'

import { Skeleton } from '@sketch/components'
import { Separator, Section } from '../../components'

const LayerContainer = styled.div`
  display: flex;
  align-items: center;

  & + & {
    margin-top: 12px;
  }
`

const SquareSkeleton = styled(Skeleton)`
  flex-shrink: 0;
  margin-right: 12px;
`

export const InspectorSkeleton = () => (
  <div data-testid="inspector-skeleton">
    <Section>
      <LayerContainer>
        <SquareSkeleton width="36px" height="36px" />
        <Skeleton width="100%" height="15px" />
      </LayerContainer>
    </Section>
    <Separator />
    <Section>
      <LayerContainer>
        <Skeleton width="100%" height="15px" />
      </LayerContainer>
      <LayerContainer>
        <Skeleton width="100%" height="90px" />
      </LayerContainer>
    </Section>
  </div>
)
