import React, { useState } from 'react'
import { Modal, ModalInjectedProps, Button, Box } from '@sketch/components'
import {
  RecoveryCode,
  VerificationCode,
  useSignOut,
} from '@sketch/modules-common'
import {
  useLinkRegularAccountWithMfaMutation,
  ParsedError,
} from '@sketch/gql-types'
import { useToast } from '@sketch/toasts'

interface MFAModalrops extends ModalInjectedProps {
  email: string
  handleClickBack: () => void
  mfa: string
}

export const MFAModal = ({
  email,
  hideModal,
  handleClickBack,
  mfa,
}: MFAModalrops) => {
  const { showToast } = useToast()
  const [totp, setTotp] = useState('')
  const [recovery, setRecovery] = useState('')
  const [showRecoveryCodeInput, setShowRecoveryCodeInput] = useState(false)

  const handleOnError = (error: ParsedError) => {
    showToast(error.message, 'negative')
  }

  const signOut = useSignOut({
    revokeSessions: 'none',
    removeDataFromSessions: 'all',
    reason: 'Account linked',
  })

  const [linkRegularAccount, { loading }] =
    useLinkRegularAccountWithMfaMutation({
      onCompleted: () => {
        showToast(`SSO Account linked to ${email}`, 'positive')
        signOut()
      },
      onError: handleOnError,
    })

  const handleOnSubmit = (autoSubmitTotp?: string) => {
    if (!autoSubmitTotp && !totp && !recovery) {
      showToast('Please enter a verification code or recovery code', 'negative')
      return
    }

    const definedTotp = autoSubmitTotp || totp

    linkRegularAccount({
      variables: {
        mfa_token: mfa,
        totp: showRecoveryCodeInput ? undefined : definedTotp,
        recovery_code: showRecoveryCodeInput ? recovery : undefined,
      },
    })
  }

  const handleToggleInterface = () => setShowRecoveryCodeInput(prev => !prev)

  const footer = (
    <Modal.Footer
      leftContent={<Button onClick={handleClickBack}>Go Back</Button>}
    >
      <Button onClick={hideModal}>Cancel</Button>
      <Button
        type="submit"
        variant="primary"
        onClick={() => handleOnSubmit()}
        loading={loading}
      >
        Link Account
      </Button>
    </Modal.Footer>
  )

  if (showRecoveryCodeInput) {
    return (
      <Modal onCancel={hideModal} title={'Enter Recovery Code'}>
        <Modal.Body>
          Can’t access your authenticator app? Enter a recovery code for{' '}
          <strong>{email}</strong> instead.
          <Box mt={6}>
            <RecoveryCode
              mfaToken={mfa}
              toggleInterface={handleToggleInterface}
              hideHeader
              onChange={e => setRecovery(e.target.value)}
            />
          </Box>
        </Modal.Body>
        {footer}
      </Modal>
    )
  }

  const onFilled = (code: string) => {
    setTotp(code)

    if (code.length === 6) {
      handleOnSubmit(code)
    }
  }

  return (
    <Modal onCancel={hideModal} title={'Enter Verification Code'}>
      <Modal.Body>
        Open your authenticator app and enter the verification code for{' '}
        <strong>{email}</strong> before it expires — or wait for a new one.
        <Box mt={6}>
          <VerificationCode
            onFilled={onFilled}
            onVerificationCodeFilled={() => {}}
            toggleInterface={handleToggleInterface}
            mfaToken={mfa}
            hideHeader
            onError={() => {}}
          />
        </Box>
      </Modal.Body>
      {footer}
    </Modal>
  )
}
