import { AuthenticatedSocket } from '../socket/AuthenticatedSocket'
import { Channel } from 'phoenix'

interface RegisteredChannelEvent {
  channelInstance: Channel
  messageHandlers: [(response: any) => void]
}

interface SocketChannelOptions {
  onError: (reason?: any) => void
  onClose: (reason?: any) => void
}

/**
 * SocketChannels class
 *
 * Class to handle joining and leaving socket channels,
 * keeping away the complexity from the code where
 * sockets are used
 */
export class SocketChannels {
  private socket: AuthenticatedSocket
  private registeredChannelEvents = new Map<string, RegisteredChannelEvent>()

  constructor(socket: AuthenticatedSocket) {
    this.socket = socket
  }

  async join(
    channelName: string,
    channelEvent: string,
    messageHandler: (response: any) => void,
    options: SocketChannelOptions = {
      onError: () => {},
      onClose: () => {},
    }
  ) {
    const channelEventId = `${channelName}:${channelEvent}`
    const registeredChannel = this.registeredChannelEvents.get(channelEventId)

    if (registeredChannel) {
      registeredChannel.messageHandlers.push(messageHandler)
      return
    }

    if (!this.socket.isConnected()) {
      await this.socket.connect()
    }

    const channelInstance = this.socket.channel(channelName)
    channelInstance.onError(options.onClose)
    channelInstance.onClose(options.onError)
    channelInstance.on(channelEvent, response => {
      this.handleNewMessage(channelEventId, response)
    })

    this.registeredChannelEvents.set(channelEventId, {
      channelInstance,
      messageHandlers: [messageHandler],
    })

    channelInstance
      .join()
      .receive('ok', response =>
        this.handleNewMessage(channelEventId, response)
      )

    return channelInstance
  }

  leave(
    channelName: string,
    channelEvent: string,
    eventHandler: (response: any) => void
  ) {
    const channelEventId = `${channelName}:${channelEvent}`
    const registeredChannelEvent =
      this.registeredChannelEvents.get(channelEventId)

    if (registeredChannelEvent) {
      const handlers = registeredChannelEvent.messageHandlers
      const handlerIndex = handlers.findIndex(
        handler => handler === eventHandler
      )

      if (handlerIndex > -1) {
        handlers.splice(handlerIndex, 1)
      }

      if (!handlers.length) {
        registeredChannelEvent.channelInstance.leave()
        this.registeredChannelEvents.delete(channelEventId)
      }
    }
  }

  private handleNewMessage(channelEventId: string, response: any) {
    const registeredChannel = this.registeredChannelEvents.get(channelEventId)

    if (registeredChannel) {
      registeredChannel.messageHandlers.forEach(handler => handler(response))
      return
    }
  }
}
