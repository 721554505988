import React from 'react'
import { NavLinkProps } from 'react-router-dom'
import {
  AnnotationDotFragment,
  BaseAnnotationFragment,
} from '@sketch/gql-types'
import { Button, Text } from '@sketch/components'

import CommentTabMessageError from '../CommentTabMessageError'
import CommentTabMessageNoAnnotations from '../CommentTabMessageNoAnnotations'
import CommentSkeleton from '../CommentSkeleton'

import useGetAnnotations from '../../operations/useGetAnnotations'

import { useAnnotationQueryVariables } from '../../context'

import { AnnotationStatusFilterWithoutUnread } from '../../types'
import AnnotationList from '../AnnotationList'
import styled from 'styled-components'

// eslint-disable-next-line no-restricted-imports
import { PaginationMeta } from '@sketch/gql-types/expansive'

const StyledButton = styled(Button)`
  margin-top: 16px;
`

const EMPTY_META: Pick<PaginationMeta, '__typename' | 'after' | 'totalCount'> =
  {
    __typename: 'PaginationMeta',
    after: null,
    totalCount: 0,
  }

// Same interface used in "containers/AnnotationsListContainer"
interface ListProps {
  activeAnnotationIdentifier?: string
  isCanvasLoading?: boolean
  resolveAnnotationLink?: (
    annotation: BaseAnnotationFragment
  ) => NavLinkProps['to'] | undefined
  shouldRenderAnnotation?: (
    annotation: AnnotationDotFragment,
    isActiveAnnotation: boolean
  ) => boolean
}

const PLACEHOLDER_COUNT = 3

const AllAnnotationsList: React.FC<ListProps> = props => {
  const {
    activeAnnotationIdentifier,
    resolveAnnotationLink,
    shouldRenderAnnotation,
    isCanvasLoading,
  } = props

  const variables = useAnnotationQueryVariables('sidebar')
  const { annotationStatus } = variables

  /* 
    We split the Annotations list in two (Resolved and Unresolved) for a newer implementation of the UI. 
    Both queries below will always override the variables from "AnnotationQueryVariablesContext" and "useAnnotationQueryVariables".
  */
  const active = useGetAnnotations({
    variables: {
      ...variables,
      sort: 'LATEST_FIRST',
      annotationStatus: 'ACTIVE_ONLY',
    },
  })

  const hasLoadedAllActive =
    !active.loading && active.data?.annotations.meta.after === null

  const resolved = useGetAnnotations({
    variables: {
      ...variables,
      sort: 'LATEST_RESOLVED_FIRST',
      annotationStatus: 'RESOLVED_ONLY',
    },
    skip: !hasLoadedAllActive,
  })

  const loading = active.loading
  const isActiveEmpty = active.data?.annotations.entries.length === 0
  const isResolvedEmpty = resolved.data?.annotations.entries.length === 0

  const refetch = active.error ? active.refetch : resolved.refetch

  if (loading || isCanvasLoading) {
    return <CommentSkeleton count={PLACEHOLDER_COUNT} />
  }

  if (active.error || resolved.error) {
    return (
      <CommentTabMessageError title="Unable to load comments">
        <Text textStyle="copy.quarternary.standard.D">
          There was an error loading comments.
        </Text>
        <StyledButton onClick={() => refetch()} variant="secondary" size="32">
          Try again
        </StyledButton>
      </CommentTabMessageError>
    )
  }

  if (isActiveEmpty && isResolvedEmpty) {
    return (
      <CommentTabMessageNoAnnotations
        annotationStatus={
          annotationStatus as AnnotationStatusFilterWithoutUnread
        }
        showAddCommentButton
      />
    )
  }

  const activeMeta = active.data?.annotations.meta ?? EMPTY_META
  const resolvedMeta = resolved.data?.annotations.meta ?? EMPTY_META
  const fetchMore = activeMeta.after ? active.fetchMore : resolved.fetchMore
  const meta = activeMeta.after
    ? activeMeta
    : {
        ...resolvedMeta,
        totalCount: activeMeta.totalCount + resolvedMeta.totalCount,
      }

  const entries = [
    ...(active.data?.annotations.entries ?? []),
    ...(!activeMeta.after ? (resolved.data?.annotations.entries ?? []) : []),
  ]

  return (
    <>
      <AnnotationList
        activeAnnotationIdentifier={activeAnnotationIdentifier}
        resolveAnnotationLink={resolveAnnotationLink}
        entries={entries}
        meta={meta}
        fetchMore={fetchMore}
        annotationStatus={annotationStatus}
        shouldRenderAnnotation={shouldRenderAnnotation}
      />
    </>
  )
}

export default AllAnnotationsList
