/**
 * Intersperse an array with a specific separator element, returning a new array.
 *
 * @param arr - The source array whose elements you want to intersperse.
 * @param separator - The element to insert between each array element.
 * @returns A new array with the separator inserted between each original element.
 */
export function intersperse<T, U>(arr: T[], separator: U): (T | U)[] {
  return arr.flatMap((element, index) =>
    index < arr.length - 1 ? [element, separator] : [element]
  )
}
