import styled, { css } from 'styled-components'

import * as Collapsible from '@radix-ui/react-collapsible'
import { ButtonUnstyled, Pill } from '@sketch/components'

import arrowDownIcon from '@sketch/icons/chevron-vertical-16.svg'
import { breakpoint } from '@sketch/global-styles'

export const SidebarWrapper = styled.nav`
  display: flex;
  flex-direction: column;

  /* Limit the components width, preventing items with big name to have scroll */
  width: 100%;
  /* Enables scroll on the sidebar instead on the frame, causing double scrollbars
  in some scenarios. More info: https://github.com/sketch-hq/Cloud/issues/9511 */
  height: 100%;
  flex: 1;
`

export const WorkspaceSelectorWrapper = styled.aside`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 20px;
`

export const MenuWrapper = styled.div<{ $isDarkMode?: boolean }>(
  ({ $isDarkMode }) => css`
    padding: 8px;
    width: 100%;
    flex: 1;
    overflow-y: auto;

    /*
  Forcing the elements on this container to be stacked vertically
  and that their size or ability to grow is respected
  */
    display: flex;
    flex-direction: column;

    ${!$isDarkMode &&
    css`
      /* Scroll shadow */
      --bgRGB: 255, 255, 255;
      --bg: rgb(var(--bgRGB));
      --bgTrans: rgba(var(--bgRGB), 0);

      --shadow: rgba(0, 0, 0, 0.1);

      background:
      /* Shadow Cover TOP */
        linear-gradient(var(--bg) 30%, var(--bgTrans)) center top,
        /* Shadow Cover BOTTOM */ linear-gradient(var(--bgTrans), var(--bg) 70%)
          center bottom,
        /* Shadow TOP */
          radial-gradient(
            farthest-side at 50% 0,
            var(--shadow),
            rgba(0, 0, 0, 0)
          )
          center top,
        /* Shadow BOTTOM */
          radial-gradient(
            farthest-side at 50% 100%,
            var(--shadow),
            rgba(0, 0, 0, 0)
          )
          center bottom;

      background-repeat: no-repeat;
      background-size:
        100% 40px,
        100% 40px,
        100% 14px,
        100% 14px;
      background-attachment: local, local, scroll, scroll;
    `}
  `
)

export const Header = styled.div`
  ${({ theme }) => css`
    color: ${theme.colors.foreground.secondary.D};
    font-size: ${theme.fontSizes.D};
    font-weight: ${theme.fontWeights.semibold};
    text-transform: capitalize;
    margin: 0 0 4px 12px;

    display: flex;
    justify-content: space-between;
    align-items: center;

    ${breakpoint('base', 'sm')`
      margin-bottom: 8px;

      & button > ::before {
        top: -5px;
    }`}
  `}
`

export const FooterWrapper = styled.div``

export const StyledPill = styled(Pill)`
  margin-left: auto;
  margin-right: 12px;

  .active & {
    background-color: ${({ theme }) => theme.colors.sketch.F};
    color: ${({ theme }) => theme.colors.sketch.A};
  }
`

export const CollapsibleTrigger = styled(Collapsible.Trigger)`
  border: none;
  background: none;
`

export const CollapsibleIcon = styled(ButtonUnstyled)<{
  selected?: boolean
  isOpen?: boolean
}>(
  ({ theme, selected, isOpen }) => css`
    position: relative;
    flex: none;
    border: none;
    background: none;
    color: ${({ theme }) => theme.colors.foreground.secondary.D};
    border-radius: 6px;
    width: 40px;

    ${breakpoint('sm')`
      height: 32px;
    `};

    :hover {
      background-color: ${theme.colors.background.tertiary.C};
    }

    &:before {
      position: absolute;
      content: '';
      top: 50%;
      left: 50%;
      width: 16px;
      height: 16px;
      mask: url(${arrowDownIcon}) no-repeat 50% 50%;
      mask-size: cover;
      background-color: ${selected
        ? theme.colors.sketch.A
        : theme.colors.foreground.secondary.D};
      transition-timing-function: ease-in-out;
      transition-duration: 0.3s;
      transition-property: transform;
      will-change: transform;
      transform: translate(-50%, -50%) ${!isOpen && 'rotate(-90deg)'};
    }
  `
)

export const MenuListItem = styled.li`
  list-style: none;
`

export const Spacer = styled.div`
  margin-top: 16px;
`
