import styled, { css } from 'styled-components'
import { ReactComponent as Chevron } from '@sketch/icons/chevron-right-16'
import { ReactComponent as ChevronUpDown } from '@sketch/icons/chevrons-up-down-16'
import { Dropdown, truncateStyles } from '@sketch/components'

// There are some parent containers which are setting the line-height to 0
// we need to reset this value back to initial.
export const Container = styled.div`
  line-height: initial; /* stylelint-disable-line scales/line-height */
  display: flex;
  align-items: center;
  margin-bottom: 10px; /* stylelint-disable-line scales/space */
`

export const ChevronIcon = styled(Chevron)`
  width: 16px;
  height: 16px;
  transform: rotate(180deg);
`

const PlainButton = styled.button`
  margin: 0;
  padding: 0;
  cursor: pointer;
  border: none;
  background: none;

  display: flex;
  align-items: center;

  transition: color ${({ theme }) => theme.transitions.duration[0]};
  color: ${({ theme }) => theme.colors.foreground.secondary.D};
  :hover {
    color: ${({ theme }) => theme.colors.foreground.secondary.A};
  }
`

export const ChevronButton = styled(PlainButton).attrs({
  type: 'button',
})`
  width: 16px;
  height: 16px;
`

export const ChevronUpDownIcon = styled(ChevronUpDown)`
  width: 16px;
  height: 16px;
  margin-left: 4px;
  opacity: 0;
  transition: opacity ${({ theme }) => theme.transitions.duration[1]};
`

export const BreadcrumbsTrigger = styled(PlainButton)`
  height: 16px;
  padding: 0 4px;
  align-items: center;

  :hover {
    ${ChevronUpDownIcon} {
      opacity: 1;
    }
  }
`
export const BreadcrumbsTriggerText = styled.span`
  text-transform: uppercase;
  font-size: 11px; /* stylelint-disable-line scales/font-size */
  font-weight: 600;
  max-width: 320px;

  ${truncateStyles}
`

const selectedStyle = css`
  background-color: ${({ theme }) => theme.colors.sketch.F};
  color: ${({ theme }) => theme.colors.sketch.A};

  &:hover {
    background-color: ${({ theme }) => theme.colors.sketch.F};
  }
`

export const IconContainer = styled.div`
  width: 16px;
  height: 16px;
  margin-right: 8px;
  flex-shrink: 0;
`

export const DropdownItem = styled(Dropdown.Item)<{ depth?: number }>`
  display: flex;

  &[aria-current='true'] {
    ${selectedStyle};
  }

  padding-left: ${({ depth }) => (depth || 0) * 16 + 12}px;
  align-items: center;
`
