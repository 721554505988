import React, { useCallback, useRef, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import { useHistory } from 'react-router'
import { chunk } from 'lodash'

import { useModalContext } from '@sketch/components'
import { routes, useUserSignedIn } from '@sketch/modules-common'

import {
  PublicationReactionsFragment,
  useAddReactionToPublicationMutation,
  usePublicationReactionsUpdatedSubscription,
} from '@sketch/gql-types'

import { SignInForLoveModal } from '../../modals'

import {
  DetailButton,
  HeartCount,
  DetailHeartIcon,
  ListButton,
  ListButtonIcon,
} from './HeartButton.styles'

const START_ANIMATION_DELAY = 1000

type HeartButtonFlavour = 'detail' | 'list'

const UNITS_BY_NUMBER = 3

const getUnitByLength = (length: number) => {
  switch (length) {
    case 3:
      return 'm'

    case 2:
      return 'k'

    default:
      return ''
  }
}

const formatHeartNumber = (totalHearts: number) => {
  const totalHeartsString = `${totalHearts}`
  const missingChunks = Math.abs(
    UNITS_BY_NUMBER - (totalHeartsString.length % UNITS_BY_NUMBER || 3)
  )

  const totalHeartsByUnit = chunk(
    [...Array.from(Array(missingChunks)), ...totalHeartsString],
    UNITS_BY_NUMBER
  )

  const integerPart = totalHeartsByUnit[0]?.join('')
  const decimalPart = totalHeartsByUnit[1]?.join('')
  const unit = getUnitByLength(totalHeartsByUnit.length)

  if (decimalPart) {
    return `${integerPart}.${decimalPart}`.slice(0, 4) + unit
  }

  return `${integerPart}${unit}`
}

interface ButtonProps {
  className?: string
  publication: PublicationReactionsFragment
  action: () => void
  flavour?: HeartButtonFlavour
}

const AnimatedDetailButton = React.forwardRef<HTMLButtonElement, ButtonProps>(
  function DetailHeartButton(props, ref) {
    const { action, publication, className } = props
    const { totalHearts, userReaction } = publication

    const [animate, setAnimate] = useState(false)
    const timeout = useRef<NodeJS.Timeout | null>(null)
    const userHasHearted = userReaction === 'HEART'

    /**
     * This function is in-charge of cleaning the
     * animation tracks. Making sure that it's totally reset
     */
    const clearTimeOut = useCallback(() => {
      if (!timeout.current) {
        return
      }

      setAnimate(false)
      clearTimeout(timeout.current)
      timeout.current = null
    }, [])

    const handleOnClick = useCallback(() => {
      action()
      clearTimeOut()
    }, [clearTimeOut, action])

    const handleOnPointerDown = useCallback(() => {
      timeout.current = setTimeout(() => {
        setAnimate(true)
      }, START_ANIMATION_DELAY)
    }, [])

    return (
      <DetailButton
        ref={ref}
        className={className}
        data-testid="heart-button"
        buttonStyle="tertiary-40"
        onClick={handleOnClick}
        onPointerLeave={clearTimeOut}
        onPointerDown={!userHasHearted ? handleOnPointerDown : undefined}
        $fill={userHasHearted}
      >
        <span className="sr-only">Love this</span>
        <DetailHeartIcon $animate={animate} />
        {totalHearts > 0 && (
          <HeartCount>
            <span data-testid="heart-button-count">
              {formatHeartNumber(totalHearts)}
            </span>{' '}
            <span className="sr-only">already loved this</span>
          </HeartCount>
        )}
      </DetailButton>
    )
  }
)

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  function Button(props, ref) {
    const { className, publication, action, flavour } = props

    if (flavour === 'list') {
      const { totalHearts, userReaction } = publication
      const userHasHearted = userReaction === 'HEART'

      return (
        <ListButton
          className={className}
          data-testid="heart-button"
          ref={ref}
          $fill={userHasHearted}
          onClick={event => {
            /**
             * We are preventing the element from executing the link default action if the
             * the value is wrapped by one
             */
            event.preventDefault()

            action()
          }}
        >
          <span className="sr-only">Love this</span>
          {totalHearts > 0 && (
            <span>
              <span data-testid="heart-button-count">
                {formatHeartNumber(totalHearts)}
              </span>{' '}
              <span className="sr-only">already loved this</span>
            </span>
          )}
          <ListButtonIcon />
        </ListButton>
      )
    }

    return (
      <AnimatedDetailButton
        className={className}
        ref={ref}
        publication={publication}
        action={action}
      />
    )
  }
)

const AuthenticatedButton = React.forwardRef<
  HTMLButtonElement,
  OmitSafe<ButtonProps, 'action'>
>(function AuthenticatedButton(props, ref) {
  const { className, publication, flavour } = props
  const { identifier, userReaction, totalHearts } = publication
  const userHasHearted = userReaction === 'HEART'

  const [mutate] = useAddReactionToPublicationMutation({
    onError: 'show-toast',
    variables: {
      publicationIdentifier: identifier,
      reaction: userHasHearted ? 'NO_REACTION' : 'HEART',
    },
    optimisticResponse: {
      __typename: 'RootMutationType',
      addReactionToPublication: {
        __typename: 'AddReactionToPublicationResponse',
        publication: {
          __typename: 'Publication',
          identifier,
          totalHearts: totalHearts + (userHasHearted ? -1 : +1),
          userReaction: userHasHearted ? 'NO_REACTION' : 'HEART',
        },
      },
    },
  })

  return (
    <Button
      ref={ref}
      className={className}
      flavour={flavour}
      publication={publication}
      action={mutate}
    />
  )
})

const UnauthenticatedButton = React.forwardRef<
  HTMLButtonElement,
  OmitSafe<ButtonProps, 'action'>
>(function UnauthenticatedButton(props, ref) {
  const { className, publication, flavour } = props

  const { showModal } = useModalContext()
  const history = useHistory()

  return (
    <Button
      className={className}
      flavour={flavour}
      ref={ref}
      publication={publication}
      action={() =>
        showModal(SignInForLoveModal, {
          onSignIn: () => {
            history.push(routes.SIGN_IN.create({}))
          },
        })
      }
    />
  )
})

export interface HeartButtonProps {
  className?: string
  publication: PublicationReactionsFragment
  trackHeartCount?: boolean
  flavour?: HeartButtonFlavour
}

const HeartButton = (props: HeartButtonProps) => {
  const { publication, trackHeartCount, className, flavour } = props
  const isUserSignedIn = useUserSignedIn()

  /**
   * Subscribe to the Heart updates when the button
   * is in view, preventing unwanted listeners to be mounted
   */
  const [ref, inView] = useInView({
    skip: !trackHeartCount,
    rootMargin: '50px 0px 50px 0px',
  })

  usePublicationReactionsUpdatedSubscription({
    variables: { publicationIdentifier: publication.identifier },
    skip: !(trackHeartCount && inView),
  })

  if (isUserSignedIn) {
    return (
      <AuthenticatedButton
        className={className}
        ref={ref}
        flavour={flavour}
        publication={publication}
      />
    )
  }

  return (
    <UnauthenticatedButton
      className={className}
      ref={ref}
      flavour={flavour}
      publication={publication}
    />
  )
}

export default HeartButton
