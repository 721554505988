import React, { FC } from 'react'
import { Redirect, useLocation } from 'react-router'

import { routes } from '@sketch/modules-common'

import { ErrorHandler } from '@sketch/tracing'

import {
  DocumentHead,
  LoadingState,
  Pill,
  useModalContext,
  useForTablet,
  Tabs,
  Tab,
  TabList,
  TabPanel,
} from '@sketch/components'
import DocumentItemsLayout from '../../../shares/components/DocumentItemsLayout'
import { Footer } from '../../../shares/components/DocumentItemsLayout/DocumentItemsLayout.styles'
import { DocumentItemDropdown } from '../../../shares/components/DocumentItemDropdown'
import { ShareTrashedModal } from '../../../shares/components/ShareTrashedModal'

import ProjectHeader from '../ProjectHeader'

import ProjectEmptyState from '../ProjectEmptyState'
import ProjectCollectionError, {
  ProjectNotFoundError,
} from '../ProjectCollectionError'
import {
  HeaderAfterCollections,
  DocumentsWrapper,
  TabPanelContent,
  MobileWrapper,
} from './ProjectShares.styles'

import { useSearch } from '../../../shares/hooks/useSearch'
import { useSearchFilters } from '../../../shares/hooks/useSearchFilters'
import { useProject } from '../../hooks/useProject/useProject'

import {
  WorkspaceMinimalFragment,
  useGetDocumentListSettingsQuery,
  ShareListItemFragment,
} from '@sketch/gql-types'
import { useSortingSettings } from '../../../shares/hooks/useSortSettings'
import { ScrollToTop } from '../../../../components/ScrollToTopButton'
import { useHideCollectionsSetting } from '../../../collections/hooks'
import NestedProjectItemsLayout from '../../../nestedProjects/components/NestedProjectItemsLayout'
import NestedProjectDropdown from '../../../nestedProjects/components/NestedProjectDropdown'

export interface ProjectSharesProps {
  projectId: string
  workspace: WorkspaceMinimalFragment
}

interface ProjectDocumentListProps {
  projectId: string
  workspace: WorkspaceMinimalFragment
  totalCount: number
  onLoadMore: () => Promise<any>
  shares: ShareListItemFragment[]
}

const ProjectDocumentList: FC<ProjectDocumentListProps> = ({
  workspace,
  projectId,
  totalCount,
  onLoadMore,
  shares,
}) => {
  const { showModal } = useModalContext()
  const { hideCollections } = useHideCollectionsSetting()
  const { sort } = useSortingSettings()
  const queryVariables = {
    shortId: projectId,
    after: null,
    search: {
      name: null,
      isCurrentVersionDownloadable: null,
      filters: ['NO_COLLECTION'],
    },
    sortOrder: sort,
  }

  return (
    <DocumentItemsLayout
      projectId={projectId}
      shares={shares}
      showProjectName={false}
      showCollectionName={hideCollections}
      totalCount={totalCount}
      onLoadMore={onLoadMore}
      renderDropdown={item => {
        // Rendering a Share Item
        const share = shares.find(share => share.identifier === item.identifier)

        if (!share) {
          ErrorHandler.shouldNeverHappen(
            'share should always be present rendering the ProjectDocumentDropdown'
          )
          return null
        }

        return (
          <DocumentItemDropdown
            share={share}
            workspaceIdentifier={workspace.identifier}
            queryVariables={queryVariables}
            workspaceStatus={workspace.status}
          />
        )
      }}
      action={item => {
        // This action is triggered when the user clicks the DocumentItem
        const isShare = item.__typename === 'Share'
        if (isShare && item.deletedAt) {
          return () => showModal(ShareTrashedModal, { share: item })
        }

        return 'link'
      }}
    />
  )
}

const ProjectShares: FC<ProjectSharesProps> = props => {
  const { workspace, projectId } = props
  const location = useLocation()
  const { searchDebounced: search } = useSearch()
  const { filters } = useSearchFilters()

  const isTabletAndBigger = useForTablet()
  const isMobile = !isTabletAndBigger

  const { identifier: workspaceId } = workspace

  const { data, loading, error, loadMore, totalCount } = useProject({
    workspaceId,
    projectId,
    search,
    filters,
  })

  // Load the document layout
  const { data: documentListSettingsData } = useGetDocumentListSettingsQuery()
  const { documentsLayout } = documentListSettingsData || {}

  const isCurrentlyInProjectView =
    location.pathname ===
    routes.WORKSPACE_PROJECT.create({ workspaceId, projectId })

  const isCurrentlyInTrashProjectView =
    location.pathname ===
    routes.WORKSPACE_TRASH_PROJECT.create({ workspaceId, projectId })

  if (loading && !data.storageItems) {
    return <LoadingState />
  }

  const projectWorkspace = data.project?.workspace

  if (projectWorkspace && workspaceId !== projectWorkspace?.identifier) {
    return <ProjectNotFoundError />
  }

  if (error) {
    return (
      <ProjectCollectionError
        projectId={projectId}
        error={error}
        type="project"
      />
    )
  }

  const project = data.project
  const storageItems = data.storageItems
  const breadcrumbs = data.breadcrumbs

  const nestedProjects =
    storageItems?.filter(
      // eslint-disable-next-line @typescript-eslint/ban-types
      (x): x is Extract<typeof x, { __typename: 'Project' }> =>
        x.__typename === 'Project'
    ) || []

  const shares =
    storageItems?.filter(
      // eslint-disable-next-line @typescript-eslint/ban-types
      (x): x is Extract<typeof x, { __typename: 'Share' }> =>
        x.__typename === 'Share'
    ) || []

  const hasShares = !!shares.length
  const hasNestedProjects = !!nestedProjects.length

  /* ==========
   * Handle redirects when trashing / restoring projects
   * ==========
   * > Redirect to the Trash Project View if the project has been soft-deleted */

  if (!!project?.deletedAt && isCurrentlyInProjectView) {
    return (
      <Redirect
        to={routes.WORKSPACE_TRASH_PROJECT.create({
          projectId,
          workspaceId,
        })}
        push={false}
      />
    )
  }

  /*
   * > Redirect to the Project View if the project was restored */
  if (!project?.deletedAt && isCurrentlyInTrashProjectView) {
    return (
      <Redirect
        to={routes.WORKSPACE_PROJECT.create({
          projectId,
          workspaceId,
        })}
        push={false}
      />
    )
  }
  /* ==========
   * ========== */

  const totalProjectsCount = nestedProjects.length
  // Project Empty State
  if (!loading && project && totalCount.storageItems === 0) {
    return (
      <ProjectEmptyState
        workspace={workspace}
        project={project}
        breadcrumbs={breadcrumbs}
        search={search}
      />
    )
  }

  const renderDocumentsList = () => {
    if (!hasShares) {
      return null
    }

    const isGridLayout = documentsLayout === 'GRID'

    return (
      <DocumentsWrapper
        listLayout={!isGridLayout}
        // return list without gradient styling when there's no nested projects
        grouped={hasNestedProjects}
      >
        {/**
         * This header should only be seen when the user has both shares and
         * nested projects and is on the grid view
         */}
        {hasNestedProjects && isGridLayout && isTabletAndBigger && (
          <HeaderAfterCollections>
            Documents
            <Pill variant="secondary">{totalCount.shares}</Pill>
          </HeaderAfterCollections>
        )}
        {/* NOTE: do not render ProjectDocumentList conditionally within or without a wrapper
         * as this might cause bugs. See more: https://linear.app/sketch/issue/SWEB-380/creating-project-causes-modal-to-close-prematurely
         */}
        <ProjectDocumentList
          projectId={projectId}
          workspace={workspace}
          shares={shares}
          totalCount={totalCount.shares}
          onLoadMore={loadMore.storageItems}
        />
      </DocumentsWrapper>
    )
  }

  const renderNestedProjectsList = () => {
    if (!hasNestedProjects) return null

    return (
      <>
        <NestedProjectItemsLayout
          workspaceId={workspaceId}
          projectId={projectId}
          nestedProjects={nestedProjects}
          onLoadMore={loadMore.storageItems}
          totalCount={totalCount.projects}
          renderDropdown={item => {
            const nestedProject = nestedProjects.find(
              p => p.identifier === item.identifier
            )

            if (!nestedProject) {
              ErrorHandler.shouldNeverHappen(
                'share should always be present rendering the ProjectDocumentDropdown'
              )
              return null
            }

            return (
              <NestedProjectDropdown
                workspace={workspace}
                nestedProject={nestedProject}
              />
            )
          }}
        />
        <ScrollToTop />
      </>
    )
  }

  // For mobile view we want to show tabs
  if (isMobile && hasNestedProjects) {
    return (
      <MobileWrapper>
        <DocumentHead title={project?.name} />
        {project && (
          <ProjectHeader
            project={project}
            workspace={workspace}
            breadcrumbs={breadcrumbs}
            workspaceAccessLevel={project?.workspaceAccessLevel!}
          />
        )}
        <Tabs>
          <TabList>
            <Tab>
              Projects{' '}
              {totalProjectsCount && (
                <Pill variant="secondary">{totalProjectsCount}</Pill>
              )}
            </Tab>
            <Tab>
              Documents{' '}
              {totalCount.shares && (
                <Pill variant="secondary">{totalCount.shares}</Pill>
              )}
            </Tab>
          </TabList>
          <TabPanel>
            <TabPanelContent>
              {renderNestedProjectsList()}
              <Footer />
            </TabPanelContent>
          </TabPanel>
          <TabPanel>
            <TabPanelContent>{renderDocumentsList()}</TabPanelContent>
          </TabPanel>
        </Tabs>
      </MobileWrapper>
    )
  }

  return (
    <>
      <DocumentHead title={project?.name} />
      {project && (
        <ProjectHeader
          project={project}
          workspace={workspace}
          breadcrumbs={breadcrumbs}
          workspaceAccessLevel={project?.workspaceAccessLevel!}
        />
      )}
      {renderNestedProjectsList()}
      {renderDocumentsList()}
    </>
  )
}

export default ProjectShares
