import React from 'react'
import { routes } from '@sketch/modules-common'

import {
  useGetProjectsQuery,
  useGetProjectsLazyQuery,
  GetProjectsQueryVariables,
  GetProjectsQuery,
  ProjectInSidebarAndHeaderFragment,
} from '@sketch/gql-types'

import { ProjectLink } from '../types'
import { useOnEvent } from '@sketch/utils'

type ProjectsById = {
  [key: string]: ProjectLink
}

export interface UseProjectsSwitchProps
  extends OmitSafe<GetProjectsQueryVariables, 'workspaceId'> {
  workspaceId: string
}

export const useGetProjects = ({
  workspaceId,
  after,
}: UseProjectsSwitchProps) => {
  const { data, ...query } = useGetProjectsQuery({
    variables: { workspaceId, after },
  })

  const projectsData = data?.workspace.projects
  const draftsData = data?.workspace.draftsProject

  useOnEvent('storageItemTransferred', ({ location }) => {
    const { destination } = location

    // Not in the same workspace, no need to refetch
    if (destination.workspace.identifier !== workspaceId) {
      return
    }

    query.refetch()
  })

  const {
    pinnedProjects,
    rootProjects: rootStandardProjects,
    projectsById: standardProjectsById,
  } = React.useMemo(
    () => parseProjects(projectsData?.entries || [], workspaceId),
    [projectsData?.entries, workspaceId]
  )

  const {
    rootProjects: rootDraftProjectsRaw,
    projectsById: draftProjectsByIdRaw,
  } = React.useMemo(
    () => parseProjects(draftsData?.entries || [], workspaceId),
    [draftsData?.entries, workspaceId]
  )

  // Special treatment for drafts (see parseDrafts for a detailed explanation)
  const [rootDraftProjects, draftProjectsById] = React.useMemo(
    () => parseDrafts(workspaceId, rootDraftProjectsRaw, draftProjectsByIdRaw),
    [rootDraftProjectsRaw, draftProjectsByIdRaw, workspaceId]
  )

  return {
    ...query,
    ...normalizeQueryData(data),
    projectsById: { ...standardProjectsById, ...draftProjectsById },
    rootStandardProjects,
    pinnedProjects,
    rootDraftProjects,
  }
}

export const useGetProjectsLazy = () => {
  const [getProjects, workspaceResult] = useGetProjectsLazyQuery()
  const { data, ...rest } = workspaceResult

  return { getProjects, ...rest, ...normalizeQueryData(data) }
}

function normalizeQueryData(data: GetProjectsQuery | undefined) {
  if (!data) return {}

  const projects = data.workspace.projects
  const draftsProject = data.workspace.draftsProject

  return {
    projects,
    draftsProject,
    allProjects: [...draftsProject.entries, ...projects.entries],
  }
}

// Takes the projects data and normalizes it to a structure that can be used by
// different components, returning a list of pinned projects, root projects and
// an object of projects by id
function parseProjects(
  entries: ProjectInSidebarAndHeaderFragment[],
  workspaceId: string
) {
  // Create needed structure from project data, and fill the links
  const projectsLink: ProjectLink[] = entries.map(project => ({
    project,
    nestedProjects: [],
    link: routes.WORKSPACE_PROJECT.create({
      projectId: project.identifier,
      workspaceId,
    }),
  }))

  // Create a plain projects list by id, it's easier to manipulate
  const projectsById = projectsLink.reduce(
    (acc: { [key: string]: ProjectLink }, projectLink) => {
      acc[projectLink.project.identifier] = projectLink
      return acc
    },
    {}
  )

  // Fill the nested projects
  projectsLink.forEach(projectLink => {
    const parentId = projectLink.project.parentProjectIdentifier

    if (parentId && projectsById[parentId]) {
      projectsById[parentId].nestedProjects.push(projectLink)
    }
  })

  const pinnedProjects = projectsLink.filter(
    ({ project }) => project.pinnedByCurrentUserAt
  )

  const rootProjects = projectsLink.filter(
    project => !project.project.parentProjectIdentifier
  )

  return { pinnedProjects, rootProjects, projectsById } as const
}

// My drafts project is a bit special, because it should be accessed via
// `.../drafts` url, and not with the project identifier like any other
// project, that's why we need to tweak these drafts lists
function parseDrafts(
  workspaceId: string,
  drafts: ProjectLink[],
  byId: ProjectsById
): [ProjectLink[], ProjectsById] {
  const myDrafts = drafts[0]

  if (!myDrafts) {
    return [[], {}]
  }

  const myDraftsId = myDrafts.project.identifier

  const rootDraftProjectsSorted: ProjectLink[] = [
    {
      ...myDrafts,
      link: routes.WORKSPACE_DRAFTS.create({ workspaceId }),
    },
  ]
  const draftProjectsById: ProjectsById = {
    ...byId,
    [myDraftsId]: {
      ...byId[myDraftsId],
      link: routes.WORKSPACE_DRAFTS.create({ workspaceId }),
    },
  }

  return [rootDraftProjectsSorted, draftProjectsById]
}

export function useGetRootProject(workspaceId: string, projectId: string) {
  const { projectsById } = useGetProjects({ workspaceId })

  let currentProject = projectsById[projectId]

  while (currentProject?.project?.parentProjectIdentifier) {
    currentProject =
      projectsById[currentProject.project.parentProjectIdentifier]
  }

  return currentProject
}
