import { SegmentValues } from '../../constants'
import {
  isDocumentRoute,
  isDocumentPageRouteCanvas,
  isDocumentPageRouteLegacy,
  isArtboardRoute,
  isCwvRouteOnly,
  isDocumentPrototypeRoute,
  isFrameGroupRoute,
} from '@sketch/modules-common'

interface CreateTabProps {
  shouldShowAbout?: boolean
  shouldShowComment?: boolean
  shouldShowVersion?: boolean
  shouldShowInspector?: boolean
}

export const createTabs = ({
  shouldShowAbout,
  shouldShowInspector,
  shouldShowVersion,
  shouldShowComment,
}: CreateTabProps) => {
  const tabs: (SegmentValues | false | undefined)[] = [
    shouldShowAbout && 'About',
    shouldShowComment && 'Comment',
    shouldShowVersion && 'Version',
    shouldShowInspector && 'Inspect',
  ]

  return tabs.filter(Boolean) as SegmentValues[]
}

interface GetActivePanelsArgs {
  path: string
  canInspect?: boolean
  canComment?: boolean
}

/**
 * This function returns the active panels for the current route having into
 * account some user permissions.
 *
 * Usually the result of this function will be used as the argument of
 * `useShareSidebarTab(getActivePanels(...))` hook.
 *
 * Summary of the active panels per route (inspect and comments depends also on
 * user permissions):
 *
 * - Document view: about, versions, inspect, comments
 * - Page view (canvas): about, versions, inspect, comments
 * - Page view (legacy): about, versions
 * - Artboard view: versions, inspect, comments
 * - Components (except symbols): about, versions, inspect (shown in sidebar, not in panel)
 *   - Symbols = about, versions
 * - Prototypes: about, versions
 */
export const getActivePanels = ({
  path,
  canInspect,
  canComment,
}: GetActivePanelsArgs): SegmentValues[] => {
  if (isDocumentRoute(path) || isDocumentPageRouteCanvas(path)) {
    return createTabs({
      shouldShowAbout: true,
      shouldShowVersion: true,
      shouldShowInspector: canInspect,
      shouldShowComment: canComment,
    })
  }

  if (isArtboardRoute(path) || isFrameGroupRoute(path)) {
    return createTabs({
      shouldShowAbout: false,
      shouldShowVersion: true,
      shouldShowInspector: canInspect,
      shouldShowComment: canComment,
    })
  }

  // Note: for CWV (except symbols) we show an inspector, but it's not shown in
  // a panel so we set `shouldShowInspector` to false here, this case is
  // managed manually in <DocumentSidebar />
  if (
    isDocumentPageRouteLegacy(path) ||
    isCwvRouteOnly(path) ||
    isDocumentPrototypeRoute(path)
  ) {
    return createTabs({
      shouldShowAbout: true,
      shouldShowVersion: true,
      shouldShowInspector: false,
      shouldShowComment: false,
    })
  }

  return []
}
