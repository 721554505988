import { useCallback, useEffect } from 'react'
import {
  VersionFragment,
  useEditDescriptionsMutation,
  useGetArtboardDescriptionQuery,
  useGetPendingDescriptionQuery,
} from '@sketch/gql-types'
import { useEventDispatch } from '@sketch/utils'
import { useComponentsDescriptionContext } from '../../../ComponentsWebView/context'

import { useFlag } from '@sketch/modules-common'
import { SketchElement, isSymbolMasterElement } from '../../../../inspector'

declare module '@sketch/utils' {
  export interface EventsMap {
    versionIsTriggeredFromSymbol: string
  }
}

/**
 * Read and write component description of components like symbols.
 * For this hook to work, useSetupComponentDescriptions must be called first
 * to fetch the component descriptions and set them in the context
 */
export function useEditableComponentDescription() {
  const {
    updateDescripton,
    componentsDescription: { selected: selectedComponentId, descriptions },
  } = useComponentsDescriptionContext()

  const onVersionTriggered = useEventDispatch('versionIsTriggeredFromSymbol')

  const [editDescription] = useEditDescriptionsMutation({
    onCompleted: () => {
      onVersionTriggered(descriptions[selectedComponentId!].shareIdentifier!)
    },
    onError: 'show-toast',
  })

  const editDescriptionHandler = useCallback(
    (description: string) => {
      const componentSelected = selectedComponentId!
      const component = descriptions[componentSelected]

      editDescription({
        variables: {
          baseVersionIdentifier: component.baseVersionIdentifier!,
          compatibilityVersion: component.compatibilityVersion!,
          documentVersion: component.documentVersion!,
          shareIdentifier: component.shareIdentifier!,
          edits: [
            {
              componentUuid: componentSelected,
              description,
            },
          ],
        },
      })

      updateDescripton(componentSelected, description)
    },
    [descriptions, editDescription, selectedComponentId, updateDescripton]
  )
  return {
    description:
      descriptions?.[selectedComponentId || '']?.description ?? undefined,
    isEnabled: Boolean(
      descriptions?.[selectedComponentId || '']?.canEditDescriptions
    ),
    editDescriptionHandler,
  }
}

/**
 * Fetch component descriptions and set them in the context for the inspector
 * to grab them when selecting a symbol and select the current artboard.
 */
export function useSetupComponentDescriptions(
  shareIdentifier: string,
  element: SketchElement | null,
  currentVersion: VersionFragment | undefined
) {
  const isComponentDescriptionsOn = useFlag('components-description')

  const { componentsDescription, onComponentSelected } =
    useComponentsDescriptionContext()

  const { data, loading: pendingArtboardDescriptionLoading } =
    useGetArtboardDescriptionQuery({
      variables: {
        shareIdentifier,
        permanentArtboardId: element?.elementUUID || '',
        documentVersionShortId: currentVersion?.shortId || '',
      },
      skip:
        !element ||
        !isSymbolMasterElement(element) ||
        !currentVersion?.identifier ||
        !isComponentDescriptionsOn,
    })

  const { data: pendingDescriptionsData, loading: pendingDescriptionsLoading } =
    useGetPendingDescriptionQuery({
      variables: {
        shareIdentifier,
        versionIdentifier: currentVersion?.identifier!,
      },
      skip: !currentVersion?.identifier || !isComponentDescriptionsOn,
    })

  const artboardDescription = data?.artboard?.component?.description

  // Sync description or pending description with Component Description context
  // The logic way to deal with this effect would be to call
  // `resetComponentDescriptionSelection` when the component is unmounted, but
  // this is causing an inifinte loop
  useEffect(() => {
    if (
      pendingDescriptionsLoading ||
      pendingArtboardDescriptionLoading ||
      !currentVersion?.identifier ||
      componentsDescription.selected === element?.elementUUID ||
      !isComponentDescriptionsOn
    ) {
      return
    }

    if (element?.elementUUID) {
      const editingComponent = (
        pendingDescriptionsData?.collaborativeEditingSession.componentEdits ||
        []
      ).find(
        component =>
          (component.componentUuid as string).toUpperCase() ===
          element?.elementUUID
      )

      const componentDescription = {
        componentUuid: element?.elementUUID,
        documentVersion: currentVersion?.document?.documentVersion!,
        baseVersionIdentifier: currentVersion?.identifier!,
        compatibilityVersion:
          currentVersion?.document?.coeditCompatibilityVersion!,
        shareIdentifier,
        canEditDescriptions: currentVersion?.document?.canEditDescriptions!,
        description: editingComponent?.description || artboardDescription,
      }

      // Set the artboard at the selected component
      onComponentSelected(element?.elementUUID, componentDescription)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    element?.elementUUID,
    shareIdentifier,
    pendingDescriptionsLoading,
    pendingArtboardDescriptionLoading,
    currentVersion?.identifier,
    componentsDescription.selected,
    isComponentDescriptionsOn,
    artboardDescription,
  ])
}
