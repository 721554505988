import styled, { css } from 'styled-components'

import { Image } from '../../../shares/components/DocumentItem/DocumentItem.styles'

export const Container = styled.div(
  ({ theme }) => css`
    position: relative;
    display: flex;
    flex-shrink: 0;

    width: 64px;
    height: 64px;

    margin-right: 16px;
    background-color: ${theme.colors.background.tertiary.C};
    border-radius: 6px 0 0 6px;

    &::before,
    &::after {
      content: '';
      position: absolute;
      padding: 0;
      border-radius: 0;
      box-shadow: none;
      background: ${theme.colors.background.secondary.A};
    }

    &::before {
      width: 2px;
      height: 64px;
      top: 0;
      left: 31px;
    }

    &::after {
      width: 64px;
      height: 2px;
      top: 31px;
      left: 0;
    }
  `
)

export const StyledStatusIcon = styled.div`
  height: 64px;
  width: 64px;
  /* This color should be "Light/colors.foreground.secondary.4" but given the icon composition */
  /* Opacity needs to be applied to the whole object */
  color: ${({ theme }) => theme.colors.foreground.secondary.A};
  opacity: 0.55;
`

export const Thumbnail = styled(Image)`
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  overflow: hidden;

  /* Create the border inside */
  :after {
    border-radius: 4px;

    content: '';
    box-shadow: inset 0 0 0 1px ${({ theme }) => theme.colors.border.A};
    position: absolute;

    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
`

export const ThumbnailWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  column-gap: 2px;
  row-gap: 2px;

  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  position: absolute;
`

export const NoThumbnailWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  box-shadow: inset 0 0 0 1px ${({ theme }) => theme.colors.border.A};
`
